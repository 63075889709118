import * as React from "react";
export class SubMenuElements extends React.Component<LMI.LmiSubMenuElementsProps, any> {
	default = {
		svg: "/images/LoopMeIn_LOGO_FINAL.svg",
		color: "#999",
		style: {
			width: "75px",
			height: "75px",
			bottom: "0",
			right: "10px"
		}
	};

	getSVGBG(props: LMI.LmiSubMenuElementsProps) {
		let style = {
			backgroundColor: props.color ? props.color : this.default.color,
			mask: `url(${props.svg ? props.svg : this.default.svg}) no-repeat center / contain`,
			WebkitMask: `url(${props.svg ? props.svg : this.default.svg}) no-repeat center / contain`,
			...this.default.style
		};
		if (props.style) style = { ...style, ...props.style };
		return <span className="subMenuIcon" style={style} />;
	}

	render() {
		return <>{this.getSVGBG(this.props)}</>;
	}
}
export default SubMenuElements;
