import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { addAlert, triggerUABCallback } from "api/redux/actions";
import { Form, Input } from "formsy-semantic-ui-react";
import { Button, Container, Dimmer, Loader } from "semantic-ui-react";
import * as Raven from "raven-js";
import AddLeaseReturnForm from "./AddLeaseReturnForm";
import { handleErrorResponse } from "../../../../../../utils/functions";
import { restAPI } from "../../../../../../utils/rest";

export class AddLeaseReturnFormView extends React.Component<
  LMI.IALRFVProps,
  LMI.IALRFVState
> {
  constructor(props: LMI.IALRFVProps) {
    super(props);

    this.state = {
      searching: false,
      saving: false,
      selected: null,
      searchValue: null,
    };
  }

  onValidate = () => {
    this.setState({ searching: true }, () => {
      this.props.setHasResults(true);
    });
  };

  createLeaseReturn(item: LMI.IInventoryGQL, customer_name: string) {
    this.setState({ saving: true }, () => {
      restAPI({
        endpointName: "createLeaseReturn",
        urlArgs: [this.props.storeId],
        data: { vin: item.vin, customer_name },
        callback: async (error, result) => {
          if (error) {
            this.setState({ saving: false });
            this.sendAlert({
              type: "error",
              message: handleErrorResponse({ error }),
            });
            Raven.captureException(error);
            console.error(error);
          } else {
            this.props.setHasResults(false);
            this.props.closeModalCallback();
            await this.props.onChangeMade(false);
            this.props.onRefetchLeaseReturns();
          }
        },
      });
    });
  }

  sendAlert({ type, message }) {
    this.props.addAlert({
      type,
      message,
      timeout: 3000,
    });
  }

  searchAgain = (result: string = "") => {
    if (result && result.trim() !== "")
      this.sendAlert({ type: "error", message: result });
    this.setState({ searching: false }, () => this.props.setHasResults(false));
  };

  render() {
    const { searchValue, searching, saving } = this.state;

    const searchProps = {
      VIN: searchValue,
      searchAgain: (result: string) => this.searchAgain(result),
      onCreateLeaseReturn: (inventoryItem: LMI.IInventoryGQL, customer_name) =>
        this.createLeaseReturn(inventoryItem, customer_name),
      ...this.props,
    };

    return (
      <div className="lookup-container">
        {saving && (
          <Dimmer active inverted>
            <Loader inverted>Building Lease Return</Loader>
          </Dimmer>
        )}
        {!searching ? (
          this.getValidationForm()
        ) : (
          <AddLeaseReturnForm {...searchProps} />
        )}
      </div>
    );
  }

  getValidationForm() {
    return (
      <Form
        id="VinValidationForm"
        noValidate
        onValidSubmit={this.onValidate.bind(this)}
      >
        <Form.Field>
          <label>Let's start by getting the vehicles VIN</label>
          <Input
            id="SearchInput"
            name="search"
            onChange={(e, data) =>
              this.setState({ searchValue: data.value.toUpperCase() })
            }
            required
          />
        </Form.Field>
        <Container textAlign="right">
          <Button type="submit" positive size="large">
            Validate
          </Button>
        </Container>
      </Form>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModalCallback: () => {
      dispatch(triggerUABCallback(false));
    },
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const AddLeaseReturnValidation = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AddLeaseReturnFormView) as React.ComponentType<any>;

export default AddLeaseReturnValidation;
