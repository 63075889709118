import * as React from "react";
import VehiclePhoto from "./VehiclePhoto";
import { LightboxComponent } from "client/components/ImageHandling/LightboxComponent";

interface PhotoDetailProps extends LMI.InventoryDetailProps {}
interface PhotoDetailState {
  lightbox_open: boolean;
  lightbox_photo_index: number;
}

export class PhotoDetail extends React.Component<
  PhotoDetailProps,
  PhotoDetailState
> {
  constructor(props: PhotoDetailProps) {
    super(props);

    this.state = {
      lightbox_open: false,
      lightbox_photo_index: 0,
    };
  }
  render() {
    const { detail } = this.props;
    const { lightbox_open, lightbox_photo_index } = this.state;
    const hasMultiplePhotos = detail.photos && detail.photos.length > 1;

    return (
      <span>
        {lightbox_open && (
          <LightboxComponent
            photo_index={lightbox_photo_index}
            photos={detail.photos.map((p) => p.url)}
            onCloseLightbox={() =>
              this.setState({ lightbox_open: false, lightbox_photo_index: 0 })
            }
          />
        )}
        <div className={`image-container`}>
          <VehiclePhoto
            detail={detail}
            rounded={false}
            imageClass={`vehicle-image`}
            clicked={() => this.setState({ lightbox_open: true })}
            enableSoldBanner={true}
            photoParams={`&max-h=${
              hasMultiplePhotos
                ? `250&txtsize=46&txt-align=bottom%2Cleft&txtfont=Avenir+Next+Condensed,Bold&txt=${detail.photos.length}%20more%20photos&txtpad=30&txtclr=fff&txt-shad=10`
                : "250"
            }`}
          />
        </div>
      </span>
    );
  }
}

export default PhotoDetail;
