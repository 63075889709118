// node_modules
// components
import { Form } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";

export class JobTitlesSelectComponentView extends React.Component<LMI.SUAdminNewUserJobRoleProps, any> {
	render() {
		const opts = this.props.job_titles ? this.props.job_titles.map(r => ({ text: r.name, value: r.id, key: "job-title-" + r.id })) : [];
		const selectedJobTitle = this.props.employee_user ? this.props.employee_user.job_title_id : null;
		return (
			<span>
				<Form.Select
					search
					label="Job Title"
					disabled={opts.length === 0}
					name="job_title"
					value={selectedJobTitle}
					options={opts ? opts : []}
					placeholder="Select a Job Title"
					onChange={(error, data) => {
						if (data.value) {
							this.props.saveTitle(data.value);
						}
					}}
				/>
			</span>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const JobTitlesSelectComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.jobTitles, {
		skip: (ownProps: any) => !ownProps.storeId,
		options: (props: any) => {
			return {
				variables: {
					storeTypeId: props.type
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, job_titles, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				job_titles: job_titles.job_titles,
				refetch
			};
		}
	})
)(JobTitlesSelectComponentView) as React.ComponentType<any>;

export default JobTitlesSelectComponent;
