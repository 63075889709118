import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { hasPermission } from "client/utils/userAccess";
import { flowRight as compose } from "lodash";
import { Permission } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Grid, Loader, Segment } from "semantic-ui-react";
import { addAlert, enableUABCallback, triggerUABCallback } from "../../../../../../../../api/redux/actions";
import { Loading } from "../../../../../../../components/Loading";
import { withUAB } from "../../../../../../WithUAB";
import { SortableTable } from "../../../../SortableTable";
import { restAPI } from "client/utils/rest";
import { UniversalActionModalComponent } from "../../../../UniversalActionModalComponent";
import { AddEmployeeFormComponent, EmployeeDetailComponent } from "../../components";
import * as Raven from "raven-js";
import { Session } from "client/utils/session";

import "./EmployeeListComponent.css";

export class EmployeeListComponentView extends React.Component<LMI.IEmployeesTPProps, LMI.IEmployeesListTPState> {
	excludedEmpJobTitles = [47];
	constructor(props) {
		super(props);
		this.state = {
			formData: null,
			showMessage: false,
			message: "",
			selectedEmployeeId: null,
			selectedIndex: 0,
			reload: null,
			complete: null,
			invitedUsers: []
		};
	}

	sendUserInvite(user: any, storeId: string, invited: any) {
		restAPI({
			endpointName: "inviteStoreEmployeeUser",
			urlArgs: [storeId.toString(), user.user.id],
			data: null,
			callback: (err, res) => {
				const message = !err ? "An email invitation was sent to " + user.user.full_name + "!" : err.reason.response.data.message;
				this.props.addAlert({
					type: !err ? "success" : "danger",
					message,
					timeout: 5000
				});
				this.setState({
					invitedUsers: [...this.state.invitedUsers, ...[invited]]
				});
			}
		});
	}

	render() {
		const props = this.props;

		if (props.loading) {
			return <Loading />;
		}

		if (props.hasErrors) {
			console.error("EmployeesTabPanel Data errors:", props.error);
		}

		const employeeList = props.employees.filter(e => !e.job_title || !this.excludedEmpJobTitles.includes(e.job_title.id));
		const employees = this.getTableData(employeeList, props.viewType);

		const selectedIndex =
			employees && this.state.selectedEmployeeId
				? employees.findIndex(i => i.id === this.state.selectedEmployeeId) !== -1
					? employees.findIndex(i => i.id === this.state.selectedEmployeeId)
					: 0
				: 0;

		const selectedEmployee =
			selectedIndex !== -1 ? this.getEmployeeAtIndex(selectedIndex, employees) : this.getEmployeeById(this.state.selectedEmployeeId, employees);
		const selectedUserId = selectedEmployee && selectedEmployee.id;

		const employeeDetailObj = {
			id: selectedUserId,
			storeId: props.storeId,
			orgId: props.orgId,
			permissions: props.permissions,
			isActive: selectedEmployee ? selectedEmployee.is_active : undefined,
			userInvited: this.state.invitedUsers && this.state.invitedUsers.findIndex(i => i === selectedUserId) >= 0 ? true : false,
			onRefresh: () => this.props.refetch_employees(),
			onInvite: user => this.sendUserInvite(user, this.props.storeId, selectedUserId)
		};

		const modalProps = {
			orgId: this.props.orgId,
			onCreate: (user, permissions) => this.onSubmitHandler(user, permissions)
		};

		return (
			<div className="employees-tab-panel">
				{this.props.viewType.includes("sub-component") ? (
					""
				) : (
					<UniversalActionModalComponent
						size="large"
						scrolling={false}
						headerText="+ Add Employee"
						toggleUABCallback={props.toggleUABCallback}
						universalActionCallback={props.universalActionCallback}
						contentComponent={() => this.getModalForm(modalProps)}
					/>
				)}
				<Grid>
					<Grid.Column width={employees && employees.length ? 7 : 16} className="scrollable-segment employee-select">
						<div className="ui segment nopad list">
							<SortableTable
								tableData={this.buildTableData(employees)}
								selectedIndex={selectedIndex}
								onClick={({ row }) => {
									this.setState({ selectedEmployeeId: row.id });
								}}
							/>
							{this.state.reload && (
								<div className="lazyloader">
									<Loader active />
								</div>
							)}
						</div>
					</Grid.Column>
					{employees && employees.length && (
						<Grid.Column width={9} className="scrollable-segment">
							<Segment className="details">
								<EmployeeDetailComponent {...employeeDetailObj} />
							</Segment>
						</Grid.Column>
					)}
				</Grid>
			</div>
		);
	}

	componentDidMount() {
		// Should we show the UAB to this user?
		if (!hasPermission(this.props.permissions, Permission.ADMIN_ADD_EMPLOYEE, Session.get("isSuperUser"))) {
			this.props.disableUAB();
		}
	}

	componentDidUpdate() {
		if (!hasPermission(this.props.permissions, Permission.ADMIN_ADD_EMPLOYEE, Session.get("isSuperUser"))) {
			this.props.disableUAB();
		}
	}

	getEmployeeById(employeeId: number, employees: any[]) {
		return employees.find(item => {
			return item.id === employeeId;
		});
	}

	getEmployeeByEmail(email: string) {
		return this.props.employees.find(item => {
			return item.user.email === email;
		});
	}

	getEmployeeAtIndex(index: number, employees: any[]) {
		if (employees && employees.length) {
			return employees[index];
		}
		return null;
	}

	getEmployeeRowIndex(id) {
		return this.props.employees.findIndex(item => {
			return item.user.id === id;
		});
	}

	buildTableData(employees: any[]) {
		const tableHeaders: any = [
			{
				id: "name",
				label: "Name",
				sortable: false
			},
			{
				id: "email",
				label: "Email",
				sortable: false
			},
			{
				id: "job_title",
				label: "Job Title",
				sortable: false
			},
			{
				id: "active",
				label: "Status",
				sortable: false
			}
		];
		return {
			headers: tableHeaders,
			body: { rows: employees }
		};
	}

	getTableData(employees: LMI.IEmployeeGQL[], viewType: string) {
		const searchFilter = this.props.searchFilter ? this.props.searchFilter.toLowerCase() : null;
		const activeFilter = this.props.activeFilter ? this.props.activeFilter : null;

		const emp =
			employees &&
			employees
				.map((emp: LMI.IEmployeeGQL) => {
					const user = emp.user;
					const employee = {
						id: emp.id,
						name:
							user.activated_at === null
								? {
										text: user.full_name,
										labeled: "NOT ACTIVATED",
										mini: true,
										attention: true
								  }
								: user.full_name || ("" as any),
						email: user.email || "",
						job_title: emp.job_title ? emp.job_title.name : "Not Assigned",
						active: emp.is_active ? "Active" : "Inactive"
					};
					return employee;
				})
				.filter(item => {
					return activeFilter === "All" || item.active.includes(activeFilter);
				})
				.filter(item => {
					const itemActivated = item.name.labeled ? item.name.text.toLowerCase() : item.name.toLowerCase();
					return !searchFilter || itemActivated.includes(searchFilter) || item.email.toLowerCase().includes(searchFilter);
				});
		return emp;
	}

	getModalForm(props) {
		return <AddEmployeeFormComponent {...props} />;
	}

	clean(obj) {
		for (const propName in obj) {
			if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
				delete obj[propName];
			}
		}
		return obj;
	}

	async onSubmitHandler(user, permissions) {
		const cleanuser = this.clean(user);
		const store = permissions[0];
		try {
			const data = Object.assign({}, cleanuser, {
				role_ids: store.roles,
				permissions: store.changed
			});
			restAPI({
				endpointName: "add_store_employee",
				urlArgs: [store.id],
				data,
				callback: (error, result) => {
					if (!error) {
						this.props.refetch_employees().then(() => {
							this.setState({
								selectedEmployeeId: result.data.employeeId
							});
						});
					} else {
						this.props.addAlert({
							type: "danger",
							message: error.reason.response.data.message,
							timeout: 3000
						});
					}
					return result;
				}
			});
		} catch (e) {
			Raven.captureException(e);
			console.error(e);
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		toggleUABCallback: state.app.admin.toggleUABCallback,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		universalActionCallback: () => {
			dispatch(triggerUABCallback(false));
		},
		disableUAB: () => {
			dispatch(enableUABCallback(false));
		},
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

const showUABOn = [{ viewType: "dealerships" }, { viewType: "vendors" }];
const EmployeeListComponentViewUAB: any = withUAB(EmployeeListComponentView, showUABOn);

export const EmployeeListComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.employeesByIsActive, {
		// skip: (ownProps: any) => ownProps.viewType !== "dealerships sub-component",
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					isActive: true
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employees, refetch, networkStatus, fetchMore } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				employees,
				refetch,
				networkStatus,
				reload: cursor => {
					return fetchMore({
						variables: {
							offset: cursor
						},
						updateQuery(prev: LMI.IEmployeesQueryProps, { fetchMoreResult }) {
							const newer: any = fetchMoreResult;
							if (!newer.employees.length) {
								return prev;
							} else {
								return Object.assign({}, prev, {
									employees: [...prev.employees, ...newer.employees]
								});
							}
						}
					});
				}
			};
		}
	}),
	graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.employees, {
		// skip: (ownProps: any) => ownProps.viewType === "dealerships sub-component",
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					term: props.searchFilter && props.searchFilter,
					activeFilter: props.activeFilter ? props.activeFilter : undefined
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employees, refetch: refetch_employees, networkStatus, fetchMore } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				employees,
				refetch_employees,
				networkStatus,
				reload: (offset, limit) => {
					return fetchMore({
						variables: {
							offset,
							limit
						},
						updateQuery(prev: LMI.IEmployeesQueryProps, { fetchMoreResult }) {
							const newer: any = fetchMoreResult;
							if (!newer.employees.length) {
								return prev;
							} else {
								return Object.assign({}, prev, {
									employees: offset === undefined ? newer.employees : [...prev.employees, ...newer.employees]
								});
							}
						}
					});
				}
			};
		}
	})
)(EmployeeListComponentViewUAB) as React.ComponentType<any>;

export default EmployeeListComponent;
