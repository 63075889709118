import * as React from "react";

import { Button, Image, Popup, PopupProps } from "semantic-ui-react";
import { connect } from "react-redux";

import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { gqlQueries } from "gql-imports";
import { graphql } from "@apollo/react-hoc";
import { updateTour } from "api/redux/actions";

enum statuses {
	disabled = 1,
	defaultClosed = 2
}

// keeping this in here because it has dep imports
export interface ToursIntroPopupProps extends LMI.IAdminReduxState {
	route: string;
	name: string;
	popupProps: PopupProps;
	content: () => any;
	trigger: () => any;
	tourId?: number;
	tours?: LMI.ITourGQL[];
	disableTriggerElemId?: string;
}

export class ToursIntroPopupView extends React.Component<ToursIntroPopupProps, LMI.IToursIntroPopupState> {
	delayPop: any;
	static defaultProps: PopupProps = { position: "bottom right", className: "default" };
	static getDerivedStateFromProps(nextProps: ToursIntroPopupProps, prevState: LMI.IToursIntroPopupState) {
		let nextState = prevState;
		const store = localStorage.getItem(nextProps.name);
		const storeObj = store ? JSON.parse(store) : null;
		nextState.tour =
			nextProps.tours && nextProps.tourId
				? nextProps.tours.find(tour => tour.id === nextProps.tourId)
				: nextProps.tours && nextProps.tours.length >= 1
				? nextProps.tours[0]
				: null;
		nextState.disabled = !nextState.tour || (storeObj && storeObj.includes(statuses.disabled)) ? true : false;
		nextState.defaultOpen = storeObj ? !storeObj.includes(statuses.defaultClosed) : prevState.defaultOpen;
		return nextState;
	}

	constructor(props: ToursIntroPopupProps) {
		super(props);
		this.state = {
			tour: null,
			disabled: false,
			defaultOpen: true,
			isOpen: false
		};
	}

	render() {
		const { popupProps, name, trigger } = this.props;
		const { className, position } = { ...ToursIntroPopupView.defaultProps, ...popupProps };
		const { disabled, defaultOpen, isOpen } = this.state;
		const safe = defaultOpen ? isOpen : true;

		if (safe)
			return (
				<Popup
					id={name}
					className={`tour-intro-popup ${className}`}
					content={this.popupContent()}
					defaultOpen={isOpen}
					disabled={disabled}
					hoverable
					position={position}
					size="mini"
					trigger={trigger()}
				/>
			);
		else return <span />;
	}

	componentDidMount() {
		const { defaultOpen } = this.state;
		// delay default open so the the page loads before we show the popup
		if (defaultOpen) this.delayPop = setTimeout(() => this.setState({ isOpen: true }), 500);
	}

	componentWillUnmount() {
		if (this.delayPop) clearTimeout(this.delayPop);
	}

	popupContent(): any {
		const { tour } = this.state;
		if (tour)
			return (
				<div className="new-feature">
					{!this.getStatus(statuses.defaultClosed) && <Image src="/images/new-icon.svg" className="new-icon" />}
					<h4>{tour.name}</h4>
					<p dangerouslySetInnerHTML={{ __html: tour.description }} />
					<Button content="Take a Tour" color="blue" size="mini" onClick={() => this.launchTour()} />
					<Button content="Skip" size="mini" onClick={() => this.setStatus(statuses.disabled)} />
				</div>
			);
		else return <span />;
	}

	getStatus(status: statuses): boolean {
		const storeStatus = this.getStatuses();
		return storeStatus && storeStatus.length > 0 ? storeStatus.includes(status) : false;
	}

	getStatuses(): statuses[] {
		const intro = localStorage.getItem(this.props.name);
		const intObj = intro ? JSON.parse(intro) : null;
		return intObj;
	}

	setStatus(status: statuses) {
		const storeStatus: statuses[] = this.getStatuses();
		if (!this.getStatus(status)) {
			const newStatus = [status];
			const value = storeStatus ? [...storeStatus, ...newStatus] : newStatus;
			localStorage.setItem(this.props.name, JSON.stringify(value));
			this.setState({ [statuses[status]]: !this.state[statuses[status]] });
		}
	}

	launchTour() {
		const { tour } = this.state;
		this.setStatus(statuses.disabled);
		if (tour) this.props.updateTour(tour.steps);
	}

	componentDidUpdate() {
		const setupDisabler = !this.getStatus(statuses.disabled);
		if (setupDisabler && this.props.disableTriggerElemId) {
			const searchButton = document.getElementById(this.props.disableTriggerElemId) as HTMLElement;
			if (searchButton) searchButton.onclick = () => this.setStatus(statuses.disabled);
		}
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		updateTour: (steps: LMI.ITourStepGQL[]) => {
			dispatch(updateTour(steps));
		}
	};
};

export const ToursIntroPopup = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.tours.list, {
		options: (props: any) => {
			return {
				variables: { route: props.route },
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, tours, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				tours,
				refetch
			};
		}
	})
)(ToursIntroPopupView) as React.ComponentType<ToursIntroPopupProps>;

export default ToursIntroPopup;
