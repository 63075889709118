// node_modules
import * as React from "react";
import { Loader, Dropdown } from "semantic-ui-react";

export class EditReconLevel extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			recon_level: null,
			selected: null,
			dirty: false
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { detail } = nextProps;
		if (detail && (detail.recon_level_name || detail.recon_level_id)) {
			if (prevState.selected !== detail.recon_level_id) {
				const findByIdOrName = recon_level => {
					const { recon_level_name, recon_level_id } = nextProps.detail;
					if (recon_level_id) return recon_level.id === recon_level_id;
					else if (recon_level_name) return recon_level.name === recon_level_name;
					else return null;
				};
				const selected = nextProps.recon_levels ? nextProps.recon_levels.find(rl => findByIdOrName(rl)) : null;
				return { selected: selected ? selected.id : null };
			}
		}
		return null;
	}

	render() {
		const { loading, recon_levels, onSubmit, readonly } = this.props;
		const { selected } = this.state;

		if (loading || !recon_levels) return <Loader />;

		let reconName;
		const reconLevelOptions = [
			{ key: null, text: "Select Workflow", value: null },
			...recon_levels.map((rl, key) => {
				if (selected && selected === rl.id) reconName = rl.name;
				return {
					key,
					text: rl.name,
					value: rl.id
				};
			})
		];
		if (!reconName) reconName = "None Selected";

		return (
			<span className="detail-selectors">
				<span className="attached-label">WORKFLOW</span>
				{!readonly ? (
					<Dropdown
						className="selectorsy"
						placeholder="Select Workflow"
						search
						selection
						value={selected}
						options={reconLevelOptions}
						onChange={(e, data) => onSubmit(data.value)}
					/>
				) : (
					<span className="readonly">{reconName}</span>
				)}
			</span>
		);
	}
}

export default EditReconLevel;
