import * as React from "react";
import { multiTokenFilter } from "client/utils/functions";
import { GeographicalRegion } from "client/utils/globals";
import { Dropdown } from "semantic-ui-react";
import "./StateSelector.css";

export class StateSelector extends React.Component<LMI.IStateSelectorProps, LMI.IStateSelectorState> {
	constructor(props: LMI.IStateSelectorProps) {
		super(props);

		this.state = {
			selected: null,
			searchFilter: "",
			open: false
		};
	}

	renderMenu() {
		const displayHeader = region => ({ display: region.states.length > 0 && countries.length > 1 ? "inherit" : "none" });
		let countries = GeographicalRegion;
		if (this.state.searchFilter)
			countries = countries.map(region => ({ ...region, ...{ states: multiTokenFilter({ searchString: this.state.searchFilter, array: region.states }) } }));

		return (
			<Dropdown.Menu>
				{countries.map(region => [
					<Dropdown.Header key={region.name} className="region-header" style={displayHeader(region)}>
						<i className={`flag ${region.abbreviation.toLowerCase()}`} /> {region.name}
					</Dropdown.Header>,
					region.states.map(state => (
						<Dropdown.Item
							key={state.abbreviation}
							text={state.name}
							value={state.abbreviation}
							selected={state.name === this.state.selected}
							onClick={(event, data) => this.selectItem(data)}
						/>
					))
				])}
			</Dropdown.Menu>
		);
	}

	selectItem(data) {
		this.setState({ selected: data.text.toString(), open: false }, () => {
			this.props.onChange(data);
			const StoreSelector = document.getElementById("StoreSelector") as HTMLElement;
			if (StoreSelector) {
				const searchInput = StoreSelector.childNodes[0] as HTMLInputElement;
				searchInput.blur();
			}
		});
	}

	render() {
		const { placeholder, passedProps } = this.props;
		const { selected, open } = this.state;
		const ddProps = {
			open,
			id: "StoreSelector",
			className: "selection",
			fluid: true,
			search: true,
			onSearchChange: (event, data) => this.setState({ searchFilter: data.searchQuery }),
			onOpen: () => this.setState({ open: true, searchFilter: "" }),
			onClose: () => this.setState({ open: false }),
			...passedProps
		};
		return <Dropdown {...{ ...ddProps, ...(selected ? { text: selected } : { placeholder }) }}>{this.renderMenu()}</Dropdown>;
	}

	static getDerivedStateFromProps(nextProps: LMI.IStateSelectorProps, prevState: LMI.IStateSelectorState) {
		if (!prevState.selected && nextProps.value) {
			const region = GeographicalRegion.find(region => region.states.findIndex(i => i.abbreviation === nextProps.value) > -1);
			if (region) {
				const state = region.states.find(s => s.abbreviation === nextProps.value);
				if (state) return { selected: state.name };
			}
		}
		return null;
	}
}

export default StateSelector;
