// node_modules
import * as React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Loading } from "../Loading";

export class LightboxComponent extends React.Component<LMI.IInventoryLightboxTabProps, LMI.IInventoryLightboxTabState> {
	constructor(props) {
		super(props);
		this.state = {
			photo_index: props.photo_index ? props.photo_index : 0
		};
	}

	closeLightbox = () => {
		this.props.onCloseLightbox();
	};

	render() {
		const { photos } = this.props;
		const { photo_index } = this.state;

		if (!photos) {
			return (
				<div className="lightbox-loading">
					<Loading />
				</div>
			);
		}

		return (
			<Lightbox
				mainSrc={photos[photo_index]}
				nextSrc={photos[(photo_index + 1) % photos.length]}
				prevSrc={photos[(photo_index + photos.length - 1) % photos.length]}
				onCloseRequest={() => this.closeLightbox()}
				onMovePrevRequest={() =>
					this.setState({
						photo_index: (photo_index + photos.length - 1) % photos.length
					})
				}
				onMoveNextRequest={() =>
					this.setState({
						photo_index: (photo_index + 1) % photos.length
					})
				}
			/>
		);
	}
}
