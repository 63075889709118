import * as React from "react";
import { Button, Divider, Header, Icon, List, Segment } from "semantic-ui-react";

interface InspectionConfirmAddMultiItemProps {
	inspection_template_items: LMI.InspectionTemplateItems[];
	item_duplicates: LMI.IInspectionLineItem[];
	onConfirm: (items: LMI.InspectionTemplateItems[]) => void;
	onClose: () => void;
}

interface InspectionConfirmAddMultiItemState {
	confirm_items: LMI.InspectionTemplateItems[];
}

export class ConfirmAddMultipleItems extends React.Component<InspectionConfirmAddMultiItemProps, InspectionConfirmAddMultiItemState> {
	constructor(props: InspectionConfirmAddMultiItemProps) {
		super(props);
		this.state = {
			confirm_items: props.inspection_template_items
		};
	}

	getDuplicateChecklist() {
		const { item_duplicates } = this.props;
		const { confirm_items } = this.state;
		const config_duplicates = item_duplicates.filter(dup => confirm_items.map(i => i.inspection_item_option_id).includes(dup.inspection_item_option_id));
		const removeItem = item => this.setState({ confirm_items: confirm_items.filter(i => i.inspection_item_option_id !== item.inspection_item_option_id) });

		if (config_duplicates && config_duplicates.length > 0)
			return (
				<Segment id="DupChecklist" placeholder style={{ minHeight: 0 }}>
					<strong>{`We found ${
						config_duplicates.length !== confirm_items.length ? `${config_duplicates.length} of the ${confirm_items.length}` : config_duplicates.length
					} items already exist in this inspection.`}</strong>
					<small>Click to remove any duplicates you don't want to add to the inspection.</small>
					<List>
						{config_duplicates.map((item, key) => {
							return (
								<List.Item key={key}>
									<List.Content>
										<Icon link color="red" name="minus square" onClick={() => removeItem(item)} />
										{item.inspection_item_option.name}
									</List.Content>
								</List.Item>
							);
						})}
					</List>
				</Segment>
			);
	}

	render() {
		const { item_duplicates, onConfirm, onClose } = this.props;
		const { confirm_items } = this.state;
		return (
			<div id="confirmDecline">
				<Header as="h5">Please confirm you want to add multiple items to this inspection.</Header>
				{item_duplicates && item_duplicates.length > 0 && this.getDuplicateChecklist()}
				<div className="confirm-options">
					<Divider />
					<div className="confirm-option-btns">
						<Button content="Nevermind" onClick={() => onClose()} />
						{confirm_items && confirm_items.length > 0 && (
							<Button content={`Add ${confirm_items.length} Items`} positive onClick={() => onConfirm(confirm_items)} />
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default ConfirmAddMultipleItems;
