import { gqlQueries } from "gql-imports";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Header, Icon, Divider, Button, Segment, Dimmer, Loader, List, Grid, Label, Popup } from "semantic-ui-react";
import { stringToInt } from "client/utils/functions";
import "./ConfirmOverrideModal.css";

export class ConfirmOverrideModalView extends React.Component<LMI.ICustomConfirmRoles, LMI.ICustomConfirmRolesState> {
	constructor(props) {
		super(props);
		this.state = {
			customPermissions: null
		};
	}
	render() {
		const props = this.props;

		if (props.customPermissions && props.customPermissions.permissions.length < 1) {
			// custom perms check made, callback to save the role but wait half a second
			setTimeout(() => {
				props.confirmCallback(null);
			}, 500);
		}

		if (props.customPermissions && props.customPermissions.permissions.length > 0) {
			const permissions = this.state.customPermissions ? this.state.customPermissions : props.customPermissions;
			const customPermissionsList: LMI.IEmployeeCustomPermissions =
				permissions && permissions.permissions.length
					? {
							permissions: permissions.permissions.filter(p => p.employee_id === props.employeeId)
					  }
					: null;
			const moreThanOneCustomPermission = customPermissionsList && customPermissionsList.permissions && customPermissionsList.permissions.length > 1;
			return (
				<Dimmer active={true}>
					<Segment className="confirm-override">
						<Header as="h2">
							<Icon name="close" className="close" onClick={() => props.closeCallback()} />
							<Icon name="exclamation circle" color="red" />
							<Header.Content>Please Confirm</Header.Content>
						</Header>
						<Divider />
						<Header as="h4">{`This employee has${!moreThanOneCustomPermission ? " a " : " "}customized permission${
							moreThanOneCustomPermission ? "s" : ""
						}`}</Header>
						<p>Would you like to clear or keep the custom configuration?</p>
						<Grid textAlign="left">
							<Grid.Column width={3} />
							<Grid.Column width={10}>
								<Popup
									trigger={
										<List verticalAlign="middle" className="permissions-list">
											{customPermissionsList &&
												customPermissionsList.permissions.length &&
												customPermissionsList.permissions.map((cp: LMI.IEmployeeCustomPermissionsPermissions, index) => {
													const added = cp.add_or_remove === "A";
													return (
														<List.Item key={index} className={moreThanOneCustomPermission ? "clickable-item" : "item"}>
															<Segment className={added ? "perm plus" : "perm minus"} onClick={() => this.removeCustomPermission(cp)}>
																<Label size="small" horizontal>
																	{added ? "ADD" : "DENY"}
																</Label>
																{cp.permission.description}
															</Segment>
														</List.Item>
													);
												})}
										</List>
									}
									content="Click on items to delete them"
									position="left center"
									size="mini"
									disabled={!moreThanOneCustomPermission}
									inverted
								/>
							</Grid.Column>
							<Grid.Column width={3} />
						</Grid>
						<div className="buttoncase center">
							{this.state.customPermissions ? (
								<Button primary content="Cancel" size="large" onClick={() => this.setState({ customPermissions: null })} />
							) : (
								<Button
									primary
									content={`Clear custom permission${moreThanOneCustomPermission ? "s" : ""}`}
									size="large"
									onClick={() => this.props.confirmCallback(null)}
								/>
							)}
							<Button
								content={this.state.customPermissions ? "Save Changes" : `Keep custom permission${moreThanOneCustomPermission ? "s" : ""}`}
								onClick={() => this.props.confirmCallback(permissions)}
								size="large"
							/>
						</div>
					</Segment>
				</Dimmer>
			);
		} else {
			return <Loader />;
		}
	}

	removeCustomPermission(perm: LMI.IEmployeeCustomPermissionsPermissions) {
		// only removing individual permissions if there is more than one in the list
		if (this.props.customPermissions && this.props.customPermissions.permissions.length > 1) {
			const cp = this.state.customPermissions ? this.state.customPermissions : this.props.customPermissions;
			this.setState({
				customPermissions: {
					permissions: cp.permissions
						.filter(i => i.permission_id !== perm.permission_id)
						.map(p => {
							return p;
						})
				}
			});
		}
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const ConfirmOverrideModal = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.employeeCustomPermissions, {
		options: (props: any) => {
			return {
				variables: {
					store_id: stringToInt(props.storeId),
					employee_id: stringToInt(props.employeeId)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employee_custom_permissions, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				customPermissions: employee_custom_permissions,
				refetch
			};
		}
	})
)(ConfirmOverrideModalView) as React.ComponentType<any>;

export default ConfirmOverrideModal;
