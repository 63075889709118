// node_modules
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
// components
import { Divider, Checkbox } from "semantic-ui-react";
import { stringToInt } from "client/utils/functions";

export class GroupsComponentView extends React.Component<LMI.SUAdminGroupsProps, any> {
	render() {
		const groups = this.props.su_organizations && this.props.su_organizations[0].groups;
		return (
			<div>
				{groups && groups.length ? (
					<span>
						<Divider />
						<label>Groups:</label>
						<br />
						<br />
						{groups.map((group, index) => {
							return (
								<div key={index}>
									<Checkbox
										label={group.name}
										value={group.id}
										onChange={(e, data) => {
											this.props.onCheck(data);
										}}
									/>
								</div>
							);
						})}
					</span>
				) : (
					""
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const GroupsComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.super.organization, {
		options: (props: any) => {
			return {
				variables: {
					id: stringToInt(props.id)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, su_organizations, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				su_organizations,
				refetch
			};
		}
	})
)(GroupsComponentView) as React.ComponentType<any>;

export default GroupsComponent;
