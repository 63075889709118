import * as React from "react";
import * as ReactTabsContainer from "./react-tabs-container";

import { Label, Loader } from "semantic-ui-react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { TabsFactory } from "./utils/TabsFactory";
import { getSelectedTabStore } from "client/utils/functions";
import { getAppRoutes } from "client/components/Routes/utils";

/**
 * Container component used to build groups of tabs.
 *
 * @export
 * @class TabsContainer
 * @extends {React.Component<TCProps, undefined>}
 */
 export class TabsContainer extends React.Component<ReactTabsContainer.TCProps, any> {
	/**
	 * A factory for creating tab panel components.
	 *
	 * @type {TabsFactory}
	 * @memberof TabsContainer
	 */
	tabsFactory: TabsFactory;
	tabMap: Map<number, string>;

	constructor(props: ReactTabsContainer.TCProps) {
		super(props);
		this.tabsFactory = new TabsFactory();
		this.tabMap = new Map<number, string>();

		this.state = {
			tabList: this.getSubTabs(props.subTabContainer)
		};
	}
	/**
	 * Render the component. Component life-cycle hook.
	 *
	 * @returns
	 *
	 * @memberof TabsContainer
	 */

	render() {
		const { subRouteId, classes, permissions, onChange } = this.props;
		const classNames = classes ? `tabs-container ${classes}` : "tabs-container";
		if (!permissions || permissions.length <= 0) return <Loader active={true} />;
		return (
			<div className={classNames}>
				<Tabs
					selectedIndex={this.getSelectedTab(subRouteId)}
					onSelect={(index: number) => {
						localStorage.setItem(this.getLocalStorageName(), index.toString());
						onChange(this.tabMap.get(index));
					}}
				>
					<TabList>{this.getTabList()}</TabList>
					{this.getTabPanels()}
				</Tabs>
			</div>
		);
	}

	componentDidMount() {
		const tabId: string = this.tabMap.get(this.getSelectedTab(this.props.subRouteId));
		if (tabId) this.props.onChange(tabId);
	}

	componentDidUpdate(prevProps: ReactTabsContainer.TCProps) {
		if (this.props.permissions !== prevProps.permissions) {
			this.props.onChange(this.tabMap.get(this.getSelectedTab(this.props.subRouteId)));
		}
	}

	getSubTabs(containerName) {
		const container = getAppRoutes(this.props.permissions).find(route => route.subTabContainer === containerName);
		return container.subTabs;
	}

	/**
	 * Get a list of Tab components to display. Uses the 'tabList' and
	 * 'permissions' props to filter and create only the panels which
	 * can be seen by the current user.
	 *
	 * @returns {Tab[]}
	 *
	 * @memberof AdminPageView
	 */
	getTabList(): any[] {
		this.tabMap.clear();
		return this.tabsFactory.getTabsList(this.state.tabList, this.props.permissions).map((tab: any, index: number) => {
			const hasBadge = this.props.badges && this.props.badges.find(b => b.id === tab.id);
			const badge = hasBadge ? hasBadge : undefined;
			this.tabMap.set(index, tab.id);
			return (
				<Tab key={index}>
					{tab.name}
					{badge && (
						<span className="tab-badge">
							<Label className="tab-badges" circular color={badge.color} key={index}>
								{badge.value}
							</Label>
						</span>
					)}
				</Tab>
			);
		});
	}
	/**
	 * Get a list of TabPanel components to display. Uses the 'tabList'
	 * and 'permissions' props to filter and create only the panels which
	 * can be seen by the current user.
	 *
	 * @returns {TabPanel[]}
	 *
	 * @memberof AdminPageView
	 */
	getTabPanels(): any[] {
		const { viewType, permissions, dataPassThru } = this.props;
		return this.tabsFactory.getTabsList(this.state.tabList, this.props.permissions).map((tab: any, index: number) => {
			const { name, component } = tab;
			return <TabPanel key={index}>{React.createElement(component, { name, viewType, permissions, ...dataPassThru })}</TabPanel>;
		});
	}

	/**
	 * Gets the 'defaultTabIndex' from localStorage or returns
	 * zero if empty.
	 *
	 * @returns {number}
	 *
	 * @memberof AdminPageView
	 */
	getSelectedTab(selectedTabName: string): number {
		const tabByName = selectedTabName && this.tabsFactory.getTabsList(this.state.tabList, this.props.permissions).findIndex(i => i.id === selectedTabName);
		return tabByName > -1 ? tabByName : getSelectedTabStore(this.props.subTabContainer);
	}
	/**
	 * Gets the key for the value used to store tab state in
	 * local storage.
	 *
	 * @returns {string}
	 *
	 * @memberof TabsContainer
	 */
	getLocalStorageName(): string {
		return `${this.props.subTabContainer}_defaultTabIndex`;
	}
}

export default TabsContainer;
