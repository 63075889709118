import track from "react-tracking";
import { Form, Input } from "formsy-semantic-ui-react";
import * as Raven from "raven-js";
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { Redirect } from "react-router";
import {
  Button,
  Container,
  Header,
  Image,
  Loader,
  Message,
} from "semantic-ui-react";
import { addAlert, loginUser } from "../../../api/redux/actions";
import { restAPI } from "../../utils/rest";
import { AnalyticsEventType } from "loopmein-shared";

import "./Login.css";

/**
 * View used to render the site login form.
 *
 * @class Login
 * @extends {React.Component<LoginProps, LoginState>}
 */
@track(
  { event_type: AnalyticsEventType.NAVIGATION, event_subtype: "login" },
  { dispatchOnMount: true }
)
export class LoginView extends React.Component<
  LMI.ILoginProps,
  LMI.ILoginState
> {
  /**
   * Refs for the Login form.
   *
   * @type {LoginRefs}
   * @memberof Login
   */
  refs: LMI.ILoginRefs;

  constructor(props: LMI.ILoginProps) {
    super(props);

    this.state = {
      errorMessage: null,
      forgotPassword: false,
      submitReady: false,
      forgotPasswordEmail: "",
      force_change_password: false,
      email: process.env.REACT_APP_ENVIRONMENT === "live" ? "" : "multiple.stores@jsoftllc.com",
      password: process.env.REACT_APP_ENVIRONMENT === "live" ? "" : "testtest",
      login_loading: false,
      logged_in_successfully: false,
    };
    this.forgotPassword = this.forgotPassword.bind(this);
  }

  /**
   * Method triggered when the login form is submitted.
   * @param {any} event
   * @memberof Login
   */
  login(args) {
    if (!this.state.logged_in_successfully)
      this.setState({ login_loading: true }, () => {
        restAPI({
          endpointName: "login_user",
          urlArgs: null,
          data: { email: this.state.email, password: this.state.password },
          callback: async (error, result) => {
            const isu =
              result && result.data ? result.data.is_super_user : false;
            const noStoreSet =
              result && result.data && result.data.store_type_ids.length === 0;

            if (result && result.data.must_reset_password) {
              this.setState({ force_change_password: true });
            }
            if (error || (noStoreSet && !isu)) {
              const errorMessage = error
                ? "Please verify your username and password."
                : "There was a problem with your account. Please contact your administrator.";
              this.setState({ errorMessage });
              localStorage.removeItem("token");
            } else {
              this.setState({
                errorMessage: null,
                logged_in_successfully: true,
              });
              this.props.onLogin({ storeName: "Pick a Store" });
              localStorage.setItem("token", result.data.token);
              localStorage.setItem("isu", isu);
              localStorage.setItem("user_full_name", result.data.full_name);

              const email = JSON.parse(result.config.data).email;
              this.props.tracking.trackEvent({
                event_type: AnalyticsEventType.ACTION,
                event_subtype: "login.success",
                email,
                data: {
                  isu,
                  full_name: result.data.full_name,
                  email: JSON.parse(result.config.data).email,
                  stores: result.data.stores,
                },
              });

              if (!this.props.norefresh) {
                try {
                  if (result.data.must_reset_password)
                    window.open(`/change-password`, "_parent");
                  else window.location.reload();
                } catch (e) {
                  console.log("Login error: ", e);
                  Raven.captureException(e);
                }
              } else this.props.loggedInCallback();
            }
            this.setState({ login_loading: false });
          },
        });
      });

    this.props.tracking.trackEvent({
      event_type: AnalyticsEventType.ACTION,
      event_subtype: "login.login",
      data: { details: args },
    });
  }

  onInputChange = (data) => {
    const input = {};
    input[data.id] = data.value;
    this.setState(input);
  };

  /**
   * Render the component.
   *
   * @returns
   *
   * @memberof Login
   */
  render() {
    const { authenticated, location } = this.props;

    let renderContent = null;
    let query;
    let sessionExpired;
    let sessionExpiredMessage;

    if (location && location.search) {
      query = new URLSearchParams(location.search);
      sessionExpired = query.get("sessionExpired") === "true";
      sessionExpiredMessage = sessionExpired ? (
        <Message negative>
          <Message.Header>Session Expired</Message.Header>
          Your session has expired. Please login again.
        </Message>
      ) : null;
    }

    if (authenticated) {
      if (this.state.force_change_password) {
        renderContent = <Redirect to="/change-password" push />;
      } else {
        renderContent = <Redirect to="/" push />;
      }
    } else {
      const errorMessage = this.state.errorMessage ? (
        <Message negative>
          <Message.Header>Login Attempt Failed</Message.Header>
          {this.state.errorMessage}
        </Message>
      ) : null;

      renderContent = (
        <Container className="login-page">
          <Container className="login-block">
            <Image src="/images/LoopMeIn_LOGO_FINAL.png" size="small" />
            <Header>
              {this.state.forgotPassword
                ? "Forgotten Password"
                : "Welcome to LoopMeIn!"}
            </Header>
            {sessionExpiredMessage}
            {errorMessage}
            {this.state.forgotPassword ? (
              <Form
                id="ForgotPasswordForm"
                name="ForgotPasswordForm"
                onSubmit={this.forgotPassword}
              >
                <p>
                  Please provide the email address for your account and we will
                  send an email with instructions to change your password.
                </p>
                <Form.Field id="forgotPassword">
                  <label>Email Address:</label>
                  <input
                    placeholder="Email"
                    value={this.state.forgotPasswordEmail}
                    onChange={this.emailUpdating.bind(this)}
                    type="email"
                    ref="forgotPassword"
                  />
                </Form.Field>
                <Button
                  className="submit-btn"
                  disabled={!this.state.submitReady}
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            ) : (
              <Form
                id="LoginForm"
                name="LoginForm"
                onSubmit={this.login.bind(this)}
              >
                <Form.Field id="email">
                  <label>Email Address:</label>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={(e, data) => this.onInputChange(data)}
                    type="email"
                    ref="email"
                  />
                </Form.Field>
                <Form.Field id="password">
                  <label>Password:</label>
                  <Input
                    id="password"
                    name="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={(e, data) => this.onInputChange(data)}
                    type="password"
                    ref="password"
                  />
                </Form.Field>
                <Button id="submit" className="submit-btn" type="submit">
                  {this.state.login_loading ? (
                    <Loader className="loader active small" />
                  ) : this.state.logged_in_successfully ? (
                    "Loading..."
                  ) : (
                    "Login"
                  )}
                </Button>
              </Form>
            )}
          </Container>
          {!this.state.forgotPassword && (
            <Container className="forgot-password" textAlign="center">
              <Button
                className="forgot-password-btn"
                onClick={() => {
                  this.setState({
                    forgotPassword: true,
                    forgotPasswordEmail: "",
                  });
                }}
              >
                Forgot Password
              </Button>
            </Container>
          )}
          {this.state.forgotPassword && (
            <Container className="forgot-password" textAlign="center">
              <Button
                className="forgot-password-btn"
                onClick={() => {
                  this.setState({
                    forgotPassword: false,
                    forgotPasswordEmail: "",
                  });
                }}
              >
                Sign In
              </Button>
            </Container>
          )}
          <Container className="message" textAlign="center">
            <div>
              <strong>Need help?</strong> You can contact your store
              administrator or{" "}
              <a
                href="https://support.loopmein.app"
                target="_blank"
                rel="noreferrer"
              >
                contact us online
              </a>{" "}
              or voice at 435-527-LOOP.
            </div>
          </Container>
        </Container>
      );
    }

    return renderContent;
  }

  emailUpdating(event) {
    this.setState({
      submitReady: event.currentTarget.value !== "",
      forgotPasswordEmail: event.currentTarget.value,
    });
  }

  forgotPassword() {
    const { forgotPassword } = this.refs;
    restAPI({
      endpointName: "forgot_user_password",
      urlArgs: null,
      data: { email: forgotPassword.value },
      callback: (error, result) => {
        const message = error
          ? "Please verify your email address."
          : "We sent you an email with a link to reset your password.";
        this.props.addAlert({
          type: error ? "danger" : "success",
          message,
          timeout: 5000,
        });
        if (error) {
          console.error(error);
        } else {
          console.log("Forgot Password:", result);
          this.setState({ errorMessage: null, forgotPassword: false });
        }
      },
    });
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogin: (data: any) => {
      dispatch(loginUser(data));
    },
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const Login: any = compose(connect(mapStateToProps, mapDispatchToProps))(
  LoginView
) as React.ComponentType<any>;

export default Login;
