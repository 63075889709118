import StageBreakdownGrid from "./components/grid";
import { withRouter } from "react-router";
import { gqlQueries } from "gql-imports";

import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Dropdown, Header, Loader, Icon, Popup } from "semantic-ui-react";
import { mergeDedupe } from "../../../../../utils/functions";
import moment from "moment";
import { DateRangePickerComponent, RangeEventArgs } from "@syncfusion/ej2-react-calendars";
import track from "react-tracking";
import { AnalyticsEventType, Months } from "loopmein-shared";

import "./StageBreakdownTabPanel.css";

export enum ETimeTrackingType {
	TotalStageTime = 1,
	TotalReconTime = 2
}

@track(
	{
		event_type: AnalyticsEventType.NAVIGATION,
		event_subtype: "stage-breakdown"
	},
	{ dispatchOnMount: true }
)
export class StageBreakdownTabPanelView extends React.Component<LMI.IStageBreakdownTabProps, LMI.IStageBreakdownTabState> {
	today = moment();
	oneYear = moment(new Date()).subtract(1, "year").startOf("day").toDate();

	constructor(props: LMI.IStageBreakdownTabProps) {
		super(props);

		this.state = {
			data: null,
			phases: null,
			startDate: this.oneYear,
			endDate: this.today.toDate(),
			recon_level_selected: -2,
			total_or_recon: ETimeTrackingType.TotalStageTime
		};
	}

	static getExistingMonths = breakdown => {
		const existingMonthsArrays = breakdown.map(r => {
			return r.months.map(m => m.month_year);
		});
		return mergeDedupe(existingMonthsArrays).sort(StageBreakdownTabPanelView.sortMonthYear);
	};

	static sortMonthYear = (a, b) => {
		a = a.split("-");
		b = b.split("-");
		return (new Date(a[1], parseInt(Months[a[0]], 10), 1) as any) - (new Date(b[1], parseInt(Months[b[0]], 10), 1) as any);
	};

	static formatGridData = (bd, props) => {
		const breakdown = { ...bd };
		const { recon_level_selected } = props;
		const defaultProp = { month: 0, count: 0, days: 0 };
		const existingMonthsTop = StageBreakdownTabPanelView.getExistingMonths(breakdown.average_days_by_recon_level);
		const existingMonthsBottom = StageBreakdownTabPanelView.getExistingMonths(breakdown.average_days_by_phase);
		const existingMonthsBottomFinal = StageBreakdownTabPanelView.getExistingMonths(breakdown.final_stage_breakdown);

		if (existingMonthsTop.length <= 0 && existingMonthsBottom.length <= 0) {
			breakdown["average_top_grid"] = [];
			breakdown["average_bottom_grid"] = [];
			breakdown["average_bottom_final"] = [];

			return breakdown;
		}

		// tslint:disable-next-line:one-variable-per-declaration
		let warningAvg = 0,
			criticalAvg = 0,
			divisor = 0;

		const average_days_by_recon_levels = breakdown.average_days_by_recon_level.map(r => {
			const warning = r.warning_threshold_days
				? {
						text: r.warning_threshold_days + " D",
						number: r.warning_threshold_days
				  }
				: null;
			const critical = r.error_threshold_days
				? {
						text: r.error_threshold_days + " D",
						number: r.error_threshold_days
				  }
				: r.error_threshold_hours
				? {
						text: r.error_threshold_hours + " H",
						number: r.error_threshold_hours
				  }
				: r.error_threshold_minutes
				? {
						text: r.error_threshold_minutes + " M",
						number: r.error_threshold_minutes
				  }
				: null;

			return Object.assign(
				{},
				...existingMonthsTop.map(rm => {
					if (warning && critical) {
						divisor++;
						warningAvg += warning.number;
						criticalAvg += critical.number;
					}
					const month = r.months.filter(m => m.month_year === rm)[0];
					return {
						category: `${r.recon_level === "Not Set - New" ? "New" : r.recon_level === "Not Set - Used" ? "Not Set" : r.recon_level}`,
						warning,
						critical,
						recon_level_id: r.recon_level_id,
						[rm]: month ? month.props : defaultProp
					};
				})
			);
		});

		const warnAvgCalc = (warningAvg > 0 ? warningAvg / divisor : 0).toFixed(0);
		const critAvgCalc = (criticalAvg > 0 ? criticalAvg / divisor : 0).toFixed(0);
		if (average_days_by_recon_levels.length > 0) {
			average_days_by_recon_levels[average_days_by_recon_levels.length - 1]["warning"] = {
				text: warnAvgCalc + " D",
				number: warnAvgCalc
			};
			average_days_by_recon_levels[average_days_by_recon_levels.length - 1]["critical"] = {
				text: critAvgCalc + " D",
				number: critAvgCalc
			};
		}
		const average_days_by_phases = breakdown.average_days_by_phase.map(p => {
			const warning = p.warning_threshold_days
				? {
						text: p.warning_threshold_days + " D",
						number: p.warning_threshold_days
				  }
				: p.warning_threshold_hours
				? {
						text: p.warning_threshold_hours + " H",
						number: p.warning_threshold_hours
				  }
				: p.warning_threshold_minutes
				? {
						text: p.warning_threshold_minutes + " M",
						number: p.warning_threshold_minutes
				  }
				: null;
			const critical = p.error_threshold_days
				? {
						text: p.error_threshold_days + " D",
						number: p.error_threshold_days
				  }
				: p.error_threshold_hours
				? {
						text: p.error_threshold_hours + " H",
						number: p.error_threshold_hours
				  }
				: p.error_threshold_minutes
				? {
						text: p.error_threshold_minutes + " M",
						number: p.error_threshold_minutes
				  }
				: null;

			return Object.assign(
				{},
				...existingMonthsBottom.map(pm => {
					const month = p.months.filter(m => m.month_year === pm)[0];
					return {
						category: `${p.phase_name ? p.phase_name : "Not Set"}`,
						warning,
						critical,
						phase_id: p.phase_id,
						final_stage: false,
						[pm]: month ? month.props : defaultProp
					};
				})
			);
		});

		const final_stage_breakdown = breakdown.final_stage_breakdown.map(p => {
			return Object.assign(
				{},
				...existingMonthsBottom.map(pm => {
					const month = p.months.filter(m => m.month_year === pm)[0];
					return {
						category: `${p.phase_name ? p.phase_name : "Not Set"}`,
						warning: null,
						critical: null,
						phase_id: p.phase_id,
						final_stage: true,
						[pm]: month ? month.props : defaultProp
					};
				})
			);
		});

		breakdown["average_top_grid"] = {
			existing_months: existingMonthsTop,
			data: [
				...StageBreakdownTabPanelView.calcAvgSum({
					records: average_days_by_recon_levels,
					existing_months: existingMonthsTop
				})
			]
		};
		breakdown["average_bottom_grid"] = {
			existing_months: existingMonthsBottom,
			data: [
				...StageBreakdownTabPanelView.calcAvgSum({
					records: average_days_by_phases,
					existing_months: existingMonthsBottom
				}),
				...StageBreakdownTabPanelView.calcAvgSum({
					records: final_stage_breakdown,
					existing_months: existingMonthsBottomFinal
				})
			]
		};
		breakdown["key"] = new Date().getTime();
		breakdown["recon_level_selected"] = recon_level_selected;

		return breakdown;
	};

	static calcAvgSum = ({ records, existing_months }) => {
		return records.map(a => {
			let daysSum = 0,
				countSum = 0,
				divisor = 0;

			existing_months.map(m => {
				divisor++;
				daysSum += a[m].days;
				if (a[m] && a[m].count) countSum += a[m].count;
			});

			return {
				...a,
				AVG: {
					count: countSum && divisor ? countSum / divisor : 0,
					days: !a.final_stage && daysSum > 0 && divisor ? daysSum / divisor : 0
				},
				SUM: { count: countSum, days: 0 }
			};
		});
	};

	handleRangeChange = (range: RangeEventArgs): void => {
		if (range.isInteracted) {
			if (range.startDate && range.endDate) {
				const startDate = range.startDate;
				const endDate = range.endDate;
				this.setState({ startDate, endDate }, async () => this.props.refetchBreakdown({ startDate, endDate }));
			} else {
				const today = moment();
				const oneYear = moment(new Date()).subtract(1, "year").startOf("day").toDate();
				this.props.refetchBreakdown({ startDate: oneYear, endDate: today });
			}
		}
	};

	handleDropdown = (event, { value }) => {
		const { startDate, endDate } = this.state;
		this.setState({ recon_level_selected: value }, () =>
			this.props.refetchBreakdown({
				startDate,
				endDate,
				reconLevelId: value === -2 ? null : value
			})
		);
	};

	getReconLevelOptions = recon_levels => {
		const options = recon_levels ? [...recon_levels] : [];
		options.unshift({ id: -2, name: "All Vehicles" });
		options.push({ id: 0, name: "Not Set" });
		options.push({ id: -1, name: "New" });
		return (
			options.length > 1 &&
			options.map(d => {
				return {
					key: d.id,
					text: d.name,
					value: d.id,
					content: <Header content={d.name} />
				};
			})
		);
	};

	handleTimeDropdown = (event, { value }) => {
		const total_or_recon = value;
		const { startDate, endDate } = this.state;
		const start = startDate ? startDate : moment(new Date()).subtract(1, "year").startOf("day").toDate();
		const end = endDate ? endDate : moment();

		this.setState({ total_or_recon }, () => {
			this.props.refetchBreakdown({
				storeId: parseInt(this.props.storeId, 10),
				startDate: start,
				endDate: end,
				timeTrackingType: total_or_recon
			});
		});
	};

	render() {
		const { data, phases, phasers, startDate, endDate, recon_level_selected, total_or_recon } = this.state;
		const { storeId, recon_levels } = this.props;
		if (!data || !phases || !phasers) return <Loader className={`loader active`} size="small" />;
		const reconLevelOptions = this.getReconLevelOptions(recon_levels);

		const gridProps: LMI.IStageBreakdownGridProps = {
			store_id: storeId,
			breakdown: data,
			phases,
			phasers,
			total_or_recon,
			tracking_path: this.props.tracking.getTrackingData().event_subtype
		};

		const inReportsTab = this.props.viewType.includes("sub-component") ? "" : "out";
		const { totalTimeTypes } = this.getTotalStageReconTimeProps();

		return (
			<span className={`with${inReportsTab}-tabs`}>
				<div className="stage-tools">
					<div className="beta-banner">
						<Popup
							hoverable
							position="bottom center"
							style={{ backgroundColor: "#fffaf3", color: "#573a08" }}
							trigger={<Icon link size="large" name="info circle" color="orange" />}
							content={
								<span>
									This report is currently in <strong>BETA</strong>. <br />
									If you see issues or have comments please contact us at{" "}
									<u>
										<a href="mailto:support@loopmein.app" target="_top">
											support@loopmein.app
										</a>
									</u>
								</span>
							}
						/>
					</div>

					<div className="total-time-dropdown">
						<Dropdown
							className="select-workflow"
							value={total_or_recon}
							placeholder="Choose Total Time"
							loading={!totalTimeTypes}
							selection
							options={totalTimeTypes}
							onChange={this.handleTimeDropdown}
						/>
					</div>
					<div className="workflow-dropdown">
						<Dropdown
							className="select-workflow"
							value={recon_level_selected}
							placeholder="Choose Workflow"
							loading={!recon_levels}
							selection
							options={reconLevelOptions}
							onChange={this.handleDropdown}
						/>
					</div>
					<div className="date-range-field">
						<DateRangePickerComponent
							maxDays={400}
							start="Year"
							depth="Year"
							placeholder="Select month range"
							format="MM/yyyy"
							max={moment().toDate()}
							startDate={startDate}
							endDate={endDate}
							allowEdit={false}
							strictMode={true}
							showClearButton={true}
							change={this.handleRangeChange}
						/>
					</div>
				</div>
				<StageBreakdownGrid {...gridProps} />
			</span>
		);
	}

	getTotalStageReconTimeProps() {
		return {
			totalTimeTypes: [
				{
					value: ETimeTrackingType.TotalStageTime,
					text: "Total Time",
					desc: "The time from when a vehicle is added to LoopMeIn until it reaches a finalized stage.  This includes all stages: both timed and un-timed"
				},
				{
					value: ETimeTrackingType.TotalReconTime,
					text: "Recon Times",
					desc:
						"The time from when a vehicle arrives in its first timed stage until it reaches a final stage.  This time only includes stages marked as 'include in recon timer'"
				}
			].map(({ value, text, desc }) => ({
				key: value,
				value,
				text,
				content: (
					<span className="time-type-options">
						<strong>{text}</strong>
						<br />
						<span className="sub">{desc}</span>
					</span>
				)
			}))
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const result = {};
		if (nextProps.phases) {
			if (nextProps.phases !== prevState.phases) {
				result["phases"] = nextProps.phases;
				result["phasers"] = nextProps.phasers;
			}
		}
		if (nextProps.stage_breakdown) {
			const formattedData = StageBreakdownTabPanelView.formatGridData(nextProps.stage_breakdown, prevState);
			if (formattedData !== prevState.data) {
				result["data"] = formattedData;
			}
		}
		return result;
	}
}

const mapStateToProps = (state: LMI.IAdminReduxGlobalState) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const StageBreakdownTabPanel = compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.stageBreakdown, {
		options: (props: any) => {
			const today = moment();
			const oneYear = moment(new Date()).subtract(1, "year").startOf("day").toDate();

			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					startDate: oneYear,
					endDate: today,
					timeTrackingType: ETimeTrackingType.TotalStageTime
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, stage_breakdown, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				stage_breakdown,
				refetchBreakdown: refetch
			};
		}
	}),
	graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.phases, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, dealer_phases, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				phases: dealer_phases.phases,
				phasers: dealer_phases.phasers,
				refetchPhases: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.reconLevels, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_recon_levels, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				recon_levels: store_recon_levels.recon_levels,
				refetchPhases: refetch
			};
		}
	})
)(StageBreakdownTabPanelView) as React.ComponentType<any>;

export default StageBreakdownTabPanel;
