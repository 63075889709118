import * as React from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { TCProps, TabsContainer } from "client/pages/admin/components/ReactTabsContainer";
import { changeSubtab, triggerUABCallback } from "api/redux/actions";

import { buildTabTarget } from "client/components/Menu/utils";
import SubTabContainers from "client/components/Routes/SubTabContainers";
import "./ListsTabPanel.css";

export class ListsTabPanelView extends React.Component<LMI.IListsTPProps, LMI.IListsTPState> {
	constructor(props) {
		super(props);

		this.state = {
			subRouteId: "contact-settings"
		};
	}

	render() {
		const { subRouteId } = this.state;
		const { permissions, topLevelStoreId, viewType } = this.props;
		const tcProps: TCProps = {
			subRouteId,
			subTabContainer: SubTabContainers.settings,
			permissions: permissions,
			classes: "lists-tab-panel-tabs",
			viewType: "lists-component",
			dataPassThru: { storeId: topLevelStoreId, orgId: parseInt(localStorage.getItem("selectedOrgId"), 10), storeType: viewType },
			onChange: (tabSelected: string) => this.selectedTab(tabSelected)
		};

		return (
			<div className="lists-tab-panel vert-tab-table-content">
				<TabsContainer {...tcProps} />
			</div>
		);
	}

	selectedTab(tab) {
		this.props.changeSubtab(tab);
		this.props.history.push(buildTabTarget(this.props.match.path, tab));
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return { subRouteId: nextProps.match ? nextProps.match.params.id : prevState.subRouteId };
	}

	componentWillUnmount() {
		this.props.changeSubtab(null);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		toggleUABCallback: state.app.admin.toggleUABCallback,
		topLevelStoreId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		universalActionCallback: () => {
			dispatch(triggerUABCallback(false));
		},
		changeSubtab: (tabName: string) => {
			dispatch(changeSubtab(tabName));
		}
	};
};

export const ListsTabPanel: any = compose(connect(mapStateToProps, mapDispatchToProps))(ListsTabPanelView);
export default ListsTabPanel;
