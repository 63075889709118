import * as React from "react";
import { Link } from "react-router-dom";
import { Icon, Image, Popup } from "semantic-ui-react";

type menuItemProps = {
	selected?: boolean;
	label: string;
	path: string;
	isNew: boolean;
	mouseEntered?: () => void;
	itemClicked?: () => void;
	actions?: LMI.IAppRouteMenuActions[];
	actionClicked?: (action: LMI.IAppRouteMenuActions) => void;
};

export function MenuItem({ selected, path, label, isNew, mouseEntered, itemClicked, actions, actionClicked }: menuItemProps) {
	return (
		<li className={`routes ${selected ? "selected" : ""}`} onMouseEnter={mouseEntered}>
			<Link to={path} onClick={() => itemClicked()}>
				{isNew && <Image src="/images/new-icon.svg" className="new-badge" />}
				{label}
			</Link>
			<span className="action-btns">
				{selected &&
					actions &&
					actions.length > 0 &&
					actions.map(action => (
						<Popup
							key={action.icon}
							className="action-pop"
							inverted
							size="tiny"
							content={action.text}
							trigger={<Icon name={action.icon} color={action.color} onClick={() => actionClicked(action)} />}
						/>
					))}
			</span>
		</li>
	);
}
