import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { addAlert, updateInventoryLog } from "api/redux/actions";
import { InspectionLineItemStatus, Validation } from "loopmein-shared";
import { restAPI } from "client/utils/rest";
import { handleErrorResponse, validateDto, zeroPlus } from "client/utils/functions";
import { InspectionService } from "./util";

import { Button, Confirm, Divider } from "semantic-ui-react";
import { Loading } from "client/components/Loading";
import { ModalComponent } from "../ModalComponent";
import { AlertPositions } from "../AlertComponent";
import { InspectionForm, InspectionItemsForm, InspectionNotes, InspectionItemTypeForm, ConfirmAddMultipleItems } from "./components";

import "./InventoryInspection.css";

export class InspectionComponentView extends React.Component<LMI.IReconInspectionPanelProps, LMI.IReconInspectionComponentState> {
	constructor(props: LMI.IReconInspectionPanelProps) {
		super(props);

		this.state = {
			confirm: null,
			confirm_decline: null,
			open_notes: false,
			add_type_item: null,
			has_warning_type_dups: false,
			confirm_add_multiple: null
		};
	}

	handleToggleNotes = ({ direction, elementId }) => {
		this.setState({ open_notes: direction !== undefined ? direction : !this.state.open_notes });
	};

	getReconLevel(str: string): string {
		const { recon_level } = this.props.inspection;
		const replacement = recon_level ? recon_level.name : "Default";
		return str.replace(new RegExp("{{reconLevel}}"), replacement);
	}

	getCatagories() {
		const { inspection_line_item_sections } = this.props;
		// TODO: get colors from the inspection_line_item_sections when the midtier is sending them and GQL is updated
		const colors = ["00aeef", "e3165b", "FFA500"];
		return inspection_line_item_sections
			? inspection_line_item_sections.sections.map((s, i) => ({ ...s, ...{ name: this.getReconLevel(s.name), color_code: colors[i] } }))
			: [];
	}

	render() {
		if (!this.props.inspection || this.props.loading) return <Loading />;

		const { inspection, recon_level } = this.props.inspection;
		const {
			inspection_line_item_sections,
			inspection_item_type_options,
			technicians,
			inspection_line_item_statuses,
			templates,
			refetchTemplates,
			readonly,
			permissions,
			enableItemPriceEditInRework
		} = this.props;

		const { confirm, open_notes, add_type_item, confirm_decline, confirm_add_multiple } = this.state;
		const statuses = inspection_line_item_statuses ? inspection_line_item_statuses.statuses : [];
		const modal_props = add_type_item || confirm_decline || confirm_add_multiple;
		const permits = InspectionService.getPermits({ readonly, permissions });

		return (
			<div key="form" id="reconInspection" className="recon-inspection-container">
				<Confirm
					key="confirm"
					{...{
						...{
							open: false,
							size: "tiny",
							header: "Please Confirm",
							content: "We need you to confirm.",
							cancelButton: "Cancel",
							confirmButton: "Confirm",
							onCancel: () => this.setState({ confirm: null }),
							onConfirm: () => console.log("Confirmed")
						},
						...confirm
					}}
				/>
				{modal_props && <ModalComponent {...modal_props} />}
				<div className="recon-form-container">
					<div className="forms main-form">
						<InspectionForm
							{...{
								inspection,
								technicians,
								updateInspection: data => this.updateInspection(data),
								...permits
							}}
						/>
					</div>
					<InspectionItemsForm
						{...({
							inspection,
							inspection_item_type_options,
							recon_level,
							statuses,
							catagories: this.getCatagories(),
							create_item: new_item => this.createInspectionItem(new_item),
							update_item: item => this.updateInspectionLineItem(item),
							update_status: data => this.updateItemStatus(data),
							add_item_option: (data, item_id) => this.addItemOption(data, item_id, inspection_line_item_sections.sections[0].id),
							delete_item: data => this.deleteItem(data),
							templates,
							refetchTemplates,
							load_template: template => this.confirmAddTemplateItems(template),
							enableItemPriceEditInRework,
							...permits
						} as LMI.IReconInspectionItemsFormProps)}
					/>
					<InspectionNotes
						{...{
							inspection,
							can_edit: permits.can_edit,
							is_open: open_notes,
							toggleOpen: direction => this.handleToggleNotes({ direction, elementId: "inspectionNotes" }),
							onAlert: alert => {
								this.props.addAlert({
									type: alert.type,
									message: alert.type === "danger" ? handleErrorResponse(alert.message) : alert.message,
									timeout: 3000
								});
								this.refreshInspectionData();
							},
							createInspection: async () => {
								try {
									const inpection: any = {};
									const newInspection = await this.createInspection(inpection);
									console.log("New Inspection for Notes", newInspection);
									this.refreshInspectionData();
								} catch (error) {
									console.log("There was an error creating inspection", error);
								}
							}
						}}
					/>
				</div>
			</div>
		);
	}

	getActions(statuses) {
		if (!statuses || statuses.length < 0) return [];
		return statuses.map((s, key) => ({
			key,
			text: s.name,
			value: s.id,
			label: { style: { background: `#${s.color_code}` }, empty: true, circular: true }
		}));
	}

	async actionSelected(status: number): Promise<void> {
		const allItems = this.props.inspection.inspection.inspection_line_items;
		const decision = { items: allItems.map(i => i.id), status };
		this.updateItemStatus(decision);
	}

	async approvedCompleted(): Promise<void> {
		const { inspection } = this.props.inspection;
		if (inspection && inspection.is_completed)
			this.setState(
				{
					confirm: null
				},
				() =>
					this.props.addAlert({
						type: "success",
						message: "This Inspection has already been submitted for approval.",
						timeout: 3000
					})
			);

		if (inspection && !inspection.is_completed)
			this.setState({
				confirm: {
					open: true,
					content: "Are you sure you would like to mark this inspection as complete and send it off for approval?",
					confirmButton: "Yes, send for approval",
					cancelButton: "No",
					onConfirm: () => {
						if (inspection)
							restAPI({
								endpointName: "completeInspection",
								urlArgs: [this.props.storeId, inspection.inventory_item_id, inspection.id],
								data: null,
								callback: (error, res) => {
									this.setState({
										confirm: null
									});
									this.props.addAlert({
										type: error ? "danger" : "success",
										message: error ? handleErrorResponse(error) : res.data.message,
										timeout: 3000
									});
									this.refreshInspectionData();
								}
							});
					}
				}
			});
	}

	async createInspection(inspection: LMI.IReconInspection): Promise<any> {
		return new Promise(async (resolve, reject) => {
			restAPI({
				endpointName: "newInspection",
				urlArgs: [this.props.storeId, this.props.inventory_item_id],
				data: inspection,
				callback: (error, res) => {
					if (error) reject(error);
					else resolve(res);
				}
			});
		});
	}

	async updateInspection(update: LMI.IReconInspection): Promise<void> {
		if (update) {
			// check if inspection exists and create one or if it doesn exist update the stored inspection
			if (!this.props.inspection || !this.props.inspection.inspection) {
				try {
					await this.createInspection(update);
					this.refreshInspectionData();
				} catch (error) {
					this.props.addAlert({
						type: "danger",
						message: handleErrorResponse(error),
						timeout: 3000
					});
				}
			} else {
				const { inspection } = this.props.inspection;
				if (inspection)
					restAPI({
						endpointName: "editInspection",
						urlArgs: [this.props.storeId, inspection.inventory_item_id, inspection.id],
						data: update,
						callback: error => {
							if (error) {
								this.props.addAlert({
									type: "danger",
									message: handleErrorResponse(error),
									timeout: 3000
								});
							}
						}
					});
			}
		}
	}

	async confirmAddTemplateItems(template: LMI.InspectionTemplate) {
		if (template && template.inspection_template_items && template.inspection_template_items.length > 0) {
			const item_duplicates = this.friskInspectionItemDups(template.inspection_template_items);
			this.setState({
				confirm_add_multiple: {
					size: item_duplicates && item_duplicates.length > 0 ? "small" : "mini",
					headerText: "Add Multiple Items?",
					shouldBeOpen: true,
					onClose: () => this.setState({ confirm_add_multiple: null }),
					contentComponent: () => {
						return (
							<ConfirmAddMultipleItems
								inspection_template_items={template.inspection_template_items}
								item_duplicates={item_duplicates}
								onConfirm={confirmed_items => this.setState({ confirm_add_multiple: null }, () => this.addItemsFromTemplate(confirmed_items))}
								onClose={() => this.setState({ confirm_add_multiple: null })}
							/>
						);
					}
				}
			});
		}
	}

	formatLineItems(items) {
		const newItemIds = items.map(ti => ti.inspection_item_option_id || ti.id);
		return this.props.inspection_item_type_options
			.filter(ito => newItemIds.includes(ito.id))
			.map(item => {
				return {
					inspection_item_option_id: item.id,
					inspection_line_item_section_id: item.inspection_line_item_section_id,
					parts: zeroPlus(item.parts),
					labor: zeroPlus(item.labor),
					hours: zeroPlus(item.hours)
				};
			});
	}

	async addItemsFromTemplate(inspection_template_items) {
		if (inspection_template_items && inspection_template_items.length > 0) {
			const newItems = this.formatLineItems(inspection_template_items);
			if (!this.props.inspection || !this.props.inspection.inspection) {
				try {
					const inpection: any = {};
					const create = await this.createInspection(inpection);
					const newInspection = create.data.inspection;
					if (newInspection) this.createInspectionLineItems(newItems, newInspection);
				} catch (error) {
					this.props.addAlert({
						type: "danger",
						message: handleErrorResponse(error),
						timeout: 3000
					});
				}
			} else this.createInspectionLineItems(newItems, this.props.inspection.inspection);
		}
	}

	async createInspectionItem(new_item: LMI.IInspectionLineItem): Promise<void> {
		const newItem = this.formatLineItems([new_item]);
		// check if inspection exists and create one with the line item or if it doesn exist create an item for the stored inspection
		if (!this.props.inspection || !this.props.inspection.inspection) {
			try {
				const inpection: any = {};
				const create = await this.createInspection(inpection);
				const newInspection = create.data.inspection;
				if (newInspection) this.createInspectionLineItems(newItem, newInspection);
			} catch (error) {
				this.props.addAlert({
					type: "danger",
					message: handleErrorResponse(error),
					timeout: 3000
				});
			}
		} else this.createInspectionLineItems(newItem);
	}

	async createInspectionLineItems(items: LMI.IInspectionLineItem[], inspection = this.props.inspection.inspection): Promise<void> {
		const validData = await validateDto(Validation.Auth.Dealer.Inspection.AddMultipleLineItems, { items });
		if (inspection && validData) {
			this.scanItemsForDupes(items);
			restAPI({
				endpointName: "add_inspection_line_item_multi",
				urlArgs: [this.props.storeId, inspection.id],
				data: validData,
				callback: error => {
					if (error) {
						this.props.addAlert({
							type: "danger",
							message: handleErrorResponse(error),
							timeout: 3000
						});
					}
					this.refreshInspectionData();
				}
			});
		}
	}

	async updateInspectionLineItem(item: LMI.IInspectionLineItem): Promise<void> {
		const { inspection } = this.props.inspection;
		if (inspection)
			restAPI({
				endpointName: "editInspectionLineItem",
				urlArgs: [this.props.storeId, inspection.inventory_item_id, inspection.id, item.id],
				data: item,
				callback: (error, res) => {
					if (error) {
						this.props.addAlert({
							type: "danger",
							message: handleErrorResponse(error),
							timeout: 3000
						});
					}
					this.refreshInspectionData();
				}
			});
	}

	async updateItemStatus({ items, status }): Promise<void> {
		if (items.length > 0) {
			const statusOpt = this.props.inspection_line_item_statuses.statuses.find(s => s.id === status);
			if (InspectionService.confirmStatuses.includes(statusOpt.id) && this.props.enableItemConfirm) this.getConfirmation({ items, status });
			else this.sendDecision(status, items);
			this.refreshInspectionData();
		}
	}

	getConfirmation({ items, status }): any {
		let content = "Set Item Status?";
		let needsDeclineConfirm = false;
		const setMultiple = items.length > 1;

		switch (status) {
			case InspectionLineItemStatus.Approved:
			case InspectionLineItemStatus.Completed:
				content = `Are you sure you want to ${status == InspectionLineItemStatus.Approved ? "approve" : "complete"} ${
					setMultiple ? `${items.length} remaining` : "this"
				} item${setMultiple ? "s" : ""}?`;
				break;
			case InspectionLineItemStatus.Declined:
				if (setMultiple) {
					content = "Do you want to decline all incomplete items or just those not approved?";
					needsDeclineConfirm = true;
				} else {
					this.sendDecision(status, items);
					return;
				}
				break;
			default:
				break;
		}

		if (needsDeclineConfirm)
			this.setState({
				confirm_decline: {
					size: "mini",
					headerText: "Please Confirm",
					shouldBeOpen: true,
					onClose: () => this.setState({ confirm_decline: null }),
					contentComponent: () => {
						return (
							<div id="confirmDecline">
								<span>{content}</span>
								<div className="confirm-options">
									<Divider />
									<div className="confirm-option-btns">
										<Button
											color="green"
											content="Not Completed"
											onClick={() => this.setState({ confirm_decline: null }, () => this.sendDecision(status, items, true))}
										/>
										<Button
											color="blue"
											content="Not Approved"
											onClick={() => this.setState({ confirm_decline: null }, () => this.sendDecision(status, items))}
										/>
									</div>
								</div>
							</div>
						);
					}
				}
			});
		else
			this.setState({
				confirm: {
					size: "mini",
					open: true,
					header: "Please Confirm",
					content,
					onConfirm: () => this.setState({ confirm: null }, () => this.sendDecision(status, items))
				}
			});
	}

	async sendDecision(status, line_item_ids, include_approved = false): Promise<void> {
		const { storeId } = this.props;
		const { inspection } = this.props.inspection;
		const setMultiple = line_item_ids.length > 1;

		let endpointName: string = "update_line_item_status";
		let urlArgs: any[] = [storeId, inspection.inventory_item_id, inspection.id, status];
		let data: any = { line_item_ids };

		if (setMultiple) {
			switch (status) {
				case InspectionLineItemStatus.Approved:
					endpointName = "approve_all_inspection_items";
					urlArgs = [storeId, inspection.inventory_item_id];
					data = null;
					break;
				case InspectionLineItemStatus.Declined:
					endpointName = "decline_all_inspection_items";
					urlArgs = [storeId, inspection.inventory_item_id];
					data = { include_approved };
					break;
				default:
					break;
			}
		}

		restAPI({
			endpointName,
			urlArgs,
			data,
			callback: (error, res) => {
				if (error) console.log(error);
				this.props.addAlert({
					type: error ? "danger" : "success",
					message: error ? handleErrorResponse(error) : res.data.message,
					timeout: 3000
				});
				this.refreshInspectionData();
			}
		});
	}

	async addItemOption(value: string, item_id: number, section_id: number): Promise<void> {
		const add_type_item: LMI.IModalProps = {
			size: "small",
			headerText: `Add OP Codes`,
			shouldBeOpen: true,
			onClose: () => this.setState({ add_type_item: null }),
			contentComponent: () => (
				<InspectionItemTypeForm
					{...{
						item: null,
						startingValue: value,
						inspection_item_options: {
							options: this.props.inspection_item_type_options
						},
						onSave: itemData => this.addItemTypeOption(itemData, item_id, section_id)
					}}
				/>
			)
		};
		this.setState({ add_type_item });
	}

	async addItemTypeOption(newItemType, item_id, inspection_line_item_section_id) {
		restAPI({
			endpointName: "add_inspection_item_option",
			urlArgs: [this.props.storeId],
			data: InspectionService.formatInspectionTypeItemOption(newItemType),
			callback: (err, res) => {
				const inspection_item_option = res.data.option;
				this.setState({ add_type_item: null }, () => {
					this.props.refetchInspectionOptions();
					if (item_id) {
						const { inspection } = this.props.inspection;
						const item = inspection.inspection_line_items.find(i => i.id === item_id);
						this.updateInspectionLineItem({ ...item, ...{ inspection_item_option, inspection_item_option_id: inspection_item_option.id } });
					} else {
						this.createInspectionLineItems(
							[
								{
									inspection_item_option_id: inspection_item_option.id,
									inspection_line_item_section_id: inspection_item_option.inspection_line_item_section_id,
									parts: inspection_item_option.parts ? inspection_item_option.parts : 0,
									labor: inspection_item_option.labor ? inspection_item_option.labor : 0,
									hours: inspection_item_option.hours ? inspection_item_option.hours : 0
								}
							],
							this.props.inspection.inspection
						);
					}
				});
			}
		});
	}

	async deleteItem(item: LMI.IInspectionLineItem): Promise<void> {
		const { inspection } = this.props.inspection;
		if (inspection)
			restAPI({
				endpointName: "deleteInspectionLineItem",
				urlArgs: [this.props.storeId, inspection.inventory_item_id, inspection.id, item.id],
				data: null,
				callback: error => {
					if (error) {
						console.log(error);
						this.props.addAlert({
							type: "danger",
							message: handleErrorResponse(error),
							timeout: 3000
						});
					}
					this.refreshInspectionData();
				}
			});
	}

	scanItemsForDupes(items) {
		const { inspection } = this.props.inspection;
		if (inspection && inspection.inspection_line_items && inspection.inspection_line_items.length > 0) {
			const currentlyUsedOptionIds = inspection.inspection_line_items.map(i => i.inspection_item_option_id);
			const existing = items.filter(item => currentlyUsedOptionIds.includes(item.inspection_item_option_id));
			if (existing.length > 0)
				this.props.addAlert({
					type: "warning",
					message: "This inspection has duplicate items",
					position: AlertPositions.Top,
					timeout: 3000,
					fancy: true
				});
		}
	}

	friskInspectionItemDups(new_items): LMI.IInspectionLineItem[] {
		const { inspection } = this.props.inspection;
		if (inspection && inspection.inspection_line_items && inspection.inspection_line_items.length > 0) {
			return inspection.inspection_line_items.filter(i => {
				const itemIndex = new_items.findIndex(item => item.inspection_item_option_id == i.inspection_item_option_id);
				return itemIndex > -1;
			});
		} else return [];
	}

	refreshInspectionData() {
		this.props.refetchInspection();
		this.props.updateInventoryLog();
	}

	shouldComponentUpdate(nextProps: any) {
		if (nextProps.inspection) return true;
		else return false;
	}
}

const mapStateToProps = state => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		updateInventoryLog: () => {
			dispatch(updateInventoryLog(new Date().toString()));
		},
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const InventoryInspection = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inspection, {
		options: (props: LMI.IReconInspectionPanelProps) => {
			return {
				variables: {
					storeId: props.storeId,
					inventoryItemId: props.inventory_item_id
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inspection, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				inspection,
				refetchInspection: refetch
			};
		}
	}),
	graphql<LMI.InspectionItemOptionsGQL, any, any, ClassAttributes<any>>(gqlQueries.dealership.inspectionItemOptions, {
		options: (props: LMI.IReconInspectionPanelProps) => {
			return {
				variables: {
					storeId: props.storeId
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inspection_item_options, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				inspection_item_type_options: inspection_item_options.options,
				refetchInspectionOptions: refetch
			};
		}
	}),
	graphql<LMI.IReconInspectionSectionsGQL, any, any, ClassAttributes<any>>(gqlQueries.dealership.inspectionLineItemSections, {
		options: () => {
			return {
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inspection_line_item_sections, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				inspection_line_item_sections,
				refetchSections: refetch
			};
		}
	}),
	graphql<LMI.IEmployeeByJobTitleGQL, any, any, ClassAttributes<any>>(gqlQueries.employeesByJobTitle, {
		options: (props: LMI.IReconInspectionPanelProps) => {
			return {
				storeId: props.storeId,
				jobTitleIds: props.jobTitleIds ? props.jobTitleIds : [],
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employees_with_job_titles, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				technicians: {
					technicians: employees_with_job_titles.employees
				},
				refetchTechnicians: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inspectionLineItemStatuses, {
		options: () => {
			return {
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inspection_line_item_statuses, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				inspection_line_item_statuses,
				refetchStatuses: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inspectionTemplates, {
		options: props => {
			return {
				storeId: props.storeId,
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inspection_templates, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				templates: inspection_templates.templates,
				refetchTemplates: refetch
			};
		}
	})
)(InspectionComponentView) as React.ComponentType<any>;

export default InventoryInspection;
