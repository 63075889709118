import { graphql } from "@apollo/react-hoc";
import RenderDataGrid, { DataGridProps, GridColumnHeaders, GridToolOptions } from "client/components/DataGrid/DataGrid";
// node_modules

import { gqlQueries, gqlSubscriptions } from "gql-imports";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { Icon, Loader } from "semantic-ui-react";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import { filterDataSet, objectArrayDiff, RelativeDatePipe } from "client/utils/functions";
import { MapsDialog } from "../../../../../../components/MapDialog";
import { SaniDetailPanel } from "./SaniDetailPanel";
import { NotificationService } from "../../../../../../utils/notification";
import { RowTimer } from "./RowTimer";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

import "./ActiveTabPanel.css";

@track(
	props => {
		return {
			event_type: AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}active`
		};
	},
	{ dispatchOnMount: true }
)
export class ActiveTabPanelView extends React.Component<LMI.ISaniTabProps, LMI.ISaniTabState> {
	grid: GridComponent;
	abortController = new AbortController();
	filterIsSet: boolean = false;
	searchKey: string = "";

	constructor(props: LMI.ISaniTabProps) {
		super(props);

		const notificationsEnabled = localStorage.getItem("sani_notifications_enabled");

		this.state = {
			data: null,
			sub_id: null,
			search_is_set: false,
			searchData: [],
			show_lot_location: false,
			detail: null,
			notifications_enabled: notificationsEnabled ? notificationsEnabled === "true" : false
		};
	}

	calculateElapsedMinimum({ created_at, useFull = true }) {
		const elapsedTime = new Date().getTime() - +created_at;

		let ms = elapsedTime;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = Math.floor(ms / 24);

		if (days > 0) return days + ` D${useFull ? `ay${days > 1 ? "s" : ""}` : ""}`;
		if (hours > 0) return hours + ` H${useFull ? `our${hours > 1 ? "s" : ""}` : ""}`;
		if (minutes > 0) return minutes + ` M${useFull ? `inute${minutes > 1 ? "s" : ""}` : ""}`;
		if (seconds > 0) return `<1 M${useFull ? `inute${minutes > 0 ? "s" : ""}` : ""}`;

		return `<1 M${useFull ? `inute${minutes > 0 ? "s" : ""}` : ""}`;
	}

	getProgressTemplate = props => {
		const done = props.percent_complete >= 100;
		const elapsed = this.calculateElapsedMinimum({ created_at: +props.created_at_raw });
		const rowProps = {
			settings: this.props.store_settings,
			is_completed: done,
			created_at_raw: +props.created_at_raw,
			percent_complete: props.percent_complete,
			elapsed,
			key: props.created_at_raw
		};

		return <RowTimer {...rowProps} />;
	};

	locationSortComparer = (reference, comparer) => {
		if (reference.latitude) return -1;
		if (comparer.latitude) return 1;
		return 0;
	};

	static formatGridData = requests => {
		return requests
			.sort((a, b) => {
				if (+a.created_at > +b.created_at) return 1;
				if (+a.created_at < +b.created_at) return -1;
			})
			.map((request, index) => {
				const {
					inventory_item,
					sanitation_request_items,
					completed_at,
					created_at,
					created_by_user,
					completed_by_emp,
					lead_name,
					id,
					reference_number
				} = request;
				const details = sanitation_request_items.sort((a, b) => {
					return a.sort_order === b.sort_order ? 0 : +(a.sort_order > b.sort_order) || -1;
				});
				const percent_complete = (details.filter(d => !!d.completed_by_emp).length / details.length) * 100;
				const steps = details.length;
				return {
					index,
					id,
					year_make_model: inventory_item.year_make_model,
					vin: inventory_item.vin.substr(-8),
					stock_number: inventory_item.stock_number,
					photo: `${inventory_item.primary_photo_full_url}`,
					location: { latitude: inventory_item.latitude, longitude: inventory_item.longitude },
					completed: completed_at ? RelativeDatePipe(+completed_at, false, true) : null,
					completed_by: completed_by_emp ? completed_by_emp.user.full_name : null,
					created_at: created_at ? RelativeDatePipe(+created_at, false, true) : null,
					created_at_raw: created_at,
					created_by: created_by_user.full_name,
					percent_complete: Math.floor(percent_complete),
					lead_name,
					steps,
					details,
					reference_number
				};
			});
	};

	createGridColumns(data) {
		const defaultProps = {
			textAlign: "left",
			allowFiltering: true,
			allowSorting: true
		};

		const headerData: any = [
			{
				...defaultProps,
				id: "year_make_model",
				headerText: "Year Make Model",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "vin",
				headerText: "Vin",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "stock_number",
				headerText: "Stock",
				textAlign: "center",
				width: "35"
			},
			{
				...defaultProps,
				id: "lead_name",
				headerText: "Lead",
				textAlign: "left",
				width: "55",
				clipMode: "EllipsisWithTooltip"
			},
			{
				...defaultProps,
				id: "created_at_raw",
				headerText: "Created",
				textAlign: "left",
				allowFiltering: false,
				width: "70",
				cellAttrs: {
					template: props => (
						<span>
							<div>{props.created_by}</div>
							<div className="grey">{props.created_at}</div>
						</span>
					)
				}
			},
			{
				...defaultProps,
				id: "percent_complete",
				headerText: "Progress",
				textAlign: "left",
				width: "90",
				cellAttrs: {
					template: props => this.getProgressTemplate(props)
				}
			},
			{
				...defaultProps,
				id: "location",
				headerText: "Location",
				textAlign: "center",
				width: "35",
				allowFiltering: false,
				sortComparer: this.locationSortComparer,
				cellAttrs: {
					// attributes: { class: "location" },
					template: props =>
						props && props.location.latitude ? (
							<Icon
								id="location"
								style={{ opacity: "1!important", fontSize: "20px" }}
								name="map marker alternate"
								link
								onClick={() => this.setState({ detail: props, show_lot_location: true })}
							/>
						) : (
							<></>
						)
				}
			},
			{
				...defaultProps,
				id: "reference_number",
				headerText: "Ref#",
				width: "35",
				textAlign: "center"
			}
		];

		const headerList = ["percent_complete", "year_make_model", "vin", "stock_number", "reference_number", "created_at_raw", "lead_name", "location"];

		return GridColumnHeaders(data, headerData, headerList);
	}

	filterDataSet = searchString => {
		// Set the filter set to all records for local filter
		const searchData = this.state.data
			.filter(i => filterDataSet({ searchString, item: i }))
			.map(item => {
				return item;
			});

		this.setState({ searchData, search_is_set: true }, () => {
			this.grid.searchSettings.key = searchString;
		});

		this.filterIsSet = true;
	};

	resetFilterRanges = () => {
		this.setState({ searchData: [], search_is_set: false });
		this.filterIsSet = false;
		this.searchKey = "";
		this.grid.getContent().children[0].scrollTop = 0;
	};

	setGrid(grid) {
		this.grid = grid;
		setTimeout(() => this.grid.selectRow(0), 500);
	}

	handleSelection = detail => {
		this.props.tracking.trackEvent({
			event_type: AnalyticsEventType.ACTION,
			event_subtype: `${this.props.tracking_path ? this.props.tracking_path + "." : ""}handleSelection`,
			data: { details: { vin: detail.vin, year_make_model: detail.year_make_model, stock_number: detail.stock_number } }
		});

		this.setState({ detail, selected_index: detail.index });
	};

	handleToggleNotifications = value => {
		this.setState({ notifications_enabled: value }, () => localStorage.setItem("sani_notifications_enabled", value.toString()));
	};

	render() {
		const { data, detail, notifications_enabled } = this.state;
		const { loading, storeId, permissions, store_settings, onNoResults, sanitation_sub } = this.props;

		const detail_open = !!detail; // Always open if a row is selected

		if (!data || loading) return <Loader active />;

		const detailProps = {
			sanitation_sub,
			permissions,
			store_id: storeId,
			store_settings,
			detail,
			onRefetch: this.props.refetchRequests
		};

		return (
			<div id="sani-active-list" className="grid-container">
				<MapsDialog
					{...{
						open: this.state.show_lot_location,
						title: this.state.detail && `Lot Location - ${this.state.detail.year_make_model}`,
						location:
							this.state.detail && this.state.detail.location.latitude && this.state.detail.location.longitude
								? {
										latitude: this.state.detail.location.latitude,
										longitude: this.state.detail.location.longitude
								  }
								: null,
						actions: [],
						onClose: () => this.setState({ show_lot_location: false })
					}}
				/>
				<div className={`sani-grid ${detail_open ? "detail-open" : ""}`}>
					{data.length > 0 ? (
						<RenderDataGrid
							{...({
								source_data: data,
								data: this.state.search_is_set ? this.state.searchData : data,
								columns: this.createGridColumns(data),
								setGrid: grid => this.setGrid(grid),
								onSelect: e => this.handleSelection(e),
								filterDataSet: str => this.filterDataSet(str),
								onReset: () => this.resetFilterRanges(),
								notifications_enabled,
								onToggleNotifications: value => this.handleToggleNotifications(value),
								tools: [
									GridToolOptions.selection,
									GridToolOptions.sorting,
									GridToolOptions.filter,
									GridToolOptions.search,
									GridToolOptions.detailRow,
									GridToolOptions.summary,
									GridToolOptions.notification
								],
								textwrap: true
							} as DataGridProps)}
						/>
					) : (
						onNoResults({ message: "There are no Active Sanitization Requests at this time." })
					)}
				</div>
				{detail && (
					<div id="SaniDetailWrapper" className="sani-detail">
						<SaniDetailPanel {...detailProps} />
					</div>
				)}
			</div>
		);
	}

	subscriptionRefresh = async () => {
		let index = this.state.selected_index ? this.state.selected_index : 0;
		this.grid.clearSelection();
		await this.props.refetchRequests();

		let dataRows = 0;
		try {
			dataRows = this.grid.getDataRows() as any;
		} catch {
			console.warn("There are no rows left.");
		}

		if (!dataRows) {
			this.setState({ detail: null });
			return false;
		}

		if (!this.state.data[index]) index -= 1;
		setTimeout(() => this.grid.selectRow(index), 800);
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevState.sub_id !== this.state.sub_id) {
			this.subscriptionRefresh();
		}

		// On refetch what changed?  Should we notify the user of the change?
		if (this.state.notifications_enabled) this.considerSendingNotification(prevProps);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.sanitation_sub && nextProps.sanitation_sub.id !== prevState.sub_id) {
			return { sub_id: nextProps.sanitation_sub.id };
		} else if (nextProps.sanitation_requests) {
			const formattedData = ActiveTabPanelView.formatGridData(nextProps.sanitation_requests.requests);
			if (formattedData !== prevState.data) {
				return { data: formattedData };
			}
		}
		return null;
	}

	considerSendingNotification = prevProps => {
		const requests = prevProps.sanitation_requests && prevProps.sanitation_requests.requests;
		const currentRequests = this.props.sanitation_requests && this.props.sanitation_requests.requests;
		if (!currentRequests) return;
		if (requests && requests.length > currentRequests.length) {
			const diff = objectArrayDiff(currentRequests, requests);
			const vin = requests[diff].inventory_item.vin;
			NotificationService.sendNotification({
				type: "remove_request",
				description: `Sanitization completed (vin: ${vin.substr(-8)})`
			});
		} else if (requests && requests.length < currentRequests.length) {
			const diff = objectArrayDiff(requests, currentRequests);
			const vin = currentRequests[diff].inventory_item.vin;
			NotificationService.sendNotification({
				type: "add_request",
				description: `Sanitization requested (vin: ${vin.substr(-8)})`
			});
		}
	};

	componentWillUnmount(): void {
		this.abortController.abort();
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const ActiveTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IDealerPhasesSubProps, any, any, ClassAttributes<any>>(gqlSubscriptions.dealer.sanitation, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				}
			};
		},
		props: ({ data: { error, loading, sanitation } }): any => {
			if (loading) return { subs_loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				sanitation_sub: sanitation.result
			};
		}
	}),
	graphql<LMI.ISaniQueryGQL, any, any, ClassAttributes<any>>(gqlQueries.dealership.sanitationRequests, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					openOnly: true
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, sanitation_requests, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				sanitation_requests,
				refetchRequests: refetch
			};
		}
	}),
	graphql<LMI.IOrgSettingsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.settings.store, {
		skip: (ownProps: any) => !ownProps.storeId,
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					names: ["SANITIZATION_WARNING_MINUTES", "SANITIZATION_ERROR_MINUTES"]
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_settings, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				store_settings,
				refetch
			};
		}
	})
)(ActiveTabPanelView) as React.ComponentType<any>;

export default ActiveTabPanel;
