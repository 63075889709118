import track from "react-tracking";
import { SearchComponent } from "client/pages/admin/components/SearchComponent";

import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
// components
import { Dropdown, Grid } from "semantic-ui-react";
import { EmployeeListComponent } from "./components";
import { AnalyticsEventType } from "loopmein-shared";

import "./EmployeesTabPanel.css";

@track(
	props => {
		return {
			event_type: AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}employees`
		};
	},
	{ dispatchOnMount: true }
)
export class EmployeesTabPanelView extends React.Component<LMI.IEmployeesTPProps, LMI.IEmployeesTPState> {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			searchFilter: null,
			selectEmployee: null,
			toggleActive: false,
			activeFilter: "Active"
		};
	}

	renderTools() {
		const searchProps: LMI.ISearchProps = {
			placeholder: "Search Employees",
			onSubmit: data => this.setState({ searchFilter: data.term }),
			onEmpty: () => this.setState({ searchFilter: null }),
			tracking_path: this.props.tracking.getTrackingData().event_subtype
		};
		const activeFilterOptions = ["All", "Active", "Inactive"].map((v, i) => ({ key: i, text: v, value: v }));

		return (
			<Grid className="tools">
				<Grid.Column width={4}>
					<SearchComponent {...searchProps} />
				</Grid.Column>
				<Grid.Column width={3} className="align-bottom">
					<span className="filter-active align-right">
						Show{" "}
						<Dropdown
							inline
							options={activeFilterOptions}
							value={this.state.activeFilter}
							onChange={(e, data) => {
								this.setState({ activeFilter: data.value });
							}}
						/>
					</span>
				</Grid.Column>
			</Grid>
		);
	}

	render() {
		const listProps = Object.assign({}, this.props, {
			searchFilter: this.state.searchFilter ? this.state.searchFilter : null,
			activeFilter: this.state.activeFilter
		});

		return (
			<div className="employeeFilter panel-content">
				{this.renderTools()}
				<EmployeeListComponent {...listProps} />
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const EmployeesTabPanel = compose(connect(mapStateToProps, mapDispatchToProps))(EmployeesTabPanelView) as React.ComponentType<any>;

export default EmployeesTabPanel;
