import { graphql } from "@apollo/react-hoc";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import { gqlQueries, gqlSubscriptions } from "gql-imports";
import RenderDataGrid, { DataGridProps, GridColumnHeaders, GridToolOptions } from "client/components/DataGrid/DataGrid";
import { Loading } from "client/components/Loading";
import { promiseBase64 } from "client/utils/fileHandling";
import { diffObjects, filterDataSet, formatCurrency, isEmptyObject, objectArrayDiff, removeNullProps } from "client/utils/functions";
import { hasPermission } from "client/utils/userAccess";
import { flowRight as compose } from "lodash";
import { Permission, TaskStatus } from "loopmein-shared";
import moment from "moment";
import * as React from "react";
import { ClassAttributes } from "react";
import "react-image-lightbox/style.css";
import { connect } from "react-redux";
import { Icon, Label, Popup } from "semantic-ui-react";
import { NotificationService } from "../../../../../../../utils/notification";
import InventoryLocationPinDialog from "../../../InventoryTabPanel/Components/InventoryLocationPinDialog";
import TaskDetailComponent from "../TasksDetailComponent/TasksDetailComponent";
import * as Raven from "raven-js";
import ActionMenuComponent from "../ActionMenuComponent/ActionMenuComponent";
import StoreAssignForm from "../StoreAssignFormComponent/StoreAssignFormComponent";
import TaskPhotoComponent from "../TaskPhotoComponent/TaskPhotoComponent";
import { addAlert } from "api/redux/actions";
import { restAPI } from "../../../../../../../utils/rest";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { Session } from "client/utils/session";

export class TasksListView extends React.Component<LMI.ITaskListProps, LMI.ITaskListState> {
	grid: GridComponent;
	filterIsSet: boolean = false;
	holdTasks = [];
	actionItems = [];
	actions = [
		{ action: "Complete", permission: Permission.COMPLETE_TASK },
		{ action: "Decline", permission: Permission.DECLINE_TASK },
		{ action: "Add Note", permission: Permission.ADD_WORK_LOG },
		{ action: "Delete Task", permission: Permission.REMOVE_TASK }
	];

	constructor(props: LMI.ITaskListProps) {
		super(props);

		const notificationsEnabled = localStorage.getItem("task_notifications_enabled");

		this.state = {
			isVendor: props.viewType.includes("vendors"),
			reload: null,
			complete: null,
			actionModal: null,
			searchData: [],
			search_is_set: false,
			sub_id: null,
			detail_open: false,
			detail: null,
			update_key: "update",
			notifications_enabled: notificationsEnabled ? notificationsEnabled === "true" : false,
			viewTypeStoreId: props.storeId
		};

		this.setupActions();
	}

	shouldComponentUpdate(nextProps: any, nextState: any) {
		let result = false;
		if (this.state !== nextState) {
			result = true;
		}
		if (this.props !== nextProps) {
			const diff = diffObjects(this.props, nextProps);
			if (diff !== "task_view_sub" && diff !== "sub_loading") result = true;
		}

		return result;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.showCompleted !== this.props.showCompleted) this.setState({ detail: null, detail_open: false });
		if (!this.state.search_is_set && prevState.sub_id !== this.state.sub_id) {
			this.props.taskRefetch();
		}

		// On refetch what changed?  Should we notify the user of the change?
		if (this.state.notifications_enabled) this.considerSendingNotification(prevProps);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		// when the component is loaded as a sub-component use the vendor id instead of the store id for CRUD calls
		// https://github.com/JointSoft/loopmein-web/issues/1223
		const storeId = {
			viewTypeStoreId: nextProps.viewType.includes("vendors sub-component") ? nextProps.vendorId : nextProps.storeId
		};

		if (nextProps.task_view_sub && nextProps.task_view_sub.id !== prevState.sub_id) {
			return { sub_id: nextProps.task_view_sub.id, ...storeId };
		}
		return storeId;
	}

	considerSendingNotification = (prevProps: LMI.ITaskListProps) => {
		try {
			if (prevProps.tasks && this.props.tasks && prevProps.tasks.length > this.props.tasks.length) {
				const diff = objectArrayDiff(this.props.tasks, prevProps.tasks);
				const task: any = prevProps.tasks[diff];
				if (task) {
					const service = task.service.name;
					const stock = task.inventory_item.stock_number;
					NotificationService.sendNotification({
						type: "remove_task",
						service,
						description: `Task removed for stock#: ${stock}`
					});
				}
			} else if (prevProps.tasks && this.props.tasks && prevProps.tasks.length < this.props.tasks.length) {
				const diff = objectArrayDiff(prevProps.tasks, this.props.tasks);
				const nTask: any = this.props.tasks[diff];
				if (nTask) {
					const service = nTask.service.name;
					const stock = nTask.inventory_item.stock_number;
					NotificationService.sendNotification({
						type: "add_task",
						service,
						description: `Task added for stock#: ${stock}`
					});
				}
			}
		} catch (error) {
			console.log("There was an error sending browser notification:", error);
			Raven.captureException(error);
			console.error(error);
		}
	};

	handleToggleNotifications = value => {
		this.setState({ notifications_enabled: value }, () => localStorage.setItem("task_notifications_enabled", value.toString()));
	};

	setupActions() {
		const actions = this.friskActions(this.actions);
		// maps as text for syncfusion DropDownButtonComponent (add icons here)
		this.actionItems = actions
			.filter(i => i.include)
			.map(a => ({
				text: a.action,
				iconCss: this.getActionItemIconClass(a)
			}));
	}

	friskActions(actions) {
		return actions.map(a => {
			const action = a.action;
			const include = hasPermission(this.props.permissions, a.permission, Session.get("isSuperUser"));
			return { action, include };
		});
	}

	getActionItemIconClass(a) {
		return a.action === "Complete" ? "icon check circle" : a.action === "Decline" || a.action === "Delete Task" ? "icon times circle" : "icon quote left";
	}

	getCleanTotal(data) {
		return data && Array.isArray(data) ? data.length : 0;
	}

	handleGrouping = mode => {
		console.log("Grouping", mode);
		if (mode === "actionBegin") {
			const searchData = this.mapTasks(this.props.tasks, false, true);
			this.setState({ searchData, search_is_set: true });
		}
	};

	handleUngrouping = mode => {
		console.log("Ungrouping", mode);
		if (mode === "actionBegin") {
			this.resetFilterRanges();
		}
	};

	render() {
		const props = this.props;
		if (props.loading) {
			return <Loading />;
		}

		const { isVendor, searchData, search_is_set, detail, detail_open, update_key, notifications_enabled } = this.state;
		const tasks = props.tasks ? this.mapTasks(props.tasks, isVendor, false) : [];
		const gridData = search_is_set ? searchData : tasks;
		const selectedRowIndex = detail ? gridData.findIndex(i => i.id === detail.id) : -1;

		const detailProps = {
			detail,
			onClose: () => this.setState({ detail: null, detail_open: false }),
			onSelect: detail => this.handleSelection(detail),
			...props
		};

		return (
			<div id="task-list" className="grid-container">
				<div className={`task-grid ${detail_open ? "detail-open" : ""}`}>
					{props.tasks?.length ? this.getCustomFilters() : ""}
					<RenderDataGrid
						{...({
							source_data: new DataManager(props.tasks).executeLocal(new Query()),
							total: this.getCleanTotal(props.tasks),
							update_key,
							data: gridData,
							selectedRowIndex,
							cancelSelectionColumns: true,
							columns: this.createGridColumns(gridData),
							setGrid: grid => this.setGrid(grid),
							filterDataSet: str => this.filterDataSet(str),
							onSelect: e => this.handleSelection(e),
							onReset: () => this.resetFilterRanges(),
							onGrouping: mode => this.handleGrouping(mode),
							onUngrouping: mode => this.handleUngrouping(mode),
							notifications_enabled,
							onToggleNotifications: value => this.handleToggleNotifications(value),
							tools: [
								GridToolOptions.grouping,
								GridToolOptions.sorting,
								GridToolOptions.filter,
								GridToolOptions.search,
								GridToolOptions.selection,
								GridToolOptions.notification,
								GridToolOptions.summary,
								GridToolOptions.infiniteScroll
							],
							textwrap: true,
							noresults: {
								icon: "truck",
								text: "Currently no tasks have been created.",
								action: {
									text: "Add Task"
								}
							}
						} as DataGridProps)}
					/>
				</div>
				{detail_open && (
					<div id="TaskDetailWrapper" className="task-detail">
						<TaskDetailComponent {...detailProps} />
					</div>
				)}
			</div>
		);
	}

	handleSelection(detail) {
		this.setState({ detail, detail_open: true });
	}

	mapTasks(tasks: any[], isVendor: boolean, allRecords = false) {
		this.holdTasks = tasks.map(task => {
			const { statusColor, taskStatus } = this.getTaskStatus(task);
			return Object.assign({}, task, {
				created: task.created_at,
				taskStatus,
				statusColor,
				stock: task.inventory_item.stock_number,
				vin: task.inventory_item.vin,
				task: task.service.name,
				comment: task.comment,
				storeName: isVendor ? task.store.name : task.assigned_vendor ? task.assigned_vendor.name : "Unassigned",
				YMM: task.inventory_item.year_make_model,
				assignedEmployee: task.assigned_employee && task.assigned_employee.user ? task.assigned_employee.user.full_name : "Unassigned",
				stage: task.inventory_item.phase ? task.inventory_item.phase.name : "Not in Stage",
				amount: task.cost > 0 ? formatCurrency(task.cost, 2) : "N/A",
				flagged_at: task.inventory_item.flagged_at,
				location: task.inventory_item.latitude ? true : false,
				action: true,
				sold: !task.inventory_item.is_active
			});
		});

		return this.holdTasks;
	}

	getCustomFilters = () => {
		const { showCompleted } = this.props;
		return (
			<div className="custom-toolbar">
				<div id="text" className="e-btn-group">
					<input
						type="radio"
						id="left"
						name="align"
						value="left"
						checked={!showCompleted}
						onChange={() =>
							this.setState({ update_key: `update${!showCompleted}` }, () => {
								this.grid.clearFiltering();
								this.resetFilterRanges();
								this.props.toggleStatus();
							})
						}
					/>
					<label className="e-btn" htmlFor="left">
						Incomplete
					</label>
					<input
						type="radio"
						id="right"
						name="align"
						value="right"
						checked={showCompleted}
						onChange={() =>
							this.setState({ update_key: `update${!showCompleted}` }, () => {
								this.grid.clearFiltering();
								this.resetFilterRanges();
								this.props.toggleStatus();
							})
						}
					/>
					<label className="e-btn" htmlFor="right">
						Completed
					</label>
				</div>
			</div>
		);
	};

	resetFilterRanges = () => {
		this.setState({ searchData: [], search_is_set: false });
		this.filterIsSet = false;
		this.grid.getContent().children[0].scrollTop = 0;
		this.props.taskRefetch();
	};

	getTaskStatus(task) {
		let statusColor;
		let taskStatus = task.task_status.name;
		switch (task.task_status.id) {
			case TaskStatus.Unassigned:
				statusColor = "yellow";
				break;
			case TaskStatus.Assigned:
				statusColor = null;
				break;
			case TaskStatus.Completed:
				statusColor = "green";
				break;
			case TaskStatus.Approved:
				statusColor = "teal";
				break;
			default:
				statusColor = null;
		}
		if (task.is_rejected) {
			statusColor = "red";
			taskStatus = task.task_status.id === TaskStatus.Assigned ? "Rejected" : "Declined";
		}
		return { statusColor, taskStatus };
	}

	filterDataSet(searchString) {
		// Set the filter set to all records for local filter
		const searchData = this.mapTasks(this.props.tasks, false, true)
			.filter(i => filterDataSet({ searchString, item: i }))
			.map(item => {
				return item;
			});

		this.setState({ searchData, search_is_set: true }, () => {
			this.grid.searchSettings.key = searchString;
		});

		this.filterIsSet = true;
	}

	setGrid(grid) {
		this.grid = grid;
	}

	createGridColumns(tasks) {
		const { showCompleted } = this.props;
		const defaultProps = {
			width: "45",
			textAlign: "left",
			allowFiltering: true
		};

		const headerData: any = [
			{
				...defaultProps,
				id: "created",
				headerText: "Date",
				textAlign: "center",
				cellAttrs: {
					template: this.getCreatedTemplate
				}
			},
			{
				...defaultProps,
				id: "storeName",
				headerText: this.state.isVendor ? "Store" : "Vendor",
				width: "85",
				cellAttrs: {
					template: this.getStoreTemplate,
					attributes: {
						class: "store-cell"
					}
				}
			},
			{
				...defaultProps,
				id: "stage",
				headerText: "Stage",
				textAlign: "center",
				cellAttrs: {
					template: this.getStageTemplate
				}
			},
			{
				...defaultProps,
				id: "stock",
				headerText: "Stock/VIN",
				cellAttrs: {
					template: this.getStockVinTemplate
				}
			},
			{
				...defaultProps,
				id: "YMM",
				width: "55",
				headerText: "Vehicle"
			},
			{
				...defaultProps,
				id: "task",
				headerText: "Task",
				cellAttrs: {
					template: this.getTaskTemplate
				}
			},
			{
				...defaultProps,
				id: "assignedEmployee",
				headerText: "Assigned To",
				width: this.state.isVendor ? "65" : "45",
				cellAttrs: {
					template: this.getAssignedTemplate
				}
			},
			{
				...defaultProps,
				id: "amount",
				headerText: "Amount",
				textAlign: "center",
				width: "25"
			},
			{
				...defaultProps,
				id: "taskStatus",
				headerText: "Status",
				visible: !this.state.isVendor,
				textAlign: "center",
				width: "35",
				cellAttrs: {
					template: this.getStatusTemplate
				}
			},
			{
				...defaultProps,
				id: "statusColor",
				headerText: "Invoiced",
				visible: this.state.isVendor && showCompleted,
				textAlign: "center",
				width: "35",
				cellAttrs: {
					template: this.getInvoicedTemplate
				}
			},
			{
				...defaultProps,
				id: "sold",
				headerText: "Sold",
				textAlign: "center",
				width: "25",
				cellAttrs: {
					template: this.getSoldCheck
				}
			},
			{
				...defaultProps,
				id: "flagged_at",
				headerText: "Flagged",
				textAlign: "center",
				width: "25",
				cellAttrs: {
					template: this.getFlaggedTemplate
				}
			},
			{
				...defaultProps,
				id: "location",
				headerText: "Location",
				textAlign: "center",
				width: "25",
				cellAttrs: {
					template: this.getLocationTemplate
				}
			},
			{
				...defaultProps,
				id: "action",
				textAlign: "center",
				width: "25",
				visible: this.actionItems.length > 0,
				allowFiltering: false,
				allowSorting: false,
				cellAttrs: {
					template: this.getActionsTemplate
				}
			}
		];

		const headerList = [
			"created",
			"storeName",
			"stage",
			"stock",
			"YMM",
			"task",
			"assignedEmployee",
			"amount",
			"taskStatus",
			"statusColor",
			"sold",
			"flagged_at",
			"location",
			"action"
		];

		return GridColumnHeaders(tasks, headerData, headerList);
	}

	getCreatedTemplate = props => {
		// moment(task.created_at).format("MM/DD/YY")
		return <span>{moment(props.created_at).format("MM/DD/YY")}</span>;
	};

	getStockVinTemplate = props => {
		return (
			<span>
				<div>
					<b>Stock</b>: {props.inventory_item.stock_number}
				</div>
				<div>
					<b>VIN</b>: {props.inventory_item.vin.slice(props.inventory_item.vin.length - 8)}
				</div>
			</span>
		);
	};

	getStageTemplate = props => {
		return <span style={{ color: props.stage === "Not in Stage" ? "grey" : "black" }}>{props.stage}</span>;
	};

	getStoreTemplate = props => {
		const { isVendor } = this.state;
		const isAssigned = props.storeName !== "Unassigned";
		const disabled = props.task_status.id === TaskStatus.Completed || props.task_status.id === TaskStatus.Approved;

		const title = () => {
			if (isVendor || disabled) {
				// (internal)vendors get the store
				return <h5 style={{ color: `${isAssigned ? "black" : "grey"}` }}>{props.storeName}</h5>;
			} else {
				// dealers have a dropdown
				const opts = this.fetchAssignments(props);
				// handle lack of approved vendors
				const hasopts = opts.length > 0;
				const selectedOpt = props.assigned_vendor && opts.find(i => i.value === props.assigned_vendor_id);

				if (this.props.viewType.includes("sub-component") || !hasopts)
					return <h5 style={{ color: `${isAssigned ? "black" : "grey"}` }}>{hasopts ? props.storeName : "No Approved Vendors"}</h5>;
				else
					return (
						<StoreAssignForm
							{...{
								selectedOpt,
								disabled,
								opts,
								isAssigned,
								assigned_vendor_id: props.assigned_vendor_id,
								onSubmit: value => this.assignVendor(props.id, value)
							}}
						/>
					);
			}
		};
		const subTitle = props.created_by_user && isAssigned ? <span className="sub">{props.created_by_user.full_name}</span> : "";
		return (
			<div className="prop-data">
				{title()}
				{subTitle}
			</div>
		);
	};

	getTaskTemplate = props => {
		return (
			<div className="prop-data">
				<TaskPhotoComponent {...props} />
				<h5>{props.task}</h5>
				{props.comment && props.comment !== "N/A" && props.comment !== "" ? <span className="sub">{props.comment}</span> : ""}
			</div>
		);
	};

	getAssignedTemplate = props => {
		const { isVendor } = this.state;
		const isAssigned = props.assignedEmployee !== "Unassigned";
		const blockStyle = { color: `${isAssigned ? "black" : "grey"}` };
		if (isVendor) {
			const opts = this.fetchAssignments(props);
			// handle lack of approved vendors
			const hasopts = opts.length > 0;
			const selectedOpt = props.assigned_employee && opts.find(i => i.value === props.assigned_employee.id);
			const isClosed = props.task_status.id === TaskStatus.Completed || props.task_status.id === TaskStatus.Approved;
			if (hasopts && !isClosed)
				return (
					<div className="prop-data">
						<h5 className={`${selectedOpt ? "" : "unassigned"}`}>
							<DropDownListComponent
								enabled={hasPermission(this.props.permissions, Permission.EDIT_ASSIGNEE, Session.get("isSuperUser"))}
								cssClass="selector cancel-row-selection"
								dataSource={opts}
								placeholder="Unassigned"
								value={selectedOpt ? selectedOpt.text : undefined}
								change={data => {
									const selected: any = data.itemData;
									this.assignEmployee(props.id, selected.value);
								}}
							/>
						</h5>
					</div>
				);
		}
		return <span style={blockStyle}>{props.assignedEmployee}</span>;
	};

	getStatusTemplate = props => {
		return (
			<Label color={props.statusColor} horizontal>
				{props.taskStatus}
			</Label>
		);
	};

	getInvoicedTemplate = props => {
		return props.taskStatus === "Approved" ? <Icon name="check" color="green" /> : <span />;
	};

	getSoldCheck = props => {
		return props.sold ? <Icon name="check" color="green" /> : <span />;
	};

	getActionsTemplate = props => {
		const closedTask = props.taskStatus === "Completed" || props.taskStatus === "Approved";
		const items = closedTask ? this.actionItems.filter(i => i.text !== "Complete" && i.text !== "Decline") : this.actionItems;
		return (
			<span className="cancel-row-selection">
				<ActionMenuComponent
					{...{
						items,
						props,
						onCompleteTask: (taskId, data) => this.completeTask(taskId, data),
						onDeclineTask: (taskId, data) => this.declineTask(taskId, data),
						onAddTaskNote: (taskId, data) => this.addTaskNote(taskId, data),
						onDeleteTask: taskId => this.deleteTask(taskId),
						task_rejection_types: this.props.task_rejection_types
					}}
				/>
			</span>
		);
	};

	getLocationTemplate = props => {
		return <InventoryLocationPinDialog {...props.inventory_item} />;
	};

	getFlaggedTemplate = (props): any => {
		if (props.flagged_at) {
			return (
				<Popup
					inverted
					content={
						"Flagged on by " + props.inventory_item.flagged_by_user.full_name + " on " + moment(parseFloat(props.inventory_item.flagged_at)).format("llll")
					}
					trigger={
						<span>
							<div>
								<i className="yellow flag icon" />
							</div>
							<div>{props.inventory_item.flagged_by_user.full_name.split(" ").map(f => f.split("")[0].toUpperCase())}</div>
							<div>{moment(parseFloat(props.inventory_item.flagged_at)).format("DD-MMM")}</div>
						</span>
					}
				/>
			);
		} else return <span></span>;
	};

	fetchAssignments(task) {
		const { isVendor } = this.state;
		let assignopts;
		if (isVendor) {
			assignopts = this.props.employees
				? this.props.employees.map(emp => {
						return {
							text: emp.user.full_name,
							value: emp.id
						};
				  })
				: [];
		} else {
			const checkApproved = vId => {
				return this.props.store_approved_vendor_services &&
					this.props.store_approved_vendor_services.find(
						vendor => vendor.vendor_service.service.id === task.service_id && vendor.vendor_service.vendor.id === vId
					)
					? true
					: false;
			};
			assignopts =
				this.props.store_approved_vendors &&
				this.props.store_approved_vendors
					.filter(vendor => vendor.vendor.services.find(service => service.service.id === task.service_id))
					.filter(vendor => checkApproved(vendor.vendor.id))
					.map((vendor, index) => {
						return {
							key: index + 1,
							text: vendor.vendor.name,
							value: vendor.vendor.id
						};
					});
		}
		const unassigned = {
			text: "Unassigned",
			value: 0
		};
		assignopts.unshift(unassigned);
		return assignopts;
	}

	processResult(err, res, needsRefetch = false) {
		this.props.addAlert({
			type: err ? "danger" : "success",
			message: err ? err.reason.response.data.message : res.data.message,
			timeout: 3000
		});
		if (needsRefetch) {
			this.props.taskRefetch();
			this.grid.refresh();
		}
	}

	// Vendor Endpoints
	completeTask = (taskId, formData) => {
		const hasNoteFormData = !isEmptyObject(formData.noteForm) && (formData.noteForm.description || formData.noteForm.files.length > 0);
		const completeData = removeNullProps(formData.completion);

		// create task log if they entered a comment of photo
		if (hasNoteFormData) this.addTaskNote(taskId, formData);

		// complete the task
		restAPI({
			endpointName: "vendorCompleteTask",
			urlArgs: [this.state.viewTypeStoreId, taskId],
			data: completeData,
			callback: (err, res) => {
				this.processResult(err, res, true);
			}
		});
	};

	declineTask = (taskId, formData) => {
		const data = removeNullProps(formData);
		restAPI({
			endpointName: "vendorDeclineTask",
			urlArgs: [this.state.viewTypeStoreId, taskId],
			data: data,
			callback: (err, res) => {
				this.processResult(err, res, true);
			}
		});
	};

	addTaskNote = async (taskId, formData) => {
		const data: any = { description: null, photo: null };
		const properties = removeNullProps(formData.noteForm);
		const addTaskLogger = this.state.isVendor ? "addTaskWorkLog" : "addDealerTaskWorkLog";

		if (properties) {
			if (properties.description) data.description = properties.description;

			if (properties.files && properties.files.length > 0) {
				properties.files.forEach(async fileObject => {
					const { file, photo_tag } = fileObject;
					data.photo = file ? await promiseBase64(file[0]) : null;
					if (data.photo && ["before", "after"].includes(photo_tag)) data.photo_tag = photo_tag;
					restAPI({
						endpointName: addTaskLogger,
						urlArgs: [this.state.viewTypeStoreId, parseInt(taskId, 10)],
						data: removeNullProps(data),
						callback: (err, res) => this.processResult(err, res)
					});
				});
			} else
				restAPI({
					endpointName: addTaskLogger,
					urlArgs: [this.state.viewTypeStoreId, parseInt(taskId, 10)],
					data: removeNullProps(data),
					callback: (err, res) => this.processResult(err, res)
				});
		}
	};

	assignEmployee(taskId: number, employeeId: number) {
		restAPI({
			endpointName: "assign_employee_to_task",
			urlArgs: [this.state.viewTypeStoreId, taskId],
			data: { employee_id: employeeId },
			callback: (err, res) => {
				this.processResult(err, res, true);
			}
		});
	}

	// Dealer Endpoints
	assignVendor(taskId: number, vendorId: any) {
		restAPI({
			endpointName: "assignVendorToTask",
			urlArgs: [this.state.viewTypeStoreId, taskId, vendorId],
			data: null,
			callback: (err, res) => {
				this.processResult(err, res, true);
			}
		});
	}

	deleteTask(taskId: number) {
		restAPI({
			endpointName: "dealerDeleteTask",
			urlArgs: [this.state.viewTypeStoreId, taskId],
			data: null,
			callback: (err, res) => {
				this.processResult(err, res, true);
			}
		});
	}
}

const mapStateToProps = (state: any, ownProps) => {
	return {
		storeId: ownProps.viewType.includes("sub-component") && ownProps.clientId ? ownProps.clientId : state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const TasksListComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IDealerPhasesSubProps, any, any, ClassAttributes<any>>(gqlSubscriptions.dealer.task_view, {
		skip: (ownProps: any) => ownProps.viewType.includes("vendor") || !ownProps.storeId,
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				}
			};
		},
		props: ({ data: { error, loading, task_view } }): any => {
			if (loading) return { sub_loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				task_view_sub: task_view.result
			};
		}
	}),
	graphql<LMI.IDealerPhasesSubProps, any, any, ClassAttributes<any>>(gqlSubscriptions.vendor.task_view, {
		skip: (ownProps: any) => ownProps.viewType.includes("dealership") || !ownProps.storeId,
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				}
			};
		},
		props: ({ data: { error, loading, task_view } }): any => {
			if (loading) return { sub_loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				task_view_sub: task_view.result
			};
		}
	}),
	graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.employeesByIsActive, {
		skip: (ownProps: any) => ownProps.viewType.includes("dealership") || !ownProps.storeId,
		options: (props: any) => {
			return {
				variables: {
					storeId: props.viewType.includes("sub-component") ? parseInt(props.vendorId, 10) : parseInt(props.storeId, 10),
					isActive: true
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employees, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				employees,
				empRefetch: refetch
			};
		}
	}),
	graphql<LMI.IStoreQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.tasks, {
		skip: (ownProps: any) => ownProps.viewType.includes("vendors") || !ownProps.storeId,
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					vendorId: props.vendorId && props.viewType.includes("sub-component") ? parseInt(props.vendorId, 10) : undefined,
					task_status_ids: props.taskStatuses,
					order: [["created_at", "DESC"]]
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, dealer_tasks, refetch, networkStatus } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				tasks: dealer_tasks,
				taskRefetch: refetch,
				networkStatus
			};
		}
	}),
	graphql<LMI.IStoreQueryProps, any, any, ClassAttributes<any>>(gqlQueries.vendor.tasks, {
		skip: (ownProps: any) => ownProps.viewType.includes("dealership") || !ownProps.storeId,
		options: (props: any) => {
			let variables: any = {
				storeId: props.viewType.includes("sub-component") ? parseInt(props.vendorId, 10) : parseInt(props.storeId, 10),
				dealer_id: props.viewType.includes("sub-component") ? parseInt(props.storeId, 10) : undefined,
				task_status_ids: props.taskStatuses,
				order: [["created_at", "ASC"]]
			};

			if (props.searchFilter) variables = { ...variables, ...{ term: props.searchFilter } };
			if (props.filterRejected) variables = { ...variables, ...{ is_rejected: true } };

			return {
				variables,
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, vendor_tasks, refetch, networkStatus } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				tasks: vendor_tasks,
				taskRefetch: refetch,
				networkStatus
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.vendor.taskRejectionTypes, {
		skip: (ownProps: any) => ownProps.viewType.includes("dealership") || !ownProps.storeId,
		options: (props: any) => {
			return {
				variables: {
					vendor_id: props.viewType.includes("sub-component") ? parseInt(props.vendorId, 10) : parseInt(props.storeId, 10),
					store_type_id: parseInt(localStorage.getItem("selectedStoreType"), 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, task_rejection_types, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				task_rejection_types,
				refetchRejectionTypes: refetch
			};
		}
	})
)(TasksListView) as React.ComponentType<any>;

export default TasksListComponent;
