// node_modules
import * as React from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { Button, Dropdown, Grid, Icon, Label, List } from "semantic-ui-react";
import "./RolesComponent.css";

export class RolesComponentView extends React.Component<LMI.ISSRolesCProps, LMI.ISSRolesCState> {
	constructor(props) {
		super(props);

		this.state = {
			selectedRole: null,
			roleset: false,
			permsopen: false
		};
	}
	render() {
		const props = this.props;

		const selectedRolesList = props.selectedRoles
			.map((roleId, index) => {
				const name =
					this.props.roles.find(role => {
						return role.id === roleId;
					}).name || "Not set";
				return {
					id: roleId,
					name
				};
			})
			.sort((a, b) => {
				return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() === b.name.toLowerCase() ? 0 : -1;
			});

		return (
			<span id="rolesComponent" className="roles-component">
				<Grid>
					<Grid.Column width={8}>
						{!this.state.roleset || !selectedRolesList.length ? (
							<Button.Group className="roleselector">
								<Dropdown
									button
									className="icon"
									labeled
									scrolling
									options={props.roles.map((role: LMI.IRoleGQL, index: number) => {
										return {
											key: index,
											value: role.id,
											text: role.name
										};
									})}
									placeholder="Add Access Role"
									onChange={(evt, data) => {
										this.setState({ selectedRole: data.value as number });
									}}
								/>
								<Button
									positive
									disabled={this.state.selectedRole ? false : true}
									onClick={() => {
										props.onSelectRole(this.state.selectedRole);
										this.setState({ roleset: true, selectedRole: null });
									}}
								>
									Add
								</Button>
							</Button.Group>
						) : (
							""
						)}
						<List>
							{selectedRolesList.map((role, index) => {
								return (
									<List.Item key={index}>
										<Label size="big">
											{role.name}
											<Icon name="delete" onClick={() => props.onRemoveRole(role.id)} />
										</Label>
									</List.Item>
								);
							})}
						</List>
					</Grid.Column>
					<Grid.Column width={8}>
						{this.state.roleset && selectedRolesList.length ? (
							<span className="roles-cta">
								<Button
									onClick={() => {
										this.setState({ roleset: false });
									}}
								>
									Add Access Role
								</Button>
							</span>
						) : (
							""
						)}
					</Grid.Column>
				</Grid>
			</span>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const RolesComponent = compose(connect(mapStateToProps, mapDispatchToProps))(RolesComponentView) as React.ComponentType<any>;

export default RolesComponent;
