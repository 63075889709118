// node_modules
import * as React from "react";
import Lightbox from "react-image-lightbox";
import { Icon } from "semantic-ui-react";

class TaskPhotoComponent extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			openLightbox: null,
			photo_index: 0
		};
	}

	render() {
		const { task, YMM, primary_photo_url, after_photo_full_url, before_photo_full_url } = this.props;
		const { openLightbox, photo_index } = this.state;
		const imageArray: any = [
			{ url: primary_photo_url },
			{ url: before_photo_full_url, label: "Before Photo" },
			{ url: after_photo_full_url, label: "After Photo" }
		];
		const images = imageArray
			.filter(i => i.url && !i.url.includes("default_task_image.png"))
			.map(img => {
				return {
					url: img.url,
					label: img.label ? img.label : null
				};
			});
		const hasMultiple = images.length > 1;

		if (images.length > 0) {
			const active = images[photo_index];
			const next = (photo_index + 1) % images.length;
			const prev = (photo_index + images.length - 1) % images.length;
			const nextSrc = images[next];
			const prevSrc = images[prev];

			return (
				<div className="cancel-row-selection">
					{openLightbox && (
						<Lightbox
							mainSrc={active.url}
							nextSrc={hasMultiple ? nextSrc.url : null}
							prevSrc={hasMultiple ? prevSrc.url : null}
							imageTitle={`${task} on ${YMM}${active.label ? ` - ${active.label}` : ""}`}
							onCloseRequest={() => this.setState({ openLightbox: null })}
							onMovePrevRequest={() => this.setState({ photo_index: prev })}
							onMoveNextRequest={() => this.setState({ photo_index: next })}
						/>
					)}
					<span className="task-photo cancel-row-selection" onClick={() => this.setState({ openLightbox: true })}>
						<Icon name="image outline" color="grey" />
					</span>
				</div>
			);
		} else return <span />;
	}
}

export default TaskPhotoComponent;
