import { Form, Input } from "formsy-semantic-ui-react";
import StateSelector from "client/components/StateSelector/StateSelector";
import { MaskedInputComponent } from "client/pages/admin/components/MaskedInputComponent";
import { RegularExpressions } from "client/utils/regex";
import * as React from "react";
import { Button, Label, Segment } from "semantic-ui-react";

export class AuctionDetailsComponent extends React.Component<
  LMI.IAuctionHouseDetails,
  LMI.IAuctionHouseDetailsState
> {
  constructor(props) {
    super(props);
    this.state = {
      newAuction: props.auctionhouse ? false : true,
      selectedState: null,
      errorState: false,
    };
  }

  render() {
    const props = this.props;
    const errorLabel = <Label color="red" pointing="above" />;

    return (
      <div id="auction-house-detail">
        <Form
          noValidate
          onSubmit={this.validate.bind(this)}
          onValidSubmit={this.submitForm.bind(this)}
        >
          <Form.Field>
            <label>Name</label>
            <Input
              name="name"
              value={props.auctionhouse && props.auctionhouse.name}
              required
              validations={{
                matchRegexp: RegularExpressions.NAMES,
              }}
              validationErrors={{
                matchRegexp:
                  "Only letters, numbers, spaces, commas, periods, apostrophes, and dashes allowed.",
                isDefaultRequiredValue: "Name is required",
              }}
              instantValidation
              errorLabel={errorLabel}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Contact Name</label>
              <Input
                name="contact_name"
                value={props.auctionhouse && props.auctionhouse.contact_name}
                required
                validations="isWords"
                validationErrors={{
                  isWords: "No numbers or special characters allowed",
                  isDefaultRequiredValue: "Contact name is required",
                }}
                errorLabel={errorLabel}
              />
            </Form.Field>
            <Form.Field>
              <label>Mobile Phone</label>
              <MaskedInputComponent
                {...{
                  value: props.auctionhouse && props.auctionhouse.phone,
                  type: "phone",
                  required: true,
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Address</label>
            <Input
              name="address1"
              value={props.auctionhouse && props.auctionhouse.address1}
              required
              validations={{
                matchRegexp: RegularExpressions.ADDRESS,
              }}
              validationErrors={{
                matchRegexp:
                  "Only letters, numbers, spaces, commas, periods, apostrophes, and dashes allowed.",
                isDefaultRequiredValue: "Address is required",
              }}
              errorLabel={errorLabel}
            />
          </Form.Field>
          <Form.Field>
            <label>Address 2</label>
            <Input
              name="address2"
              value={props.auctionhouse && props.auctionhouse.address2}
              validations={{
                matchRegexp: RegularExpressions.ADDRESS,
              }}
              errorLabel={errorLabel}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>City</label>
              <Input
                name="city"
                value={props.auctionhouse && props.auctionhouse.city}
                required
                validations={{
                  matchRegexp: RegularExpressions.CITY,
                }}
                validationErrors={{
                  matchRegexp:
                    "Only letters, numbers, spaces, commas, periods, apostrophes, and dashes allowed.",
                  isDefaultRequiredValue: "City is required",
                }}
                errorLabel={errorLabel}
              />
            </Form.Field>
            <Form.Field>
              <label>State/Province</label>
              <StateSelector {...this.getStateSelectorProps()} />
              {this.state.errorState ? (
                <Label
                  color="red"
                  pointing="above"
                  content="State/Province is required"
                />
              ) : (
                <span />
              )}
            </Form.Field>
            <Form.Field>
              <label>Zip</label>
              <Input
                name="zip"
                value={props.auctionhouse && props.auctionhouse.zip}
                required
                validations="isNumeric,minLength:5,maxLength:5"
                validationErrors={{
                  isNumeric: "Only numbers allowed",
                  minLength: "Must be at least 5 digits",
                  maxLength: "Can only be 5 digits",
                  isDefaultRequiredValue: "Zip code is required",
                }}
                errorLabel={errorLabel}
              />
            </Form.Field>
          </Form.Group>
          <Segment basic textAlign="right">
            <Button.Group>
              <Button type="button" onClick={() => props.closeCallback()}>
                Cancel
              </Button>
              <Button.Or />
              <Button positive className="submit-btn" type="submit">
                Save
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      </div>
    );
  }

  getStateSelectorProps(): LMI.IStateSelectorProps {
    let stateSelectorProps: LMI.IStateSelectorProps = {
      value: this.props.auctionhouse ? this.props.auctionhouse.state : null,
      placeholder: "Select State/Province",
      onChange: (data) =>
        this.setState({ selectedState: data, errorState: false }),
    };
    if (this.state.errorState)
      stateSelectorProps = {
        ...stateSelectorProps,
        ...{ passedProps: { error: true } },
      };
    return stateSelectorProps;
  }

  validate() {
    if (!this.state.selectedState) this.setState({ errorState: true });
  }

  submitForm(formData: any) {
    formData.state = this.state.selectedState?.value;
    if (this.state.newAuction) {
      this.props.onCreate(formData);
    } else {
      this.props.onSave(formData, this.props.auctionhouse.id);
    }
  }
}

export default AuctionDetailsComponent;
