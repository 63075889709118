import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import * as React from "react";
import { Button } from "semantic-ui-react";
import { ModalComponent } from "../../../../ModalComponent";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

class StoreAssignForm extends React.Component<any, any> {
	dropdown = null;
	constructor(props: any) {
		super(props);

		this.state = {
			confirm: null
		};
	}

	render() {
		const { selectedOpt, disabled, opts, isAssigned, assigned_vendor_id, onSubmit } = this.props;
		const { confirm } = this.state;

		return (
			<h5 className={`${selectedOpt ? "" : "unassigned"}`}>
				{confirm && (
					<ModalComponent
						shouldBeOpen={true}
						size={confirm.size}
						headerText={confirm ? confirm.title : null}
						className="confirmation-modal"
						onClose={() => confirm.onClose()}
						contentComponent={() => {
							return (
								<div id="Confirm">
									<span>
										<p style={{ margin: "23px 3px 0", fontWeight: "bold" }}>{confirm.text}</p>
										<small>This will notify the currently assigned vendor.</small>
										<br />
										<br />
										<Button
											positive
											content={confirm.confirmBtn ? confirm.confirmBtn : "Confirm"}
											onClick={() => this.setState({ confirm: null }, () => onSubmit(confirm.value))}
										/>
										<Button negative content="Cancel" onClick={() => confirm.onClose()} />
									</span>
								</div>
							);
						}}
					/>
				)}

				<DropDownListComponent
					ref={scope => (this.dropdown = scope)}
					enabled={!disabled}
					cssClass="selector cancel-row-selection"
					dataSource={opts}
					placeholder="Unassigned"
					value={selectedOpt ? selectedOpt.text : undefined}
					change={args => {
						const selected: any = args.itemData;
						if (isAssigned && selected.value !== assigned_vendor_id) {
							const unassign = selected.value == 0;
							const text = `Are you sure you want to ${unassign ? "unassign" : "reassign"} this task${unassign ? "" : ` to ${selected.text}`}?`;
							this.setState({
								confirm: {
									text,
									size: "mini",
									value: selected.value,
									onClose: () => this.setState({ confirm: null }, () => (this.dropdown.value = args.previousItemData.text))
								}
							});
						} else onSubmit(selected.value);
					}}
				/>
			</h5>
		);
	}
}

export default StoreAssignForm;
