import track from "react-tracking";

// node_modules
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
// redux
import { changeSubtab } from "api/redux/actions";
// components
import { TabsContainer, TCProps } from "client/pages/admin/components/ReactTabsContainer";
import { buildTabTarget } from "client/components/Menu/utils";
import SubTabContainers from "client/components/Routes/SubTabContainers";
import { AnalyticsEventType } from "loopmein-shared";

import "./ReportsTabPanel.css";
import "./ReportsCommon.css";

@track({ event_type: AnalyticsEventType.NAVIGATION, event_subtype: "reports" }, { dispatchOnMount: true })
export class ReportsTabPanelView extends React.Component<LMI.IReportsTabPanelProps, LMI.IReportsTabPanelState> {
	constructor(props) {
		super(props);

		this.state = {
			subRouteId: "stage-breakdown"
		};
	}

	render() {
		return (
			<div className="reports-tab-panel panel-content">
				<TabsContainer {...this.getTabProps()} />
			</div>
		);
	}

	getTabProps(): TCProps {
		const { subRouteId } = this.state;
		const { storeId, permissions, viewType, refetch } = this.props;
		const tabProps: TCProps = {
			subRouteId,
			subTabContainer: SubTabContainers.reports,
			permissions: permissions,
			classes: "reports-tab-panel-tabs",
			viewType: `${viewType} sub-component`,
			dataPassThru: {
				storeId: storeId,
				orgId: parseInt(localStorage.getItem("selectedOrgId")),
				refetchAll: refetch
			},
			onChange: (tabSelected: string) => this.selectedTab(tabSelected)
		};
		return tabProps;
	}

	selectedTab(tab) {
		this.props.changeSubtab(tab);
		this.props.history.push(buildTabTarget(this.props.match.path, tab));
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			subRouteId: nextProps.match ? nextProps.match.params.id : prevState.subRouteId
		};
	}

	componentWillUnmount() {
		this.props.changeSubtab(null);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		changeSubtab: (tabName: string) => {
			dispatch(changeSubtab(tabName));
		}
	};
};

export const ReportsTabPanel = compose(connect(mapStateToProps, mapDispatchToProps))(ReportsTabPanelView) as React.ComponentType<any>;

export default ReportsTabPanel;
