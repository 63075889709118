// node_modules
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import * as React from "react";
import { Button, Form, TextArea } from "semantic-ui-react";
import { ModalComponent } from "../../../../ModalComponent";
import { VehicleNoteForm } from "../../../../VehicleNoteForm";

class ActionMenuComponent extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			confirm: null,
			task_decline_type_id: null,
			requires_comment: false
		};
	}

	actionSelected(action, taskId) {
		// configure action event handlers in switch
		const selected = action.item.text;
		let confirm;
		switch (selected) {
			case "Complete":
				confirm = {
					title: "Complete Task",
					content: () => this.completeTaskContent(taskId)
				};
				break;
			case "Decline":
				confirm = {
					size: "mini",
					content: () => this.declineTaskContent(taskId)
				};
				break;
			case "Add Note":
				confirm = {
					title: "Add Task Note",
					content: () => this.addNoteContent(taskId)
				};
				break;
			case "Delete Task":
				confirm = {
					size: "tiny",
					content: () => this.deleteTaskContent(taskId)
				};
				break;
			default:
				console.log("Nothing is configured for this action item");
				break;
		}
		this.setState({ confirm });
	}

	completeTaskContent(taskId) {
		return (
			<div id="Confirm">
				<span>
					<p>Please optionally provide the info below and/or click the complete button to complete this task.</p>
					<VehicleNoteForm
						{...{
							...this.props,
							cost: true,
							requireNote: false,
							btnText: "Complete Task",
							files: ["before", "after"],
							onSubmit: data => this.setState({ confirm: null }, () => this.props.onCompleteTask(taskId, data))
						}}
					/>
				</span>
			</div>
		);
	}

	deleteTaskContent(taskId) {
		return (
			<div id="Confirm">
				<span>
					<h3>Are you sure you want to delete this task?</h3>
					<Button color="blue" content="Yes, Delete" onClick={() => this.setState({ confirm: null }, () => this.props.onDeleteTask(taskId))} />
					<Button content="Cancel" onClick={() => this.setState({ confirm: null })} />
				</span>
			</div>
		);
	}

	declineTaskContent(taskId) {
		const { task_rejection_types } = this.props;
		const { task_decline_type_id, requires_comment, task_decline_comment } = this.state;
		const disableSubmit = !task_decline_type_id || (requires_comment && !task_decline_comment);
		return (
			<div id="Confirm">
				<span>
					<p style={{ margin: "23px 3px" }}>
						<strong>Are you sure you want to decline this task?</strong> <br />
						Please provide a decline reason below:
					</p>
					<Form noValidate>
						<DropDownListComponent
							cssClass="selector cancel-row-selection"
							dataSource={task_rejection_types.map(type => type.name)}
							placeholder="Select Reason"
							change={data => {
								const selected = data.itemData;
								const type = task_rejection_types.find(ty => ty.name === selected.value);
								this.setState({ task_decline_type_id: type.id, requires_comment: type.requires_comment });
							}}
						/>
						<br />
						<TextArea
							id="taskDeclineComment"
							className="notes"
							rows={4}
							placeholder="Tell us more"
							onChange={(e, data) => this.setState({ task_decline_comment: data.value })}
						/>
						<br />
						<Button
							positive
							content="Decline Task"
							disabled={disableSubmit}
							onClick={() =>
								this.setState({ confirm: null, requires_comment: false }, () =>
									this.props.onDeclineTask(taskId, { task_decline_type_id, task_decline_comment })
								)
							}
						/>
					</Form>
				</span>
			</div>
		);
	}

	addNoteContent(taskId) {
		return (
			<div id="Confirm">
				<span>
					<VehicleNoteForm
						{...{
							cost: false,
							files: ["misc"],
							btnText: "Add Note",
							onSubmit: data => this.setState({ confirm: null }, () => this.props.onAddTaskNote(taskId, data))
						}}
					/>
				</span>
			</div>
		);
	}

	render() {
		const { items, props } = this.props;
		const { confirm } = this.state;

		// filter delete task option for completed tasks #1174
		const isComplete = props.task_status.name === "Completed" || props.task_status.name === "Approved";
		const options = isComplete ? items.filter(i => i.text !== "Delete Task") : items;

		return (
			<div className="item-actions-btn">
				{confirm && (
					<ModalComponent
						size={confirm.size}
						headerText={confirm ? confirm.title : null}
						shouldBeOpen={true}
						className="confirmation-modal"
						onClose={() => this.setState({ confirm: null })}
						contentComponent={() => confirm.content()}
					/>
				)}

				<DropDownButtonComponent
					items={options}
					iconCss="e-icons e-menu cancel-row-selection"
					cssClass="e-caret-hide cancel-row-selection"
					select={e => this.actionSelected(e, props.id)}
				/>
			</div>
		);
	}
}

export default ActionMenuComponent;
