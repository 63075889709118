// node_modules
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
// components
import StoreEmployeesTabPanelView from "./StoreEmployeesTabPanel";
import { addAlert } from "api/redux/actions";

const mapStateToProps = (state: any, ownProps) => {
	return {
		storeId: ownProps.viewType === "vendors sub-component" && ownProps.clientId ? ownProps.clientId : state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const StoreEmployeesTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.vendorEmployees, {
		options: (props: any) => {
			return {
				variables: {
					store_id: parseInt(props.storeId, 10),
					vendor_id: parseInt(props.vendorId, 10)
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, vendor_assigned_employees, approved_vendor, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, error };

			return {
				employees: vendor_assigned_employees,
				primary_contact: approved_vendor,
				refetch
			};
		}
	})
)(StoreEmployeesTabPanelView) as React.ComponentType<any>;

export default StoreEmployeesTabPanel;
