import track from "react-tracking";
// node_modules
import { gqlQueries } from "gql-imports";
import { SearchComponent } from "client/pages/admin/components/SearchComponent";

import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
// components
import { Button, Dropdown, Grid } from "semantic-ui-react";
import { LeadsListComponent } from "./components";
import { AnalyticsEventType } from "loopmein-shared";

import "./LeadsTabPanel.css";

@track({ event_type: AnalyticsEventType.NAVIGATION, event_subtype: "leads" }, { dispatchOnMount: true })
export class LeadsTabPanelView extends React.Component<LMI.ILeadsTabProps, LMI.ILeadsTabState> {
	constructor(props: LMI.ILeadsTabProps) {
		super(props);

		this.state = {
			searchFilter: null,
			empFilter: null,
			exportLeads: false,
			leadsLength: null,
			alert: null
		};
	}

	render() {
		const props = this.props;

		const searchProps: LMI.ISearchProps = {
			placeholder: "Search Leads",
			onSubmit: data => {
				this.setState({ searchFilter: data.term });
			},
			onEmpty: () => {
				this.setState({ searchFilter: null });
			},
			tracking_path: this.props.tracking.getTrackingData().event_subtype
		};

		const leadsListProps: LMI.ILeadsListProps = Object.assign({}, props, {
			searchFilter: this.state.searchFilter ? this.state.searchFilter : null,
			empFilter: this.state.empFilter ? this.state.empFilter : null,
			exportList: this.state.exportLeads ? this.state.exportLeads : false,
			limit: this.state.leadsLength,
			exportCallback: length => {
				this.setState({
					exportLeads: false,
					leadsLength: length
				});
			},
			tracking_path: this.props.tracking.getTrackingData().event_subtype
		});

		const empOptions: any =
			props.employees && props.employees.length
				? props.employees.map(emp => {
						return {
							value: emp.user.id,
							text: emp.user.full_name
						};
				  })
				: [];
		empOptions.unshift({ value: "all", text: "All Employees" });

		return (
			<div id="leads-panel">
				<div className="sortable-container">
					<div className="sortable-filter">
						<Grid className="tools">
							<Grid.Column width={4}>
								<SearchComponent {...searchProps} />
							</Grid.Column>
							<Grid.Column width={7} />
							<Grid.Column width={2}>
								<Button
									basic
									className="exportbtn"
									color="grey"
									floated="right"
									loading={this.state.exportLeads}
									onClick={() => this.setState({ exportLeads: true })}
									content="Export"
									icon="file excel outline"
								/>
							</Grid.Column>
							<Grid.Column width={3}>
								<Dropdown
									placeholder="Filter by Employee"
									fluid
									selection
									defaultValue="all"
									options={empOptions}
									onChange={(e, data) => {
										this.setState({
											empFilter: data.value !== "all" ? data.value.toString() : null
										});
									}}
								/>
							</Grid.Column>
						</Grid>
					</div>
					<div className="sortable-content leads-list">
						<LeadsListComponent {...leadsListProps} />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const LeadsTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.employees, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employees, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				employees,
				refetch
			};
		}
	})
)(LeadsTabPanelView) as React.ComponentType<any>;

export default LeadsTabPanel;
