// node_modules
import * as React from "react";
// components
import { Button, Form, Input, Divider } from "semantic-ui-react";

export class LeaseReturnSettingEditor extends React.Component<LMI.ILeaseReturnSettingEditorProps, LMI.ILeaseReturnSettingEditorState> {
	constructor(props) {
		super(props);
		this.state = {
			item: props.item
		};
	}

	updataItem(value: number) {
		const { item } = this.state;
		item.value = value;
		this.setState({ item });
	}

	render() {
		const { item } = this.state;
		const { value, store_setting_type } = item;
		const { onSave } = this.props;

		return (
			<div id="LeaseSettingEditor">
				<Form>
					<Form.Field>
						<Input
							name="name"
							type="number"
							label="Days"
							labelPosition="right"
							value={value ? value : ""}
							onChange={(e, data) => this.updataItem(parseInt(data.value, 10))}
						/>
					</Form.Field>
					{store_setting_type.label}
					<Divider hidden />
					<Button color="green" content="Save Item" onClick={() => onSave(item)} />
				</Form>
			</div>
		);
	}
}

export default LeaseReturnSettingEditor;
