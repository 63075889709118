import { gqlQueries } from "gql-imports";
import { updateTour } from "api/redux/actions";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { Button, Header, Image } from "semantic-ui-react";
import { StoreType } from "loopmein-shared";
import { SortBySortOrder, stringToInt } from "client/utils/functions";
import "./FeatureTours.css";

export class FeatureToursView extends React.Component<LMI.IFeatureToursProps, LMI.IFeatureToursState> {
	constructor(props: LMI.IFeatureToursProps) {
		super(props);
		const completedTours = this.completedFeatureTours();
		this.state = {
			launched: false,
			tours: [],
			completed: completedTours ? completedTours : []
		};
	}

	render() {
		const { open } = this.props;
		const { launched, completed, tours } = this.state;
		if (launched || open) {
			const incompleteTours = tours ? tours.filter(tour => this.needsTour(tour, completed)).sort(SortBySortOrder) : [];
			// if feature tours were opened manually then include all feature tours even if it has been toured previously
			const featureTours = open ? tours : incompleteTours;
			if (featureTours && featureTours.length > 0) {
				return (
					<ModalComponent
						size="small"
						shouldBeOpen={launched || open ? true : false}
						className="feature-tours"
						headerText="Featured Tours"
						contentComponent={() => this.featureToursMarkup(featureTours)}
						onClose={() =>
							this.setState({ launched: false }, () => {
								if (this.props.onClose) this.props.onClose();
							})
						}
					/>
				);
			} else return <span />;
		}
		return <span />;
	}

	needsTour(tour: LMI.ITourGQL, completed: number[]) {
		return completed ? !completed.includes(tour.id) : true;
	}

	tourTemplate(title: string, text: string) {
		return (
			<div className="tour-template">
				<Header content={title} />
				<p>{text}</p>
			</div>
		);
	}

	static getDerivedStateFromProps(nextProps: LMI.IFeatureToursProps, prevState: LMI.IFeatureToursState) {
		const tours = nextProps.tours && nextProps.storeSpecificTours ? [...nextProps.tours, ...nextProps.storeSpecificTours] : [];
		return { tours };
	}

	action(tour: LMI.ITourGQL, takeTour: boolean = false) {
		if (takeTour) this.setState({ launched: false }, () => setTimeout(() => this.props.updateTour(tour.steps), 200));
		if (this.props.onClose) this.props.onClose();
		this.completeTours([tour.id]);
	}

	featureToursMarkup(tours: LMI.ITourGQL[]) {
		const launched = this.state.launched && !this.props.open;
		return (
			<div className="tour-list">
				{tours.map(tour => {
					return (
						<div key={tour.id} className="tour-card">
							{launched ? <Image src="/images/new-icon.svg" className="new-icon" /> : <span />}
							<div className="tour-details">
								<div className="tour">
									<Header content={tour.name} />
									<p>{tour.description}</p>
								</div>
							</div>
							<div className="tour-options">
								<Button color="green" content="Tour" onClick={() => this.action(tour, true)} />
								{launched && <Button content="Skip" onClick={() => this.action(tour)} />}
							</div>
						</div>
					);
				})}
			</div>
		);
	}

	launchAutoTour() {
		const launch = () => this.setState({ launched: true });
		setTimeout(launch, 2000);
	}

	componentDidMount() {
		if (this.props.autoLaunch) this.launchAutoTour();
	}

	shouldComponentUpdate(nextProps: LMI.IFeatureToursProps, nextState: LMI.IFeatureToursState) {
		return nextState.launched !== this.state.launched || nextState.completed !== this.state.completed || nextProps.open !== this.props.open;
	}

	completeTours(tourIds: number[]) {
		const completedTours = this.completedFeatureTours();
		const existing = completedTours ? completedTours : [];
		const completed = [...existing, ...tourIds.filter(id => !existing.includes(id))];
		localStorage.setItem("completedFeatureTours", JSON.stringify(completed));
		this.setState({ completed: this.completedFeatureTours() });
	}

	completedFeatureTours() {
		const completed = localStorage.getItem("completedFeatureTours");
		return completed ? JSON.parse(completed) : null;
	}
}

const mapStateToProps = (state: any) => {
	return {
		tourSteps: state.app.admin.steps
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		updateTour: (steps: LMI.ITourStepGQL[]) => {
			dispatch(updateTour(steps));
		}
	};
};

export const FeatureTours = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IToursQueryProps, any, any, ClassAttributes<any>>(gqlQueries.tours.list, {
		options: (props: any) => {
			return {
				variables: { route: "/feature-tours" },
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, tours, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				tours,
				refetch
			};
		}
	}),
	graphql<LMI.IToursQueryProps, any, any, ClassAttributes<any>>(gqlQueries.tours.list, {
		options: (props: any) => {
			const storeTypeId = localStorage.getItem("selectedStoreType") ? stringToInt(localStorage.getItem("selectedStoreType")) : null;
			const userType = storeTypeId === StoreType.Dealership ? "dealerships" : storeTypeId === StoreType.Vendor ? "vendors" : "ivendors";
			return {
				variables: { route: `/${userType}/feature-tours` },
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, tours, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				storeSpecificTours: tours,
				refetch
			};
		}
	})
)(FeatureToursView) as React.ComponentType<any>;

export default FeatureTours;
