import { addAlert } from "api/redux/actions";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Button, Container, Dimmer, Grid, Loader } from "semantic-ui-react";
import { FileDropComponent } from "../../../../../../components/FileHandling";
import { calculateBase64ImageSize, generateThumbnail, getFileObjectFromPath, humanFileSize, promiseBase64 } from "../../../../../../utils/fileHandling";
import { handleErrorResponse } from "../../../../../../utils/functions";
import { restAPI } from "../../../../../../utils/rest";

import "./AddLeaseReturnDocument.css";

export class DocumentView extends React.Component<LMI.ILRAddDocumentViewProps, LMI.ILRAddDocumentViewState> {
	constructor(props: LMI.ILRAddDocumentViewProps) {
		super(props);

		this.state = {
			fileHandles: props.fileHandles ? props.fileHandles : null,
			uploading: false,
			editing: false,
			saving: false,
			creating: false
		};
	}

	setFileHandles = fileHandles => {
		this.setState({ fileHandles });
	};

	uploadDocument = () => {
		const { fileHandles } = this.state;
		if (fileHandles && fileHandles.length > 0) {
			this.setState({ creating: true }, async () => {
				const { storeId, detail, lease_return } = this.props;

				const hasFile = fileHandles && fileHandles.length > 0;
				const isCropped = hasFile && fileHandles[0].cropped;
				const sortOrder = Math.max(...lease_return.lease_return_photos.map(l => l.sort_order), 100) + 10;
				const data = {
					sort_order: sortOrder,
					photo: hasFile ? (isCropped ? fileHandles[0].cropped : await promiseBase64(fileHandles[0])) : null
				};
				restAPI({
					endpointName: "addLeaseReturnDocument",
					urlArgs: [storeId, detail.detail_id],
					data: data,
					callback: async (error, result) => {
						if (error) {
							this.sendAlert({ type: "danger", message: handleErrorResponse({ error }) });
						} else {
							await this.props.refetchDocuments();
							await this.props.closeModalCallback();
						}
						this.setState({ saving: false, creating: false });
					}
				});
			});
		}
	};

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
		if (type === "danger") {
			console.log(`Error: ${message}`);
		}
	}

	render() {
		const { editing, fileHandles, creating } = this.state;
		const { loading } = this.props;

		if (loading || editing || creating)
			return (
				<Dimmer active inverted>
					<Loader inverted />
				</Dimmer>
			);

		const fileDropProps = {
			fileHandles,
			multiple: false,
			fileSizeMaxMB: 2,
			fileTypes: ["image/png", "image/jpg", "image/jpeg"],
			enableCropping: true,
			dropIcon: "photo",
			dropMessage: "Drop your photo or click here to upload",
			onReturnFileHandles: this.setFileHandles,
			generateThumbnail: args => generateThumbnail(args),
			humanFileSize: args => humanFileSize(args),
			calculateBase64ImageSize: args => calculateBase64ImageSize(args),
			promiseBase64: args => promiseBase64(args),
			getFileObjectFromPath: args => getFileObjectFromPath(args)
		};

		return (
			<div className="add-inspection-item">
				<Grid>
					<Grid.Row id="fileRow">
						<Grid.Column width={16}>
							<FileDropComponent {...fileDropProps} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Container textAlign="right">
							<Button
								id="create"
								type="submit"
								positive
								size="large"
								disabled={fileHandles && fileHandles.length > 0 ? false : true}
								onClick={this.uploadDocument.bind(this)}
							>
								Upload
							</Button>
						</Container>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const DocumentForm = compose(connect(mapStateToProps, mapDispatchToProps))(DocumentView) as React.ComponentType<any>;

export default DocumentForm;
