import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  List,
  Message,
  Table,
} from "semantic-ui-react";
import { Loading } from "client/components/Loading";
import { PermissionsComponent, RolesComponent } from "./components";
import { Permission } from "loopmein-shared";
import { stringToInt } from "client/utils/functions";
import { Session } from "client/utils/session";

import "./SelectStoresComponent.css";

export class SelectStoresComponentView extends React.Component<
  LMI.ISelectStoresCProps,
  LMI.ISelectStoresCState
> {
  storeMap: Map<string, any>;
  rolesMap: Map<number, number[]>;
  changedPermissionsMap: Map<
    number,
    Array<{ id: number; add_or_remove: string }>
  >;

  constructor(props: LMI.ISelectStoresCProps) {
    super(props);

    this.storeMap = new Map<string, any>();
    this.rolesMap = new Map<number, number[]>();
    this.changedPermissionsMap = new Map<
      number,
      Array<{ id: number; add_or_remove: string }>
    >();

    this.state = {
      selectedStore: parseInt(localStorage.getItem("selectedStoreId"), 10),
      selectedStores: [],
      selectedRole: null,
      editingPermissions: false,
      multipleStoresMode: false,
      rolesList: [],
      permissionsMap: new Map<number, number[]>(),
    };
  }
  render() {
    const props = this.props;

    if (!props.user || !props.permissions || !props.roles || props.loading) {
      return <Loading />;
    }
    if (props.hasErrors) {
      console.error("EmployeesTabPanel Data errors:", props.error);
    }

    const employees = [].concat(props.user.employees) || [];

    employees.sort((a, b) => {
      return a.store.name.toLowerCase() > b.store.name.toLowerCase()
        ? 1
        : a.store.name.toLowerCase() === b.store.name.toLowerCase()
        ? 0
        : -1;
    });

    const currentStore = this.state.multipleStoresMode
      ? this.state.selectedStores[0]
      : this.state.selectedStore;
    const storePermissions: number[] = this.state.permissionsMap.has(
      currentStore
    )
      ? this.state.permissionsMap.get(currentStore)
      : [];

    const permObj = {
      storePermissions,
      permissions: props.permissions,
      onClick: (data: any, perm: LMI.IPermissionsGQL): void => {
        this.editPermissionsCB(data, perm, storePermissions);
      },
    };

    const sessionStores = Session.get("stores");

    const dimmed =
      this.state.selectedStore || this.state.selectedStores.length
        ? false
        : true;
    const permDimmed = storePermissions.length || dimmed ? false : true;

    return (
      <div className="select-stores-component">
        <div className="ssc-content">
          <Grid>
            <Grid.Column width={16}>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      Store{employees.length > 1 && "s"}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Roles &amp; Permissions{" "}
                      {this.state.selectedStore &&
                      !this.state.multipleStoresMode
                        ? "for " +
                          employees.find(
                            (store) =>
                              store.store.id === this.state.selectedStore
                          ).store.name
                        : this.state.selectedStores
                        ? "for Multiple Stores"
                        : ""}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell verticalAlign="top" className="stores">
                      <Button
                        compact
                        size="mini"
                        active={this.state.multipleStoresMode}
                        onClick={() => {
                          this.selectAllStores();
                        }}
                      >
                        {this.state.multipleStoresMode
                          ? "Stop editing multiple"
                          : "Select All"}
                      </Button>
                      <small>
                        {this.state.multipleStoresMode
                          ? "Click on stores to select/deselect"
                          : ""}
                      </small>
                      <List divided selection size="large">
                        {employees.map((employee, index) => {
                          const storeIndex = sessionStores.findIndex(
                            (store) => {
                              return store.store_id === employee.store.id;
                            }
                          );
                          if (
                            sessionStores[storeIndex] &&
                            sessionStores[storeIndex].permissions.includes(
                              Permission.ADMIN_ADD_EMPLOYEE
                            )
                          ) {
                            this.storeMap.set(
                              employee.store.name,
                              employee.store
                            );
                            const isSelected = this.state.multipleStoresMode
                              ? this.state.selectedStores.indexOf(
                                  employee.store.id
                                ) > -1
                                ? "selected"
                                : ""
                              : this.state.selectedStore === employee.store.id
                              ? "selected"
                              : "";
                            return (
                              <List.Item
                                key={employee.store.id}
                                className={isSelected}
                                onClick={(evt, { content }) => {
                                  const storeId = this.storeMap.get(
                                    content.toString()
                                  ).id;
                                  if (this.state.multipleStoresMode) {
                                    this.setState((prevState) => ({
                                      selectedStores:
                                        this.state.selectedStores.indexOf(
                                          employee.store.id
                                        ) > -1
                                          ? [
                                              ...prevState.selectedStores.filter(
                                                (val) =>
                                                  val !== employee.store.id
                                              ),
                                            ]
                                          : [
                                              ...prevState.selectedStores,
                                              employee.store.id,
                                            ],
                                      rolesList:
                                        this.rolesMap.get(storeId) || [],
                                    }));
                                  } else {
                                    this.setState({
                                      selectedStore: employee.store.id,
                                      rolesList:
                                        this.rolesMap.get(storeId) || [],
                                    });
                                  }
                                }}
                                icon={
                                  this.rolesMap.has(employee.store.id)
                                    ? "user circle"
                                    : null
                                }
                                content={employee.store.name}
                              />
                            );
                          }
                          return false;
                        })}
                      </List>
                    </Table.Cell>
                    <Dimmer.Dimmable
                      as={Table.Cell}
                      verticalAlign="top"
                      dimmed={dimmed}
                    >
                      <Dimmer inverted active={dimmed}>
                        <Header as="h2" icon>
                          <Icon name="arrow circle left" />
                          Select a store to add roles &amp; permissions
                        </Header>
                      </Dimmer>

                      {this.state.multipleStoresMode && (
                        <Message size="mini" info>
                          <Message.Header>
                            Currently setting permissions for multiple stores
                          </Message.Header>
                        </Message>
                      )}
                      <RolesComponent
                        {...{
                          roles: props.roles,
                          selectedRoles: this.state.rolesList,
                          onSelectRole: (selectedRole: number) => {
                            if (
                              this.state.rolesList.indexOf(selectedRole) === -1
                            ) {
                              const newRoles = [selectedRole].concat(
                                this.state.rolesList
                              );

                              // if multiple stores selected set new roles in roles map for each
                              if (this.state.multipleStoresMode) {
                                this.state.selectedStores.forEach((store) => {
                                  this.rolesMap.set(store, newRoles);
                                });
                              } else {
                                this.rolesMap.set(
                                  this.state.selectedStore,
                                  newRoles
                                );
                              }

                              this.setState({
                                permissionsMap: this.buildPermissionsMap(
                                  newRoles,
                                  storePermissions
                                ),
                                rolesList: newRoles,
                              });
                            }
                          },
                          onRemoveRole: (RRole: number) => {
                            const newRoles = [].concat(this.state.rolesList);
                            const index = newRoles.indexOf(RRole);
                            newRoles.splice(index, 1);

                            // if multiple stores selected set new roles in roles map for each
                            if (this.state.multipleStoresMode) {
                              this.state.selectedStores.forEach((store) => {
                                this.rolesMap.set(store, newRoles);
                              });
                            } else {
                              this.rolesMap.set(
                                this.state.selectedStore,
                                newRoles
                              );
                            }

                            this.setState({
                              permissionsMap: this.buildPermissionsMap(
                                newRoles,
                                storePermissions
                              ),
                              rolesList: newRoles,
                            });
                          },
                          onEditPermissions: () => {
                            this.setState({ editingPermissions: true });
                          },
                        }}
                      />
                      <Dimmer.Dimmable as="div" dimmed={permDimmed}>
                        <Dimmer inverted active={permDimmed}>
                          <Header as="h3" icon>
                            <Icon name="arrow circle up" />
                            Add a role to view permissions
                          </Header>
                        </Dimmer>
                        <div className="permissions">
                          <PermissionsComponent {...permObj} />
                        </div>
                      </Dimmer.Dimmable>
                    </Dimmer.Dimmable>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Container textAlign="right" className="controls">
                <Button.Group>
                  <Button
                    positive
                    size="large"
                    className="submit-btn"
                    type="submit"
                    disabled={this.rolesMap.size <= 0}
                    onClick={() => {
                      const perms = [];
                      this.storeMap.forEach((store, storeName) => {
                        const storeData = {
                          id: store.id,
                          roles: this.rolesMap.has(store.id)
                            ? this.rolesMap.get(store.id)
                            : [],
                          changed: this.changedPermissionsMap.has(store.id)
                            ? this.changedPermissionsMap.get(store.id)
                            : [],
                        };
                        // Only add stores that roles/permissions setup
                        if (
                          storeData.roles.length > 0 ||
                          storeData.changed.length > 0
                        ) {
                          perms.push(storeData);
                        }
                      });
                      props.onSubmit(perms);
                    }}
                  >
                    {props.buttonLabel}
                  </Button>
                </Button.Group>
              </Container>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    );
  }

  selectAllStores() {
    if (!this.state.multipleStoresMode) {
      const stores = [];
      this.props.user.employees.forEach((store) => {
        stores.push(store.store.id);
      });
      this.setState({
        multipleStoresMode: true,
        selectedStore: null,
        selectedStores: stores,
      });
    } else {
      this.setState({
        multipleStoresMode: false,
        selectedStores: [],
      });
    }
  }

  buildPermissionsMap(rolesList: any[], storePermissions: number[]) {
    let newPerms: number[] = [];
    rolesList.forEach((selectedRole) => {
      const rolePerms = this.props.roles.find((role: LMI.IRoleGQL): boolean => {
        return role.id === selectedRole;
      }).permissions;
      newPerms = storePermissions.concat(
        rolePerms.map((perm) => {
          return perm.id;
        })
      );
    });

    // List of changed permissions
    const changedPerms =
      this.changedPermissionsMap.get(this.state.selectedStore) || [];
    // For each changed permission
    changedPerms.forEach((changed, index) => {
      // Does it already exist in the new list of permissions?
      const changedPermIndex: number = newPerms.findIndex((newPerm) => {
        return newPerm === changed.id;
      });
      // If it does
      if (changedPermIndex > -1) {
        if (changed.add_or_remove === "R") {
          // Remove it from the new perms list
          newPerms.splice(changedPermIndex, 1);
        }
      } else {
        if (changed.add_or_remove === "A") {
          // Add it to the new permissions list
          newPerms.push(changed.id);
        }
      }
    });

    // Return the new permissions map
    const permMap = new Map<number, number[]>(this.state.permissionsMap);

    // If setting for multiple
    if (this.state.multipleStoresMode) {
      this.state.selectedStores.forEach((store) => {
        permMap.set(store, newPerms);
      });
    } else {
      permMap.set(
        this.state.selectedStore,
        this.filterDuplicatePermissions(newPerms)
      );
    }

    return permMap;
  }

  filterDuplicatePermissions(permissions: number[]): number[] {
    const dupes = [];
    const perms = [].concat(permissions).filter((perm) => {
      if (dupes.indexOf(perm) === -1) {
        dupes.push(perm);
        return true;
      }
      return false;
    });
    return perms;
  }

  tallyPermissionsChanges(data: any, perm: LMI.IPermissionsGQL, store: number) {
    const changedPerms = this.changedPermissionsMap.get(store) || [];
    const changedPermIndex: number = changedPerms.findIndex((changedPerm) => {
      return changedPerm.id === perm.id;
    });
    if (changedPermIndex > -1) {
      changedPerms.splice(changedPermIndex, 1);
    } else {
      changedPerms.push({
        id: perm.id,
        add_or_remove: data.checked ? "R" : "A",
      });
    }
    this.changedPermissionsMap.set(store, changedPerms);
  }

  editPermissionsCB(
    data: any,
    perm: LMI.IPermissionsGQL,
    storePermissions: number[]
  ): void {
    const store = this.state.multipleStoresMode
      ? this.state.selectedStores[0]
      : this.state.selectedStore;

    // Keep track of changes to role permissions
    if (this.state.multipleStoresMode) {
      this.state.selectedStores.forEach((eachStore) => {
        this.tallyPermissionsChanges(data, perm, eachStore);
      });
    } else {
      this.tallyPermissionsChanges(data, perm, store);
    }

    // Update the current list of selected store permissions
    const permIndex: number = storePermissions.findIndex((permId: number) => {
      return permId === perm.id;
    });
    if (permIndex > -1) {
      storePermissions.splice(permIndex, 1);
    } else {
      storePermissions.push(perm.id);
    }

    // update the permissionsMap consider multiple stores editing
    const permMap = new Map<number, number[]>(this.state.permissionsMap);
    if (this.state.multipleStoresMode) {
      this.state.selectedStores.forEach((each) => {
        permMap.set(each, storePermissions);
      });
    } else {
      permMap.set(store, this.filterDuplicatePermissions(storePermissions));
    }
    this.setState({
      permissionsMap: permMap,
    });
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const SelectStoresComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IListQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.list,
    {
      options: (props: any) => {
        return {
          variables: {
            userId: props.userId,
            email: props.email,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, user, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true };
        }

        return {
          user,
          refetch,
        };
      },
    }
  ),
  graphql<LMI.IPermissionsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.permissions,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: stringToInt(localStorage.getItem("selectedStoreId")),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, permissions, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true, error };
        }

        return {
          permissions,
          pRefetch: refetch,
        };
      },
    }
  ),
  graphql<LMI.IRolesQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.roles,
    {
      options: (props: any) => {
        const storeType = stringToInt(
          localStorage.getItem("selectedStoreType")
        );
        return {
          variables: { storeType },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, roles, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true, error };
        }
        return {
          roles,
          rRefetch: refetch,
        };
      },
    }
  )
)(SelectStoresComponentView) as React.ComponentType<any>;

export default SelectStoresComponent;
