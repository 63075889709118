// node_modules
import * as React from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { Button, Dimmer } from "semantic-ui-react";
import { MenuItem } from "./components/MenuItem";
import { MenuSubItem } from "./components/MenuSubItem";
import { SubMenuElements } from "./components/SubMenuElements";
import { FormatMenuData } from "./utils";
import { toggleAddInventoryDialog, updateCurrentRoute } from "api/redux/actions";
import ToursIntroPopup, { ToursIntroPopupProps } from "../Tours/ToursIntroPopup";
import "./Menu.css";

export class MenuView extends React.Component<LMI.LmiMenuProps, LMI.LmiMenuState> {
	instance = React.createRef<HTMLUListElement>();
	static readonly DELAY = 150;
	static defaultProps = {
		tolerance: 100,
		direction: "RIGHT",
		styleConfig: {}
	};

	static getDerivedStateFromProps(nextProps: LMI.LmiMenuProps, prevState: LMI.LmiMenuState) {
		if (nextProps.routes && nextProps.count !== prevState.count) {
			const routes = nextProps.routes;
			return {
				count: nextProps.count,
				items: FormatMenuData(routes)
			};
		}
		return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.openMenu && !this.state.openMenu) this.setState({ activeRow: -1 });
		if (this.state.items) {
			const splitPath = window.location.pathname.split("/").filter(item => item);
			if (splitPath && splitPath.length > 3) {
				const parentRoute = this.state.items.find(i => i.key === splitPath[splitPath.length - 2]);
				if (parentRoute) {
					const subRouteIndex = parentRoute && parentRoute.items.findIndex(item => item.key === splitPath[splitPath.length - 1]);
					this.setSubTabIndex(parentRoute.subTabContainer, subRouteIndex);
				}
			}
		}
	}

	setSubTabIndex(container, index) {
		if (container) localStorage.setItem(`${container}_defaultTabIndex`, index.toString());
	}

	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			items: [],
			activeRow: -1,
			mouseLocs: [],
			openMenu: false,
			tourMenu: false
		};
	}

	mouseLeave = () => {
		this.dismissTimeout();
		const { onExit } = this.props;
		if (onExit) {
			onExit();
		}
		if (!this.state.tourMenu) this.setState({ activeRow: -1 });
	};

	mouseEnterRow(row: number) {
		if (!this.state.tourMenu)
			return () => {
				this.dismissTimeout();
				this.possiblyActivate(row);
			};
	}

	possiblyActivate(row: number) {
		const delay = this.getActivationDelay();
		if (delay) {
			const timeoutID = setTimeout(() => {
				this.possiblyActivate(row);
			}, delay);
			this.setState({ timeoutID } as any);
		} else {
			this.activate(row);
		}
	}

	activate(row: number) {
		const { activeRow } = this.state;
		if (row === activeRow) {
			return;
		}
		this.setState({ activeRow: row });
	}

	genCoords(x: number, y: number): LMI.LmiMenuMouseCoords {
		return { x, y };
	}

	recordMouse = (e: React.MouseEvent) => {
		const x = e.pageX,
			y = e.pageY;

		this.setState(prevState => {
			const mouseLocs = [...prevState.mouseLocs];
			mouseLocs.push({ x, y });
			if (mouseLocs.length > 8) {
				mouseLocs.shift();
			}
			return { mouseLocs };
		});
	};

	calcSlope(a: LMI.LmiMenuMouseCoords, b: LMI.LmiMenuMouseCoords) {
		return (b.y - a.y) / (b.x - a.x);
	}

	enterSubMenu = () => {
		this.dismissTimeout();
	};

	dismissTimeout() {
		const { timeoutID } = this.state;
		if (timeoutID) {
			clearTimeout(timeoutID);
		}
	}

	getActivationDelay() {
		const { activeRow } = this.state;
		const { tolerance } = this.props;
		if (activeRow < 0) {
			return 0;
		}

		const bounds = this.instance.current!.getBoundingClientRect();
		const upperLeft = this.genCoords(bounds.left, bounds.top - (tolerance || 0)),
			upperRight = this.genCoords(bounds.left + bounds.width, upperLeft.y),
			lowerLeft = this.genCoords(bounds.left, bounds.top + bounds.height + (tolerance || 0)),
			lowerRight = this.genCoords(bounds.left + bounds.width, lowerLeft.y);

		const { mouseLocs } = this.state;
		const loc = mouseLocs[mouseLocs.length - 1];
		let prevLoc = mouseLocs[0];

		if (!loc) return 0;
		if (!prevLoc) prevLoc = loc;
		if (prevLoc.x < bounds.left || prevLoc.x > lowerRight.x || prevLoc.y < bounds.top || prevLoc.y > lowerRight.y) return 0;
		const { lastDelayLoc } = this.state;
		if (lastDelayLoc && loc.x === lastDelayLoc.x && loc.y === lastDelayLoc.y) return 0;

		let decreasingCorner, increasingCorner;
		switch (this.props.direction) {
			case "LEFT":
			default:
				{
					decreasingCorner = lowerRight;
					increasingCorner = upperRight;
				}
				break;
			case "RIGHT":
				{
					decreasingCorner = upperLeft;
					increasingCorner = lowerLeft;
				}
				break;
		}

		const decreasingSlope = this.calcSlope(loc, decreasingCorner),
			increasingSlope = this.calcSlope(loc, increasingCorner),
			prevDecreasingSlope = this.calcSlope(prevLoc, decreasingCorner),
			prevIncreasingSlope = this.calcSlope(prevLoc, increasingCorner);

		if (decreasingSlope < prevDecreasingSlope && increasingSlope > prevIncreasingSlope) {
			this.setState({ lastDelayLoc: loc });
			return LmiMenu.DELAY;
		}

		this.setState({ lastDelayLoc: undefined });
		return 0;
	}

	render() {
		const { openMenu, tourMenu, activeRow, items } = this.state;
		const hasSubMenu = activeRow > -1 && items[activeRow].items && items[activeRow].items.length > 0;

		return [
			<span key="tour">{this.tourControl()}</span>,
			<div key="lmimenu" id="LMIMegaMenu" className={`lmi-menu${this.props.mini ? " mini" : ""}`} onMouseLeave={() => this.setState({ openMenu: false })}>
				<ToursIntroPopup
					{...({
						route: "/feature-tours",
						tourId: 2,
						name: "appMenuIntro",
						popupProps: {
							position: "left center",
							className: "bring-top"
						},
						disableTriggerElemId: "LMIMenuButton",
						trigger: () => (
							<Button
								id="LMIMenuButton"
								className="menu-button large"
								onClick={() => this.setState({ openMenu: true })}
								onMouseEnter={() => this.setState({ openMenu: true })}
								icon="sidebar"
							/>
						)
					} as ToursIntroPopupProps)}
				/>
				{openMenu || tourMenu ? (
					<div className="LMIMenu">
						<div id="LMIMenu" className="menu" onMouseLeave={this.mouseLeave}>
							<ul className={`menuUL ${hasSubMenu ? "has-multiple" : ""}`} ref={this.instance} onMouseMove={this.recordMouse}>
								{items.map(({ label, key, path, isNew, menuActions }, i) => (
									<MenuItem
										key={key}
										path={path}
										label={label}
										isNew={isNew}
										selected={i === activeRow}
										mouseEntered={this.mouseEnterRow(i)}
										itemClicked={() => this.setState({ activeRow: -1, openMenu: null }, () => this.props.updateCurrentRoute(label))}
										actions={menuActions}
										actionClicked={action => this.setState({ openMenu: null }, () => this.fireMenuAction(action.action))}
									/>
								))}
							</ul>
							{hasSubMenu && (
								<div onMouseEnter={this.enterSubMenu} className="subMenu">
									<div className="subMenuTitle">{items[activeRow].label}</div>
									<ul className="subMenuUL">
										{items[activeRow].items.map((sub, key) => {
											return (
												<MenuSubItem
													key={key}
													label={sub.label}
													isNew={sub.isNew}
													path={sub.path}
													itemClicked={() => this.setState({ activeRow: -1, openMenu: null })}
												/>
											);
										})}
									</ul>
									<SubMenuElements {...items[activeRow].subMenu} />
								</div>
							)}
						</div>
					</div>
				) : (
					<span />
				)}
			</div>,
			<Dimmer key="menudimmer" className="menuDimmer" active={openMenu} />
		];
	}

	fireMenuAction(action) {
		const fireAction = this.props[action];
		if (fireAction) fireAction();
	}

	tourControl() {
		const { items } = this.state;
		if (items && items.length >= 1) {
			// find a menu option with items to open submenu action
			const subItems = items.filter(i => i.items.length >= 1);
			const activeRow = subItems.length >= 1 ? items.findIndex(i => i.id === subItems[0].id) : -1;
			return (
				<>
					<span id="OpenMenuTour" onClick={() => this.setState({ tourMenu: true, activeRow: -1 })} />
					<span id="OpenSubMenuTour" onClick={() => this.setState({ activeRow })} />
					<span id="CloseMenuTour" onClick={() => this.setState({ tourMenu: false, activeRow: -1 })} />
				</>
			);
		}
	}
}

const mapStateToProps = (state: any) => {
	const count = state.app.admin.routes.length + state.app.admin.storeName.length;
	return {
		routes: state.app.admin.routes,
		count,
		storeName: state.app.admin.storeName,
		storeId: state.app.admin.storeId,
		showUAB: state.app.admin.isUABEnabled,
		permissions: state.app.admin.permissions
	};
};

const matchDispatchToProps = (dispatch: any) => {
	return {
		updateCurrentRoute: data => {
			dispatch(updateCurrentRoute(data));
		},
		toggleAddInventoryDialog: () => {
			dispatch(toggleAddInventoryDialog(true));
		}
	};
};

export const LmiMenu = compose(connect(mapStateToProps, matchDispatchToProps))(MenuView);
export default LmiMenu;
