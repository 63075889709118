import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
// components
import {
	ColumnDirective,
	ColumnsDirective,
	CommandColumn,
	Filter,
	GridComponent,
	Group,
	Inject,
	Page,
	Search,
	SelectionSettingsModel,
	Sort,
	Toolbar
} from "@syncfusion/ej2-react-grids";
import "@syncfusion/ej2-react-grids/styles/material.css";
// node_modules
import * as React from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { Loader, Message } from "semantic-ui-react";

import "./VehicleDocument.css";

export class VehicleDocumentGridView extends React.Component<LMI.VehDocGridProps, LMI.VehDocGridState> {
	grid: GridComponent;
	cellSelectionSettings: SelectionSettingsModel = { cellSelectionMode: "Box", type: "Single", mode: "Both" };
	customPhotoAttributes: any = { class: "custom-photos" };
	customAgeAttributes: any = { class: "age" };
	filterSettings: any = { type: "CheckBox" };

	constructor(props) {
		super(props);

		this.state = {
			noneFound: null
		};
	}

	shouldComponentUpdate(nextProps: LMI.VehDocGridProps) {
		if (
			!this.grid ||
			this.grid.selectedRowIndex < 0 ||
			this.grid.selectedRowIndex === null ||
			nextProps.vehicle_documents.documents !== this.props.vehicle_documents.documents
		) {
			return true;
		}
		return this.grid.selectedRowIndex !== nextProps.selectedDocumentIndex ? true : false;
	}

	render() {
		const { vehicle_documents, gridheight, selectedDocumentIndex } = this.props;

		if (!vehicle_documents) {
			return <Loader />;
		}

		const documentList =
			vehicle_documents &&
			vehicle_documents.documents.map(document => {
				const display = this.generatedGridData(document);
				return {
					...document,
					...display
				};
			});

		return (
			<div id="DocumentGrid" className={`grid-container searchable-grid ${this.props.superuser ? "super" : ""}`}>
				<GridComponent
					dataSource={documentList}
					enableHover={true}
					allowSelection={true}
					allowSorting={true}
					selectionSettings={this.cellSelectionSettings}
					selectedRowIndex={selectedDocumentIndex ? selectedDocumentIndex : null}
					cellSelecting={this.cellSelection}
					toolbar={this.toolbarOptions()}
					toolbarClick={this.onToolbarClick.bind(this)}
					allowPaging={false}
					allowFiltering={true}
					filterSettings={this.filterSettings}
					allowTextWrap={true}
					beforeDataBound={this.onBeforeDataBound}
					allowGrouping={true}
					groupSettings={this.getGroupOptions()}
					ref={g => (this.grid = g)}
					height={gridheight ? gridheight : "100%"}
					gridLines="Both"
				>
					{this.noResults()}
					{this.getColumnsDirective()}
					<Inject services={[Group, Toolbar, Search, Filter, Sort, Page, CommandColumn]} />
				</GridComponent>
			</div>
		);
	}

	getColumnsDirective = () => {
		const { superuser } = this.props;
		if (superuser) {
			return (
				<ColumnsDirective>
					{/* <ColumnDirective
						field="default_image_url"
						headerText="Photo"
						textAlign="Center"
						width="50"
						template={this.getPhotoTemplate}
						customAttributes={this.customPhotoAttributes}
						allowFiltering={false}
						allowSorting={false}
					/> */}
					<ColumnDirective field="name" headerText="Name" width="70" textAlign="Center" />
					<ColumnDirective field="identifier" headerText="Identifier" width="70" textAlign="Center" />
					<ColumnDirective field="year" headerText="Year" width="70" textAlign="Center" />
					<ColumnDirective field="make" headerText="Make" width="70" textAlign="Center" />
					<ColumnDirective field="model" headerText="Model" width="70" textAlign="Center" />
					<ColumnDirective field="level" headerText="Level" width="70" textAlign="Center" />
					<ColumnDirective field="type" headerText="Type" width="70" textAlign="Center" />
				</ColumnsDirective>
			);
		} else {
			return (
				<ColumnsDirective>
					<ColumnDirective
						field="default_image_url"
						headerText="Photo"
						textAlign="Center"
						width="50"
						template={this.getPhotoTemplate}
						customAttributes={this.customPhotoAttributes}
						allowFiltering={false}
						allowSorting={false}
					/>
					<ColumnDirective field="name" headerText="Name" width="70" textAlign="Center" />
					<ColumnDirective field="condition" headerText="Condition" width="70" textAlign="Center" />
					<ColumnDirective field="make" headerText="Make" width="70" textAlign="Center" />
					<ColumnDirective field="level" headerText="Level" width="70" textAlign="Center" />
					<ColumnDirective field="type" headerText="Type" width="70" textAlign="Center" />
				</ColumnsDirective>
			);
		}
	};

	generatedGridData = (doc: LMI.VehicleDocumentProps) => {
		const year = doc.years ? doc.years.join(", ") : null;
		const hasModels = doc.models && doc.models.length > 0 ? doc.models[0] : null;
		const model = hasModels ? doc.models.map(m => m.name).join(", ") : null;
		const vehicleText = [year, doc.make, model].filter(Boolean).join(" ");
		const { vin, inventory_item_id } = doc;
		const identifier = vehicleText ? vehicleText : vin ? vin : inventory_item_id ? inventory_item_id : null;
		const level = doc.organization_id !== null ? "Organization" : doc.store_id ? "Store" : "Global";

		return {
			type: doc.document_type_name,
			year,
			model,
			identifier,
			level
		};
	};

	toolbarOptions = () => {
		const tools: any = [
			{ text: "CLEAR FILTERS", id: "reset", cssClass: "reset-grid", align: "Right" },
			{ tooltipText: "Expand All Groups", prefixIcon: "e-icons e-expand", id: "expand" },
			{ tooltipText: "Collapse All Groups", prefixIcon: "e-icons e-collapse", id: "collapse" }
		];
		const { vehicle_documents } = this.props;
		if (vehicle_documents && vehicle_documents.documents.length > 0) tools.unshift({ text: "Search", id: "search" });
		return tools;
	};

	onToolbarClick(args) {
		switch (args.item.id) {
			case "expand":
				this.grid.groupModule.expandAll();
				break;
			case "collapse":
				this.grid.groupModule.collapseAll();
				break;
			case "reset":
				this.grid.searchSettings.key = "";
				this.props.onFilterSelect && this.props.onFilterSelect({ years: null, makes: null, models: null });
				this.grid.clearFiltering();
				break;
			default:
				break;
		}
	}

	groupTemplate = props => {
		if (props.count === 1) {
			return (
				<span>
					{props.key} - {props.count} item
				</span>
			);
		} else {
			return (
				<span>
					{props.key} - {props.count} items
				</span>
			);
		}
	};

	getGroupOptions = () => {
		return { showGroupedColumn: false, captionTemplate: this.groupTemplate } as any;
	};

	noResults() {
		if (this.state.noneFound) {
			return (
				<div id="no-results" className="top in" style={{ top: "420px" }}>
					<Message content="Currently no Vehicle Documents uploaded" />
				</div>
			);
		}
	}

	onBeforeDataBound = (args): void => {
		if (args.count > 0) {
			const fn = () => {
				this.grid.showSpinner();
				this.grid.off("toolbar-refresh", fn);
			};
			this.grid.on("toolbar-refresh", fn);
		} else if (args.count <= 0) {
			this.grid.hideSpinner();
		} else {
			this.grid.hideSpinner();
		}
	};

	cellSelection = (args: any) => {
		if (args.cellIndex.rowIndex >= 0) {
			const data = {
				selectedDocument: args.data,
				selectedDocumentIndex: args.cellIndex.rowIndex
			};
			this.props.onSelect(data);
		}
	};

	getPhotoTemplate = (props): any => {
		return (
			<div className="vehicle-image">
				<img src={`${props.full_url && props.full_url.includes("imgix") ? props.full_url : props.default_image_url}?h=50&fm=png`} alt={props.name} />
			</div>
		);
	};
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const VehicleDocumentGrid = compose(connect(mapStateToProps, mapDispatchToProps))(VehicleDocumentGridView);

export default VehicleDocumentGrid;