import * as React from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { Loading } from "../../../../../../components/Loading";
import { restAPI } from "../../../../../../utils/rest";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets from "@amcharts/amcharts4/plugins/bullets";

import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { LightboxComponent } from "../../../../../../components/ImageHandling/LightboxComponent";
import { RelativeDatePipe } from "../../../../../../utils/functions";
import { addAlert } from "../../../../../../../api/redux/actions";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";
import "./Timeline.css";

am4core.useTheme(am4themes_animated);

@track(
  {
    event_type: AnalyticsEventType.NAVIGATION,
    event_subtype: "recon.inventory.timeline",
  },
  { dispatchOnMount: true }
)
export class TimelineComponentView extends React.Component<
  LMI.IInventoryTimelineTabProps,
  LMI.IInventoryTimelineTabState
> {
  chart: any;
  chartData: any = [];

  constructor(props: LMI.IInventoryTimelineTabProps) {
    super(props);
    this.state = {
      loading: false,
      notes: null,
      has_notes: false,
      lightbox_photo_index: 0,
      lightbox_open: false,
      lightbox_photos: [],
      chart_should_load: false,
    };
  }

  componentDidMount(): void {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true });
    restAPI({
      endpointName: "get_inventory_notes",
      urlArgs: [this.props.storeId, this.props.inventory_item_id],
      data: null,
      callback: (error, result) => {
        let alert;
        if (error) {
          alert = {
            type: "danger",
            message: this.props.onHandleErrorResponse(error),
          };
          this.props.onAlert(alert);
        } else {
          let hasNotes = false;
          for (const d in result.data) {
            if (result.data[d].length > 0) {
              hasNotes = true;
              break;
            }
          }
          this.setState(
            { notes: result.data, loading: false, has_notes: hasNotes },
            () => this.configureChart()
          );
        }
      },
    });
  };

  componentWillUnmount() {
    if (this.chart) {
      // console.log("Dispose of the timeline.");
      this.chart.dispose();
    }
  }

  generateAvatar = (text, foregroundColor, backgroundColor) => {
    let initials;
    if (text) {
      const words = text.toUpperCase().split(" ");
      initials = words
        .map((w, index) => {
          if (index < 2 && index < words.length - 1)
            return w.substr(0, 1) + String.fromCharCode(8202);
          else if (index === 2 || (index < 2 && index === words.length - 1))
            return w.substr(0, 1);
        })
        .join("");
    } else {
      initials = "Not Set";
    }
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = 400;
    canvas.height = 400;

    // Draw background
    context.fillStyle = backgroundColor;
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.font = "bold 14rem Lato";
    context.fillStyle = foregroundColor;
    context.textAlign = "center";
    context.textBaseline = "middle";

    context.fillText(initials, canvas.width / 2, canvas.height / 2);

    return canvas.toDataURL("image/png");
  };

  configureChart = () => {
    const { timelineFlags, phases } = this.props;
    const { notes } = this.state;
    const {
      phase,
      vehicle_note,
      inspection_note,
      misc,
      vendor_task,
      issue,
      task_description,
    } = notes && notes;
    const colorSet = new am4core.ColorSet();
    colorSet.saturation = 0.5;

    // tslint:disable-next-line:one-variable-per-declaration
    let minDate, maxDate;

    const phaseData = phase
      ? phase.map((p, index) => {
          const stageDescription =
            p.description && p.description.split("Stage changed to ")[1];
          const phaseName = stageDescription && stageDescription.trim();
          const phaseSettings = phases.filter((p) => p.name === phaseName)[0];
          const timerOff =
            phaseSettings &&
            (phaseSettings.final_stage || !phaseSettings.work_timer_enabled);
          const color = timerOff
            ? am4core.color("#ddd")
            : phaseSettings && phaseSettings.color_code
            ? am4core.color(phaseSettings.color_code)
            : colorSet.getIndex(index * 2);
          const created = new Date(p.created_at);
          if (!minDate || created < minDate) {
            minDate = created;
          }
          if (!maxDate || created > maxDate) {
            maxDate = created;
          }
          const start = new Date(p.created_at);
          const end = phase[index - 1]
            ? new Date(phase[index - 1].created_at)
            : new Date();
          return {
            timer_off: timerOff,
            category: phaseName,
            color,
            start,
            end,
            icon: this.generateAvatar(phaseName, "#000", "transparent"),
            task: `${p.description || p.comment} by ${p.first_name} ${
              p.last_name
            }\n${RelativeDatePipe(p.created_at, false, false, true)}\n(${
              timerOff
                ? "TIMER NOT RUNNING"
                : RelativeDatePipe(start, true, false, false) +
                  " - " +
                  RelativeDatePipe(end, true, false, false)
            })`,
          };
        })
      : [];

    const notesData =
      timelineFlags && timelineFlags["Vehicle_Notes"] && vehicle_note
        ? vehicle_note.map((p, index) => {
            const created = new Date(p.created_at);
            if (!minDate || created < minDate) {
              minDate = created;
            }
            if (!maxDate || created > maxDate) {
              maxDate = created;
            }

            // Truncate long comments
            p.comment =
              p.comment && p.comment.length > 100
                ? p.comment.substring(0, 100) + "..."
                : p.comment;

            return {
              category: "",
              fill: "pink",
              label: "[#FF9999]Note[/]",
              eventDate: new Date(p.created_at),
              description: `[white]${p.comment ? p.comment : ""}${
                p.description ? "\n" + p.description : ""
              } - ${p.first_name} ${p.last_name}\n${RelativeDatePipe(
                p.created_at,
                false,
                false,
                true
              )}[/]`,
              data_photo_url: p.data_photo_url
                ? `${p.data_photo_url}?q=90&ch=Save-Data&auto=compress`
                : true,
              image_config: {
                disabled: p.data_photo_url === null,
              },
            };
          })
        : [];

    const vendorsData =
      timelineFlags && timelineFlags["Vendors"] && vendor_task
        ? vendor_task.map((p, index) => {
            const created = new Date(p.created_at);
            if (!minDate || created < minDate) {
              minDate = created;
            }
            if (!maxDate || created > maxDate) {
              maxDate = created;
            }

            return {
              category: "",
              fill: "green",
              label: "[green]Vendor[/]",
              eventDate: new Date(p.created_at),
              description: `[white]${p.comment ? p.comment : ""}${
                p.description ? "\n" + p.description : ""
              } - ${p.first_name} ${p.last_name}\n${RelativeDatePipe(
                p.created_at,
                false,
                false,
                true
              )}[/]`,
              data_photo_url: p.data_photo_url
                ? `${p.data_photo_url}?q=90&ch=Save-Data&auto=compress`
                : true,
              image_config: {
                disabled: p.data_photo_url === null,
              },
            };
          })
        : [];

    const tasksData =
      timelineFlags && timelineFlags["Tasks"] && task_description
        ? task_description.map((p, index) => {
            const created = new Date(p.created_at);
            if (!minDate || created < minDate) {
              minDate = created;
            }
            if (!maxDate || created > maxDate) {
              maxDate = created;
            }

            return {
              category: "",
              fill: "orange",
              label: "[orange]Task[/]",
              eventDate: new Date(p.created_at),
              description: `[white]${
                p.comment && p.comment != "N/A" ? p.comment : ""
              }${p.description ? "\n" + p.description : ""} - ${p.first_name} ${
                p.last_name
              }\n${RelativeDatePipe(p.created_at, false, false, true)}[/]`,
              data_photo_url: p.data_photo_url
                ? `${p.data_photo_url}?q=90&ch=Save-Data&auto=compress`
                : true,
              image_config: {
                disabled: p.data_photo_url === null,
              },
            };
          })
        : [];

    const issueData =
      timelineFlags && timelineFlags["Issues"] && issue
        ? issue.map((p, index) => {
            const created = new Date(p.created_at);
            if (!minDate || created < minDate) {
              minDate = created;
            }
            if (!maxDate || created > maxDate) {
              maxDate = created;
            }

            return {
              category: "",
              fill: "yellow",
              label: "[#8D8D01]Issue[/]",
              eventDate: new Date(p.created_at),
              description: `[white]${p.comment ? p.comment : ""}${
                p.description ? "\n" + p.description : ""
              } - ${p.first_name} ${p.last_name}\n${RelativeDatePipe(
                p.created_at,
                false,
                false,
                true
              )}[/]`,
              data_photo_url: p.data_photo_url
                ? `${p.data_photo_url}?q=90&ch=Save-Data&auto=compress`
                : true,
              image_config: {
                disabled: p.data_photo_url === null,
              },
            };
          })
        : [];

    const inspectionData =
      timelineFlags && timelineFlags["Inspections"] && inspection_note
        ? inspection_note.map((p, index) => {
            const created = new Date(p.created_at);
            if (!minDate || created < minDate) {
              minDate = created;
            }
            if (!maxDate || created > maxDate) {
              maxDate = created;
            }

            const assignedUser =
              p.assigned_user &&
              p.assigned_user.last_name &&
              " " +
                p.assigned_user.first_name +
                " " +
                p.assigned_user.last_name;

            return {
              category: "",
              fill: "red",
              label: "[red]Inspection[/]",
              eventDate: new Date(p.created_at),
              description: `[white]${p.system_log_type_name}${
                assignedUser ? "\n" + assignedUser : ""
              }${p.description ? "\n" + p.description : ""}\n${RelativeDatePipe(
                p.created_at,
                false,
                false,
                true
              )}[/]`,
              data_photo_url: p.data_photo_url
                ? `${p.data_photo_url}?q=90&ch=Save-Data&auto=compress`
                : true,
              image_config: {
                disabled: p.data_photo_url === null,
              },
            };
          })
        : [];

    const miscData =
      timelineFlags && timelineFlags["Miscellaneous"] && misc
        ? misc.map((p, index) => {
            const created = new Date(p.created_at);
            if (!minDate || created < minDate) {
              minDate = created;
            }
            if (!maxDate || created > maxDate) {
              maxDate = created;
            }

            return {
              category: "",
              fill: "brown",
              label: "[brown]Misc[/]",
              eventDate: new Date(p.created_at),
              description: `[white]${p.description} - ${p.first_name} ${
                p.last_name
              }\n${RelativeDatePipe(p.created_at, false, false, true)}[/]`,
              data_photo_url: p.data_photo_url
                ? `${p.data_photo_url}?q=90&ch=Save-Data&auto=compress`
                : true,
              image_config: {
                disabled: p.data_photo_url === null,
              },
            };
          })
        : [];

    this.chartData = [
      ...notesData,
      ...inspectionData,
      ...miscData,
      ...vendorsData,
      ...issueData,
      ...tasksData,
    ];

    this.setState({
      chart_should_load: this.chartData.length + phaseData.length > 0,
    });

    const chart = am4core.create(
      "chartdiv",
      am4plugins_timeline.SerpentineChart
    );
    chart.curveContainer.padding(50, 20, 50, 20);
    chart.levelCount = 4;
    chart.yAxisRadius = am4core.percent(25);
    chart.yAxisInnerRadius = am4core.percent(-25);
    chart.maskBullets = false;

    chart.data = [...phaseData];
    chart.dateFormatter.dateFormat = "yyyy-MM-dd";
    chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    chart.fontSize = 11;

    // @ts-ignore
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    // @ts-ignore
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.labels.template.paddingRight = 25;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.innerRadius = -60;
    categoryAxis.renderer.radius = 60;

    // @ts-ignore
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 70;
    // @ts-ignore
    dateAxis.baseInterval = { timeUnit: "second", count: 1 };
    dateAxis.renderer.tooltipLocation = 0;
    dateAxis.startLocation = -0.5;
    dateAxis.renderer.line.strokeDasharray = "1,4";
    dateAxis.renderer.line.strokeOpacity = 0.6;
    dateAxis.tooltip.background.fillOpacity = 0.2;
    dateAxis.tooltip.background.cornerRadius = 5;
    dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor(
      "alternativeBackground"
    );
    dateAxis.tooltip.label.paddingTop = 7;

    if (minDate && maxDate) {
      // @ts-ignore
      dateAxis.min = minDate.getTime();
      // @ts-ignore
      dateAxis.max = maxDate.getTime();
    }
    const labelTemplate = dateAxis.renderer.labels.template;
    labelTemplate.verticalCenter = "middle";
    labelTemplate.fillOpacity = 0.7;
    labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor(
      "background"
    );
    labelTemplate.background.fillOpacity = 1;
    labelTemplate.padding(7, 7, 7, 7);

    const series = chart.series.push(
      new am4plugins_timeline.CurveColumnSeries()
    );
    series.columns.template.height = am4core.percent(20);
    series.columns.template.tooltipText = "[white]{task}[/]";

    series.dataFields.openDateX = "start";
    series.dataFields.dateX = "end";
    series.dataFields.categoryY = "category";
    series.columns.template.propertyFields.fill = "color"; // get color from data
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.strokeOpacity = 0;
    series.tooltip.exportable = true;

    const imageBullet1 = series.bullets.push(
      new am4plugins_bullets.PinBullet()
    );
    imageBullet1.background.radius = 20;
    imageBullet1.locationX = 1;
    imageBullet1.propertyFields.stroke = "color";
    imageBullet1.background.propertyFields.fill = "color";
    imageBullet1.image = new am4core.Image();
    imageBullet1.image.propertyFields.href = "icon";
    imageBullet1.image.scale = 0.5;
    imageBullet1.circle.radius = am4core.percent(90);
    imageBullet1.background.fillOpacity = 0.6;
    imageBullet1.background.strokeOpacity = 0;
    imageBullet1.dy = -2;
    imageBullet1.background.pointerBaseWidth = 10;
    imageBullet1.background.pointerLength = 10;
    imageBullet1.tooltipText = "[white]{task}[/]";
    imageBullet1.exportable = true;

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.radius = 3;
    bullet.circle.strokeOpacity = 0;
    bullet.propertyFields.fill = "color";
    bullet.locationX = 0;

    const bullet2 = series.bullets.push(new am4charts.CircleBullet());
    bullet2.circle.radius = 3;
    bullet2.circle.strokeOpacity = 0;
    bullet2.propertyFields.fill = "color";
    bullet2.locationX = 1;

    const eventSeries = chart.series.push(
      new am4plugins_timeline.CurveLineSeries()
    );
    eventSeries.dataFields.dateX = "eventDate";
    eventSeries.dataFields.categoryY = "category";
    eventSeries.data = this.chartData;
    eventSeries.strokeOpacity = 0;
    eventSeries.tooltip.exportable = true;
    eventSeries.exportable = true;

    const photoBullet = eventSeries.bullets.push(
      new am4plugins_bullets.PinBullet()
    );
    photoBullet.configField = "image_config";
    photoBullet.background.radius = 30;
    photoBullet.locationX = 1;
    photoBullet.propertyFields.stroke = "fill";
    photoBullet.background.propertyFields.fill = "fill";
    photoBullet.image = new am4core.Image();
    photoBullet.image.propertyFields.href = "data_photo_url";
    photoBullet.image.scale = 1;
    photoBullet.circle.radius = am4core.percent(90);
    photoBullet.background.fillOpacity = 0.6;
    photoBullet.background.strokeOpacity = 1;
    photoBullet.dy = -2;
    photoBullet.dx = 0;
    photoBullet.background.pointerBaseWidth = 10;
    photoBullet.background.pointerLength = 30;
    photoBullet.background.pointerAngle = 75;
    photoBullet.tooltipText = "{description}";
    photoBullet.exportable = true;

    // onClick for photo bullet
    const _self = this;
    photoBullet.events.on("hit", function (event) {
      const url = (event.target.dataItem.dataContext as any).data_photo_url;
      if (url) {
        _self.setState({ lightbox_open: true, lightbox_photos: [url] });
      }
    });

    const flagBullet = eventSeries.bullets.push(
      new am4plugins_bullets.FlagBullet()
    );
    flagBullet.label.propertyFields.text = "label";
    flagBullet.locationX = 0;
    flagBullet.tooltipText = "{description}";
    flagBullet.fontSize = 9;
    flagBullet.exportable = true;

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.align = "center";
    chart.scrollbarX.width = am4core.percent(85);

    const cursor = new am4plugins_timeline.CurveCursor();
    chart.cursor = cursor;
    cursor.xAxis = dateAxis;
    cursor.yAxis = categoryAxis;
    cursor.lineY.disabled = true;
    cursor.lineX.strokeDasharray = "1,4";
    cursor.lineX.strokeOpacity = 1;

    dateAxis.renderer.tooltipLocation2 = 0;
    categoryAxis.cursorTooltipEnabled = false;

    chart.zoomOutButton.disabled = false;
    chart.scrollbarX = new am4core.Scrollbar();
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "bottom";
    chart.exporting.menu.items = [
      {
        label: "Export",
        menu: [
          {
            label: "Image",
            menu: [
              { type: "png", label: "PNG" },
              { type: "jpg", label: "JPG" },
              { type: "svg", label: "SVG" },
              { type: "pdf", label: "PDF" },
            ],
          },
          {
            label: "Print",
            type: "print",
          },
        ],
      },
    ];

    this.chart = chart;
  };

  render() {
    const {
      notes,
      lightbox_open,
      lightbox_photo_index,
      lightbox_photos,
      has_notes,
      chart_should_load,
    } = this.state;

    if (!notes) {
      return <Loading />;
    }

    return (
      <>
        {!has_notes && !chart_should_load ? (
          <div className="no-notes">
            <img src="/images/workflow.png" />
            <span>No data is available to display on the timeline</span>
          </div>
        ) : has_notes && !chart_should_load ? (
          <div className="no-notes">
            <img src="/images/workflow.png" />
            <span>
              There may be data to display, but those notes sections have been
              disabled for the timeline display. Open the notes panel and enable
              those sections to view the timeline.
            </span>
          </div>
        ) : (
          <></>
        )}
        {lightbox_open && (
          <LightboxComponent
            photo_index={lightbox_photo_index}
            photos={lightbox_photos}
            onCloseLightbox={() =>
              this.setState({
                lightbox_open: false,
                lightbox_photo_index: 0,
                lightbox_photos: [],
              })
            }
          />
        )}
        <div id="chartdiv" />
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    permissions: state.app.admin.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const TimelineView = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(TimelineComponentView);
