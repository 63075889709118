import * as React from "react";
import { useDropzone } from "react-dropzone";
import "./DropZone.css";

export const DropZone = props => {
	const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
		accept: props.accept,
		multiple: props.multiple,
		onDrop: props.onDrop
	});

	const acceptedFileItems = acceptedFiles.map((file: any) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	const fileRejectionItems = fileRejections.map(({ file, errors }: any) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
			<ul>
				{errors.map(e => (
					<li key={e.code}>{e.message}</li>
				))}
			</ul>
		</li>
	));

	const acceptedItems = acceptedFileItems.length > 0;
	const rejectedItems = fileRejectionItems.length > 0;
	const currently = "zone " + (rejectedItems ? "reject" : acceptedItems ? "loaded" : "ready");

	return (
		<div {...getRootProps({ className: "dropzone" })}>
			<div className={currently}>
				<input {...getInputProps()} />
				<p>Drop your file or click here to uploads</p>
				{rejectedItems && (
					<span>
						<h4>Rejected files</h4>
						<ul>{fileRejectionItems}</ul>
					</span>
				)}
			</div>
		</div>
	);
};
