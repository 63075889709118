// node_modules
import * as React from "react";
// components
import { Button, Divider, Label } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { SettingSelector } from "../StoreSettingSelectionComponent/StoreSettingSelectionComponent";
import "./StoreSettingsDetailComponent.css";

class StoreSettingsDetailComponent extends React.Component<LMI.IStoreSettingsDetailsProps, LMI.IStoreSettingsDetailsState> {
	constructor(props) {
		super(props);
		this.state = {
			currentValue: props.onCalculateCurrentValue(props.store_setting),
			organization: props.store_setting.organization_override,
			store: props.store_setting.store_override
		};
	}

	handleOrgChange(e, { value }) {
		const store_value = this.state.store;
		const currentValue = store_value && store_value !== "" ? store_value : value !== "" ? value : this.props.store_setting.default_value;
		this.setState({ organization: value, currentValue });
	}

	handleStoreChange(e, { value }) {
		const org_value = this.state.organization;
		const currentValue = value !== "" ? value : org_value && org_value !== "" ? org_value : this.props.store_setting.default_value;
		this.setState({ store: value, currentValue });
	}

	isRange = value => {
		try {
			const json = JSON.parse(value);
			if (typeof json !== "object") return false;
			return json;
		} catch (e) {
			return false;
		}
	};

	yesNoDisplay = value => {
		const yesNo = ["No", "Yes", "Optional"];
		return yesNo[value];
	};

	render() {
		const { raw_settings, store_setting, store_id, organization_id } = this.props;
		let childProps;
		let childType;
		let childEvent;
		const range_value = store_setting.store_setting_type.value_type === "STRING" ? this.isRange(store_setting.default_value) : false;
		const type = range_value ? "RANGE" : store_setting.store_setting_type.value_type;
		const useYesNo = store_setting.name === "VEHICLE_INFO_REPORT_PAYWALL_LEVEL" ? true : false;

		if (store_setting.child_setting_names && store_setting.child_setting_names.length > 0) {
			const setting = raw_settings.filter(r => r.name === store_setting.child_setting_names[0])[0];
			childProps = Object.assign({}, this.props, { store_setting: setting });
			childType = childProps.store_setting.store_setting_type.value_type;
			childEvent = store_setting.children[0].on;
		}

		return (
			<div id="store-settings-wrapper">
				<Form onSubmit={this.submitForm.bind(this)}>
					<Form.Field>
						<label>Default Value</label>
						{range_value && range_value.length ? (
							<span>
								{range_value.map((r, i) => {
									return <Label key={i}>{`${r[0] ? r[0] : "0"}-${r[1] ? r[1] : "MAX"}`}</Label>;
								})}
							</span>
						) : (
							<Input
								disabled
								name="default_value[0]"
								placeholder="There is no default for this setting"
								value={
									store_setting && store_setting.default_value
										? useYesNo
											? this.yesNoDisplay(store_setting.default_value)
											: store_setting.default_value
										: null
								}
							/>
						)}
					</Form.Field>
					<Form.Field>
						<label>Organization Level Override</label>
						<SettingSelector
							type={type}
							name="organization[0]"
							useYesNo={useYesNo}
							value={store_setting && store_setting.organization_override}
							onChange={this.handleOrgChange.bind(this)}
							onSaveRange={range => this.handleOrgChange(null, { value: range })}
						/>
					</Form.Field>
					<Form.Field>
						<label>Store Level Override</label>
						<SettingSelector
							type={type}
							name="store[0]"
							useYesNo={useYesNo}
							value={store_setting && store_setting.store_override}
							onChange={this.handleStoreChange.bind(this)}
							onSaveRange={range => this.handleStoreChange(null, { value: range })}
						/>
					</Form.Field>

					<span className="hiddenfields">
						{store_setting && store_setting.name ? <Input type="hidden" name="name[0]" value={store_setting.name} /> : ""}
						{store_setting && store_setting.name ? <Input type="hidden" name="index[0]" value={0} /> : ""}
						{store_setting && store_id ? <Input type="hidden" name="store_id" value={store_id} /> : ""}
						{store_setting && organization_id ? <Input type="hidden" name="organization_id" value={organization_id} /> : ""}
					</span>

					{childProps && this.state.currentValue === childEvent && (
						<div id="store-settings-children">
							<Divider />
							<h3 className="child-header">Child Setting: {childProps.store_setting.store_setting_type.label}</h3>
							<Form.Field>
								<label>Default Value</label>
								<Input
									disabled
									name="default_value[1]"
									placeholder="There is no default for this setting"
									value={childProps.store_setting && childProps.store_setting.default_value}
								/>
							</Form.Field>
							<Form.Field>
								<label>Organization Level Override</label>
								<SettingSelector
									type={childType}
									name="organization[1]"
									useYesNo={useYesNo}
									value={childProps.store_setting && childProps.store_setting.organization_override}
								/>
							</Form.Field>
							<Form.Field>
								<label>Store Level Override</label>
								<SettingSelector
									type={childType}
									name="store[1]"
									useYesNo={useYesNo}
									value={childProps.store_setting && childProps.store_setting.store_override}
								/>
							</Form.Field>
							<span className="hiddenfields">
								{childProps.store_setting.name && childProps.store_setting.name ? (
									<Input type="hidden" name="name[1]" value={childProps.store_setting.name} />
								) : (
									""
								)}
								{childProps.store_setting.name && childProps.store_setting.name ? <Input type="hidden" name="index[1]" value={1} /> : ""}
							</span>
						</div>
					)}
					<Divider />
					<Button content="Submit" positive />
				</Form>
			</div>
		);
	}

	submitForm(formData: any) {
		this.props.onSave(formData);
	}
}

export default StoreSettingsDetailComponent;
