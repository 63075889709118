import "./SortableTable.css";
// node_modules
import { Form, Input } from "formsy-semantic-ui-react";
import moment from "moment";
import * as React from "react";
// components
import {
  Button,
  Checkbox,
  Dropdown,
  Icon,
  Image,
  Input as SURInput,
  Label,
  Message,
  Popup,
  Table,
  Loader,
} from "semantic-ui-react";

/**
 * A container component used to build tables which can be sorted by
 * column.
 *
 * @export
 * @class SortableTable
 * @extends {React.Component<STProps, STState>}
 */

export class SortableTable extends React.Component<LMI.ISTProps, LMI.ISTState> {
  DEFAULT_SORT_ORDER = "descending";

  constructor(props) {
    super(props);
    this.state = {
      column: props.defaultSortedColumn ? props.defaultSortedColumn : null,
      data: props.tableData,
      direction: props.defaultSortOrder
        ? props.defaultSortOrder
        : this.DEFAULT_SORT_ORDER,
      selectedRow: Array.isArray(props.selectedIndex)
        ? props.selectedIndex
        : [props.selectedIndex],
    };
  }
  /**
   * Should the component update. Component life-cycle hook.
   *
   * @param {any} nextProps
   * @param {any} nextState
   * @returns
   *
   * @memberof SortableTable
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    if (this.props.defaultSortedColumn) {
      return true;
    }
    if (
      this.props.tableData.body.rows.length !==
      nextProps.tableData.body.rows.length
    ) {
      return true;
    }

    return false;
  }
  /**
   * Will the component update. Component life-cycle hook.
   *
   * @param {any} nextProps
   * @param {any} nextState
   * @returns
   *
   * @memberof SortableTable
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    let data = prevState.data;
    let selectedRow = prevState.selectedRow;
    if (data !== nextProps.tableData) data = nextProps.tableData;
    if (selectedRow !== nextProps.selectedIndex) {
      const newIndex = Array.isArray(nextProps.selectedIndex)
        ? nextProps.selectedIndex
        : [nextProps.selectedIndex];
      selectedRow = newIndex;
    }
    return { data, selectedRow };
  }

  componentDidUpdate(previousProps) {
    // check for current column and sort accordingly
    if (
      this.state.column !== null &&
      previousProps.tableData !== this.props.tableData &&
      previousProps.selectedIndex === this.props.selectedIndex
    ) {
      this.handleDataSort(this.state.column, true)();
    }
  }

  /**
   * Sorts the table data based on the clickedColumn. If the
   * same column is clicked successively then the sort order
   * reverses on each subsequent click.
   *
   * @param {string} clickedColumn
   * @returns {any[]}
   *
   * @memberof SortableTable
   */
  tableDataSort(clickedColumn: string): any[] {
    return this.props.tableData.body.rows
      .map((el, i) => {
        const dataObj = {
          index: i,
          value: null,
        };

        switch (typeof el[clickedColumn]) {
          case "string":
            // string may be a date, please check for valid date format
            if (moment(el[clickedColumn], "MMMM D, YYYY", true).isValid()) {
              dataObj.value = moment(
                el[clickedColumn],
                "MMMM D, YYYY",
                true
              ).unix();
            } else {
              dataObj.value = el[clickedColumn].toLowerCase();
            }
            break;
          case "number":
            dataObj.value = el[clickedColumn];
            break;
          case "object":
            if (el[clickedColumn]) {
              const objvalue =
                el[clickedColumn].text && el[clickedColumn].text !== undefined
                  ? el[clickedColumn].text.toLowerCase()
                  : el[clickedColumn].value &&
                    el[clickedColumn].value !== undefined
                  ? el[clickedColumn].value.toLowerCase()
                  : el[clickedColumn];
              dataObj.value = objvalue;
            } else {
              dataObj.value = "";
            }
            break;
          default:
            dataObj.value =
              el[clickedColumn] && el[clickedColumn].value.toString();
        }
        return dataObj;
      })
      .sort(
        (
          a: { index: number; value: any },
          b: { index: number; value: any }
        ): number => {
          return +(a.value > b.value) || +(a.value === b.value) - 1;
        }
      )
      .map((el, index) => {
        const element = document.getElementsByClassName(`row-${index}`)[0];
        const row = this.props.tableData.body.rows[el.index];
        element.classList.remove("selected");
        if (el.index === this.props.selectedIndex) {
          element.classList.add("selected");
        }
        if (row.active === "Inactive") {
          element.classList.add("inactive");
        } else {
          element.classList.remove("inactive");
        }
        return this.props.tableData.body.rows[el.index];
      }, this);
  }

  /**
   * Click event handler for sortable table header cells.
   *
   * @param {string} clickedColumn
   * @memberof SortableTable
   */
  handleDataSort = (clickedColumn, load) => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn || load) {
      this.setState({
        column: clickedColumn,
        data: Object.assign({}, data, {
          body: {
            rows: this.tableDataSort(clickedColumn),
          },
        }),
        direction: this.DEFAULT_SORT_ORDER,
      });
      return;
    }

    this.setState({
      data: Object.assign({}, data, {
        body: {
          rows: data.body.rows.reverse(),
        },
      }),
      direction: direction === "ascending" ? "descending" : "ascending",
    });
  };
  /**
   * Render the component. Component life-cycle hook.
   *
   * @returns
   *
   * @memberof SortableTable
   */
  render() {
    const { column, data, direction } = this.state;
    const classes = this.props.classes
      ? `${this.props.classes} sortable-table`
      : "sortable-table";
    const rows: any[] =
      data && data.body && data.body.rows ? data.body.rows : [];
    return (
      <div className={classes} ref={this.props.paneDidMount}>
        {rows.length > 0 ? (
          <Table
            unstackable
            celled
            padded
            sortable
            id={this.props.tableid}
            selectable={
              this.props.selectableRows ? this.props.selectableRows : false
            }
          >
            <Table.Header>
              <Table.Row className="header-row">
                {data.headers.map((header: any, index: number) => {
                  let headerCell;
                  if (!header.label) {
                    headerCell = <Table.HeaderCell key={index} />;
                  } else {
                    if (header.sortable) {
                      headerCell = (
                        <Table.HeaderCell
                          key={index}
                          sorted={column === header.id ? direction : null}
                          onClick={this.handleDataSort(header.id, false)}
                          className={`col-${index}`}
                        >
                          {header.label}
                        </Table.HeaderCell>
                      );
                    } else {
                      headerCell = (
                        <Table.HeaderCell
                          key={index}
                          className={`col-${index}`}
                        >
                          {header.label}
                        </Table.HeaderCell>
                      );
                    }
                  }
                  return headerCell;
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rows.length > 0
                ? rows.map((row: any, index: number) => {
                    const indx =
                      this.props.onClick && direction === "ascending"
                        ? rows.length - 1 - index
                        : index;
                    return this.buildTableRow(
                      row,
                      indx,
                      this.props.onClick || null,
                      data.headers
                    );
                  })
                : ""}
            </Table.Body>
          </Table>
        ) : (
          <div id="no-results">
            <Message info>
              {this.props.filter ? (
                <span>
                  <Message.Header>
                    No matches found for{" "}
                    {this.props.filter
                      ? "'" + this.props.filter + "'"
                      : "your search"}
                    .
                  </Message.Header>
                  <p>Please try again.</p>
                </span>
              ) : (
                <span>
                  <Message.Header>There's nothing to show</Message.Header>
                  {this.props.message && <p>{this.props.message}</p>}
                </span>
              )}
            </Message>
          </div>
        )}
      </div>
    );
  }

  buildTableRow(row, index: number, onClick: any, headers: any) {
    const rowClass = `st-row row-${row.id ? row.id.value : index} ${
      row.active === "Inactive" ? "inactive" : ""
    }`;
    if (onClick) {
      return (
        <Table.Row
          key={index}
          className={
            this.state.selectedRow &&
            this.state.selectedRow.indexOf(index) !== -1
              ? `${rowClass} selected`
              : rowClass
          }
          onClick={(args) => {
            const selectedRow =
              this.props.multiSelect && this.state.selectedRow
                ? [...this.state.selectedRow, index]
                : [index];
            this.setState({ selectedRow });
            onClick({ row });
          }}
        >
          {Object.keys(row)
            .filter((key) => key !== "id")
            .map((key: string, jndex: number) => {
              const collapsing = headers.find((h) => h.id === key).collapsing;
              const selectable = headers.find((h) => h.id === key).selectable;
              return this.buildTableCell(
                row,
                key,
                index,
                jndex,
                collapsing,
                selectable
              );
            })}
        </Table.Row>
      );
    }
    return (
      <Table.Row key={index} className={rowClass}>
        {Object.keys(row).map((key: string, jndex: number) => {
          const collapsing = headers.find((h) => h.id === key).collapsing;
          const selectable = headers.find((h) => h.id === key).selectable;
          return this.buildTableCell(
            row,
            key,
            index,
            jndex,
            collapsing,
            selectable
          );
        })}
      </Table.Row>
    );
  }

  buildTableCell(
    row,
    key,
    index: number,
    jndex: number,
    collapsing: boolean,
    selectable: boolean
  ) {
    if (typeof row[key] === "string") {
      return (
        <Table.Cell
          key={key}
          className={`col-${jndex}`}
          collapsing={collapsing}
          selectable={selectable}
        >
          {row[key]}
        </Table.Cell>
      );
    }
    if (row[key] && typeof row[key] === "object" && key === "inventory_item") {
      return (
        <Table.Cell
          key={key}
          className={`col-${jndex}`}
          collapsing={collapsing}
          selectable={selectable}
        >
          <div className="inventory-cell">
            <span className="inline dark-text">
              <span>{row[key].year}</span>
              <span>{row[key].make}</span>
              <span>{row[key].model}</span>
            </span>
            <span className="block light-text">
              <span>{row[key].vin}&nbsp;</span>
              <span>{row[key].stock_number}</span>
            </span>
          </div>
        </Table.Cell>
      );
    }
    if (row[key] && row[key].component) {
      return (
        <Table.Cell
          key={key}
          className={`col-${jndex}`}
          collapsing={collapsing}
          selectable={selectable}
        >
          {row[key].component(this, index, key, row[key].callback)}
        </Table.Cell>
      );
    }
    if (row[key] && row[key].labeled) {
      return (
        <Table.Cell
          className={
            row[key].mini ? `minilabeled col-${jndex}` : `col-${jndex}`
          }
          key={key}
          collapsing={collapsing}
          selectable={selectable}
        >
          {row[key].mini ? (
            <p
              className={`custom-label${
                row[key].attention ? " attention" : row[key].info ? " info" : ""
              }`}
            >
              {row[key].labeled}
            </p>
          ) : (
            <Label color="blue" style={{ marginRight: "10px" }}>
              {row[key].labeled}
            </Label>
          )}
          {row[key].text}
        </Table.Cell>
      );
    }
    return (
      <Table.Cell
        key={key}
        className={`col-${jndex}`}
        collapsing={collapsing}
        selectable={selectable}
      >
        {row[key] ? row[key].toString() : ""}
      </Table.Cell>
    );
  }
}
/**
 * Defines props for the SortableCheckbox component.
 *
 * @interface SCProps
 */
interface SCProps {
  /**
   * The table view which is rendering this component.
   *
   * @type {*}
   * @memberof SCProps
   */
  view: any;
  /**
   * The row number which this component is a part of.
   *
   * @type {number}
   * @memberof SCProps
   */
  index: number;
  /**
   * The key name for the data which is being displayed.
   *
   * @type {string}
   * @memberof SCProps
   */
  name: string;
  callback?: any;
}
/**
 * Creates a Semantic-UI React Checkbox component which can be
 * sorted by value.
 *
 * @export
 * @class SortableCheckbox
 * @extends {React.Component<SCProps, undefined>}
 */
export class SortableCheckbox extends React.Component<SCProps, undefined> {
  render() {
    const props = this.props;
    const rows = props.view.state.data.body.rows;
    const checked = rows[props.index][props.name].value;
    const disabled = rows[props.index][props.name].disabled;
    const stateData = props.view.state.data;
    return (
      <Checkbox
        toggle
        checked={checked}
        disabled={disabled === true ? true : false}
        onClick={() => {
          if (props.callback) {
            props.callback({
              state: !checked,
              success: () => {
                rows[props.index][props.name].value = !checked;
                props.view.setState({
                  data: Object.assign({}, stateData, {
                    body: {
                      rows,
                    },
                  }),
                });
              },
              error: () => {
                console.log("There has been an error");
              },
            });
          }
        }}
      />
    );
  }
}
/**
 * Helper function for creating a SortableCheckbox component.
 *
 * @export
 * @param {any} view The table view which is rendering this component.
 * @param {number} index The row number which this component is a part of.
 * @param {string} key The key name for the data which is being displayed.
 * @returns SortableCheckbox component
 */
export function getSortableCheckbox(
  view: any,
  index: number,
  key: string,
  callback: any
): any {
  return (
    <SortableCheckbox
      view={view}
      index={index}
      name={key}
      callback={callback}
    />
  );
}
/**
 * Defines props for the SortableDropdown component.
 *
 * @interface SDProps
 */
interface SDProps {
  /**
   * The table view which is rendering this component.
   *
   * @type {*}
   * @memberof SDProps
   */
  view: any;
  /**
   * The row number which this component is a part of.
   *
   * @type {number}
   * @memberof SDProps
   */
  index: number;
  /**
   * The key name for the data which is being displayed.
   *
   * @type {string}
   * @memberof SDProps
   */
  name: string;
  /**
   * An array of Dropdown.Item props.
   *
   * @example { text: 'Sample', value: 'sample' }
   *
   * @type {any[]}
   * @memberof SDProps
   */
  opts: any[];
  callback?: any;
}

/**
 * Creates a Semantic-UI React Dropdown component which can be
 * sorted by value.
 *
 * @export
 * @class SortableDropdown
 * @extends {React.Component<SDProps, undefined>}
 */
export class SortableDropdown extends React.Component<SDProps, undefined> {
  render() {
    const props = this.props;
    const rows = props.view.state.data.body.rows;
    const value = rows[props.index][props.name].value;
    const stateData = props.view.state.data;
    const placeholder = rows[props.index][props.name].placeholder;
    const disabled = rows[props.index][props.name].disabled;

    return (
      <Dropdown
        inline
        placeholder={placeholder}
        disabled={disabled ? true : false}
        options={props.opts}
        value={value}
        selectOnBlur={false}
        scrolling
        onChange={(evt, data) => {
          if (props.callback) {
            props.callback({ index: this.props.index, data, props });
          }
          rows[props.index][props.name].value = data.value;
          props.view.setState({
            data: Object.assign({}, stateData, {
              body: {
                rows,
              },
            }),
          });
        }}
      />
    );
  }
}
/**
 * Helper function for creating a SortableDropdown component.
 *
 * @export
 * @param {any} view The table view which is rendering this component.
 * @param {number} index The row number which this component is a part of.
 * @param {string} key The key name for the data which is being displayed.
 * @param {any[]} opts An array of Dropdown.Item props.
 * @returns SortableDropdown component
 */
export function getSortableDropdown(
  view: any,
  index: number,
  key: string,
  opts: any[],
  callback?: any
) {
  return (
    <SortableDropdown
      view={view}
      index={index}
      name={key}
      opts={opts}
      callback={callback}
    />
  );
}

/**
 * Defines props for the SortableInput component.
 *
 * @interface SIProps
 */
interface SIProps {
  /**
   * The table view which is rendering this component.
   *
   * @type {*}
   * @memberof SCProps
   */
  view: any;
  /**
   * The row number which this component is a part of.
   *
   * @type {number}
   * @memberof SCProps
   */
  index: number;
  /**
   * The key name for the data which is being displayed.
   *
   * @type {string}
   * @memberof SCProps
   */
  name: string;
  callback?: any;
  validations?: any;
}
/**
 * Creates a Formsy Semantic-UI React Input component which can be
 * sorted by value.
 *
 * @export
 * @class SortableInput
 * @extends {React.Component<SIProps, undefined>}
 */
export class SortableInput extends React.Component<SIProps, undefined> {
  render() {
    const props = this.props;
    const rows = props.view.state.data.body.rows;
    const value = rows[props.index][props.name].value;

    if (props.validations) {
      const isRequired: boolean = props.validations.required;
      const name: string = props.validations.name;
      const validations: string = props.validations.validations;
      const errors: any = props.validations.errors;
      const errorLabel: any = props.validations.errorLabel;
      return (
        <Input
          name={name}
          required={isRequired}
          validations={validations}
          validationErrors={errors}
          errorLabel={errorLabel}
          value={value}
        />
      );
    } else {
      return (
        <SURInput
          defaultValue={value}
          onChange={(evt, data) => {
            if (props.callback) {
              props.callback({
                value: data.value,
              });
            }
          }}
        />
      );
    }
  }
}
/**
 * Helper function for creating a SortableInput component.
 *
 * @export
 * @param {any} view The table view which is rendering this component.
 * @param {number} index The row number which this component is a part of.
 * @param {string} key The key name for the data which is being displayed.
 * @returns SortableInput component
 */
export function getSortableInput(
  view: any,
  index: number,
  key: string,
  validations: any,
  callback: any
): any {
  return (
    <SortableInput
      view={view}
      index={index}
      name={key}
      callback={callback}
      validations={validations}
    />
  );
}

/**
 * Defines props for the SortableInputSubmit component.
 *
 * @interface SIProps
 */
interface SISProps {
  /**
   * The table view which is rendering this component.
   *
   * @type {*}
   * @memberof SCProps
   */
  view: any;
  /**
   * The row number which this component is a part of.
   *
   * @type {number}
   * @memberof SCProps
   */
  index: number;
  /**
   * The key name for the data which is being displayed.
   *
   * @type {string}
   * @memberof SCProps
   */
  name: string;
  value: string;
  placeholder?: string;
  callback?: any;
  validations?: any;
}
interface SISState {
  dirty: boolean;
}
/**
 * Creates a Formsy Semantic-UI React Input component which can be
 * sorted by value.
 *
 * @export
 * @class SortableInput
 * @extends {React.Component<SISProps, undefined>}
 */
export class SortableInputSubmit extends React.Component<SISProps, SISState> {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false,
    };
  }
  render() {
    const props = this.props;
    return (
      <Form onSubmit={this.submitForm.bind(this)}>
        <Input
          action={this.state.dirty}
          size="mini"
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={() => this.setState({ dirty: true })}
        >
          <input />
          {this.state.dirty && (
            <Button animated="vertical">
              <Button.Content hidden>Save</Button.Content>
              <Button.Content visible>
                <Icon name="save" />
              </Button.Content>
            </Button>
          )}
        </Input>
      </Form>
    );
  }

  submitForm(data: any) {
    this.setState({
      dirty: false,
    });
    this.props.callback(data);
  }
}
/**
 * Helper function for creating a SortableInput component.
 *
 * @export
 * @param {any} view The table view which is rendering this component.
 * @param {number} index The row number which this component is a part of.
 * @param {string} key The key name for the data which is being displayed.
 * @returns SortableInput component
 */
export function getSortableInputSubmit(
  view: any,
  index: number,
  name: string,
  value: string,
  placeholder: string,
  callback: any
): any {
  return (
    <SortableInputSubmit
      view={view}
      index={index}
      name={name}
      value={value}
      placeholder={placeholder}
      callback={callback}
    />
  );
}

/**
 * Defines props for the SortableCheckbox component.
 *
 * @interface SLProps
 */
interface SLProps {
  /**
   * The table view which is rendering this component.
   *
   * @type {*}
   * @memberof SLProps
   */
  view: any;
  /**
   * The row number which this component is a part of.
   *
   * @type {number}
   * @memberof SLProps
   */
  index: number;
  /**
   * The key name for the data which is being displayed.
   *
   * @type {string}
   * @memberof SLProps
   */
  value: string;
  callback?: any;
  addClass?: string;
}

interface SLState {
  loading: boolean;
}

/**
 * Creates an anchor element
 *
 * @export
 * @class SortableLink
 * @extends {React.Component<SLProps, undefined>}
 */
export class SortableLink extends React.Component<SLProps, SLState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  async callbacker(cb) {
    this.setState({ loading: true });
    await cb();
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const props = this.props;
    const rows = props.view.state.data.body.rows;
    const value = rows[props.index][props.value].value;
    const value2 = rows[props.index][props.value].value2;
    const callback = rows[props.index][props.value].callback;
    const callback2 = rows[props.index][props.value].callback2;
    const iconsize = rows[props.index][props.value].iconsize;
    const iconaction = rows[props.index][props.value].iconaction;
    const iconactioncallback =
      rows[props.index][props.value].iconactioncallback;
    const iconactioncorner = rows[props.index][props.value].iconactioncorner;
    const icon2action = rows[props.index][props.value].icon2action;
    const icon2actioncallback =
      rows[props.index][props.value].icon2actioncallback;
    const icon2actioncorner = rows[props.index][props.value].icon2actioncorner;
    const imageIconCallback = rows[props.index][props.value].imageIconCallback;
    const imageIconAction = rows[props.index][props.value].imageIconAction;
    const imageIconActionPopupText =
      rows[props.index][props.value].imageIconActionPopupText;
    const imageIconStyle = rows[props.index][props.value].imageIconStyle;
    const addClass = rows[props.index][props.value].addClass;
    const addClass2 = rows[props.index][props.value].addClass2;

    if (loading) {
      return <Loader active size="small" />;
    }
    return (
      <span>
        {imageIconAction ? (
          <Popup
            trigger={
              <Image
                src={imageIconAction}
                href=""
                size="mini"
                floated="right"
                style={imageIconStyle}
                onClick={imageIconCallback}
              />
            }
            content={imageIconActionPopupText}
            on="hover"
            size="tiny"
            position="bottom center"
            inverted
          />
        ) : (
          ""
        )}
        {icon2action &&
          (icon2actioncorner ? (
            <Icon.Group size={iconsize} style={{ float: "right" }}>
              <Icon link name={icon2action} onClick={icon2actioncallback} />
              <Icon
                link
                corner
                name={icon2actioncorner}
                onClick={icon2actioncallback}
                color="green"
              />
            </Icon.Group>
          ) : (
            <Icon
              size={iconsize}
              link
              color="green"
              name={icon2action}
              onClick={icon2actioncallback}
              style={{ float: "right" }}
            />
          ))}
        {iconaction &&
          (iconactioncorner ? (
            <Icon.Group size={iconsize} style={{ float: "right" }}>
              <Icon link name={iconaction} onClick={iconactioncallback} />
              <Icon
                link
                corner
                name={iconactioncorner}
                onClick={iconactioncallback}
                color="blue"
              />
            </Icon.Group>
          ) : (
            <Icon
              size={iconsize}
              link
              color="blue"
              name={iconaction}
              onClick={iconactioncallback}
              style={{ float: "right" }}
            />
          ))}
        <a
          className={`click-${props.value} ${addClass}`}
          onClick={() => this.callbacker(callback)}
        >
          {value}
        </a>
        {value2 ? (
          <a
            className={`click-${props.value} second ${addClass2}`}
            onClick={() => this.callbacker(callback2)}
          >
            {value2}
          </a>
        ) : (
          ""
        )}
      </span>
    );
  }
}
/**
 * Helper function for creating a SortableLink component.
 *
 * @export
 * @param {any} view The table view which is rendering this component.
 * @param {number} index The row number which this component is a part of.
 * @param {string} key The key name for the data which is being displayed.
 * @returns SortableLink component
 */
export function getSortableLink(
  view: any,
  index: number,
  value: string,
  callback: any,
  addClass: string
): any {
  return (
    <SortableLink
      view={view}
      index={index}
      value={value}
      callback={callback}
      addClass={addClass}
    />
  );
}

/**
 * Defines props for the SelectableLink component.
 *
 * @interface SLProps
 */
interface SeLProps {
  /**
   * The table view which is rendering this component.
   *
   * @type {*}
   * @memberof SLProps
   */
  view: any;
  /**
   * The row number which this component is a part of.
   *
   * @type {number}
   * @memberof SLProps
   */
  index: number;
  /**
   * The key name for the data which is being displayed.
   *
   * @type {string}
   * @memberof SLProps
   */
  value: string;
  callback?: any;
}
/**
 * Creates an anchor element
 *
 * @export
 * @class SortableLink
 * @extends {React.Component<SLProps, undefined>}
 */
export class SelectableLink extends React.Component<SeLProps, undefined> {
  render() {
    const props = this.props;
    const rows = props.view.state.data.body.rows;
    const value = rows[props.index][props.value].value;
    const callback = rows[props.index][props.value].callback;

    return (
      <a className="view-invoice" onClick={callback}>
        {value}
      </a>
    );
  }
}
/**
 * Helper function for creating a SelectableLink component.
 *
 * @export
 * @param {any} view The table view which is rendering this component.
 * @param {number} index The row number which this component is a part of.
 * @param {string} key The key name for the data which is being displayed.
 * @returns SelectableLink component
 */
export function getSelectableLink(
  view: any,
  index: number,
  value: string,
  callback: any
): any {
  return (
    <SelectableLink
      view={view}
      index={index}
      value={value}
      callback={callback}
    />
  );
}

/**
 * Creates an anchor element
 *
 * @export
 * @class SortableLink
 * @extends {React.Component<SLProps, undefined>}
 */
export class SortableEditable extends React.Component<SLProps, undefined> {
  render() {
    const props = this.props;
    const rows = props.view.state.data.body.rows;
    const value = rows[props.index][props.value].value;
    const editcallback = rows[props.index][props.value].editcallback;
    const removecallback = rows[props.index][props.value].removecallback;
    const editable = rows[props.index][props.value].editable;
    const removable = rows[props.index][props.value].removable;
    const style = {
      float:
        rows[props.index][props.value].float &&
        rows[props.index][props.value].float.toString(),
    };

    return (
      <span>
        {editable && (
          <Icon
            name="pencil"
            color="blue"
            link
            style={style}
            onClick={editcallback}
          />
        )}
        {removable && (
          <Icon
            name="cancel"
            color="red"
            link
            style={style}
            onClick={removecallback}
          />
        )}
        {value}
      </span>
    );
  }
}
/**
 * Helper function for creating a SortableLink component.
 *
 * @export
 * @param {any} view The table view which is rendering this component.
 * @param {number} index The row number which this component is a part of.
 * @param {string} key The key name for the data which is being displayed.
 * @returns SortableLink component
 */
export function getSortableEditable(
  view: any,
  index: number,
  value: string,
  callback: any
): any {
  return (
    <SortableEditable
      view={view}
      index={index}
      value={value}
      callback={callback}
    />
  );
}
/**
 * Defines props for the SortableCheckbox component.
 *
 * @interface SLProps
 */
interface SLabProps {
  /**
   * The table view which is rendering this component.
   *
   * @type {*}
   * @memberof SLProps
   */
  view: any;
  /**
   * The row number which this component is a part of.
   *
   * @type {number}
   * @memberof SLProps
   */
  index: number;
  /**
   * The key name for the data which is being displayed.
   *
   * @type {string}
   * @memberof SLProps
   */
  value: string;
  color?: string;
}
/**
 * Creates an labelled element
 *
 * @export
 * @class SortableLabeled
 * @extends {React.Component<SLProps, undefined>}
 */
export class SortableLabeled extends React.Component<SLabProps, undefined> {
  render() {
    const props = this.props;
    const rows = props.view.state.data.body.rows;
    const value = rows[props.index][props.value].value;
    const color = rows[props.index][props.value].color;
    return <Label color={color} content={value} />;
  }
}
/**
 * Helper function for creating a SortableLink component.
 *
 * @export
 * @param {any} view The table view which is rendering this component.
 * @param {number} index The row number which this component is a part of.
 * @param {string} key The key name for the data which is being displayed.
 * @returns SortableLink component
 */
export function getSortableLabeled(
  view: any,
  index: number,
  value: string,
  color: any
): any {
  return (
    <SortableLabeled view={view} index={index} value={value} color={color} />
  );
}
/**
 * Defines props for the SortableImage component.
 *
 * @interface SImgProps
 */
interface SImgProps {
  /**
   * @type {*}
   * @memberof SImgProps
   */
  view: any;
  /**
   * @type {number}
   * @memberof SImgProps
   */
  index: number;
  /**
   * @type {string}
   * @memberof SImgProps
   */
  value: string;
}
/**
 * Creates an image element
 *
 * @export
 * @class SortableImage
 * @extends {React.Component<SImgProps, undefined>}
 */
export class SortableImage extends React.Component<SImgProps, undefined> {
  render() {
    const props = this.props;
    const rows = props.view.state.data.body.rows;
    const value = rows[props.index][props.value].value;
    return (
      <Image
        src={value}
        as="a"
        className="imgthumb"
        href={value}
        rounded
        target="_blank"
        loading="lazy"
      />
    );
  }
}
/**
 * Helper function for creating a SortableImage component.
 */
export function getSortableImage(view: any, index: number, value: string): any {
  return <SortableImage view={view} index={index} value={value} />;
}

/**
 * Defines props for the SortableToolTip component.
 *
 * @interface STipProps
 */
interface STipProps {
  /**
   * @type {*}
   * @memberof STipProps
   */
  view: any;
  /**
   * @type {number}
   * @memberof STipProps
   */
  index: number;
  /**
   * @type {string}
   * @memberof STipProps
   */
  value: string;
}
/**
 * Creates an image element
 *
 * @export
 * @class SortableToolTip
 * @extends {React.Component<STipProps, undefined>}
 */
export class SortableToolTip extends React.Component<STipProps, undefined> {
  render() {
    const props = this.props;
    const rows = props.view.state.data.body.rows;
    const value = rows[props.index][props.value].value;
    const mainicon = rows[props.index][props.value].mainicon;
    const mainiconcolor = rows[props.index][props.value].mainiconcolor;
    const tipvalue = rows[props.index][props.value].tipvalue;
    const tipicon = rows[props.index][props.value].tipicon;
    const tippopvalue = rows[props.index][props.value].tippopvalue;
    const callback = rows[props.index][props.value].callback;
    const tippopcallback = rows[props.index][props.value].tippopcallback;
    return (
      <span>
        {value && mainicon ? (
          <Popup
            flowing
            hoverable
            trigger={
              <Icon
                name={mainicon}
                color={mainiconcolor}
                link={true}
                onClick={callback}
              />
            }
          >
            {tipvalue}
            {tipicon && (
              <div>
                {tippopvalue ? (
                  <Popup
                    trigger={
                      <Icon
                        name={tipicon}
                        size="large"
                        color="blue"
                        link={callback ? true : false}
                        onClick={tippopcallback}
                      />
                    }
                    content={tippopvalue}
                    position="top center"
                    size="tiny"
                    on="hover"
                    inverted
                  />
                ) : (
                  <Icon name={tipicon} onClick={callback} />
                )}
              </div>
            )}
          </Popup>
        ) : (
          ""
        )}
        {value}
      </span>
    );
  }
}
/**
 * Helper function for creating a SortableToolTip component.
 */
export function getSortableToolTip(
  view: any,
  index: number,
  value: string
): any {
  return <SortableToolTip view={view} index={index} value={value} />;
}

/**
 * Helper function for creating a SortableIcon component.
 *
 * @export
 * @param {any} view The table view which is rendering this component.
 * @param {number} index The row number which this component is a part of.
 * @param {string} key The key name for the data which is being displayed.
 * @returns SortableIcon component
 */
export function getSortableIcon(
  view: any,
  index: number,
  name: string,
  color: string
): any {
  return <SortableIcon view={view} index={index} name={name} color={color} />;
}
/**
 * Defines props for the SortableIcon component.
 *
 * @interface SIProps
 */
interface SIProps {
  /**
   * The table view which is rendering this component.
   *
   * @type {*}
   * @memberof SIProps
   */
  view: any;
  /**
   * The row number which this component is a part of.
   *
   * @type {number}
   * @memberof SIProps
   */
  index: number;
  /**
   * The key name for the data which is being displayed.
   *
   * @type {string}
   * @memberof SIProps
   */
  name: string;
  color?: string;
}
/**
 * Creates an labelled element
 *
 * @export
 * @class SortableLabeled
 * @extends {React.Component<SIProps, undefined>}
 */
export class SortableIcon extends React.Component<SIProps, undefined> {
  render() {
    const props = this.props;
    const rows = props.view.state.data.body.rows;
    const name = rows[props.index][props.name].name;
    const color = rows[props.index][props.name].color;
    if (name) return <Icon color={color} name={name} />;
    else return <span />;
  }
}
