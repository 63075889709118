import * as React from "react";
import { Loader } from "semantic-ui-react";

export class AverageTimer extends React.Component<LMI.IAverageTimerProps, LMI.IAverageTimerState> {
	phaseInterval: any;

	constructor(props: LMI.IAverageTimerProps) {
		super(props);

		this.state = {
			loading: false,
			phase_elapsed_time: null
		};
	}

	componentDidMount() {
		if (this.props.id) {
			this.getPhaseTimer();
		}
	}

	componentWillUnmount(): void {
		clearInterval(this.phaseInterval);
	}

	getAverageTime() {
		const { inventory } = this.props;

		if (inventory[0]) {
			let inc = 0;
			let average = inventory.reduce((total, next) => {
				if (next.phase_timer_running_since_ms > 0) inc++;
				return total + next.phase_timer_running_since_ms;
			}, 0);
			average = average / inc;

			return { phase_timer_running_since_ms: average, current_phase_elapsed_ms: 0 };
		}
		return { phase_timer_running_since_ms: 0, current_phase_elapsed_ms: 0 };
	}

	getPhaseTimer() {
		const oldestInventory = this.getAverageTime();
		const { phase_timer_running_since_ms, current_phase_elapsed_ms } = oldestInventory;
		const now = new Date();

		let elapsed;
		if (phase_timer_running_since_ms > 0) {
			// New session, elapsed time not recoreded yet, so calculate it
			if (current_phase_elapsed_ms === 0) {
				elapsed = now.getTime() - phase_timer_running_since_ms;
			} else elapsed = current_phase_elapsed_ms > 0 ? current_phase_elapsed_ms + now.getTime() - phase_timer_running_since_ms : 0;
		} else {
			elapsed = current_phase_elapsed_ms;
		}

		this.setState({
			phase_elapsed_time: elapsed
		});

		const interval = 1000;
		this.phaseInterval = setInterval(() => {
			if (phase_timer_running_since_ms) {
				this.setState(prevState => ({ phase_elapsed_time: prevState.phase_elapsed_time + interval }));
			}
		}, interval);
	}

	calculateElapsedTime(elapsedTime) {
		let ms = elapsedTime;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = Math.floor(ms / 24);
		return {
			days: days.toString().padStart(2, "0"),
			hours: hours.toString().padStart(2, "0"),
			minutes: minutes.toString().padStart(2, "0"),
			seconds: seconds.toString().padStart(2, "0")
		};
	}

	phaseThreshold(elapsedMS) {
		const msThresholds = this.calculateMilliseconds();
		return { warning: elapsedMS > msThresholds.warning, error: elapsedMS > msThresholds.error };
	}

	calculateMilliseconds() {
		const {
			warning_threshold_days: wDays,
			warning_threshold_hours: wHours,
			warning_threshold_minutes: wMinutes,
			error_threshold_days: eDays,
			error_threshold_hours: eHours,
			error_threshold_minutes: eMinutes
		} = this.props;

		const wDayMS = wDays * 24 * 60 * 60 * 1000;
		const wHoursMS = wHours * 60 * 60 * 1000;
		const wMinutesMS = wMinutes * 60 * 1000;
		const wMS = wDayMS + wHoursMS + wMinutesMS;
		const eDayMS = eDays * 24 * 60 * 60 * 1000;
		const eHoursMS = eHours * 60 * 60 * 1000;
		const eMinutesMS = eMinutes * 60 * 1000;
		const eMS = eDayMS + eHoursMS + eMinutesMS;
		return { warning: wMS, error: eMS };
	}

	render() {
		const { phase_elapsed_time } = this.state;
		const { phase_timer_running_since_ms, final_stage } = this.props;

		if (phase_timer_running_since_ms && !phase_elapsed_time) {
			return <Loader className={`loader active right`} size="small" />;
		} else {
			const thresholds = this.phaseThreshold(phase_elapsed_time);
			const { days, hours, minutes, seconds } = this.calculateElapsedTime(phase_elapsed_time);
			if (final_stage) {
				return (
					<span className={`${thresholds.error ? "error" : thresholds.warning ? "warning" : ""}`}>
						{days}
						<span className="lowlight">d</span>
					</span>
				);
			}
			return (
				<span className={`${thresholds.error ? "error" : thresholds.warning ? "warning" : ""}`}>
					{days}
					<span className="lowlight">d</span> {hours}
					<span className="lowlight">h</span> {minutes}
					<span className="lowlight">m</span>
					{phase_elapsed_time > 0 && (
						<span className="lowlight tiny">
							<sup>{seconds}</sup>
						</span>
					)}
				</span>
			);
		}
	}
}
