import * as React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Grid, Message, Image } from "semantic-ui-react";

export enum CropperAspectRatios {
	square = 1 / 1,
	standard = 4 / 3,
	wide = 16 / 9
}

export class CropperComponent extends React.Component<LMI.IImageCropperProps, LMI.IImageCropperState> {
	imageRef: any;
	fileUrl: any;

	constructor(props: LMI.IImageCropperProps) {
		super(props);

		this.state = {
			src: props.src,
			croppedImageUrl: null,
			croppedImg: null,
			crop: props.crop
		};
	}

	onImageLoaded = image => {
		this.imageRef = image;
	};

	onCropComplete = crop => {
		this.makeClientCrop(crop);
	};

	onCropChange = (crop, percentCrop) => {
		this.setState({ crop });
	};

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
			const filePack = await this.getCroppedImg(this.imageRef, crop, "newFile.jpeg");
			const { croppedImageUrl, croppedImg } = filePack;
			this.setState({ croppedImageUrl, croppedImg });
		}
	}

	getCroppedImg(image, crop, fileName): Promise<any> {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.style.height = crop.height + "px";
		canvas.style.width = crop.width + "px";
		canvas.width = crop.width * 2;
		canvas.height = crop.height * 2;

		const ctx = canvas.getContext("2d");

		ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * 2, crop.height * 2);

		return new Promise((resolve, reject) => {
			// As Base64 string
			const croppedImg = canvas.toDataURL("image/jpeg");

			// create preview
			canvas.toBlob((blob: any) => {
				if (!blob) {
					reject(new Error("Canvas is empty"));
					console.error("Canvas is empty");
					return;
				}
				blob.name = fileName;
				window.URL.revokeObjectURL(this.fileUrl);
				this.fileUrl = window.URL.createObjectURL(blob);
				resolve({ croppedImageUrl: this.fileUrl, croppedImg });
			}, "image/jpeg");
		});
	}

	fix_dpi(canvas, dpi) {
		let style_height = +getComputedStyle(canvas)
			.getPropertyValue("height")
			.slice(0, -2);
		let style_width = +getComputedStyle(canvas)
			.getPropertyValue("width")
			.slice(0, -2);
		//scale the canvas
		canvas.setAttribute("height", style_height * dpi);
		canvas.setAttribute("width", style_width * dpi);
	}

	render() {
		const { crop, croppedImageUrl, croppedImg, src } = this.state;

		return (
			<div className="img-cropper">
				<Grid className="preview" columns="equal">
					<Grid.Column>
						{src ? (
							<ReactCrop
								src={src.preview}
								crop={crop}
								ruleOfThirds
								onImageLoaded={this.onImageLoaded}
								onComplete={this.onCropComplete}
								onChange={this.onCropChange}
							/>
						) : (
							<Message content="The Image wasn't loaded" />
						)}
					</Grid.Column>
					<Grid.Column>
						{croppedImageUrl && <Image alt="Cropped" fluid src={croppedImageUrl} />}
						<div className="tools">
							<br />
							<Button content="Crop Image" floated="right" positive onClick={() => this.props.onSave(croppedImg)} />
							<Button content="Cancel" floated="right" onClick={() => this.props.onClose()} />
						</div>
					</Grid.Column>
				</Grid>
			</div>
		);
	}
}
