// node_modules
import * as React from "react";
// components
import { Form, Input } from "formsy-semantic-ui-react";
import { Button } from "semantic-ui-react";

export class EditCostComponent extends React.Component<LMI.IEditServiceCostProps, LMI.IEditServiceCostState> {
	constructor(props) {
		super(props);
		this.state = {
			base_price: props.service.base_price ? parseFloat(props.service.base_price).toFixed(2) : null
		};
	}
	render() {
		const props = this.props;
		return (
			<div className="edit-cost">
				<Form noValidate onSubmit={(data, e) => props.editService(data, props.service.vendor_service.id)}>
					<Form.Field>
						<label>Price for this dealership</label>
						<Input name="base_price" id="base_price" autoFocus type="text" value={this.state.base_price} />
					</Form.Field>
					<Button content="Submit" positive />
				</Form>
			</div>
		);
	}
}

export default EditCostComponent;
