import * as React from "react";
import { gqlQueries } from "gql-imports";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Statistic, Dimmer, Loader, Divider, Popup } from "semantic-ui-react";

export class DocumentsCountsComponentView extends React.Component<LMI.VehDocCountDialogProps, undefined> {
	render() {
		const { document_counts, loading } = this.props;
		if (loading)
			return (
				<Dimmer active>
					<Loader />
				</Dimmer>
			);
		return (
			<div id="SUDocumentCounts">
				<div>
					Total Documents Uploaded: <strong>{document_counts.total_count}</strong>
				</div>
				<Divider />
				<div className="counts">{this.getDocumentCountsView(document_counts)}</div>
				<Divider hidden />
			</div>
		);
	}

	getDocumentCountsView = (document_counts: LMI.VehDocumentCounts) => {
		const length: any = document_counts.counts.length;
		const bindLabel = name => {
			// make labels look good if they are long
			const l = name.length;
			if (l >= 25) {
				const labelBinding = `${name.substring(0, 23)}...`;
				return <Popup inverted style={{ textAlign: "center" }} content={name} trigger={<span>{labelBinding}</span>} />;
			}
			return name;
		};
		return (
			<Statistic.Group widths={length > 3 ? 3 : length} size="small">
				{document_counts &&
					document_counts.counts.map((i, ii) => {
						return (
							<Statistic key={ii} color="blue">
								<Statistic.Value>{i.document_count}</Statistic.Value>
								<Statistic.Label>{bindLabel(i.name)}</Statistic.Label>
							</Statistic>
						);
					})}
			</Statistic.Group>
		);
	};
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const DocumentsCountsComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.super.vehicleDocumentCounts, {
		options: (props: LMI.VehDocCountDialogProps) => {
			return {
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, su_vehicle_document_counts, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				document_counts: su_vehicle_document_counts,
				refetch
			};
		}
	})
)(DocumentsCountsComponentView) as React.ComponentType<any>;

export default DocumentsCountsComponent;
