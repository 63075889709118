const Notify = require("notifyjs");

interface INotification {
	type: string;
	service?: string;
	description: string;
}

export class NotificationController {
	private _notify: any;
	private _type: string;
	private _types: any = {
		add_task: {
			filename: "/sounds/add.mp3",
			title: "Task Added",
			icon: "/images/LoopMeIn_LOGO_FINAL.png"
		},
		remove_task: {
			filename: "/sounds/remove.mp3",
			title: "Task Removed",
			icon: "/images/LoopMeIn_LOGO_FINAL.png"
		},
		add_request: {
			filename: "/sounds/add.mp3",
			title: "Sanitization Requested",
			icon: "/images/LoopMeIn_LOGO_FINAL.png"
		},
		remove_request: {
			filename: "/sounds/remove.mp3",
			title: "Sanitization Completed",
			icon: "/images/LoopMeIn_LOGO_FINAL.png"
		}
	};

	constructor() {
		console.log("Notification Constructor");
	}

	getPermission = () => {
		if (!Notify.needsPermission) {
			this.showNotification();
		} else if (Notify.isSupported()) {
			Notify.requestPermission(this.onPermissionGranted, this.onPermissionDenied);
		}
	};

	onPermissionGranted = () => {
		console.log("Permission has been granted by the user");
		this.showNotification();
	};

	onPermissionDenied = () => {
		console.warn("Permission has been denied by the user");
		// still play sound if they deny permission
		this.playSound(this._types[this._type].filename);
	};

	sendNotification = ({ type, service, description }: INotification) => {
		this._type = type;
		const title = this._types[this._type].title;

		this._notify = new Notify(`${title}${service ? " - " + service : ""}`, {
			icon: this._types[this._type].icon,
			body: description,
			notifyShow: this.onNotifyShow,
			timeout: 10
		});

		this.getPermission();
	};

	showNotification = () => {
		this._notify.show();
		this.playSound(this._types[this._type].filename);
	};

	playSound = async filename => {
		const audio = new Audio();
		audio.src = `${filename}`;
		try {
			await audio.play();
			console.log(`Played audio: ${filename}`);
		} catch (error) {
			console.error(error);
		}
	};

	onNotifyShow = () => {
		console.log("Notification was shown!");
	};
}

export const NotificationService = new NotificationController();
