import { Form, Input } from "formsy-semantic-ui-react";
import { MaskedInputComponent } from "client/pages/admin/components/MaskedInputComponent";
import * as React from "react";
import { Button, Divider, Grid, Header, Segment } from "semantic-ui-react";
import { RolesSelectComponent, StoreSelectComponent } from "../index";
import "./NewUserComponent.css";

export class NewUserComponent extends React.Component<
  LMI.SUAdminNewUserProps,
  LMI.SUAdminNewUserState
> {
  constructor(props) {
    super(props);
    this.state = {
      selectedStore: null,
      formValid: false,
    };
  }

  render() {
    const storeSelectProps = {
      stores: this.props.allStores,
      disabled: false,
      selectedStore: null,
      storeChange: (data) => {
        const selectedStore = data
          ? {
              text: data.name,
              value: data.id,
              type: data.store_type_id,
              orgId: data.organization_id,
              storeId: data.id,
            }
          : null;
        this.setState({
          selectedStore,
        });
      },
    };
    let roleSelectProps = null;
    if (this.state.selectedStore) {
      const store = this.props.stores.find(
        (store) => store.value === this.state.selectedStore.value
      );
      roleSelectProps = {
        type: store.type,
        storeId: store.value,
        orgId: store.orgId,
      };
    }
    const phoneInputProps: LMI.IMaskInputProps = {
      type: "phone",
      value: null,
    };

    return (
      <div id="create-new-user">
        <Segment padded color="blue">
          <Form
            noValidate
            onValid={() => this.enableSubmitButton()}
            onValidSubmit={this.props.success.bind(this)}
          >
            <Grid divided>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as="h3" content="User Information" />
                  <Divider />
                  <Form.Field required>
                    <label>Email</label>
                    <Input name="email" required />
                  </Form.Field>
                  <Form.Field required>
                    <label>First Name</label>
                    <Input name="first_name" required />
                  </Form.Field>
                  <Form.Field required>
                    <label>Last Name</label>
                    <Input name="last_name" required />
                  </Form.Field>
                  <Form.Field>
                    <label>Mobile Phone</label>
                    <MaskedInputComponent {...phoneInputProps} />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header as="h3" content="Employment Information" />
                  <Divider />
                  <StoreSelectComponent {...storeSelectProps} />
                  <RolesSelectComponent {...roleSelectProps} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16} textAlign="right">
                  <Divider />
                  <Button
                    content="Save New User"
                    size="large"
                    disabled={this.state.formValid ? false : true}
                    positive
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <span className="hiddenfields">
              <Input
                type="hidden"
                name="store"
                value={
                  this.state.selectedStore
                    ? this.state.selectedStore.storeId
                    : ""
                }
              />
            </span>
          </Form>
        </Segment>
      </div>
    );
  }

  enableSubmitButton() {
    this.setState({
      formValid: true,
    });
  }
}

export default NewUserComponent;
