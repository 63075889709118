import moment from "moment";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { promiseBase64 } from "../../../../utils/fileHandling";

import { DatePicker } from "react-datepicker";
import { Loading } from "../../../../components/Loading";
import { Button, Icon, Image, Label, Message } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import {
  CropperAspectRatios,
  CropperComponent,
} from "./components/CropperComponent";
import { DropZone } from "client/components/DropZone/DropZone";

import "./FileUploadComponent.css";

export class FileUploadComponentView extends React.Component<
  LMI.IFileUploadProps,
  LMI.IFileUploadState
> {
  constructor(props: LMI.IFileUploadProps) {
    super(props);

    this.state = {
      uploading: false,
      file: false,
      can_expire: false,
      uploaderror: null,
      expiration_date: null,
      one_active: false,
      cropping: false,
      croppedImage: null,
    };
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  render() {
    const errorLabel = <Label color="red" pointing="above" />;
    const props = this.props;
    const { uploading, cropping, file } = this.state;

    if (uploading) {
      return <Loading />;
    }

    if (cropping)
      return (
        <CropperComponent
          {...{
            src: file[0],
            crop: {
              unit: "%",
              width: 50,
              aspect: CropperAspectRatios.square,
            },
            onSave: (croppedImage) =>
              this.setState({ croppedImage, cropping: false }),
            onClose: () => this.setState({ cropping: false }),
          }}
        />
      );

    const typeOptions =
      props.document_types &&
      props.document_types.map((type) => ({
        text: type.name,
        value: type.id,
        key: type.id,
      }));
    return (
      <div id="fileupload">
        <Form
          id="UploadFileForm"
          encType="multipart/form-data"
          onValidSubmit={(formData, reset) => {
            this.onSubmitHandler(formData, reset);
          }}
        >
          <Form.Group widths="equal">
            {props.allowName && (
              <Form.Field>
                <label>File Name</label>
                <Input
                  name="file_name"
                  placeholder="File Name"
                  required
                  validations={{
                    matchRegexp: /([A-Za-z0-9\-\_]+)/,
                  }}
                  validationErrors={{
                    matchRegexp:
                      "Only letters and numbers allowed in file name",
                    isDefaultRequiredValue: "File name is required",
                  }}
                  errorLabel={errorLabel}
                />
              </Form.Field>
            )}
            {typeOptions && props.documentmode && (
              <Form.Field>
                <Form.Select
                  search
                  label="Document Type"
                  name="document_type_id"
                  value={this.isOther(typeOptions)}
                  options={typeOptions}
                  placeholder="Select a document type"
                  onChange={(e, data) => this.canExpire(data.value)}
                />
              </Form.Field>
            )}
            {this.state.can_expire && (
              <Form.Field>
                <label>Expiration Date</label>
                <DatePicker
                  name="expiration_date"
                  className="datepicker"
                  placeholderText="Select Date"
                  required
                  minDate={moment().add(1, "days")}
                  selected={this.state.expiration_date}
                  onChange={this.handleDateChange}
                />
              </Form.Field>
            )}
          </Form.Group>
          <Form.Field>
            <div className="file-uploader">
              <DropZone
                accept={props.filesToAccept}
                multiple={false}
                className="dropzone"
                onDrop={(file) =>
                  this.setState({
                    file: file.map((file) =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    ),
                  })
                }
              ></DropZone>
            </div>
          </Form.Field>
          {this.state.uploaderror && (
            <Message icon>
              <Icon name="exclamation circle" color="red" />
              <Message.Content>
                <Message.Header>{this.state.uploaderror}</Message.Header>
              </Message.Content>
            </Message>
          )}
          {this.state.one_active ? (
            <Message icon>
              <Icon name="file text" />
              <Message.Content>
                <Button
                  positive
                  className="submit-btn"
                  type="submit"
                  floated="right"
                  content="Save and Replace"
                />
                <Message.Header>
                  A document with this type already exists.
                </Message.Header>
                {`Click save to replace "${this.state.one_active.name}"`}
              </Message.Content>
            </Message>
          ) : (
            <Button.Group floated="right">
              <Button type="button" onClick={props.onClose}>
                Cancel
              </Button>
              <Button.Or />
              <Button positive className="submit-btn" type="submit">
                Save
              </Button>
            </Button.Group>
          )}
        </Form>
        {this.props.enableCropping && (
          <Button
            icon="crop"
            disabled={!this.state.file}
            active={this.state.cropping}
            onClick={() => this.setState({ cropping: true })}
          />
        )}
      </div>
    );
  }

  getPreview(file) {
    const { croppedImage } = this.state;
    if (file.type === "application/pdf") {
      return <Icon name="file alternate" size="huge" />;
    } else {
      return (
        <Image
          src={croppedImage ? croppedImage : file.preview}
          rounded
          size="tiny"
        />
      );
    }
  }

  isOther(types) {
    return types && types.find((type) => type.text === "Other").value;
  }

  canExpire(selected) {
    const type = this.props.document_types.find((t) => t.id === selected);
    this.setState({
      can_expire: type.can_expire ? true : false,
    });
  }

  handleDateChange(date: Date): void {
    this.setState({
      expiration_date: date,
    });
  }

  onSubmitHandler(formData, reset) {
    const typeCheck =
      this.props.docs &&
      this.props.docs.length &&
      this.props.docs.find((d) => d.id === formData.document_type_id);
    if (typeCheck && typeCheck.one_active_max && !this.state.one_active) {
      this.setState({
        one_active: typeCheck,
      });
    } else {
      this.submitNewFile(formData);
    }
  }

  async submitNewFile(formData) {
    const file = this.state.file[0];

    if (!file)
      this.setState({
        uploaderror: "You must upload a file to save",
      });
    else {
      const image = this.state.croppedImage
        ? this.state.croppedImage
        : await promiseBase64(file);
      const filepackage: any = {
        file_name: formData.file_name || this.state.file[0].name,
        image,
      };
      if (this.props.documentmode) {
        filepackage.document_type_id = formData.document_type_id;
      }
      const canExpire =
        this.props.document_types &&
        this.props.document_types.find(
          (doc) => doc.id === formData.document_type_id
        );
      if (canExpire && canExpire.can_expire) {
        if (this.state.expiration_date) {
          this.setState({ uploading: true, uploaderror: null });
          filepackage.expiration_date = this.state.expiration_date.toDate();
        } else {
          this.setState({
            uploaderror:
              "Expiration date is required for this type of document.",
          });
          return;
        }
      }
      this.props.onSubmit(filepackage, () =>
        this.setState({ uploading: false })
      );
    }
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const FileUploadComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IDocumentTypesGQL, any, any, ClassAttributes<any>>(
    gqlQueries.documentTypes,
    {
      skip: () => !localStorage.getItem("token"),
      options: (props: any) => {
        return {
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, document_types, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, error };

        return {
          document_types,
          refetchDocTypes: refetch,
        };
      },
    }
  )
)(FileUploadComponentView) as React.ComponentType<any>;

export default FileUploadComponent;
