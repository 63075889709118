import * as React from "react";
import { Select } from "formsy-semantic-ui-react";
import {
  Accordion,
  Button,
  Grid,
  Header,
  Icon,
  Input as RangeInput,
  Input,
  Label,
} from "semantic-ui-react";

const TypeSelect = (props) => {
  const emptyOption = [{ key: "", value: "", text: "[not set]" }];
  const typeOptions = props.useYesNo
    ? ["No", "Optional", "Mandatory"]
    : props.type === "NUMBER"
    ? ["0", "30", "60", "90", "180", "360"]
    : ["true", "false"];
  return (
    <Select
      placeholder="Select One..."
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      options={[
        ...emptyOption,
        ...typeOptions.map((o, i) => {
          return { key: o, value: props.useYesNo ? i.toString() : o, text: o };
        }),
      ]}
    />
  );
};

export class SettingSelector extends React.Component<
  LMI.SettingSelectionProps,
  LMI.SettingSelectionState
> {
  constructor(props) {
    super(props);
    this.state = {
      openRangeSelector: false,
      rangeValues: {
        values: props.value
          ? this.getRangeJSON(props.value)
          : [
              ["0", "99"],
              ["100", "269"],
              ["270", "364"],
              ["365", null],
            ],
      },
      savedRangeValue: null,
    };
  }

  getSelector = (settingProps) => {
    const { type, name, value } = settingProps;
    const { openRangeSelector, savedRangeValue } = this.state;
    const range_value = savedRangeValue
      ? this.getRangeJSON(savedRangeValue)
      : value
      ? this.getRangeJSON(value)
      : null;

    switch (type) {
      case "RANGE":
        return (
          <div>
            <div className={`overlay${openRangeSelector ? " active" : ""}`} />
            {!range_value || openRangeSelector ? (
              <Accordion styled fluid className="rangeForm">
                <Accordion.Title
                  active={openRangeSelector}
                  content="Override Range Values"
                  index={0}
                  onClick={() =>
                    this.setState({ openRangeSelector: !openRangeSelector })
                  }
                />
                <Accordion.Content
                  active={openRangeSelector}
                  content={this.getRangeForm()}
                />
              </Accordion>
            ) : (
              <span>
                {range_value.map((r, i) => {
                  return (
                    <Label key={i}>{`${r[0] ? r[0] : "0"}-${
                      r[1] ? r[1] : "max"
                    }`}</Label>
                  );
                })}
                <Button
                  type="button"
                  icon="pencil"
                  size="mini"
                  onClick={() => this.setState({ openRangeSelector: true })}
                />
                <Input
                  name={name}
                  type="hidden"
                  value={savedRangeValue || value}
                />
              </span>
            )}
          </div>
        );
      case "STRING":
        return (
          <Input name={name} placeholder="Add Setting Override" value={value} />
        );
      case "BOOLEAN":
        return <TypeSelect {...this.props} />;
      case "NUMBER":
        return <TypeSelect {...this.props} />;
      default:
        break;
    }
  };

  getRangeForm = () => {
    return (
      <Grid className="range-inputs">
        {this.state.rangeValues.values.map((rv, i) => {
          const min = rv[0] ? rv[0] : "";
          const max = rv[1] ? rv[1] : "";
          const isLast = i === this.state.rangeValues.values.length - 1;
          return (
            <Grid.Row key={i}>
              <Grid.Column width={2}>
                <Header
                  as="h3"
                  content={i === 0 ? "0" : min}
                  style={{ marginTop: "7px" }}
                />
              </Grid.Column>
              <Grid.Column textAlign="center" width={1}>
                <Icon name="minus" style={{ marginTop: "10px" }} />
              </Grid.Column>
              <Grid.Column width={4}>
                {isLast ? (
                  <Header as="h3" content="max" style={{ marginTop: "7px" }} />
                ) : (
                  <RangeInput
                    name="range"
                    value={max}
                    placeholder="Max"
                    onChange={(e, data) => {
                      const values = this.state.rangeValues.values.map(
                        (s, ix) => {
                          if (ix === i) s[1] = data.value;
                          if (ix === i + 1)
                            s[0] = (parseInt(data.value, 10) + 1).toString();
                          return s;
                        }
                      );
                      this.setState({ rangeValues: { values } });
                    }}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          );
        })}
        <Grid.Column width={16}>
          <Button
            type="button"
            content="Done Editing"
            onClick={(e) => this.prepAndSaveRange()}
          />
        </Grid.Column>
      </Grid>
    );
  };

  prepAndSaveRange = () => {
    const newRange = this.state.rangeValues.values.map((v) => {
      return v.map((i) => {
        return parseInt(i, 10);
      });
    });
    this.setState({
      openRangeSelector: false,
      savedRangeValue: JSON.stringify(newRange),
    });
    this.props.onSaveRange(JSON.stringify(newRange));
  };

  getRangeJSON = (value) => {
    try {
      const json = JSON.parse(value);
      if (typeof json !== "object") return null;
      return json;
    } catch (e) {
      return null;
    }
  };

  render() {
    return this.getSelector(this.props);
  }
}

export default SettingSelector;
