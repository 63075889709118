import * as React from "react";
import { ClassAttributes } from "react";
import track from "react-tracking";
import { gqlQueries } from "gql-imports";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { addAlert } from "api/redux/actions";
import { restAPI } from "../../../../../utils/rest";
import { filterDataSet, formatCurrency, handleErrorResponse, handleSuccessResponse } from "client/utils/functions";
import { AnalyticsEventType, Permission } from "loopmein-shared";
import { hasPermission } from "client/utils/userAccess";

import { Loading } from "client/components/Loading";
import { Button, Grid, Header, Icon, Image, Input, Label, List, Popup, Segment } from "semantic-ui-react";
import { ModalComponent, DefaultModalProps } from "../../ModalComponent";
import InspectionItemTypeForm from "../../InventoryInspection/components/InspectionTypeForm";
import { InspectionService } from "../../InventoryInspection/util";
import { InspectionTemplateForm } from "../../InspectionTemplates";
import { Session } from "client/utils/session";

import "./InspectionSettingsTabPanel.css";

enum editableLists {
	inspectionItemType = 1,
	inspectionTemplates = 2
}

@track(
	props => {
		return {
			event_type: AnalyticsEventType.SUBNAV,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}settings.inspections`
		};
	},
	{ dispatchOnMount: true }
)
export class InspectionsSettingsTabPanelView extends React.Component<LMI.IInspectionSettingsProps, LMI.IInspectionSettingsState> {
	constructor(props) {
		super(props);
		this.state = {
			openListEditor: -1,
			inspectionItemType: null,
			inspectionTemplate: null,
			disableDelete: false,
			typeFilter: null
		};
	}

	getTemplates() {
		// format in the option props so we can see if it is active and also have the options data available in template items
		const { templates } = this.props;
		const formatItems = t => {
			const template = { ...t };
			if (template.inspection_template_items?.length > 0) {
				template.inspection_template_items = template.inspection_template_items.map(ti => this.templateItemOptionAdminProps(ti));
				return template;
			} else return template;
		};
		const formatted = templates?.length ? [...templates.map(i => formatItems(i))] : [];
		return formatted;
	}

	templateItemOptionAdminProps(templateItem) {
		const option = this.props.inspection_item_options?.options?.find(o => o.id == templateItem.inspection_item_option_id);
		return { ...option, ...templateItem };
	}

	render() {
		const { openListEditor, typeFilter } = this.state;
		const { loading, inspection_item_options } = this.props;

		if (loading) return <Loading />;

		const templates = this.getTemplates();
		const canEditInspectionItemTypes = hasPermission(this.props.permissions, Permission.ADMIN_INSPECTIONS, Session.get("isSuperUser"));
		const canCreateTemplates = hasPermission(this.props.permissions, Permission.ADMIN_INSPECTIONS, Session.get("isSuperUser"));

		let typeOptions = inspection_item_options && inspection_item_options.options ? inspection_item_options.options : [];
		if (typeOptions.length > 0 && typeFilter) typeOptions = typeOptions.filter(item => filterDataSet({ searchString: typeFilter, item }));

		const activeTemplateItems = template => template?.inspection_template_items?.filter(i => i.is_active);

		return (
			<div id="InspectionSettings" className="panel-content">
				<ModalComponent {...this.getModalProps(openListEditor)} />
				<Grid>
					<Grid.Column width={12}>
						<Header as="h3">
							<Image src={`/images/inspection-icon.svg`} />
							<Header.Content>Inspection Settings</Header.Content>
						</Header>
					</Grid.Column>
				</Grid>
				<Segment id="InspectionTypeItemsList">
					<Header as="h4">
						<Input className="type-filter" placeholder="Search" onChange={(e, data) => this.setState({ typeFilter: data.value })} />
						<Button
							className="add-item-btn op-codes"
							content="Add OP Code"
							floated="right"
							size="mini"
							onClick={() =>
								this.setState({
									openListEditor: editableLists.inspectionItemType
								})
							}
						/>
						OP Codes
					</Header>
					{!inspection_item_options || (inspection_item_options.options && inspection_item_options.options.length <= 0) ? (
						<Header content="There are currently no item types configured." as="h5" color="grey" />
					) : (
						<div style={{ clear: "both" }}>
							<div className="tableTop">
								<Grid>
									{canEditInspectionItemTypes && <Grid.Column width={2}>EDIT/DELETE</Grid.Column>}
									<Grid.Column width={2}>OP CODE</Grid.Column>
									<Grid.Column width={canEditInspectionItemTypes ? 6 : 8}>NAME</Grid.Column>
									<Grid.Column width={2}>PARTS</Grid.Column>
									<Grid.Column width={2}>LABOR</Grid.Column>
									<Grid.Column width={2}>HOURS</Grid.Column>
								</Grid>
							</div>
							<List divided size="large" relaxed="very" className="inspection-settings-list">
								{typeOptions ? (
									typeOptions
										.filter(i => i.is_active)
										.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
										.map((option, index) => {
											return (
												<List.Item key={index}>
													<Grid>
														{canEditInspectionItemTypes && (
															<Grid.Column width={2}>
																<Icon
																	name="pencil"
																	link
																	color="blue"
																	onClick={() =>
																		this.setState({
																			inspectionItemType: option,
																			openListEditor: editableLists.inspectionItemType
																		})
																	}
																/>
																<Popup
																	on="click"
																	hideOnScroll
																	trigger={<Icon name="trash alternate outline" link color="red" />}
																	content={
																		<span>
																			Delete this Inspection Item?
																			<br />
																			<Button size="tiny" onClick={() => this.deleteInspectionItemType(option)} content="Confirm" />
																		</span>
																	}
																/>
															</Grid.Column>
														)}
														<Grid.Column width={2}>{option.op_code ? option.op_code : <span className="empty">None</span>}</Grid.Column>
														<Grid.Column width={canEditInspectionItemTypes ? 6 : 8}>{option.name}</Grid.Column>
														<Grid.Column width={2}>
															{option.parts !== null && option.parts >= 0 ? (
																option.parts > 0 ? (
																	formatCurrency(option.parts, 2)
																) : (
																	`$${option.parts}.00`
																)
															) : (
																<span className="empty">N/A</span>
															)}
														</Grid.Column>
														<Grid.Column width={2}>
															{option.labor !== null && option.labor >= 0 ? (
																option.labor > 0 ? (
																	formatCurrency(option.labor, 2)
																) : (
																	`$${option.labor}.00`
																)
															) : (
																<span className="empty">N/A</span>
															)}
														</Grid.Column>
														<Grid.Column width={2}>
															{option.hours !== null && option.hours >= 0 ? option.hours : <span className="empty">N/A</span>}
														</Grid.Column>
													</Grid>
												</List.Item>
											);
										})
								) : (
									<span />
								)}
							</List>
						</div>
					)}
				</Segment>

				<Segment>
					<Header as="h4">
						<Button
							className="add-item-btn"
							content="Add Template"
							floated="right"
							size="mini"
							onClick={() =>
								this.setState({
									openListEditor: editableLists.inspectionTemplates,
									inspectionTemplate: {
										name: "",
										description: null,
										inspection_template_items: []
									}
								})
							}
						/>
						Inspection Templates
					</Header>
					{!templates || (templates && templates.length <= 0) ? (
						<Header content="There are currently no inspection templates configured." as="h5" color="grey" />
					) : (
						<div style={{ clear: "both" }}>
							<div className="tableTop">
								<Grid>
									{canCreateTemplates && <Grid.Column width={2}>EDIT/DELETE</Grid.Column>}
									<Grid.Column width={canCreateTemplates ? 4 : 6}>NAME</Grid.Column>
									<Grid.Column width={6}>DESCRIPTION</Grid.Column>
									<Grid.Column width={4}>ITEMS</Grid.Column>
								</Grid>
							</div>
							<List divided size="large" relaxed="very" className="inspection-settings-list">
								{templates ? (
									templates
										.sort((a, b) => (a.name && b.name && a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
										.map((option, index) => {
											const activeItems = activeTemplateItems(option);
											const activeCount = activeItems ? activeItems.length : 0;
											return (
												<List.Item key={index}>
													<Grid>
														{canCreateTemplates && (
															<Grid.Column width={2}>
																<Icon
																	name="pencil"
																	link
																	color="blue"
																	onClick={() =>
																		this.setState({
																			inspectionTemplate: option,
																			openListEditor: editableLists.inspectionTemplates
																		})
																	}
																/>
																<Popup
																	hideOnScroll
																	on="click"
																	trigger={<Icon name="trash alternate outline" link color="red" />}
																	content={
																		<span>
																			Delete this Inspection Template?
																			<br />
																			<Button size="tiny" onClick={() => this.deleteInspectionTemplate(option)} content="Confirm" />
																		</span>
																	}
																/>
															</Grid.Column>
														)}
														<Grid.Column width={canCreateTemplates ? 4 : 6}>{option.name}</Grid.Column>
														<Grid.Column width={6}>{option.description}</Grid.Column>
														<Grid.Column width={4}>
															<Popup
																content={
																	activeCount > 0
																		? `${activeCount} Active Item${activeCount > 1 ? "s" : ""}`
																		: "This option has no active items and will not show up in template forms"
																}
																trigger={<Label content={activeCount} color={activeCount > 0 ? null : "red"} />}
															/>
														</Grid.Column>
													</Grid>
												</List.Item>
											);
										})
								) : (
									<span />
								)}
							</List>
						</div>
					)}
				</Segment>
			</div>
		);
	}

	getModalProps(openListEditor) {
		return openListEditor === editableLists.inspectionItemType
			? this.getInspectionItemEditorProps()
			: openListEditor === editableLists.inspectionTemplates
			? this.getInspectionTemplateEditorProps()
			: DefaultModalProps;
	}

	getInspectionItemEditorProps() {
		const { inspection_item_options } = this.props;
		const item = this.state.inspectionItemType;
		const editorProps: LMI.IModalProps = {
			size: "small",
			headerText: `${item ? "Edit" : "Add"} OP Code${item ? `: ${item.op_code ? item.op_code : item.name}` : ""}`,
			shouldBeOpen: true,
			onClose: () => this.setState({ openListEditor: -1, inspectionItemType: null }),
			contentComponent: () => (
				<InspectionItemTypeForm
					{...{
						item,
						inspection_item_options,
						onSave: itemData => this.setState({ openListEditor: -1 }, () => this.createInspectionItemType(itemData)),
						onUpdate: itemData => this.setState({ openListEditor: -1 }, () => this.updateInspectionItemType(itemData))
					}}
				/>
			)
		};
		return editorProps;
	}

	getInspectionTemplateEditorProps() {
		const { inspectionTemplate } = this.state;
		const { inspection_item_options, templates } = this.props;

		const editorProps: LMI.IModalProps = {
			size: "small",
			shouldBeOpen: true,
			headerText: inspectionTemplate && inspectionTemplate.id ? "Edit Template" : "Create Template",
			onClose: () => this.setState({ inspectionTemplate: null, openListEditor: -1 }, () => this.props.refetchTemplates()),
			contentComponent: () => {
				return (
					<InspectionTemplateForm
						enable_loading={false}
						types={inspection_item_options && inspection_item_options.options}
						on_close={() => this.setState({ inspectionTemplate: null, openListEditor: -1 }, () => this.props.refetchTemplates())}
						on_update={this.updateSettings.bind(this)}
						templates={templates}
						template={inspectionTemplate}
						template_items={inspectionTemplate?.inspection_template_items ?? []}
					/>
				);
			}
		};
		return editorProps;
	}

	updateSettings() {
		this.props.refetchInspectionTypeOptions();
		this.props.refetchTemplates();
	}

	deleteInspectionTemplate(template: LMI.InspectionTemplate) {
		restAPI({
			endpointName: "delete_inspection_template",
			urlArgs: [this.props.storeId, template.id],
			data: null,
			callback: (err, res) => {
				this.props.addAlert(this.getAlertProps(err, res));
				this.props.refetchTemplates();
			}
		});
	}

	createInspectionItemType(item: any) {
		restAPI({
			endpointName: "add_inspection_item_option",
			urlArgs: [this.props.storeId],
			data: InspectionService.formatInspectionTypeItemOption(item),
			callback: (err, res) => {
				this.props.addAlert(this.getAlertProps(err, res));
				this.props.refetchInspectionTypeOptions();
			}
		});
	}

	updateInspectionItemType(item: any) {
		const data = {
			id: item.id,
			...InspectionService.formatInspectionTypeItemOption(item)
		};
		const { id } = item;
		restAPI({
			endpointName: "edit_inspection_item_option",
			urlArgs: [this.props.storeId, id],
			data,
			callback: (err, res) => {
				this.setState({ inspectionItemType: null }, () => {
					this.props.addAlert(this.getAlertProps(err, res));
					this.props.refetchInspectionTypeOptions();
				});
			}
		});
	}

	deleteInspectionItemType(item: any) {
		if (!this.state.disableDelete)
			this.setState({ disableDelete: true }, () => {
				restAPI({
					endpointName: "delete_inspection_item_option",
					urlArgs: [this.props.storeId, item.id],
					data: null,
					callback: (err, res) => {
						this.setState({ disableDelete: false }, () => {
							this.props.addAlert(this.getAlertProps(err, res));
							this.props.refetchInspectionTypeOptions();
						});
					}
				});
			});
	}

	getAlertProps(err, res) {
		return {
			type: err ? "danger" : "success",
			message: err ? handleErrorResponse({ error: err }) : handleSuccessResponse({ result: res }),
			timeout: 3000
		};
	}

	static getDerivedStateFromProps(nextProps: LMI.IInspectionSettingsProps, prevState: LMI.IInspectionSettingsState) {
		let state = prevState;
		if (nextProps.templates?.length && prevState.inspectionTemplate?.id)
			state.inspectionTemplate = nextProps.templates?.find(template => template.id == prevState.inspectionTemplate.id);
		return state;
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const InspectionSettingsPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.InspectionItemOptionsGQL, any, any, ClassAttributes<any>>(gqlQueries.dealership.inspectionItemOptions, {
		options: (props: any) => {
			return {
				variables: {
					storeId: props.storeId
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inspection_item_options, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				inspection_item_options,
				refetchInspectionTypeOptions: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inspectionTemplates, {
		options: props => {
			return {
				variables: {
					storeId: props.storeId,
					isAdmin: true
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inspection_templates, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				templates: inspection_templates.templates,
				refetchTemplates: refetch
			};
		}
	})
)(InspectionsSettingsTabPanelView) as React.ComponentType<any>;

export default InspectionSettingsPanel;
