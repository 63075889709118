import * as React from "react";
import { ProgressIndicatorDirection } from "loopmein-shared";
import "./ProgressIndicator.css";

export const ProgressIndicator = ({ direction, imagePath, progressLabel, progress, width, strokeWidth, strokeColor, innerColor, outerColor, isCompleted }) => {
	const radius = width / 2 - strokeWidth * 2;
	const circumference = radius * 2 * Math.PI;
	const offset = direction === ProgressIndicatorDirection.Clockwise ? 320 : 0;

	setTimeout(() => {
		setOffset("progressRingCircle", progress, circumference);
	}, 100);

	return (
		<div id="progressIndicator">
			<svg id="progressRing" className="progress-ring" height={width} width={width}>
				<circle id="progressOuterCircle" className="progress-outer__circle" fill={outerColor} r={`${radius + 5}`} cx={`${width / 2}`} cy={`${width / 2}`} />
				{!isCompleted && (
					<circle
						style={{ strokeDasharray: circumference, strokeDashoffset: offset }}
						id="progressRingCircle"
						className="progress-ring__circle"
						strokeWidth={strokeWidth}
						stroke={strokeColor}
						fill={outerColor}
						r={`${radius}`}
						cx={`${width / 2}`}
						cy={`${width / 2}`}
					/>
				)}
				<circle
					id="progressInnerCircle"
					className="progress-inner__circle"
					fill={innerColor}
					r={`${radius + 5 - strokeWidth}`}
					cx={`${width / 2}`}
					cy={`${width / 2}`}
				/>
			</svg>
			<div id="iconText">
				<img src={imagePath} />
				<div className="text">{progressLabel}</div>
			</div>
		</div>
	);
};

const setOffset = (id, progress, circumference) => {
	const offset = circumference - (progress / 100) * circumference;
	const progressRingCircle: HTMLElement = document.querySelector("#progressRingCircle");
	if (progressRingCircle) progressRingCircle.style.strokeDashoffset = `${offset}`;
};

export default ProgressIndicator;
