import { Form, Input } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { MaskedInputComponent } from "client/pages/admin/components/MaskedInputComponent";
import { RegularExpressions } from "client/utils/regex";
// node_modules
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
// components
import { Button, Card, Grid, Icon, Label, Message } from "semantic-ui-react";
import { validateEmail } from "../../../../../utils/functions";

import "./PrimaryContactComponent.css";

export class EmailInputView extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.state = {
			foundUser: false
		};
	}

	render() {
		return (
			<Input
				name="email"
				validations="isEmail"
				validationError="This is not an email"
				onChange={(e, data) => this.checkEmail(data)}
				placeholder="Enter a valid email"
				required
			/>
		);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.su_user_check && !this.state.foundUser) {
			this.setState({ foundUser: true });
			this.props.setValues(nextProps.su_user_check);
		}
	}

	checkEmail(data: any) {
		if (validateEmail(data.value)) {
			this.props.setEmailValue(data.value);
		}
	}
}

const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: any) => ({});

export const EmailInput = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.super.userCheck, {
		skip: (ownProps: any) => !ownProps.value,
		options: (props: any) => {
			return {
				variables: {
					email: props.value
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, su_user_check, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				su_user_check,
				refetch
			};
		}
	})
)(EmailInputView) as React.ComponentType<any>;

export class PrimaryContactComponent extends React.Component<LMI.SUAdminPrimaryContact, any> {
	constructor(props) {
		super(props);

		this.state = {
			currentEmailValue: null,
			foundUser: null
		};
	}

	render() {
		const props = this.props;
		const emailInputProps: any = {
			value: this.state.currentEmailValue,
			setEmailValue: data => {
				this.setState({ currentEmailValue: data, foundUser: null });
			},
			setValues: data => {
				this.setState({ foundUser: data });
			}
		};
		const phoneInputProps: LMI.IMaskInputProps = {
			type: "phone",
			value: this.state.foundUser && this.state.foundUser.user.phone,
			disabled: !this.state.currentEmailValue ? true : false
		};

		const errorLabel = <Label color="red" pointing="above" />;

		return (
			<div id="primary-contact-component" className={this.props.info_screen ? " storeinfo" : ""}>
				<Form noValidate onValidSubmit={props.callback.bind(this)}>
					<Card fluid>
						<Card.Content>
							<Card.Header>
								<Icon link name="close" color="red" className="close-right" onClick={() => this.props.nevermind()} />
								Add Primary Contact
							</Card.Header>
						</Card.Content>
						<Card.Content className="new-primary-form">
							<p style={{ textAlign: "center" }}>
								<br />
								Creating a new user as a primary contact defaults the employees role to admin.
								<br />
								<br />
							</p>
							<Grid>
								<Grid.Column width={3} />
								<Grid.Column width={10}>
									<Form.Field>
										<label>Email Address</label>
										<EmailInput
											{...emailInputProps}
											validations={{
												matchRegexp: RegularExpressions.EMAIL
											}}
											validationErrors={{
												matchRegexp: "Valid Email address is required",
												isDefaultRequiredValue: "Email is required"
											}}
											errorLabel={errorLabel}
										/>
									</Form.Field>
									<Form.Field>
										<label>First Name</label>
										<Input
											name="first_name"
											value={this.state.foundUser && this.state.foundUser.user.first_name}
											disabled={!this.state.currentEmailValue}
											required
											validations="isExisty"
											validationErrors={{
												isExisty: "No numbers or special characters allowed",
												isDefaultRequiredValue: "Contact first name is required"
											}}
											errorLabel={errorLabel}
										/>
									</Form.Field>
									<Form.Field>
										<label>Last Name</label>
										<Input
											name="last_name"
											value={this.state.foundUser && this.state.foundUser.user.last_name}
											disabled={!this.state.currentEmailValue}
											required
											validations="isExisty"
											validationErrors={{
												isExisty: "No numbers or special characters allowed",
												isDefaultRequiredValue: "Contact last name is required"
											}}
											errorLabel={errorLabel}
										/>
									</Form.Field>
									<Form.Field>
										<label>Mobile Phone</label>
										<MaskedInputComponent {...phoneInputProps} />
									</Form.Field>
									{this.state.foundUser && <Message info content="A user with this email already exists!" />}
								</Grid.Column>
							</Grid>
							<Input type="hidden" value={props.store_id} name="store_id" />
							<Input type="hidden" value={this.state.foundUser} name="user_found" />
						</Card.Content>
						<Card.Content extra>
							<Button positive content="Save Primary Contact" />
						</Card.Content>
					</Card>
				</Form>
			</div>
		);
	}
}

export default PrimaryContactComponent;
