// node_modules
import * as React from "react";
// components
import { Header, Modal, Loader, Dimmer } from "semantic-ui-react";
import "./ModalComponent.css";

export const DefaultModalProps = { shouldBeOpen: false, contentComponent: () => <span />, onClose: null };
export class ModalComponent extends React.Component<LMI.IModalProps, LMI.IModalState> {
	constructor(props) {
		super(props);

		this.state = {
			showMessage: false,
			message: ""
		};
	}

	render() {
		const { shouldBeOpen, onClose, className, size, style, headerText, scrolling, contentComponent, loading, loading_message, hideDimmer } = this.props;
		const { showMessage, message } = this.state;
		const dimmer = hideDimmer ? { className: "transparent-dimmer" } : true;
		return (
			<Modal
				size={size}
				style={style}
				open={shouldBeOpen}
				closeIcon="close"
				dimmer={dimmer}
				className={`dialog ${!headerText ? "no-header " : ""}${className ? className : ""}`}
				onClose={(evt, data) => onClose()}>
				{headerText ? <Header as="h1" content={headerText} /> : ""}
				{showMessage ? <Header as="h2">{message}</Header> : ""}
				<Modal.Content className={!headerText ? "no-padding" : ""} scrolling={scrolling}>
					{loading && (
						<Dimmer active>
							<Loader>{loading_message}</Loader>
						</Dimmer>
					)}
					{contentComponent({ onClose })}
				</Modal.Content>
			</Modal>
		);
	}
}

export default ModalComponent;
