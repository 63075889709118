// node_modules
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { flowRight as compose } from "lodash";

import { updateCurrentRoute } from "api/redux/actions";
import { FormatMenuData } from "client/components/Menu/utils";
import "./AdminPageView.css";

export class AdminPageView extends React.Component<LMI.IAdminProps, LMI.IAdminState> {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			items: []
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.routes && nextProps.count !== prevState.count) {
			const routes = nextProps.routes;
			return {
				count: nextProps.count,
				items: FormatMenuData(routes)
			};
		}
		return null;
	}

	render() {
		const { items } = this.state;
		return <Redirect to={items[0].path} />;
		// return (
		// 	<div className={`lmi-admin-page page`}>
		// 		<Card.Group itemsPerRow="5" textAlign="center">
		// 			{items &&
		// 				items.map((item, index) => {
		// 					const icon = item.menuIcon ? item.menuIcon : "/images/list.svg";
		// 					return (
		// 						<Card key={item.key} color={SemanticCOLORS[index]} raised as={Link} to={item.path} onClick={() => this.props.updateCurrentRoute(item.label)}>
		// 							<Image src={icon} size="small" className="item-icon" />
		// 							<Card.Content>
		// 								<Header content={item.label} />
		// 							</Card.Content>
		// 						</Card>
		// 					);
		// 				})}
		// 		</Card.Group>
		// 	</div>
		// );
	}
}

const mapStateToProps = (state: any) => {
	return {
		routes: state.app.admin.routes,
		count: state.app.admin.routes.length
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		updateCurrentRoute: data => {
			dispatch(updateCurrentRoute(data));
		}
	};
};
export const AdminPage = compose(connect(mapStateToProps, mapDispatchToProps))(AdminPageView);
