import * as React from "react";
import * as queryString from "query-string";
import { addAlert } from "api/redux/actions";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { handleErrorResponse } from "../../../utils/functions";
import { restAPI } from "../../../utils/rest";

import { Form, Input } from "formsy-semantic-ui-react";
import { Button, Container, Header, Image, Label } from "semantic-ui-react";

import "./PasswordReset.css";

export class PasswordResetPageView extends React.Component<
  LMI.IPRProps,
  LMI.IPRState
> {
  constructor(props) {
    super(props);

    this.state = {
      formData: null,
      showMessage: false,
      message: "",
    };
  }
  render() {
    const errorLabel = <Label color="red" pointing="above" />;
    if (localStorage.getItem("token")) {
      return <Redirect to="/" push />;
    } else {
      return (
        <Container className="change-password-page page">
          <Container className="change-password-block">
            <Image src="/images/LoopMeIn_LOGO_FINAL.svg" alt="" size="small" />
            <Header as="h1">Reset your password</Header>
            {this.state.showMessage ? (
              <Header as="h5">{this.state.message}</Header>
            ) : (
              ""
            )}
            <Form
              noValidate
              onValidSubmit={(formData, reset) => {
                this.onSubmitHandler(formData, reset);
                this.setState({
                  showMessage: true,
                  message: "Resetting password...",
                });
              }}
            >
              <Form.Field id="new_password">
                <label className="nopad">New Password:</label>
                <Input
                  name="new_password"
                  type="password"
                  required
                  validations="isExisty,minLength:8"
                  validationErrors={{
                    isExisty: "Must not be empty",
                    minLength: "Password must be a minimum of 8 characters.",
                    isDefaultRequiredValue: "New Password is required",
                  }}
                  errorLabel={errorLabel}
                />
              </Form.Field>
              <Form.Field id="new_password_confirm">
                <label className="nopad">Confirm New Password:</label>
                <Input
                  name="new_password_confirm"
                  type="password"
                  required
                  validations="isExisty,equalsField:new_password"
                  validationErrors={{
                    isExisty: "Must not be empty",
                    equalsField: "Must match the new password",
                    isDefaultRequiredValue:
                      "Confirming the new password is required",
                  }}
                  errorLabel={errorLabel}
                />
              </Form.Field>
              <Button positive className="submit-btn" type="submit">
                Reset Password
              </Button>
            </Form>
          </Container>
        </Container>
      );
    }
  }

  onSubmitHandler(formData, reset) {
    const parsed = queryString.parse(this.props.location.search);
    // console.log("rest password form submitted", formData, parsed.token);
    restAPI({
      endpointName: "reset_user_password",
      urlArgs: null,
      data: {
        token: parsed.token,
        password: formData.new_password,
        passwordConfirm: formData.new_password_confirm,
      },
      callback: (error, res) => {
        const message = error
          ? handleErrorResponse({
              error,
              message: "There was a problem resetting your password.",
            })
          : "Your password was successfully changed. Please log in.";
        this.props.addAlert({
          type: error ? "danger" : "success",
          message,
          timeout: 5000,
        });
        if (!error) {
          setTimeout(() => {
            this.props.history.push("/login");
          }, 5000);
        } else {
          reset();
          this.setState({
            message: "There was a problem resetting your password.",
          });
          console.error(error);
        }
      },
    });
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const PasswordResetPage = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PasswordResetPageView);
export default PasswordResetPage;
