import * as React from "react";
import { Loader, Popup } from "semantic-ui-react";

export class TotalTimer extends React.Component<LMI.ITotalTimerProps, LMI.ITotalTimerState> {
	phaseInterval: any;
	totalInterval: any;

	constructor(props: LMI.ITotalTimerProps) {
		super(props);

		this.state = {
			loading: false,
			total_elapsed_time: null,
			phase_elapsed_time: null
		};
	}

	componentDidMount() {
		const { id, phase } = this.props;
		if (id) {
			if (!phase.final_stage) {
				this.getPhaseTimer();
			} else {
				this.getTotalTimer();
			}
		}
	}

	componentWillUnmount(): void {
		if (this.totalInterval) clearInterval(this.totalInterval);
		if (this.phaseInterval) clearInterval(this.phaseInterval);
	}

	// This was the total timer but now it's a total phase timer, keeping this until we're sure this change is perm.
	getTotalTimer() {
		const { phase, total_phase_elapsed_ms, phase_timer_running_since } = this.props;
		const now = new Date();
		const phase_timer_run: any = new Date(parseInt(phase_timer_running_since, 10));

		let elapsed;
		if (phase_timer_run > 0) {
			// New session, elapsed time not recorded yet, so calculate it
			if (total_phase_elapsed_ms === 0) {
				elapsed = now.getTime() - phase_timer_run;
			} else elapsed = total_phase_elapsed_ms > 0 ? total_phase_elapsed_ms + now.getTime() - phase_timer_run : 0;
		} else {
			elapsed = total_phase_elapsed_ms;
		}

		// console.log("Dispo:", this.calculateElapsedTime(dispositioned_at - recon_started_at_ms), this.calculateElapsedTime(total_phase_elapsed_ms))

		this.setState({
			total_elapsed_time: elapsed
		});

		const interval = 1000;
		this.totalInterval = setInterval(() => {
			if (!phase.final_stage && phase_timer_running_since) {
				this.setState(prevState => ({ total_elapsed_time: prevState.total_elapsed_time + interval }));
			}
		}, interval);
	}

	getPhaseTimer() {
		const { phase_timer_running_since, current_phase_elapsed_ms } = this.props;
		const now = new Date();
		const phase_timer_run: any = new Date(parseInt(phase_timer_running_since, 10));
		let elapsed;
		if (phase_timer_run > 0) {
			// New session, elapsed time not recoreded yet, so calculate it
			if (current_phase_elapsed_ms === 0) {
				elapsed = now.getTime() - phase_timer_run;
			} else elapsed = current_phase_elapsed_ms > 0 ? current_phase_elapsed_ms + now.getTime() - phase_timer_run : 0;
		} else {
			elapsed = current_phase_elapsed_ms;
		}

		this.setState({
			phase_elapsed_time: elapsed
		});

		const interval = 1000;
		this.phaseInterval = setInterval(() => {
			if (phase_timer_run) {
				this.setState(prevState => ({ phase_elapsed_time: prevState.phase_elapsed_time + interval }));
			}
		}, interval);
	}

	calculateElapsedTime(elapsedTime) {
		let ms = elapsedTime;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = Math.floor(ms / 24);
		return {
			days: days.toString().padStart(2, "0"),
			hours: hours.toString().padStart(2, "0"),
			minutes: minutes.toString().padStart(2, "0"),
			seconds: seconds.toString().padStart(2, "0")
		};
	}

	render() {
		const { phase_elapsed_time, total_elapsed_time } = this.state;
		const { phase_timer_running_since, phase, disablePopup } = this.props;

		if (phase_timer_running_since && !phase_elapsed_time && !total_elapsed_time) {
			return <Loader className={`loader active right`} size="small" />;
		} else {
			const { days, hours, minutes, seconds } = this.calculateElapsedTime(phase_elapsed_time || total_elapsed_time);
			return (
				<Popup
					trigger={
						<span className="total-time">
							{days}:{hours}:{minutes}
						</span>
					}
					content={
						<div>
							<div>
								<strong>{phase && phase.final_stage ? "Total Recon Time:" : "Cumulative Stage Time:"}</strong>
							</div>
							{`${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`}
						</div>
					}
					disabled={disablePopup !== undefined ? disablePopup : false}
					position="bottom right"
					size="small"
					inverted
				/>
			);
		}
	}
}
