// node_modules
import * as React from "react";
import { timeZones } from "client/utils/globals";
// components
import { Button, Icon } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import StateSelector from "client/components/StateSelector/StateSelector";
import { MaskedInputComponent } from "client/pages/admin/components/MaskedInputComponent";

export class EditStoreComponent extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			selectedState: null,
			selectedTimeZone: null
		};
	}

	render() {
		const { store } = this.props;
		const selectedTimeZone = timeZones.find(tz => tz.utc === store.timezone_name);
		return (
			<Form ref="form" size="small" onValidSubmit={this.props.updateStore.bind(this)}>
				<Icon name="close" color="red" className="action-icon" link onClick={() => this.props.onClose()} />
				<Form.Field>
					<label>Store Name</label>
					<Input name="name" value={store.name} required />
				</Form.Field>
				<Form.Field>
					<label>Address 1</label>
					<Input name="address1" value={store.address1} />
				</Form.Field>
				<Form.Field>
					<label>Address 2</label>
					<Input name="address2" value={store.address2} />
				</Form.Field>
				<Form.Group widths="equal">
					<Form.Field>
						<label>City</label>
						<Input name="city" value={store.city} />
					</Form.Field>
					<Form.Field>
						<label>{store.country_code === "US" ? "State" : "Province"}</label>
						<StateSelector
							{...{
								value: store.state,
								placeholder: "Select a State/Province",
								onChange: data => this.setState({ selectedState: data })
							}}
						/>
					</Form.Field>
					<Form.Field>
						<label>Postal</label>
						<Input name="zip" value={store.zip} />
					</Form.Field>
				</Form.Group>
				<Form.Group widths="equal">
					<Form.Field>
						<label>Phone</label>
						<MaskedInputComponent {...{ type: "phone", value: store.phone }} />
					</Form.Field>
					<Form.Field>
						<Form.Select
							search
							label="Time Zone"
							name="timezone_name"
							value={selectedTimeZone ? selectedTimeZone.value : null}
							options={timeZones.map(({ value, text }) => ({ value, text: value, key: text }))}
							placeholder="Select a Time Zone"
							onChange={(e, { value }) => this.setState({ selectedTimeZone: value })}
							required
						/>
					</Form.Field>
				</Form.Group>
				<br />
				<Button positive floated="right" type="submit">
					Update Store Info
				</Button>
			</Form>
		);
	}
}

export default EditStoreComponent;
