import * as React from "react";
import { Checkbox, Divider, Grid, Image, Loader, Segment } from "semantic-ui-react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { gqlQueries } from "gql-imports";
import { graphql } from "@apollo/react-hoc";
import { PhaseTimer } from "../../../WorkflowTools";
import { getPhaseColor, shadeColor } from "client/utils/functions";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

@track(
	props => {
		return { event_type: AnalyticsEventType.NAVIGATION, event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}results` };
	},
	{ dispatchOnMount: false }
)
class SearchResultsView extends React.Component<LMI.IReconInventorySearchResultsProps, LMI.IReconInventorySearchResultsState> {
	constructor(props: any) {
		super(props);

		this.state = {
			includeFinalStages: false
		};
	}

	openInventory(detail) {
		this.props.onClose();
		this.props.history.push(`/admin/dealerships/workflow/${detail.current_phase}?id=${detail.inventory_id}`);
	}

	getSearchFilters() {
		const { includeFinalStages } = this.state;
		return (
			<Checkbox
				label="Include Inventory in Final Stages"
				checked={includeFinalStages}
				onChange={() => this.setState({ includeFinalStages: !includeFinalStages })}
			/>
		);
	}

	render() {
		const { term, loading, hasErrors, inventory_results, dealer_phases, final_phases } = this.props;
		const { includeFinalStages } = this.state;

		if (hasErrors) return <Segment color="red" content="We're sorry, there was a problem. Try again later." />;
		if (loading) return <Loader active />;

		const searchResults = inventory_results
			? inventory_results
					.filter(i => i.current_phase && i.current_phase_name)
					.filter(i => (includeFinalStages ? true : !final_phases.includes(i.current_phase)))
			: [];

		if (searchResults)
			return (
				<div className="search-results">
					{searchResults.length > 0 ? (
						<div className="results-list">
							<Divider horizontal>{`${searchResults.length} Result${searchResults.length > 1 ? "s" : ""}`}</Divider>
							<div className="results-container">
								{searchResults.map(detail => {
									const phaseColor: string = getPhaseColor(dealer_phases, detail.current_phase);
									const timerProps = { phase: dealer_phases.find(i => i.id === detail.current_phase), update_key: detail.id.toString(), ...detail };
									const inFinalStage = final_phases.includes(detail.current_phase);
									return (
										<Segment key={detail.id} raised className="result-tile" onClick={() => this.openInventory(detail)}>
											<span className="stage-label" style={{ backgroundColor: phaseColor, textShadow: `2px 2px 5px ${shadeColor(phaseColor, -25)}` }}>
												{detail.current_phase_name}
											</span>
											<Grid className="summary">
												<Grid.Column width={3}>
													<Image size="small" rounded src={detail.photo_url ? `${detail.photo_url}?h=150&w=150&auto=compress` : ""} loading="lazy" />
												</Grid.Column>
												<Grid.Column width={13}>
													<div className="vehicle">
														<div className="condition">{detail.condition.toLowerCase() === "used" ? "PRE-OWNED" : detail.condition.toUpperCase()}</div>
														<div className="details">
															{detail.year} {detail.make} {detail.model}
														</div>
														<div className="identity">
															<span className="i-label">STOCK #:</span> {detail.stock_number} <span className="i-label">|</span>{" "}
															<span className="i-label">VIN:</span> {detail.vin}
														</div>
														{!inFinalStage && (
															<div className="identity">
																<span className="i-label">AGE:</span> {detail.days_in_stock == 0 ? ">" : detail.days_in_stock} Day
																{detail.days_in_stock > 1 ? "s" : ""} <span className="i-label">|</span> <span className="i-label">STAGE TIME:</span>{" "}
																<PhaseTimer key={detail.phase_timer_running_since_ms} {...timerProps} />
															</div>
														)}
													</div>
												</Grid.Column>
											</Grid>
										</Segment>
									);
								})}
							</div>
							<Divider hidden />
							{this.getSearchFilters()}
						</div>
					) : (
						<div>
							<br />
							<Segment color="red">
								<span className="no-result-text">
									Sorry, there were no results for <strong>{term}</strong>
								</span>
								{this.getSearchFilters()}
							</Segment>
						</div>
					)}
				</div>
			);
	}

	componentDidUpdate() {
		this.props.tracking.trackEvent({
			event_type: AnalyticsEventType.SUBNAV,
			event_subtype: `${this.props.tracking_path ? this.props.tracking_path + "." : ""}results`,
			data: { details: this.props.inventory_results }
		});

		if (
			this.props.inventory_results &&
			this.props.inventory_results[0] !== null &&
			this.props.inventory_results.length === 1 &&
			this.props.inventory_results[0].current_phase
		)
			setTimeout(() => this.openInventory(this.props.inventory_results[0]), 30);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => ({});

const ReconInventorySearchResults = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventorySearchPartDuex, {
		options: (props: any) => {
			// if taking tour massage for search results
			const search_text = props.term === "tour" ? null : props.term;
			const limit = props.term === "tour" ? 5 : 50;

			return {
				variables: {
					store_ids: parseInt(props.storeId, 10),
					search_text,
					phase: null,
					sold: false,
					limit
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inventory_search2, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				inventory_results: inventory_search2.items,
				refetchInventory: refetch
			};
		}
	})
)(SearchResultsView) as React.ComponentType<any>;

export default ReconInventorySearchResults;
