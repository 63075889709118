import { Form, Input } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { FileUploadComponent } from "client/pages/admin/components/FileUploadComponent";
import { MaskedInputComponent } from "client/pages/admin/components/MaskedInputComponent";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { getSelectableLink, SortableTable } from "client/pages/admin/components/SortableTable";
import { formatPhone, RelativeDatePipe } from "client/utils/functions";
import * as Raven from "raven-js";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Button, Card, Divider, Dropdown, Grid, Icon, Image, Label, Loader, Message, Segment } from "semantic-ui-react";
import { RolesSelectComponent, StoreSelectComponent } from "../index";
import { addAlert } from "../../../../../../../../api/redux/actions";
import { restAPI } from "../../../../../../../utils/rest";
import "./UsersListComponent.css";

export class UsersListComponentView extends React.Component<LMI.SUAdminUserListProps, LMI.SUAdminUserListState> {
	constructor(props) {
		super(props);
		this.state = {
			selectedUser: [],
			selectedUserIndex: 0,
			reload: null,
			complete: null,
			editUserInfo: false,
			editprofile: false,
			addEmployer: false,
			selectedStore: null,
			inviteSent: false,
			uploadFile: false,
			editEmployeeRole: null,
			errorMessage: null,
			forceResetText: "Force Password Reset"
		};
	}

	render() {
		const {
			selectedUser,
			selectedStore,
			editUserInfo,
			selectedUserIndex,
			addEmployer,
			editEmployeeRole,
			editprofile,
			errorMessage,
			forceResetText,
			inviteSent,
			reload,
			uploadFile
		} = this.state;
		const users = this.props.su_users && this.mapUsers(this.props.su_users);
		const selectedIndex =
			selectedUser && selectedUser.length > 1
				? selectedUser.map(u => {
						const index = users.findIndex(i => i.id.value === u.id);
						return index;
				  })
				: users && selectedUser.length > 0 && selectedUser[0]
				? users.findIndex(user => user.id.value === selectedUser[0].id)
				: null;

		// new employer props
		const storeSelectProps = {
			stores: this.props.allStores,
			disabled: editEmployeeRole ? true : false,
			selectedStore,
			storeChange: data => {
				const selectedStore = data
					? {
							text: data.name,
							value: data.id,
							type: data.store_type_id,
							orgId: data.organization_id
					  }
					: null;
				this.setState({ selectedStore });
			}
		};
		const roleSelectProps = selectedStore
			? {
					type: this.props.stores.find(store => store.value === selectedStore.value).type,
					storeId: this.props.stores.find(store => store.value === selectedStore.value).value,
					orgId: this.props.stores.find(store => store.value === selectedStore.value).orgId,
					empId: editEmployeeRole
			  }
			: null;

		const fileProps = {
			filesToAccept: "image/png, image/jpeg",
			onSubmit: this.onSubmitFileHandler.bind(this),
			onClose: () => {
				this.setState({ uploadFile: false });
			}
		};

		const phoneInputProps: LMI.IMaskInputProps = {
			type: "phone",
			value: selectedUser && selectedUser[selectedUserIndex] ? selectedUser[selectedUserIndex].phone : null
		};

		const storesetupTitle = editEmployeeRole ? "Edit Employee Role" : "Add Employee Role";

		return (
			<div id="su-users-list-component">
				{addEmployer || editEmployeeRole ? (
					<ModalComponent
						headerText={storesetupTitle}
						size="small"
						shouldBeOpen={addEmployer || editEmployeeRole ? true : false}
						onClose={() => {
							this.setState({ addEmployer: false, editEmployeeRole: false });
						}}
						className="perms-modal"
						contentComponent={() => (
							<Form noValidate onSubmit={!editEmployeeRole ? this.addUserEmployer.bind(this) : this.editUserEmployee.bind(this)}>
								<StoreSelectComponent {...storeSelectProps} />
								<RolesSelectComponent {...roleSelectProps} />
								<Divider />
								<Button positive content="Save Employee" />
							</Form>
						)}
					/>
				) : (
					""
				)}
				{uploadFile && (
					<ModalComponent
						headerText="Upload Image"
						shouldBeOpen={uploadFile}
						onClose={(evt, data) => {
							this.setState({ uploadFile: false });
						}}
						className="upload-image-modal"
						contentComponent={() => <FileUploadComponent {...fileProps} />}
					/>
				)}
				<Grid>
					<Grid.Column width={selectedUser.length > 0 ? 10 : 16} className="users-table">
						{errorMessage ? <Message error header="Proxy Error" content={errorMessage} /> : ""}
						{users && (
							<SortableTable
								paneDidMount={this.paneDidMount}
								message="Try changing the values of the stores or search input tools"
								tableData={this.getTableData(users)}
								selectableRows={true}
								multiSelect={this.props.multiSelect ? true : false}
								selectedIndex={selectedIndex}
								onClick={() => {
									// console.log("%c ~~~~~~~~SelectedUSER", "background: #222; color: #bada55");
								}}
							/>
						)}
					</Grid.Column>
					{selectedUser.length > 0 && selectedUser[selectedUserIndex] && (
						<Grid.Column width={6} className="user_info_cont">
							<Card className="user_info">
								<Card.Content>
									<Card.Header>
										{this.props.multiSelect && selectedUser.length > 1 && (
											<Segment>
												<Label attached="top right" content={`${selectedUser.length} Users`} />
												Apply to All Selected Users
												<br />
												<br />
												<Button content={forceResetText} onClick={() => this.forcePassordReset(selectedUser)} />
											</Segment>
										)}
										{selectedUser && selectedUser.length === 1 && (
											<Icon
												link
												name="close"
												color="red"
												className="close-right"
												onClick={() => {
													const array = selectedUser;
													array.splice(selectedUserIndex, 1);
													this.setState({ selectedUser: array, selectedUserIndex: 0 });
												}}
											/>
										)}
										User{selectedUser.length > 1 ? "s " : " "}Info{" "}
										{this.props.multiSelect && selectedUser.length > 1 && (
											<div>
												{selectedUser.map((i, index) => {
													return (
														<Label
															key={`su-${index}`}
															as="a"
															circular
															color={index === selectedUserIndex ? "green" : null}
															onClick={() => this.setState({ selectedUserIndex: index })}
														>
															{`${i.first_name} ${i.last_name}`}
															{index === selectedUserIndex && (
																<Icon
																	name="delete"
																	onClick={() => {
																		selectedUser.splice(index, 1);
																		this.setState({
																			selectedUser,
																			selectedUserIndex: 0,
																			editUserInfo: false,
																			editprofile: false,
																			inviteSent: false,
																			forceResetText: "Force Password Reset"
																		});
																	}}
																/>
															)}
														</Label>
													);
												})}
											</div>
										)}
									</Card.Header>
								</Card.Content>
								<Card.Content>
									{!editUserInfo && selectedUser && (
										<span className="profilePic">
											{editprofile ? (
												<div className="picMode">
													<span className="tools">
														<Icon name="upload" size="large" link onClick={() => this.setState({ uploadFile: true, editUserInfo: true })} />
														<Icon name="compress" size="large" link onClick={() => this.setState({ editprofile: false })} />
													</span>
													<Image rounded size="medium" centered src={selectedUser[selectedUserIndex].photo_url + "?w=300"} loading="lazy" />
												</div>
											) : (
												<Image
													rounded
													floated="right"
													size="mini"
													className="profile"
													src={selectedUser[selectedUserIndex].photo_url + "?w=300"}
													onClick={() => this.setState({ editprofile: true })}
													loading="lazy"
												/>
											)}
										</span>
									)}

									{!editUserInfo && <Card.Header>{selectedUser[selectedUserIndex].full_name}</Card.Header>}
									{!editUserInfo && (
										<Card.Meta>
											<p>
												Joined {selectedUser && RelativeDatePipe(selectedUser[selectedUserIndex].created_at, false, true)}
												<br />
												{selectedUser[selectedUserIndex].activated_at ? (
													<span>Activated {selectedUser && RelativeDatePipe(selectedUser[selectedUserIndex].activated_at, false, true)}</span>
												) : (
													<span className="red">Not Yet Activated</span>
												)}
											</p>
										</Card.Meta>
									)}
									<Button size="tiny" content="Proxy User" className="proxy-btn" onClick={() => this.proxyUser()} />

									{forceResetText && (
										<Button
											size="tiny"
											className="proxy-btn"
											content={forceResetText}
											onClick={() => this.forcePassordReset(selectedUser[selectedUserIndex].id)}
										/>
									)}

									<Card.Description>
										{editUserInfo ? (
											<span className="edit-user-info">
												<br />
												<Form id={selectedUser[selectedUserIndex].id} noValidate onValidSubmit={this.saveUser.bind(this)}>
													<Form.Field>
														<label>First Name</label>
														<Input name="first_name" value={selectedUser[selectedUserIndex].first_name} required />
													</Form.Field>
													<Form.Field>
														<label>Last Name</label>
														<Input name="last_name" value={selectedUser[selectedUserIndex].last_name} required />
													</Form.Field>
													<Form.Field>
														<label>Email</label>
														<Input name="email" value={selectedUser[selectedUserIndex].email} required />
													</Form.Field>
													<Form.Field>
														<label>Mobile Phone</label>
														<MaskedInputComponent {...phoneInputProps} />
													</Form.Field>
													<Button positive type="submit" content="Save" />
													<Button type="button" content="Cancel" onClick={() => this.setState({ editUserInfo: false })} />
												</Form>
											</span>
										) : (
											<span className="user-info">
												<Divider />
												<span className="info">
													<Icon
														link
														color={selectedUser[selectedUserIndex].is_active ? "green" : "grey"}
														name={selectedUser[selectedUserIndex].is_active ? "toggle on" : "toggle off"}
														onClick={() => {
															this.toggleUserActive(selectedUser[selectedUserIndex].id);
														}}
													/>{" "}
													{selectedUser[selectedUserIndex].is_active ? "Active" : "Not Active"}
												</span>
												<span className="info">
													<Icon name="mail" />{" "}
													<a href={`mailto:${selectedUser[selectedUserIndex].email}`} target="_top">
														{selectedUser[selectedUserIndex].email}
													</a>
													<br />
												</span>
												<span className="info">
													<Icon name="phone square" />{" "}
													{selectedUser[selectedUserIndex].phone ? (
														formatPhone(selectedUser[selectedUserIndex].phone)
													) : (
														<a onClick={() => this.setState({ editUserInfo: true })}>Add a phone</a>
													)}
												</span>

												<span className="labeler">
													Employed At:
													<Icon link name="plus square" color="green" onClick={() => this.setState({ addEmployer: true })} />
												</span>
												<div className="store-data">
													{selectedUser[selectedUserIndex].employees
														.map(emp => {
															return (
																<p key={emp.store_id}>
																	<span style={{ float: "right" }}>
																		<Icon
																			link
																			color={emp.is_active ? "green" : "grey"}
																			name={emp.is_active ? "toggle on" : "toggle off"}
																			onClick={() => {
																				this.updateEmployee(emp.id, emp.store_id.toString(), { is_active: emp.is_active = !emp.is_active });
																			}}
																		/>
																		<Icon
																			fitted
																			link
																			color="blue"
																			name="pencil"
																			onClick={() => {
																				const selectedStore = this.props.stores.find(store => store.value === emp.store_id);
																				this.setState({ editEmployeeRole: emp.id, selectedStore });
																			}}
																		/>
																	</span>
																	{emp.store.name} {emp.is_active}
																</p>
															);
														}) // Sort by alpha then sort inactive to bottom
														.sort((a, b) => (a.props.children[1].toLowerCase() < b.props.children[1].toLowerCase() ? -1 : 1))
														.sort((a, b) => b.props.children[3] - a.props.children[3])}
												</div>
											</span>
										)}
									</Card.Description>
								</Card.Content>
								{!editUserInfo && (
									<Card.Content extra>
										<Button type="button" onClick={() => this.setState({ editUserInfo: true })} content="Edit" />
										{inviteSent && <Button positive content="Invite Sent!" />}
										{!inviteSent && (
											<span id="invite-user">
												{selectedUser && selectedUser[selectedUserIndex].employees.filter(i => i.is_active).length > 1 ? (
													<Dropdown
														text={selectedUser && selectedUser[selectedUserIndex].invited_at ? "Resend Invite" : "Send Invite"}
														floating
														labeled
														button
														className="icon"
													>
														<Dropdown.Menu>
															<Dropdown.Header icon="send" content="From Store" />
															{selectedUser[selectedUserIndex].employees
																.filter(i => i.is_active)
																.map((emp, index) => {
																	return (
																		<Dropdown.Item key={emp.id} onClick={() => this.sendUserInvite(selectedUser[selectedUserIndex], index)}>
																			{emp.store.name}
																		</Dropdown.Item>
																	);
																})}
														</Dropdown.Menu>
													</Dropdown>
												) : (
													<Button
														color={selectedUser[selectedUserIndex] && selectedUser[selectedUserIndex].invited_at ? "green" : "blue"}
														onClick={() => this.sendUserInvite(selectedUser[selectedUserIndex], 0)}
														labelPosition="left"
														icon
													>
														<Icon name="send" />
														{selectedUser[selectedUserIndex] && selectedUser[selectedUserIndex].invited_at ? "Resend" : "Send"} Invite
													</Button>
												)}
											</span>
										)}
									</Card.Content>
								)}
							</Card>
						</Grid.Column>
					)}
				</Grid>
				{(this.props.loading || reload) && (
					<div className={reload ? "lazyloader" : ""}>
						<Loader active />
					</div>
				)}
			</div>
		);
	}

	mapUsers(users: any[]) {
		return users.map(user => {
			const modifiedUser = { ...user, created_at_date: null, activated_at_date: null };
			const callback = () => {
				const stateReset = {
					selectedUserIndex: 0,
					editUserInfo: false,
					editprofile: false,
					inviteSent: false,
					forceResetText: "Force Password Reset"
				};
				if (this.props.multiSelect) {
					const isNewSelection = this.state.selectedUser && this.state.selectedUser.findIndex(i => i.id === modifiedUser.id) === -1;
					const selectedUser = this.state.selectedUser ? [modifiedUser, ...this.state.selectedUser] : [modifiedUser];
					if (isNewSelection) {
						this.setState({
							selectedUser,
							...stateReset
						});
					}
				} else {
					this.setState({
						selectedUser: [modifiedUser],
						...stateReset
					});
				}
			};
			return {
				id: {
					value: user.id,
					hidden: true
				},
				name: {
					component: getSelectableLink,
					value: user.full_name,
					callback
				},
				email: {
					component: getSelectableLink,
					value: user.email,
					callback
				},
				phone: {
					component: getSelectableLink,
					value: formatPhone(user.phone),
					callback
				}
			};
		});
	}

	getTableData(users: any) {
		const headers: any = [
			{
				id: "name",
				label: "Name",
				sortable: true,
				selectable: true
			},
			{
				id: "email",
				label: "Email",
				sortable: true,
				selectable: true
			},
			{
				id: "phone",
				label: "Mobile Phone",
				sortable: true,
				selectable: true
			}
		];

		const sortableTableData: LMI.ITableData = {
			headers,
			body: {
				rows: users
			}
		};
		return sortableTableData;
	}

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
	}

	// data handling methods
	onSubmitFileHandler(imageObject) {
		this.saveUser({
			photo: imageObject.image
		});
		this.setState({ uploadFile: false, editUserInfo: false });
	}

	toggleUserActive(userId: string) {
		restAPI({
			endpointName: "suToggleUserActive",
			urlArgs: [userId],
			data: null,
			callback: (err, res) => {
				if (!err) {
					this.reload("update");
				} else {
					this.sendAlert({
						type: "danger",
						message: err.reason.response.data.message
					});
				}
			}
		});
	}
	updateEmployee(employeeId: number, storeId: string, data: any) {
		restAPI({
			endpointName: "updateStoreEmployee",
			urlArgs: [storeId, employeeId],
			data,
			callback: (err, res) => {
				if (!err) {
					this.props.reload();
				} else {
					this.sendAlert({
						type: "danger",
						message: err.reason.response.data.message
					});
				}
			}
		});
	}

	saveUser(formData: any) {
		const { selectedUser, selectedStore, selectedUserIndex, editUserInfo } = this.state;
		// tslint:disable-next-line:one-variable-per-declaration
		let employeeId, storeId;
		if (selectedUser && selectedUser[selectedUserIndex] && editUserInfo) {
			// We just need a valid employee id for this user so choose index 0
			employeeId = selectedUser[selectedUserIndex].employees[0].id;
			storeId = selectedUser[selectedUserIndex].employees[0].store_id;
		} else if (selectedUser && selectedUser[selectedUserIndex] && selectedStore) {
			// In this case we need the correct employee id, so find it
			employeeId = selectedUser[selectedUserIndex].employees.filter(e => e.store_id === selectedStore.value)[0].id;
			storeId = selectedUser[selectedUserIndex].employees.filter(e => e.store_id === selectedStore.value)[0].store_id;
		} else {
			// We didn't have what we needed to edit this employee so alert the user
			this.sendAlert({
				type: "danger",
				message: "Error saving user."
			});
			return;
		}
		const data = {};
		for (const name in formData) {
			if (formData[name] !== selectedUser[name] && formData[name] !== null) {
				data[name] = formData[name];
			}
		}
		restAPI({
			endpointName: "suUpdateEmployee",
			urlArgs: [storeId, employeeId],
			data,
			callback: (err, res) => {
				if (!err) {
					this.reload("update");
					this.setState({
						editUserInfo: false
					});
				} else {
					this.sendAlert({
						type: "danger",
						message: err.reason.response.data.message
					});
				}
			}
		});
	}

	proxyUser() {
		if (this.state.selectedUser[this.state.selectedUserIndex].email) {
			restAPI({
				endpointName: "suProxyUserByEmail",
				urlArgs: null,
				data: { email: this.state.selectedUser[this.state.selectedUserIndex].email },
				callback: (error, result) => {
					if (error) {
						this.setState({ errorMessage: error.reason.response.data.message });
					} else if (result.data.is_super_user) {
						this.setState({ errorMessage: "This user is a super user" });
					} else {
						this.setState({ errorMessage: null });

						if (localStorage.getItem("su_token") === null) {
							localStorage.setItem("su_token", localStorage.getItem("token"));
						}
						localStorage.setItem("token", result.data.token);
						localStorage.setItem("isu", result.data.is_super_user);

						try {
							window.location.reload();
						} catch (e) {
							console.log("Login error: ", e);
							Raven.captureException(e);
						}
					}
				}
			});
		} else {
			this.setState({ errorMessage: "We encountered an error attempting to proxy with this user. Please contact your admin." });
		}
	}

	forcePassordReset(uuids: any) {
		const isArray = Array.isArray(uuids);
		if (isArray) {
			uuids = {
				user_ids: uuids.map(id => {
					return id.id;
				})
			};
		} else {
			uuids = { user_ids: [uuids] };
		}

		this.setState({
			forceResetText: "Processing..."
		});

		restAPI({
			endpointName: "suForceUserPasswordReset",
			urlArgs: null,
			data: uuids,
			callback: (err, res) => {
				this.sendAlert({
					type: !err ? "success" : "danger",
					message: !err ? res.data.message : err.reason.response.data.message
				});
				if (!err) {
					this.setState({ forceResetText: "Finished!" });
					setTimeout(() => {
						this.setState({
							forceResetText: isArray || this.state.selectedUser.length > 1 ? "Force Password Reset" : null
						});
					}, 1000);
				}
			}
		});
	}

	sendUserInvite(formData: any, storeIndex: number) {
		const storeId = formData.employees[storeIndex].store_id;
		const userId = formData.id;

		restAPI({
			endpointName: "suSendUserInvite",
			urlArgs: [storeId],
			data: userId,
			callback: (err, res) => {
				if (!err) {
					this.setState({
						editUserInfo: false,
						inviteSent: true
					});
					this.reload("invite");
				}
				this.sendAlert({
					type: !err ? "success" : "danger",
					message: !err ? res.data.message : err.reason.response.data.message
				});
			}
		});
	}

	addUserEmployer(formData: any) {
		const permissions = {
			changed: formData.changed_permissions
				? formData.changed_permissions.map(change => {
						return {
							id: change.id,
							add_or_remove: change.checked ? "A" : "R"
						};
				  })
				: [],
			id: this.state.selectedStore.value,
			roles: formData.role
		};
		const user = {
			email: this.state.selectedUser[this.state.selectedUserIndex].email,
			first_name: this.state.selectedUser[this.state.selectedUserIndex].first_name,
			last_name: this.state.selectedUser[this.state.selectedUserIndex].last_name,
			phone: this.state.selectedUser[this.state.selectedUserIndex].phone,
			job_title_id: formData.job_title,
			role_ids: permissions.roles,
			permissions: permissions.changed
		};

		restAPI({
			endpointName: "addStoreEmployee",
			urlArgs: [this.state.selectedStore.value],
			data: user,
			callback: (error, result) => {
				if (!error) {
					this.reload("update");
				}
				this.setState({ addEmployer: false });
				this.sendAlert({
					type: error ? "danger" : "success",
					message: error ? error.reason.response.data.message : "Successfully created store employee"
				});
			}
		});
	}

	updatePermission(storeId: string, employeeId: number, data: any, perm: any) {
		const change = { add_or_remove: data.checked ? "A" : "R" };
		restAPI({
			endpointName: "updateStoreEmployeePermissions",
			urlArgs: [storeId.toString(), employeeId.toString(), perm.toString()],
			data: change,
			callback: err => {
				if (err) {
					this.sendAlert({
						type: "danger",
						message: err.reason.response.data.message
					});
				}
				this.reload("update");
			}
		});
	}

	saveRole(storeId: string, employeeId: number, roleId: any) {
		restAPI({
			endpointName: "addStoreEmployeeRole",
			urlArgs: [storeId.toString(), employeeId.toString(), roleId.toString()],
			data: null,
			callback: (err, res) => {
				if (!err) {
					this.reload("update");
				} else {
					this.sendAlert({
						type: "danger",
						message: err.reason.response.data.message
					});
				}
			}
		});
	}

	deleteRole(storeId: string, employeeId: number, roleId: any) {
		restAPI({
			endpointName: "deleteStoreEmployeeRole",
			urlArgs: [storeId.toString(), employeeId.toString(), roleId.toString()],
			data: null,
			callback: (err, res) => {
				if (!err) {
					this.reload("update");
				} else {
					this.sendAlert({
						type: "danger",
						message: err.reason.response.data.message
					});
				}
			}
		});
	}

	// wrappers for logics
	editUserEmployee(formData: any) {
		if (formData.changed_title) this.saveTitle(formData.changed_title);
		if (formData.changed_role) this.updateRoles(formData.role, formData.old_roles);
		if (formData.changed_permissions) this.saveCustomPerms(formData.changed_permissions);

		this.setState({
			selectedStore: null,
			editEmployeeRole: false
		});
	}

	updateRoles(roles: any, oldRoles: any) {
		// compare users employee role changes for this store -- TESTED
		const addRoles = roles.filter(r => oldRoles.indexOf(r) === -1).map(r => r);
		const removeRoles = oldRoles.filter(r => roles.indexOf(r) === -1).map(r => r);
		const employee = this.state.selectedUser[this.state.selectedUserIndex].employees.find(emp => emp.store_id === this.state.selectedStore.value);
		if (addRoles.length) {
			addRoles.forEach(role => {
				this.saveRole(employee.store_id.toString(), employee.id, role);
			});
		}
		if (removeRoles.length) {
			removeRoles.forEach(role => {
				this.deleteRole(employee.store_id.toString(), employee.id, role);
			});
		}
	}

	saveTitle(title) {
		this.saveUser({ job_title_id: title });
	}

	saveCustomPerms(changed: any) {
		// send off requests for custom perms -- TEST
		const employee = this.state.selectedUser[this.state.selectedUserIndex].employees.find(emp => emp.store_id === this.state.selectedStore.value);
		changed.forEach(perm => {
			this.updatePermission(employee.store_id.toString(), employee.id, perm, perm.id);
		});
	}

	isBottom(el) {
		return el.scrollTop + el.clientHeight >= el.scrollHeight;
	}

	paneDidMount = node => {
		if (node) node.addEventListener("scroll", () => this.reloadtrigger(node));
	};

	reloadtrigger(node: any) {
		if (this.isBottom(node) && !this.state.reload && !this.state.complete) this.reload();
	}

	reload(event?: any) {
		if (this.props.su_users) {
			const lazy = ["update", "invite"].indexOf(event) === -1;
			const users = this.props.su_users && this.props.su_users.length;
			// if lazy load use offset
			const offset = lazy ? users : undefined;
			// set limit for non lazy load reloads
			const limit = !lazy ? users : 20;
			this.setState({ reload: true });
			this.props.reload(offset, limit).then(prev => {
				const complete = prev.data.su_users.length < 20 ? true : false;
				this.setState({ complete, reload: false });
			});
		}
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		// reset complete load state
		if (nextProps.search !== this.props.search) {
			this.setState({
				selectedUser: []
			});
		}
		if (this.state.complete) {
			this.setState({
				complete: false
			});
		}
	}

	componentDidUpdate(prev) {
		if (this.state.selectedUser && prev.su_users !== this.props.su_users && prev.search === this.props.search) {
			const selectedUser = this.state.selectedUser.map(user => {
				return this.props.su_users && this.props.su_users.find(use => use && user && use.id === user.id);
			});
			this.setState({ selectedUser });
		}
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const UsersListComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.super.employees, {
		options: (props: any) => {
			return {
				variables: {
					limit: 20,
					search: props.search,
					storeIds: props.filter_stores ? props.filter_stores : undefined
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, su_users, refetch, fetchMore } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				su_users,
				refetchUsers: refetch,
				reload: (offset, limit) => {
					return fetchMore({
						variables: {
							offset,
							limit
						},
						updateQuery(prev: LMI.SuUsersAdminGQL, { fetchMoreResult }) {
							const newUsers: any = fetchMoreResult;
							if (!newUsers.su_users.length) {
								return prev;
							} else {
								return Object.assign({}, prev, {
									su_users: offset === undefined ? newUsers.su_users : [...prev.su_users, ...newUsers.su_users]
								});
							}
						}
					});
				}
			};
		}
	})
)(UsersListComponentView) as React.ComponentType<any>;

export default UsersListComponent;
