import { graphql } from "@apollo/react-hoc";
import "@syncfusion/ej2-popups/styles/material.css";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import { gqlQueries } from "gql-imports";
import RenderDataGrid, {
  DataGridProps,
  GridColumnHeaders,
  GridToolOptions,
} from "client/components/DataGrid/DataGrid";

import {
  formatPrecisionCurrency,
  formatUSNumber,
  handleErrorResponse,
  multiTokenFilter,
} from "client/utils/functions";
import { flowRight as compose } from "lodash";
import moment from "moment";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Confirm, Dropdown, Grid, Icon, Tab } from "semantic-ui-react";
import DetailsComponent from "./components/DetailsTab";
import ChecklistComponent from "./components/ChecklistTab";
import InspectionComponent from "./components/InspectionTab";
import DocumentComponent from "./components/DocumentTab";
import CommentComponent from "./components/CommentTab";
import { MapsDialog } from "../../../../../components/MapDialog";
import { Badge } from "../../../../../components/Badges";
import * as Raven from "raven-js";
import {
  AnalyticsEventType,
  Features,
  LeaseReturnDisposition,
  Permission,
} from "loopmein-shared";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { hasPermission } from "../../../../../utils/userAccess";
import { IAgeColors } from "loopmein-shared/bin/features/leaseReturn";
import {
  DateRangePickerComponent,
  PresetDirective,
  PresetsDirective,
  RangeEventArgs,
} from "@syncfusion/ej2-react-calendars";
import { UniversalActionModalComponent } from "../../UniversalActionModalComponent";
import { AddLeaseReturnValidation } from "./components/AddLeaseReturnValidation";
import { withUAB } from "../../../../WithUAB";
import {
  addAlert,
  enableUABCallback,
  triggerUABCallback,
} from "api/redux/actions";
import { restAPI } from "../../../../../utils/rest";
import { Loading } from "client/components/Loading";
import track from "react-tracking";
import { Session } from "client/utils/session";

import "../InventoryTabPanel/InventoryTabPanel.css";
import "./LeaseReturnTabPanel.css";

@track(
  { event_type: AnalyticsEventType.NAVIGATION, event_subtype: "lease_returns" },
  { dispatchOnMount: true }
)
export class LeaseReturnView extends React.Component<
  LMI.ILeaseReturnViewProps,
  LMI.ILeaseReturnViewState
> {
  private static dispositionArray: string[] = Object.keys(
    LeaseReturnDisposition
  )
    .filter((key) => !isNaN(Number(LeaseReturnDisposition[key])))
    .map((n) => n.replace(/([A-Z])/g, " $1"));

  public today = moment(new Date()).toDate();
  public yesterday = moment().subtract(1, "days").startOf("day").toDate();
  public oneWeek = moment(new Date())
    .subtract(7, "days")
    .startOf("day")
    .toDate();
  public oneMonth = moment(new Date())
    .subtract(1, "month")
    .startOf("day")
    .toDate();
  public threeMonths = moment(new Date())
    .subtract(3, "months")
    .startOf("day")
    .toDate();
  public sixMonths = moment(new Date())
    .subtract(6, "months")
    .startOf("day")
    .toDate();
  public oneYear = moment(new Date())
    .subtract(1, "year")
    .startOf("day")
    .toDate();

  actions = ["Export"];
  body: HTMLElement;
  leftEl: HTMLElement;
  rightEl: HTMLElement;
  handle: HTMLElement;
  setPoint: number;
  origLeftWidth: number;
  origRightWidth: number;
  groupSettings: LMI.IGridGroupSettings = {
    showDropArea: false,
  };
  dialog: DialogComponent;
  grid: GridComponent;
  abortController = new AbortController();
  filterIsSet: boolean = false;
  searchKey: string = "";
  canDelete: boolean = hasPermission(
    this.props.permissions,
    Permission.EDIT_LEASE_RETURN,
    Session.get("isSuperUser")
  );

  constructor(props: LMI.ILeaseReturnViewProps) {
    super(props);

    this.state = {
      loading: false,
      panel_height: null,
      detail_open: false,
      detail: null,
      show_dialog: false,
      email: "",
      sharing: false,
      share_url: "",
      detail_id: null,
      show_lot_location: false,
      sub_id: null,
      all_checklist_item_count: null,
      uncompleted_checklist_item_count: null,
      selected_id: 0,
      uncompleted: true,
      completed: false,
      picked_up: false,
      change_made: false,
      add_remove: false,
      update_key: null,
      confirm_remove: null,
      startDate: this.oneMonth,
      endDate: this.today,
      show_stats: false,
    };
  }

  setGrid(grid) {
    this.grid = grid;
  }

  sendAlert({ type, message }) {
    this.props.addAlert({
      type,
      message,
      timeout: 3000,
    });
    if (type === "danger") {
      console.log(`Error: ${message}`);
    }
  }

  getTabPanes = (props) => {
    return [
      {
        menuItem: "Detail",
        render: () => (
          <Tab.Pane className="detail-tab">
            <DetailsComponent {...props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Checklist",
        render: () => (
          <Tab.Pane className="checklist-tab">
            <ChecklistComponent {...props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Inspection",
        render: () => (
          <Tab.Pane className="inspection-tab">
            <InspectionComponent {...props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Documents",
        render: () => (
          <Tab.Pane className="document-tab">
            <DocumentComponent {...props} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Comments",
        render: () => (
          <Tab.Pane className="comment-tab">
            <CommentComponent {...props} />
          </Tab.Pane>
        ),
      },
    ];
  };

  static formatGridData = (requests) => {
    return requests
      .sort((a, b) => {
        if (+a.created_at > +b.created_at) return 1;
        if (+a.created_at < +b.created_at) return -1;
      })
      .map((request) => {
        return {
          detail_id: request.id,
          ...request,
          ...request.vehicle,
          disposition:
            LeaseReturnView.dispositionArray[request.disposition_id - 1],
          created_at: moment(parseInt(request.created_at, 10)).format(
            "MM/DD/YY"
          ),
          pick_up_date: request.pick_up_date
            ? moment(parseInt(request.pick_up_date, 10)).format("MM/DD/YY")
            : null,
          leasing_company_name: request.leasing_company
            ? request.leasing_company.name
            : null,
          status: request.lease_return_status
            ? request.lease_return_status.name
            : null,
          action: "",
        };
      });
  };

  handleSelectRow(data) {
    if (data) {
      const selectedIndex = this.props.lease_returns.findIndex(
        (l) => l.id === data.detail_id
      );
      this.setState(
        {
          update_key: selectedIndex,
          all_checklist_item_count: data.all_checklist_item_count,
          uncompleted_checklist_item_count:
            data.uncompleted_checklist_item_count,
          comment_count: data.comment_count,
          detail: { ...data.vehicle, detail_id: data.detail_id },
          detail_open: true,
          selected_id: selectedIndex,
          selected_customer_name: data.customer_name,
        },
        () => {
          this.props.tracking.trackEvent({
            event_type: AnalyticsEventType.ACTION,
            event_subtype: "lease_returns.handleSelectRow",
            data: { details: data },
          });
        }
      );
    }
  }

  getVehicleTemplate = (props: any) => {
    const leaseImage =
      props.primary_photo_url.includes("default_vehicle_image.png") &&
      props.vehicle.stock_image_url
        ? `${props.vehicle.stock_image_url}?exp=-10&q=50&w=150&ch=Save-Data&auto=compress&txt=STOCK%20IMAGE&txt-size=11&txt-color=ffffff57&txt-align=middle,center&txt-font=Futura%20Condensed%20Medium`
        : `${props.primary_photo_url}?q=50&w=150&ch=Save-Data&auto=compress`;
    return (
      <div className="lease-return-vehicle">
        <Grid columns={2}>
          <Grid.Column width={6} className="photo">
            <img src={`${leaseImage}`} />
          </Grid.Column>
          <Grid.Column width={10} className="ymm">
            <div>
              <b>
                {props.model_year} {props.make}
              </b>
            </div>
            <div>{props.model}</div>
            <div>{props.vin}</div>
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  getMoneyTemplate = (props: any) => {
    return <span>{formatPrecisionCurrency(props.blue_book_price, false)}</span>;
  };

  getUSNumberTemplate = (props: any) => {
    return <span>{formatUSNumber(parseInt(props.odometer, 10))}</span>;
  };

  getDateTemplate = (props: any) => {
    return <span>{moment(parseInt(props.created_at, 10)).format("LL")}</span>;
  };

  getAgeTemplate = (props: any) => {
    const { store_settings } = this.props;
    const ageColors =
      store_settings &&
      Features.LeaseReturn.getLeaseAgeColor(
        parseInt(props.age, 10),
        store_settings
      );
    return (
      <span style={{ color: ageColors.barColor, fontWeight: 700 }}>
        {props.age}
      </span>
    );
  };

  selectFilter = (event: any, data: any) => {
    const selected = data.value;
    let newState = {
      search_is_set: false,
      selected_id: 0,
      detail: null,
      detail_open: false,
    };
    const filterKeys = [
      "uncompleted",
      "completed",
      "picked_up",
      "warning",
      "error",
    ];
    const wasSelected = (key) =>
      key === selected || (key === "uncompleted" && selected === "all");
    filterKeys.forEach(
      (key) => (newState[key] = wasSelected(key) ? true : false)
    );
    this.updateGridData(newState);
  };

  handleRangeChange = (range: RangeEventArgs): void => {
    const { startDate, endDate } = range;
    const refetchVars = {
      completedStartDate: startDate,
      completedEndDate: endDate,
    };
    this.setState(
      {
        startDate,
        endDate,
        search_is_set: false,
        selected_id: 0,
        detail: null,
        detail_open: false,
      },
      async () => {
        await this.props.refetchLeaseReturns(refetchVars);
        setTimeout(() => this.grid.selectRow(0), 500);
      }
    );
  };

  updateGridData = async (newState) => {
    const { completed } = newState;
    let refetchVars = {
      completed: false,
      completedStartDate: null,
      completedEndDate: null,
    };
    if (completed)
      refetchVars = {
        completed: true,
        completedStartDate: this.oneMonth,
        completedEndDate: this.today,
      };

    this.setState(
      { change_made: new Date().getTime(), ...newState },
      async () => {
        this.filterIsSet = true;
        await this.props.refetchLeaseReturns(refetchVars);
        setTimeout(() => this.grid.selectRow(0), 150);
      }
    );
  };

  getButtonGroupTemplate = () => {
    return (
      <div className="e-btn-group toolbar-buttongroup lease-returns">
        <ButtonComponent
          id="uncompleted"
          className={
            this.state.uncompleted
              ? "toolbar-button-selected"
              : "toolbar-button"
          }
          content="Active"
          onClick={this.selectFilter.bind(this)}
        />
        <ButtonComponent
          id="completed"
          className={
            this.state.completed ? "toolbar-button-selected" : "toolbar-button"
          }
          content="Completed"
          onClick={this.selectFilter.bind(this)}
        />
        <ButtonComponent
          id="picked_up"
          className={
            this.state.picked_up ? "toolbar-button-selected" : "toolbar-button"
          }
          content="Picked-Up"
          onClick={this.selectFilter.bind(this)}
        />
        <ButtonComponent
          id="warning"
          style={{ backgroundColor: "#fcb77e" }}
          className={
            this.state.warning ? "toolbar-button-selected" : "toolbar-button"
          }
          content="Warning"
          onClick={this.selectFilter.bind(this)}
        />
        <ButtonComponent
          id="error"
          style={{ backgroundColor: "#fc8d7e" }}
          className={
            this.state.error ? "toolbar-button-selected" : "toolbar-button"
          }
          content="Error"
          onClick={this.selectFilter.bind(this)}
        />
      </div>
    );
  };

  getCurrentRange() {
    const oneDay: number = 24 * 60 * 60 * 1000;
    const firstDate: any = new Date(this.state.startDate);
    const secondDate: any = new Date(this.state.endDate);
    const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    return `Past ${diffDays} Days`;
  }

  getSelectGroupTemplate = () => {
    const {
      completed,
      warning,
      error,
      startDate,
      endDate,
      show_stats,
    } = this.state;
    const { store_settings } = this.props;
    const ageColors: IAgeColors =
      store_settings &&
      Features.LeaseReturn.getLeaseAgeColor(0, store_settings);

    const value = completed
      ? "completed"
      : error
      ? "error"
      : warning
      ? "warning"
      : "all";
    const options = [
      {
        key: "all",
        text: "Active - All",
        value: "all",
      },
      {
        key: "warning",
        text: <span style={{ color: "#de9c38" }}>Active - Warning</span>,
        value: "warning",
      },
      {
        key: "error",
        text: <span style={{ color: "#de5742" }}>Active - Critical</span>,
        value: "error",
      },
      {
        key: "completed",
        text: (
          <span style={{ color: ageColors.barColor, whiteSpace: "nowrap" }}>
            Completed ({this.getCurrentRange()})
          </span>
        ),
        value: "completed",
      },
    ];

    return (
      <div
        className={`e-btn-group toolbar-buttongroup lease-returns ${
          show_stats ? "stats-open" : ""
        }`}
      >
        {/* <Button content={`${show_stats ? "Hide" : "Show"} Stats`} onClick={() => this.setState({ show_stats: !show_stats })} /> */}
        <Dropdown
          id={`selectStatus`}
          className={`select`}
          value={value}
          fluid
          selection
          compact={true}
          options={options}
          onChange={this.selectFilter.bind(this)}
        />
        {completed && (
          <div className="date-range-field">
            <span id="createdLabel">Created:</span>
            <DateRangePickerComponent
              id="datepickerStatus"
              placeholder="Select a range"
              max={this.today}
              startDate={startDate}
              endDate={endDate}
              allowEdit={true}
              strictMode={true}
              showClearButton={false}
              change={this.handleRangeChange}
            >
              <PresetsDirective>
                <PresetDirective
                  label="Today"
                  start={this.today}
                  end={this.today}
                ></PresetDirective>
                <PresetDirective
                  label="Yesterday"
                  start={this.yesterday}
                  end={this.today}
                ></PresetDirective>
                <PresetDirective
                  label="One Week"
                  start={this.oneWeek}
                  end={this.today}
                ></PresetDirective>
                <PresetDirective
                  label="One Month"
                  start={this.oneMonth}
                  end={this.today}
                ></PresetDirective>
                <PresetDirective
                  label="Three Months"
                  start={this.threeMonths}
                  end={this.today}
                ></PresetDirective>
                <PresetDirective
                  label="One Year"
                  start={this.oneYear}
                  end={this.today}
                ></PresetDirective>
              </PresetsDirective>
            </DateRangePickerComponent>
          </div>
        )}
      </div>
    );
  };

  createGridColumns(data) {
    const defaultProps = {
      textAlign: "left",
      allowFiltering: true,
      allowSorting: true,
    };

    const headerData: any = [
      {
        id: "id",
        headerText: "vehicle",
        textAlign: "left",
        width: "210",
        cellAttrs: {
          template: this.getVehicleTemplate,
          attributes: {
            id: "lightboxworthy",
            class: "lease-return-vehicle-template",
          },
        },
      },
      {
        ...defaultProps,
        id: "age",
        headerText: "Age",
        textAlign: "center",
        width: "30",
        allowFiltering: true,
        allowSorting: true,
        cellAttrs: {
          template: this.getAgeTemplate,
        },
      },
      {
        ...defaultProps,
        id: "model_year",
        headerText: "Year",
        textAlign: "left",
        visible: false,
      },
      {
        ...defaultProps,
        id: "make",
        headerText: "Make",
        textAlign: "left",
      },
      {
        ...defaultProps,
        id: "model",
        headerText: "Model",
        textAlign: "left",
      },
      {
        ...defaultProps,
        id: "customer_name",
        headerText: "Customer",
        textAlign: "left",
      },
      {
        ...defaultProps,
        id: "leasing_company_name",
        headerText: "Lease Company",
        textAlign: "left",
      },
      {
        ...defaultProps,
        id: "status",
        width: "45",
        headerText: "Status",
        textAlign: "left",
      },
      {
        ...defaultProps,
        id: "disposition",
        headerText: "Disposition",
        textAlign: "left",
        width: "50",
      },
      {
        ...defaultProps,
        id: "created_at",
        headerText: "Created",
        textAlign: "center",
        width: "45",
      },
      {
        ...defaultProps,
        id: "pick_up_date",
        headerText: "Picked-Up",
        textAlign: "center",
        width: "50",
      },
      {
        ...defaultProps,
        id: "action",
        headerText: "Action",
        textAlign: "right",
        width: "50",
        allowSorting: false,
        allowFiltering: false,
        cellAttrs: {
          attributes: { class: "action" },
          template: (props) => {
            if (!!props.completed_at) return null;
            const color = this.canDelete ? "red" : "#666";
            return props && props.latitude ? (
              <>
                <Icon
                  id="location"
                  style={{
                    opacity: "1!important",
                    fontSize: "20px",
                    color: "#00aeef",
                  }}
                  name="map marker alternate"
                  link
                  onClick={() =>
                    this.setState({ detail: props, show_lot_location: true })
                  }
                />
                <Icon
                  id="remove"
                  style={{ opacity: "1!important", fontSize: "20px", color }}
                  name="trash alternate"
                  link
                  onClick={() => {
                    if (this.canDelete)
                      this.setState({ confirm_remove: props.detail_id });
                  }}
                />
              </>
            ) : (
              <Icon
                id="remove"
                style={{ opacity: "1!important", fontSize: "20px", color }}
                name="trash alternate"
                link
                onClick={() => {
                  if (this.canDelete)
                    this.setState({ confirm_remove: props.detail_id });
                }}
              />
            );
          },
        },
      },
    ];

    const headerList = this.getColumnHeaders();
    return GridColumnHeaders(data, headerData, headerList);
  }

  getColumnHeaders = () => [
    "id",
    "age",
    "created_at",
    "customer_name",
    "leasing_company_name",
    "disposition",
    "status",
    "pick_up_date",
    "action",
    "model_year",
  ];

  resetPanelDims() {
    // the body height minus the header and tabs etc...
    const height = document.body.scrollHeight - 280;
    this.setPoint = null;
    this.origLeftWidth = null;
    this.origRightWidth = null;
    this.setState({ panel_height: height.toString() + "px" });
  }

  beginResize = (e) => {
    e.currentTarget.style.borderColor = "orange";
    this.initResize(e);
  };

  initResize = (e) => {
    this.body = document.querySelector("body");
    this.leftEl = document.querySelector("#left");
    this.rightEl = document.querySelector("#right");
    this.handle = document.querySelector("#divider");

    this.body.classList.add("no-select");
    window.addEventListener("mousemove", this.resize, false);
    window.addEventListener("mouseup", this.stopResize, false);
    this.setPoint = e.clientX;
    this.origLeftWidth = this.leftEl.offsetWidth;
    this.origRightWidth = this.rightEl.offsetWidth;
  };

  stopResize = (e) => {
    this.body.classList.remove("no-select");
    this.handle.style.borderColor = "grey";
    window.removeEventListener("mousemove", this.resize, false);
    window.removeEventListener("mouseup", this.stopResize, false);
  };

  resize = (e) => {
    const diff = this.setPoint - e.clientX;
    const parentWidth = this.leftEl.parentElement.offsetWidth;
    const rightWidth = ((this.origRightWidth + diff) / parentWidth) * 100;
    this.rightEl.style.width = rightWidth + "%";
    const leftWidth = ((this.origLeftWidth - diff) / parentWidth) * 100;
    this.leftEl.style.width = leftWidth + "%";
  };

  removeLeaseReturn = (leaseReturnId: number) => {
    this.setState({ saving: true }, () => {
      restAPI({
        endpointName: "removeLeaseReturn",
        urlArgs: [this.props.storeId, leaseReturnId],
        data: null,
        callback: (error, result) => {
          if (error) {
            this.sendAlert({
              type: "error",
              message: handleErrorResponse({ error }),
            });
            Raven.captureException(error);
            console.error(error);
          } else {
            this.sendAlert({
              type: "success",
              message: "Lease Return successfully removed.",
            });
            this.props.refetchLeaseReturns();
          }
          this.setState({ saving: false, confirm_remove: null }, () =>
            setTimeout(() => this.grid.selectRow(0), 1000)
          );
        },
      });
    });
  };

  filterDataSet(searchString) {
    const searchFiltered = multiTokenFilter({
      searchString,
      array: this.state.data,
    });

    // Set the filter set to all records for local filter
    const searchData = searchFiltered.map((item) => {
      return { ...item };
    });

    this.setState(
      { searchData, search_is_set: true, detail_open: searchData.length > 0 },
      () => {
        this.grid.searchSettings.key = searchString;
        if (searchData.length > 0)
          setTimeout(() => this.grid.selectRow(0), 500);
      }
    );

    this.filterIsSet = true;
    this.searchKey = searchString;
  }

  thresholdDataSet = () => {
    const { error } = this.state;
    const level = error ? "error" : "warning";
    let thresholdFiltered;
    const warnDays = this.props.store_settings.find(
      (s) => s.name === "LEASE_RETURN_WARNING_THRESHOLD_DAYS"
    ).value;
    const errorDays = this.props.store_settings.find(
      (s) => s.name === "LEASE_RETURN_ERROR_THRESHOLD_DAYS"
    ).value;
    if (level === "warning")
      thresholdFiltered = this.state.data.filter(
        (d) => d.age >= warnDays && d.age < errorDays
      );
    else if (level === "error")
      thresholdFiltered = this.state.data.filter((d) => d.age >= errorDays);
    return thresholdFiltered;
  };

  getPrintableColumns = () => {
    const headers = this.getColumnHeaders();
    return headers
      .filter((h) => !["photo_url", "action", "latitude"].includes(h))
      .map((i, index) => {
        const shortened = [
          {
            ch: "id",
            short: "vin",
          },
        ];
        const hasShortened = shortened.find((s) => s.ch === i);
        const header = hasShortened ? hasShortened.short : i;
        return {
          index,
          field: i,
          headerText: header.split("_").join(" ").toUpperCase(),
        };
      });
  };

  resetFilterRanges = async () => {
    await this.updateGridData({
      searchData: [],
      search_is_set: false,
      warning: false,
      error: false,
      completed: false,
      startDate: this.oneMonth,
    });
    this.filterIsSet = false;
    this.searchKey = "";
    this.grid.getContent().children[0].scrollTop = 0;
  };

  setHeaderColor = ({ age, store_settings, is_completed }) => {
    const ageColors: IAgeColors =
      store_settings &&
      Features.LeaseReturn.getLeaseAgeColor(age, store_settings);
    const header: HTMLElement = document.querySelector(
      "#leaseReturnDetailHeader"
    );
    header.style.backgroundColor = !is_completed
      ? ageColors.headerBGColor
      : "#efefef";
    header.style.color = !is_completed ? ageColors.headerTextColor : "#777";
  };

  onRefetchLeaseReturns = () => {
    this.props.refetchLeaseReturns(null);
    setTimeout(
      () => this.grid.selectRow(this.props.lease_returns.length - 1),
      1200
    );
  };

  getUABModal = () => {
    return (
      <div style={{ textAlign: "right" }}>
        <UniversalActionModalComponent
          headerText="Create Lease Return"
          size="small"
          contentComponent={() => {
            return (
              <AddLeaseReturnValidation
                {...{
                  onRefetchLeaseReturns: this.onRefetchLeaseReturns,
                  setHasResults: (has) =>
                    this.setState({ hasAddLeaseReturnResult: has }),
                  onChangeMade: (change_made) => this.setState({ change_made }),
                  ...this.props,
                }}
              />
            );
          }}
          toggleUABCallback={this.props.toggleUABCallback}
          universalActionCallback={() => {
            this.setState({ hasAddLeaseReturnResult: false }, async () => {
              await this.props.universalActionCallback();
            });
          }}
        />
      </div>
    );
  };

  updateCommentCount = ({ count }) => {
    this.setState({ comment_count: count });
  };

  getNoResult = ({ message, moveLeft = false, complete_count }) => {
    const noresults = {
      icon: (
        <img
          className={moveLeft ? "move-left" : ""}
          src="/images/lease_return.png"
        />
      ),
      text: message,
      action: null,
    };

    return (
      <div className="noresults">
        {this.getUABModal()}
        <div className="icon">{noresults.icon}</div>
        <div className="message">
          {noresults && noresults.text
            ? noresults.text
            : "Sorry, no results were loaded"}{" "}
          Use the Floating Action Button in the upper right to add one.
        </div>
      </div>
    );
  };

  getNoResultsText() {
    const { completed, warning, error } = this.state;
    const status =
      warning || error
        ? ` with ${warning ? "warning" : "critical"} status `
        : " ";
    return `No lease returns${status}were found.${
      completed ? " Try adjusting the date range." : ""
    }`;
  }

  getGridData(data) {
    let grid_data = data;
    const { search_is_set, searchData, warning, error } = this.state;
    if (search_is_set && searchData) grid_data = searchData;
    else if (warning || error) grid_data = this.thresholdDataSet();
    return grid_data;
  }

  render() {
    const {
      comment_count,
      detail_open,
      filter_condition,
      data,
      detail,
      panel_height,
      show_lot_location,
      force,
      all_checklist_item_count,
      uncompleted_checklist_item_count,
      selected_id,
      confirm_remove,
      selected_customer_name,
      filtering,
      change_made,
      show_stats,
    } = this.state;

    const { storeId, store_settings, complete_count } = this.props;
    if (!data || !store_settings || filtering) return <Loading />;

    const inventoryProps: any = {
      onHandleErrorResponse: (error) => handleErrorResponse({ error }),
      onAlert: (alert) => this.sendAlert(alert),
      detail,
      filter_condition,
      storeId: storeId
        ? storeId.toString()
        : localStorage.getItem("selectedStoreId").toString(),
      all_checklist_item_count,
      uncompleted_checklist_item_count,
      onCompleteChecklistItem: (uncompleted) =>
        this.setState({ uncompleted_checklist_item_count: uncompleted }),
      onResetSelectedIndex: () => this.setState({ selected_id: 0 }),
      onChangeMade: (change_made) => this.onChangeMade(change_made),
      onSetHeaderColor: (args) => this.setHeaderColor(args),
      filterIsSet: this.filterIsSet,
      searchKey: this.searchKey,
      onFilterDataSet: (searchKey) => this.filterDataSet(searchKey),
      grid: this.grid,
      can_edit: hasPermission(
        this.props.permissions,
        Permission.EDIT_LEASE_RETURN,
        Session.get("isSuperUser")
      ),
      onUpdateCommentCount: (args) => this.updateCommentCount(args),
      tracking_path: this.props.tracking.getTrackingData().event_subtype,
      ...this.props,
    };

    const badgeProps: LMI.IBadgeProps = {
      badge: {
        side: "right",
        background_color: "orange",
        color: "#fff",
        badge_tab_bar_selector: "#leaseReturnTabs > .menu > .item",
        existing_badge_selector:
          "#leaseReturnTabs > .menu > .item > .tabs-badge",
        id: "comments",
        count: comment_count,
      },
    };

    const tabPanes = this.getTabPanes(inventoryProps);

    if (complete_count === 0 && !this.filterIsSet && data && data.length <= 0) {
      return this.getNoResult({
        message: "There are no active Lease Returns at this time.",
        moveLeft: true,
        complete_count,
      });
    }

    return (
      <>
        <div
          className="grid-container lease-return"
          style={{ height: panel_height }}
          key={force}
        >
          {this.getUABModal()}
          <Confirm
            open={!!confirm_remove}
            size="tiny"
            header="Please Confirm"
            content="Remove this Lease Return? This action cannot be undone."
            cancelButton="Nevermind"
            confirmButton="Yes, Confirm"
            onCancel={() => this.setState({ confirm_remove: null })}
            onConfirm={() => this.removeLeaseReturn(confirm_remove)}
          />
          <MapsDialog
            {...{
              open: show_lot_location,
              title:
                detail &&
                `Lot Location - ${detail.model_year} ${detail.make} ${detail.model}`,
              location:
                detail && detail.latitude && detail.longitude
                  ? {
                      latitude: detail.latitude,
                      longitude: detail.longitude,
                    }
                  : null,
              actions: [],
              onClose: () => this.setState({ show_lot_location: false }),
            }}
          />
          {data ? (
            <>
              <div
                id="left"
                className={`lease-left-column ${
                  detail_open && detail ? "detail" : "full"
                } ${show_stats ? "stat-open" : ""}`}
              >
                {/* {show_stats && <LeaseStatPanel />} */}
                <div className="toolbar-group lease-returns">
                  {this.getSelectGroupTemplate()}
                </div>
                <RenderDataGrid
                  {...({
                    update_key: change_made.toString(),
                    gridLines: "Horizontal",
                    total: data.length,
                    data: this.getGridData(data),
                    columns: this.createGridColumns(data),
                    tools: [
                      GridToolOptions.selection,
                      GridToolOptions.pdfexport,
                      GridToolOptions.excelexport,
                      GridToolOptions.sorting,
                      GridToolOptions.filter,
                      GridToolOptions.search,
                    ],
                    pdfExportOptions: {
                      columns: this.getPrintableColumns(),
                    },
                    onReset: () => this.resetFilterRanges(),
                    filterDataSet: (str) => this.filterDataSet(str),
                    groupSettings: this.groupSettings,
                    selectedRowIndex: selected_id,
                    textwrap: true,
                    onSelect: (data) => this.handleSelectRow(data),
                    setGrid: (grid) => this.setGrid(grid),
                    noresults: {
                      icon: "truck",
                      text: this.getNoResultsText(),
                    },
                  } as DataGridProps)}
                />
              </div>
              {detail_open && detail && (
                <div id="divider" onMouseDown={this.beginResize}></div>
              )}
            </>
          ) : (
            ""
          )}
          {detail_open && detail && (
            <div id="right" className="lease-right-column">
              <Grid className="detail-container">
                <Grid.Row className="detail-row top">
                  <div id="leaseReturnDetailHeader" className="header">
                    {selected_customer_name} - {detail.model_year} {detail.make}{" "}
                    {detail.model}
                  </div>
                </Grid.Row>
                <Grid.Row className="recon detail-row bottom">
                  <Tab
                    id="leaseReturnTabs"
                    className="tabs"
                    panes={tabPanes}
                    key={data.id}
                  />
                  <Badge
                    key={detail.detail_id + comment_count}
                    {...badgeProps}
                  />
                </Grid.Row>
              </Grid>
            </div>
          )}
        </div>
      </>
    );
  }

  onChangeMade(change_made) {
    this.props.refetchLeaseReturns(null);
    this.setState({ change_made });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.lease_returns) {
      const formattedData =
        !prevState.data ||
        nextProps.lease_returns.length !== prevState.data.length
          ? LeaseReturnView.formatGridData(nextProps.lease_returns)
          : prevState.data;
      let selectedId = prevState.selected_id;
      if (prevState.selected_id > formattedData.length - 1) selectedId = 0;
      if (window.location.search) {
        const params = new URLSearchParams(window.location.search);
        selectedId =
          nextProps.lease_returns &&
          nextProps.lease_returns.findIndex(
            (lr) => lr.lease_return_uuid === params.get("id")
          );
      }
      return {
        data: formattedData,
        selected_id: selectedId,
        detail_open: prevState.search_is_set
          ? prevState.searchData.length > 0
          : formattedData.length > 0,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.grid &&
      window.location.search &&
      prevState.selected_id !== this.state.selected_id
    ) {
      setTimeout(() => this.grid.selectRow(this.state.selected_id), 300);
    }
  }

  UNSAFE_componentWillMount() {
    window.addEventListener("resize", () => {
      this.resetPanelDims();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
      this.resetPanelDims();
    });
    this.abortController.abort();
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    toggleUABCallback: state.app.admin.toggleUABCallback,
    permissions: state.app.admin.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    universalActionCallback: () => {
      dispatch(triggerUABCallback(false));
    },
    disableUAB: () => {
      dispatch(enableUABCallback(false));
    },
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

const showUABOn = [
  { viewType: "dealerships", permissions: [Permission.ADD_LEASE_RETURN] },
];
const LeaseReturnViewUAB: any = withUAB(LeaseReturnView, showUABOn);

export const LeaseReturnTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.leaseReturns,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            completed: false,
          },
          fetchPolicy: "no-cache",
        };
      },
      props: ({ data: { error, loading, lease_returns, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          complete_count: lease_returns.complete_count,
          lease_returns: lease_returns.lease_returns,
          refetchLeaseReturns: refetch,
        };
      },
    }
  ),
  graphql<LMI.IOrgSettingsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.settings.store,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            names: [
              "LEASE_RETURN_PROGRESS_METHOD",
              "LEASE_RETURN_ERROR_THRESHOLD_DAYS",
              "LEASE_RETURN_WARNING_THRESHOLD_DAYS",
            ],
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_settings, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          store_settings,
          refetch,
        };
      },
    }
  )
)(LeaseReturnViewUAB) as React.ComponentType<any>;

export default LeaseReturnTabPanel;
