import * as React from "react";
import { restAPI } from "client/utils/rest";
import { Popup } from "semantic-ui-react";

export class WorkTimer extends React.Component<LMI.IWorkTimerProps, LMI.IWorkTimerState> {
	workInterval: any;

	constructor(props: LMI.IWorkTimerProps) {
		super(props);

		this.state = {
			loading: false,
			work_elapsed_time: null
		};
	}

	componentDidMount() {
		if (this.props.id) {
			this.setState({ loading: true });
			this.getWorkTimer(this.props.id);
		}
	}

	componentWillUnmount(): void {
		clearInterval(this.workInterval);
	}

	getWorkTimer(vehicleId) {
		const { store_id, current_phase, setUpdateKey, onHandleErrorResponse, onAlert } = this.props;

		restAPI({
			endpointName: "getWorkTimer",
			urlArgs: [store_id, vehicleId, current_phase],
			data: null,
			callback: (error, result) => {
				let alert;
				const now = new Date();

				if (!error) {
					if (result.data.start_time) {
						setUpdateKey(!result.data.stop_time ? "started" : "stopped");

						const elapsing = +result.data.total_elapsed_ms + +now.getTime() - +result.data.start_time;
						const existing = +result.data.total_elapsed_ms;
						const isRunning = !result.data.stop_time;

						this.setState({
							work_elapsed_time: isRunning ? elapsing : existing,
							loading: false
						});
					} else {
						this.setState({
							work_elapsed_time: 0,
							loading: false
						});
						setUpdateKey("stopped");
					}
				} else {
					setUpdateKey("stopped");

					alert = { type: "danger", message: onHandleErrorResponse(error) };
					onAlert(alert);
					console.error(onHandleErrorResponse(error));
				}
			}
		});

		const interval = 1000;
		this.workInterval = setInterval(() => {
			const { update_key } = this.props;
			if (update_key === "started") {
				this.setState(prevState => ({ work_elapsed_time: +prevState.work_elapsed_time + +interval }));
			}
		}, interval);
	}

	calculateElapsedTime(elapsedTime) {
		let ms = elapsedTime;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = Math.floor(ms / 24);
		return {
			days: days.toString().padStart(2, "0"),
			hours: hours.toString().padStart(2, "0"),
			minutes: minutes.toString().padStart(2, "0"),
			seconds: seconds.toString().padStart(2, "0")
		};
	}

	render() {
		const { work_elapsed_time } = this.state;
		const { update_key } = this.props;

		if (work_elapsed_time === 0) {
			return <span className="no-time">00:00:00:00</span>;
		} else {
			const { days, hours, minutes, seconds } = this.calculateElapsedTime(work_elapsed_time);
			return (
				<Popup
					trigger={
						<span id="workTimeBlock">
							{days}:{hours}:{minutes}:<span className={update_key === "started" ? "darker" : ""}>{seconds}</span>
						</span>
					}
					content={
						<div>
							<div>
								<strong>Production Time Spent:</strong>
							</div>
							{`${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`}
						</div>
					}
					position="bottom center"
					size="mini"
					inverted
				/>
			);
		}
	}
}
