import * as React from "react";
import { formatCurrency } from "client/utils/functions";
import { InventoryType } from "loopmein-shared";

//componentry
import { Grid } from "semantic-ui-react";
import { AgeDetail, PhotoDetail, WorkflowTools } from "./components";
import "./Detail.css";

interface InventoryDetailColumnState {
	photoColumnWidth: any;
	specColumnWidth: any;
}
export class InventoryDetailSpecs extends React.Component<LMI.InventoryDetailProps, InventoryDetailColumnState> {
	container = "InventorySpec";
	sizeManager: any;

	constructor(props: LMI.InventoryDetailProps) {
		super(props);
		this.state = {
			photoColumnWidth: 4,
			specColumnWidth: 12
		};
	}

	componentDidMount() {
		// some new school resize magic
		// @ts-ignore
		const resize_ob = new ResizeObserver(entries => {
			const rect = entries[0].contentRect;
			const width = rect.width;
			const smaller = width < 900;
			this.setState({
				photoColumnWidth: smaller ? 5 : 4,
				specColumnWidth: smaller ? 11 : 12
			});
		});
		const spec = document.querySelector(this.container);
		this.sizeManager = spec ? resize_ob.observe(spec) : null;
	}

	componentWillUnmount() {
		if (this.sizeManager) this.sizeManager.unobserve(document.querySelector(this.container));
	}

	render() {
		const { detail } = this.props;
		const { photoColumnWidth, specColumnWidth } = this.state;
		return (
			<div id={this.container} className="inventory-detail">
				<Grid>
					<Grid.Column id="photoColumn" width={photoColumnWidth}>
						<PhotoDetail {...this.props} />
					</Grid.Column>
					<Grid.Column id="specColumn" width={specColumnWidth}>
						<div className="price">
							{formatCurrency(detail.selling_price, 0)}
							<span className={`inventory-type-marker inline name ${detail.inventory_type_id === InventoryType.Wholesale ? "wholesale" : ""}`}>
								{detail.inventory_type.name}
							</span>
						</div>
						<AgeDetail {...this.props} />
						<WorkflowTools {...this.props} />
					</Grid.Column>
				</Grid>
			</div>
		);
	}
}

export default InventoryDetailSpecs;
