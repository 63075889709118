import { restAPI } from "../../../../../../utils/rest";
import { Permission } from "loopmein-shared";
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { addAlert } from "api/redux/actions";
import {
  Divider,
  Grid,
  Header,
  Image,
  Loader,
  Popup,
  Radio,
} from "semantic-ui-react";
import { RelativeDatePipe } from "../../../../../../utils/functions";
import { hasPermission } from "../../../../../../utils/userAccess";
import { Timer } from "./Timer";
import { Session } from "client/utils/session";

import "./SaniDetailPanel.css";

export class SaniDetailPanelView extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      detail_id: props.detail ? props.detail.id : null,
      toggle_items: props.detail
        ? props.detail.details.map((d) => !!d.completed_at)
        : [],
      details: props.detail ? props.detail.details : [],
      completed: false,
      rest_request_id: null,
    };
  }

  static togglesHaveChanged = (current, next) => {
    if (current.length !== next.length) return true;
    for (const key in current) {
      if (current[key].completed_at !== next[key].completed_at) {
        return true;
      }
    }
    return false;
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const changed = SaniDetailPanel.togglesHaveChanged(
      prevState.details,
      nextProps.detail.details
    );
    if (
      (nextProps.detail && nextProps.detail.id !== prevState.detail_id) ||
      changed
    ) {
      return {
        details: nextProps.detail.details,
        detail_id: nextProps.detail.id,
        toggle_items: nextProps.detail.details.map((d) => !!d.completed_at),
        completed:
          nextProps.detail.details.filter((c) => !!c.completed_at).length ===
          nextProps.detail.details.length,
      };
    } else if (!changed && prevState.toggle_items.every((t) => t)) {
      return { completed: true };
    }
    return null;
  }

  sendAlert({ type, message }) {
    this.props.addAlert({
      type,
      message,
      timeout: 3000,
    });
    if (type === "danger") {
      console.log(`Error: ${message}`);
    }
  }

  configureDetails = ({ prevState, index, value, detail, reverse = false }) => {
    const checker = [...prevState.toggle_items];
    checker[index] = reverse ? !value : value;
    const completed = checker.filter((c) => c).length === detail.details.length;
    const details = [...prevState.details];
    if (checker[index]) {
      details[index] = {
        ...details[index],
        completed_at: new Date().getTime(),
        completed_by_emp: { user: { full_name: Session.get("fullName") } },
      };
    } else {
      details[index] = {
        ...details[index],
        completed_at: false,
        completed_by_emp: { user: { full_name: null } },
      };
    }
    return { details, toggle_items: checker, completed };
  };

  handleItemToggle = async (value, index, item) => {
    const { detail } = this.props;
    this.setState(
      (prevState) => {
        return {
          ...this.configureDetails({
            prevState,
            index,
            value,
            detail,
            reverse: false,
          }),
          toggle_loading: index,
        };
      },
      () => {
        restAPI({
          endpointName: "updateSanitizationItem",
          urlArgs: [this.props.store_id, item.id],
          data: { completed: value },
          callback: (error, result) => {
            this.props.onRefetch();
            if (error) {
              // Alert error and reverse the toggle
              this.sendAlert({
                type: "error",
                message: `Toggle update failed due to an unknown error.`,
              });
              this.setState((prevState) => {
                return this.configureDetails({
                  prevState,
                  index,
                  value,
                  detail,
                  reverse: true,
                });
              });
            } else {
              setTimeout(() => this.setState({ toggle_loading: null }), 1000);
            }
          },
        });
      }
    );
  };

  handleCompleteRequest = (requestId) => {
    restAPI({
      endpointName: "completeSanitizationRequest",
      urlArgs: [this.props.store_id, requestId],
      data: { completed: true },
      callback: (error, result) => {},
    });
  };

  render() {
    const { detail, loading, store_settings } = this.props;
    const { toggle_items, details, completed, toggle_loading } = this.state;
    const canEditSanitization = hasPermission(
      this.props.permissions,
      Permission.EDIT_SANITIZATION,
      false
    );
    const canCompleteSanitization = hasPermission(
      this.props.permissions,
      Permission.APPROVE_SANITIZATION,
      false
    );

    if (loading || !detail) return <Loader active />;

    return (
      <div id="SaniDetail" className="sani-detail-panel">
        <div className="sani-vehicle-detail">
          <Image
            src={`${detail.photo}?q=100&w=115`}
            rounded
            className="vehicle-img"
            floated="left"
            loading="lazy"
          />
          <Header as="h3" content={detail.year_make_model} />
          <span className="inv-ids">
            <b>VIN:</b> {detail.vin}
            <br />
            <b>REF#:</b> {detail.reference_number}
          </span>
          <Divider horizontal className="timer-divider">
            <Timer
              settings={store_settings}
              is_completed={completed}
              age={detail.created_at_raw}
              key={detail.created_at_raw}
            />
          </Divider>
        </div>
        <div className="sani-checklist">
          <Grid className="details">
            {details.map((item, index) => {
              return (
                <Grid.Row
                  columns={2}
                  key={item.id + item.completed_at}
                  className="item"
                >
                  {toggle_loading === index && (
                    <Loader className="toggle-loader" active size="small" />
                  )}
                  <Grid.Column width={14}>
                    <div>
                      <Popup
                        trigger={<b>{item.name}</b>}
                        content={
                          item.description ? item.description : item.name
                        }
                        size="tiny"
                        inverted
                      />
                    </div>
                    {item.completed_at && (
                      <div className="who-when">
                        by{" "}
                        {item.completed_by_emp &&
                          `${
                            item.completed_by_emp.user.full_name
                          } ${RelativeDatePipe(
                            item.completed_at,
                            false,
                            true
                          )}`}
                      </div>
                    )}
                  </Grid.Column>
                  <Grid.Column width={2} textAlign="right">
                    <Radio
                      toggle
                      disabled={!canEditSanitization}
                      checked={toggle_items[index]}
                      onClick={(e, data) => {
                        return (
                          canEditSanitization &&
                          this.handleItemToggle(data.checked, index, item)
                        );
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              );
            })}
          </Grid>
        </div>
        <div className="footer">
          <button
            id={`complete-btn`}
            className={`${
              !completed || !canCompleteSanitization ? "disabled" : ""
            }`}
            disabled={!completed || !canCompleteSanitization}
            onClick={() => {
              return (
                canCompleteSanitization && this.handleCompleteRequest(detail.id)
              );
            }}
          >
            COMPLETE
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const SaniDetailPanel = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(SaniDetailPanelView);
export default SaniDetailPanel;
