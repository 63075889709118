import * as React from "react";

import { Link } from "react-router-dom";
import { Image } from "semantic-ui-react";

type menuSubItemProps = {
	label: string;
	path: string;
	isNew: boolean;
	mouseEntered?: () => void;
	itemClicked?: () => void;
};

export function MenuSubItem({ path, label, isNew, itemClicked }: menuSubItemProps) {
	return (
		<li className="route">
			<Link to={path} onClick={() => itemClicked()}>
				{isNew && <Image src="/images/new-icon.svg" className="new-badge" />}
				{label}
			</Link>
		</li>
	);
}
