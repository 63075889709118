import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import moment from "moment";
// components
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  SelectionSettingsModel,
} from "@syncfusion/ej2-react-grids";
// node_modules
import { gqlQueries } from "gql-imports";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Loader, Message, Header } from "semantic-ui-react";

export class AccessoriesGridComponentView extends React.Component<
  LMI.IInventoryAccessoriesTabProps,
  LMI.IInventoryAccessoriesTabState
> {
  grid: GridComponent;
  cellSelectionSettings: SelectionSettingsModel = {
    cellSelectionMode: "Box",
    type: "Single",
    mode: "Both",
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRowIndex: null,
      alert: null,
    };
  }

  format(accessories: any): LMI.IInventoryAccessoryDetailGQL[] {
    return accessories.map((item) => {
      return {
        scheduled: moment(item.created_at).isValid()
          ? moment(item.created_at).format("L")
          : "",
        completed: moment(item.received_at).isValid()
          ? moment(item.received_at).format("L")
          : "",
        item: item.other_name ? item.other_name : item.accessory.name,
        assigned_to: item.responsible_employee
          ? item.responsible_employee.user.full_name
          : null,
        received_by: item.received_by_user
          ? item.received_by_user.full_name
          : null,
      };
    });
  }

  cellSelection = (args: any) => {
    args.cancel = true;
  };

  getWrapSettings: any = () => {
    return { wrapMode: "Content" };
  };

  render() {
    const { inventory_accessories } = this.props;
    const readyAccessories = inventory_accessories
      ? this.format(inventory_accessories)
      : null;

    if (!readyAccessories) {
      return <Loader className={`loader active`} size="small" />;
    }

    return (
      <>
        {readyAccessories.length == 0 ? (
          <Message>
            <Header color="grey">
              There are currently no accessories for this vehicle.
            </Header>
          </Message>
        ) : (
          <GridComponent
            className="grid-component"
            enableHover={false}
            allowSelection={false}
            allowSorting={true}
            selectionSettings={this.cellSelectionSettings}
            cellSelecting={this.cellSelection}
            dataSource={readyAccessories}
            allowGrouping={false}
            allowPaging={false}
            allowFiltering={false}
            allowTextWrap={true}
            textWrapSettings={this.getWrapSettings}
            ref={(g) => (this.grid = g)}
            height="100%"
            gridLines="Both"
          >
            <ColumnsDirective>
              <ColumnDirective
                field="item"
                headerText="Item"
                textAlign="Left"
              />
              <ColumnDirective
                field="completed"
                headerText="Completed"
                textAlign="Left"
              />
              <ColumnDirective
                field="assigned_to"
                headerText="Assigned To"
                textAlign="Left"
              />
              <ColumnDirective
                field="received_by"
                headerText="Received By"
                textAlign="Left"
              />
            </ColumnsDirective>
          </GridComponent>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    permissions: state.app.admin.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const InventoryAccessories = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.inventoryAccessories,
    {
      options: (props: any) => {
        return {
          variables: {
            inventory_item_id: props.inventory_item_id,
          },
          fetchPolicy: "network-only",
          notifyOnNetworkStatusChange: true,
        };
      },
      props: ({
        data: { error, loading, vehicle_accessories, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          inventory_accessories: vehicle_accessories,
          refetch,
        };
      },
    }
  )
)(AccessoriesGridComponentView) as React.ComponentType<any>;

export default InventoryAccessories;
