// node_modules
import * as React from "react";
// components
import { Header, Modal } from "semantic-ui-react";
import "./UniversalActionModalComponent.css";

export class UniversalActionModalComponent extends React.Component<LMI.IUAMCProps, LMI.IUAMCState> {
	constructor(props) {
		super(props);

		this.state = {
			showMessage: false,
			message: ""
		};
	}

	render() {
		const props = this.props;
		let classes = "";
		let isScrolling = true;
		if (props.classes != null) {
			classes = props.classes;
		}
		if (props.scrolling != null) {
			isScrolling = props.scrolling;
		}
		return (
			<Modal
				className={classes}
				centered={false}
				open={props.toggleUABCallback}
				closeIcon="close"
				size={props.size}
				onClose={(evt, data) => {
					props.universalActionCallback();
				}}
			>
				<Header as="h1" content={props.headerText} />
				{this.state.showMessage ? <Header as="h2">{this.state.message}</Header> : ""}
				<Modal.Content scrolling={isScrolling}>
					{props.contentComponent({
						universalActionCallback: props.universalActionCallback
					})}
				</Modal.Content>
			</Modal>
		);
	}
}

export default UniversalActionModalComponent;
