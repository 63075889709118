import { graphql } from "@apollo/react-hoc";
import { Form } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Button, Dropdown } from "semantic-ui-react";
import { restAPI } from "../../../../../../utils/rest";

export class ReconLevelAssignmentView extends React.Component<LMI.IReconLevelProps, LMI.IReconLevelState> {
	constructor(props: LMI.IReconLevelProps) {
		super(props);

		this.state = {
			value:
				props.dealer_phasers && props.dealer_phasers.recon_assignments.filter(r => r.phase_id === props.phase_id)[0]
					? props.dealer_phasers.recon_assignments.filter(r => r.phase_id === props.phase_id)[0].recon_levels.map(rl => rl.id)
					: null
		};
	}

	handleChange = (event, { value }) => {
		this.setState({ value });
	};

	handleSubmit = () => {
		const { value } = this.state;
		const { phase_id, storeId } = this.props;

		const data = [{ id: phase_id, recon_levels: value }];

		restAPI({
			endpointName: "modify_store_phases",
			urlArgs: [storeId],
			data,
			callback: error => {
				let alert;
				if (error) {
					alert = { type: "danger", message: this.props.onHandleErrorResponse(error) };
					this.props.onSendAlert(alert);
				}
				this.props.onRefetch();
				this.props.onClose();
			}
		});
	};

	render() {
		const { value } = this.state;
		const { recon_levels } = this.props;

		const options =
			recon_levels &&
			recon_levels.map(r => {
				return { key: r.id, text: r.name, value: r.id };
			});

		return [
			<Form>
				<div>Select the Workflow to assign to this Stage.</div>
				<div id="phaseBody" className="recon-form">
					<Dropdown
						className="select"
						value={value}
						placeholder="Workflow"
						loading={!recon_levels}
						fluid
						multiple
						search
						selection
						options={options}
						onChange={this.handleChange}
					/>
				</div>
			</Form>,
			<div className="cta-footer">
				<Button className="submit" id="save" positive content="Save" onClick={this.handleSubmit} />
			</div>
		];
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const ReconLevelAssignment = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.reconLevels, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_recon_levels, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				recon_levels: store_recon_levels.recon_levels,
				refetch
			};
		}
	})
)(ReconLevelAssignmentView) as React.ComponentType<any>;

export default ReconLevelAssignment;
