export enum SubTabContainers {
	clients = "ClientsTabPanel",
	settings = "SettingsTabPanel",
	reports = "ReportsTabPanel",
	vendors = "VendorsTabPanel",
	sanitization = "SaniTabsPanel",
	userSettings = "UserSettings"
}

export default SubTabContainers;
