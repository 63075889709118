import * as Raven from "raven-js";
import * as React from "react";
import { Button, Dropdown, Header, Icon, Message } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { restAPI } from "../../../../../utils/rest";
import "./ProxyUserComponent.css";

export class ProxyUserComponent extends React.Component<
  LMI.ProxyAsUserProps,
  LMI.ProxyAsUserState
> {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: localStorage.getItem("su_token") ? true : false,
      droptext: localStorage.getItem("su_token")
        ? "as: " + this.props.userName
        : "Proxy User",
      currentProxy: localStorage.getItem("su_token")
        ? this.props.userName
        : null,
      errorMessage: null,
      loading: false,
    };
  }

  render() {
    const props = this.props;

    return props.superUser || localStorage.getItem("su_token") ? (
      <Dropdown
        direction="right"
        className="icon proxy-user-component leftDropdown"
        text={this.state.droptext}
        icon={this.state.loggedIn ? { name: "spy", color: "blue" } : "spy"}
        floating
        labeled
        button
      >
        {!this.state.loggedIn ? (
          <Dropdown.Menu>
            <Dropdown.Item>
              {this.state.errorMessage ? (
                <Message
                  error
                  header="Proxy Error"
                  content={this.state.errorMessage}
                />
              ) : (
                ""
              )}
              <Form noValidate onSubmit={this.loginUser.bind(this)}>
                <Form.Field>
                  <label>Email</label>
                  <Input
                    name="email"
                    type="email"
                    onClick={(e) => e.stopPropagation()}
                  />
                </Form.Field>
                <Button
                  positive
                  fluid
                  content="Let's GO"
                  type="submit"
                  onClick={(e) => e.stopPropagation()}
                />
              </Form>
            </Dropdown.Item>
          </Dropdown.Menu>
        ) : (
          <Dropdown.Menu>
            <Dropdown.Header>
              <small>Logged in as:</small>
              <Header as="h4" className="user-name">
                <Icon name="user circle outline" /> {this.state.currentProxy}
              </Header>
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item onClick={this.logoutUser.bind(this)}>
              End Session
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    ) : (
      <span />
    );
  }
  loginUser(data: any) {
    // Clear previous settings except the token for the current Admin
    const token = localStorage.getItem("token");
    const superUser = {
      id: localStorage.getItem("userId"),
      user_full_name: localStorage.getItem("user_full_name"),
    };
    localStorage.clear();
    localStorage.setItem("token", token);

    restAPI({
      endpointName: "suProxyUserByEmail",
      urlArgs: null,
      data,
      callback: (error, result) => {
        if (error) {
          this.setState({ errorMessage: error.reason.response.data.message });
        } else if (result.data.is_super_user) {
          this.setState({ errorMessage: "This user is a super user" });
        } else {
          // console.log("Login:", result);
          this.setState({ errorMessage: null });

          if (localStorage.getItem("su_token") === null) {
            localStorage.setItem("su_token", localStorage.getItem("token"));
          }
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("isu", result.data.is_super_user);
          localStorage.setItem("proxied_as", JSON.stringify(superUser));

          try {
            this.props.history.push("/");
            window.location.reload();
          } catch (e) {
            console.log("Login error: ", e);
            Raven.captureException(e);
          }
        }
      },
    });
  }
  logoutUser(data: any) {
    const suToken = localStorage.getItem("su_token");
    // Clear leftover storage from previous proxy user
    localStorage.clear();
    localStorage.setItem("token", suToken);
    // put super user token back and set is Super User to true before reloading
    localStorage.setItem("isu", "true");
    this.props.history.push("/end-proxy");
    window.location.replace("/");
  }
}

export default ProxyUserComponent;
