// node_modules
import * as React from "react";
import track from "react-tracking";
import { AnalyticsEventType, InventoryConditionString, InventoryType } from "loopmein-shared";
import { restAPI } from "client/utils/rest";
import { useGlobals } from "api/redux/hooks";
import { RelativeDatePipe } from "client/utils/functions";
import { RegularExpressions } from "loopmein-shared/bin/globals/regex";
import { Validator } from "loopmein-shared/bin/globals/validation";

import { Form, Select, Input } from "formsy-semantic-ui-react";
import { ConfirmDialog } from "client/components/ConfirmDialog";
import { confirmDefault } from "client/components/ConfirmDialog/ConfirmDialog";
import { Button, Container, Dimmer, Divider, Grid, Header, Image, Label, Loader, Message } from "semantic-ui-react";
import { useWithRouter } from "../Routes/Routes";

@track(
	{
		event_type: AnalyticsEventType.NAVIGATION,
		event_subtype: "inventory.add_inventory"
	},
	{ dispatchOnMount: true }
)
export class AddInventoryView extends React.Component<LMI.IGlobalAddInventoryProps, LMI.IAddInventoryState> {
	vinput: HTMLInputElement;

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			success: null,
			error: null,
			inventoryItem: null,
			vinputValue: null,
			stinputValue: null,
			confirm: null,
			canSubmit: false
		};
	}

	handleErrorResponse(error: any) {
		if (error.reason && error.reason.response && error.reason.response.data) {
			return error.reason.response.data.message;
		} else {
			return "There was an error.";
		}
	}

	componentDidMount() {
		setTimeout(() => {
			this.vinput = document.getElementById("vinput") as HTMLInputElement;
			this.vinput?.focus();
		}, 500);
	}

	checkVinSubmit = async (formData): Promise<any> => {
		const vinLegit = await Validator.vinCheckDigit(formData.vin);
		if (vinLegit.isValid) {
			this.submitForm(formData);
		} else {
			this.setState({
				confirm: {
					open: true,
					title: `VIN Check Failed`,
					text: `${vinLegit.message}`,
					cancelText: "Try Again",
					confirmText: "Confirm",
					success: () => {
						this.submitForm(formData);
					},
					failure: () => {
						this.setState({ confirm: confirmDefault });
						this.vinput.focus();
					}
				}
			});
		}
	};

	disableSubmit = () => {
		this.setState({ canSubmit: false });
	};

	enableSubmit = () => {
		this.setState({ canSubmit: true });
	};

	private submitForm = async formData => {
		this.setState({ loading: true });
		restAPI({
			endpointName: "explode_vin_add_inventory",
			urlArgs: [this.props.storeId],
			data: formData,
			callback: (error, result) => {
				if (error) {
					this.setState({ error: error.message, loading: false });
					return;
				}
				const inventoryItem: LMI.IInventoryGQL = result.data.inventory_item;
				if (!inventoryItem.photo_url) {
					inventoryItem.photo_url = `https://loopmein-dev.imgix.net/${
						inventoryItem.stored_image_filename ? `inventory/${inventoryItem.stored_image_filename}` : "default_vehicle_image.png"
					}`;
				}
				if (this.props.setUpdateKey) this.props.setUpdateKey(new Date());
				const success = result.data.message;
				this.setState({ inventoryItem, success, loading: false });
			}
		});
	};

	getOptions(options: Object, useNumber = true, filterValues = null) {
		return Object.keys(options)
			.filter(key => {
				const isNumber = isNaN(Number(options[key]));
				return (useNumber ? !isNumber : isNumber) && (filterValues ? !filterValues.includes(options[key]) : true);
			})
			.map(text => ({ text, value: options[text] }));
	}

	render() {
		const errorLabel = <Label color="red" pointing="above" />;
		const { loading, success, error, inventoryItem, vinputValue, stinputValue, confirm } = this.state;

		if (loading) {
			return (
				<Dimmer active>
					<Loader />
				</Dimmer>
			);
		}

		return (
			<div id="AddInventory">
				<Header as="h4" content={success ? success : "Enter the new inventory VIN Number below"} color={success ? "green" : "grey"} />
				{!success && !error ? (
					<Form id="AddInventoryForm" ref="form" onValid={this.enableSubmit} onInvalid={this.disableSubmit} onValidSubmit={this.checkVinSubmit.bind(this)}>
						<ConfirmDialog extendClass="lower" {...confirm} />
						<Grid columns="equal">
							<Grid.Column>
								<Form.Field>
									<label>
										VIN <span className="red-text">*</span>
									</label>
									<Input
										id="vinput"
										name="vin"
										value={vinputValue}
										validations={{
											matchRegexp: RegularExpressions.VIN
										}}
										validationErrors={{
											matchRegexp: "Not a Valid VIN",
											isDefaultRequiredValue: "VIN is required"
										}}
										errorLabel={errorLabel}
										onChange={(e, data) =>
											this.setState({
												vinputValue: data.value.toUpperCase()
											})
										}
										required
									/>
								</Form.Field>
							</Grid.Column>
							<Grid.Column>
								<Form.Field>
									<label>
										Stock # <span className="red-text">*</span>
									</label>
									<Input
										id="stinput"
										name="stock_number"
										value={stinputValue}
										validations={{
											matchRegexp: RegularExpressions.STOCK_NUMBER,
											minLength: 3,
											maxLength: 25
										}}
										validationErrors={{
											matchRegexp: "Stock Number must be alphanumeric",
											minLength: "Must be at least 3 digits",
											maxLength: "Can only be 25 digits",
											isDefaultRequiredValue: "Stock # is required"
										}}
										errorLabel={errorLabel}
										onChange={(e, data) =>
											this.setState({
												stinputValue: data.value.toUpperCase()
											})
										}
										required
									/>
								</Form.Field>
							</Grid.Column>
						</Grid>
						<Grid columns="equal">
							<Grid.Column>
								<Form.Field>
									<label>
										Condition <span className="red-text">*</span>
									</label>
									<Select fluid selectOnBlur={false} name="condition" options={this.getOptions(InventoryConditionString, false)} />
								</Form.Field>
							</Grid.Column>
							<Grid.Column>
								<Form.Field>
									<label>
										Sale Type <span className="red-text">*</span>
									</label>
									<Select
										fluid
										name="inventory_type_id"
										required
										selectOnBlur={false}
										options={this.getOptions(InventoryType, true, [InventoryType.Auction])}
									/>
								</Form.Field>
							</Grid.Column>
							<Grid.Column>
								<Form.Field>
									<label>Odometer</label>
									<Input id="milesinput" name="miles" />
								</Form.Field>
							</Grid.Column>
						</Grid>
						<Container textAlign="right">
							<Divider hidden />
							<Button type="submit" positive size="large" disabled={!this.state.canSubmit}>
								Submit
							</Button>
						</Container>
					</Form>
				) : (
					<div id="postSubmit">
						{success ? (
							<div id="new-inventory">
								<Divider />
								<Grid>
									<Grid.Column width={5}>
										<Image src={inventoryItem.photo_url} size="small" alt="No Image Available" loading="lazy" />
									</Grid.Column>
									<Grid.Column width={11}>
										<Header
											as="h4"
											content={`${inventoryItem.year} ${inventoryItem.make} ${inventoryItem.model} ${inventoryItem.trim ? inventoryItem.trim : ""}`}
										/>
										VIN: <strong>{inventoryItem.vin}</strong>
										<br />
										Stock #: <strong>{inventoryItem.stock_number}</strong>
										<br />
										{inventoryItem.created_at && (
											<span>
												Date Added: <strong>{RelativeDatePipe(inventoryItem.created_at)}</strong>
											</span>
										)}
									</Grid.Column>
								</Grid>
							</div>
						) : error ? (
							<Message color="red" content={error} />
						) : (
							<p>There was a problem. Please close and try again.</p>
						)}
						<Divider />
						<Button
							id={success ? "AddMoreInventory" : "TryAgain"}
							content={success ? "Add More Inventory" : "Try Again"}
							color={success ? "green" : "grey"}
							onClick={e => this.reset(e.currentTarget)}
						/>
						{success && <Button content="View Inventory Details" color="blue" onClick={() => this.viewInventory(inventoryItem)} />}
						<Button
							content="Done"
							color="grey"
							onClick={() => {
								if (this.props.isSub && this.props.onClose) this.props.onClose();
								else this.props.toggleAddInventoryDialog(false);
							}}
						/>
					</div>
				)}
			</div>
		);
	}

	viewInventory(item) {
		if (this.props.isSub && this.props.onClose) this.props.onClose();
		else this.props.toggleAddInventoryDialog(false);
		this.props.history.push(`/admin/dealerships/inventory?id=${item.inventory_id}`);
	}

	reset = args => {
		if (args.id === "AddMoreInventory") {
			this.setState({
				vinputValue: null,
				stinputValue: null
			});
		}
		this.setState({
			success: null,
			error: null,
			inventoryItem: null,
			confirm: null
		});
	};
}

export const AddInventoryComponent = (props: LMI.IGlobalAddInventoryProps) => {
	const globals = useGlobals();
	const routes = useWithRouter();
	return <AddInventoryView {...{ ...globals, ...routes, ...props }} />;
};

export default AddInventoryComponent;
