// node_modules
import * as React from "react";
// components
import { Form, Checkbox } from "semantic-ui-react";
import "./StoreGroupComponent.css";

export class StoreGroupComponent extends React.Component<LMI.ISGCProps, LMI.ISGCState> {
	constructor(props) {
		super(props);

		this.state = {
			editing: false
		};
	}

	render() {
		const props = this.props;
		return (
			<Form>
				{props.groups.map(group => {
					const checked = this.findStoreGroup(group.id);
					const orgId = checked ? checked.organization_group_id : -1;
					return (
						<Form.Field key={group.id}>
							<Checkbox
								label={group.name}
								defaultChecked={checked !== undefined}
								disabled={!this.props.canEdit}
								onChange={(evt, data) => {
									this.props.onClick({
										orgGroupId: group.id,
										checked: data.checked,
										groupId: orgId
									});
								}}
							/>
						</Form.Field>
					);
				})}
			</Form>
		);
	}

	findStoreGroup(id: number) {
		return this.props.storeGroups.find(storeGroup => {
			return storeGroup.organization_group_id === id;
		});
	}
}
