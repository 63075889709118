import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "./EmployeeDetailComponent.css";

import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { addAlert } from "api/redux/actions";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { hasPermission } from "client/utils/userAccess";
import { restAPI } from "../../../../../../../utils/rest";
import { Session } from "client/utils/session";
import { formatPhone, handleErrorResponse, handleSuccessResponse, RelativeDatePipe, stringToInt } from "client/utils/functions";
import { confirmDefault, ConfirmDialog } from "client/components/ConfirmDialog";
import { EmployeePersonalInfoTabs, Permission } from "loopmein-shared";

import { EditableFieldDisplay } from "client/pages/admin/components/EditableFieldDisplay";
import { FileUploadComponent } from "client/pages/admin/components/FileUploadComponent";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { Button, Checkbox, Container, Dimmer, Divider, Dropdown, Grid, Header, Icon, Image, Label, List, Loader, Menu } from "semantic-ui-react";
import { ActivityDialog, ActivityList, JobAlertsComponent, RolesPermissionsComponent } from "./components";
import RichTextComponent from "../../../StoreTabPanel/components/RichTextComponent/RichTextComponent";

export class EmployeeDetailComponentView extends React.Component<LMI.IEmpDCProps, LMI.IEmpDCState> {
	constructor(props) {
		super(props);
		this.state = {
			editingImage: false,
			activeTab: 1,
			activeInfoTab: 1,
			activeStore: props.storeId,
			activeEmployee: props.id,
			userInvited: false,
			forcedUserPWReset: false,
			editBio: false,
			savingData: false,
			photo_url: null,
			viewActivityDetail: [],
			openActivityDialog: false,
			confirmDialog: confirmDefault
		};
	}

	static getDerivedStateFromProps(nextProps: LMI.IEmpDCProps, prevState: LMI.IEmpDCState) {
		if (!prevState.activeEmployee) {
			return {
				activeStore: nextProps.storeId,
				activeEmployee: nextProps.id,
				activeInfoTab: 1,
				forcedUserPWReset: false,
				userInvited: false
			};
		} else return null;
	}

	getStatsGrid = () => {
		const { user, updated_at, created_by_user } = this.props.employee_user;
		return (
			<Grid className="stats-grid">
				<Grid.Row columns={4} divided>
					<Grid.Column>
						<Grid.Row className="label">Created</Grid.Row>
						<Grid.Row className="value">{RelativeDatePipe(user.created_at, true, true)}</Grid.Row>
					</Grid.Column>
					<Grid.Column>
						<Grid.Row className="label">Invited</Grid.Row>
						<Grid.Row className="value">{RelativeDatePipe(user.invited_at, true, true)}</Grid.Row>
					</Grid.Column>
					<Grid.Column>
						<Grid.Row className="label">Activated</Grid.Row>
						<Grid.Row className="value">{RelativeDatePipe(user.activated_at, true, true)}</Grid.Row>
					</Grid.Column>
					<Grid.Column>
						<Grid.Row className="label">Last Used</Grid.Row>
						<Grid.Row className="value">{RelativeDatePipe(user.last_used_at, true, true)}</Grid.Row>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row columns={4} divided>
					<Grid.Column>
						<Grid.Row className="label">Last Modified</Grid.Row>
						<Grid.Row className="value">{RelativeDatePipe(updated_at.timestamp, true, true)}</Grid.Row>
					</Grid.Column>
					<Grid.Column>
						<Grid.Row className="label">Created By</Grid.Row>
						<Grid.Row className="value">{created_by_user && created_by_user.full_name}</Grid.Row>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		);
	};

	// only being used to get the "NOTIFICATION_TYPE" setting but opened it up for future emp settings
	getEmpSetting(props: LMI.IEmpDCProps, match: string, defaultValue = "N/A") {
		let setting = defaultValue;
		if (props && props.employee_user) {
			if (props.employee_user.settings && props.employee_user.settings.length > 0) {
				const notifySetting = props.employee_user.settings.find(i => i.name === match);
				setting = notifySetting.value;
			}
		}
		return setting;
	}

	render() {
		const props = this.props;
		const { confirmDialog } = this.state;
		const userImage = {
			mWidth: 200
		};
		if (props.loading || this.state.savingData)
			return (
				<Dimmer active inverted>
					<Loader size="large">Loading</Loader>
				</Dimmer>
			);
		const { user } = this.props.employee_user;
		const canRemoveEmployee = hasPermission(props.permissions, Permission.ADMIN_REMOVE_EMPLOYEE, Session.get("isSuperUser"));
		const canEditEmployee = hasPermission(props.permissions, Permission.ADMIN_EDIT_EMPLOYEE, Session.get("isSuperUser"));
		const canDealerEditUser = hasPermission(props.permissions, Permission.EDIT_DEALERSHIP_USER, Session.get("isSuperUser"));
		const canEditBio = hasPermission(props.permissions, Permission.ADMIN_EDIT_EMPLOYEE, Session.get("isSuperUser"));
		const storeId = localStorage.getItem("selectedStoreId");
		const isCurrentUser = props.employee_user && localStorage.getItem("userId") === props.employee_user.user.id;
		const userInviteStatus: boolean = user && user.invited_at !== null;
		const userIsActive: boolean = user && user.last_login_date !== null && user.last_used_at !== null;
		const isinOrg = store => {
			const selectedOrg = localStorage.getItem("selectedOrgId");
			if (!selectedOrg) {
				return true;
			} else {
				return store.store.organization_id === parseInt(selectedOrg, 10);
			}
		};
		const employeeStores =
			props.employee_user &&
			props.employee_user.user.employees.filter(isinOrg).map((emp, index) => ({
				key: emp.id,
				text: emp.store.name,
				value: emp.store_id
			}));

		const employee = {
			id: this.state.activeEmployee,
			storeId: this.state.activeStore,
			empId: this.state.activeEmployee,
			orgId: this.props.orgId,
			user: props.employee_user,
			requestingUserPermissions: props.permissions,
			onClick: data => console.log(data),
			onRefresh: () => this.props.onRefresh()
		};

		const fileProps = {
			enableCropping: true,
			filesToAccept: "image/png, image/jpeg",
			onSubmit: this.onSubmitFileHandler.bind(this),
			onClose: () => this.setState({ editingImage: false })
		};
		let activeStore = { text: "Default" };
		if (this.state.activeStore) {
			activeStore = employeeStores && employeeStores.length ? employeeStores.find(emp => emp.value === this.state.activeStore) : { text: "Default" };
		}

		return (
			<div className="employee-detail-component">
				<ConfirmDialog {...confirmDialog} />
				{this.state.openActivityDialog ? (
					<ModalComponent
						headerText={`Activity : ${this.props.employee_user.user.full_name}`}
						shouldBeOpen={this.state.openActivityDialog}
						onClose={() => {
							this.setState({ openActivityDialog: false });
						}}
						size="large"
						contentComponent={() => <ActivityDialog {...this.props} />}
					/>
				) : (
					""
				)}
				{this.state.editingImage ? (
					<ModalComponent
						headerText="Upload Image"
						shouldBeOpen={this.state.editingImage}
						onClose={(evt, data) => {
							this.setState({ editingImage: false });
						}}
						className="upload-image-modal"
						size="small"
						contentComponent={() => <FileUploadComponent {...fileProps} />}
					/>
				) : (
					""
				)}
				{props.employee_user && (
					<Container className="employee-record">
						<div className="details-container">
							{!userIsActive && (
								<Button
									color="blue"
									onClick={() => this.onInvite(props.employee_user)}
									labelPosition="left"
									disabled={isCurrentUser}
									size="tiny"
									floated="right"
									icon>
									<Icon name="send" />
									{userInviteStatus ? "Resend User Invite" : "Send User Invite"}
								</Button>
							)}
							<Button
								onClick={() => this.forcePWReset(props.employee_user.user.id, storeId)}
								labelPosition="left"
								disabled={isCurrentUser || this.state.forcedUserPWReset}
								size="tiny"
								floated="right"
								icon>
								<Icon name="repeat" />
								Force Password Reset
							</Button>
							<Header as="h2" content={props.employee_user.user.full_name} className="emp-name" />
							<Divider horizontal>Personal Info</Divider>
							<Grid>
								<Grid.Row>
									<Grid.Column width={4}>
										<div className="profile-picture">
											{props.employee_user.user.photo_url.indexOf("imgix.net") >= 0 ? (
												<Image
													src={props.employee_user.user.photo_url + "?w=" + userImage.mWidth + "&facepad=2&faceindex=1&fit=facearea"}
													className="userimage"
													rounded
													loading="lazy"
												/>
											) : props.employee_user.user.photo_url.indexOf("ui-avatars.com") < 0 ? (
												<Icon name="user circle outline" size="massive" color="grey" />
											) : (
												<Image src={props.employee_user.user.photo_url} className="userimage" rounded loading="lazy" />
											)}
											{isCurrentUser || canDealerEditUser ? (
												<Button
													className="change-image-btn"
													onClick={() => {
														this.setState({ editingImage: true });
													}}>
													change
												</Button>
											) : (
												<span />
											)}
										</div>
									</Grid.Column>
									<Grid.Column width={12}>
										{/* {props.employee_user.user.bio && ( */}
										<Menu tabular className="short-tab">
											<Menu.Item
												active={this.state.activeInfoTab === EmployeePersonalInfoTabs.Contact}
												onClick={() =>
													this.setState({
														activeInfoTab: EmployeePersonalInfoTabs.Contact
													})
												}>
												Contact Info
											</Menu.Item>
											<Menu.Item
												active={this.state.activeInfoTab === EmployeePersonalInfoTabs.Bio}
												onClick={() =>
													this.setState({
														activeInfoTab: EmployeePersonalInfoTabs.Bio
													})
												}>
												Bio
											</Menu.Item>
											<Menu.Item
												active={this.state.activeInfoTab === EmployeePersonalInfoTabs.Activity}
												onClick={() =>
													this.setState({
														activeInfoTab: EmployeePersonalInfoTabs.Activity
													})
												}>
												Activity
											</Menu.Item>
											<Menu.Item
												active={this.state.activeInfoTab === EmployeePersonalInfoTabs.Stats}
												onClick={() =>
													this.setState({
														activeInfoTab: EmployeePersonalInfoTabs.Stats
													})
												}>
												Stats
											</Menu.Item>
										</Menu>
										{/* )} */}
										{this.state.activeInfoTab === EmployeePersonalInfoTabs.Contact ? (
											<List>
												<List.Item className="employee-info employee-first-name">
													<List.Content>
														<EditableFieldDisplay
															title="First Name"
															id="editable-user-first-name-field"
															startingValue={props.employee_user.user.first_name}
															onClick={data => {
																this.updateUser({
																	first_name: data.currentValue
																});
															}}
															canEdit={isCurrentUser || canDealerEditUser}
														/>
													</List.Content>
												</List.Item>
												<List.Item className="employee-info employee-last-name">
													<List.Content>
														<EditableFieldDisplay
															title="Last Name"
															id="editable-user-last-name-field"
															startingValue={props.employee_user.user.last_name}
															onClick={data => {
																this.updateUser({
																	last_name: data.currentValue
																});
															}}
															canEdit={isCurrentUser || canDealerEditUser}
														/>
													</List.Content>
												</List.Item>
												<List.Item className="employee-info employee-email">
													<List.Content>
														<EditableFieldDisplay
															title="Email"
															isEmail={true}
															id="editable-user-email-field"
															startingValue={props.employee_user.user.email}
															onClick={data => {
																this.updateUser({
																	email: data.currentValue
																});
															}}
															canEdit={isCurrentUser || canDealerEditUser}
														/>
													</List.Content>
												</List.Item>
												<List.Item className="employee-info employee-phone">
													<List.Content>
														<EditableFieldDisplay
															title="Mobile Phone"
															id="phone"
															startingValue={formatPhone(props.employee_user.user.phone)}
															masked={true}
															onClick={data => {
																this.updateUser({
																	phone: data.currentValue
																});
															}}
															canEdit={isCurrentUser || canDealerEditUser}
														/>
													</List.Content>
												</List.Item>
												<List.Item className="employee-info employee-phone">
													<List.Content>
														<span className="editable-field-display">
															Notifications:{" "}
															<span className="value">
																<Label content={this.getEmpSetting(props, "NOTIFICATION_TYPE", "NONE").toUpperCase()} />
															</span>
														</span>
													</List.Content>
												</List.Item>
											</List>
										) : this.state.activeInfoTab === EmployeePersonalInfoTabs.Bio ? (
											<div className="employee-info bio">
												{canEditBio && (
													<span className="action-btns">
														<Icon
															link
															name="pencil"
															onClick={() => {
																this.setState({ editBio: true });
															}}
														/>
													</span>
												)}
												<div
													dangerouslySetInnerHTML={{
														__html: props.employee_user.bio && props.employee_user.bio.length ? props.employee_user.bio : "...Add Employee Bio"
													}}
												/>

												{this.state.editBio && (
													<ModalComponent
														headerText="Employee Bio"
														shouldBeOpen={true}
														onClose={() => {
															this.setState({ editBio: false });
														}}
														className="edit"
														contentComponent={() => (
															<RichTextComponent
																{...{
																	value: props.employee_user.bio,
																	placeholder: "...Add Employee Bio",
																	onSave: data => {
																		this.setState({ editBio: false, savingData: false }, () => {
																			this.updateEmployee(props.id, storeId, {
																				bio: data ? data : ""
																			});
																		});
																	},
																	onClose: () => {
																		this.setState({ editBio: false });
																	}
																}}
															/>
														)}
													/>
												)}
											</div>
										) : this.state.activeInfoTab === EmployeePersonalInfoTabs.Stats ? (
											this.getStatsGrid()
										) : this.state.activeInfoTab === EmployeePersonalInfoTabs.Activity ? (
											<ActivityList store_id={props.storeId} employee_id={props.id} onViewAll={() => this.setState({ openActivityDialog: true })} />
										) : (
											"Select a Tab"
										)}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</div>
						<div className="roles-container">
							<Divider horizontal>
								<span className="at">at </span>
								{employeeStores.length > 1 ? (
									<Dropdown
										inline
										options={employeeStores}
										defaultValue={this.state.activeStore}
										onChange={(e, data) => {
											const activeStore = parseInt(data.value.toString(), 10);
											const activeEmployee = data.options.find(o => o.value === data.value).key;
											this.setState({ activeStore, activeEmployee });
										}}
									/>
								) : (
									<strong>{activeStore.text}</strong>
								)}
							</Divider>
							<span className="active-checkbox">
								<Checkbox
									toggle
									label={props.employee_user.is_active ? "Active" : "Not Active"}
									disabled={localStorage.getItem("userId") === props.employee_user.user.id || (!canEditEmployee && !canRemoveEmployee)}
									checked={props.employee_user.user && props.employee_user.is_active}
									onChange={(e, data) => {
										this.setState({
											confirmDialog: {
												open: true,
												title: (props.employee_user.is_active ? "Deactivate" : "Activate") + " Employee?",
												text:
													"Please confirm you would like to " +
													(props.employee_user.is_active
														? "deactivate this employee.  NOTE: Any open tasks assigned to this employee WILL BECOME UNASSIGNED!"
														: "activate this employee."),
												success: () => {
													this.toggleEmployeeActive(storeId, props.id, data.checked);
													this.setState({ confirmDialog: confirmDefault });
												},
												failure: () => this.setState({ confirmDialog: confirmDefault })
											}
										});
									}}
								/>
							</span>
							<Menu tabular>
								<Menu.Item active={this.state.activeTab === 1} onClick={() => this.setState({ activeTab: 1 })}>
									Job Title &amp; Alerts
								</Menu.Item>
								<Menu.Item active={this.state.activeTab === 2} onClick={() => this.setState({ activeTab: 2 })}>
									Access Roles &amp; Permissions
								</Menu.Item>
							</Menu>
							{this.state.activeTab === 1 ? (
								<JobAlertsComponent {...employee} />
							) : this.state.activeTab === 2 ? (
								<RolesPermissionsComponent {...employee} />
							) : (
								"Select a Tab"
							)}
						</div>
					</Container>
				)}
			</div>
		);
	}

	onInvite = user => {
		this.props.onInvite(user);
		this.setState({ userInvited: true });
	};

	toggleEmployeeActive(storeId: string, employeeId: number, isActive: any) {
		if (!this.props.employee_user) return;
		const user = {
			email: this.props.employee_user.user.email,
			phone: this.props.employee_user.user.phone ? this.props.employee_user.user.phone : "",
			first_name: this.props.employee_user.user.first_name,
			last_name: this.props.employee_user.user.last_name,
			is_active: this.props.employee_user.user.is_active ? isActive : this.props.employee_user.user.is_active
		};
		restAPI({
			endpointName: "setStoreEmployeeActive",
			urlArgs: [storeId, employeeId.toString()],
			data: user,
			callback: async (err, res) => {
				if (!err) {
					await this.props.onRefresh();
					this.props.refetch();
				} else {
					this.props.addAlert({
						type: "danger",
						message: err.reason.response.data.message,
						timeout: 3000
					});
					this.props.refetch();
				}
			}
		});
	}

	forcePWReset(id: string, storeId: string) {
		const data = [id];
		restAPI({
			endpointName: "forceResetPWEmployeeUser",
			urlArgs: [storeId],
			data,
			callback: (err, res) => {
				this.props.addAlert({
					type: !err ? "success" : "danger",
					message: !err ? res.data.message : err.reason.response.data.message,
					timeout: 3000
				});
				this.setState({ forcedUserPWReset: true });
				if (!err) {
					this.props.onRefresh();
					this.props.refetch();
				}
			}
		});
	}

	onSubmitFileHandler(imageObject) {
		this.setState({ editingImage: false }, () => this.updateUser(imageObject));
	}

	async updateUser(data: any) {
		const isCurrentUser = this.props.employee_user && localStorage.getItem("userId") === this.props.employee_user.user.id;
		if (isCurrentUser) this.updateCurrentUser(data);
		else this.updateDealerUser(data);
	}

	updateCurrentUser(data: any) {
		restAPI({
			endpointName: "update_user",
			urlArgs: null,
			data,
			callback: (err, res) => {
				if (!err) {
					if (data.file_name) {
						this.setState({ photo_url: res.data.user.photo_url });
					}
					this.props.addAlert(this.getAlertProps(err, res));
					this.props.refetch();
				}
			}
		});
	}

	async updateDealerUser(data: any) {
		try {
			const bodyObject = data.file_name ? { image: data.image } : data;
			restAPI({
				endpointName: "update_dealer_user",
				urlArgs: [this.props.storeId, this.props.employee_user.user.id],
				data: bodyObject,
				callback: (err, res) => {
					this.props.addAlert(this.getAlertProps(err, res));
					this.props.refetch();
				}
			});
		} catch (error) {
			console.error(error);
			this.props.refetch();
		}
	}

	updateEmployee(employeeId: number, storeId: string, data: any) {
		this.setState({ savingData: true });
		restAPI({
			endpointName: "updateStoreEmployee",
			urlArgs: [storeId, employeeId],
			data,
			callback: (err, res) => {
				this.setState({ savingData: false }, () => {
					this.props.refetch();
					if (!err) this.props.onRefresh();
					else
						this.props.addAlert({
							type: "danger",
							message: err.reason.response.data.message,
							timeout: 3000
						});
				});
			}
		});
	}

	getAlertProps(err, res) {
		return {
			type: err ? "danger" : "success",
			message: err ? handleErrorResponse({ error: err }) : handleSuccessResponse({ result: res }),
			timeout: 3000
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		// Note: this is here to enable lazy loading in parent component
		// only update data in this component if the new employee id is different than the previous
		// or no employee user setup and the new employee user isnt blank
		// or there is a change in this components state in which componentDidUpdate comes into play
		return this.props.id !== nextProps.id || (!this.props.employee_user && nextProps.employee_user) || this.state !== nextState ? true : false;
	}

	componentDidUpdate(prevProps) {
		// Note: this is here to enable lazy loading in parent component
		if (prevProps.id === this.props.id) {
			// no employee user than get it
			if (this.props.employee_user === undefined) {
				this.props.refetch();
			}
		}
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const EmployeeDetailComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IEmployeeQueryProps, any, any, ClassAttributes<any>>(gqlQueries.employee, {
		options: (props: any) => {
			return {
				variables: {
					store_id: stringToInt(props.storeId),
					id: stringToInt(props.id)
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, employee_user, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				employee_user,
				refetch
			};
		}
	}),
	graphql<LMI.IUserSettingsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.settings.user, {
		options: () => {
			return {
				variables: {
					name: "NOTIFICATION_TYPE" // Array of strings for multiple settings
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, my_user_settings, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				my_user_settings,
				refetchSettings: refetch
			};
		}
	})
)(EmployeeDetailComponentView) as React.ComponentType<any>;

export default EmployeeDetailComponent;
