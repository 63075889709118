import * as React from "react";
import { ClassAttributes } from "react";
import { withRouter } from "react-router";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { Column, GridComponent } from "@syncfusion/ej2-react-grids";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import moment from "moment";
import RenderDataGrid, { DataGridProps, GridColumnHeaders, GridToolOptions } from "../../../../../../../components/DataGrid/DataGrid";
import { Icon, Loader } from "semantic-ui-react";
import { AnalyticsEventType, Months } from "loopmein-shared";
import { ModalComponent } from "../../../../ModalComponent";
import { CellSelectEventArgs } from "@syncfusion/ej2-grids";
import * as Raven from "raven-js";
import { StageBreakdownNotes } from "./notes";
import track from "react-tracking";

import "./finalStageDetail.css";

@track(
	props => {
		const { month, phase_id, recon_level_selected } = props;
		return {
			event_type: props.tracking_path ? AnalyticsEventType.SUBNAV : AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}final.stage.detail`,
			data: { month, phase_id, recon_level_selected }
		};
	},
	{ dispatchOnMount: true }
)
export class FinalStageBreakdownDetailGridView extends React.Component<LMI.IFinalStageBreakdownDetailGridProps, LMI.IFinalStageBreakdownDetailGridState> {
	grids: GridComponent[] = [];
	customCommentsAttributes: any = { class: "custom-comments" };
	selectableColumns: string[] = ["stock_number", "log_count"];

	constructor(props: LMI.IFinalStageBreakdownDetailGridProps) {
		super(props);
		this.state = {
			modal_open: false,
			modal: null,
			vehicles: []
		};
	}

	setGrid(grid) {
		this.grids.push(grid);
	}

	calculateDaysFromThreshold = threshold => {
		const unit = threshold.text.split(" ")[1];
		const time = threshold.number;
		let ms = 0;

		switch (unit) {
			case "S":
				ms = time * 1000;
				break;
			case "M":
				ms = time * 60 * 1000;
				break;
			case "H":
				ms = time * 60 * 60 * 1000;
				break;
			case "D":
				ms = time * 24 * 60 * 60 * 1000;
				break;
			default:
				break;
		}
		return FinalStageBreakdownDetailGridView.calculateElapsedDays(ms);
	};

	static calculateElapsedDays(elapsedTime, precision = 2) {
		let ms = elapsedTime;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = ms / 24;

		return {
			days: days.toFixed(precision).padStart(2, "0"),
			hours: hours.toFixed(precision).padStart(2, "0"),
			minutes: minutes.toFixed(precision).padStart(2, "0"),
			seconds: seconds.toFixed(precision).padStart(2, "0")
		};
	}

	static formatData = ({ data }) => {
		return data.map((item, i) => {
			return {
				stock_number: item.stock_number,
				year: item.year,
				make: item.make,
				model: item.model,
				trim: item.trim,
				log_count: item.log_count,
				inventory_id: item.inventory_id,
				dispositioned_at: item.dispositioned_at ? moment(parseInt(item.dispositioned_at, 10)).format("MM/DD/YY hh:mmA") : null,
				recon_level_name: item.recon_level_name
			};
		});
	};

	getProductiveTemplate = (props: any) => {
		return `${props.total_elapsed.days > 0 ? props.total_elapsed.days + "d" : ""}${props.total_elapsed.hours > 0 ? " " + props.total_elapsed.hours + "h" : ""}${
			props.total_elapsed.minutes > 0 ? " " + props.total_elapsed.minutes + "m" : ""
		}${props.total_elapsed.seconds > 0 ? " " + props.total_elapsed.seconds + "s" : ""}`;
	};

	createGridColumns(source, data) {
		const defaultProps = {
			textAlign: "center",
			allowFiltering: false,
			allowSorting: false
		};

		const headerData: any = [
			{
				...defaultProps,
				id: "stock_number",
				headerText: "Stock #",
				textAlign: "left",
				cellAttrs: {
					template: (props: any) => {
						return (
							<span className={`stock-number active`} data-column="stock_number">
								{props.stock_number}
							</span>
						);
					}
				},
				valueAccessor: (field: string, data: any, column: Column) => {
					return data.stock_number;
				}
			},
			{
				...defaultProps,
				id: "year",
				headerText: "Year",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "make",
				headerText: "Make",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "model",
				headerText: "Model",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "trim",
				headerText: "Trim",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "dispositioned_at",
				headerText: "Date In",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "recon_level_name",
				headerText: "Recon Level",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "log_count",
				headerText: "Notes",
				textAlign: "center",
				customAttributes: this.customCommentsAttributes,
				cellAttrs: {
					template: (props: any) => {
						return <span data-column="log_count">{props.log_count ? <Icon name="comments" /> : ""}</span>;
					}
				},
				valueAccessor: (field: string, data: any, column: Column) => {
					return data.log_count;
				}
			}
		];

		return GridColumnHeaders(data, headerData, ["stock_number", "year", "make", "model", "trim", "recon_level_name", "dispositioned_at", "log_count"]);
	}

	calculateElapsedMinimum({ days, hours, minutes, seconds }, useFull) {
		// console.log(elapsedTime, days, hours, minutes, seconds);
		if (days > 0) return days + ` D${useFull ? `ay${days > 1 ? "s" : ""}` : ""}`;
		if (hours > 0) return hours + ` H${useFull ? `our${hours > 1 ? "s" : ""}` : ""}`;
		if (minutes > 0) return minutes + ` M${useFull ? `inute${minutes > 1 ? "s" : ""}` : ""}`;
		if (seconds > 0) return `<1 M${useFull ? `inute${minutes > 1 ? "s" : ""}` : ""}`;

		return `0 M${useFull ? "inutes" : ""}`;
	}

	closeModal = () => {
		this.setState({ modal_open: false });
	};

	clearSelection = index => {
		this.grids[index].clearSelection();
	};

	handleSelect = (args: CellSelectEventArgs, index: number): boolean => {
		try {
			if (!args || !args.currentCell || !args.currentCell.firstChild || !(args.currentCell.firstChild as HTMLElement).getAttribute) {
				args.cancel = true;
				this.clearSelection(index);
				return false;
			}

			const column = (args.currentCell.firstChild as HTMLElement).getAttribute("data-column");
			const isSelectable = this.selectableColumns.indexOf(column) >= 0;

			if (!isSelectable || (column === "log_count" && !(args.data as any).log_count)) {
				args.cancel = true;
				this.clearSelection(index);
				return false;
			} else {
				if (column === "stock_number") {
					this.props.history.push(`/admin/dealerships/inventory?id=${(args.data as any).inventory_id}`);
				} else this.setState({ modal_open: true, modal: { inventory_id: (args.data as any).inventory_id } });
			}
		} catch (error) {
			Raven.captureException(error);
			console.error(error);
		}
	};

	render() {
		const { modal_open, vehicles, modal } = this.state;
		const { loading, users, roles, month, year, store_id, count } = this.props;

		if (loading || !vehicles) return <Loader className={`loader active`} size="small" />;

		return (
			<div className="final-stage-breakdown-detail-grid">
				{modal_open && (
					<ModalComponent
						headerText="Vehicle Notes"
						size="small"
						shouldBeOpen={modal_open}
						onClose={() => this.closeModal()}
						className="stage-breakdown-notes-modal"
						scrolling={true}
						contentComponent={() => (
							<StageBreakdownNotes
								{...{
									store_id,
									inventory_id: modal.inventory_id,
									tracking_path: this.props.tracking.getTrackingData().event_subtype
								}}
							/>
						)}
					/>
				)}
				<div className="top-grid">
					<div className="grid-head">
						<div className="head-row">
							Count: <span className="associated">{count}</span>
						</div>
						<div className="head-row">
							Users: <span className="associated">{users.map((u, index) => `${u.name}${index < users.length - 1 ? ", " : ""}`)}</span>
						</div>
						<div className="head-row">
							Roles: <span className="associated">{roles.map((r, index) => `${r.name}${index < roles.length - 1 ? ", " : ""}`)}</span>
						</div>
					</div>
					<RenderDataGrid
						{...({
							rowHeight: "25",
							gridLines: "Both",
							total: vehicles.length,
							data: vehicles,
							columns: this.createGridColumns("vehicles", vehicles),
							tools: [GridToolOptions.excelexport, GridToolOptions.sorting, GridToolOptions.title, GridToolOptions.cellselection],
							grid_title: { text: vehicles.length > 0 ? `Finalized Vehicles - ${vehicles.length} UNITS` : "" },
							textwrap: false,
							setGrid: grid => this.setGrid(grid),
							onSelect: args => this.handleSelect(args, 0),
							noresults: {
								icon: "truck",
								text: `There were no Finalized Vehicles in ${Months[month]} ${year ? year : ""}`
							}
						} as DataGridProps)}
					/>
				</div>
			</div>
		);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const result = {};
		if (nextProps.vehicles) {
			const formattedData = FinalStageBreakdownDetailGridView.formatData({
				data: nextProps.vehicles
			});
			if (formattedData !== prevState.vehicles) {
				result["vehicles"] = formattedData;
			}
		}
		return result;
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const FinalStageBreakdownDetailGrid = compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventorySearchPartDuex, {
		options: (props: any) => {
			return {
				variables: {
					store_ids: parseInt(props.store_id, 10),
					phase: parseInt(props.phase_id, 10),
					sold: false
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, inventory_search2, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				inventory_results: inventory_search2.items,
				refetchSearch: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.finalStageBreakdownDetail, {
		options: (props: any) => {
			return {
				variables: {
					phaseId: parseInt(props.phase_id, 10),
					storeId: parseInt(props.store_id, 10),
					month: parseInt(props.month, 10),
					reconLevelId: parseInt(props.recon_level_selected, 10)
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, final_stage_breakdown_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				vehicles: final_stage_breakdown_detail.vehicles,
				refetchFinalDetail: refetch
			};
		}
	})
)(FinalStageBreakdownDetailGridView);
