import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";

import {
  ChangedEventArgs,
  DatePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import { gqlQueries } from "gql-imports";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import {
  Confirm,
  Dropdown,
  Header,
  Input,
  Loader,
  Radio,
} from "semantic-ui-react";
import {
  formatPhone,
  formatPrecisionCurrency,
  formatUSNumber,
  handleErrorResponse,
  scrollElementIntoView,
} from "../../../../../../utils/functions";
import moment from "moment";
import {
  AnalyticsEventType,
  Features,
  LeaseReturnDisposition,
  LeaseReturnStatusAction,
  ProgressIndicatorDirection,
} from "loopmein-shared";
import { RegularExpressions } from "../../../../../../utils/regex";
import {
  addAlert,
  enableUABCallback,
  triggerUABCallback,
} from "../../../../../../../api/redux/actions";
import * as Raven from "raven-js";
import ProgressIndicator from "../../../../../../components/Progress/ProgressIndicator";
import { restAPI } from "../../../../../../utils/rest";
import track from "react-tracking";

import "./DetailsTab.css";

@track(
  {
    event_type: AnalyticsEventType.NAVIGATION,
    event_subtype: "lease_returns.detail",
  },
  { dispatchOnMount: true }
)
export class DetailsComponentView extends React.Component<
  LMI.ILRDetailViewProps,
  LMI.ILRDetailState
> {
  private static displayGroups: string[] = [
    "customer_info",
    "bank_info",
    "other",
    "completion",
  ];
  private static includeFields: any[] = [
    { key: "age", order: 0, type: "progress", input: "progress", group: "age" },
    {
      key: "vin",
      order: 1,
      label: "vin",
      type: "string",
      input: "text",
      width: "calc(100% - 140px)",
      group: "detail",
      formatter: "uppercase",
    },
    {
      key: "customer_name",
      order: 2,
      label: "customer name",
      type: "string",
      input: "text",
      width: "calc(100% - 140px)",
      group: "detail",
    },
    {
      key: "leasing_company",
      alt_key: "leasing_company_id",
      order: 3,
      label: "leasing company",
      type: "object",
      field: "name",
      input: "select",
      width: "98%",
      group: "detail",
    },
    {
      key: "odometer",
      order: 4,
      label: "odometer",
      type: "number",
      input: "text",
      width: "48%",
      group: "detail",
      formatter: "number",
    },
    {
      key: "license_plate_number",
      order: 5,
      label: "license plate",
      type: "string",
      input: "text",
      width: "49%",
      group: "detail",
      formatter: "uppercase",
      max: 8,
    },
    {
      key: "disposition_id",
      order: 6,
      label: "disposition",
      type: "string",
      field: "name",
      input: "select",
      width: "48%",
      group: "detail",
    },
    {
      key: "lease_return_status",
      alt_key: "lease_return_status_id",
      order: 7,
      label: "lease status",
      type: "object",
      field: "name",
      input: "select",
      width: "49%",
      group: "detail",
    },
    {
      key: "drop_off_date",
      order: 8,
      label: "drop off date",
      type: "timestamp",
      input: "text",
      width: "48%",
      group: "detail",
    },
    {
      key: "customer_phone",
      order: 9,
      label: "customer phone",
      type: "phone",
      input: "text",
      width: "99%",
      group: "customer_info",
      formatter: "phone",
    },
    {
      key: "customer_email",
      order: 10,
      label: "customer email",
      type: "string",
      input: "text",
      width: "99%",
      group: "customer_info",
    },
    {
      key: "customer_signature_full_url",
      order: 11,
      label: "customer signature",
      type: "image",
      input: "text",
      width: "100%",
      group: "customer_info",
    },
    {
      key: "customer_account_number",
      order: 12,
      label: "account number",
      type: "string",
      input: "text",
      width: "99%",
      group: "bank_info",
      formatter: "uppercase",
    },
    {
      key: "buyout_price",
      order: 13,
      label: "buyout amount",
      type: "currency",
      input: "text",
      width: "99%",
      group: "bank_info",
      formatter: "precision_currency",
    },
    {
      key: "buyout_good_through",
      order: 14,
      label: "good through date",
      type: "timestamp",
      input: "text",
      width: "99%",
      group: "bank_info",
    },
    {
      key: "auction_price",
      order: 15,
      label: "mmr / auction amount",
      type: "currency",
      input: "text",
      width: "99%",
      group: "other",
      formatter: "currency",
    },
    {
      key: "blue_book_price",
      order: 16,
      label: "kbb / nada amount",
      type: "currency",
      input: "text",
      width: "99%",
      group: "other",
      formatter: "currency",
    },
    {
      key: "estimated_recon_price",
      order: 17,
      label: "estimated recon",
      type: "currency",
      input: "text",
      width: "99%",
      group: "other",
      formatter: "currency",
    },
    {
      key: "sold_here",
      order: 18,
      label: "sold here",
      type: "boolean",
      input: "toggle",
      width: "48%",
      group: "other",
    },
    {
      key: "buying_here",
      order: 19,
      label: "buying here",
      type: "boolean",
      input: "toggle",
      width: "49%",
      group: "other",
    },
  ];

  private static dispositionArray: string[] = Object.keys(
    LeaseReturnDisposition
  )
    .filter((key) => !isNaN(Number(LeaseReturnDisposition[key])))
    .map((n) => n.replace(/([A-Z])/g, " $1"));
  private currencyRegEx = RegularExpressions.CURRENCY;
  private numberRegEx = RegularExpressions.NUMBER;
  private currencyReplace = new RegExp(this.currencyRegEx, "g");
  private numberReplace = new RegExp(this.numberRegEx, "g");
  private dateObj = [];

  constructor(props: LMI.ILRDetailViewProps) {
    super(props);
    this.state = {
      id: null,
      change_made: false,
      saving: false,
      resetting: false,
      selectedRowIndex: null,
      hasAddLeaseReturnResult: false,
      data: null,
      customer_name: null,
      leasing_company: null,
      vin: null,
      odometer: null,
      drop_off_date: null,
      pick_up_date: null,
      license_plate_number: null,
      buyout_price: null,
      auction_price: null,
      blue_book_price: null,
      disposition_id: null,
      lease_return_status: null,
      date_statused: null,
      customer_signature_full_url: null,
      customer_email: null,
      customer_phone: null,
      customer_account_number: null,
      buyout_good_through: null,
      estimated_recon_price: null,
      sold_here: null,
      buying_here: null,
      age: null,
      move_to_inventory: true,
      stock_number: "",
      selling_price: null,
      customer_info: "hide",
      bank_info: "hide",
      other: "hide",
      completion: "hide",
      show_completion_group: false,
      open_select: null,
      confirm_complete: false,
      confirm_status: false,
      confirm_status_object: null,
    };
  }

  resetFields = () => {
    const fields = {};
    DetailsComponentView.includeFields.forEach((i) => {
      fields[i.key] = null;
    });

    const newState = {
      ...fields,
      change_made: false,
      saving: false,
      resetting: true,
      completion: "hide",
      show_completion_group: false,
    };
    this.setState(newState, () => this.setState({ resetting: false }));
  };

  sendAlert({ type, message }) {
    this.props.addAlert({
      type,
      message,
      timeout: 3000,
    });
    if (type === "danger") {
      console.log(`Error: ${message}`);
    }
  }

  static format(detail) {
    return DetailsComponentView.includeFields.map((i) => {
      return {
        value: detail[i.key],
        type: i.type,
        field: i.field,
        key: i.key,
        alt_key: i.alt_key,
        input: i.input,
        label: i.label,
        width: i.width,
        order: i.order,
        group: i.group,
        age: i.age,
        formatter: i.formatter,
        max: i.max,
      };
    });
  }

  getOptions = (key) => {
    const options = this.props[key];
    if (options && options.length > 0) {
      return options.map((o) => {
        let address;
        if (o.address1) {
          address = `${o.address1},`;
          const address2 = o.address2 ? ` ${o.address2},` : "";
          const cSZ = ` ${o.city}, ${o.state} ${o.zip}`;
          address += address2 + cSZ;
          if (o.phone) {
            const phoneArray = formatPhone(o.phone).split("-");
            address += ` - (${phoneArray[0]}) ${phoneArray[1]}-${phoneArray[2]}`;
          }
        }
        return {
          key: o.id,
          text: o.name,
          value: o.id,
          action: o.action,
          content: (
            <Header
              content={o.name}
              subheader={address ? address : o.description ? o.description : ""}
            />
          ),
        };
      });
    }
  };

  static getDispositionOptions = () => {
    return DetailsComponentView.dispositionArray.map((d, index) => {
      return { id: index + 1, name: d, description: "" };
    });
  };

  handleChange = (event: any, data: any, formatter: string = "") => {
    const value = data.value;
    this.setState({
      [event.currentTarget.name]: value,
      saving: false,
      change_made: true,
    });
  };

  handleChangeSelect = (event: any, data: any) => {
    const value = data.value;
    const action = data.options.find((o) => o.value === data.value).action;

    // Check for status action and if so, get confirmation, else go ahead and set the value
    if (action === LeaseReturnStatusAction.Notification)
      this.setState({
        confirm_status: true,
        confirm_status_object: {
          field: event.target.parentElement.parentElement.parentElement.getAttribute(
            "data-name"
          ),
          value: data.value,
        },
      });
    else
      this.setState({
        [event.target.parentElement.parentElement.parentElement.getAttribute(
          "data-name"
        )]: value,
        saving: false,
        change_made: true,
      });
  };

  confirmLeaseReturnStatusChange = () => {
    this.setState(
      (prevState) => {
        return {
          [prevState.confirm_status_object.field]:
            prevState.confirm_status_object.value,
          saving: false,
          change_made: true,
          confirm_status: false,
          confirm_status_object: null,
        };
      },
      () => this.handleSave()
    );
  };

  checkForAlternateKey = ({ key, alt_key }) => {
    return alt_key ? alt_key : key;
  };

  handleSave = async () => {
    if (this.state.change_made) {
      const { storeId, lease_return } = this.props;
      const data = {};

      DetailsComponentView.includeFields.forEach((d) => {
        const key = this.checkForAlternateKey({
          key: d.key,
          alt_key: d.alt_key,
        });
        if (this.state[d.key] !== null) {
          // If the user sets a field to Zero or "" then reset that field
          if (d.type === "boolean") {
            data[key] = this.state[d.key];
          } else if (d.type === "number") {
            data[key] = this.state[d.key].replace(this.numberReplace, "");
          } else if (
            parseInt(this.state[d.key].toString(), 10) === 0 ||
            this.state[d.key].toString() === ""
          ) {
            data[key] = null;
          } else {
            data[key] =
              d.type === "currency"
                ? this.state[d.key].replace(this.currencyReplace, "")
                : this.state[d.key];
          }
        }
      });

      restAPI({
        endpointName: "update_lease_return",
        urlArgs: [storeId, lease_return.id],
        data,
        callback: async (error, result) => {
          if (error) {
            this.resetFields(); // Reset the updated fields because they are likely incorrect
            this.sendAlert({
              type: "danger",
              message: handleErrorResponse({ error }),
            });
          } else {
            await this.props.refetchDetail();
            await this.props.refetchLeaseReturns(null);

            // Ensures the grid reloads on LR updates
            this.props.onChangeMade(new Date().getTime());

            // this.resetFields();
          }
        },
      });
    }
  };

  completeLeaseReturn = () => {
    const {
      lease_return,
      storeId,
      detail,
      filterIsSet,
      searchKey,
      onFilterDataSet,
    } = this.props;
    const { move_to_inventory, pick_up_date } = this.state;
    const canMoveToInventory =
      lease_return.disposition_id === 1 || lease_return.disposition_id === 3;

    if (!!lease_return.completed_at) return false;

    this.setState({ saving: true }, () => {
      const { stock_number, selling_price } = this.state;
      let didSucceed = false;

      const data = {
        pick_up_date,
        stock_number,
      };

      restAPI({
        endpointName: "completeLeaseReturn",
        urlArgs: [storeId, detail.detail_id],
        data,
        callback: (error, result) => {
          if (error) {
            this.setState({ saving: false, confirm_complete: false });
            this.sendAlert({
              type: "error",
              message: handleErrorResponse({ error }),
            });
            Raven.captureException(error);
            console.error(error);
          } else {
            didSucceed = true;
            this.sendAlert({
              type: "success",
              message: "This vehicle was successfully marked completed!",
            });

            if (canMoveToInventory && move_to_inventory) {
              const data = {};
              if (stock_number && stock_number.trim() !== "")
                data["stock_number"] = stock_number;
              if (selling_price && selling_price.trim() !== "")
                data["selling_price"] = String(selling_price).replace(
                  this.currencyReplace,
                  ""
                );
              restAPI({
                endpointName: "addLeaseReturnToInventory",
                urlArgs: [this.props.storeId, this.props.detail.detail_id],
                data,
                callback: (error, result) => {
                  if (error) {
                    this.sendAlert({
                      type: "error",
                      message: handleErrorResponse({ error }),
                    });
                    Raven.captureException(error);
                    console.error(error);
                  }
                  this.sendAlert({
                    type: "success",
                    message:
                      "This vehicle was successfully moved to inventory!",
                  });
                  console.log("Moved to inventory: ", this.props.detail);
                },
              });
            }
          }
          this.setState(
            {
              saving: false,
              confirm_complete: false,
              selected_id: 0,
              completion: "hide",
            },
            async () => {
              if (didSucceed) {
                if (filterIsSet) {
                  await this.props.refetchLeaseReturns(null);
                  onFilterDataSet(searchKey);
                } else {
                  await this.props.refetchLeaseReturns(null);
                  setTimeout(() => this.props.grid.selectRow(0), 1000);
                }
              }
            }
          );
        },
      });
    });
  };

  handleDateChange(
    data: any,
    change_made: boolean = true,
    args: ChangedEventArgs
  ): void {
    if (!this.state.resetting) {
      this.setState({
        change_made,
        saving: false,
        [data.key]: args.value,
      });
    }
  }

  handleItemToggle = ({ checked, index, item }) => {
    console.log("toggs", checked, index, item, this.state);
    this.setState({ change_made: true, saving: false, [item]: checked }, () =>
      console.log(this.state)
    );
  };

  onFocus(key: number) {
    this.dateObj[key].show();
  }

  getTextElement = ({ data, label, value, index, type }) => (
    <div
      className={`input-wrapper ${data.group} ${
        !DetailsComponentView.displayGroups.includes(data.group) ? "show" : ""
      }`}
      style={{ width: `${data.width}` }}
      key={index}
    >
      <div className="lmi-label">{label}</div>
      <Input
        disabled={
          !this.props.can_edit || !!this.props.lease_return.completed_at
        }
        className="lmi-input"
        name={data.key && data.key}
        value={value}
        onChange={(e, data) => this.handleChange(e, data)}
      />
    </div>
  );

  getReadonlyElement = ({ data, label, value, index }) => (
    <div
      className={`input-wrapper ${data.group} ${
        !DetailsComponentView.displayGroups.includes(data.group) ? "show" : ""
      }`}
      style={{ width: `${data.width}` }}
      key={index}
    >
      <div className="lmi-label">{label}</div>
      <div className="lmi-readonly">
        {moment(parseInt(value, 10)).format("LL")}
      </div>
    </div>
  );

  getSelectElement = ({ data, label, value, index }) => (
    <div
      className={`input-wrapper ${data.group} ${
        !DetailsComponentView.displayGroups.includes(data.group) ? "show" : ""
      }`}
      style={{ width: `${data.width}` }}
      key={index}
    >
      <div id={`selectLabel-${data.key}`} className="lmi-select-label">
        {label}
      </div>
      <Dropdown
        id={`select-${data.key}`}
        disabled={
          !this.props.can_edit || !!this.props.lease_return.completed_at
        }
        className={`select`}
        data-name={data.key && data.key}
        value={value}
        placeholder={`Choose ${label}`}
        fluid
        selection
        options={this.getOptions(data.key)}
        onChange={this.handleChangeSelect.bind(this)}
        onOpen={this.handleOpenSelect.bind(this)}
        onClose={this.handleCloseSelect.bind(this)}
      />
    </div>
  );

  handleOpenSelect = (event) => {
    if (event) {
      event.currentTarget.previousSibling.style.zIndex = "11";
      // this.setState({ open_select: event.currentTarget.id });
    }
  };

  handleCloseSelect = () => {
    const nodeList: NodeList = document.querySelectorAll(".lmi-select-label");
    const elements = Array.from(nodeList, (t) => t as HTMLElement);
    for (const element of elements) {
      element.style.zIndex = "1";
    }
  };

  getToggleElement = ({ data, label, value, index }) => {
    const classes = `toggle ${data.group} ${
      !DetailsComponentView.displayGroups.includes(data.group) ||
      this.state[data.group] === "show"
        ? "show"
        : ""
    }`; //|| this.state[data.group] === "show"
    return (
      <Radio
        disabled={
          !this.props.can_edit || !!this.props.lease_return.completed_at
        }
        className={classes}
        label={label}
        toggle
        checked={value}
        onClick={(e, props) => {
          return this.handleItemToggle({
            checked: props.checked,
            index,
            item: data.key,
          });
        }}
      />
    );
  };

  getDatepickerElement = ({ data, label, value, index }) => {
    return (
      <div
        className={`input-wrapper datepicker ${data.group} ${
          !DetailsComponentView.displayGroups.includes(data.group) ? "show" : ""
        }`}
        style={{ width: `${data.width}` }}
        key={index}
      >
        <div className="lmi-datepicker-label">{label}</div>
        <DatePickerComponent
          disabled={
            !this.props.can_edit || !!this.props.lease_return.completed_at
          }
          id={`datepicker-${index}`}
          className={`lmi-input datepicker`}
          format="MM/dd/yy"
          placeholder="Enter date"
          change={this.handleDateChange.bind(this, data, true)}
          value={
            typeof value === "object"
              ? value
              : value === null
              ? null
              : new Date(parseInt(value, 10))
          }
          focus={this.onFocus.bind(this, data.order)}
          ref={(scope) => {
            this.dateObj[data.order] = scope;
          }}
        />
      </div>
    );
  };

  getSignatureElement = ({ data, label, value, index }) => (
    <div
      className={`input-wrapper signature ${data.group} ${
        !DetailsComponentView.displayGroups.includes(data.group) ? "show" : ""
      }`}
      key={index}
    >
      <div className="lmi-label">{label}</div>
      <div className="lmi-signature">
        <img src={value} />
      </div>
    </div>
  );

  getGroupElement = ({ group }) => (
    <div
      key={group}
      className="group"
      onClick={this.toggleGroup.bind(this, group, group)}
    >
      <i className="icon angle up" />
      <div className={`content ${group}`}>
        {group.replace(/_/g, " ").toUpperCase()}
      </div>
      <div className={`overlay ${group}`} />
      <div className={`corner ${group}`} />
    </div>
  );

  toggleGroup = (group: string, scrollAnchor: string, event) => {
    const icon = event.currentTarget.firstElementChild;
    let mode;
    if (icon.classList.contains("up")) {
      icon.classList.remove("up");
      icon.classList.add("down");
      mode = "show";
    } else {
      icon.classList.add("up");
      icon.classList.remove("down");
      mode = "hide";
    }
    this.setState({ [group]: mode });
    this.toggleSibling(
      event.currentTarget.nextElementSibling,
      group,
      mode,
      scrollAnchor
    );
  };

  toggleSibling = (
    sibling: HTMLElement,
    group: string,
    mode: string,
    scrollAnchor: string
  ) => {
    if (sibling.classList.contains(group) && mode === "hide") {
      sibling.classList.remove("show");
    } else if (sibling.classList.contains(group)) sibling.classList.add("show");

    if (sibling.nextElementSibling) {
      this.toggleSibling(
        sibling.nextElementSibling as HTMLElement,
        group,
        mode,
        scrollAnchor
      );
    } else if (scrollAnchor) scrollElementIntoView(scrollAnchor);

    return;
  };

  toggleCompletionGroup = (event) => {
    const icon = event.currentTarget.firstElementChild;
    if (icon.classList.contains("up")) {
      icon.classList.remove("up");
      icon.classList.add("down");
      this.setState({ show_completion_group: true }, () =>
        setTimeout(() => scrollElementIntoView("completion"), 0)
      );
    } else {
      icon.classList.add("up");
      icon.classList.remove("down");
      this.setState({ show_completion_group: false }, () =>
        setTimeout(() => scrollElementIntoView("completion"), 0)
      );
    }
  };

  applyFormatter = ({ data, value }) => {
    if (data.max) value = !!value ? value.substr(0, data.max) : null;
    if (data.formatter) {
      switch (data.formatter) {
        case "uppercase":
          value = !!value ? value.toUpperCase() : null;
          break;
        case "currency":
          value = !!value
            ? String(value).replace(this.currencyReplace, "")
            : null;
          value = formatPrecisionCurrency(parseInt(value, 10), false);
          break;
        case "precision_currency":
          value = !!value
            ? String(value).replace(this.currencyReplace, "")
            : null;
          value = formatPrecisionCurrency(parseInt(value, 10), true);
          break;
        case "number":
          value = !!value ? value.replace(this.numberReplace, "") : null;
          value = formatUSNumber(parseInt(value, 10));
          break;
        case "phone":
          value = formatPhone(String(value));
          break;
        default:
          break;
      }
    }

    return value;
  };

  render() {
    const {
      data,
      render_key,
      change_made,
      saving,
      confirm_complete,
      move_to_inventory,
      stock_number,
      selling_price,
      pick_up_date,
      show_completion_group,
      disposition_id,
      confirm_status,
    } = this.state;
    const {
      can_edit,
      loading,
      lease_return,
      store_settings,
      all_checklist_item_count,
      uncompleted_checklist_item_count,
      onSetHeaderColor,
    } = this.props;
    const ready = !loading && lease_return;
    const currentGroup = {};

    if (!ready || saving) {
      return <Loader className={`loader active`} size="small" />;
    }

    const disposition = disposition_id
      ? disposition_id
      : lease_return && lease_return.disposition_id;
    const canMoveToInventory =
      lease_return &&
      (disposition === LeaseReturnDisposition.InventoryPurchase ||
        disposition === LeaseReturnDisposition.CustomerPurchase);
    const isReturnToBank = disposition === LeaseReturnDisposition.ReturnToBank;
    const disableComplete =
      (isReturnToBank && !pick_up_date) ||
      change_made ||
      !disposition ||
      (!isReturnToBank && move_to_inventory && !stock_number);
    const is_completed = !!lease_return.completed_at;

    onSetHeaderColor({ age: lease_return.age, store_settings, is_completed });

    const confirmProps = {
      open: confirm_complete || confirm_status,
      size: "tiny" as any,
      header: confirm_complete
        ? "Please Confirm Completion"
        : "Please Confirm Status Change",
      content: confirm_complete
        ? "Complete this Lease Return? This action cannot be undone."
        : "Are you sure you want to request an inspection of this vehicle?",
      cancelButton: "Nevermind",
      confirmButton: "Yes, Confirm",
      onCancel: confirm_complete
        ? () => this.setState({ confirm_complete: false })
        : () =>
            this.setState({
              confirm_status: false,
              confirm_status_object: null,
            }),
      onConfirm: confirm_complete
        ? this.completeLeaseReturn
        : this.confirmLeaseReturnStatusChange,
    };

    return (
      <>
        <div
          id={`detailsContainer`}
          className={`${!!lease_return.completed_at ? "readonly" : ""}`}
          key={render_key}
        >
          <Confirm {...confirmProps} />
          {Object.keys(data).map((d, index) => {
            // tslint:disable-next-line:one-variable-per-declaration
            let divider, response;
            if (
              currentGroup[data[d].group] &&
              currentGroup[data[d].group] > 0
            ) {
              currentGroup[data[d].group]++;
            } else {
              currentGroup[data[d].group] = 1;
              divider =
                DetailsComponentView.displayGroups.includes(data[d].group) &&
                this.getGroupElement({ group: data[d].group });
            }

            const textTypes = [
              "string",
              "number",
              "object",
              "currency",
              "progress",
              "phone",
              "boolean",
            ];
            if (textTypes.includes(data[d].type)) {
              const label =
                data[d].label && data[d].label.replace(/_/g, " ").toUpperCase();
              const rawValue =
                data[d].type !== "object"
                  ? data[d].value
                  : data[d].value && data[d].value[data[d].field];

              switch (data[d].input) {
                case "progress":
                  const age = data[d].value;
                  const colors = Features.LeaseReturn.getLeaseAgeColor(
                    age,
                    store_settings
                  );
                  const progress = Features.LeaseReturn.getLeaseProgressValue(
                    {
                      age,
                      all_checklist_item_count,
                      uncompleted_checklist_item_count,
                    },
                    store_settings
                  );
                  const width = 127;
                  const strokeWidth = 9;
                  const strokeColor = !is_completed ? colors.barColor : "#777";
                  const innerColor = !is_completed ? colors.ageColor : "#aaa";
                  const outerColor = "#ddd";
                  const imagePath = "/images/white_icon_car.png";
                  const progressLabel = !is_completed
                    ? `${age} Day${age > 1 ? "s" : ""}`
                    : "Complete";
                  const method =
                    store_settings &&
                    store_settings.find(
                      (i) => i.name === "LEASE_RETURN_PROGRESS_METHOD"
                    ).value;
                  const direction =
                    method === "checklist"
                      ? ProgressIndicatorDirection.Clockwise
                      : ProgressIndicatorDirection.CounterClockwise;
                  response = ProgressIndicator({
                    direction,
                    imagePath,
                    progressLabel,
                    progress,
                    width,
                    strokeWidth,
                    strokeColor,
                    innerColor,
                    outerColor,
                    isCompleted: is_completed,
                  });
                  break;
                case "text":
                  let value =
                    this.state[data[d].key && data[d].key] === null
                      ? rawValue
                      : this.state[data[d].key];
                  value = this.applyFormatter({ data: data[d], value });
                  response = this.getTextElement({
                    data: data[d],
                    label,
                    value,
                    index,
                    type: data[d].type,
                  });
                  break;
                case "readonly":
                  const rValue =
                    this.state[data[d].key && data[d].key] === null
                      ? rawValue
                      : this.state[data[d].key];
                  response = this.getReadonlyElement({
                    data: data[d],
                    label,
                    value: rValue,
                    index,
                  });
                  break;
                case "select":
                  const sValue = this.state[data[d].key && data[d].key]
                    ? this.state[data[d].key]
                    : data[d].type === "string"
                    ? data[d].value
                    : data[d].value
                    ? data[d].value.id
                    : null;
                  response = this.getSelectElement({
                    data: data[d],
                    label,
                    value: sValue,
                    index,
                  });
                  break;
                case "toggle":
                  const tValue =
                    this.state[data[d].key && data[d].key] !== null
                      ? this.state[data[d].key]
                      : data[d].value;
                  response = this.getToggleElement({
                    data: data[d],
                    label,
                    value: tValue,
                    index,
                  });
                  break;
                default:
                  break;
              }
            } else if (data[d].type === "timestamp") {
              const label =
                data[d].label && data[d].label.replace(/_/g, " ").toUpperCase();
              const rawValue = data[d].value;
              const value = this.state[data[d].key && data[d].key]
                ? this.state[data[d].key && data[d].key]
                : rawValue;
              response = this.getDatepickerElement({
                data: data[d],
                label,
                value,
                index,
              });
            } else if (
              data[d].key &&
              data[d].key === "customer_signature_full_url"
            ) {
              const label =
                data[d].label && data[d].label.replace(/_/g, " ").toUpperCase();
              if (data[d].value)
                response = this.getSignatureElement({
                  data: data[d],
                  label,
                  value: data[d].value,
                  index,
                });
            }
            return [divider, response];
          })}
          {!lease_return.completed_at && can_edit && (
            <>
              <div
                className="group can-active"
                onClick={this.toggleCompletionGroup.bind(this)}
              >
                <i className="icon angle up" />
                <div className={`content completion`}>COMPLETION</div>
                <div className={`overlay completion`} />
                <div className={`corner completion`} />
              </div>

              {show_completion_group && (
                <>
                  {canMoveToInventory ? (
                    <>
                      <Radio
                        id="inventoryToggle"
                        className={`completion`}
                        label="Move this vehicle to inventory?"
                        toggle
                        checked={move_to_inventory}
                        onClick={(e, props) => {
                          return this.setState({
                            move_to_inventory: props.checked,
                          });
                        }}
                      />
                      {move_to_inventory && (
                        <span id="inventoryGroup">
                          <div
                            className={`input-wrapper completion-text completion`}
                            style={{ width: "100%" }}
                            key="stocknumber"
                          >
                            <div className="lmi-label">STOCK NUMBER</div>
                            <Input
                              className={`lmi-input required`}
                              type="string"
                              name="stock_number"
                              placeholder="Required"
                              value={stock_number}
                              onChange={(e, data) =>
                                this.setState({
                                  stock_number: data.value
                                    .toString()
                                    .toUpperCase(),
                                })
                              }
                            />
                          </div>
                          <div
                            className={`input-wrapper completion-text completion`}
                            style={{ width: "100%" }}
                            key="sellingprice"
                          >
                            <div className="lmi-label">SELLING PRICE</div>
                            <Input
                              className="lmi-input"
                              type="string"
                              name="selling_price"
                              placeholder="Optional"
                              value={selling_price}
                              defaultValue="$0"
                              onChange={(e, data) =>
                                this.setState({
                                  selling_price: formatPrecisionCurrency(
                                    parseInt(
                                      String(data.value).replace(
                                        this.currencyReplace,
                                        ""
                                      ),
                                      10
                                    ),
                                    false
                                  ),
                                })
                              }
                            />
                          </div>
                        </span>
                      )}
                    </>
                  ) : (
                    isReturnToBank && (
                      <div
                        className={`input-wrapper datepicker completion`}
                        style={{
                          width: "90%",
                          margin: "20px auto",
                          padding: "0 0 0 5px",
                        }}
                      >
                        <div className="lmi-datepicker-label">PICK UP DATE</div>
                        <DatePickerComponent
                          disabled={!this.props.can_edit}
                          id={`datepicker-pickUpDate`}
                          className={`lmi-input datepicker`}
                          format="MM/dd/yy"
                          placeholder="Enter date (required)"
                          change={this.handleDateChange.bind(
                            this,
                            { key: "pick_up_date" },
                            false
                          )}
                          focus={this.onFocus.bind(
                            this,
                            DetailsComponentView.includeFields.length
                          )}
                          ref={(scope) => {
                            this.dateObj[
                              DetailsComponentView.includeFields.length
                            ] = scope;
                          }}
                        />
                      </div>
                    )
                  )}
                  <div id="disclaimer" className={`completion`}>
                    {`Clicking Complete will finalize and lock all fields. Do Not complete until Units disposition is ${[
                      DetailsComponentView.dispositionArray
                        .slice(0, -1)
                        .join(", "),
                      DetailsComponentView.dispositionArray.slice(-1)[0],
                    ].join(
                      DetailsComponentView.dispositionArray.length < 2
                        ? ""
                        : " or "
                    )}`}
                  </div>
                  <button
                    id="completion"
                    className={`completion ${
                      disableComplete ? "disabled" : ""
                    }`}
                    disabled={disableComplete}
                    onClick={() => this.setState({ confirm_complete: true })}
                  >
                    Complete Lease Return
                  </button>
                </>
              )}
            </>
          )}
        </div>

        {!lease_return.completed_at && (
          <div className="footer">
            <button
              className={!can_edit || !change_made ? "disabled" : ""}
              id={`resetBtn`}
              disabled={!can_edit || !change_made}
              onClick={this.resetFields}
            >
              CANCEL
            </button>

            <button
              className={!can_edit || !change_made ? "disabled" : ""}
              id={`completeBtn`}
              disabled={!can_edit || !change_made}
              onClick={() => {
                return this.handleSave();
              }}
            >
              SAVE
            </button>
          </div>
        )}
      </>
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.lease_return) {
      const strippedLeaseReturn = DetailsComponentView.format(
        nextProps.lease_return
      );
      let same: boolean = true;
      if (!prevState.data) same = false;
      else {
        for (const item in strippedLeaseReturn) {
          if (strippedLeaseReturn[item].value !== prevState.data[item].value) {
            same = false;
            break;
          }
        }
      }

      if (!same) {
        const fieldsToReset = {};
        DetailsComponentView.includeFields.forEach((i) => {
          fieldsToReset[i.key] = null;
        });

        let groupResetFields = {};

        // If we're changing lease returns then reset the group settings
        if (nextProps.lease_return.id !== prevState.id) {
          groupResetFields = {
            customer_info: "hide",
            bank_info: "hide",
            other: "hide",
            completion: "hide",
            show_completion_group: false,
            move_to_inventory: true,
            pick_up_date: null,
            stock_number: "",
            selling_price: null,
          };
        }

        return {
          id: nextProps.lease_return.id,
          data: DetailsComponentView.format(nextProps.lease_return),
          change_made: false,
          saving: false,
          ...fieldsToReset,
          ...groupResetFields,
        };
      }
    }
    return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    toggleUABCallback: state.app.admin.toggleUABCallback,
    permissions: state.app.admin.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    universalActionCallback: () => {
      dispatch(triggerUABCallback(false));
    },
    disableUAB: () => {
      dispatch(enableUABCallback(false));
    },
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const DetailsComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.ILeaseReturnGQL, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.leaseReturnDetail,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            leaseReturnId: parseInt(props.detail.detail_id, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, lease_return_detail, refetch, fetchMore },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        // Since dispositions are pre-defined set add them to the props (array of options)
        const disposition_id = DetailsComponentView.getDispositionOptions();

        return {
          disposition_id,
          lease_return: lease_return_detail.lease_return,
          refetchDetail: refetch,
        };
      },
    }
  ),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.leaseReturnCompanies,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, leasing_companies, refetch, fetchMore },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          leasing_company: leasing_companies.leasing_companies,
          refetchCompanies: refetch,
        };
      },
    }
  ),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.leaseReturnStatuses,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, lease_return_statuses, refetch, fetchMore },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          lease_return_status: lease_return_statuses.lease_return_statuses,
          refetchStatuses: refetch,
        };
      },
    }
  ),
  graphql<LMI.IOrgSettingsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.settings.store,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            names: [
              "LEASE_RETURN_PROGRESS_METHOD",
              "LEASE_RETURN_ERROR_THRESHOLD_DAYS",
              "LEASE_RETURN_WARNING_THRESHOLD_DAYS",
            ],
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_settings, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        return {
          store_settings,
          refetch,
        };
      },
    }
  )
)(DetailsComponentView) as React.ComponentType<any>;

export default DetailsComponent;
