import { gqlQueries } from "gql-imports";
import * as Raven from "raven-js";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { addAlert } from "api/redux/actions";
import { Loader } from "semantic-ui-react";
import { DataFilterComponent } from "../../DataFilterComponent";
import { ModalComponent } from "../../ModalComponent";
import { getSortableEditable, SortableTable } from "../../SortableTable";
import StoreSettingsDetailComponent from "./components/StoreSettingsDetailComponent/StoreSettingsDetailComponent";
import { restAPI } from "../../../../../utils/rest";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";
import { Session } from "client/utils/session";

import "./StoreSettingsTabPanel.css";

@track(
  (props) => {
    return {
      event_type: AnalyticsEventType.SUBNAV,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }settings.overrides`,
    };
  },
  { dispatchOnMount: true }
)
export class StoreSettingsTabPanelView extends React.Component<
  LMI.IStoreSettingsTPProps,
  LMI.IStoreSettingsTPState
> {
  excludedSettings = [
    "DEFAULT_STAGE_NEW",
    "DEFAULT_STAGE_USED",
    "DEFAULT_RECON_LEVEL_USED",
  ];
  constructor(props) {
    super(props);
    this.state = {
      current_store: null,
      store_settings: null,
      raw_settings: null,
      mapped_settings: null,
      editStoreSetting: null,
      searchFilter: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.store_org_settings_admin !== nextProps.store_org_settings_admin
    ) {
      const stores = Session.get("stores");
      const current_store =
        nextProps.storeId &&
        stores.find((st) => st.store_id === nextProps.storeId);
      const settings =
        nextProps.store_org_settings_admin &&
        nextProps.store_org_settings_admin.length
          ? this.mapStoreSettings(nextProps.store_org_settings_admin)
          : null;
      this.setState({
        current_store,
        store_settings: settings && settings.mapped_settings,
        raw_settings: settings && settings.raw_settings,
      });
    }
  }

  render() {
    const { props } = this;
    const { current_store } = this.state;
    let { store_settings } = this.state;

    if (props.hasErrors) {
      console.log("Overrides Tab data errors:", props.message);
    }
    if (!props.store_org_settings_admin || props.loading) {
      return <Loader />;
    }

    const currentStoreName = current_store.store_name;
    const currentStoreOrgId = current_store.organization_id;

    if (this.state.searchFilter) {
      store_settings = this.searchFilter(
        this.state.searchFilter,
        store_settings
      );
    }

    const storeSettingProps: LMI.IStoreSettingsDetailsProps = {
      store_id: props.storeId,
      organization_id: currentStoreOrgId,
      store_setting: this.state.editStoreSetting,
      raw_settings: this.state.raw_settings,
      store_settings,
      // onCreate: (data, alerts) => this.callUpdateSetting(data),
      onSave: (data) => this.callUpdateSetting(data),
      onCalculateCurrentValue: this.formatSettingValue,
    };

    return (
      <div id="store-settings" className="panel-content">
        {this.state.editStoreSetting ? (
          <ModalComponent
            headerText={this.state.editStoreSetting.store_setting_type.label}
            shouldBeOpen={!!this.state.editStoreSetting}
            onClose={(evt, data) => {
              this.setState({ editStoreSetting: null });
            }}
            size="small"
            className="store-setting-details"
            contentComponent={() => (
              <StoreSettingsDetailComponent {...storeSettingProps} />
            )}
          />
        ) : (
          ""
        )}
        <div className="sortable-container">
          {store_settings.length > 0 || this.state.searchFilter ? (
            <div className="sortable-filter">
              <DataFilterComponent
                label="Filter Results"
                searchFilter={this.state.searchFilter}
                onChange={(p, { value }) => {
                  this.setState({
                    searchFilter: value,
                  });
                }}
              />
            </div>
          ) : (
            <span />
          )}
          <div className="sortable-content">
            {props.store_org_settings_admin ? (
              <SortableTable
                filter={this.state.searchFilter}
                message={
                  currentStoreName + " currently has no settings available."
                }
                tableData={this.getTableData(store_settings)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }

  searchFilter(search: string, data: any[]) {
    const srch = search.toLowerCase();
    return data.filter((setting) => {
      return (
        setting.name.toLowerCase().includes(srch) ||
        (setting.description &&
          setting.description.toString().toLowerCase().includes(srch))
      );
    });
  }

  formatChildSettings(setting: LMI.IStoreSetting): LMI.IStoreSettingsTableRow {
    let cSettings = [];
    let label = "";
    let names = [];
    if (setting.children) {
      try {
        cSettings = JSON.parse(setting.children.toString());
      } catch (error) {
        cSettings = [];
        Raven.captureException(error);
        console.error(
          `StoreSetting ID: ${setting.id} has a malformed child object, check the child column for errors.`,
          error
        );
      }

      if (cSettings.length > 1) {
        label = cSettings
          .map((child) => child.label)
          .toString()
          .replace(/,/g, ", ");
        names = cSettings.map((child) => child.name);
      } else if (cSettings.length > 0) {
        label = cSettings[0].label;
        names.push(cSettings[0].name);
      }
      return Object.assign({}, setting, {
        children: cSettings,
        children_label: label,
        child_setting_names: names,
      });
    } else {
      return setting;
    }
  }

  consolidateSettings(storeSettings: any[]): any {
    const settingObject = {};
    storeSettings.forEach((setting) => {
      const setting_count = settingObject[setting.name]
        ? settingObject[setting.name].setting_count + 1
        : 1;
      settingObject[setting.name] = {
        name: setting.name,
        store_id: this.props.storeId,
        organization_id:
          settingObject[setting.name] &&
          settingObject[setting.name].organization_id
            ? settingObject[setting.name].organization_id
            : setting.organization_id,
        store_setting_type: setting.store_setting_type,
        default_value:
          settingObject[setting.name] &&
          settingObject[setting.name].default_value
            ? settingObject[setting.name].default_value
            : !setting.store_id && !setting.organization_id
            ? setting.value
            : null,
        organization_override:
          settingObject[setting.name] &&
          settingObject[setting.name].organization_override
            ? settingObject[setting.name].organization_override
            : setting.organization_id && !setting.store_id
            ? setting.value
            : null,
        store_override:
          settingObject[setting.name] &&
          settingObject[setting.name].store_override
            ? settingObject[setting.name].store_override
            : setting.store_id
            ? setting.value
            : null,
        children:
          settingObject[setting.name] && settingObject[setting.name].children
            ? settingObject[setting.name].children
            : !!setting.children && setting.children,
        setting_count,
      };
    });
    return Object.keys(settingObject)
      .map((i) => settingObject[i])
      .filter((ms) => {
        const isExcluded = this.excludedSettings.includes(ms.name);
        return !isExcluded;
      });
  }

  formatSettingValue(setting: LMI.IStoreSetting) {
    const settingValue = setting.store_override
      ? setting.store_override
      : setting.organization_override
      ? setting.organization_override
      : setting.default_value;

    let formattedValue = settingValue;
    if (setting.store_setting_type.value_type === "STRING")
      try {
        const value = JSON.parse(settingValue);
        formattedValue = value
          .map((v) => `${v[0] ? v[0] : "0"}-${v[1] ? v[1] : "MAX"}`)
          .join(", ");
      } catch (e) {
        formattedValue = settingValue;
      }

    if (setting.name === "VEHICLE_INFO_REPORT_PAYWALL_LEVEL") {
      const yesNo = ["No", "Optional", "Mandatory"];
      formattedValue = yesNo[settingValue];
    }

    return formattedValue;
  }

  mapStoreSettings(storeSettings: any[]): LMI.IStoreSettingsMapped {
    const raw_settings = [];
    const mapped_settings = this.consolidateSettings(storeSettings)
      .filter((i) => i.store_setting_type.setting_type !== "CHILD")
      .map(
        (setting): LMI.IStoreSettingsTableRow => {
          const editSetting = this.formatChildSettings(setting);
          raw_settings.push(editSetting);
          const childrenLabel =
            editSetting.children.length > 0
              ? editSetting.children[0].label
              : null;
          const settingData = {
            tools: {
              component: getSortableEditable,
              editable: true,
              editcallback: () => {
                this.setState({
                  editStoreSetting: editSetting,
                });
              },
            },
            name: setting.store_setting_type.label,
            value: this.formatSettingValue(setting),
            override: setting.store_override
              ? "Store"
              : setting.organization_override
              ? "Organization"
              : "Default",
            children: childrenLabel,
          };
          return settingData;
        }
      );
    return { mapped_settings, raw_settings };
  }

  getTableData(storeSettings: any) {
    const headers: any = [
      {
        id: "tools",
        label: "",
        sortable: false,
        collapsing: true,
      },
      {
        id: "name",
        label: "Name",
        sortable: true,
      },
      {
        id: "value",
        label: "Current Value",
        sortable: true,
      },
      {
        id: "override",
        label: "Setting Level",
        sortable: true,
      },
      {
        id: "children",
        label: "Child Settings",
        sortable: true,
      },
    ];
    const sortableTableData: LMI.ITableData = {
      headers,
      body: {
        rows: storeSettings,
      },
    };
    return sortableTableData;
  }

  callUpdateSetting(data: LMI.IStoreSetting) {
    data.index.forEach((store, index) => {
      if (data.store_id && data.store && data.store[index] != null) {
        restAPI({
          endpointName: "setStoreSettings",
          urlArgs: [data.store_id],
          data: { name: data.name[index], value: data.store[index] },
          callback: (err, res) => {
            if (err) {
              this.props.addAlert({
                type: "danger",
                message: err.reason.response.data.message,
                timeout: 3000,
              });
            } else {
              // console.log(`setStoreSettings - Result: ${JSON.stringify(res)}`);
            }
            this.props.refetch();
          },
        });
      }

      if (
        data.organization_id &&
        data.organization &&
        data.organization[index] != null
      ) {
        restAPI({
          endpointName: "setOrgSettings",
          urlArgs: [data.organization_id],
          data: { name: data.name[index], value: data.organization[index] },
          callback: (err, res) => {
            if (err) {
              this.props.addAlert({
                type: "danger",
                message: err.reason.response.data.message,
                timeout: 3000,
              });
            } else {
              // console.log(`setOrgSettings - Result: ${JSON.stringify(res)}`);
            }
            this.props.refetch();
          },
        });
      }
    });
    this.setState({ editStoreSetting: null });
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    permissions: state.app.admin.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const StoreSettingsTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IStoreSettingsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.settings_org.store,
    {
      options: (props: any) => {
        const storeId = props.storeId
          ? props.storeId
          : localStorage.getItem("selectedStoreId");
        return {
          variables: {
            storeId: parseInt(storeId, 10),
            includeDefaults: true,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, store_org_settings_admin, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          store_org_settings_admin,
          refetch,
        };
      },
    }
  )
)(StoreSettingsTabPanelView);
