import { Grid } from "semantic-ui-react";
import { RelativeDatePipe } from "../../utils/functions";
import * as React from "react";

import "./comments.css";

export const Comments = (props: LMI.IComments) => {
	const { less_descriptive, is_timestamp, include_time, data } = props;
	return (
		<div className="comments">
			<Grid className="list">
				{data.map((item, index) => {
					return (
						<>
							<div className="item" key={index}>
								<div className="comment">
									<div className="image">
										<img src={item.user_photo_url} />
									</div>
									<div className="text">
										<div>{item.comment}</div>
									</div>
								</div>
							</div>
							<div className="when-who">
								{item.description && `${item.description} - `}
								{RelativeDatePipe(
									item.created_at,
									less_descriptive ? less_descriptive : false,
									is_timestamp ? is_timestamp : true,
									include_time ? include_time : false
								)}{" "}
								- {item.first_name} {item.last_name}
							</div>
						</>
					);
				})}
			</Grid>
		</div>
	);
};
