import { AnalyticsEventType, AnalyticsSourceApp, API } from "loopmein-shared";
import * as Raven from "raven-js";
import { base64, camelToSnake } from "./functions";
import * as platform from "platform";
import { Session } from "client/utils/session";

// test
interface RestAPIParams {
  endpointName: string;
  urlArgs: any;
  data: object;
  callback: any;
  defaultErrorMessage?: string;
}

interface RestAPIDirectParams {
  fullEndpoint: string;
  method: string;
  data: object;
  callback: any;
  defaultErrorMessage?: string;
}

interface RestAPIAnalyticsParams {
  authorization: string;
  url: string;
  method: string;
  data: any;
}

/**
 * We have a couple of public endpoints, identify them here until they are refactored
 * @param endpointName
 */
const lookupPublicEndpoint = (endpointName) => {
  // ToDo: consider adding a public endpoint to lookup public endpoints
  switch (endpointName) {
    case "login_user":
      return { name: "login_user", url: "/login", method: "post" };
    case "forgot_user_password":
      return {
        name: "forgot_user_password",
        url: "/forgot-password",
        method: "post",
      };
    case "reset_user_password":
      return {
        name: "reset_user_password",
        url: "/password-reset",
        method: "post",
      };
    default:
      return null;
  }
};

/**
 * restAPI for all REST related API calls (mostly for mutations since we use GQL for GET queries.
 * @param endpointName
 * @param urlArgs
 * @param data
 * @param callback
 * @param defaultErrorMessage
 */
export const restAPI = async ({
  endpointName,
  urlArgs,
  data,
  callback,
  defaultErrorMessage,
}: RestAPIParams) => {
  const restUrl = process.env.REACT_APP_REST_URL;
  const authToken = localStorage.getItem("token");

  try {
    await API.go({
      publicEndpoint: lookupPublicEndpoint(endpointName),
      restUrl,
      endpointName,
      token: authToken,
      urlArgs,
      data,
      callback,
      defaultErrorMessage, // Add a specific override for the error message
      debug: false,
    });
    // Capture all API calls in tracking
    restAPIAnalytics({
      authorization: process.env.REACT_APP_ANALYTICS_TOKEN,
      url: process.env.REACT_APP_ANALYTICS_URL,
      method: "POST",
      data: {
        ...data,
        detail: { ...data, ...urlArgs },
        event_type: AnalyticsEventType.API,
        event_subtype: camelToSnake(endpointName),
      },
    });
  } catch (error) {
    Raven.captureException(error);
    callback(new Error(error));
  }
};

/**
 * restAPI for all direct API calls (skips the client_endpoints DB lookup.  Requires a full path endpoint.)
 * @param fullEndpoint
 * @param method
 * @param data
 * @param callback
 */
export const restAPIDirect = async ({
  fullEndpoint,
  method,
  data,
  callback,
  defaultErrorMessage,
}: RestAPIDirectParams) => {
  try {
    await API.goDirect({
      fullEndpoint,
      method,
      data,
      callback,
      defaultErrorMessage,
    });
  } catch (error) {
    Raven.captureException(error);
    callback(new Error(error));
  }
};

/**
 * restAPI for all direct API calls (skips the client_endpoints DB lookup.  Requires a full path endpoint.)
 * @param fullEndpoint
 * @param method
 * @param data
 * @param callback
 */
export const restAPIAnalytics = async ({
  authorization,
  url,
  method,
  data,
}: RestAPIAnalyticsParams) => {
  try {
    const amendedData = {
      ...cleanCreds(data),
      email: data.email ? data.email : Session.get("email"),
      client_type: "web",
      client_version: `${platform.name}:${platform.version}:${platform.layout}`,
      store_id: localStorage.getItem("selectedStoreId"),
      proxied_as: localStorage.getItem("proxied_as"),
      client_timestamp: Math.round(new Date().getTime() / 1000), // This is used to deduplicate down to 1 second increments
      source_app: AnalyticsSourceApp.WEB,
      environment:
        process.env.REACT_APP_ENVIRONMENT === "live"
          ? "production"
          : "development",
    };
    await API.goGeneric({
      authorization: `Basic ${base64({
        string: authorization,
        mode: "encode",
      })}`,
      url,
      method,
      data: amendedData,
      debug: false,
    });
  } catch (error) {
    Raven.captureException(error);
  }
};

const cleanCreds = (dirty) => {
  const clean = { ...dirty };
  const { data, detail, password } = clean;
  if (password) clean.password = "********";
  if (data && data.password) clean.data.password = "********";
  if (detail && detail.password) clean.detail.password = "********";
  return clean;
};

/** Local version of endpoints code for testing
 * Uncomment this section when testing
 */

// interface IRestAPIError {
// 	error: any;
// 	defaultErrorMessage: string;
// }
//
// /**
//  * Endpoint error handling
//  * @param error
//  * @param defaultMessage
//  */
// const extractError = ({ error, defaultErrorMessage }: IRestAPIError) => {
// 	return error && error.response && error.response.data && error.response.data.message ? error.response.data : defaultErrorMessage;
// };
//
// /**
//  * Validate using the configured validator in client_endpoints table
//  * @param data
//  * @param validationObject
//  */
// const validate = async ({ data, validator }) => {
// 	return new Promise(async (resolve, reject) => {
// 		try {
// 			const validated = await YUP.validate(validator, data);
// 			console.log("Validation Succeeded. Return value:", validated);
// 			resolve(validated);
// 		} catch (error) {
// 			console.error(error);
// 			reject(error);
// 		}
// 	});
// };
//
// /**
//  * Lookup Validation object based on a dot delimited path
//  * @param validationAuthObject
//  * @param path
//  */
// const getValidator = (validationAuthObject, path) => {
// 	if (!path) return validationAuthObject;
// 	const properties = path.split(".");
// 	const prop = properties.shift();
// 	if (!validationAuthObject.hasOwnProperty(prop)) {
// 		throw new Error("Bad validation path.");
// 	}
// 	return getValidator(validationAuthObject[prop], properties.join("."));
// };
//
// /**
//  * Make API call and callback with result || error to match the Meteor.method signature
//  * @param token
//  * @param url
//  * @param method
//  * @param validationObject
//  * @param data
//  * @param callback
//  * @param defaultErrorMessage
//  * @param debug
//  */
// export const makeCall = async ({ token, url, method, validationObject = null, data, callback, defaultErrorMessage, debug }) => {
// 	try {
// 		// tslint:disable-next-line:one-variable-per-declaration
// 		let validator, validated;
// 		// No validator configured continue without validation
// 		if (validationObject) {
// 			validator = getValidator(Validation.Auth, validationObject);
// 			if (validator) {
// 				// if validation fails, we'll callback with a validation error
// 				validated = await validate({ data, validator });
// 			}
// 		}
//
// 		if (debug) console.log("Validator: ", method, url, validator);
//
// 		const options = getOptions(method, url, token, validated ? validated : data);
//
// 		if (debug) console.log("Call options: ", options);
//
// 		const callResult = await axios(options);
//
// 		if (debug) console.log("Call Result: ", callResult);
//
// 		callback(null, callResult);
// 	} catch (error) {
// 		const extractedError = extractError({ error, defaultErrorMessage });
// 		console.error("Caught error: " + extractedError.message);
// 		callback(extractedError);
// 	}
// };
//
// /**
//  * Axios options getter
//  * @param method
//  * @param url
//  * @param token
//  * @param data
//  */
// const getOptions = (method: string, url: string, token: string = null, data: any = null): any => {
// 	const options = {
// 		method,
// 		url
// 	};
// 	if (token) options["headers"] = { Authorization: `JWT ${token}` };
// 	if (data) options["data"] = data;
// 	return options;
// };
//
// /**
//  * Construct the url for the endpoint by replacing the arg tokens with the passed in args.
//  * @param restUrl
//  * @param path
//  * @param urlArgs
//  */
// const constructUrl = ({ restUrl, path, urlArgs }) => {
// 	const pathArray = path.split("?");
// 	let url = `${restUrl}`;
// 	pathArray.forEach((item, index) => {
// 		url += item + (urlArgs && urlArgs[index] ? urlArgs[index] : "");
// 	});
// 	return url;
// };
//
// interface IGoArgs {
// 	publicEndpoint?: { name: string; url: string; method: string };
// 	restUrl: string;
// 	endpointName: string;
// 	token: string;
// 	urlArgs: any;
// 	data?: object;
// 	callback?: any;
// 	defaultErrorMessage?: string;
// 	debug?: boolean;
// }
//
// /**
//  * API call based on pre-defined endpoints
//  * @param publicEndpoint
//  * @param restUrl
//  * @param endpointName
//  * @param token
//  * @param urlArgs
//  * @param data
//  * @param callback
//  * @param defaultErrorMessage
//  * @param debug
//  */
// const go = async ({ publicEndpoint, restUrl, endpointName, token, urlArgs, data, callback, defaultErrorMessage, debug = false }: IGoArgs) => {
// 	// convert camel case to snake case (for backward compat with meteor method names
// 	const path = endpointName.replace(/([A-Z])/g, "_$1").toLowerCase();
// 	// tslint:disable-next-line:one-variable-per-declaration
// 	let endpoint, validationObject;
//
// 	// Endpoint lookup requires authorized user token, use publicEndpoint to hit public endpoints
// 	if (!publicEndpoint) {
// 		const options = getOptions("post", `${restUrl}/auth/endpoints`, token, {
// 			name: path
// 		});
//
// 		if (debug) console.log("GO ------> Endpoint Options: ", options);
//
// 		const clientEndpoint = await axios(options);
// 		endpoint = clientEndpoint && clientEndpoint.data ? clientEndpoint.data.endpoints[0] : null;
// 		validationObject = endpoint && endpoint.validator ? endpoint.validator : null;
// 	} else {
// 		endpoint = publicEndpoint;
// 	}
//
// 	if (debug) console.log("Endpoint: ", endpoint);
//
// 	if (!endpoint) return false;
// 	else {
// 		await makeCall({
// 			token,
// 			url: constructUrl({ restUrl, path: endpoint.url, urlArgs }),
// 			method: endpoint.method,
// 			validationObject,
// 			data,
// 			callback,
// 			defaultErrorMessage,
// 			debug
// 		});
// 	}
// 	return true;
// };
//
// /**
//  * Make generic rest call with authorization
//  * @param authorization (Authorization: Basic abc123)
//  * @param url
//  * @param method
//  * @param data
//  * @param debug
//  */
// export const goGeneric = async ({ authorization, url, method, data, debug = false }) => {
// 	try {
// 		const options = {
// 			method,
// 			url,
// 			headers: { Authorization: authorization },
// 			data
// 		};
//
// 		if (debug) console.log("Call options: ", options);
//
// 		const callResult = await axios(options);
//
// 		if (debug) console.log("Call Result: ", callResult);
// 		return callResult;
// 	} catch (error) {
// 		console.error("Caught error: " + String(error));
// 	}
// };
//
// interface IGoDirectArgs {
// 	fullEndpoint: string;
// 	method: string;
// 	data?: object;
// 	callback?: any;
// 	defaultErrorMessage?: string;
// 	debug?: boolean;
// }
//
// /**
//  * API call to a specific endpoint
//  * @param fullEndpoint
//  * @param method
//  * @param data
//  * @param callback
//  * @param defaultErrorMessage
//  * @param debug
//  */
// const goDirect = async ({
// 	fullEndpoint,
// 	method,
// 	data,
// 	callback,
// 	defaultErrorMessage = `An API endpoint error occurred on ${fullEndpoint}. Please contact the administrator.`,
// 	debug = false
// }: IGoDirectArgs) => {
// 	if (!fullEndpoint) return false;
// 	else
// 		await makeCall({
// 			token: null,
// 			url: fullEndpoint,
// 			method,
// 			data,
// 			callback,
// 			defaultErrorMessage,
// 			debug
// 		});
// 	return true;
// };
