import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { GlobalDispatch, GlobalDispatchKeys } from "api/redux/actions";

// react hook for getting redux state properties and dispatch properties
export const useGlobals = (props: any = null): LMI.IAdminReduxState => {
	const dispatch = useDispatch();
	const stateProps = useSelector((state: LMI.IAdminReduxGlobalState) => state.app.admin, shallowEqual);
	const dispatchProps = {};

	const dispatchKeys = Object.values(GlobalDispatchKeys);
	if (dispatchKeys)
		for (const key in dispatchKeys) {
			if (Object.hasOwnProperty.call(dispatchKeys, key)) {
				const action = dispatchKeys[key];
				dispatchProps[action] = state => dispatch(GlobalDispatch[action](state));
			}
		}

	return { ...props, ...stateProps, ...dispatchProps };
};
