// node_modules
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { gqlQueries } from "gql-imports";
import { Redirect } from "react-router";
// components
import { StoreType } from "loopmein-shared";
import { Menu, Tab } from "semantic-ui-react";
import {
	AppSettingsComponent,
	DocumentsComponent,
	OrganizationComponent,
	RolesPermissionsComponent,
	ServicesComponent,
	TitlesAlertsComponent,
	UsersComponent
} from "./components";
import { getStoreTypeOptions } from "client/utils/functions";
import "./SuperUserAdminTabs.css";

export class SuperUserAdminTabsView extends React.Component<any, LMI.SUAdminTabsState> {
	constructor(props) {
		super(props);

		this.state = {
			roleType: StoreType.Dealership,
			selectedTab: localStorage.getItem("ssutab") ? localStorage.getItem("ssutab") : "0",
			panelHeight: null
		};
	}

	handleTabChange = (e, { activeIndex }) => {
		localStorage.setItem("ssutab", activeIndex);
		this.setState({ selectedTab: activeIndex });
	};

	render() {
		let renderContent = null;
		const storeTypes = getStoreTypeOptions();
		const tabProps: any = {
			su_stores: this.props.su_stores,
			refetchStores: this.props.refetchStores,
			orgs: this.props.orgs,
			store_type: this.state.roleType,
			storeTypes
		};

		const settings = [
			{
				menuItem: "Organizations",
				render: () => (
					<Tab.Pane className="su-panel-content">
						<OrganizationComponent {...tabProps} />
					</Tab.Pane>
				)
			},
			{
				menuItem: "Users",
				render: () => (
					<Tab.Pane className="su-panel-content">
						<UsersComponent {...tabProps} />
					</Tab.Pane>
				)
			},
			{
				menuItem: "Roles and Permissions",
				render: () => (
					<Tab.Pane className="su-panel-content">
						<Menu secondary>
							<Menu.Item header>Store Types:</Menu.Item>
							{storeTypes.map(type => {
								return (
									<Menu.Item key={type.key} name="dealer" active={this.state.roleType === type.value} onClick={() => this.setState({ roleType: type.value })}>
										{type.text}
									</Menu.Item>
								);
							})}
						</Menu>
						<RolesPermissionsComponent {...tabProps} />
					</Tab.Pane>
				)
			},
			{
				menuItem: "Titles and Alerts",
				render: () => (
					<Tab.Pane className="su-panel-content">
						<Menu secondary>
							<Menu.Item header>Store Types:</Menu.Item>
							{storeTypes.map(type => {
								return (
									<Menu.Item key={type.key} name="dealer" active={this.state.roleType === type.value} onClick={() => this.setState({ roleType: type.value })}>
										{type.text}
									</Menu.Item>
								);
							})}
						</Menu>
						<TitlesAlertsComponent {...tabProps} />
					</Tab.Pane>
				)
			},
			{
				menuItem: "App Settings",
				render: () => (
					<Tab.Pane className="su-panel-content">
						<AppSettingsComponent />
					</Tab.Pane>
				)
			},
			{
				menuItem: "Vendor Services",
				render: () => (
					<Tab.Pane className="su-panel-content">
						<ServicesComponent />
					</Tab.Pane>
				)
			},
			{
				menuItem: "Documents",
				render: () => (
					<Tab.Pane className="su-panel-content">
						<DocumentsComponent />
					</Tab.Pane>
				)
			}
		];

		if (localStorage.getItem("isu") === "false") {
			// if the user isnt a super admin redirect them
			renderContent = <Redirect to="/" push />;
		} else {
			renderContent = (
				<div id="super-admin-tabs">
					<Tab activeIndex={this.state.selectedTab} onTabChange={this.handleTabChange} panes={settings} renderActiveOnly />
				</div>
			);
		}
		return renderContent;
	}

	getElemHeight(elem: Element) {
		return window.getComputedStyle(elem).height;
	}

	setpanelheight() {
		// the body height minus the header
		const container = document.querySelector("#app");
		const tabContainer = document.querySelector("#super-admin-tabs");
		const containHeight = this.getElemHeight(container);
		const tabHeight = Number(containHeight.substr(0, containHeight.length - 2)) - 150;
		tabContainer.setAttribute("style", `height: ${tabHeight}px`);

		// set tab container height to be containers height minus padding
		const panel = document.querySelector(".ui.tab.active");
		const panelHeight = tabHeight - 45;
		panel.setAttribute("style", `height: ${panelHeight}px; overflow-y: auto; overflow-x: hidden;`);

		// optional inner panel height for the panels inner elements (passed as props) without "px"
		const innerPanelHeight = panelHeight - 20;
		this.setState({
			panelHeight: innerPanelHeight.toString()
		});
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const SuperUserAdminTabs = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.OrgsAdminGQL, any, any, ClassAttributes<any>>(gqlQueries.super.organizations, {
		options: (props: any) => {
			return {
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, su_organizations, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				orgs: {
					su_organizations,
					refetch
				}
			};
		}
	}),
	graphql<LMI.StoresAdminGQL, any, any, ClassAttributes<any>>(gqlQueries.super.stores, {
		options: (props: any) => {
			return {
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, su_stores, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				su_stores,
				refetchStores: refetch
			};
		}
	})
)(SuperUserAdminTabsView) as React.ComponentType<any>;

export default SuperUserAdminTabs;
