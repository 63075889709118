import * as React from "react";
import { Button, Image, Label } from "semantic-ui-react";
import "./StorePhotosComponent.css";

export class StorePhotosViewer extends React.Component<LMI.IStorePhotosComponentProps, LMI.IStorePhotosComponentState> {
	constructor(props: LMI.IStorePhotosComponentProps) {
		super(props);
		this.state = {
			active: props.active ? props.store_photos.findIndex(p => p.full_url === props.active.full_url) : 0,
			storePhotos: props.store_photos,
			thumbFormat: props.thumb_format ? props.thumb_format : "",
			mainFormat: props.main_image_format ? props.main_image_format : ""
		};
	}

	render() {
		const { active, storePhotos } = this.state;
		const { store_photos, setPrimary, onDelete, onClose, canEdit } = this.props;
		const activePhoto = storePhotos[active];
		return (
			<div id="store-photos-viewer">
				<Image.Group>
					{activePhoto ? (
						<span className="img-tile active">
							<div className="tool-box">
								{canEdit &&
									(!activePhoto.is_primary ? (
										<Button
											size="small"
											content="Make Primary"
											icon="thumbtack"
											color="orange"
											className="left"
											onClick={() => {
												const newStorePhotes = store_photos.map(photo => {
													return { ...photo, ...{ is_primary: photo.id === activePhoto.id ? true : false } };
												});
												this.setState({ storePhotos: newStorePhotes });
												setPrimary(activePhoto);
											}}
										/>
									) : (
										<Label icon={{ name: "thumbtack", color: "orange" }} className="left" content="Primary Store Photo" size="large" />
									))}
								<span className="tally">{`${active + 1}/${storePhotos.length} Photos`}</span>
								{canEdit && (
									<Button
										size="small"
										content="Delete"
										color="red"
										className="right"
										onClick={() => {
											if (store_photos.length < 0) {
												onDelete(activePhoto);
												onClose();
												return;
											}
											this.setState({
												storePhotos: store_photos
													.filter(ph => ph.id !== activePhoto.id)
													.map(photo => {
														return photo;
													}),
												active: 0
											});
											onDelete(activePhoto);
										}}
									/>
								)}
							</div>
							<Image src={activePhoto.full_url + this.state.mainFormat} loading="lazy" />
						</span>
					) : (
						""
					)}
					{storePhotos.length > 1
						? storePhotos.map((photo, index) => {
								return (
									<span key={index} className="img-tile">
										{active === index && <div className="overlay" />}
										<Image
											src={photo.full_url + this.state.thumbFormat}
											label={photo.is_primary ? { color: "orange", corner: "right", icon: "thumbtack" } : false}
											onClick={() => this.setState({ active: index })}
										/>
									</span>
								);
						  })
						: ""}
				</Image.Group>
			</div>
		);
	}
}

export default StorePhotosViewer;
