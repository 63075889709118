import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { Form, Input } from "formsy-semantic-ui-react";
import {
  Button,
  Card,
  Dropdown,
  Grid,
  Icon,
  Label,
  List,
} from "semantic-ui-react";
import {
  AddGroupComponent,
  AddStoreComponent,
  GroupInfoComponent,
  PrimaryContactComponent,
  StoreInfoComponent,
} from "../";
import {
  getStoreTypeAdminRoleID,
  handleErrorResponse,
  hashLinkScroll,
  objectArrayDiff,
} from "../../../../../utils/functions";
import { addAlert } from "api/redux/actions";
import { restAPI } from "../../../../../utils/rest";

import "./OrganizationComponent.css";

export class OrganizationComponentView extends React.Component<
  LMI.SUAdminProps,
  LMI.SUAdminState
> {
  constructor(props) {
    super(props);

    this.state = {
      addingGroup: false,
      addingStore: false,
      selectedGroup: null,
      selectedStore: null,
      groupfilter: null,
      storefilter: null,
      groupActive: true,
      stateActive: true,
      storeTypeFilter: 0,
      setPrimary: null,
      activateGroup: false,
    };
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<LMI.SUAdminProps>,
    nextContext: any
  ): void {
    if (
      this.state.addingGroup &&
      this.props.orgs.su_organizations.length <
        nextProps.orgs.su_organizations.length
    ) {
      const newKey = objectArrayDiff(
        this.props.orgs.su_organizations,
        nextProps.orgs.su_organizations
      );
      this.selectGroup(nextProps.orgs.su_organizations[newKey], newKey);
      this.setState({ addingGroup: false });
    }
  }

  render() {
    const organizations = this.props.orgs && this.props.orgs.su_organizations;
    const stores = this.props.su_stores && this.props.su_stores;
    const storeId = {
      id: this.state.selectedStore,
      organizations: this.props.orgs && this.props.orgs.su_organizations,
    };
    const groupInfo = { group: this.state.selectedGroup };
    const activeFilterOptions = ["Active", "Inactive"].map((v, i) => ({
      key: v,
      text: v,
      value: i === 0 ? "true" : "false",
    }));

    // props for add form components
    const addGroupProps: LMI.SUAdminAddGroupProps = {
      storeTypes: this.props.storeTypes,
      nevermind: () => this.setState({ addingGroup: false }),
      create_group: (data: any) => this.addGroup(data),
    };

    const addStoreProps = {
      nevermind: () => this.setState({ addingStore: false }),
      create_state: (data: any) => this.addStore(data),
      organizations: this.props.orgs && this.props.orgs.su_organizations,
      selectedOrg: this.state.selectedGroup ? this.state.selectedGroup : null,
      storeType: this.state.selectedGroup
        ? stores &&
          stores.find(
            (store) => store.organization_id === this.state.selectedGroup.id
          )
        : null,
      storeTypes: this.props.storeTypes,
    };

    const primaryProps = {
      store_id: this.state.setPrimary && this.state.setPrimary.store_id,
      info_screen: false,
      nevermind: () => {
        this.setState({
          addingStore: false,
          setPrimary: false,
        });
      },
      callback: (formData) => this.savePrimaryContact(formData),
    };

    const listHeight = this.props.panelheight
      ? Number(this.props.panelheight) - 210
      : 500;
    const listselHeight =
      this.state.selectedGroup || this.state.selectedStore
        ? listHeight + 35
        : listHeight;

    // check if we can activate a group or store
    let activation: any = { can: false };
    if (stores && (this.state.selectedGroup || this.state.selectedStore)) {
      if (this.state.selectedStore) {
        const store = stores.find((s) => s.id === this.state.selectedStore);
        activation = {
          can: !store || store.activated_at === null ? true : false,
          name: store.name,
          storeIds: [store.id],
        };
      }
      if (this.state.selectedGroup && !this.state.selectedStore) {
        const groupStores = stores
          .filter(
            (s) =>
              s.organization_id === this.state.selectedGroup.id &&
              s.activated_at === null
          )
          .map((store) => store.id);
        activation = {
          can: groupStores && groupStores.length ? true : false,
          name: this.state.selectedGroup.name,
          storeIds: groupStores,
        };
      }
    }

    return (
      <div id="organizations" className="su-admin-page page">
        <Grid>
          <Grid.Column width={6}>
            {this.state.addingGroup ? (
              <AddGroupComponent {...addGroupProps} />
            ) : (
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    {organizations &&
                      !this.state.groupfilter &&
                      organizations.find((org) => org.is_active === false) && ( // if there are any inactive then show the filtering
                        <span className="filter-active">
                          Show{" "}
                          <Dropdown
                            inline
                            options={activeFilterOptions}
                            value={this.state.groupActive.toString()}
                            onChange={(e, data) =>
                              this.setState({
                                groupActive:
                                  data.value === "true" ? true : false,
                              })
                            }
                          />
                        </span>
                      )}
                    Organizations
                    <Form>
                      <Input
                        name="groupfilter"
                        className="filterinput"
                        placeholder="Filter All Organizations"
                        value={this.state.groupfilter}
                        icon={
                          this.state.groupfilter ? (
                            <Icon
                              name="close"
                              color="red"
                              link
                              onClick={() =>
                                this.setState({ groupfilter: null })
                              }
                            />
                          ) : (
                            false
                          )
                        }
                        onChange={(e, data) => {
                          this.setState({ groupfilter: data.value });
                        }}
                      />
                    </Form>
                  </Card.Header>
                </Card.Content>
                <Card.Content>
                  <List
                    divided
                    selection
                    size="large"
                    className="cardList"
                    style={{ height: listHeight.toString() + "px" }}
                  >
                    {organizations &&
                      organizations
                        .filter((org) =>
                          this.state.groupfilter
                            ? org.name
                                .toLowerCase()
                                .indexOf(
                                  this.state.groupfilter.toLowerCase()
                                ) !== -1
                            : org
                        )
                        .filter((org) =>
                          !this.state.groupfilter
                            ? org.is_active === this.state.groupActive
                            : org
                        )
                        .map((org, index) => {
                          return (
                            <List.Item
                              key={`${index}`}
                              id={`${index}`}
                              active={
                                this.state.selectedGroup &&
                                this.state.selectedGroup.id === org.id
                                  ? true
                                  : false
                              }
                              onClick={() => this.selectGroup(org, null)}
                            >
                              <List.Content floated="right">
                                <Label
                                  size="tiny"
                                  color={org.is_active ? "green" : "red"}
                                >
                                  {org.is_active ? "Active" : "Inactive"}
                                </Label>
                              </List.Content>
                              <List.Content>
                                <span>{org.name}</span>
                              </List.Content>
                            </List.Item>
                          );
                        })}
                  </List>
                </Card.Content>
                <Card.Content extra>
                  <Button
                    icon
                    primary
                    onClick={() => this.setState({ addingGroup: true })}
                  >
                    <Icon name="add" />
                    Add New Organization
                  </Button>
                </Card.Content>
              </Card>
            )}
          </Grid.Column>
          <Grid.Column width={10}>
            {this.state.addingStore ? (
              <span>
                {this.state.setPrimary ? (
                  <PrimaryContactComponent {...primaryProps} />
                ) : (
                  <AddStoreComponent {...addStoreProps} />
                )}
              </span>
            ) : (
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    {!this.state.selectedStore &&
                      !this.state.selectedGroup &&
                      !this.state.storefilter &&
                      stores &&
                      stores.find((store) => store.is_active === false) && (
                        <span className="filter-active">
                          Show{" "}
                          <Dropdown
                            inline
                            options={activeFilterOptions}
                            value={this.state.stateActive.toString()}
                            onChange={(e, data) =>
                              this.setState({
                                stateActive:
                                  data.value === "true" ? true : false,
                              })
                            }
                          />
                        </span>
                      )}
                    {!this.state.selectedStore &&
                      !this.state.selectedGroup &&
                      !this.state.storefilter &&
                      stores && (
                        <span className="filter-active last">
                          Show{" "}
                          <Dropdown
                            inline
                            options={[
                              ...[{ key: "all", text: "All", value: 0 }],
                              ...this.props.storeTypes,
                            ]}
                            value={this.state.storeTypeFilter}
                            onChange={(e, data) => {
                              this.setState({ storeTypeFilter: data.value });
                            }}
                          />{" "}
                          |
                        </span>
                      )}
                    {!this.state.selectedStore && !this.state.selectedGroup
                      ? "Stores "
                      : ""}
                    {!this.state.selectedStore && !this.state.selectedGroup ? (
                      <Form>
                        <Input
                          name="storefilter"
                          className="filterinput"
                          placeholder="Filter All Stores"
                          value={this.state.storefilter}
                          icon={
                            this.state.storefilter ? (
                              <Icon
                                name="close"
                                color="red"
                                link
                                onClick={() =>
                                  this.setState({ storefilter: null })
                                }
                              />
                            ) : (
                              false
                            )
                          }
                          onChange={(e, data) => {
                            this.setState({ storefilter: data.value });
                          }}
                        />
                      </Form>
                    ) : (
                      <span>
                        <Label
                          as="a"
                          size="large"
                          onClick={() => {
                            this.setState({
                              selectedStore: null,
                              selectedGroup: null,
                            });
                          }}
                        >
                          <Icon name="arrow left" /> View All Stores
                        </Label>
                        <span style={{ float: "right" }}>
                          {activation.can ? (
                            <Label
                              as="a"
                              size="large"
                              color="green"
                              onClick={() => {
                                this.activate(activation.storeIds);
                              }}
                            >
                              <Icon name="send" /> Activate
                            </Label>
                          ) : (
                            <span className="activatedTag">
                              <Icon name="check circle" color="green" />{" "}
                              Activated
                            </span>
                          )}
                        </span>
                      </span>
                    )}
                  </Card.Header>
                </Card.Content>
                <Card.Content>
                  <List
                    divided
                    selection
                    size="large"
                    className="cardList"
                    style={{ height: listselHeight.toString() + "px" }}
                  >
                    {this.state.selectedGroup && !this.state.selectedStore ? (
                      <GroupInfoComponent {...groupInfo} />
                    ) : (
                      ""
                    )}
                    {!this.state.selectedStore && stores ? (
                      stores
                        .filter((store) =>
                          this.state.selectedGroup
                            ? store.organization_id ===
                              this.state.selectedGroup.id
                            : this.state.storefilter
                            ? store.name
                                .toLowerCase()
                                .indexOf(
                                  this.state.storefilter.toLowerCase()
                                ) !== -1
                            : store
                        )
                        .filter((store) =>
                          !this.state.selectedGroup && !this.state.storefilter
                            ? store.is_active === this.state.stateActive
                            : store
                        )
                        .filter((store) =>
                          !this.state.selectedGroup &&
                          !this.state.storefilter &&
                          this.state.storeTypeFilter !== 0
                            ? store.store_type_id === this.state.storeTypeFilter
                            : store
                        )
                        .map((store, index) => {
                          return (
                            <List.Item
                              key={index}
                              onClick={() => {
                                this.setState({
                                  selectedStore: store.id,
                                });
                              }}
                            >
                              <List.Content floated="right">
                                <Label
                                  size="tiny"
                                  color={store.is_active ? "green" : "red"}
                                >
                                  {store.is_active ? "Active" : "Inactive"}
                                </Label>
                              </List.Content>
                              <List.Content floated="right">
                                <Label size="tiny">
                                  {this.props.storeTypes &&
                                    this.props.storeTypes.find(
                                      (s) => s.value === store.store_type_id
                                    ).text}
                                </Label>
                              </List.Content>
                              <List.Content>
                                <span>{store.name}</span>
                              </List.Content>
                            </List.Item>
                          );
                        })
                    ) : (
                      <span>
                        {this.state.selectedStore && (
                          <StoreInfoComponent {...storeId} />
                        )}
                      </span>
                    )}
                  </List>
                </Card.Content>
                <Card.Content extra>
                  <Button
                    icon
                    primary
                    onClick={() => this.setState({ addingStore: true })}
                  >
                    <Icon name="add" />
                    Add Store
                  </Button>
                </Card.Content>
              </Card>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  handleCallResponse(err, res) {
    const alert = {
      type: err ? "danger" : "success",
      message: err ? handleErrorResponse({ error: err }) : res.data.message,
    };
    this.sendAlert(alert);
  }

  sendAlert({ type, message }) {
    this.props.addAlert({
      type,
      message,
      timeout: 3000,
    });
  }

  activate(storeIds: number[]) {
    restAPI({
      endpointName: "su_activate_stores",
      urlArgs: storeIds,
      data: null,
      callback: (err, res) => {
        if (!err) {
          this.props.refetchStores();
        }
      },
    });
  }

  selectGroup(group: any, index: number) {
    // Only scroll if an index is given
    if (index) {
      hashLinkScroll(index.toString(), 184);
    }
    this.setState({
      selectedStore: null,
      selectedGroup: group,
    });
  }

  addGroup(data: any) {
    restAPI({
      endpointName: "suAddOrganization",
      urlArgs: null,
      data,
      callback: (err, res) => {
        this.handleCallResponse(err, res);
        if (!err) this.props.orgs.refetch();
      },
    });
  }

  addStore(data: any) {
    restAPI({
      endpointName: "suAddStore",
      urlArgs: null,
      data,
      callback: (err, res) => {
        this.handleCallResponse(err, res);
        if (!err) {
          const setPrimary = {
            store_id: res.data.newStore.id,
            store_type_id: res.data.newStore.store_type_id,
          };
          this.props.refetchStores();
          this.setState({ setPrimary });
        }
      },
    });
  }

  updateStore(data: any, storeId: number) {
    restAPI({
      endpointName: "modifyStore",
      urlArgs: [storeId],
      data,
      callback: (error, result) => {
        this.handleCallResponse(error, result);
        if (!error) {
          this.setState({
            addingStore: false,
            setPrimary: null,
            selectedStore: storeId,
          });
          this.props.refetchStores();
        }
      },
    });
  }

  savePrimaryContact(data: any) {
    if (data.user_found) {
      const employee = { primary_contact_id: data.user_found.id };
      this.updateStore(employee, data.store_id);
    } else {
      // const permissions = [
      // 	{
      // 		changed: [],
      // 		id: data.store_id,
      // 		roles: [getStoreTypeAdminRoleID(this.state.setPrimary.store_type_id)]
      // 	}
      // ];
      const newUser = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        role_ids: [
          getStoreTypeAdminRoleID(this.state.setPrimary.store_type_id),
        ],
        permissions: [],
      };

      for (const key in newUser) {
        if (newUser[key] === null) {
          delete newUser[key];
        }
      }

      restAPI({
        endpointName: "addStoreEmployee",
        urlArgs: [data.store_id],
        data: newUser,
        callback: (error, result) => {
          this.handleCallResponse(error, result);
          if (!error) {
            const employee = { primary_contact_id: result.data.employeeId };
            this.updateStore(employee, data.store_id);
            this.props.refetchStores();
          }
        },
      });
    }
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const OrganizationComponent = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(OrganizationComponentView);

export default OrganizationComponent;
