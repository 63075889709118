import * as React from "react";
import { useEffect } from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";

import { ChangePasswordPage, PasswordResetPage, SuperUserAdminTabs } from "../../pages/admin";
import InventoryTabPanelView from "../../pages/admin/components/tabs/InventoryTabPanel/InventoryTabPanel";
import { HomePage } from "../../pages/Home";
import { POInvoiceTool } from "../../pages/Invoice";
import { Login } from "../../pages/Login";
import { NotFound } from "../../pages/NotFound";
import { Register } from "../../pages/Register";
import { Unsubscribe } from "../../pages/Unsubscribe";
import { AdminPage } from "client/pages/admin/components/AdminPageView";
import { getViewTypeSub } from "client/utils/functions";
import { ErrorBoundary } from "client/components/ErrorBoundary/ErrorBoundary";

// Hook for fetching the router component props
export const useWithRouter = () => {
	const match = useRouteMatch();
	const location = useLocation();
	const history = useHistory();
	return { match, location, history };
};

export class RoutesView extends React.Component<LMI.IAdminRoutesProps, any> {
	shouldComponentUpdate(nextProps) {
		return localStorage.getItem("isu") === "true" || this.props.routes.length !== nextProps.routes.length;
	}

	renderRouteData(routeProps) {
		return this.props.routes.map(route => {
			return <Authenticated key={route.id} exact name={route.name} path={route.path} component={route.component} {...routeProps} />;
		});
	}

	render() {
		const { routes } = this.props;
		const routeProps: any = { viewType: getViewTypeSub(), ...this.props };
		return (
			<Switch>
				<Public exact name="index" path="/" component={() => <HomePage {...routeProps} />} />
				<Public name="Login" path="/login" component={Login} {...routeProps} />
				<Public name="Reset Password" path="/password-reset" component={PasswordResetPage} {...routeProps} />
				<Public name="Register" path="/activate/:id" component={Register} {...routeProps} />
				<Public name="Unsubscribe" path="/unsubscribe" component={Unsubscribe} {...routeProps} />
				<Public name="Invoice" path="/invoice-editor/:storeId/:id" component={POInvoiceTool} {...routeProps} />
				<Authenticated
					name="inventory"
					path="/share/inventory/:store/:inventory"
					component={() => (
						<InventoryTabPanelView
							{...{
								...routeProps,
								...{ sharePath: routeProps.history.location.pathname }
							}}
						/>
					)}
				/>
				<Authenticated exact name="dealerships-admin" path="/admin/dealerships" component={AdminPage} {...routeProps} />
				<Authenticated exact name="vendors-admin" path="/admin/vendors" component={AdminPage} {...routeProps} />
				<Authenticated exact name="ivendors-admin" path="/admin/ivendors" component={AdminPage} {...routeProps} />
				<Authenticated exact name="change-password" path="/change-password" component={ChangePasswordPage} {...routeProps} />
				<Authenticated exact name="su-admin" path="/admin/super" component={SuperUserAdminTabs} {...routeProps} />
				{routes ? this.renderRouteData(routeProps) : <></>}
				<Route path="*" component={NotFound} />
			</Switch>
		);
	}
}

const Public: any = ({ loggingIn, authenticated, component, ...rest }) => (
	<ErrorBoundary>
		<Route
			{...rest}
			render={() => (
				<PageTitle>
					{React.createElement(component, {
						loggingIn,
						authenticated,
						...rest
					})}
				</PageTitle>
			)}
		/>
	</ErrorBoundary>
);

const Authenticated: any = ({ loggingIn, authenticated, component, ...rest }) => (
	<ErrorBoundary>
		<Route
			{...rest}
			render={props => {
				if (loggingIn) return <span />;
				const passthru = props.location.pathname.indexOf("share") >= 0;
				const sendSU = localStorage.getItem("isu") === "true" && props.location.pathname !== "/admin/super";
				if (!passthru && sendSU) return <Redirect to="/admin/super" />;
				if (!authenticated) {
					localStorage.setItem("deeplink", `${props.location.pathname}${props.location.search}`);
					return <Redirect to="/login" />;
				}
				return (
					<PageTitle>
						{React.createElement(component, {
							...props,
							loggingIn,
							authenticated,
							...rest
						})}
					</PageTitle>
				);
			}}
		/>
	</ErrorBoundary>
);

const PageTitle = ({ children }) => {
	const { props } = children;

	useEffect(() => {
		document.title = `${props.name || ""} - LoopMeIn Admin`;
	}, [props.name]);

	return children;
};

const mapStateToProps = (state: any) => {
	return {
		routes: state.app.admin.routes,
		permissions: state.app.admin.permissions
	};
};

const matchDispatchToProps = (dispatch: any) => {
	return {};
};

export const LmiRoutes = compose(connect(mapStateToProps, matchDispatchToProps))(RoutesView);
export default LmiRoutes;
