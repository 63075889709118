// import { Input } from "formsy-semantic-ui-react";
import { RegularExpressions } from "client/utils/regex";
import * as React from "react";
import MaskedInput from "react-text-mask";
import { Button, Label } from "semantic-ui-react";
import { Input } from "formsy-semantic-ui-react";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { formatPhone, formatUSNumber, validatePhone } from "../../../../utils/functions";

export class MaskedInputComponent extends React.Component<LMI.IMaskInputProps, LMI.IMaskInputState> {
	constructor(props) {
		super(props);
		this.state = {
			// used to set a new value on the formsy Input from the masked input
			value: this.props.value
		};
	}

	checkValidOnChange = value => {
		switch (this.props.type) {
			case "phone":
				return validatePhone(value);
			default:
				return true;
		}
	};

	handleChange = e => {
		const { type } = this.props;
		const newValue = type === "phone" ? formatPhone(e.target.value) : formatUSNumber(e.target.value);

		this.setState({ value: newValue }, () => console.log(this.state));
		if (this.props.onChange) {
			this.props.onChange(newValue, this.checkValidOnChange(newValue));
		}
	};

	getSoloInput(mask) {
		return <MaskedInput mask={mask} value={this.state.value} onBlur={() => this.props.onBlur && this.props.onBlur()} onChange={e => this.handleChange(e)} />;
	}

	render() {
		// component uses https://github.com/text-mask/text-mask
		// component is prepped for different input types just add mask/validation regex into switch
		let mask;
		let validation;
		let validationErrors;
		const errorLabel = <Label color="red" pointing="above" />;
		switch (this.props.type) {
			case "phone":
				mask = RegularExpressions.PHONE_NUMBER_MASK;
				validation = { matchRegexp: RegularExpressions.PHONE_NUMBER };
				validationErrors = { matchRegexp: "Not a valid phone number" };
				break;
			case "currency":
				mask = createNumberMask({
					prefix: "$",
					suffix: "",
					includeThousandsSeparator: true,
					thousandsSeparatorSymbol: ",",
					allowDecimal: true,
					decimalSymbol: ".",
					decimalLimit: 2,
					integerLimit: 7,
					allowNegative: false,
					allowLeadingZeroes: false
				});
				break;
			default:
				mask = [];
		}

		if (this.props.soloInput) return this.getSoloInput(mask);
		else
			return (
				<Input
					name={this.props.name ? this.props.name : this.props.type}
					value={this.state.value}
					validations={validation}
					validationErrors={validationErrors}
					errorLabel={errorLabel}
					required={this.props.required ? this.props.required : false}
					size={this.props.size ? this.props.size : null}
					action={this.props.action ? true : false}
					disabled={this.props.disabled ? true : false}>
					<MaskedInput
						mask={mask}
						guide={false}
						value={this.state.value}
						onBlur={() => this.props.onBlur && this.props.onBlur()}
						onChange={e => this.handleChange(e)}
					/>
					{this.props.action && <Button icon={this.props.action.icon} color={this.props.action.color} />}
				</Input>
			);
	}
}

export default MaskedInputComponent;
