export const LMIEmails = ["jsoftllc.com", "loopmein.app"];
export const GeographicalRegion = [
	{
		name: "United States",
		abbreviation: "US",
		states: [
			{
				name: "Alabama",
				abbreviation: "AL"
			},
			{
				name: "Alaska",
				abbreviation: "AK"
			},
			{
				name: "American Samoa",
				abbreviation: "AS"
			},
			{
				name: "Arizona",
				abbreviation: "AZ"
			},
			{
				name: "Arkansas",
				abbreviation: "AR"
			},
			{
				name: "California",
				abbreviation: "CA"
			},
			{
				name: "Colorado",
				abbreviation: "CO"
			},
			{
				name: "Connecticut",
				abbreviation: "CT"
			},
			{
				name: "Delaware",
				abbreviation: "DE"
			},
			{
				name: "District Of Columbia",
				abbreviation: "DC"
			},
			{
				name: "Federated States Of Micronesia",
				abbreviation: "FM"
			},
			{
				name: "Florida",
				abbreviation: "FL"
			},
			{
				name: "Georgia",
				abbreviation: "GA"
			},
			{
				name: "Guam",
				abbreviation: "GU"
			},
			{
				name: "Hawaii",
				abbreviation: "HI"
			},
			{
				name: "Idaho",
				abbreviation: "ID"
			},
			{
				name: "Illinois",
				abbreviation: "IL"
			},
			{
				name: "Indiana",
				abbreviation: "IN"
			},
			{
				name: "Iowa",
				abbreviation: "IA"
			},
			{
				name: "Kansas",
				abbreviation: "KS"
			},
			{
				name: "Kentucky",
				abbreviation: "KY"
			},
			{
				name: "Louisiana",
				abbreviation: "LA"
			},
			{
				name: "Maine",
				abbreviation: "ME"
			},
			{
				name: "Marshall Islands",
				abbreviation: "MH"
			},
			{
				name: "Maryland",
				abbreviation: "MD"
			},
			{
				name: "Massachusetts",
				abbreviation: "MA"
			},
			{
				name: "Michigan",
				abbreviation: "MI"
			},
			{
				name: "Minnesota",
				abbreviation: "MN"
			},
			{
				name: "Mississippi",
				abbreviation: "MS"
			},
			{
				name: "Missouri",
				abbreviation: "MO"
			},
			{
				name: "Montana",
				abbreviation: "MT"
			},
			{
				name: "Nebraska",
				abbreviation: "NE"
			},
			{
				name: "Nevada",
				abbreviation: "NV"
			},
			{
				name: "New Hampshire",
				abbreviation: "NH"
			},
			{
				name: "New Jersey",
				abbreviation: "NJ"
			},
			{
				name: "New Mexico",
				abbreviation: "NM"
			},
			{
				name: "New York",
				abbreviation: "NY"
			},
			{
				name: "North Carolina",
				abbreviation: "NC"
			},
			{
				name: "North Dakota",
				abbreviation: "ND"
			},
			{
				name: "Northern Mariana Islands",
				abbreviation: "MP"
			},
			{
				name: "Ohio",
				abbreviation: "OH"
			},
			{
				name: "Oklahoma",
				abbreviation: "OK"
			},
			{
				name: "Oregon",
				abbreviation: "OR"
			},
			{
				name: "Palau",
				abbreviation: "PW"
			},
			{
				name: "Pennsylvania",
				abbreviation: "PA"
			},
			{
				name: "Puerto Rico",
				abbreviation: "PR"
			},
			{
				name: "Rhode Island",
				abbreviation: "RI"
			},
			{
				name: "South Carolina",
				abbreviation: "SC"
			},
			{
				name: "South Dakota",
				abbreviation: "SD"
			},
			{
				name: "Tennessee",
				abbreviation: "TN"
			},
			{
				name: "Texas",
				abbreviation: "TX"
			},
			{
				name: "Utah",
				abbreviation: "UT"
			},
			{
				name: "Vermont",
				abbreviation: "VT"
			},
			{
				name: "Virgin Islands",
				abbreviation: "VI"
			},
			{
				name: "Virginia",
				abbreviation: "VA"
			},
			{
				name: "Washington",
				abbreviation: "WA"
			},
			{
				name: "West Virginia",
				abbreviation: "WV"
			},
			{
				name: "Wisconsin",
				abbreviation: "WI"
			},
			{
				name: "Wyoming",
				abbreviation: "WY"
			}
		]
	},
	{
		name: "Canada",
		abbreviation: "CA",
		states: [
			{
				name: "Alberta",
				abbreviation: "AB"
			},
			{
				name: "British Columbia",
				abbreviation: "BC"
			},
			{
				name: "Manitoba",
				abbreviation: "MB"
			},
			{
				name: "New Brunswick",
				abbreviation: "NB"
			},
			{
				name: "Newfoundland and Labrador",
				abbreviation: "NL"
			},
			{
				name: "Northwest Territories",
				abbreviation: "NT"
			},
			{
				name: "Nova Scotia",
				abbreviation: "NS"
			},
			{
				name: "Nunavut",
				abbreviation: "NU"
			},
			{
				name: "Ontario",
				abbreviation: "ON"
			},
			{
				name: "Prince Edward Island",
				abbreviation: "PE"
			},
			{
				name: "Quebec",
				abbreviation: "QC"
			},
			{
				name: "Saskatchewan",
				abbreviation: "SK"
			},
			{
				name: "Yukon Territory",
				abbreviation: "YT"
			}
		]
	}
];
export const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const timeZones = [
	{
		value: "Hawaiian Standard Time",
		abbr: "HST",
		offset: -10,
		isdst: false,
		text: "(UTC-10:00) Hawaii",
		utc: "Pacific/Honolulu"
	},
	{
		value: "Alaskan Standard Time",
		abbr: "AKDT",
		offset: -8,
		isdst: true,
		text: "(UTC-09:00) Alaska",
		utc: "America/Anchorage"
	},
	{
		value: "Pacific Daylight Time",
		abbr: "PDT",
		offset: -7,
		isdst: true,
		text: "(UTC-07:00) Pacific Time (US & Canada)",
		utc: "America/Los_Angeles"
	},
	{
		value: "Pacific Standard Time",
		abbr: "PST",
		offset: -8,
		isdst: false,
		text: "(UTC-08:00) Pacific Time (US & Canada)",
		utc: "America/Los_Angeles"
	},
	{
		value: "US Mountain Standard Time",
		abbr: "UMST",
		offset: -7,
		isdst: false,
		text: "(UTC-07:00) Arizona",
		utc: "America/Phoenix"
	},
	{
		value: "Mountain Standard Time",
		abbr: "MDT",
		offset: -6,
		isdst: true,
		text: "(UTC-07:00) Mountain Time (US & Canada)",
		utc: "America/Denver"
	},
	{
		value: "Central Standard Time",
		abbr: "CDT",
		offset: -5,
		isdst: true,
		text: "(UTC-06:00) Central Time (US & Canada)",
		utc: "America/Chicago"
	},
	{
		value: "Eastern Standard Time",
		abbr: "EDT",
		offset: -4,
		isdst: true,
		text: "(UTC-05:00) Eastern Time (US & Canada)",
		utc: "America/Detroit"
	}
];
export const sliderDefaults = {
	price_range: [0, 200000],
	age_range: [0, 1000]
};
export const rangeBucketDefaults = {
	USED_INVENTORY_RANGE_BUCKET: [
		[0, 29],
		[30, 44],
		[45, 59],
		[60, null]
	],
	NEW_INVENTORY_RANGE_BUCKET: [
		[0, 99],
		[100, 269],
		[270, 364],
		[365, null]
	],
	CERTIFIED_INVENTORY_RANGE_BUCKET: [
		[0, 29],
		[30, 44],
		[45, 59],
		[60, null]
	]
};
export const formSchemas = {
	vehicle_types: {
		schema: {
			type: "object",
			required: ["year", "make", "model"],
			properties: {
				year: { type: "number", title: "Year:", default: "", minLength: 4, maxLength: 4 },
				make: { type: "string", title: "Make:", default: "" },
				model: { type: "string", title: "Model:", default: "" },
				trim: { type: "string", title: "Trim:", default: "" }
			}
		},
		uiSchema: {
			year: {
				classNames: "year ui input",
				"ui:autofocus": true
			},
			make: {
				classNames: "make ui input"
			},
			model: {
				classNames: "model ui input"
			},
			trim: {
				classNames: "trim ui input"
			}
		},
		validate: (formData, errors) => {
			const isValidRange = /^(19|[2-9][0-9])\d{2}$/.test(formData.year) && formData.year <= new Date().getFullYear() + 2;
			if (!formData.year || formData.year.length < 4 || formData.year.length > 4 || !isValidRange) {
				errors.year.addError("Please enter a valid 4-digit year.");
			}
			if (!formData.make || formData.make.length <= 0) {
				errors.make.addError("Make is required.");
			}
			if (!formData.model || formData.model.length <= 0) {
				errors.model.addError("Model is required.");
			}
			return errors;
		}
	}
};
export const SemanticCOLORS: any[] = ["red", "orange", "yellow", "olive", "green", "teal", "blue", "violet", "purple", "pink", "brown"];
// localstorage items we want to keep in tact
export const persistedStorage = [
	"completedFeatureTours",
	"reconSearchIntro",
	"appMenuIntro",
	"quickSearchIntro",
	"InventoryDetailIntro",
	"InventoryInfoTools",
	"StageSidebarCollapser"
];
