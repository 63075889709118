import * as React from "react";
import { debounce } from "lodash";
import { formatCurrency, zeroPlus, targetHasValue, isNumeric } from "client/utils/functions";
import { InspectionService } from "../util";
import { Features, InspectionLineItemStatus } from "loopmein-shared";

import createNumberMask from "text-mask-addons/dist/createNumberMask";
import MaskedInput from "react-text-mask";

import { Input, Grid, Dropdown, Label, Icon, Popup, Button } from "semantic-ui-react";
import { GrowingTextArea, InspectionTypeSelect } from "./";

enum ReadOnlyConfig {
	All = 1000,
	Complete = 5,
	Approved = 3,
	Rework = 7
}

export class InspectionItem extends React.Component<LMI.InspectionItem, LMI.InspectionItemState> {
	constructor(props: LMI.InspectionItem) {
		super(props);
		this.state = {
			item: props.item,
			edit_item: null,
			status_id: props.item.inspection_line_item_status_id,
			read_only_status: InspectionItem.readOnlyFields(props),
			saving: false,
			dirty: false,
			add_comment: false
		};
		// debounce the save inspection function for when Pat tabs through all quick like
		this.saveInspectionItem = debounce(this.saveInspectionItem.bind(this), 500);
		this.removeInspectionItem = debounce(this.removeInspectionItem.bind(this), 500);
	}

	static readOnlyFields(props: LMI.InspectionItem): number {
		if (!props.can_edit) return ReadOnlyConfig.All;
		else {
			const inConfig = ReadOnlyConfig[ReadOnlyConfig[props.item.inspection_line_item_status_id]];
			return inConfig > 0 ? inConfig : null;
		}
	}

	static getDerivedStateFromProps(nextProps: LMI.InspectionItem, prevState: LMI.InspectionItemState) {
		if (nextProps.item && (nextProps.item !== prevState.item || !prevState.edit_item)) {
			const { item } = nextProps;
			const add_comment = item && item.comment;
			// adding those pesky zeros
			let edits: any = {};
			const format = (val, digits = 2) => (val ? parseFloat(val).toFixed(digits) : val);
			if (item.parts) edits.parts = format(item.parts);
			if (item.labor) edits.labor = format(item.labor);
			if (item.hours) edits.hours = format(item.hours, 1);

			return {
				item,
				edit_item: { ...item, ...edits },
				status_id: nextProps.item.inspection_line_item_status_id,
				read_only_status: InspectionItem.readOnlyFields(nextProps),
				saving: false,
				dirty: false,
				add_comment
			};
		}
		return prevState;
	}

	saveInspectionItem = () => {
		if (this.state.dirty) this.setState({ saving: true }, () => this.props.update_item(this.state.edit_item));
	};

	removeInspectionItem = () => {
		this.setState({ saving: true }, () => this.props.delete_item(this.props.item));
	};

	updateHandler(value: any, name: string, save = false) {
		const { edit_item } = this.state;
		if (edit_item && edit_item[name] !== value) {
			const item = { ...edit_item, ...{ [name]: value } };
			this.setState({ edit_item: item, dirty: true }, () => save && this.saveInspectionItem());
		}
	}

	getItemTotal = (): string => {
		const { edit_item } = this.state;
		const total: number = +edit_item.labor + +edit_item.parts;
		return total ? formatCurrency(total, 2) : "$0.00";
	};

	render() {
		const { types, catagories, statuses, can_edit, can_admin, can_approve, update_status, add_item_option } = this.props;
		const { edit_item, read_only_status, status_id, saving, add_comment } = this.state;
		if (!edit_item) return <span />;

		const disableAll = read_only_status == ReadOnlyConfig.All;
		const isApproved = read_only_status == ReadOnlyConfig.Approved && !edit_item.is_declined;
		const isCompleted = read_only_status == ReadOnlyConfig.Complete;
		const isRework = read_only_status == ReadOnlyConfig.Rework;

		// check if selected status is an option to render dropdown/label
		const statusIndex = statuses ? statuses.findIndex(s => s.id === edit_item.inspection_line_item_status_id) : -1;
		const statusName = edit_item.inspection_line_item_status ? edit_item.inspection_line_item_status.name : "";
		const statusColor = statuses && statusIndex > -1 ? statuses[statusIndex].color_code : "FFFFFF";
		const indicator = statusColor !== "FFFFFF" ? { borderLeft: `8px solid #${statusColor}` } : {};
		const allowedStatusItems = Features.Inspections.getAllowedItemStatus(status_id);
		const statusOptions = InspectionService.friskStatusOptions(statuses, can_approve).filter(i => allowedStatusItems.includes(i.id));

		return (
			<div className={`item-form${isApproved ? " approved" : ""}`}>
				<Grid>
					<Grid.Row>
						<Grid.Column width={10}>
							<Grid>
								<Grid.Column width={3}>
									{statuses && (can_edit || can_approve) && statusIndex > -1 ? (
										<Dropdown
											text={statusName}
											value={status_id}
											style={indicator}
											fluid
											labeled
											button
											scrolling
											selectOnBlur={false}
											disabled={disableAll && !can_approve}
											className="icon status-dropdown"
											direction="right"
											options={statusOptions.map((s, key) => ({
												key,
												text: s.name,
												value: s.id,
												label: { style: { background: `#${s.color_code}` }, empty: true, circular: true }
											}))}
											onChange={(e, data) => this.setState({ dirty: true }, () => update_status({ items: [edit_item.id], status: data.value }))}
										/>
									) : (
										<Label className="status-label" size="large" style={indicator} content={statusName} />
									)}
								</Grid.Column>
								<Grid.Column width={10} className="type-col has-tools">
									<div>
										{edit_item.inspection_item_option_id && types && !isCompleted ? (
											<InspectionTypeSelect
												{...{
													id: `TypeSelect${edit_item.id}`,
													clearable: false,
													placeholder: "Select Item",
													disabled: disableAll || isCompleted || isRework,
													can_add: can_admin,
													types,
													catagories,
													selected_id: edit_item.inspection_item_option_id,
													onAddItem: (e, data) => add_item_option(data.value, edit_item.id),
													onSave: option => {
														if (option) {
															const edit_item = { ...this.state.edit_item, ...{ inspection_item_option: option, inspection_item_option_id: option.id } };
															this.setState({ edit_item, dirty: true }, () => this.saveInspectionItem());
														}
													}
												}}
											/>
										) : (
											<Label
												size="large"
												className="type-label"
												content={`${edit_item.inspection_item_option.op_code ? edit_item.inspection_item_option.op_code : ""} ${
													edit_item.inspection_item_option.name
												}`}
											/>
										)}
										{saving && (
											<span className="saving-indicator">
												<Icon loading name="spinner" />
											</span>
										)}
									</div>
									<div className="comment-field">
										<span className={`add-comment${add_comment ? " open" : ""}`} onClick={() => this.setState({ add_comment: !add_comment })}>
											<Icon name={add_comment ? "caret up" : "caret down"} /> Comment/Reason
										</span>
										{add_comment && (
											<GrowingTextArea
												style={{ width: "100%" }}
												name="comment"
												placeholder=""
												disabled={disableAll}
												onChange={(e, data) => this.updateHandler(data.value, "comment")}
												onBlur={() => this.saveInspectionItem()}
												value={edit_item && edit_item.comment ? edit_item.comment : ""}
												autoFocus={add_comment && !edit_item.comment}
											/>
										)}
									</div>
								</Grid.Column>
								<Grid.Column width={3} className="cat-col">
									{this.getCatSelect()}
								</Grid.Column>
							</Grid>
						</Grid.Column>
						<Grid.Column width={6} className="price-column" style={{ margin: "-10px 0" }}>
							{edit_item ? this.priceGrid() : ""}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	}

	getCatSelect() {
		const { catagories } = this.props;
		const { edit_item, read_only_status } = this.state;
		if (catagories && edit_item) {
			const selected_catagory = catagories.find(c => c.id === edit_item.inspection_line_item_section_id);
			const options = catagories.map((o, key) => ({
				key,
				text: o.name,
				value: o.id,
				label: { style: { background: `#${o.color_code}` }, empty: true, circular: true }
			}));
			const disabled = read_only_status === ReadOnlyConfig.All || read_only_status === ReadOnlyConfig.Complete || read_only_status === ReadOnlyConfig.Rework;
			if (selected_catagory && options)
				return (
					<Dropdown
						text={selected_catagory.name}
						value={selected_catagory.id}
						style={{ borderLeft: `8px solid #${selected_catagory.color_code}` }}
						fluid
						labeled
						button
						scrolling
						disabled={disabled}
						className="icon status-dropdown catagory-selector"
						direction="right"
						options={options}
						onChange={(e, data) => this.updateHandler(data.value, "inspection_line_item_section_id", true)}
					/>
				);
		}
	}

	priceGrid() {
		const { can_remove, enableItemPriceEditInRework } = this.props;
		const { edit_item, read_only_status, status_id } = this.state;
		const removable = can_remove && read_only_status !== ReadOnlyConfig.Complete;
		const isDeclined = status_id == InspectionLineItemStatus.Declined;
		const disabled = read_only_status === ReadOnlyConfig.Rework ? !enableItemPriceEditInRework : read_only_status !== null;
		const currencyMask = createNumberMask({
			prefix: "$",
			suffix: "",
			includeThousandsSeparator: true,
			thousandsSeparatorSymbol: ",",
			allowDecimal: true,
			decimalSymbol: ".",
			decimalLimit: 2,
			integerLimit: 7,
			allowNegative: false,
			allowLeadingZeroes: false
		});

		// setup price values and check for zeros
		let { parts, labor, hours } = edit_item;
		const partsValue = zeroPlus(parts);
		const laborValue = zeroPlus(labor);
		const hoursValue = zeroPlus(hours);

		return (
			<Grid className={`price-grid${isDeclined ? " declined" : ""}`}>
				<Grid.Column width={4}>
					<div className="ui input fluid">
						<MaskedInput
							mask={currencyMask}
							name="parts"
							disabled={disabled}
							placeholder={isDeclined ? "" : "Parts"}
							value={partsValue !== null ? partsValue : ""}
							onChange={e => this.updateHandler(e && targetHasValue(e.target) ? e.target.value.replace(/\$|,/g, "") : null, "parts")}
							onBlur={() =>
								this.setState({ edit_item: { ...edit_item, ...{ parts: edit_item.parts !== null ? parseFloat(edit_item.parts.toString()) : null } } }, () =>
									this.saveInspectionItem()
								)
							}
						/>
					</div>
				</Grid.Column>
				<Grid.Column width={4}>
					<div className="ui input fluid">
						<MaskedInput
							mask={currencyMask}
							name="labor"
							disabled={disabled}
							placeholder={isDeclined ? "" : "Labor"}
							value={laborValue !== null ? laborValue : ""}
							onChange={e => this.updateHandler(e && targetHasValue(e.target) ? e.target.value.replace(/\$|,/g, "") : null, "labor")}
							onBlur={() =>
								this.setState({ edit_item: { ...edit_item, ...{ labor: edit_item.labor !== null ? parseFloat(edit_item.labor.toString()) : null } } }, () =>
									this.saveInspectionItem()
								)
							}
						/>
					</div>
				</Grid.Column>
				<Grid.Column width={4}>
					<Input
						fluid
						name="hours"
						placeholder={isDeclined ? "" : "Hours"}
						autoComplete="off"
						disabled={disabled}
						onChange={(e, data) => {
							let value = data && data.value;
							if (value === ".") value = "0.";
							const target = { value };
							const hourValue = data && isNumeric(target) && targetHasValue(target) ? value : null;
							this.updateHandler(hourValue, "hours");
						}}
						onBlur={() => this.saveInspectionItem()}
						value={hoursValue !== null ? hoursValue : ""}
					/>
				</Grid.Column>
				<Grid.Column width={4} className="items-total-col">
					{!isDeclined && <span className={`item-total${disabled ? " disable" : ""}`}>{this.getItemTotal()}</span>}
					<span className="tool-col">
						{removable && (
							<Popup
								on="click"
								hideOnScroll={true}
								trigger={<Icon link className="remove-icon" name="trash alternate" color="red" />}
								content={
									<div>
										<p>Are you sure you want to remove this item?</p>
										<Button size="mini" content="Yes, remove" color="blue" onClick={() => this.removeInspectionItem()} />
									</div>
								}
							/>
						)}
						{read_only_status === ReadOnlyConfig.Complete && <Icon className="completed-icon" name="check" color="green" />}
					</span>
				</Grid.Column>
			</Grid>
		);
	}
}

export default InspectionItem;
