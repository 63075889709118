import { addAlert } from "api/redux/actions";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { Button, Container, Dimmer, Grid, Input, Loader } from "semantic-ui-react";
import { handleErrorResponse } from "../../../../../../utils/functions";
import { restAPI } from "../../../../../../utils/rest";

import "./AddLeaseReturnComment.css";

export class CommentView extends React.Component<LMI.ILRCommentViewProps, LMI.ILRCommentViewState> {
	constructor(props: LMI.ILRCommentViewProps) {
		super(props);

		this.state = {
			comment: props.comment ? props.comment : null,
			creating: false,
			saving: false
		};
	}

	handleChange = (event: any, data: any) => {
		this.setState({ comment: data.value });
	};

	createComment = () => {
		const { comment } = this.state;

		if (comment && comment.trim() !== "") {
			this.setState({ creating: true }, async () => {
				const { storeId, detail } = this.props;

				const data = {
					comment
				};

				restAPI({
					endpointName: "addLeaseReturnComment",
					urlArgs: [storeId, detail.detail_id],
					data: data,
					callback: async (error, result) => {
						if (error) {
							this.sendAlert({ type: "danger", message: handleErrorResponse({ error }) });
						} else {
							await this.props.onUpdateCommentCount({ count: this.props.lease_return.comments.length + 1 });
							await this.props.refetchComments();
							await this.props.closeModalCallback();
						}
						this.setState({ saving: false, creating: false });
					}
				});
			});
		}
	};

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
		if (type === "danger") {
			console.log(`Error: ${message}`);
		}
	}

	render() {
		const { comment, creating } = this.state;
		const { loading } = this.props;

		if (loading || creating)
			return (
				<Dimmer active inverted>
					<Loader inverted />
				</Dimmer>
			);

		return (
			<div className="add-comment">
				<Grid>
					<Grid.Row id="fileRow" className="compact">
						<Grid.Column width={16}>
							<div className="input-wrapper">
								<div className="lmi-label">COMMENT</div>
								<Input className="lmi-input" name="comment" autoFocus value={comment} onChange={(e, data) => this.handleChange(e, data)} />
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Container textAlign="right">
							<Button id="create" type="submit" positive size="large" disabled={comment ? false : true} onClick={this.createComment.bind(this)}>
								Add Comment
							</Button>
						</Container>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const CommentForm = compose(connect(mapStateToProps, mapDispatchToProps))(CommentView) as React.ComponentType<any>;

export default CommentForm;
