import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { gqlQueries } from "gql-imports";
import { graphql } from "@apollo/react-hoc";
import { hasPermission } from "client/utils/userAccess";
import { handleErrorResponse } from "../../../../../../utils/functions";
import { Permission, VehicleDocumentScope, VehicleDocumentType } from "loopmein-shared";
import { getBase64 } from "../../../../../../utils/fileHandling";
import { restAPI } from "../../../../../../utils/rest";
import { Session } from "client/utils/session";

import { Button, Card, Dimmer, Dropdown, Grid, Input, Loader } from "semantic-ui-react";
import DocumentCard from "./DocumentCard";

import "./Documents.css";

export class DocumentsGridComponentView extends React.Component<LMI.IInventoryProps, LMI.IInventoryDocumentsTabState> {
	constructor(props) {
		super(props);
		this.state = {
			selectedRowIndex: null,
			alert: null,
			documents: null,
			is_dragging: false,
			is_uploading: false,
			document_errors: []
		};
	}

	format(documents: any): LMI.IInventoryDocumentDetailGQL[] {
		return documents.map(item => {
			return {
				database_id: item.database_id,
				relative_url: item.relative_url,
				full_url: item.full_url,
				short_url: item.short_url,
				vehicle_document_type_id: item.vehicle_document_type_id,
				view_count: item.view_count,
				is_approved: item.is_approved,
				inventory_item_id: item.inventory_item_id,
				vin: item.vin,
				store_id: item.store_id,
				organization_id: item.organization_id,
				year: item.years,
				make: item.make,
				model: item.models,
				condition: item.condition,
				customer_can_view: item.customer_can_view,
				store_can_modify: item.store_can_modify,
				document_type_name: item.document_type_name,
				name: item.name,
				external_url: item.external_url,
				default_image_url: item.default_image_url,
				default_image_filename: item.default_image_filename
			};
		});
	}

	onDragEnter = args => {
		args.preventDefault();
		args.stopPropagation();

		if (!this.state.is_dragging) {
			this.setState({ is_dragging: true });
		}
	};

	onDragLeave = args => {
		args.preventDefault();
		args.stopPropagation();

		if (this.state.is_dragging) {
			this.setState({ is_dragging: false });
		}
	};

	onDrop = (args: any) => {
		args.preventDefault();
		args.stopPropagation();
		if (args.currentTarget.id === "documentWrapper") {
			const dt = args.dataTransfer;
			const files = Object.keys(dt.files).map(key => dt.files[key]);
			this.setState({
				documents: files ? files : null,
				is_dragging: false,
				document_errors: []
			});
		}
	};

	onBrowseFile = (args: any) => {
		const files = Object.keys(args.currentTarget.files).map(key => args.currentTarget.files[key]);
		this.setState({
			documents: files ? files : null,
			is_dragging: false,
			document_errors: []
		});
	};

	onSelectType = (file, args, args1) => {
		const { value } = args1;
		const nameField = document.getElementById("DocNameField");
		const documents = this.state.documents.map(document => {
			if (file.name === document.name) {
				const type = this.props.vehicle_document_types.find(dt => dt.id === value);
				document["vehicle_document_type"] = value;
				document["vehicle_document_type_scope"] = type.vehicle_document_scope_id;
				if (value === VehicleDocumentType.Other) {
					nameField.setAttribute("placeholder", "Required Name");
					nameField.setAttribute("required", "true");
				} else {
					nameField.setAttribute("placeholder", "Optional Name");
					nameField.setAttribute("required", "false");
				}
			}
			return document;
		});
		// const document_errors = this.state.document_errors.filter(e => e !== file.name);
		// this.setState({ documents, document_errors });
		this.setState({ documents, document_errors: [] });
	};

	onChangeName = (file, args, { value }) => {
		const documents = this.state.documents.map(document => {
			if (file.name === document.name) {
				// Name field required with type Other
				if (document["vehicle_document_type"] === VehicleDocumentType.Other) {
					if (args.currentTarget.required && value.length > 1) {
						args.currentTarget.required = false;
					} else if (!args.currentTarget.required && value.length <= 1) {
						args.currentTarget.setAttribute("required", "true");
					}
				}
				document["document_name"] = value;
			}
			return document;
		});
		this.setState({ documents, document_errors: [] });
	};

	onRemoveDocument = file => {
		const documents = this.state.documents.filter(d => d.name !== file.name);
		const document_errors = this.state.document_errors.filter(e => e !== file.name);
		this.setState({ documents, document_errors });
	};

	getDroppedGrid = ({ documents, vehicle_document_types }) => {
		const options = vehicle_document_types.map(type => {
			return { text: type.name, value: type.id };
		});
		return documents.map((file, index) => {
			return (
				<Grid.Row className="dropped-row" key={index}>
					<Grid.Column width={7} className="left">
						<i className="icon file" /> {file.name}
					</Grid.Column>
					<Grid.Column width={4}>
						<Dropdown
							error={this.state.document_errors.indexOf(file.name) >= 0}
							className="dropdown-left"
							scrolling
							placeholder="Select Type"
							options={options}
							onChange={this.onSelectType.bind(this, file)}
						/>
					</Grid.Column>
					<Grid.Column width={4}>
						<Input id="DocNameField" placeholder="Optional Name" onChange={this.onChangeName.bind(this, file)} required={false} />
					</Grid.Column>
					<Grid.Column width={1}>
						<i className="icon delete" onClick={this.onRemoveDocument.bind(this, file)} />
					</Grid.Column>
				</Grid.Row>
			);
		});
	};

	onUploadFiles = () => {
		const { documents } = this.state;
		this.setState({ is_uploading: true });
		if (this.state.documents) {
			// validate document type
			const requiredName = document.getElementById("DocNameField");
			const invalidDocuments = this.state.documents.filter(document => !document.vehicle_document_type || requiredName.getAttribute("required") === "true");
			if (invalidDocuments.length > 0) {
				this.setState({
					document_errors: invalidDocuments.map(i => i.name),
					is_uploading: false
				});
				return;
			}
			let documentCount = this.state.documents.length;
			Object.keys(documents).map(async key => {
				getBase64(documents[key], encodedFile => {
					const requiredData = this.getRequiredFields(documents[key].vehicle_document_type_scope);
					const data = Object.assign(requiredData, {
						document: encodedFile,
						vehicle_document_type_id: documents[key].vehicle_document_type,
						name: documents[key].document_name,
						inventory_item_id: this.props.detail.id
					});
					restAPI({
						endpointName: "uploadVehicleDocument",
						urlArgs: [this.props.storeId],
						data,
						callback: error => {
							let alert;
							if (error) {
								alert = {
									type: "danger",
									message: handleErrorResponse({
										error,
										message: error.message
									})
								};
								documentCount = 0;
							} else {
								alert = {
									type: "success",
									message: `Vehicle document successfully uploaded.`
								};
								documentCount--;
							}
							if (documentCount === 0) {
								this.setState({ is_uploading: false, documents: null });
								this.props.refetch_vehicle_documents();
								this.props.onAlert(alert);
							}
						}
					});
				});
			});
		}
	};

	getRequiredFields = type => {
		let requiredData = {};
		switch (type) {
			case VehicleDocumentScope.Vin:
				requiredData = { vin: this.props.detail.vin };
				break;
			case VehicleDocumentScope.MakeModelsYears:
				requiredData = {
					year: [this.props.detail.year],
					make: this.props.detail.make,
					model: [this.props.detail.model]
				};
				break;
			// case VehicleDocumentScope.StoreGroupCondition:
			// 	// todo: create option for is_group_level in UI and attach here
			// 	requiredData = { condition: this.props.detail.condition };
			// 	break;
			default:
				break;
		}
		return requiredData;
	};

	onCancelUploadFiles = () => {
		this.setState({ documents: null });
	};

	render() {
		const { documents, is_dragging, is_uploading, document_errors } = this.state;
		const dropped = documents && documents.length > 0;
		const { vehicle_documents, vehicle_document_types, print_ready, permissions } = this.props;
		const readyDocuments = vehicle_documents ? this.format(vehicle_documents) : null;
		const canUpload = hasPermission(permissions, Permission.ADD_VEHICLE_DOCUMENT, Session.get("isSuperUser"));

		if (!readyDocuments) {
			return <Loader className={`loader active`} size="small" />;
		}

		return (
			<div
				id="documentWrapper"
				className="document-wrapper"
				onDrop={event => this.onDrop(event)}
				onDragEnter={this.onDragEnter}
				onDragOver={this.onDragEnter}
				onDragLeave={this.onDragLeave}>
				{!print_ready && (
					<div className="file-uploader">
						{is_uploading && (
							<Dimmer active>
								<Loader indeterminate>Uploading File{documents.length > 1 && "s"}</Loader>
							</Dimmer>
						)}
						{canUpload && (
							<div className={is_dragging ? "dropper dragdrop" : dropped ? "dropper dropped" : "dropper"}>
								{is_dragging ? (
									"Drop Files Now"
								) : dropped ? (
									<Grid className="dropped-grid" columns={3} divided="vertically">
										{this.getDroppedGrid({ documents, vehicle_document_types })}
										{document_errors.length > 0 && <Grid.Row className="error">You must select a document type for each document you wish to upload.</Grid.Row>}
										<Grid.Row className="dropped-row">
											<Grid.Column width={9} />
											<Grid.Column width={6} className="action">
												<Button className="button right primary" onClick={this.onUploadFiles.bind(this)}>
													Upload
												</Button>
												<Button className="button right" onClick={this.onCancelUploadFiles}>
													Cancel
												</Button>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								) : (
									<div>
										<i className="icon file" /> Drop Documents Here -or- Click to Browse
										<input id="documentInput" type="file" name="" onChange={this.onBrowseFile} multiple />
									</div>
								)}
							</div>
						)}
					</div>
				)}
				<Card.Group className="document-group">
					{readyDocuments.map((document, index) => {
						return <DocumentCard document={document} {...this.props} key={index} />;
					})}
				</Card.Group>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const InventoryDocuments = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IInventoryDocumentsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.vehicleDocuments, {
		options: (props: any) => {
			return {
				variables: {
					store_id: parseInt(props.storeId, 10),
					inventory_item_id: props.inventory_item_id
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, vehicle_documents, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				vehicle_documents: vehicle_documents.documents,
				refetch_vehicle_documents: refetch
			};
		}
	}),
	graphql<LMI.IInventoryDocumentTypesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.vehicleDocumentTypes, {
		options: (props: any) => {
			return {
				variables: {
					store_id: parseInt(props.storeId, 10),
					scopes: [VehicleDocumentScope.Inventory, VehicleDocumentScope.Vin]
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, vehicle_document_types, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				vehicle_document_types,
				refetch_types: refetch
			};
		}
	}),
	graphql<LMI.IInventoryDetailQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventoryDetail, {
		options: (props: any) => {
			return {
				variables: {
					store_id: parseInt(props.storeId, 10),
					inventory_item_id: props.inventory_item_id
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, inventory_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				detail: inventory_detail,
				refetchDetail: refetch
			};
		}
	})
)(DocumentsGridComponentView) as React.ComponentType<any>;

export default InventoryDocuments;
