import "semantic-ui-css/semantic.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./client/App";
import { Provider } from "react-redux";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/react-hooks";

import * as Raven from "raven-js";
// import { createHttpLink } from "apollo-link-http";
import { WebSocketLink } from "@apollo/link-ws";
import { onError } from "@apollo/link-error";
import { forceLogout } from "./client/utils/userAccess";
// import { ApolloLink, split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { setContext } from "apollo-link-context";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  split,
} from "@apollo/client";
import { combineReducers, createStore } from "redux";
import { appReducer } from "./api/redux/reducers";

if (!process.env.REACT_APP_REST_URL) {
  console.error("Meteor setting rest_url not set up.");
}
// Where you started the midtier server with /graphql at end
if (!process.env.REACT_APP_GRAPHQL_URL) {
  console.error("Meteor setting graphql_url not set up.");
}
// Where you started the midtier server with /graphql at end
if (!process.env.REACT_APP_SENTRY_DSN) {
  console.error("Meteor setting sentry_dsn not set up.");
}

Raven.config(process.env.REACT_APP_SENTRY_DSN, {
  environment: "live-dev",
  shouldSendCallback: () => {
    return process.env.REACT_APP_ENVIRONMENT === "live";
  },
}).install();

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

// get the authentication token from local storage if it exists
const token = localStorage.getItem("token");

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_GRAPHQL_WS,
  options: {
    reconnect: true,
    connectionParams: {
      mode: "WEB",
      authToken: token,
    },
  },
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map((data: any) => {
      if (data.reason === "session_expired") {
        forceLogout("sessionExpired=true");
      }
    });
  }
});

console.info("GQL Subscriptions Enabled: ", !!wsLink);

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query) as any;
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const link = ApolloLink.from([errorLink, terminatingLink]);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(link as any) as any,
  cache: new InMemoryCache(),
});

class ReduxController {
  store: any;
  unsubscribe: any;

  constructor() {
    this.store = createStore(
      combineReducers({
        app: appReducer,
      }),
      {}
    );
    this.unsubscribe = this.store.subscribe(() => {
      this.onStateChange();
    });
  }

  onStateChange() {
    // TODO: State has changed, do we need to do anything at the App level?
  }
}

const rc: ReduxController = new ReduxController();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={rc.store}>
      <App client={client} />
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
