import * as React from "react";
import { TCProps, TabsContainer } from "client/pages/admin/components/ReactTabsContainer";
import SubTabContainers from "client/components/Routes/SubTabContainers";
import { buildTabTarget } from "client/components/Menu/utils";
import { changeSubtab } from "api/redux/actions";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";

import "./UserSettingsTabs.css";

export class UserSettingsPageView extends React.Component<LMI.ISAProps, LMI.ISAState> {
	constructor(props) {
		super(props);

		this.state = {
			subRouteId: "settings-profile"
		};
	}

	render() {
		const { storeId, permissions } = this.props;
		const { subRouteId } = this.state;
		const tabProps: TCProps = {
			subRouteId,
			subTabContainer: SubTabContainers.userSettings,
			permissions,
			classes: "reports-tab-panel-tabs",
			viewType: `user-settings sub-component`,
			dataPassThru: {
				storeId: storeId,
				orgId: parseInt(localStorage.getItem("selectedOrgId"), 10),
				permissions
			},
			onChange: (tabSelected: string) => this.selectedTab(tabSelected)
		};
		return (
			<div className="settings-admin-page panel-content">
				<TabsContainer {...tabProps} />
			</div>
		);
	}

	selectedTab(tab) {
		this.props.changeSubtab(tab);
		this.props.history.push(buildTabTarget(this.props.match.path, tab));
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return { subRouteId: nextProps.match ? nextProps.match.params.id : prevState.subRouteId };
	}

	componentWillUnmount() {
		this.props.changeSubtab(null);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		changeSubtab: (tabName: string) => {
			dispatch(changeSubtab(tabName));
		}
	};
};

export const UserSettingsTabs: any = compose(connect(mapStateToProps, mapDispatchToProps))(UserSettingsPageView);
export default UserSettingsTabs;
