import "./InventoryDetailComponent.css";

import * as React from "react";
import { withRouter } from "react-router";
import { ClassAttributes } from "react";
import { addAlert, toggleHeader } from "api/redux/actions";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { gqlQueries } from "gql-imports";
import { graphql } from "@apollo/react-hoc";
import { hasPermission } from "client/utils/userAccess";
import { Permission } from "loopmein-shared";
import { handleErrorResponse } from "client/utils/functions";
import { Session } from "client/utils/session";
import { getSelectedTabStore } from "client/utils/functions";

import { Loading } from "client/components/Loading";
import { Grid, Icon, Tab, Menu } from "semantic-ui-react";
import { InventoryDetailSpecs } from "./InventoryDetail";
import { InventoryDetailHeader } from "./InventoryDetailHeader/InventoryDetailHeader";
import { TimelineView } from "./InventoryDetailTabs/Timeline/Timeline";
import { InventoryInspection } from "../InventoryInspection";
import { NotesView } from "./InventoryDetailTabs/Notes/NotesSidebar";
import { InventoryInfo, InventoryTasks, InventoryDocuments, InventoryAccessories, InventoryLeads } from "./InventoryDetailTabs";
import { InventoryTab } from "./components/InventoryTab";
import { checkComponentUpdate } from "client/utils/internal";

export class InventoryDetailComponent extends React.Component<LMI.InventoryDetailProps, LMI.InventoryDetailState> {
	menuConfig = { secondary: true, pointing: true };
	inventoryDetailTabs = {
		Info: Permission.VIEW_INVENTORY,
		Tasks: Permission.LIST_TASKS,
		Inspection: Permission.VIEW_INSPECTION,
		Documents: Permission.VIEW_INVENTORY,
		Accessories: Permission.VIEW_INVENTORY_ACCESSORIES,
		Leads: Permission.LIST_LEADS,
		Timeline: Permission.VIEW_TIMELINE
	};

	constructor(props: LMI.InventoryDetailProps) {
		super(props);
		this.state = {
			show_workflow: false,
			is_sub: true,
			detail_class: "inventory-detail",
			open_sidebar: this.initialSideBarStatus(),
			has_notes: false,
			last_update: new Date(),
			Vehicle_Notes: true,
			Inspections: true,
			Issues: true,
			Tasks: true,
			Vendors: true,
			Accessories: false,
			Stages: true,
			Miscellaneous: false,
			lightbox_open: false,
			lightbox_photo_index: 0,
			lightbox_photos: [],
			on_tab: this.getInventoryTabs().activeTab,
			timeline_update: null
		};
	}

	render() {
		const {
			show_workflow,
			is_sub,
			detail_class,
			open_sidebar,
			has_notes,
			last_update,
			Vehicle_Notes,
			Inspections,
			Issues,
			Tasks,
			Vendors,
			Accessories,
			Stages,
			Miscellaneous,
			on_tab
		} = this.state;
		const { storeId, loading, detail, update_key, refetchDetail } = this.props;
		const { tabs, activeTab } = this.getInventoryTabs();

		return (
			<div id="InventoryDetailComponent" className={detail_class}>
				{loading || !detail ? (
					<div className="detail-loader">
						<Loading />
					</div>
				) : (
					<div id={`Detail${detail.id}`}>
						<InventoryDetailHeader
							{...({
								show_workflow,
								is_sub,
								updateNotes: () => this.setState({ last_update: new Date(), timeline_update: new Date() }, () => refetchDetail()),
								...this.props
							} as any)}
						/>
						<Grid>
							<Grid.Column width={open_sidebar ? 12 : 16}>
								{this.sideBarToggle(has_notes, open_sidebar)}
								<Tab
									menu={this.menuConfig}
									className="inventory-tabs"
									panes={tabs}
									defaultActiveIndex={activeTab}
									onTabChange={(event, data) => this.setSelectedTabStore(data)}
									renderActiveOnly
								/>
							</Grid.Column>
							<NotesView
								{...{
									update_key,
									last_update,
									storeId,
									notes_open: open_sidebar,
									inventoryItemId: detail.id,
									onHandleErrorResponse: error => handleErrorResponse(error),
									onAlert: alert => this.props.addAlert(alert),
									onSetHasNotes: hasNotes => this.setState({ has_notes: hasNotes }),
									onToggleTimelineFlags: (flag, isVisible) => this.toggleTimelineFlags(flag, isVisible),
									timelineFlags: {
										Vehicle_Notes,
										Inspections,
										Issues,
										Tasks,
										Vendors,
										Accessories,
										Stages,
										Miscellaneous
									},
									active_tab: tabs[on_tab].menuItem
								}}
							/>
						</Grid>
					</div>
				)}
			</div>
		);
	}

	toggleTimelineFlags = (flag: any, isVisible) => {
		// @ts-ignore
		this.setState({ [flag]: isVisible, timeline_update: new Date() });
	};

	initialSideBarStatus() {
		const sidePanelState = localStorage.getItem("recon_notes_side_panel");
		const open = sidePanelState === "true" ? true : false;
		return open;
	}

	sideBarToggle(has_notes, open_sidebar) {
		return (
			<span id="sideTab" className={`notes-tab ${has_notes ? "has" : ""}`} onClick={() => this.toggleSideBar()}>
				NOTES <Icon className={`angle ${open_sidebar ? "down" : "up"}`} />
			</span>
		);
	}

	toggleSideBar() {
		if (this.state.has_notes) {
			const open_sidebar = !this.state.open_sidebar;
			this.setState({ open_sidebar }, () => {
				localStorage.setItem("recon_notes_side_panel", open_sidebar.toString());
			});
		}
	}

	getSelectedTabStore(): number {
		let stored = getSelectedTabStore("ReconInventoryTabPanel");
		// check for the mapOpen urlParam as a condition
		if (window.location.search) {
			const params = new URLSearchParams(window.location.search);
			if (params.get("openMap")) {
				stored = 0;
				localStorage.setItem("ReconInventoryTabPanel_defaultTabIndex", "0");
			}
		}
		return stored;
	}

	setSelectedTabStore(data) {
		this.setState({ on_tab: data.activeIndex });
		localStorage.setItem("ReconInventoryTabPanel_defaultTabIndex", data.activeIndex.toString());
	}

	getInventoryTabs = () => {
		const frisk = permission => hasPermission(this.props.permissions, permission, Session.get("isSuperUser"));
		const tabs = Object.keys(this.inventoryDetailTabs)
			.filter(t => frisk(this.inventoryDetailTabs[t]))
			.map(tab => ({
				menuItem: this.getTabLabel(tab),
				render: () => this.getInventoryTab(tab)
			}));
		const tabStore = this.getSelectedTabStore();
		const activeTab = tabStore >= tabs.length ? tabs.length - 1 : tabStore;
		return { tabs, activeTab };
	};

	getTabLabel(tab) {
		return (
			<Menu.Item key={tab}>
				<InventoryTab tab={tab} props={this.props} />
			</Menu.Item>
		);
	}

	getInventoryTab(tab) {
		const { show_workflow } = this.state;
		const className = `detail-pane${show_workflow ? " workflow " : " "}inventory-${tab.toLowerCase()}`;
		return (
			<Tab.Pane className={className}>
				{tab === "Info" && <InventoryDetailSpecs {...this.props} />}
				<div className="tab-detail">{this.getInventoryTabDetail(tab)}</div>
			</Tab.Pane>
		);
	}

	getInventoryTabDetail(tab) {
		const { Vehicle_Notes, Inspections, Issues, Tasks, Vendors, Accessories, Stages, Miscellaneous } = this.state;
		const { dealer_phases } = this.props;
		const invProps = {
			inventory_item_id: this.props.detail.id,
			onAlert: alert => this.props.addAlert(alert),
			country_code: localStorage.getItem("selectedStoreCountryCode")
		};

		const infoProps = {
			...invProps,
			...this.props
		};

		const timelineProps = {
			...invProps,
			phases: dealer_phases,
			onHandleErrorResponse: error => handleErrorResponse(error),
			onAlert: alert => this.props.addAlert(alert),
			timelineFlags: {
				Vehicle_Notes,
				Inspections,
				Issues,
				Tasks,
				Vendors,
				Accessories,
				Stages,
				Miscellaneous
			}
		};

		switch (tab) {
			case "Info":
				return <InventoryInfo {...infoProps} />;
			case "Tasks":
				return <InventoryTasks {...this.props} />;
			case "Documents":
				return <InventoryDocuments {...invProps} />;
			case "Accessories":
				return <InventoryAccessories {...invProps} />;
			case "Leads":
				return <InventoryLeads {...invProps} />;
			case "Inspection":
				const jobTitleSetting = this.props.store_settings.find(setting => setting.name === "TECHNICIAN_JOB_TITLE_IDS");
				const jobTitleIds = Array.from(jobTitleSetting.value.split(",")).map(item => Number(item));
				const confirmation = this.props.store_settings.find(setting => setting.name === "INSPECTION_CONFIRMATIONS");
				const reworkPriceEditSetting = this.props.store_settings.find(setting => setting.name === "ALLOW_REWORK_AMOUNT_EDITING");
				return (
					<InventoryInspection
						{...{
							...invProps,
							jobTitleIds,
							enableItemConfirm: JSON.parse(confirmation.value),
							enableItemPriceEditInRework: JSON.parse(reworkPriceEditSetting.value)
						}}
					/>
				);
			case "Timeline":
				return <TimelineView {...timelineProps} key={this.state.timeline_update} />;
			default:
				return <span />;
		}
	}

	static getDerivedStateFromProps(nextProps: LMI.InventoryDetailProps, prevState: LMI.InventoryDetailState) {
		let derived: LMI.InventoryDetailState = prevState;
		const show_workflow = nextProps.detail && nextProps.detail.phase ? true : false;
		const is_sub = !nextProps.match.path.includes("inventory-detail");
		const detail_class = `inventory-detail${show_workflow ? " workflow" : ""}${is_sub ? "" : " full-screen"}${
			nextProps.is_workflow_screen ? " workflow-screen" : ""
		}`;
		if (!nextProps.showHeader && !is_sub) document.title = InventoryDetailComponent.documentTitle(nextProps.detail);
		return { ...derived, ...{ show_workflow, is_sub, detail_class } };
	}

	static documentTitle(detail) {
		return detail ? `${detail.stock_number} - ${detail.vehicle_title ? detail.vehicle_title : "Inventory Detail"}` : "Inventory Detail";
	}

	static printDetail(detail) {
		console.log("ADD PRINT FUNCTIONALITY", detail);
		const printWindow = window.open(
			`/admin/dealerships/inventory-detail/${detail.inventory_id}`,
			InventoryDetailComponent.documentTitle(detail),
			"width=900,height=900"
		);
		setTimeout(() => printWindow && printWindow.print(), 5000);
	}

	componentDidMount() {
		if (this.props.showHeader && !this.state.is_sub) this.props.toggleHeader(false);
	}

	componentWillUnmount() {
		if (!this.props.showHeader && !this.state.is_sub) this.props.toggleHeader(true);
	}

	componentDidUpdate(prevProps, prevState) {
		const { modifyGridItem, detail } = this.props;
		if (prevProps.detail !== detail && modifyGridItem) {
			modifyGridItem({ item: detail });
		}
		if (false) checkComponentUpdate(this.props, prevProps, this.state, prevState);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions,
		showHeader: state.app.admin.showHeader
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		},
		toggleHeader: showHeader => {
			dispatch(toggleHeader(showHeader));
		}
	};
};

export const InventoryDetail = compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventoryDetail, {
		options: (props: any) => {
			const guidParam = props.match.params && props.match.params.id;
			const inv_guid = props.inventory_id ? props.inventory_id : guidParam ? guidParam : null;
			return {
				variables: { store_id: props.storeId, inv_guid },
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inventory_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				detail: {
					...inventory_detail,
					...{
						vehicle_title: `${inventory_detail.year} ${inventory_detail.make} ${inventory_detail.model}`
					}
				},
				refetchDetail: refetch
			};
		}
	}),
	graphql<LMI.IOrgSettingsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.settings.store, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					names: [
						"USED_INVENTORY_RANGE_BUCKET",
						"NEW_INVENTORY_RANGE_BUCKET",
						"CERTIFIED_INVENTORY_RANGE_BUCKET",
						"TECHNICIAN_JOB_TITLE_IDS",
						"INSPECTION_CONFIRMATIONS",
						"ALLOW_REWORK_AMOUNT_EDITING"
					]
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_settings, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				store_settings,
				refetchSettings: refetch
			};
		}
	}),
	graphql<LMI.IDealerPhasesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.phases, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					hasNew: null,
					hasUsed: null
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, dealer_phases, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				dealer_phases: dealer_phases.phases,
				dealer_phasers: dealer_phases.phasers,
				refetchRecon: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.reconLevels, {
		skip: (ownProps: any) => !ownProps.storeId,
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_recon_levels } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				recon_levels: store_recon_levels.recon_levels
			};
		}
	})
)(InventoryDetailComponent) as React.ComponentType<any>;

export default InventoryDetail;
