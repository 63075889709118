// node_modules
import * as React from "react";
// components
import { Card } from "./Card";
import { EditableCard } from "./EditableCard";
import { restAPI } from "../../../../../../utils/rest";

let drag_element: HTMLElement = null;

export class ReconCardView extends React.Component<LMI.IReconCardProps, LMI.IReconCardState> {
	constructor(props: LMI.IReconCardProps) {
		super(props);

		this.state = {
			phase_count: props.inventory ? props.inventory.length : 0,
			is_up: null
		};
	}

	findOrder = (element: HTMLElement, className: string) => {
		if (!element) return;
		if (element.classList.contains(className)) {
			return {
				id: parseInt(element.getAttribute("data-id"), 10),
				order: parseInt(element.getAttribute("data-order"), 10)
			};
		}
		return this.findOrder(element.parentElement, className);
	};

	findElement = (element: HTMLElement, className: string = "card") => {
		if (element.classList.contains(className)) {
			return element;
		}
		return this.findElement(element.parentElement, className);
	};

	hideCardBody = dragId => {
		// Shrink the cards so we can see them all during dnd
		const cards = document.querySelectorAll(".card-body");
		[].slice.call(cards).forEach(card => {
			// Don't minimize the dragee
			if (card.parentNode.getAttribute("data-id") !== dragId.toString()) {
				if (!card.classList.contains("minimize")) {
					card.classList.add("minimize");
				}
			}
		});
	};

	handleDragStart = event => {
		event.target.style.opacity = 0.1;
		drag_element = event.target;

		event.dataTransfer.effectAllowed = "move";
		event.dataTransfer.setData(
			"text/plain",
			JSON.stringify({
				id: event.target.getAttribute("data-id"),
				order: event.target.getAttribute("data-order")
			})
		);
	};

	handleDragEnd = event => {
		event.target.style.opacity = 1;
	};

	handleDragEnter = event => {
		const containers = document.querySelectorAll(".card-column");
		const containerArray = [].slice.call(containers);
		const containerArrayIds = containerArray.map(c => c.getAttribute("data-id"));
		const overElement = this.findOrder(event.target, "card");
		const overIndex = containerArrayIds.indexOf(overElement.id.toString());
		const dragElement = this.findOrder(drag_element, "card");
		const dragIndex = containerArrayIds.indexOf(dragElement.id.toString());

		// if the position is -1 then prev sib moves fwd 1, -2 pref sib fwd 2, etc. (-fwd, +back)
		// So, item[dragIndex - position] gets replaced by dragElement and then adjust siblings
		let position = overIndex - dragIndex;
		// When position is positive, it's to the right of the dragElement so increment by one.
		if (position > 0) position++;
		if (dragIndex + position === containers.length) {
			containers[dragIndex + position - 1].nextElementSibling.parentNode.insertBefore(containers[dragIndex], containers[dragIndex + position]);
		} else containers[dragIndex + position].parentNode.insertBefore(containers[dragIndex], containers[dragIndex + position]);
	};

	handleDrop = event => {
		if (event.stopPropagation) {
			event.stopPropagation();
		}

		const drag = JSON.parse(event.dataTransfer.getData("text/plain"));
		const drop = this.findOrder(event.target, "card");

		if (drag.id !== drop.id) {
			const containers = document.querySelectorAll(".card-column");
			const containerArray = [].slice.call(containers);
			const orderedPhases = containerArray
				.map((c, i) => {
					return { id: c.getAttribute("data-id"), order: i };
				})
				.filter(c => c.id);
			restAPI({
				endpointName: "modify_store_phases",
				urlArgs: [this.props.storeId],
				data: orderedPhases,
				callback: error => {
					let alert;
					if (error) {
						alert = { type: "danger", message: this.props.onHandleErrorResponse(error) };
						this.props.onSendAlert(alert);
					} else {
						alert = {
							type: "success",
							message: `Store phases successfully updated.`
						};
						console.log(alert);
					}
				}
			});
		}

		return false;
	};

	handleDragOver = event => {
		if (event.preventDefault) {
			event.preventDefault(); // Necessary. Allows us to drop.
		}

		// Allow moving of elements
		event.dataTransfer.dropEffect = "move";

		return false;
	};

	phaseThreshold() {
		const msThresholds = this.calculateMilliseconds();
		const elapsedMS = this.calculateElapsedMilliseconds();
		return { warning: elapsedMS > msThresholds.warning, error: elapsedMS > msThresholds.error };
	}

	calculateMilliseconds() {
		const {
			warning_threshold_days: wDays,
			warning_threshold_hours: wHours,
			warning_threshold_minutes: wMinutes,
			error_threshold_days: eDays,
			error_threshold_hours: eHours,
			error_threshold_minutes: eMinutes
		} = this.props;

		const wDayMS = wDays * 24 * 60 * 60 * 1000;
		const wHoursMS = wHours * 60 * 60 * 1000;
		const wMinutesMS = wMinutes * 60 * 1000;
		const wMS = wDayMS + wHoursMS + wMinutesMS;
		const eDayMS = eDays * 24 * 60 * 60 * 1000;
		const eHoursMS = eHours * 60 * 60 * 1000;
		const eMinutesMS = eMinutes * 60 * 1000;
		const eMS = eDayMS + eHoursMS + eMinutesMS;
		return { warning: wMS, error: eMS };
	}

	calculateElapsedMilliseconds() {
		const { reconIntervalAverages } = this.props;
		const { days, hours, minutes, seconds } = (reconIntervalAverages as any).elapsed;

		const dayMS = days * 24 * 60 * 60 * 1000;
		const hoursMS = hours * 60 * 60 * 1000;
		const minutesMS = minutes * 60 * 1000;
		const secondsMS = seconds * 1000;
		return dayMS + hoursMS + minutesMS + secondsMS;
	}

	render() {
		const {
			storeId,
			editable,
			draggable,
			order,
			id,
			description,
			name,
			warning_threshold_days,
			warning_threshold_hours,
			warning_threshold_minutes,
			error_threshold_days,
			error_threshold_hours,
			error_threshold_minutes,
			work_timer_enabled,
			color_code,
			inventory,
			user_assignments,
			job_title_assignments,
			recon_assignments,
			final_stage,
			has_new,
			has_used,
			reconIntervalAverages
		} = this.props;

		const thresholds = reconIntervalAverages && this.phaseThreshold();

		const editableProps = {
			storeId,
			order,
			id,
			description,
			name,
			warning_threshold_days,
			warning_threshold_hours,
			warning_threshold_minutes,
			error_threshold_days,
			error_threshold_hours,
			error_threshold_minutes,
			work_timer_enabled,
			color_code,
			inventory,
			user_assignments,
			job_title_assignments,
			recon_assignments,
			final_stage,
			has_new,
			has_used,
			draggable,
			onHandleErrorResponse: error => this.props.onHandleErrorResponse(error),
			onSendAlert: alert => this.props.onSendAlert(alert),
			onHandleModalOpen: props => this.props.onHandleModalOpen(props),
			refetchRecon: this.props.refetchRecon,
			reconIntervalAverages,
			eventProps: {
				onDragOver: this.handleDragOver,
				onDragStart: this.handleDragStart,
				onDragEnd: this.handleDragEnd,
				onDragEnter: this.handleDragEnter,
				onDrop: this.handleDrop
			}
		};

		const notEditableProps = {
			...this.props,
			isUp: this.state.is_up,
			thresholds
		};

		return editable ? <EditableCard {...editableProps} /> : <Card {...notEditableProps} />;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.phase_count !== this.state.phase_count) {
			setTimeout(() => this.setState({ is_up: null }), 2000);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.inventory && nextProps.inventory.length !== prevState.phase_count) {
			const is_up = prevState.phase_count - nextProps.inventory.length < 0;
			return { phase_count: nextProps.inventory.length, is_up };
		}
		return { is_up: null };
	}
}

export default ReconCardView;
