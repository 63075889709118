import { restAPI } from "../../../../../../../../../utils/rest";
import { addAlert } from "api/redux/actions";
// node_modules
import { gqlQueries } from "gql-imports";
import { stringToInt } from "client/utils/functions";
import { getStoreTypeId } from "client/utils/userAccess";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Loader,
  Message,
  Input,
} from "semantic-ui-react";
// components
import { ConfirmOverrideModal } from "../../components";

import "./PermissionsModalComponent.css";

export class PermissionsModalComponentView extends React.Component<
  LMI.IEmpDRMProps,
  LMI.IEmpDRMState
> {
  constructor(props) {
    super(props);
    this.state = {
      newRole: null,
      deleteRole: null,
      addRole: !props.employeeRoles.length ? true : false,
      confirmOverride: false,
      newRoleFilter: null,
    };
  }

  getCustomPermissions = () => {
    const props = this.props;
    if (props.employeeRoles && props.roles) {
      const empRoleIds = props.employeeRoles.map((r) => {
        const roleOptions = this.getRoleOption(r);
        if (r && r.name) return roleOptions && roleOptions.id;
      });
      const empRolePermissions = [].concat.apply(
        [],
        props.roles
          .map((role) => {
            const roleIdIndex = empRoleIds.findIndex((i) => i === role.id);
            if (roleIdIndex !== -1) return role.permissions.map((i) => i.id);
          })
          .filter((i) => i !== undefined)
      );
      const customPermissions = [
        ...props.employeePermissions
          .map((p) => {
            const rolePermIndex = empRolePermissions.findIndex(
              (i) => i === parseInt(p.permission_id, 10)
            );
            if (rolePermIndex === -1)
              return {
                id: parseInt(p.permission_id, 10),
                added: true,
              };
          })
          .filter((i) => i !== undefined),
        ...empRolePermissions
          .map((p) => {
            const empPermIndex = props.employeePermissions.findIndex(
              (i) => p === parseInt(i.permission_id, 10)
            );
            if (empPermIndex === -1)
              return {
                id: p,
                added: false,
              };
          })
          .filter((i) => i !== undefined),
      ];
      return customPermissions
        .map((p) => {
          const matchingPerm =
            props.permissions &&
            props.permissions.find((perm) => perm.id === p.id);
          if (matchingPerm)
            return {
              ...p,
              ...{ description: matchingPerm && matchingPerm.description },
            };
        })
        .filter((i) => i !== undefined);
    } else return null;
  };

  render() {
    const props = this.props;
    const customPerms = this.getCustomPermissions();

    const confirmProps: LMI.ICustomConfirmRoles = Object.assign({}, props, {
      customPermissions: null,
      closeCallback: () => {
        this.setState({ confirmOverride: false });
      },
      confirmCallback: (permissions: LMI.IEmployeeCustomPermissions) => {
        if (this.state.newRole) {
          this.saveRole(
            props.storeId,
            props.employeeId,
            this.state.newRole.id,
            permissions
          );
          this.setState({ confirmOverride: false });
        } else if (this.state.deleteRole) {
          this.deleteRole(
            props.storeId,
            props.employeeId,
            this.state.deleteRole,
            permissions
          );
          this.setState({ confirmOverride: false, deleteRole: null });
        }
      },
    });

    const Alphebetical = (a, b) => {
      const ta = a.text.toUpperCase();
      const tb = b.text.toUpperCase();
      return ta < tb ? -1 : ta > tb ? 1 : 0;
    };

    const roleoptions =
      props.roles &&
      props.roles
        .filter((role) => {
          if (this.state.newRoleFilter)
            return role.name
              .toLowerCase()
              .includes(this.state.newRoleFilter.toLowerCase());
          else
            return role.id === 7 ||
              props.employeeRoles.find((erole) => role.name === erole.name)
              ? false
              : true;
        })
        .map((role) => {
          return {
            key: role.id,
            text: role.name,
            value: role.id,
          };
        })
        .sort(Alphebetical);

    return (
      <Container className="permissions-content">
        {this.state.confirmOverride ? (
          <ConfirmOverrideModal {...confirmProps} />
        ) : (
          ""
        )}
        <Grid>
          <Grid.Column width={roleoptions ? 10 : 15}>
            {props.roles ? (
              <Container>
                <span>
                  Access Role
                  {props.employeeRoles.length > 1 && "s"}:{" "}
                </span>
                <List horizontal>
                  {props.employeeRoles
                    ? props.employeeRoles.map((role, key) => {
                        const roleOption = this.getRoleOption(role);
                        return (
                          <List.Item key={key}>
                            <Label size="large">
                              {role.name}
                              <Icon
                                name="delete"
                                onClick={() =>
                                  this.setState({
                                    deleteRole: roleOption.id,
                                    confirmOverride: true,
                                  })
                                }
                              />
                            </Label>
                          </List.Item>
                        );
                      })
                    : ""}
                </List>
              </Container>
            ) : (
              ""
            )}
          </Grid.Column>
          <Grid.Column width={roleoptions ? 6 : 1}>
            <Container textAlign="right">
              {roleoptions ? (
                <Button.Group className="roleselector">
                  <Dropdown
                    text={
                      this.state.newRole
                        ? this.state.newRole.name
                        : "Select a Role"
                    }
                    icon="location arrow"
                    floating
                    labeled
                    button
                    closeOnBlur
                    className="icon store-selector"
                  >
                    <Dropdown.Menu>
                      <Input
                        icon="search"
                        iconPosition="left"
                        className="search"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e, data) =>
                          this.setState({ newRoleFilter: data.value })
                        }
                      />
                      <Dropdown.Menu scrolling>
                        {roleoptions.map((role, index) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              value={role.value}
                              onClick={(e, data) => this.selectRole(data)}
                            >
                              {role.text}
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button
                    positive
                    disabled={this.state.newRole ? false : true}
                    onClick={() => this.setState({ confirmOverride: true })}
                  >
                    Add
                  </Button>
                </Button.Group>
              ) : (
                ""
              )}
            </Container>
          </Grid.Column>
          <Grid.Column width={16}>
            <Divider horizontal>Permissions</Divider>
            <Grid divided>
              <Grid.Column
                width={customPerms && customPerms.length > 0 ? 11 : 16}
              >
                {props.permissions ? (
                  <List className="permissionsList">
                    {props.employeeRoles.length ? (
                      props.permissions.map((perm, key) => {
                        return (
                          <List.Item key={perm.id}>
                            <Checkbox
                              id={key}
                              checked={
                                props.employeePermissions.find(
                                  (emp) =>
                                    emp.permission_id === perm.id.toString()
                                )
                                  ? true
                                  : false
                              }
                              label={perm.description}
                              onClick={(e, data) => {
                                this.updatePermission(
                                  props.storeId,
                                  props.employeeId,
                                  data,
                                  perm
                                );
                              }}
                            />
                          </List.Item>
                        );
                      })
                    ) : (
                      <div>
                        <br />
                        <br />
                        <Header as="h3" textAlign="center">
                          Please select a Role to see a list of permissions.
                        </Header>
                        <br />
                        <br />
                      </div>
                    )}
                  </List>
                ) : (
                  <div>
                    <br />
                    <br />
                    <Dimmer active inverted>
                      <Loader active />
                    </Dimmer>
                    <br />
                    <br />
                  </div>
                )}
              </Grid.Column>
              {customPerms ? (
                <Grid.Column width={5}>
                  {customPerms.map((p, i) => {
                    return (
                      <Message
                        key={i}
                        size="mini"
                        icon={`${p.added ? "add" : "remove"} circle`}
                        color={p.added ? "green" : "red"}
                        content={p.description}
                      />
                    );
                  })}
                </Grid.Column>
              ) : (
                ""
              )}
            </Grid>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }

  getRoleOption = (role) => {
    if (role) return this.props.roles.find((erole) => erole.name === role.name);
  };

  selectRole(data: any) {
    console.log(data);

    const roley = this.props.roles.find((role) => role.id === data.value);
    this.setState({
      newRole: roley,
    });
  }

  updatePermission(storeId: string, employeeId: number, data: any, perm: any) {
    const change = { add_or_remove: data.checked ? "A" : "R" };
    restAPI({
      endpointName: "updateStoreEmployeePermissions",
      urlArgs: [storeId.toString(), employeeId.toString(), perm.id.toString()],
      data: change,
      callback: (err, res) => {
        if (!err) {
          this.props.onRefresh();
        } else {
          this.props.addAlert({
            type: "danger",
            message: err.reason.response.data.message,
            timeout: 3000,
          });
        }
      },
    });
  }

  saveRole(
    storeId: string,
    employeeId: number,
    roleId: any,
    customPerms: LMI.IEmployeeCustomPermissions = null
  ) {
    const customPermissions: LMI.ICustomConfirmRolePermsREST[] =
      customPerms && customPerms.permissions.length
        ? customPerms.permissions.map((perm) => {
            return {
              permission_id: perm.permission_id,
              add_or_remove: perm.add_or_remove,
            };
          })
        : null;
    restAPI({
      endpointName: "addStoreEmployeeRole",
      urlArgs: [storeId.toString(), employeeId.toString(), roleId.toString()],
      data: customPermissions,
      callback: (err, res) => {
        if (!err) {
          this.props.onRefresh();
        } else {
          this.props.addAlert({
            type: "danger",
            message: err.reason.response.data.message,
            timeout: 3000,
          });
        }
      },
    });
    this.setState({
      newRole: null,
      addRole: false,
    });
  }

  deleteRole(
    storeId: string,
    employeeId: number,
    roleId: any,
    customPerms: LMI.IEmployeeCustomPermissions = null
  ) {
    const customPermissions: LMI.ICustomConfirmRolePermsREST[] =
      customPerms && customPerms.permissions.length
        ? customPerms.permissions.map((perm) => {
            return {
              permission_id: perm.permission_id,
              add_or_remove: perm.add_or_remove,
            };
          })
        : null;
    restAPI({
      endpointName: "deleteStoreEmployeeRole",
      urlArgs: [storeId.toString(), employeeId.toString(), roleId.toString()],
      data: customPermissions,
      callback: (err, res) => {
        if (!err) {
          this.props.onRefresh();
        } else {
          this.props.addAlert({
            type: "danger",
            message: err.reason.response.data.message,
            timeout: 3000,
          });
        }
      },
    });
    this.setState({
      addRole: false,
    });
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const PermissionsModalComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IPermissionsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.permissions,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: stringToInt(props.storeId),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, permissions, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true, error: error.graphQLErrors[0].message };
        }
        return {
          permissions,
          refetch,
        };
      },
    }
  ),
  graphql<LMI.IRolesQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.roles,
    {
      options: (props: any) => {
        const storeTypeId = getStoreTypeId();
        return {
          variables: {
            storeType: storeTypeId,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, roles, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true, error };
        }

        return {
          roles,
          rRefetch: refetch,
        };
      },
    }
  )
)(PermissionsModalComponentView) as React.ComponentType<any>;

export default PermissionsModalComponent;
