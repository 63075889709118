import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { toggleAddInventoryDialog } from "api/redux/actions";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import AddInventoryComponent from "./AddInventoryComponent";

export class AddInventoryModal extends React.Component<LMI.IAdminReduxState, undefined> {
	render() {
		return (
			<ModalComponent
				headerText="Add Inventory"
				size="tiny"
				className="docked-modal"
				shouldBeOpen={this.props.addInventoryModalOpen}
				contentComponent={() => <AddInventoryComponent />}
				onClose={() => this.props.toggleAddInventoryDialog(false)}
			/>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		addInventoryModalOpen: state.app.admin.addInventoryModalOpen
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		toggleAddInventoryDialog: toggle => {
			dispatch(toggleAddInventoryDialog(toggle));
		}
	};
};

export const AddInventory = compose(connect(mapStateToProps, mapDispatchToProps))(AddInventoryModal);
export default AddInventory;
