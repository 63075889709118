import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { flowRight as compose } from "lodash";
// node_modules
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import {
  Loader,
  Image,
  Grid,
  Segment,
  Button,
  Header,
  Icon,
  Container,
  Dimmer,
  Input,
} from "semantic-ui-react";

export class AddSaniSearchView extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      selected: null,
      leadName: null,
    };
  }

  getEmptyResult() {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name="search" />
          Sorry, no inventory currently matches your search
        </Header>
        <Segment.Inline>
          <Button onClick={() => this.props.searchAgain()}>Search Again</Button>
        </Segment.Inline>
      </Segment>
    );
  }

  getInventoryItem(item: LMI.IInventoryGQL, selected = false) {
    return (
      <Grid.Row
        key={item.id}
        onClick={() => this.setState({ selected: item })}
        className={`sani-search ${selected ? "selected" : "selectable"}`}
      >
        <Grid.Column width={3}>
          <Image
            src={`${item.photo_url}?h=53&w=80&auto=compress%20enhance`}
            size="tiny"
            rounded
            loading="lazy"
          />
        </Grid.Column>
        <Grid.Column width={13}>
          <Grid.Row className="ymm">
            {item.year} {item.make} {item.model}
          </Grid.Row>
          <Grid.Row id="vin">
            <b>VIN:</b> {item.vin}
          </Grid.Row>
          <Grid.Row id="stock">
            <b>Stock#:</b> {item.stock_number}
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    const { loading, inventory_results } = this.props;
    const { selected } = this.state;

    if (loading)
      return (
        <Dimmer active inverted>
          <Loader inverted>Searching</Loader>
        </Dimmer>
      );

    if (inventory_results && inventory_results.length < 1)
      return this.getEmptyResult();

    if (selected || (inventory_results && inventory_results.length === 1)) {
      const inventoryItem = selected ? selected : inventory_results[0];
      return (
        <div className="selected-item">
          <Grid>
            {this.getInventoryItem(inventoryItem, true)}
            <Grid.Row>
              <Grid.Column width={5}>
                <Input
                  onChange={(e, data) =>
                    this.setState({ leadName: data.value })
                  }
                  placeholder="Optional Lead Name"
                />
              </Grid.Column>
              <Grid.Column width={11}>
                <Container textAlign="right">
                  <Button
                    type="submit"
                    size="large"
                    onClick={() => this.props.searchAgain()}
                  >
                    Search Again
                  </Button>
                  <Button
                    type="submit"
                    positive
                    size="large"
                    onClick={() =>
                      this.props.createSanitization(
                        inventoryItem,
                        this.state.leadName
                      )
                    }
                  >
                    Create Sanitization
                  </Button>
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    }

    return (
      <Grid>
        {inventory_results.map((inventoryItem: LMI.IInventoryGQL) => {
          return this.getInventoryItem(inventoryItem);
        })}
        <Grid.Row>
          <Grid.Column>
            <Container textAlign="right">
              <Button
                type="submit"
                size="large"
                onClick={() => this.props.searchAgain()}
              >
                Search Again
              </Button>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const AddSaniSearch = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.inventorySearchPartDuex,
    {
      options: (props: any) => {
        return {
          variables: {
            store_ids: parseInt(props.storeId, 10),
            search_text: props.term,
            sold: false,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, inventory_search2, refetch, fetchMore },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          inventory_results: inventory_search2.items,
          refetchInventory: refetch,
          reload: (variables) => {
            return fetchMore({
              variables,
              updateQuery(prev: any, { fetchMoreResult }) {
                return fetchMoreResult;
              },
            });
          },
        };
      },
    }
  )
)(AddSaniSearchView) as React.ComponentType<any>;

export default AddSaniSearch;
