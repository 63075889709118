// node_modules
import * as React from "react";
import { Grid, Input, Segment, Image, Popup, Divider } from "semantic-ui-react";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import { zeroPlus } from "client/utils/functions";

export class InspectionForm extends React.Component<LMI.IReconInspectionFormProps, LMI.IReconInspectionFormState> {
	static maxValues = {
		treadDepth: 32,
		brakeThick: 12,
		batteryHealth: 100,
		batteryCharge: 100,
		cca: 999,
		tireSize: 25
	};

	constructor(props: LMI.IReconInspectionFormProps) {
		super(props);

		this.state = {
			inspection: props.inspection,
			editable: props.can_edit
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.inspection !== this.props.inspection) {
			this.setState({ inspection: this.props.inspection });
		}
	}

	saveInspection = () => {
		this.props.updateInspection(this.state.inspection);
	};

	enforceMaxValue(data: any, maxValue: number) {
		if (data.value < 0) return null;
		const value = parseInt(data.value, 10) <= maxValue ? data.value : data.value.slice(0, -1);
		return value !== "" ? parseInt(value, 10) : null;
	}

	updateHandler(value: any, name: string, update = false) {
		const { inspection, editable } = this.state;
		if (editable) {
			const newInspection = { ...inspection, ...{ [name]: value } };
			this.setState({ inspection: newInspection });
			if (update) this.saveInspection();
		}
	}

	formatTechnicians(techs: LMI.IReconInspectionTechnicians): any[] {
		return techs
			? techs.technicians.map((t, key) => {
					if (t)
						return {
							text: `${t.user.first_name} ${t.user.last_name}`,
							value: t.id,
							key
						};
			  })
			: [];
	}

	render() {
		const { technicians } = this.props;
		const { inspection, editable } = this.state;
		const { batteryHealth, cca, treadDepth, brakeThick } = InspectionForm.maxValues;

		const technicianOptions = this.formatTechnicians(technicians);
		const hasTechs = technicianOptions && technicianOptions.length > 0;
		const techPlaceholder = hasTechs ? "Select Technician" : "No Technician's Configured";
		const selectedTech =
			inspection && inspection.technician_emp_id && technicianOptions ? technicianOptions.find(t => t.value === inspection.technician_emp_id) : null;
		const techValue = selectedTech ? selectedTech.text : "";

		// setup input values
		const battery_health = inspection && zeroPlus(inspection.battery_health);
		const actual_cca = inspection && zeroPlus(inspection.actual_cca);
		const rated_cca = inspection && zeroPlus(inspection.rated_cca);
		const tire_lf = inspection && zeroPlus(inspection.tire_lf);
		const tire_lr = inspection && zeroPlus(inspection.tire_lr);
		const tire_rf = inspection && zeroPlus(inspection.tire_rf);
		const tire_rr = inspection && zeroPlus(inspection.tire_rr);
		const brake_lf = inspection && zeroPlus(inspection.brake_lf);
		const brake_lr = inspection && zeroPlus(inspection.brake_lr);
		const brake_rf = inspection && zeroPlus(inspection.brake_rf);
		const brake_rr = inspection && zeroPlus(inspection.brake_rr);

		return (
			<div className="inspection-form">
				<div className="form-group">
					<Segment>
						<Grid>
							<Grid.Row>
								<Grid.Column width={3} className="form-group-icons">
									<Popup trigger={<Image src="/images/technician_with_wrench.svg" alt="Technician" />} content="Technician" size="tiny" inverted />
								</Grid.Column>
								<Grid.Column width={13} className="tech-form">
									<AutoCompleteComponent
										id="itemAutoCompleteTech"
										cssClass="autocomplete"
										enabled={hasTechs && editable}
										autofill={true}
										ignoreCase={true}
										dataSource={technicianOptions}
										popupHeight="250px"
										popupWidth="250px"
										placeholder={techPlaceholder}
										value={techValue}
										focus={(e: any) => e.event.target.setAttribute("placeholder", "")}
										blur={() => document.getElementById("itemAutoCompleteTech").setAttribute("placeholder", techPlaceholder)}
										change={e => {
											if (e.isInteracted) this.updateHandler(e.itemData ? e.itemData.value : null, "technician_emp_id", true);
											else {
												const inputelem: any = e.element;
												inputelem.value = "";
											}
										}}
									/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row>
								<Grid.Column width={3} className="form-group-icons">
									<Popup trigger={<Image src="/images/ro_document.svg" alt="RO Document" />} content="RO Number" size="tiny" inverted />
								</Grid.Column>
								<Grid.Column width={13}>
									<Input
										fluid
										type="text"
										name="ro_number"
										placeholder="RO Number"
										disabled={!editable}
										value={inspection && inspection.ro_number ? inspection.ro_number : ""}
										onChange={(e, data) => this.updateHandler(data.value, "ro_number")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</div>
				<div className="form-group">
					<Segment className="sm-form">
						<Grid className="sm-form-inputs">
							<Grid.Row className="tight">
								<Grid.Column width={3} className="form-group-icons">
									<Popup
										trigger={<Image src="/images/car-battery.svg" alt="Car Battery" size="mini" style={{ right: "9px" }} />}
										content="Battery Health/Charge"
										size="tiny"
										inverted
									/>
								</Grid.Column>
								<Grid.Column width={13}>
									<label className="cca_labels">Health</label>
									<Input
										fluid
										type="number"
										name="battery_health"
										placeholder=""
										disabled={!editable}
										value={battery_health !== null ? battery_health : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, batteryHealth), "battery_health")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row className="tight">
								<Grid.Column width={16}>
									<Divider className="cca_label space" horizontal>
										CCA (Cold Cranking Amps)
									</Divider>
								</Grid.Column>
								<Grid.Column width={8}>
									<label className="cca_labels">Actual</label>
									<Input
										fluid
										type="number"
										name="actual_cca"
										placeholder=""
										disabled={!editable}
										value={actual_cca !== null ? actual_cca : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, cca), "actual_cca")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
								<Grid.Column width={8}>
									<label className="cca_labels">Rated</label>
									<Input
										fluid
										type="number"
										name="rated_cca"
										placeholder=""
										style={{ marginLeft: "4px" }}
										disabled={!editable}
										value={rated_cca !== null ? rated_cca : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, cca), "rated_cca")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</div>
				<div className="form-group">
					<Segment className="sm-form">
						<Grid className="sm-form-inputs">
							<Grid.Row className="tight">
								<Grid.Column width={3} className="form-group-icons">
									<Popup trigger={<Image src="/images/tire.svg" alt="Tire" style={{ right: "9px" }} />} content="Tire Size/Tread Depth" size="tiny" inverted />
								</Grid.Column>
								<Grid.Column width={13}>
									<label className="cca_labels">Size</label>
									<Input
										fluid
										name="tire_size"
										placeholder=""
										disabled={!editable}
										value={inspection && inspection.tire_size ? inspection.tire_size : ""}
										onChange={(e, data) => this.updateHandler(data.value, "tire_size")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row className="tight">
								<Grid.Column width={16}>
									<Divider className="cca_label" horizontal>
										Tread Depth
									</Divider>
								</Grid.Column>
								<span className="unit-lable">/32</span>
								<Grid.Column width={8}>
									<Input
										type="number"
										className="half-width sm pos-left"
										name="tire_lf"
										label="LF"
										labelPosition="left"
										placeholder=""
										disabled={!editable}
										value={tire_lf !== null ? tire_lf : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, treadDepth), "tire_lf")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
								<Grid.Column width={8}>
									<Input
										type="number"
										className="half-width sm pos-right"
										name="tire_rf"
										label="RF"
										labelPosition="right"
										placeholder=""
										disabled={!editable}
										value={tire_rf !== null ? tire_rf : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, treadDepth), "tire_rf")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row className="tight">
								<Grid.Column width={8}>
									<Input
										type="number"
										className="half-width sm pos-left"
										name="tire_lr"
										label="LR"
										labelPosition="left"
										placeholder=""
										disabled={!editable}
										value={tire_lr !== null ? tire_lr : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, treadDepth), "tire_lr")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
								<Grid.Column width={8}>
									<Input
										type="number"
										className="half-width sm pos-right"
										name="tire_rr"
										label="RR"
										labelPosition="right"
										placeholder=""
										disabled={!editable}
										value={tire_rr !== null ? tire_rr : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, treadDepth), "tire_rr")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</div>
				<div className="form-group" style={{ top: "-6px" }}>
					<Segment className="sm-form">
						<Grid className="sm-form-inputs">
							<Grid.Row className="brake-specific">
								<Grid.Column width={3} className="form-group-icons">
									<Popup trigger={<Image src="/images/disc-brake.svg" alt="Brakes" />} content="Brake Thickness" size="tiny" inverted />
								</Grid.Column>
								<Grid.Column width={13}>
									<Divider className="cca_label" style={{ marginTop: "9px" }} horizontal>
										Brake Thickness
									</Divider>
								</Grid.Column>
								<Grid.Column width={2} className="form-group-icons" />
								<span className="unit-lable">mm</span>
								<Grid.Column width={6}>
									<Input
										type="number"
										className="half-width pos-left"
										name="brake_lf"
										label="LF"
										labelPosition="left"
										placeholder=""
										disabled={!editable}
										value={brake_lf !== null ? brake_lf : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, brakeThick), "brake_lf")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
								<Grid.Column width={1} />
								<Grid.Column width={6}>
									<Input
										type="number"
										className="half-width pos-right"
										name="brake_rf"
										label="RF"
										labelPosition="right"
										placeholder=""
										disabled={!editable}
										value={brake_rf !== null ? brake_rf : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, brakeThick), "brake_rf")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row className="brake-specific">
								<Grid.Column width={2} className="form-group-icons" />
								<Grid.Column width={6}>
									<Input
										type="number"
										className="half-width pos-left"
										name="brake_lr"
										label="LR"
										labelPosition="left"
										placeholder=""
										disabled={!editable}
										value={brake_lr !== null ? brake_lr : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, brakeThick), "brake_lr")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
								<Grid.Column width={1} />
								<Grid.Column width={6}>
									<Input
										type="number"
										className="half-width pos-right"
										name="brake_rr"
										label="RR"
										labelPosition="right"
										placeholder=""
										disabled={!editable}
										value={brake_rr !== null ? brake_rr : ""}
										onChange={(e, data) => this.updateHandler(this.enforceMaxValue(data, brakeThick), "brake_rr")}
										onBlur={() => this.saveInspection()}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</div>
			</div>
		);
	}
}

export default InspectionForm;
