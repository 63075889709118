export const RegularExpressions = {
	ADDRESS: /^[a-zA-Z0-9 ,.'\-]+$/,
	CITY: /^[a-zA-Z0-9 ,.'\-]+$/,
	NAMES: /^[a-zA-Z0-9 ,.'\-]+$/,
	PHONE_NUMBER: /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/,
	PHONE_NUMBER_MASK: [/[1-9]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
	EMAIL: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	STATE: /^((A[LKZRS])|(C[AOT])|(D[EC])|(F[LM])|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EDAINSOTHP])|(N[EVHJMYCD])|(O[HKR])|(P[AWR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[TAI])|(W[AVIY]))$/,
	ISWEBSITE: /^http[s]?\:\/\//,
	CURRENCY: /([$,.])/,
	NUMBER: /([,])/,
	VIN: /^[A-HJ-NPR-Z0-9]{17}/
};
