import * as React from "react";
import { Button, Label, Segment } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { RegularExpressions } from "client/utils/regex";

export class GroupsDetailsComponent extends React.Component<
  LMI.IGroupsDetailsProps,
  LMI.IGroupsDetailsState
> {
  constructor(props) {
    super(props);
    this.state = {
      newgroup: props.group ? false : true,
    };
  }

  render() {
    const props = this.props;
    const errorLabel = <Label color="red" pointing="above" />;
    return (
      <div id="group-detail">
        <Form onSubmit={this.submitForm.bind(this)}>
          <Form.Field>
            <label>Group Name</label>
            <Input
              name="name"
              value={props.group && props.group.name}
              required
              validations={{
                matchRegexp: RegularExpressions.NAMES,
              }}
              validationErrors={{
                matchRegexp:
                  "Only letters, numbers, spaces, commas, periods, apostrophes, and dashes allowed.",
                isDefaultRequiredValue: "Name is required",
              }}
              errorLabel={errorLabel}
            />
          </Form.Field>
          <Segment basic textAlign="right">
            <Button.Group>
              <Button type="button" onClick={() => props.closeCallback()}>
                Cancel
              </Button>
              <Button.Or />
              <Button positive className="submit-btn" type="submit">
                Save
              </Button>
            </Button.Group>
          </Segment>
        </Form>
      </div>
    );
  }

  submitForm(formData: any) {
    if (this.state.newgroup) {
      this.props.onCreate(formData);
    } else {
      this.props.onSave(formData, this.props.group.id);
    }
  }
}

export default GroupsDetailsComponent;
