// node_modules
import { Form } from "formsy-semantic-ui-react";
import { FileUploadComponent } from "client/pages/admin/components/FileUploadComponent";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { RelativeDatePipe } from "client/utils/functions";
import { SystemLogType } from "loopmein-shared";
import * as React from "react";
import { Button, Divider, Icon, Image, Label, Table, TextArea } from "semantic-ui-react";
import { restAPI } from "../../../../../utils/rest";

export class InspectionNotes extends React.Component<LMI.IReconInspectionNotesProps, LMI.IReconInspectionNotesState> {
	constructor(props: LMI.IReconInspectionNotesProps) {
		super(props);

		this.state = {
			inspection_note: [],
			comment: null,
			uploadPhoto: false,
			previewPhoto: false,
			photo: null
		};
	}

	componentDidMount() {
		this.getInspectionNotes(this.props);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.inspection && this.props.inspection) {
			if (this.props.inspection.inventory_item_id !== prevProps.inspection.inventory_item_id) this.getInspectionNotes(this.props);
		}
	}

	async getInspectionNotes(props: LMI.IReconInspectionNotesProps): Promise<void> {
		const { onAlert } = this.props;
		if (props && props.inspection)
			restAPI({
				endpointName: "getInspectionNotes",
				urlArgs: [props.inspection.store_id, props.inspection.inventory_item_id],
				data: null,
				callback: (error, res) => {
					if (error)
						onAlert({
							type: "danger",
							message: error
						});

					this.setState({ inspection_note: res.data.inspection_note }, () =>
						this.props.toggleOpen(this.state.inspection_note && this.state.inspection_note.length > 0 ? true : false)
					);
				}
			});
	}

	handleSubmitNote = () => {
		const { inspection, onAlert } = this.props;
		const { comment } = this.state;

		if (!comment) return;
		if (inspection)
			restAPI({
				endpointName: "addInventoryNote",
				urlArgs: [inspection.store_id, inspection.inventory_item_id],
				data: {
					type: SystemLogType.INSPECTION_NOTE,
					comment,
					description: `Inspection ${inspection.ro_number ? inspection.ro_number : "Note"}`
				},
				callback: (error, res) => {
					this.getInspectionNotes(this.props);
					this.setState({ comment: null }, () => {
						onAlert({
							type: error ? "danger" : "success",
							message: error ? error : res.data.message
						});
					});
				}
			});
	};

	getCommentForm = () => {
		const { comment } = this.state;
		return (
			<Form noValidate>
				<div id="phaseBody" className="recon-form">
					<TextArea id="comment" rows={4} placeholder="Add Inspection Note" value={comment ? comment : ""} onChange={this.handleChangeComment.bind(this)} />
				</div>
				<Button type="submit" className="submit" id="complete" positive content="Add Note" disabled={!this.props.inspection} onClick={this.handleSubmitNote} />
			</Form>
		);
	};

	getCommentGrid = list => {
		return (
			<Table className="notes-table" celled>
				<Table.Body>
					{list.map(l => {
						return (
							<Table.Row key={l.created_at}>
								<Table.Cell>
									<div>{l.comment ? l.comment : "N/A"}</div>
									<div className="id">
										{l.description} - {l.created_by_user.full_name}, {RelativeDatePipe(l.created_at, false, false, false)}
									</div>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		);
	};

	preview() {
		return (
			<div className="photo-preview">
				<Image src={this.state.photo.image} loading="lazy" />
				<Divider hidden />
				<Button negative content="Remove Photo" onClick={() => this.setState({ photo: null, previewPhoto: false })} />
			</div>
		);
	}

	handleChangeComment = event => {
		this.setState({ comment: event.currentTarget.value });
	};

	handleUploadPhoto(photo) {
		console.log(photo);
		this.setState({ uploadPhoto: false, photo });
	}

	render() {
		const { is_open, toggleOpen, can_edit } = this.props;
		const { inspection_note, previewPhoto, uploadPhoto } = this.state;
		const notesCount = inspection_note && inspection_note.length;
		const fileProps = {
			filesToAccept: "image/png, image/jpeg",
			onSubmit: this.handleUploadPhoto.bind(this),
			onClose: () => {
				this.setState({ uploadPhoto: false, previewPhoto: false });
			}
		};

		return (
			<div className="forms pad notes">
				<ModalComponent
					size="small"
					headerText={uploadPhoto ? "Upload Photo" : "Photo"}
					className="upload-image-modal"
					shouldBeOpen={uploadPhoto || previewPhoto}
					contentComponent={() => {
						if (uploadPhoto) return <FileUploadComponent {...fileProps} />;
						else if (previewPhoto) return this.preview();
					}}
					onClose={() => {
						this.setState({ uploadPhoto: false, previewPhoto: false });
					}}
				/>

				<div id="inspectionNotes" onClick={() => toggleOpen()} className={`section-title ${is_open ? "needs-margin-bottom" : ""}`}>
					<span className="stitle">
						<Icon link name={is_open ? "angle down" : "angle right"} />
						<span>Inspection Notes</span>
					</span>
					<span className={`corner`}>
						{notesCount > 0 && (
							<Label circular size="tiny">
								{notesCount}
							</Label>
						)}
					</span>
				</div>

				{is_open ? (
					<div className="inspection-notes">
						<div className="notes-list">{inspection_note && this.getCommentGrid(inspection_note)}</div>
						{can_edit && <div className="notes-form">{this.getCommentForm()}</div>}
					</div>
				) : (
					<span />
				)}
			</div>
		);
	}
}
export default InspectionNotes;
