import * as React from "react";
import { Grid, Popup } from "semantic-ui-react";

export class GridWorkTimer extends React.Component<LMI.IWorkTimerProps, LMI.IWorkTimerState> {
	constructor(props: LMI.IWorkTimerProps) {
		super(props);

		this.state = {
			loading: false,
			elapsed_time: null,
			running: false,
			hovering: false
		};
	}

	componentDidMount() {
		const { id, current_phase, work_timer_running_since_ms, current_work_elapsed_ms } = this.props;
		if ((window as any).workInterval) clearInterval((window as any).workInterval[`${this.props.id}${this.props.current_phase}`]);
		else (window as any).workInterval = {};

		if (id) {
			const now = new Date();

			let elapsed;
			if (work_timer_running_since_ms > 0) {
				// New session, elapsed time not recoreded yet, so calculate it
				if (current_work_elapsed_ms === 0) {
					elapsed = now.getTime() - work_timer_running_since_ms;
				} else elapsed = current_work_elapsed_ms > 0 ? current_work_elapsed_ms + now.getTime() - work_timer_running_since_ms : 0;
			} else {
				elapsed = current_work_elapsed_ms;
			}

			this.setState({ loading: true, elapsed_time: elapsed, running: false }, () => {
				if (work_timer_running_since_ms) {
					const interval = 1000;
					(window as any).workInterval[`${id}${current_phase}`] = setInterval(() => {
						this.setState(prevState => ({ running: true, elapsed_time: prevState.elapsed_time + interval }));
					}, interval);
				}
			});
		}
	}

	calculateElapsedTime(elapsedTime) {
		let ms = elapsedTime;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = Math.floor(ms / 24);
		return {
			days: days.toString().padStart(2, "0"),
			hours: hours.toString().padStart(2, "0"),
			minutes: minutes.toString().padStart(2, "0"),
			seconds: seconds.toString().padStart(2, "0")
		};
	}

	render() {
		const { work_timer_started_by_name } = this.props;
		const { elapsed_time, running } = this.state;

		const time = this.calculateElapsedTime(elapsed_time);
		const { days, hours, minutes, seconds } = time;

		if (elapsed_time <= 0) return <span></span>;
		return (
			<div id={`workDays-${this.props.id}`} className={`work-days`}>
				<Grid columns={2}>
					<Grid.Column className="indicator-column" width={1}>
						<span className={`${running ? "cresc" : ""}`} id="indicator" />
					</Grid.Column>
					<Grid.Column className="time-column" width={8}>
						<Popup
							trigger={
								<span id="time" className={`${running ? "green" : ""}`}>
									<span style={{ color: "#666" }}>Time Spent: </span>
									{days}:{hours}:{minutes}:{seconds}
								</span>
							}
							disabled={!work_timer_started_by_name}
							content={`Started by: ${work_timer_started_by_name}`}
							size="tiny"
							inverted
						/>
					</Grid.Column>
				</Grid>
			</div>
		);
	}
}
