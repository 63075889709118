import { graphql } from "@apollo/react-hoc";
import { Form } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Button, Dropdown } from "semantic-ui-react";
import { getStoreTypeId } from "../../../../../../utils/userAccess";
import { restAPI } from "../../../../../../utils/rest";

export class JobTitleAssignmentView extends React.Component<LMI.IJobTitleAssignmentProps, LMI.IJobTitleAssignmentState> {
	constructor(props: LMI.IJobTitleAssignmentProps) {
		super(props);

		this.state = {
			value:
				props.dealer_phasers && props.dealer_phasers.job_title_assignments
					? props.dealer_phasers.job_title_assignments.filter(j => j.phase_id === props.phase_id).map(jp => jp.job_title_id)
					: null
		};
	}

	handleChange = (event, { value }) => {
		this.setState({ value });
	};

	handleSubmit = () => {
		const { value } = this.state;
		const { phase_id, storeId } = this.props;

		const data = [{ id: phase_id, job_titles: value }];

		restAPI({
			endpointName: "modify_store_phases",
			urlArgs: [storeId],
			data,
			callback: error => {
				let alert;
				if (error) {
					alert = { type: "danger", message: this.props.onHandleErrorResponse(error) };
					this.props.onSendAlert(alert);
				}
				this.props.onClose();
				this.props.onRefetch();
			}
		});
	};

	render() {
		const { value } = this.state;
		const { job_titles } = this.props;

		const options =
			job_titles &&
			job_titles.map(j => {
				return { key: j.id, text: j.name, value: j.id };
			});

		return [
			<Form noValidate>
				<div>Select the job title(s) to assign to this Stage.</div>
				<div id="phaseBody" className="recon-form">
					<Dropdown
						className="select"
						value={value}
						placeholder="Job Title"
						loading={!job_titles}
						fluid
						multiple
						search
						selection
						options={options}
						onChange={this.handleChange}
					/>
				</div>
			</Form>,
			<div className="cta-footer">
				<Button className="submit" id="save" positive content="Save" onClick={this.handleSubmit} />
			</div>
		];
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const JobTitleAssignment = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.jobTitles, {
		options: (props: any) => {
			const storeTypeId = getStoreTypeId();
			return {
				variables: {
					storeTypeId
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, job_titles, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				job_titles: job_titles.job_titles,
				refetch
			};
		}
	})
)(JobTitleAssignmentView) as React.ComponentType<any>;

export default JobTitleAssignment;
