import * as React from "react";
import { SortBySortOrder } from "client/utils/functions";
import Tour from "reactour";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { updateTour } from "api/redux/actions";
import { withRouter } from "react-router";
import { Icon } from "semantic-ui-react";

import "./Tours.css";

interface TourActions {
  action?: string;
  loadAction?: string;
  nextAction?: string;
  closeAction?: string;
}

export class LMITourView extends React.Component<
  LMI.ILmiTourProps,
  LMI.ILmiTourState
> {
  tour: Tour;
  constructor(props) {
    super(props);
    this.state = {
      startTour: false,
    };
  }

  render() {
    const { startTour } = this.state;
    const steps = this.getSteps();
    if (steps)
      return (
        <Tour
          ref={(c) => (this.tour = c)}
          isOpen={startTour}
          steps={steps}
          showNumber={false}
          showNavigation={false}
          disableInteraction={true}
          rounded={5}
          maskSpace={0}
          children={
            <div className="tour-title">
              <Icon name="map signs" color="blue" /> Tours
            </div>
          }
          prevButton={<span />}
          nextButton={
            <span className="ui mini button tour-next-btn">Next</span>
          }
          nextStep={() => this.nextStep()}
          className="lmi-screentour"
          lastStepNextButton={
            <span onClick={() => this.closeAction()} className="ui mini button">
              Done
            </span>
          }
          onRequestClose={() => {
            // run the final steps action to make sure any close event is run
            this.onRequestClose();
            this.props.updateTour(null);
          }}
        />
      );
    else return <span />;
  }

  getSteps(): any {
    const steps = this.props.steps ? [...this.props.steps] : [];
    if (!steps || steps.length <= 0) return null;
    return steps
      .map((step: LMI.ITourStepGQL) => {
        const { target, action, title, content } = step;
        const createContent = () => ({ __html: content });
        return {
          ...step,
          selector: target,
          action: (node) => this.fireAction(node, action),
          content: () => {
            return (
              <div className="tour-template">
                <h4>{title}</h4>
                <span dangerouslySetInnerHTML={createContent()} />
              </div>
            );
          },
        };
      })
      .sort(SortBySortOrder);
  }

  fireAction(node: HTMLElement, action: string) {
    if (action) {
      const actionObj: TourActions = JSON.parse(action);
      if (actionObj.action && node) {
        if (Array.isArray(actionObj.action))
          actionObj.action.forEach((actn) => eval(actn.action));
        else eval(actionObj.action);
      }
      if (actionObj.loadAction) {
        // nest your actions inside your components as elements with onClick events
        const actionElem = document.getElementById(actionObj.loadAction);
        if (actionElem) actionElem.click();
      }
    }
  }

  nextStep() {
    const { steps } = this.props;
    const step = steps[this.tour.state.current];
    if (step && step.action) {
      const actionObj: TourActions = JSON.parse(step.action);
      if (actionObj && actionObj.nextAction) {
        // nest your actions inside your components as elements with onClick events
        const actionElem = document.getElementById(actionObj.nextAction);
        if (actionElem) actionElem.click();
        // give the comp action time to run before nav to next step
        setTimeout(() => this.tour.nextStep(), 400);
      }
    } else this.tour.nextStep();
  }

  onRequestClose() {
    const { steps } = this.props;
    const finalStep = steps[steps.length - 1];
    if (finalStep.action) {
      const actionObj: TourActions = JSON.parse(finalStep.action);
      if (actionObj.action) {
        if (Array.isArray(actionObj.action))
          actionObj.action.forEach((actn) => eval(actn.action));
        else eval(actionObj.action);
      }
      let actionElem;
      if (actionObj.closeAction)
        actionElem = document.getElementById(actionObj.closeAction);
      else if (actionObj.loadAction)
        actionElem = document.getElementById(actionObj.loadAction);
      if (actionElem) actionElem.click();
    }
  }

  closeAction() {
    const { steps } = this.props;
    const finalStep = steps[steps.length - 1];
    const actionObj: TourActions = JSON.parse(finalStep.action);
    if (actionObj && actionObj.closeAction) {
      const actionElem = document.getElementById(actionObj.closeAction);
      if (actionElem) actionElem.click();
    }
  }

  static getDerivedStateFromProps(nextProps: LMI.ILmiTourProps) {
    let startTour = false;
    if (nextProps.steps && nextProps.steps.length > 0) {
      const steps = nextProps.steps ? [...nextProps.steps] : [];
      const tourSteps = steps.sort(SortBySortOrder);
      startTour = LMITourView.checkTourLocation(
        tourSteps[0],
        nextProps.history
      );
    }
    return { startTour };
  }

  static checkTourLocation = (step: LMI.ITourStepGQL, history: any) => {
    const locate = "setlocation";
    if (step.action && step.action.indexOf(locate) >= 0) {
      const location = step.action.substring(
        step.action.lastIndexOf("(") + 1,
        step.action.lastIndexOf(")")
      );
      const startTour = history.location.pathname === location;
      if (!startTour) history.push(location);
      return startTour;
    } else return true;
  };
}

const mapStateToProps = (state: any) => {
  return {
    steps: state.app.admin.steps,
  };
};

const matchDispatchToProps = (dispatch: any) => {
  return {
    updateTour: (steps: LMI.ITourStepGQL[]) => {
      dispatch(updateTour(steps));
    },
  };
};

export const LmiTour = compose(
  withRouter,
  connect(mapStateToProps, matchDispatchToProps)
)(LMITourView);
export default LmiTour;
