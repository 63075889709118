import * as React from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { DataSync } from "./DataSync";
import { Permission, StoreType } from "loopmein-shared";
import { hasPermission } from "client/utils/userAccess";
import { DataSyncQueries } from "./util";
import { enableDataSync } from "api/redux/actions";

interface DataSyncManagerProps extends LMI.IAppProps {
  store_settings: LMI.ISettingGQL[];
}

export class DataSyncManagerView extends React.Component<
  DataSyncManagerProps,
  any
> {
  getSetting(name: string) {
    const { store_settings } = this.props;
    const setting =
      store_settings && store_settings.find((set) => set.name === name);
    return setting ? JSON.parse(setting.value) : null;
  }

  getPolling(name: string) {
    const pollingInterval = this.getSetting(name);
    return pollingInterval && pollingInterval > 0 ? pollingInterval : 0;
  }

  getDataSyncQueries() {
    const { storeId, dataSync } = this.props;
    const syncList = [];
    const setSyncList = {
      inventory:
        this.getSetting("DATA_SYNC_INVENTORY_ENABLED") &&
        this.props.userType === StoreType.Dealership &&
        hasPermission(
          this.props.permissions,
          Permission.VIEW_INVENTORY,
          localStorage.getItem("isu") === "true"
        ),
    };

    if (dataSync !== setSyncList)
      setTimeout(() => this.props.enableDataSync(setSyncList), 500);

    if (setSyncList.inventory)
      syncList.push({
        name: "Inventory",
        sync: DataSyncQueries.inventory(storeId),
        pollInterval: this.getPolling("DATA_SYNC_POLLING_INTERVAL"),
        debug: false,
        enabled: setSyncList.inventory,
      });

    return syncList;
  }

  render() {
    const { authenticated, store_settings } = this.props;
    if (!authenticated || !store_settings) return <span />;
    const DataSyncQueries = this.getDataSyncQueries();
    return DataSyncQueries.map((sync, key) => (
      <DataSync key={key} client={this.props.client} {...sync} />
    ));
  }

  shouldComponentUpdate(nextProps: DataSyncManagerProps) {
    const needsStore =
      !this.props.storeId || this.props.storeId !== nextProps.storeId;
    const gotSettings =
      nextProps.store_settings &&
      nextProps.store_settings.length &&
      !this.props.store_settings;
    const shouldI = needsStore || gotSettings ? true : false;
    return shouldI;
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    dataSync: state.app.admin.dataSync,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    enableDataSync: (dataSyncObject: LMI.EnabledDataSync) => {
      dispatch(enableDataSync(dataSyncObject));
    },
  };
};

export const DataSyncManager = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IOrgSettingsQueryProps, any, any, React.ClassAttributes<any>>(
    gqlQueries.settings.store,
    {
      skip: (ownProps: any) => !ownProps.storeId,
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            names: [
              "DATA_SYNC_POLLING_INTERVAL",
              "DATA_SYNC_INVENTORY_ENABLED",
            ],
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_settings, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          store_settings,
          refetchSettings: refetch,
        };
      },
    }
  )
)(DataSyncManagerView) as React.ComponentType<any>;

export default DataSyncManager;
