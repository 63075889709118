// node_modules
import * as React from "react";
import { Form, Input } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { stringToInt } from "client/utils/functions";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
// components
import {
  Button,
  Checkbox,
  Dimmer,
  Icon,
  List,
  Loader,
  Table,
} from "semantic-ui-react";
import { restAPI } from "../../../../../utils/rest";
import "./RolesPermissionsComponent.css";

export class RolesPermissionsComponentView extends React.Component<
  LMI.SUAdminRolesProps,
  LMI.SUAdminRolesState
> {
  constructor(props) {
    super(props);

    this.state = {
      selectedRole: null,
      addingRole: false,
      permfilter: null,
      disableEditing: false,
    };
  }

  // manage selected role while switching store types and setting new permissions when adding/deleting rolePermissions
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.store_type !== nextProps.store_type) {
      this.setState({ selectedRole: null });
    } else {
      if (this.state.selectedRole) {
        this.setState((prevState) => ({
          selectedRole: {
            ...prevState.selectedRole,
            permissions: nextProps.roles.find(
              (role) => role.id === this.state.selectedRole.id
            ).permissions,
          },
        }));
      }
    }
  }

  render() {
    const rolesPerms = this.props;

    return (
      <div id="roles-permissions-super-component">
        <Table celled padded verticalAlign="top">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Roles</Table.HeaderCell>
              <Table.HeaderCell>
                {this.state.selectedRole && (
                  <Form className="permfilter">
                    <Input
                      size="mini"
                      name="filter"
                      placeholder="Filter Permissions"
                      value={this.state.permfilter}
                      icon={
                        this.state.permfilter ? (
                          <Icon
                            name="close"
                            color="red"
                            link
                            onClick={() => this.setState({ permfilter: null })}
                          />
                        ) : (
                          false
                        )
                      }
                      onChange={(e, data) =>
                        this.setState({ permfilter: data.value })
                      }
                    />
                  </Form>
                )}
                <span
                  className={this.state.selectedRole ? "table-perm-title" : ""}
                >
                  Permissions
                </span>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              {this.state.addingRole ? (
                <Table.Cell>
                  <Form onSubmit={this.addRole.bind(this)}>
                    <Icon
                      link
                      name="close"
                      color="red"
                      className="close-right"
                      onClick={() => this.setState({ addingRole: false })}
                    />
                    <Form.Field>
                      <label>Role Name</label>
                      <Input name="name" placeholder="Name of new role" />
                    </Form.Field>
                    <Form.Field>
                      <label>Description</label>
                      <Input name="description" />
                    </Form.Field>
                    <Form.Field>
                      <Form.Select
                        search
                        label="Store Type"
                        name="store_type_id"
                        options={rolesPerms.storeTypes}
                        placeholder="Select an Store Type"
                      />
                    </Form.Field>
                    <Button positive content="Save New Role" />
                  </Form>
                </Table.Cell>
              ) : (
                <Table.Cell>
                  <List divided selection size="large">
                    {rolesPerms.roles
                      ? rolesPerms.roles.map((role, index) => {
                          return (
                            <List.Item
                              key={index}
                              active={
                                this.state.selectedRole &&
                                this.state.selectedRole.id === role.id
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                this.setState({
                                  selectedRole: {
                                    permissions: role.permissions,
                                    id: role.id,
                                  },
                                });
                              }}
                            >
                              <List.Content>
                                <span>{role.name}</span>
                              </List.Content>
                            </List.Item>
                          );
                        })
                      : ""}
                  </List>
                  <Button
                    primary
                    onClick={() =>
                      this.setState({ addingRole: true, selectedRole: null })
                    }
                  >
                    Add Role
                  </Button>
                </Table.Cell>
              )}
              <Table.Cell>
                <Dimmer active={this.state.disableEditing} inverted>
                  <Loader inverted>Saving</Loader>
                </Dimmer>
                <List divided size="large" className="permissions-list">
                  {this.state.selectedRole &&
                  rolesPerms.su_all_permissions &&
                  !this.props.loading
                    ? rolesPerms.su_all_permissions
                        .filter((perm) =>
                          this.state.permfilter
                            ? perm.description
                                .toLowerCase()
                                .indexOf(this.state.permfilter.toLowerCase()) >
                              -1
                            : perm
                        )
                        .map((perm, index) => {
                          return (
                            <List.Item key={index}>
                              <Checkbox
                                label={perm.description}
                                checked={
                                  this.state.selectedRole.permissions.find(
                                    (p) => p.id === perm.id
                                  )
                                    ? true
                                    : false
                                }
                                value={perm.id}
                                onChange={(e, data) => {
                                  this.togglePermissions(data);
                                }}
                              />
                            </List.Item>
                          );
                        })
                    : ""}
                </List>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }

  addRole(data: any) {
    for (const prop in data) {
      if (data[prop] === null || data[prop] === undefined) {
        delete data[prop];
      }
    }
    restAPI({
      endpointName: "suAddRole",
      urlArgs: null,
      data,
      callback: (err, res) => {
        if (!err) {
          this.setState({ addingRole: false });
          this.props.refetch();
        }
      },
    });
  }

  togglePermissions(data: any) {
    this.setState({ disableEditing: true });
    const rolePerm = {
      permission_id: data.value,
      role_id: this.state.selectedRole.id,
    };
    if (data.checked) {
      restAPI({
        endpointName: "suAddRolePermission",
        urlArgs: null,
        data: rolePerm,
        callback: (err, res) => {
          if (!err) {
            this.props.refetch();
            setTimeout(() => {
              this.setState({ disableEditing: false });
            }, 500);
          }
        },
      });
    } else {
      restAPI({
        endpointName: "suDeleteRolePermission",
        urlArgs: [data.value],
        data: rolePerm,
        callback: (err, res) => {
          if (!err) {
            this.props.refetch();
            setTimeout(() => {
              this.setState({ disableEditing: false });
            }, 500);
          }
        },
      });
    }
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const RolesPermissionsComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.AllPermissionsAdminGQL, any, any, ClassAttributes<any>>(
    gqlQueries.super.permissions,
    {
      options: (props: any) => {
        return {
          variables: {
            store_type_id: stringToInt(props.store_type),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, su_all_permissions, refetch },
      }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true };
        }

        return {
          su_all_permissions,
          refetch,
        };
      },
    }
  ),
  graphql<LMI.IRolesQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.roles,
    {
      options: (props: any) => {
        return {
          variables: {
            storeType: stringToInt(props.store_type),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, roles, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true };
        }
        return {
          roles,
          refetch,
        };
      },
    }
  )
)(RolesPermissionsComponentView) as React.ComponentType<any>;

export default RolesPermissionsComponent;
