import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { ClassAttributes } from "react";
import { handleErrorResponse, RelativeDatePipe } from "../../../../../../utils/functions";
import { InventoryType, InventoryTypeString } from "loopmein-shared";
import { restAPI } from "../../../../../../utils/rest";
import { Grid, Loader } from "semantic-ui-react";
import { ModalComponent } from "../../../ModalComponent";
import { SaniHistory } from "../../InventoryDetail/components";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";

import "./Info.css";

export class InventoryInfoView extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.state = {
			open_stage_editor: false,
			open_sani_add: false,
			sani_input: "",
			selectStageDropdownOpen: false,
			viewSaniHistory: false
		};
	}

	handleSaniInput = event => {
		this.setState({ sani_input: event.currentTarget.value });
	};

	getSanitizedTemplate = () => {
		const { detail } = this.props;
		return (
			<span className={`last-sanitized ${detail.last_sanitized_at ? "history" : ""}`} onClick={() => this.viewSaniHistory(detail)}>
				{detail.last_sanitized_at ? RelativeDatePipe(detail.last_sanitized_at, false, true) : "N/A"}
			</span>
		);
	};

	viewSaniHistory(detail) {
		if (detail.last_sanitized_at) this.setState({ viewSaniHistory: true });
	}

	submitSaniRequest = () => {
		const { detail, storeId } = this.props;
		const { sani_input } = this.state;
		restAPI({
			endpointName: "createSanitizationRequest",
			urlArgs: [storeId],
			data: { inventory_items: [{ id: detail.id, lead_name: sani_input }] },
			callback: (error, result) => {
				if (error) {
					this.sendAlert({ type: "error", message: handleErrorResponse(error) });
				}
				this.setState({ sani_input: "", open_sani_add: false }, () => {
					this.props.refetchDetail();
				});
			}
		});
	};

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
		if (type === "danger") {
			console.log(`Error: ${message}`);
		}
	}

	getInventoryDetails = detail => {
		const invDetails = [
			{
				raw: true,
				header: "Sales Type",
				description:
					detail.inventory_type_id && detail.inventory_type_id === InventoryType.Auction
						? InventoryTypeString.Auction
						: detail.inventory_type_id === InventoryType.Retail
						? InventoryTypeString.Retail
						: detail.inventory_type_id === InventoryType.Wholesale
						? InventoryTypeString.Wholesale
						: "Unknown"
			},
			{
				raw: true,
				header: "Body Style",
				description: detail.body_style ? detail.body_style : "Unknown"
			},
			{
				raw: true,
				header: "Interior Color",
				description: detail.interior_color_description ? detail.interior_color_description : "Unknown"
			},
			{
				raw: true,
				header: "Exterior Color",
				description: detail.exterior_color_description ? detail.exterior_color_description : "Unknown"
			},
			{
				raw: false,
				header: "Last Sanitized",
				description: detail.last_sanitized_at ? RelativeDatePipe(detail.last_sanitized_at, false, true) : "N/A",
				getTemplate: this.getSanitizedTemplate
			},
			{
				raw: true,
				header: "Odometer",
				description: detail.miles ? detail.miles.toLocaleString() : "Unknown"
			},
			{
				raw: true,
				header: "Fuel Type",
				description: detail.fuel_type ? detail.fuel_type : "Unknown"
			},
			{
				raw: true,
				header: "Highway Fuel Economy",
				description:
					this.props.country_code === "US"
						? detail.highway_miles_per_gallon
							? detail.highway_miles_per_gallon + " mpg"
							: "Unknown"
						: detail.highway_liters_per_100_km
						? detail.highway_liters_per_100_km.toFixed(1) + " kpl"
						: "Unknown"
			},
			{
				raw: true,
				header: "City Fuel Economy",
				description:
					this.props.country_code === "US"
						? detail.city_miles_per_gallon
							? detail.city_miles_per_gallon + " mpg"
							: "Unknown"
						: detail.city_liters_per_100_km
						? detail.city_liters_per_100_km.toFixed(1) + " kpl"
						: "Unknown"
			},
			{
				raw: true,
				header: "Engine",
				description: detail.engine_description ? detail.engine_description : "Unknown"
			},
			{
				raw: true,
				header: "Transmission",
				description: detail.transmission_description ? detail.transmission_description : "Unknown"
			},
			{
				raw: true,
				header: "Capacity",
				description: detail.passenger_capacity ? `${detail.passenger_capacity} Passengers` : "Unknown"
			},
			{
				raw: true,
				header: "Description",
				description: detail.decode_options ? detail.decode_options : "Unknown"
			}
		];

		return invDetails;
	};

	getOpenTasksCount = detail => {
		const openTasks = detail.tasks.filter(t => !t.completed_at);
		return openTasks.length;
	};

	render() {
		const { detail } = this.props;
		const { viewSaniHistory } = this.state;

		const inventoryDisplay = detail ? this.getInventoryDetails(detail) : [];

		if (!detail) return <Loader className={`loader active`} size="small" />;

		return (
			<div>
				{viewSaniHistory && (
					<ModalComponent
						shouldBeOpen={true}
						headerText={`Sanitization History - ${detail.year} ${detail.make} ${detail.model}`}
						onClose={() => this.setState({ viewSaniHistory: false })}
						contentComponent={() => {
							return <SaniHistory {...this.props} />;
						}}
					/>
				)}
				<Grid celled className="info-details">
					{inventoryDisplay.map((d, i) => {
						if (d !== null)
							return (
								<Grid.Row key={i} className="info-row">
									<Grid.Column width={6}>
										<label>{d.header}:</label>
									</Grid.Column>
									<Grid.Column width={10}>{d.raw ? <div dangerouslySetInnerHTML={{ __html: d.description }} /> : d.getTemplate()}</Grid.Column>
								</Grid.Row>
							);
					})}
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const InventoryInfo = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventoryDetail, {
		options: (props: any) => {
			return {
				variables: {
					store_id: parseInt(props.storeId, 10),
					inventory_item_id: props.inventory_item_id
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, inventory_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				detail: inventory_detail,
				refetchDetail: refetch
			};
		}
	})
)(InventoryInfoView) as React.ComponentType<any>;

export default InventoryInfo;
