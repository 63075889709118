import * as Raven from "raven-js";
import * as React from "react";
import { Button, Container, Header } from "semantic-ui-react";

import "./ErrorBoundary.css";

export class ErrorBoundary extends React.Component<LMI.IErrorBoundaryProps, LMI.IErrorBoundaryState> {
	constructor(props: LMI.IErrorBoundaryProps) {
		super(props);
		this.state = { has_error: false, error_info: null };
	}

	componentDidCatch(error, errorInfo) {
		Raven.captureException(error);
		this.setState({ has_error: true, error_info: errorInfo });
	}

	reset = () => {
		this.setState({ has_error: false });
	};

	render() {
		if (this.state.has_error) {
			return (
				<Container className="error-fallback page" textAlign="center">
					<div className="error-fallback-title">
						<span className="error-icon" />
						<Header as="h2">
							We are sorry, an unexpected error has occurred.
							<Header.Subheader>Our system has logged the error and we have our tech staff looking into the problem.</Header.Subheader>
						</Header>
						<br />
						<br />
						<Button positive size="large" content="Click here to refresh" icon="refresh" onClick={this.reset} />
					</div>
				</Container>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
