// node_modules
import * as React from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { StoreType } from "loopmein-shared";
import { NotFound } from "../NotFound";
import { Loading } from "client/components/Loading";

export class HomePageView extends React.Component<LMI.IHomeProps, LMI.IHomeState> {
	constructor(props: LMI.IHomeProps) {
		super(props);
		this.state = {
			userType: props.userType,
			redirect: null
		};
	}

	render() {
		const { redirect, userType } = this.state;
		if (!redirect) {
			if (userType === LocalUserTypes.loading) return <Loading />;
			else return <NotFound {...{ noStores: userType === LocalUserTypes.nonEmployee }} />;
		} else return <Redirect to={redirect} />;
	}

	componentWillUnmount() {
		if (localStorage.getItem("deeplink")) localStorage.removeItem("deeplink");
	}

	static getDerivedStateFromProps(nextProps: LMI.IHomeProps, prevState: LMI.IHomeState) {
		const userType = HomePageView.getUserType(nextProps.userType);
		return {
			userType,
			redirect: HomePageView.getRedirect(userType)
		};
	}

	static getUserType = (ut: any) => {
		let userType: number;
		if (localStorage.getItem("isu") === "true") userType = LocalUserTypes.superUser;
		else {
			switch (ut) {
				case null:
					userType = LocalUserTypes.loading;
					break;
				case undefined:
					userType = LocalUserTypes.nonEmployee;
					break;
				default:
					userType = ut;
			}
		}
		return userType;
	};

	static getRedirect = (userType: number) => {
		if (!localStorage.getItem("token")) return "/login";
		if (localStorage.getItem("deeplink")) return localStorage.getItem("deeplink");

		let redirect;
		switch (userType) {
			case StoreType.Dealership:
				redirect = "/admin/dealerships";
				break;
			case StoreType.Vendor:
				redirect = "/admin/vendors";
				break;
			case StoreType.InternalVendor:
				redirect = "/admin/ivendors";
				break;
			case LocalUserTypes.superUser:
				redirect = "/admin/super";
				break;
			case LocalUserTypes.loading:
			case LocalUserTypes.nonEmployee:
				redirect = null;
				break;
			default:
				redirect = "/login";
		}
		return redirect;
	};
}

enum LocalUserTypes {
	loading = -1,
	nonEmployee = -2,
	superUser = -3
}

const mapStateToProps = (state: any) => {
	return {
		userType: state.app.admin.userType
	};
};

const matchDispatchToProps = (dispatch: any) => {
	return {};
};

export const HomePage = compose(connect(mapStateToProps, matchDispatchToProps))(HomePageView);
export default HomePage;
