import { restAPI } from "../../../../utils/rest";
import { addAlert } from "api/redux/actions";
import { confirmDefault, ConfirmDialog } from "client/components/ConfirmDialog";
import {
  BasicDateFormat,
  formatCurrency,
  formatPhone,
  handleErrorResponse,
} from "client/utils/functions";
import { getStoreTypeId, hasPermission } from "client/utils/userAccess";
import { flowRight as compose } from "lodash";
import { gqlQueries } from "gql-imports";
import { AnalyticsEventType, Permission, StoreType } from "loopmein-shared";
import { graphql } from "@apollo/react-hoc";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import {
  Checkbox,
  Dimmer,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  List,
  Loader,
  Table,
} from "semantic-ui-react";
import { ModalComponent } from "../ModalComponent";
import track from "react-tracking";
import { Session } from "client/utils/session";

import "./InvoiceDialogComponent.css";

interface InvoiceDialogListItem {
  id: number;
  description: string;
  vehicle_description: string;
  reference_number: string;
  quantity: string;
  total: string;
  task: any;
  vendor_comment: string;
}

@track(
  (props) => {
    return {
      event_type: AnalyticsEventType.NAVIGATION,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }invoice-dialog`,
      data: { details: { invoice: props.invoice } },
    };
  },
  { dispatchOnMount: true }
)
export class InvoiceDialogView extends React.Component<
  LMI.IInvoiceDialogProps,
  LMI.IInvoiceDialogState
> {
  lineItemColumns: string[] = [
    "Description",
    "Reference #",
    "Price",
    "Qty",
    "Total",
  ];
  constructor(props: LMI.IInvoiceDialogProps) {
    super(props);
    const storeTypeDealer = getStoreTypeId() === StoreType.Dealership;
    this.state = {
      invoice_detail: null,
      lineItemAlert: null,
      confirm: confirmDefault,
      canEditInvoice: hasPermission(
        this.props.permissions,
        Permission.EDIT_INVOICE,
        Session.get("isSuperUser")
      ),
      canEditReference:
        storeTypeDealer &&
        hasPermission(
          this.props.permissions,
          Permission.EDIT_INVOICE_PO,
          Session.get("isSuperUser")
        ),
      canEditPO: hasPermission(
        props.permissions,
        Permission.EDIT_INVOICE_PO,
        Session.get("isSuperUser")
      ),
      is_paid: props.invoice.is_paid,
      is_received: props.invoice.is_received,
      saving: false,
      po_number_changed: null,
      po_number: null,
      changed_fields: null,
      isDealer: props.viewType.includes("dealers"),
    };
  }

  componentWillMount() {
    this.updateProps();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.po_number !== this.state.po_number) {
      this.setState({
        po_number_changed: true,
        changed_fields: { ...{ po_number: this.state.po_number } },
      });
    }
  }

  _renderInvoiceTemplate = () => {
    const { loading } = this.props;
    const { invoice_detail, saving } = this.state;
    const { confirm } = this.state;
    const invoice_items: InvoiceDialogListItem[] = this._formatInvoiceItems(
      invoice_detail
    );

    if (loading || !invoice_detail)
      return (
        <Dimmer active>
          <Loader />
        </Dimmer>
      );

    return (
      <div id="invoiceDialog">
        <Dimmer active={saving}>
          <Grid rows={2}>
            <Grid.Row>
              <Loader />
            </Grid.Row>
            <Grid.Row textAlign="center">
              Saving changes please wait...
            </Grid.Row>
          </Grid>
        </Dimmer>
        <ConfirmDialog {...confirm} />
        <Grid columns={3}>
          <Grid.Column>{this._contactInfoBlock()}</Grid.Column>
          <Grid.Column>{this._invoiceInfoBlock()}</Grid.Column>
          <Grid.Column>{this._invoiceStatusInfoBlock()}</Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column width={16}>
            <Table>
              <Table.Header>
                <Table.Row>
                  {this.lineItemColumns.map((col, key) => {
                    return (
                      <Table.HeaderCell
                        key={key}
                        {...this._columnProperties(col)}
                      />
                    );
                  })}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {invoice_items.map((item: InvoiceDialogListItem, key) => {
                  return (
                    <Table.Row key={key}>
                      <Table.Cell>
                        <Header.Content>
                          <strong>{item.description}</strong>
                          <Header.Subheader>
                            {item.vehicle_description}
                          </Header.Subheader>
                          {item.task && item.task.comment && (
                            <div className="itemDetails">
                              <small>
                                <strong>Comment:</strong> {item.task.comment}
                              </small>
                            </div>
                          )}
                          {item.vendor_comment && (
                            <div className="itemDetails">
                              <small>
                                <strong>Vendor Comment:</strong>{" "}
                                {item.vendor_comment}
                              </small>
                            </div>
                          )}
                        </Header.Content>
                      </Table.Cell>
                      <Table.Cell>
                        {(this.state.canEditReference &&
                          !invoice_detail.is_paid &&
                          !this.state.is_paid) ||
                        !this.state.canEditInvoice ? (
                          <Input
                            icon
                            size="small"
                            defaultValue={item.reference_number}
                            placeholder="Set Reference Number"
                            onChange={(e, data) =>
                              (e.target.value = data.value)
                            }
                            onBlur={(e) =>
                              this.saveItemReferenceNumber(e.target.value, item)
                            }
                          >
                            <input />
                            {this.state.lineItemAlert &&
                            this.state.lineItemAlert.item === item.id ? (
                              <Icon
                                className="lineItemAlert"
                                name={
                                  this.state.lineItemAlert.alertProps.type ===
                                  "success"
                                    ? "check circle outline"
                                    : "close"
                                }
                                color={
                                  this.state.lineItemAlert.alertProps.type ===
                                  "success"
                                    ? "green"
                                    : "red"
                                }
                              />
                            ) : (
                              ""
                            )}
                          </Input>
                        ) : (
                          <span>{item.reference_number}</span>
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {formatCurrency(parseInt(item.total, 10), 2)}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {item.quantity}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(parseInt(item.total, 10), 2)}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column width={8}>
            {invoice_detail.approved_signature_url ? (
              <Grid className="approved-box">
                <Grid.Column width={16} className="approvedLabel">
                  <Header sub content="Approved by" />
                </Grid.Column>
                <Grid.Column
                  width={8}
                  className="approvalSignature"
                  textAlign="center"
                >
                  {invoice_detail.approved_signature_url ? (
                    <Image
                      bordered={true}
                      src={invoice_detail.approved_signature_url}
                      loading="lazy"
                    />
                  ) : (
                    ""
                  )}
                </Grid.Column>
                <Grid.Column width={8}>
                  <Header>
                    {`${invoice_detail.approved_by_user.full_name}`}
                    <Header.Subheader>
                      on {BasicDateFormat(invoice_detail.approved_at)}
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            ) : (
              <div className="noapprove">NOT YET APPROVED</div>
            )}
          </Grid.Column>
          <Grid.Column width={8}>
            <List divided relaxed className="totals">
              <List.Item>
                <List.Content>
                  <List.Description>
                    Subtotal:{" "}
                    <strong className="right">
                      {formatCurrency(parseInt(invoice_detail.subtotal, 10), 2)}
                    </strong>
                  </List.Description>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Description>
                    Total:{" "}
                    <strong className="right large">
                      {formatCurrency(parseInt(invoice_detail.total, 10), 2)}
                    </strong>
                  </List.Description>
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  _columnProperties = (content) => {
    let textAlign;
    switch (content) {
      case "Qty":
        textAlign = "center";
        break;
      case "Price":
        textAlign = "center";
        break;
      case "Total":
        textAlign = "right";
        break;
      default:
        textAlign = "left";
        break;
    }
    return {
      className: "grey",
      content,
      textAlign,
    };
  };

  _formatInvoiceItems = (invoice_detail: LMI.IInvoiceDetail) => {
    return invoice_detail
      ? invoice_detail.invoice_line_items.map((item: LMI.IInvoiceLineItems) => {
          const {
            description,
            vehicle_description,
            quantity,
            total,
            reference_number,
            id,
            task,
            vendor_comment,
          } = item;
          return {
            id,
            description,
            vehicle_description,
            quantity,
            total,
            reference_number,
            task,
            vendor_comment,
          } as InvoiceDialogListItem;
        })
      : [];
  };

  _contactInfoBlock = () => {
    const { viewType } = this.props;
    const { invoice_detail } = this.state;
    const contact =
      viewType === "dealerships"
        ? invoice_detail.provider_store
        : invoice_detail.customer_store;

    return (
      <div className="invoiceContactBlock">
        <Header sub content={viewType === "dealerships" ? "FROM" : "FOR"} />
        <div className="logo-container">
          {contact.logo_url ? (
            <Image
              src={contact.logo_url}
              size="tiny"
              floated="left"
              loading="lazy"
            />
          ) : (
            ""
          )}
        </div>
        <strong className="companyName">{contact.name}</strong>
        <p>
          {contact.address1} {contact.address2}
          <br />
          {contact.city}, {contact.state} {contact.zip}
          <br />
          {formatPhone(contact.phone)}
        </p>
      </div>
    );
  };

  _invoiceStatusInfoBlock = () => {
    const { invoice_detail, is_paid, is_received, isDealer } = this.state;

    // turn PO label red based on store setting and po_number val https://github.com/jointsoft/loopmein-web/issues/1384
    const poSetting =
      this.props.store_settings &&
      this.props.store_settings.find(
        (setting) => setting.name === "PO_ISSUER_EMAIL"
      );
    const noReqPO = poSetting && poSetting.value && !invoice_detail.po_number;

    return (
      <div className="invoiceStatusInfo">
        <Header sub content="Invoice Status" />
        <label className={`po-label ${noReqPO ? "red" : ""}`}>PO Number:</label>
        {invoice_detail.is_paid ||
        this.state.is_paid ||
        !this.state.canEditPO ||
        !this.state.canEditInvoice ? (
          <strong>{invoice_detail.po_number}</strong>
        ) : (
          <Input
            size="small"
            placeholder="PO Number"
            defaultValue={invoice_detail.po_number}
            onChange={(e, data) => this.setState({ po_number: data.value })}
            error={noReqPO}
          />
        )}
        <Grid columns={2} divided className="toggles">
          <Grid.Column>
            <Checkbox
              className={
                !isDealer || !this.state.canEditInvoice ? "disabled" : ""
              }
              toggle
              disabled={!isDealer || !this.state.canEditInvoice}
              // defaultChecked={invoice_detail.is_paid}
              label="IS PAID"
              checked={is_paid === null ? invoice_detail.is_paid : is_paid}
              onChange={(e, data) => {
                this.setState({
                  confirm: {
                    open: true,
                    title: data.checked
                      ? "Mark this Invoice Paid?"
                      : "Mark this Invoice NOT Paid?",
                    text: data.checked
                      ? "This will make PO and Reference Numbers read only."
                      : "Are you sure you want to mark this Invoice as not paid?",
                    success: () => {
                      this.updateInvoice({
                        is_paid: data.checked,
                        ...this.state.changed_fields,
                      });
                      this.setState({
                        confirm: confirmDefault,
                        is_paid: data.checked,
                        changed_fields: null,
                        po_number_changed: false,
                      });
                    },
                    failure: () =>
                      this.setState({
                        confirm: confirmDefault,
                        is_paid: invoice_detail.is_paid,
                      }),
                  },
                });
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Checkbox
              className={
                isDealer || !this.state.canEditInvoice ? "disabled" : ""
              }
              toggle
              disabled={isDealer || !this.state.canEditInvoice}
              label="IS RECEIVED"
              checked={
                is_received === null ? invoice_detail.is_received : is_received
              }
              onChange={(evt, data) => {
                this.setState({
                  is_received: data.checked,
                  confirm: {
                    open: true,
                    title: data.checked
                      ? "Mark this Invoice Received?"
                      : "Mark this Invoice as NOT Received?",
                    success: () => {
                      this.updateInvoice({
                        is_received: data.checked,
                        ...this.state.changed_fields,
                      });
                      this.setState({
                        confirm: confirmDefault,
                        is_received: data.checked,
                        changed_fields: null,
                        po_number_changed: false,
                      });
                    },
                    failure: () =>
                      this.setState({
                        confirm: confirmDefault,
                        is_received: invoice_detail.is_received,
                      }),
                  },
                });
              }}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  updateProps = () => {
    const { storeId, topLevelStoreId, invoice } = this.props;
    const { isDealer } = this.state;
    const invoiceId = invoice.id;
    const method = isDealer
      ? "getDealerInvoiceDetails"
      : "getVendorInvoiceDetails";
    restAPI({
      endpointName: method,
      urlArgs: [isDealer ? storeId : topLevelStoreId, invoiceId],
      data: null,
      callback: (error, result) => {
        if (!error) {
          this.setState({
            invoice_detail: result.data,
            is_paid: result.data.is_paid,
            is_received: result.data.is_received,
          });
        } else {
          console.error(error);
        }
      },
    });
  };

  _invoiceInfoBlock = () => {
    const { invoice_detail } = this.state;
    const blockFactory = () => {
      const infoBlocks = {
        "Invoice #": invoice_detail.invoice_number,
        "Date of Invoice": BasicDateFormat(invoice_detail.created_at),
        "Due Date": invoice_detail.due_date
          ? BasicDateFormat(invoice_detail.due_date)
          : BasicDateFormat(moment().add(14, "days")),
        "Created By": invoice_detail.created_by_user.full_name,
      };
      const blocks: any[] = [];
      for (const block in infoBlocks) {
        if (infoBlocks[block] !== null)
          blocks.push(
            <span key={block}>
              {block}: <strong>{infoBlocks[block]}</strong>
            </span>
          );
      }
      return blocks;
    };
    return (
      <div className="invoiceInfoBlock">
        <Header sub content="Invoice Info" />
        {blockFactory()}
      </div>
    );
  };

  render() {
    return (
      <ModalComponent
        size="large"
        shouldBeOpen={true}
        headerText="Invoice Detail"
        onClose={async () => {
          if (this.state.po_number_changed) {
            this.setState({ po_number_changed: false });
            this.updateInvoice(
              { po_number: this.state.po_number },
              this.props.onClose
            );
          } else {
            this.props.onClose();
          }
        }}
        contentComponent={() => this._renderInvoiceTemplate()}
      />
    );
  }

  saveItemReferenceNumber = (val, item) => {
    const invoiceitem = this.state.invoice_detail.invoice_line_items.find(
      (i) => i.id === item.id
    );
    if (invoiceitem && invoiceitem.reference_number !== val) {
      this.updateInvoiceItem({ reference_number: val }, item.id);
    }
  };

  updateInvoiceItem = (data, itemId) => {
    const { storeId, topLevelStoreId } = this.props;
    const { isDealer } = this.state;
    restAPI({
      endpointName: "updateDealerInvoiceItem",
      urlArgs: [isDealer ? storeId : topLevelStoreId, itemId],
      data,
      callback: (err, res) => {
        const AlertProps: any = {
          type: !err ? "success" : "danger",
          message: !err ? res.data.message : err.reason.response.data.message,
        };
        this.setState({
          lineItemAlert: { alertProps: AlertProps, item: itemId },
        });
        setTimeout(() => {
          this.setState({ lineItemAlert: null });
        }, 2000);
      },
    });
  };

  updateInvoice = (data, callback?: any) => {
    const { storeId, topLevelStoreId } = this.props;
    const { isDealer } = this.state;
    const apimethod = !isDealer ? "updateVendorInvoice" : "updateDealerInvoice";
    this.setState({ saving: true });
    restAPI({
      endpointName: apimethod,
      urlArgs: [
        isDealer ? storeId : topLevelStoreId,
        this.state.invoice_detail.id,
      ],
      data,
      callback: async (err, res) => {
        this.handleInvoiceQuery(err, callback);
        this.setState({ saving: false }, () => {
          this.props.refetchInvoices();
          this.props.addAlert({
            type: !err ? "success" : "danger",
            message: !err
              ? res.data.message
              : handleErrorResponse({
                  error: err,
                  message: "There was a problem updating this invoice.",
                }),
            timeout: 2000,
          });
        });
      },
    });
  };

  handleInvoiceQuery = (err: any, callback?: any) => {
    if (!err) {
      if (callback) {
        setTimeout(() => {
          callback();
        }, 250);
      } else {
        this.updateProps();
      }
    } else {
      this.updateProps();
    }
  };
}

const mapStateToProps = (state: any) => {
  return {
    topLevelStoreId: state.app.admin.storeId,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const InvoiceDialog = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<any, any, any, React.ClassAttributes<any>>(
    gqlQueries.settings.store,
    {
      options: (props: any) => {
        const store_id = props.viewType.includes("dealers")
          ? props.storeId
          : props.topLevelStoreId;
        return {
          variables: {
            storeId: parseInt(store_id, 10),
            names: ["PO_ISSUER_EMAIL"],
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_settings, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        return {
          store_settings,
          refetch,
        };
      },
    }
  )
)(InvoiceDialogView) as React.ComponentType<any>;

export default InvoiceDialog;
