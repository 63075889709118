// node_modules
import * as React from "react";
// components
import { Button, Image, Segment } from "semantic-ui-react";
import { promiseBase64 } from "client/utils/fileHandling";
import { DropZone } from "client/components/DropZone/DropZone";
import "./FileUploadComponent.css";

export class MultiImageUploader extends React.Component<LMI.IMultiImageUploadProps, LMI.IMultiImageUploadState> {
	constructor(props: any) {
		super(props);

		this.state = {
			files: null
		};
	}

	render() {
		const { onClose } = this.props;
		const { files } = this.state;

		return (
			<div id="fileupload">
				<div className="file-uploader">
					<DropZone
						multiple={true}
						className="dropzone"
						accept="image/png, image/jpeg"
						onDrop={newFiles => {
							const nfiles = files && files.length ? [...files, ...newFiles] : newFiles;
							this.setState({
								files: nfiles.map(file =>
									Object.assign(file, {
										preview: URL.createObjectURL(file)
									})
								)
							});
						}}
					/>
				</div>
				<Segment basic textAlign="right">
					<Button.Group size="large">
						<Button type="button" onClick={onClose}>
							Cancel
						</Button>
						<Button.Or />
						<Button positive className="submit-btn" disabled={files && files.length ? false : true} onClick={() => this.prepareFilesPackage()}>
							Save
						</Button>
					</Button.Group>
				</Segment>
			</div>
		);
	}

	getPreview = file => {
		return <Image src={file.preview} rounded loading="lazy" />;
	};

	prepareFilesPackage = async () => {
		const { files } = this.state;
		const { onSubmit } = this.props;
		if (!files.length) {
			console.log("No Files to Upload");
			return;
		}

		const filesPackage = await Promise.all(
			files.map(async file => {
				return { image: await promiseBase64(file) };
			})
		);

		onSubmit(filesPackage);
	};
}

export default MultiImageUploader;
