import { restAPI } from "../../../../../utils/rest";
import { gqlQueries } from "gql-imports";
import {
  addAlert,
  enableUABCallback,
  triggerUABCallback,
} from "api/redux/actions";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { UniversalActionModalComponent } from "client/pages/admin/components/UniversalActionModalComponent";
import { hasPermission } from "client/utils/userAccess";
import { AnalyticsEventType, Permission } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Loading } from "../../../../../components/Loading";
import { withUAB } from "../../../../../pages/WithUAB";
import { DataFilterComponent } from "../../DataFilterComponent";
import {
  getSortableCheckbox,
  getSortableEditable,
  SortableTable,
} from "../../SortableTable";
import { GroupsDetailsComponent } from "./components";
import track from "react-tracking";
import { Session } from "client/utils/session";

import "./GroupsTabPanel.css";

@track(
  (props) => {
    return {
      event_type: AnalyticsEventType.SUBNAV,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }settings.groups`,
    };
  },
  { dispatchOnMount: true }
)
export class GroupsTabPanelView extends React.Component<
  LMI.IGroupsTPProps,
  LMI.IGroupsTPState
> {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: null,
      editGroup: null,
    };
  }

  render() {
    const props = this.props;
    if (props.hasErrors) {
      console.log("GroupsTabPanel Data errors:", props.message);
    }
    if (!props.stre || props.loading) {
      return <Loading />;
    }

    const stores = Session.get("stores");
    const currentStoreName =
      props.storeId &&
      stores.find((st) => st.store_id === props.storeId).store_name;

    const canView = hasPermission(
      this.props.permissions,
      Permission.ADMIN_VIEW_GROUP,
      Session.get("isSuperUser")
    );
    const canEdit = hasPermission(
      this.props.permissions,
      Permission.ADMIN_ADD_GROUP,
      Session.get("isSuperUser")
    );

    let groups: any[] =
      props.stre.organization && props.stre.organization.groups
        ? this.formatGroupData(props.stre.organization.groups, props.viewType)
        : [];

    if (this.state.searchFilter) {
      groups = this.searchFilter(this.state.searchFilter, groups);
    }

    const modalProps: LMI.IGroupsDetailsProps = {
      group: this.state.editGroup,
      onSave: (data, regionId) => this.updateGroup(data, regionId),
      onCreate: (data) => this.createGroup(data),
      closeCallback: () => {
        if (!this.state.editGroup) {
          props.universalActionCallback();
        } else {
          this.setState({
            editGroup: null,
          });
        }
      },
    };

    return (
      <div className="groups-tab-panel panel-content vert-sortable">
        <UniversalActionModalComponent
          toggleUABCallback={props.toggleUABCallback}
          universalActionCallback={props.universalActionCallback}
          contentComponent={() => this.getModalForm(modalProps)}
          headerText="Add a Group"
          size="small"
        />
        {this.state.editGroup && (
          <ModalComponent
            headerText={this.state.editGroup.name}
            shouldBeOpen={this.state.editGroup ? true : false}
            onClose={(evt, data) => {
              this.setState({ editGroup: null });
            }}
            size="small"
            className="region-details"
            contentComponent={() => this.getModalForm(modalProps)}
          />
        )}
        <div className="sortable-container">
          {groups.length > 0 || this.state.searchFilter ? (
            <div className="sortable-filter">
              <DataFilterComponent
                label="Filter Results"
                searchFilter={this.state.searchFilter}
                onChange={(p, { value }) => {
                  this.setState({
                    searchFilter: value,
                  });
                }}
              />
            </div>
          ) : (
            <span />
          )}
          <div className="sortable-content">
            {canView && (
              <SortableTable
                filter={this.state.searchFilter}
                message={
                  currentStoreName +
                  " currently has no groups setup" +
                  (canEdit
                    ? ". Use the [+] button on the top right to add a new group."
                    : ".")
                }
                tableData={this.getTableData(groups, props.viewType)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  getModalForm(props) {
    return <GroupsDetailsComponent {...props} />;
  }

  searchFilter(search: string, data: any[]) {
    const srch = search.toLowerCase();
    return data.filter((service) => {
      return (
        service.name.toLowerCase().includes(srch) ||
        (service.groupId &&
          service.groupId.toString().toLowerCase().includes(srch))
      );
    });
  }

  getTableData(groups, viewType) {
    const headers: any = [
      {
        id: "tools",
        label: "",
        sortable: false,
        collapsing: true,
      },
      {
        id: "name",
        label: "Name",
        sortable: true,
      },
      {
        id: "active",
        label: "Active?",
        sortable: false,
        collapsing: true,
      },
    ];
    const sortableTableData: LMI.ITableData = {
      headers,
      body: {
        rows: [],
      },
    };
    return Object.assign({}, sortableTableData, {
      body: {
        rows: groups,
      },
    });
  }

  formatGroupData(groups: any[], viewType: string): LMI.IGroupTableRow[] {
    return groups.map(
      (group): LMI.IGroupTableRow => {
        return {
          tools: {
            component: getSortableEditable,
            editable: hasPermission(
              this.props.permissions,
              Permission.ADMIN_EDIT_GROUP,
              Session.get("isSuperUser")
            ),
            editcallback: () => {
              this.setState({
                editGroup: group,
              });
            },
          },
          name: group.name,
          active: {
            value: group.is_active,
            component: getSortableCheckbox,
            disabled: !hasPermission(
              this.props.permissions,
              Permission.ADMIN_REMOVE_GROUP,
              Session.get("isSuperUser")
            ),
            callback: (data) => this.toggleActive(data, group.id),
          },
        };
      }
    );
  }

  toggleActive(formData: any, groupId: number) {
    const data = {
      is_active: formData.state,
    };
    this.updateGroup(data, groupId);
  }

  createGroup(formData: any) {
    restAPI({
      endpointName: "storeCreateOrgGroup",
      urlArgs: [this.props.stre.organization.id],
      data: formData,
      callback: (err, res) => {
        if (err)
          this.props.addAlert({
            type: "danger",
            message: err.reason.response.data.message,
            timeout: 3000,
          });
        this.props.refetch();
        this.props.universalActionCallback();
      },
    });
  }

  updateGroup(formData: any, groupId: number) {
    restAPI({
      endpointName: "storeUpdateOrgGroup",
      urlArgs: [this.props.stre.organization.id, groupId],
      data: formData,
      callback: (err, res) => {
        if (err)
          this.props.addAlert({
            type: "danger",
            message: err.reason.response.data.message,
            timeout: 3000,
          });
        this.props.refetch();
        this.setState({
          editGroup: null,
        });
      },
    });
  }

  componentDidMount() {
    // Should we show the UAB to this user?
    if (
      !hasPermission(
        this.props.permissions,
        Permission.ADMIN_ADD_GROUP,
        Session.get("isSuperUser")
      )
    ) {
      this.props.disableUAB();
    }
  }
}

const showUABOn = [{ viewType: "lists-component" }];
const GroupsTabPanelViewUAB: any = withUAB(GroupsTabPanelView, showUABOn);

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    toggleUABCallback: state.app.admin.toggleUABCallback,
    permissions: state.app.admin.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    universalActionCallback: () => {
      dispatch(triggerUABCallback(false));
    },
    disableUAB: () => {
      dispatch(enableUABCallback(false));
    },
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const GroupsTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IStoreQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.listsGroups,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          stre: store,
          refetch,
        };
      },
    }
  )
)(GroupsTabPanelViewUAB) as React.ComponentType<any>;

export default GroupsTabPanel;
