import { gqlQueries } from "gql-imports";
import { hasPermissions } from "client/utils/userAccess";
import { Permission } from "loopmein-shared";
import { useQuery } from "@apollo/react-hooks";
import { Session } from "client/utils/session";

const useLeadBadge = ({ storeId, detail, permissions }) => {
	const canViewLeads = hasPermissions(permissions, [Permission.LIST_LEADS], Session.get("isSuperUser"));
	const leads = useQuery(gqlQueries.dealership.inventoryDetailTabBadgeCountsLeads, {
		skip: !storeId || !detail || !canViewLeads,
		variables: {
			storeId: parseInt(storeId, 10),
			inventoryItemId: detail.id
		},
		fetchPolicy: "network-only"
	});
	return leads?.data?.dealer_leads?.length ?? 0;
};

const useAccBadge = ({ storeId, detail, permissions }) => {
	const canViewAcc = hasPermissions(permissions, [Permission.VIEW_INVENTORY_ACCESSORIES], Session.get("isSuperUser"));
	const accessories = useQuery(gqlQueries.dealership.inventoryDetailTabBadgeCountsAcc, {
		skip: !storeId || !detail || !canViewAcc,
		variables: {
			storeId: parseInt(storeId, 10),
			inventoryItemId: detail.id
		},
		fetchPolicy: "network-only"
	});
	return accessories?.data?.vehicle_accessories?.length ?? 0;
};

export const useTabCount = (tab, props) => {
	const leadBadge = useLeadBadge(props);
	const accBadge = useAccBadge(props);
	switch (tab) {
		case "Tasks":
			return props?.detail?.tasks?.length ?? 0;
		case "Accessories":
			return accBadge;
		case "Leads":
			return leadBadge;
		default:
			break;
	}
};
