import * as React from "react";
import * as Raven from "raven-js";
import * as LogRocket from "logrocket";
import history from "../../utils/history";
import { Session } from "client/utils/session";
import { restAPI } from "../../utils/rest";
import { LMIEmails } from "../../utils/globals";
import { Link } from "react-router-dom";
import { getAppRoutes } from "../Routes/utils";

import { Dropdown, Grid, Loader } from "semantic-ui-react";
import { UniversalActionButton } from "../../pages/admin/components/UniversalActionButton";
import { HelpTours } from "client/components/Header/HelpTours";
import { ProxyUserComponent } from "../../pages/admin/super/components";
import { StoreSelector } from "../StoreSelector";
import { LmiMenu } from "../Menu";
import { QuickSearch } from "../QuickSearch";
import { FeatureTours } from "./FeatureTours";
import "./Header.css";

export class HeaderComponent extends React.Component<LMI.IHeaderProps, LMI.IHeaderState> {
	constructor(props) {
		super(props);

		this.state = {
			me: this.getEmptyMe(),
			userType: null,
			superUser: localStorage.getItem("isu") === "true" ? true : false,
			loading: true,
			openFeatureTours: false
		};
	}

	isSharePage = (sharePages: string[], path: string) => {
		const parent = path.split("/")[1];
		return sharePages.indexOf(parent) >= 0;
	};

	render() {
		const firstName = this.state.me.first_name;
		const lastName = this.state.me.last_name;
		const props = this.props;

		const ssProps = {
			userId: this.state.me.id,
			email: this.state.me.email,
			userType: this.state.userType,
			superUser: this.state.superUser,
			employees: this.state.me.employees,
			onStoreChange: (headHome = true) => {
				this.setState({ loading: true }, () => {
					this.setState({ loading: false }, () => {
						const routes = getAppRoutes(this.props.permissions);
						this.props.updateRoutes(routes);
						this.props.updateCurrentRoute("administration");
						if (headHome) history.push("/");
					});
				});
			}
		};
		const htProps = {
			history: props.history,
			openFeatureTours: () => this.setState({ openFeatureTours: true })
		};
		const proxyUserProps = Object.assign({}, this.props, {
			userName: firstName + " " + lastName,
			superUser: this.state.superUser,
			onLogin: (data, e) => {
				console.log(data);
			}
		});
		const pageIsShare = this.isSharePage(this.props.sharePages, this.props.history.location.pathname);
		const padForUAB = this.props.showUAB ? { paddingRight: "0" } : {};
		const authenticated = this.props.authenticated && this.state.superUser === false;

		if (!props.showHeader || pageIsShare) return this.miniHeader();

		return (
			<header>
				{this.props.authenticated ? (
					<div id="Header">
						<div id="TitleBar">
							<div id="SiteLogo">
								<Link to="/">
									<img alt="LoopMeIn Auto" className="site-logo" src="/images/LoopMeIn_FINAL_horizontal_large.png" />
								</Link>
							</div>
							<div className="action-bar">
								{!this.state.loading ? (
									<span className="delay-render">
										<QuickSearch />
										<StoreSelector {...(ssProps as any)} />
										<ProxyUserComponent {...proxyUserProps} />
									</span>
								) : (
									<Loader size="mini" inline inverted active className="inline-loader" />
								)}
								<HelpTours {...(htProps as any)} />
								<Dropdown pointing button icon="user" className="icon top right">
									<Dropdown.Menu>
										<Dropdown.Header content="User Menu" />
										<Dropdown.Item
											icon="lock"
											text="Change Password"
											onClick={() => {
												this.props.history.push("/change-password");
											}}
										/>
										<Dropdown.Item
											icon="student"
											text="Online Training"
											onClick={() => {
												window.open(this.getMenuSetting("training_link"), "_blank");
											}}
										/>
										<Dropdown.Item
											icon="comment"
											text="Request Help or Report a Bug"
											onClick={() => {
												window.open(this.getMenuSetting("request_help_link"), "_blank");
											}}
										/>
										<Dropdown.Item
											icon="setting"
											text="Settings"
											onClick={() => {
												this.props.history.push("/admin/user/settings-admin/settings-profile");
											}}
										/>
										<Dropdown.Divider />
										<Dropdown.Item icon="power" text="Logout" onClick={this.logoutFunction.bind(this)} />
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div id="Subheader">
							<Grid columns="equal">
								<Grid.Column width={7} id="Breadcrumb">
									{authenticated && `${this.props.storeName} - ${this.props.currentRoute}`}
								</Grid.Column>
								<Grid.Column id="Username" textAlign="right" className={!this.props.showUAB ? "noUAB" : ""} style={padForUAB}>
									{firstName} {lastName}
								</Grid.Column>
								{this.props.authenticated && this.props.showUAB && <Grid.Column style={{ paddingRight: "0", paddingLeft: "0" }} width={1} />}
							</Grid>
							{this.props.authenticated && this.props.showUAB ? <UniversalActionButton /> : ""}
						</div>
						<FeatureTours open={this.state.openFeatureTours} autoLaunch={false} onClose={() => this.setState({ openFeatureTours: false })} />
					</div>
				) : (
					<div className="action-bar" />
				)}
				{this.props.authenticated && !this.state.superUser ? <LmiMenu history={this.props.history} /> : <span />}
			</header>
		);
	}

	miniHeader() {
		// return <div id="MiniHeader">{this.props.authenticated && !this.state.superUser ? <LmiMenu mini={true} history={this.props.history} /> : <span />}</div>;
		return <div id="MiniHeader" />;
	}

	shouldComponentUpdate(nextProps: any, nextState: any) {
		const newMe = nextProps && nextProps.me ? nextProps.me : {};
		const oldMe = this.props.me ? this.props.me : null;
		if (newMe && Object.keys(newMe).length > 0 && !oldMe) return true;
		if (nextState.loading !== this.state.loading) return true;
		if (nextProps.showUAB !== this.props.showUAB) return true;
		if (nextProps.currentRoute !== this.props.currentRoute) return true;
		if (nextState.openFeatureTours !== this.state.openFeatureTours) return true;
		if (nextProps.showHeader !== this.props.showHeader) return true;
		return false;
	}

	componentDidUpdate(prevProps: any, prevState: any) {
		// update me
		const oldMe = prevProps && prevProps.me ? prevProps.me : {};
		const newMe = this.props.me ? this.props.me : null;
		// Session.set(
		//   "tracking_email",
		//   newMe.email ? newMe.email : oldMe.email ? oldMe.email : "Not Yet Set"
		// );

		if (!this.props.employeeId) {
			const employee = newMe.employees && newMe.employees.find(e => e.store.id === this.props.storeId);
			this.props.changeSelectedEmployee(employee ? employee.id : null);
		}

		if (newMe && oldMe !== newMe && newMe.email) {
			this.setState({ loading: true, me: this.getEmptyMe() });
			this.whoAmI(newMe);
		}
	}

	getEmptyMe() {
		return {
			id: "",
			first_name: "",
			last_name: "",
			email: ""
		};
	}

	getMenuSetting(setting_id: string): string {
		if (!process.env[setting_id]) {
			console.error(`Menu setting ${setting_id} not set up.`);
		}
		return process.env[setting_id];
	}

	logoutFunction(data) {
		this.setState({ me: this.getEmptyMe(), loading: true });
		this.props.logoutFunc(data);
	}

	whoAmI(me: any) {
		restAPI({
			endpointName: "getUser",
			urlArgs: null,
			data: null,
			callback: async (error, result) => {
				if (error) {
					console.log("Error getting user data: ", error);
					this.setState({ loading: false }, () =>
						this.props.addAlert({
							type: "danger",
							message: error.message,
							timeout: 5000
						})
					);
					setTimeout(() => this.logoutFunction(null), 3000);
				} else {
					localStorage.setItem("userId", me.id.toString());
					Session.set("userId", me.id.toString());
					Session.set("stores", result.data.stores);
					Session.set("storeTypeIds", result.data.store_type_ids);
					Session.set("email", me.email.toString());
					Session.set("fullName", `${me.first_name} ${me.last_name}`);
					Session.set("isSuperUser", this.state.superUser);
					Session.set("userType", result.data.store_type_ids.toString());

					const storeType = localStorage.getItem("selectedStoreType") !== undefined ? localStorage.getItem("selectedStoreType") : result.data.store_type_ids[0];

					this.setState({
						me,
						loading: false,
						userType: storeType,
						superUser: this.state.superUser
					});

					// this.props.changeUserType(storeType);

					if (process.env.REACT_APP_ENVIRONMENT === "live") {
						// Don't init log rocket for LMI users
						const isLMI = LMIEmails.filter(e => me.email.toString().indexOf(e) >= 0);
						if (!isLMI || isLMI.length <= 0) {
							this.logRocketSetup();
						}
						this.ravenSetup();
					}
				}
			}
		});
	}

	logRocketSetup() {
		LogRocket.init("loopmein/loopmein-web");
		LogRocket.startNewSession();
		LogRocket.identify(Session.get("userId"), {
			name: Session.get("fullName"),
			email: Session.get("email"),
			isSuperUser: Session.get("isSuperUser").toString(),
			storeType: Session.get("userType")
		});
		// console.log("LogRocket Started");
		// console.log("LogRocket.sessionURL", LogRocket.sessionURL);
	}

	ravenSetup() {
		Raven.setUserContext({
			email: Session.get("email"),
			id: Session.get("userId")
		});

		Raven.setDataCallback(data => {
			// console.log("Raven.setDataCallback", data);
			data.extra.sessionURL = LogRocket.sessionURL;
			return data;
		});
	}
}
