import * as React from "react";
import { BasicDateFormat, formatCurrency, shadeColor } from "client/utils/functions";
import { Segment, Grid, Image, Button } from "semantic-ui-react";

export class InventoryResult extends React.Component<LMI.LmiResultTypeProps, undefined> {
	render() {
		const { onSelect } = this.props;
		const detail: LMI.IInventoryGQL = this.props.detail;

		return (
			<Segment key={detail.id} raised className="result-tile">
				{detail.current_phase && detail.is_active && (
					<span
						className="stage-label"
						onClick={() => onSelect(`workflow/${detail.current_phase}?id=${detail.inventory_id}`)}
						style={{ backgroundColor: detail.current_phase_color, textShadow: `2px 2px 5px ${shadeColor(detail.current_phase_color, -25)}` }}
					>
						{detail.current_phase_name}
					</span>
				)}
				{detail.latitude && detail.longitude && (
					<Button
						circular
						onClick={() => onSelect(`inventory?id=${detail.inventory_id}&store=${localStorage.getItem("selectedStoreId")}&openMap=true`)}
						icon="map marker alternate"
						link
						className="mapLink"
					/>
				)}
				<Grid className="summary" onClick={() => onSelect(`inventory?id=${detail.inventory_id}`)}>
					<Grid.Column width={3}>
						<Image size="small" rounded src={detail.photo_url ? `${detail.photo_url}?h=150&w=150&auto=compress` : "/images/inventory.png"} loading="lazy" />
					</Grid.Column>
					<Grid.Column width={13}>
						<div className="vehicle">
							{detail.condition && (
								<div className="condition">
									{detail.condition.toLowerCase() === "used" ? "PRE-OWNED" : detail.condition.toUpperCase()}{" "}
									{!detail.is_active && (
										<span className="sold-flag">
											<strong> - SOLD </strong>
											{detail.date_removed_from_inventory ? BasicDateFormat(detail.date_removed_from_inventory) : ""}
										</span>
									)}
								</div>
							)}
							<div className="details">
								{detail.year} {detail.make} {detail.model}
							</div>
							<div className="identity">
								<div className="i-label">
									STOCK#: <strong>{detail.stock_number}</strong> | VIN: <strong>{detail.vin}</strong>
								</div>
								<div className="i-label">
									PRICE: <strong>{formatCurrency(detail.selling_price, 0)}</strong> | ODO: <strong>{Number(detail.miles).toLocaleString("en")}</strong>
								</div>
							</div>
						</div>
					</Grid.Column>
				</Grid>
			</Segment>
		);
	}
}

export default InventoryResult;

