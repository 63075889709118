import track from "react-tracking";
import * as React from "react";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { gqlQueries } from "gql-imports";
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  List,
  Popup,
  Segment,
} from "semantic-ui-react";
import { ModalComponent } from "../../ModalComponent";
import { DragDropListComponent } from "client/pages/admin/components/DragdropListComponent";
import LeaseReturnItemEditor from "./components/LeaseReturnItemEditor";
import LeaseReturnSettingEditor from "./components/LeaseReturnSettingEditor";
import {
  formatAddress,
  handleErrorResponse,
  handleSuccessResponse,
} from "client/utils/functions";
import { hasPermission } from "client/utils/userAccess";
import { AnalyticsEventType, Permission } from "loopmein-shared";
import { addAlert } from "api/redux/actions";
import { restAPI } from "../../../../../utils/rest";
import { Loading } from "client/components/Loading";
import { Session } from "client/utils/session";

import "./LeaseReturnSettingsTabPanel.css";

export enum leaseSettingLists {
  checklist = "Lease Checklist",
  company = "Lease Companies",
  status = "Lease Status",
}

@track(
  (props) => {
    return {
      event_type: AnalyticsEventType.SUBNAV,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }settings.lease-return`,
    };
  },
  { dispatchOnMount: true }
)
export class LeaseReturnSettingsTabPanelView extends React.Component<
  LMI.ILeaseReturnSettingsProps,
  LMI.ILeaseReturnSettingsState
> {
  settingSort = (a) => (a.name === "LEASE_RETURN_PROGRESS_METHOD" ? -1 : 0);
  editableLists = Object.values(leaseSettingLists);

  constructor(props) {
    super(props);

    this.state = {
      openSettingEditor: false,
      editSetting: null,
      openListEditor: false,
      editListItem: null,
      editList: null,
    };
  }

  formatSettingName(name) {
    return name
      .replace(/LEASE_RETURN_/g, "")
      .replace(/_/g, " ")
      .toLowerCase()
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.substr(1))
      .join(" ");
  }

  getSettingEditor(setting, settingName) {
    const { value, name } = setting;
    const isWarning = name === "LEASE_RETURN_WARNING_THRESHOLD_DAYS";
    const isProgressMethod = name === "LEASE_RETURN_PROGRESS_METHOD";

    return isProgressMethod ? (
      <Dropdown
        compact
        selection
        selectOnBlur={false}
        value={value}
        options={["Checklist", "Age"].map((o) => ({
          key: o,
          text: o,
          value: o.toLowerCase(),
        }))}
        onChange={(e, data) =>
          this.updateLeaseSetting({ name, value: data.value })
        }
      />
    ) : (
      <Label
        style={{
          backgroundColor: isWarning ? "#de9c38" : "#de5742",
          color: "#efefef",
        }}
        onClick={() =>
          this.setState({
            openSettingEditor: true,
            editSetting: { ...setting, ...{ settingName } },
          })
        }
      >
        <Icon link name="pencil" /> {`${value} Days`}
      </Label>
    );
  }

  getListItems(list) {
    let data = [];
    switch (list) {
      case leaseSettingLists.checklist:
        data = this.props.leasing_checklist_items;
        break;
      case leaseSettingLists.company:
        data = this.props.leasing_companies;
        break;
      case leaseSettingLists.status:
        data = this.props.lease_return_statuses;
        break;
      default:
        break;
    }
    return data;
  }

  getListPermissions(list) {
    // so far the only permission for the midtier is ADMIN_LEASE_RETURNS
    // if we need to wrap any permissions moving forward just use the leaseSettingLists enum to adjust for new perm
    const isLRAdmin = hasPermission(
      this.props.permissions,
      Permission.ADMIN_LEASE_RETURNS,
      Session.get("isSuperUser")
    );
    const canEdit = isLRAdmin;
    const canAdd = isLRAdmin;
    const canDelete = isLRAdmin;
    return { canEdit, canAdd, canDelete };
  }

  getGlobalStatusList() {
    const { global_lease_return_statuses, lease_return_statuses } = this.props;
    const globalStatuses = global_lease_return_statuses.filter(
      (s) => !lease_return_statuses.find((i) => i.id === s.id)
    );
    return (
      <div>
        <div className="tableTop">
          <Grid>
            <Grid.Column width={2}>TYPE</Grid.Column>
            <Grid.Column width={4}>NAME</Grid.Column>
            <Grid.Column width={10}>DESCRIPTION</Grid.Column>
          </Grid>
        </div>
        <List divided size="large" relaxed="very">
          {globalStatuses.map((item, index) => {
            return (
              <List.Item className="item disabled" key={index}>
                <Grid>
                  <Grid.Column width={2}>
                    <i>Global</i>
                  </Grid.Column>
                  <Grid.Column width={4}>{item.name}</Grid.Column>
                  <Grid.Column width={10} className="serviceDesc">
                    <em>
                      {item.description ? item.description : "No description"}
                    </em>
                  </Grid.Column>
                </Grid>
              </List.Item>
            );
          })}
        </List>
      </div>
    );
  }

  getSortableListEditor(list, key) {
    const items = this.getListItems(list);
    const { canEdit, canAdd, canDelete } = this.getListPermissions(list);
    const isChecklist = list === leaseSettingLists.checklist;
    const isCompanies = list === leaseSettingLists.company;
    const isStatuses = list === leaseSettingLists.status;
    let noItems = !items || items.length <= 0;

    if (isStatuses) {
      noItems =
        noItems &&
        (!this.props.global_lease_return_statuses ||
          this.props.global_lease_return_statuses.length <= 0);
    }

    return (
      <Segment key={key}>
        <Header as="h4">
          {canAdd ? (
            <Button
              className="add-item-btn"
              content="Add Item"
              floated="right"
              size="mini"
              onClick={() =>
                this.setState({ openListEditor: true, editList: list })
              }
            />
          ) : (
            ""
          )}
          {list}{" "}
          {isStatuses ? (
            <small style={{ color: "#999" }}>
              The greyed out items are system wide statuses - click "add item"
              to add a custom status
            </small>
          ) : (
            ""
          )}
        </Header>
        {noItems ? (
          <Header
            content={`There are currently no ${list} items configured.`}
            as="h5"
            color="grey"
          />
        ) : (
          <div style={{ clear: "both" }}>
            {isStatuses ? this.getGlobalStatusList() : ""}
            {items && items.length > 0 ? (
              <div className="tableTop">
                <Grid>
                  <Grid.Column width={2}>{`SORT${canEdit ? "/EDIT" : ""}${
                    canDelete ? "/DELETE" : ""
                  }`}</Grid.Column>
                  <Grid.Column width={isChecklist ? 14 : 4}>NAME</Grid.Column>
                  {!isChecklist && (
                    <Grid.Column width={10}>
                      {isCompanies ? "ADDRESS" : "DESCRIPTION"}
                    </Grid.Column>
                  )}
                </Grid>
              </div>
            ) : (
              ""
            )}
            <DragDropListComponent
              {...{
                listName: list.replace(/\s+/g, ""),
                items: items.map((item) => {
                  return {
                    item,
                    key: item.id,
                    name: item.name,
                    description: isCompanies
                      ? formatAddress(item)
                      : item.description,
                  };
                }),
                editable: canEdit,
                deletable: canDelete,
                dragHandle: true,
                hideDesc: isChecklist ? true : false,
                editCallback: (editListItem) =>
                  this.setState({
                    editList: list,
                    editListItem,
                    openListEditor: true,
                  }),
                deleteCallback: (editListItem) =>
                  this.removeListItem(editListItem, list),
                callback: (items) =>
                  this.orderListItems(
                    items.map((i) => i.item),
                    list
                  ),
              }}
            />
          </div>
        )}
      </Segment>
    );
  }

  render() {
    const { loading, store_settings } = this.props;
    
    if (loading) return <Loading />;
    const modalProps = this.getModalProps();
    const settings = [...store_settings];

    return (
      <div id="leaseSettings" className="panel-content">
        {modalProps && <ModalComponent {...modalProps} />}
        <Grid className="sticky-header">
          <Grid.Column width={12}>
            <Header as="h3">
              <Image src={`/images/lease_returns.png`} />
              <Header.Content>Lease Return Settings</Header.Content>
            </Header>
          </Grid.Column>
        </Grid>
        <Segment>
          <Header as="h4" content="Basic Settings" />
          {settings
            .sort((a, b) => this.settingSort(a))
            .map((setting, key) => {
              const { store_setting_type, name } = setting;
              const settingName = this.formatSettingName(name);
              return (
                <div key={key} className="lease-setting">
                  <Popup
                    inverted
                    content={store_setting_type.label}
                    trigger={this.getSettingEditor(setting, settingName)}
                  />
                  <span className="setting-name">{settingName}</span>
                </div>
              );
            })}
        </Segment>
        {this.editableLists.map((list, key) =>
          this.getSortableListEditor(list, key)
        )}
      </div>
    );
  }

  getModalProps() {
    const {
      editListItem,
      openListEditor,
      editSetting,
      openSettingEditor,
      editList,
    } = this.state;
    const settingEditorProps: LMI.IModalProps = {
      size: "mini",
      headerText: editSetting ? editSetting.settingName : "Edit Setting",
      shouldBeOpen: openSettingEditor,
      onClose: () =>
        this.setState({ openSettingEditor: false, editSetting: null }),
      contentComponent: () => (
        <LeaseReturnSettingEditor
          {...{
            item: editSetting,
            onSave: (data) =>
              this.setState({ openSettingEditor: false }, () =>
                this.updateLeaseSetting(data)
              ),
          }}
        />
      ),
    };
    const type =
      editList === leaseSettingLists.status
        ? "Status"
        : editList === leaseSettingLists.company
        ? "Company"
        : "Item";
    const listEditorProps: LMI.IModalProps = {
      size: editList !== "Lease Companies" ? "mini" : "medium",
      headerText: `${editListItem ? "Edit" : "Add"} ${type}`,
      shouldBeOpen: openListEditor,
      onClose: () =>
        this.setState({ openListEditor: false, editListItem: null }),
      contentComponent: () => (
        <LeaseReturnItemEditor
          {...{
            listType: editList,
            item: editListItem,
            onSave: (itemData) =>
              this.setState({ openListEditor: false }, () =>
                this.createListItem(itemData, editList)
              ),
            onUpdate: (itemData) =>
              this.setState({ openListEditor: false }, () =>
                this.updateListItem(itemData, editList)
              ),
          }}
        />
      ),
    };
    return openListEditor
      ? listEditorProps
      : openSettingEditor
      ? settingEditorProps
      : null;
  }

  getAlertProps(err, res) {
    return {
      type: err ? "danger" : "success",
      message: err
        ? handleErrorResponse({ error: err })
        : handleSuccessResponse({ result: res }),
      timeout: 3000,
    };
  }

  refetchListData(list: leaseSettingLists) {
    switch (list) {
      case leaseSettingLists.checklist:
        this.props.refetchChecklistItems();
        break;
      case leaseSettingLists.company:
        this.props.refetchCompanies();
        break;
      case leaseSettingLists.status:
        this.props.refetchStatuses();
        break;
      default:
        break;
    }
  }

  updateLeaseSetting(data: any) {
    restAPI({
      endpointName: "setStoreSettings",
      urlArgs: [this.props.storeId],
      data: { name: data.name, value: data.value.toString() },
      callback: (err, res) => {
        this.setState({ editSetting: null }, () => {
          this.props.addAlert(this.getAlertProps(err, res));
          this.props.refetchSettings();
        });
      },
    });
  }

  createListItem(item, list) {
    restAPI({
      endpointName: `create${list.replace(/\s+/g, "")}Item`,
      urlArgs: [this.props.storeId],
      data: item,
      callback: (err, res) => {
        this.setState({ editListItem: null }, () => {
          this.props.addAlert(this.getAlertProps(err, res));
          this.refetchListData(list);
        });
      },
    });
  }

  updateListItem(item, list) {
    restAPI({
      endpointName: `update${list.replace(/\s+/g, "")}Item`,
      urlArgs: [this.props.storeId, item.id],
      data: item,
      callback: (err, res) => {
        this.setState({ editListItem: null }, () => {
          this.props.addAlert(this.getAlertProps(err, res));
          this.refetchListData(list);
        });
      },
    });
  }

  removeListItem(item, list) {
    restAPI({
      endpointName: `remove${list.replace(/\s+/g, "")}Item`,
      urlArgs: [this.props.storeId, item.id],
      data: null,
      callback: (err, res) => {
        this.setState({ editListItem: null }, () => {
          this.props.addAlert(this.getAlertProps(err, res));
          this.refetchListData(list);
        });
      },
    });
  }

  orderListItems(items, list) {
    const orderedItems = { ids: items.map((i) => i.id) };
    restAPI({
      endpointName: `sort${list.replace(/\s+/g, "")}Items`,
      urlArgs: [this.props.storeId],
      data: orderedItems,
      callback: (err, res) => {
        this.props.addAlert(this.getAlertProps(err, res));
        this.refetchListData(list);
      },
    });
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const LeaseReturnSettingsTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IOrgSettingsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.settings.store,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            names: [
              "LEASE_RETURN_WARNING_THRESHOLD_DAYS",
              "LEASE_RETURN_ERROR_THRESHOLD_DAYS",
              "LEASE_RETURN_PROGRESS_METHOD",
            ],
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_settings, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        return {
          store_settings,
          refetchSettings: refetch,
        };
      },
    }
  ),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.leaseReturnChecklistTemplates,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: {
          error,
          loading,
          store_lease_return_checklist_templates,
          refetch,
        },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          leasing_checklist_items: store_lease_return_checklist_templates,
          refetchChecklistItems: refetch,
        };
      },
    }
  ),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.leaseReturnCompanies,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, leasing_companies, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          leasing_companies: leasing_companies.leasing_companies,
          refetchCompanies: refetch,
        };
      },
    }
  ),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.leaseReturnStatuses,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            storeLevelOnly: true,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, lease_return_statuses, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          lease_return_statuses: lease_return_statuses.lease_return_statuses,
          refetchStatuses: refetch,
        };
      },
    }
  ),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.leaseReturnStatuses,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            storeLevelOnly: false,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, lease_return_statuses, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          global_lease_return_statuses:
            lease_return_statuses.lease_return_statuses,
          refetchGlobalStatuses: refetch,
        };
      },
    }
  )
)(LeaseReturnSettingsTabPanelView) as React.ComponentType<any>;

export default LeaseReturnSettingsTabPanel;
