import * as React from "react";
import { Card, Checkbox, Confirm, Dimmer, Image, Loader, TextArea } from "semantic-ui-react";
import { ModalComponent } from "../../../ModalComponent";
import { restAPI } from "../../../../../../utils/rest";
import { InvoiceModalComponent } from "../../../tabs/InvoicesTabPanel/components";
import { handleErrorResponse } from "../../../../../../utils/functions";

export class DocumentCard extends React.Component<LMI.IInventoryDocumentCardProps, LMI.IInventoryDocumentCardTabState> {
	constructor(props) {
		super(props);
		this.state = {
			confirm_delete: false,
			is_deleting_id: null,
			is_sharing_id: null,
			view_share: null,
			customer_can_view: props.document.customer_can_view,
			store_can_modify: props.document.store_can_modify,
			name_editing: null,
			name: props.document.name ? props.document.name : "",
			saving_card: false
		};
	}

	onCustomerViewChanged = (id, args) => {
		// this.setState({ saving_card: true });

		const data = {
			customer_can_view: args.target.checked
		};
		restAPI({
			endpointName: "updateVehicleDocument",
			urlArgs: [this.props.storeId, id],
			data,
			callback: error => {
				let alert;
				if (error) {
					alert = {
						type: "danger",
						message: this.props.onHandleErrorResponse(error)
					};
				} else {
					this.setState({ customer_can_view: data.customer_can_view });

					alert = {
						type: "success",
						message: `Vehicle document successfully updated.`
					};
				}
				// this.setState({ saving_card: false });
				this.props.onAlert(alert);
			}
		});
	};

	onNameChanged = (id, name) => {
		const data = {
			name
		};
		restAPI({
			endpointName: "updateVehicleDocument",
			urlArgs: [this.props.storeId, id],
			data,
			callback: error => {
				let alert;
				if (error) {
					alert = {
						type: "danger",
						message: this.props.onHandleErrorResponse(error)
					};
				} else {
					alert = {
						type: "success",
						message: `Vehicle document type name successfully updated.`
					};
				}
				this.setState({ saving_card: false });
				this.props.onAlert(alert);
			}
		});
	};

	onDeleteDocument = id => {
		restAPI({
			endpointName: "delete_vehicle_document",
			urlArgs: [this.props.storeId, id],
			data: null,
			callback: error => {
				let alert;
				if (error) {
					alert = {
						type: "danger",
						message: handleErrorResponse({ error, message: error.message })
					};
				} else {
					alert = {
						type: "success",
						message: `Vehicle document successfully deleted.`
					};
				}
				this.props.refetch_vehicle_documents();
				this.props.onAlert(alert);
				this.setState({ is_deleting_id: null, confirm_delete: false });
			}
		});
	};

	shareDocument = id => {
		const data = { url: this.props.document.full_url };
		restAPI({
			endpointName: "getShortUrl",
			urlArgs: [this.props.storeId.toString()],
			data,
			callback: (error, result) => {
				let alert;
				if (error) {
					alert = {
						type: "danger",
						message: this.props.onHandleErrorResponse(error)
					};
					this.props.onAlert(alert);
				} else {
					console.log(result, "This feature is not turned on.  Please notify your administrator.");
					// This feature was put on hold so don't show the modal
					// this.setState({ view_share: true });
				}
			}
		});
	};

	onOpenImage = url => {
		window.open(url);
	};

	onEditName = (id, args) => {
		const textarea = args.currentTarget.firstChild;
		this.setState({ name_editing: id }, () => textarea.select());
	};

	onSaveName = (id, args) => {
		if (this.state.name.trim() !== "") {
			this.setState({ name_editing: null, saving_card: true });
			this.onNameChanged(id, this.state.name);
		} else {
			this.setState({ name_editing: null });
		}
	};

	onInputName = (id, args, { value }) => {
		this.setState({ name: value });
	};

	emailDocumentURL(formData: any, documentId: string) {
		// ToDo: This method doesn't exist yet.  we'll need to add it so that we can share the document URL.
		// const data = {
		// 	email: formData.email
		// };
		// restAPI({
		// 	endpointName: "shareDocumentURL",
		// 	urlArgs: [this.props.storeId, documentId],
		// 	data,
		// 	callback: (error, result) => {
		// 		const alert = {
		// 			type: !error ? "success" : "danger",
		// 			message: !error ? result.content.message : error.content.message
		// 		};
		//
		// 		this.props.onAlert(alert);
		// 	}
		// });
	}

	render() {
		const { print_ready, document } = this.props;
		const { document_type_name, full_url, external_url, default_image_url } = document;
		const id = document.database_id;
		const canEdit = id !== null;
		const { is_deleting_id, customer_can_view, store_can_modify, name_editing, name, saving_card, view_share } = this.state;

		const modalProps: LMI.IInvoiceModalProps = {
			link: view_share && view_share.link,
			sharing: view_share && view_share.sharing,
			onSubmit: (formData: any) => {
				this.emailDocumentURL(formData, view_share.document.id);
				this.setState({
					view_share: null
				});
			},
			onClose: () => this.setState({ view_share: null })
		};

		return (
			<Card id={print_ready ? "printReadyCard" : "documentCard"} className="document-card" key={id}>
				{is_deleting_id === id && (
					<Confirm
						open={!!this.state.confirm_delete}
						size="tiny"
						header="Please Confirm"
						content={`Delete document '${document_type_name}'? This action cannot be undone.`}
						cancelButton="Nevermind"
						confirmButton="Yes, Confirm"
						onCancel={() => this.setState({ confirm_delete: null })}
						onConfirm={this.onDeleteDocument.bind(this, id)}
					/>
				)}
				{view_share && (
					<ModalComponent
						headerText={view_share.sharing ? "Invoice Sharing" : "Your browser is set to block pop-ups!"}
						shouldBeOpen={view_share}
						onClose={(evt, data) => {
							this.setState({ view_share: null });
						}}
						size="small"
						className="region-details"
						contentComponent={() => <InvoiceModalComponent {...modalProps} />}
					/>
				)}
				{saving_card && (
					<Dimmer active>
						<Loader>Updating...</Loader>
					</Dimmer>
				)}
				<Card.Content extra>
					{!print_ready && !name_editing && canEdit && (
						<div>
							<span className="delete-button">
								<i className="icon delete hide" title="Delete Document" onClick={() => this.setState({ is_deleting_id: id, confirm_delete: true })} />
							</span>
						</div>
					)}
					<div className="document-type">{document_type_name}</div>
				</Card.Content>
				<Card.Content extra className="photo" onClick={this.onOpenImage.bind(this, external_url ? external_url : full_url)}>
					<Image
						title="Open image in new window"
						size={external_url ? "small" : "medium"}
						className={external_url ? "default" : "preview"}
						src={external_url ? default_image_url : `${full_url}?page=1&w=150&h=213`}
						loading="lazy"
					/>
				</Card.Content>
				{canEdit && !print_ready && (
					<Card.Content extra>
						<div>
							{name_editing && <i className="icon save" title="Save Name" />}
							<div className="ui two document-name" title="Edit Document Name" onClick={this.onEditName.bind(this, id)}>
								<TextArea className="doc-name" placeholder="Document Name" onBlur={this.onSaveName.bind(this, id)} onInput={this.onInputName.bind(this, id)} value={name} />
							</div>
							{store_can_modify && (
								<div title="Is this document available to customers?">
									<Checkbox id={"customerCanView-" + id} toggle={true} checked={!!customer_can_view} onChange={this.onCustomerViewChanged.bind(this, id)} />
									<div className="label">Customer Can View?</div>
								</div>
							)}
						</div>
					</Card.Content>
				)}
			</Card>
		);
	}
}

export default DocumentCard;
