import { restAPI } from "../../../../../utils/rest";
import { gqlQueries } from "gql-imports";
import { Loading } from "client/components/Loading";
import { VehicleSelector } from "client/components/VehicleSelector";
import { EFDInputComponent } from "client/pages/admin/components/EditableFieldDisplay/components/EFDInputComponent";
import {
  FileUploadComponent,
  MultiImageUploader,
} from "client/pages/admin/components/FileUploadComponent";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";

import { formatPhone, removeNullProps } from "client/utils/functions";
import { timeZones } from "client/utils/globals";
import { RegularExpressions } from "client/utils/regex";
import { hasPermission } from "client/utils/userAccess";
import { AnalyticsEventType, Permission, StoreType } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { addAlert } from "api/redux/actions";
import {
  Button,
  Card,
  Dimmer,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Loader,
  Popup,
  Segment,
  Statistic,
} from "semantic-ui-react";
import { EditableSelectableComponent } from "../../EditableSelectableComponent";
import { SelectableUserDisplay } from "../../SelectableUserDisplay";
import { StoreGroupComponent } from "../../StoreGroupComponent";
import EditAddressComponent from "../VendorTabPanel/components/EditAddressComponent/EditAddressComponent";
import { HoursComponent } from "./components/HoursComponent/HoursComponent";
import { RichTextComponent } from "./components/RichTextComponent/RichTextComponent";
import { StorePhotosViewer } from "./components/StorePhotosComponent/StorePhotosComponent";
import { Form } from "formsy-semantic-ui-react";
import { MaskedInputComponent } from "../../MaskedInputComponent";
import moment from "moment";
import track from "react-tracking";
import { Session } from "client/utils/session";

import "./StoreTabPanel.css";

@track(
  (props) => {
    return {
      event_type: props.tracking_path
        ? AnalyticsEventType.SUBNAV
        : AnalyticsEventType.NAVIGATION,
      event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}${
        props.userType === StoreType.Dealership ? "dealership" : "vendor"
      }.store`,
    };
  },
  { dispatchOnMount: true }
)
export class StoreTabPanelView extends React.Component<
  LMI.IStoreTPProps,
  LMI.IStoreTPState
> {
  constructor(props: LMI.IStoreTPProps) {
    super(props);

    this.state = {
      editHours: false,
      editingImage: false,
      editingWebsite: false,
      editTestimonial: false,
      editWhyUs: false,
      editingStoreImages: false,
      editingAddress: false,
      editingPhone: false,
      addingStoreImages: false,
      savingData: false,
      selectedBrands: [],
      editBrands: false,
    };
  }

  updatePhone(data) {
    restAPI({
      endpointName: "modifyStore",
      urlArgs: [this.props.store.id],
      data,
      callback: (err, res) => {
        if (!err)
          this.setState({ editingPhone: false }, () =>
            this.props.storeRefetch()
          );
      },
    });
  }

  render() {
    const props = this.props;
    const {
      editHours,
      editingImage,
      editingWebsite,
      editTestimonial,
      editWhyUs,
      editingStoreImages,
      editingAddress,
      addingStoreImages,
      savingData,
    } = this.state;

    const canEditStoreInfo = hasPermission(
      props.permissions,
      Permission.ADMIN_EDIT_STORE,
      Session.get("isSuperUser")
    );
    const canGoToVehicleDashboard = hasPermission(
      props.permissions,
      Permission.LIST_SANITIZATIONS,
      Session.get("isSuperUser")
    );

    if ((!props.store && !props.employees) || props.loading) return <Loading />;

    const store = props.store;
    const storeHours = props.store_hours;
    const storePhotos = store.store_photos
      ? store.store_photos.map((photo) => {
          const primaryStorePhotoId = store.primary_photo_full_url
            ? store.store_photos.find(
                (photo) => photo.full_url === store.primary_photo_full_url
              ).id
            : null;
          return {
            ...photo,
            ...{
              is_primary: photo.id === primaryStorePhotoId ? true : false,
            },
          };
        })
      : [];
    const employees = props.employees || [];
    const primaryContact = store.primary_contact_id || -1;
    const organization =
      store.organization ||
      ({
        id: -1,
        name: "None",
        regions: [],
        groups: [],
      } as LMI.IOrganizationGQL);
    const region = store.region || { id: -1 };
    const imageUrl: string = store.logo_url
      ? store.logo_url
      : "https://loopmein.imgix.net/default_dealership_logo.png";
    const activeEmployees = employees.filter((e) => e.is_active);
    const stats: any = [
      {
        value: activeEmployees.length,
        text: `Employee${activeEmployees.length > 1 ? "s" : ""}`,
        linked: false,
      },
    ];
    if (props.store_task_count) {
      if (props.store_task_count.open_task_count > 0) {
        stats.push({
          value: props.store_task_count.open_task_count,
          text: `Open Task${
            props.store_task_count.open_task_count > 1 ? "s" : ""
          }`,
          linked: false,
        });
      }
      if (props.store_task_count.closed_task_count > 0) {
        stats.push({
          value: props.store_task_count.closed_task_count,
          text: `Closed Task${
            props.store_task_count.closed_task_count > 1 ? "s" : ""
          }`,
          linked: false,
        });
      }
    }

    const editAddressModalProps: LMI.IEditAddressProps = {
      store_country_code: store.country_code,
      address: Object.assign(
        { address1: store.address1 },
        { address2: store.address2 },
        { city: store.city },
        { state: store.state },
        { zip: store.zip }
      ),
      updateAddress: (data) => {
        this.submitAddress(data);
        this.setState({ editingAddress: false });
      },
    };

    return (
      <div className="store-tab-panel panel-content">
        {editHours && (
          <ModalComponent
            headerText="Opening Hours"
            shouldBeOpen={true}
            onClose={() => this.setState({ editHours: false })}
            contentComponent={() => (
              <HoursComponent
                {...{
                  hours: storeHours,
                  onSubmit: (hours) => this.saveCompanyHours(hours),
                }}
              />
            )}
          />
        )}
        {editingImage && (
          <ModalComponent
            headerText="Upload Store Logo"
            shouldBeOpen={editingImage}
            onClose={() => {
              this.setState({ editingImage: false });
            }}
            className="upload-image-modal"
            contentComponent={() => (
              <FileUploadComponent
                {...{
                  filesToAccept: "image/png, image/jpeg",
                  onSubmit: this.onSubmitLogoHandler.bind(this),
                  onClose: () => {
                    this.setState({ editingImage: false });
                  },
                }}
              />
            )}
          />
        )}
        {addingStoreImages && (
          <ModalComponent
            headerText="Upload Store Photos"
            shouldBeOpen={addingStoreImages}
            onClose={() => {
              this.setState({ addingStoreImages: false });
            }}
            className="upload-image-modal"
            contentComponent={() => (
              <MultiImageUploader
                {...{
                  onSubmit: (images) => this.onSubmitStorePhotosHandler(images),
                  onClose: () => this.setState({ addingStoreImages: false }),
                }}
              />
            )}
          />
        )}
        {editingStoreImages && (
          <ModalComponent
            headerText="Store Photos"
            shouldBeOpen={editingStoreImages}
            onClose={() => {
              this.setState({ editingStoreImages: false });
            }}
            className="store-images-modal"
            contentComponent={() => (
              <StorePhotosViewer
                {...{
                  store_photos: storePhotos,
                  active: storePhotos[0],
                  thumb_format:
                    "?fp-x=0.5&fp-y=0.5&fp-z=1&fit=crop&w=119&h=90&crop=focalpoint&auto=compress,enhance",
                  main_image_format:
                    "?&fit=clamp&max-w=860&max-h=480&auto=compress,enhance",
                  canEdit: canEditStoreInfo,
                  onClose: () => {
                    this.setState({ editingStoreImages: false });
                  },
                  onDelete: (photo) => {
                    restAPI({
                      endpointName: "removeStorePhoto",
                      urlArgs: [store.id, photo.id],
                      data: null,
                      callback: (err, res) => {
                        if (err) {
                          console.error(err);
                        }
                        props.storeRefetch();
                      },
                    });
                  },
                  setPrimary: (photo) => {
                    restAPI({
                      endpointName: "modifyStorePhoto",
                      urlArgs: [store.id, photo.id],
                      data: {
                        is_primary: true,
                      },
                      callback: (err, res) => {
                        if (err) {
                          console.error(err);
                        }
                        props.storeRefetch();
                      },
                    });
                  },
                }}
              />
            )}
          />
        )}
        {editTestimonial && (
          <ModalComponent
            headerText="Customer Testimonials"
            shouldBeOpen={true}
            onClose={() => {
              this.setState({ editTestimonial: false });
            }}
            className="edit-testimonial"
            contentComponent={() => (
              <RichTextComponent
                {...{
                  value: store.testimonial,
                  placeholder: "...Add Testimonial",
                  onSave: (value) => {
                    this.saveTestimonial(value);
                    this.setState({ editTestimonial: false });
                  },
                  onClose: () => {
                    this.setState({ editTestimonial: false });
                  },
                }}
              />
            )}
          />
        )}
        {editWhyUs && (
          <ModalComponent
            headerText="Why Us?"
            shouldBeOpen={true}
            onClose={() => {
              this.setState({ editWhyUs: false });
            }}
            className="edit-testimonial"
            contentComponent={() => (
              <RichTextComponent
                {...{
                  value: store.why_us,
                  placeholder: "...Add Why Us?",
                  onSave: (value) => {
                    this.saveWhyUs(value);
                    this.setState({ editWhyUs: false });
                  },
                  onClose: () => {
                    this.setState({ editWhyUs: false });
                  },
                }}
              />
            )}
          />
        )}
        <Grid className="mainGrid">
          <Grid.Column width={4}>
            <div className="store-photos-container">
              <Card
                fluid
                image={imageUrl + "?w=600&max-h=350&auto=compress%2Cenhance"}
                className="store-logo"
                onClick={() => {
                  if (canEditStoreInfo) this.setState({ editingImage: true });
                }}
                extra={
                  canEditStoreInfo ? (
                    <Button
                      className="change-image-btn"
                      onClick={() => {
                        this.setState({ editingImage: true });
                      }}
                    >
                      Update Store Logo
                    </Button>
                  ) : (
                    <span />
                  )
                }
              />

              <Card fluid className="store-photos">
                <Card.Content>
                  <Header>
                    {storePhotos && storePhotos.length && canEditStoreInfo ? (
                      <div className="store-photo-tools">
                        <Popup
                          content="Add Store Photos"
                          trigger={
                            <Icon
                              name="add"
                              onClick={() =>
                                this.setState({ addingStoreImages: true })
                              }
                            />
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    Store Photos
                  </Header>
                </Card.Content>
                <Card.Content className="phtos">
                  {storePhotos && storePhotos.length ? (
                    <div
                      onClick={() =>
                        this.setState({ editingStoreImages: true })
                      }
                    >
                      <Image
                        className="primary"
                        label={{
                          color: "orange",
                          corner: "right",
                          icon: "thumbtack",
                        }}
                        loading="lazy"
                        src={
                          storePhotos.filter((f) => f.is_primary).length
                            ? storePhotos.filter((f) => f.is_primary)[0]
                                .full_url +
                              "?fit=crop&w=300&h=auto&auto=compress,enhance"
                            : storePhotos[0].full_url +
                              "?fp-x=0.5&fp-y=0.5&fp-z=1.5&fit=crop&w=90&h=60&crop=focalpoint&auto=compress,enhance"
                        }
                      />
                      {storePhotos
                        .filter((f) => !f.is_primary)
                        .map((file, index) => {
                          if (index <= 2) {
                            return (
                              <div key={index} className="thumbs">
                                <Image
                                  key={index}
                                  loading="lazy"
                                  src={`${
                                    file.full_url +
                                    "?fp-x=0.5&fp-y=0.5&fp-z=1.5&fit=crop&w=90&h=60&crop=focalpoint&auto=compress,enhance"
                                  }`}
                                />
                              </div>
                            );
                          }
                        })}
                    </div>
                  ) : (
                    <>
                      {canEditStoreInfo ? (
                        <Button
                          content="Upload Store Photos"
                          fluid
                          onClick={() =>
                            this.setState({ addingStoreImages: true })
                          }
                        />
                      ) : (
                        <span />
                      )}
                    </>
                  )}
                </Card.Content>
                {storePhotos && storePhotos.length ? (
                  <Card.Content extra>
                    <Icon name="photo" />
                    {`${storePhotos.length} Store Photos`}
                  </Card.Content>
                ) : (
                  ""
                )}
              </Card>
            </div>
          </Grid.Column>
          <Grid.Column width={12}>
            <Dimmer active={savingData} inverted>
              <Loader>Saving</Loader>
            </Dimmer>
            <Segment
              color="blue"
              padded
              className="scrollable-el"
              style={{ marginTop: 0 }}
            >
              <Grid>
                <Grid.Column width={8}>
                  <Header as="h2">
                    {store.name}
                    <Header.Subheader>{organization.name}</Header.Subheader>
                    <Header.Subheader>
                      Store Code: {store.store_code}{" "}
                      {canGoToVehicleDashboard && (
                        <span>
                          (
                          <a
                            target="_blank"
                            href={`${process.env.REACT_APP_VEHICLE_DASH_URL}/${store.store_code}`}
                          >
                            Sanitization Dashboard
                          </a>
                          )
                        </span>
                      )}
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
                <Grid.Column width={8} className="quick-stats">
                  {stats && stats.length > 1 ? (
                    <Statistic.Group size="mini" widths={stats.length}>
                      {stats &&
                        stats.map((stat, index) => {
                          return (
                            <Statistic
                              color="blue"
                              key={index}
                              className={stat.linked ? "stat statLink" : "stat"}
                              onClick={stat.callback}
                            >
                              <Statistic.Value>{stat.value}</Statistic.Value>
                              <Statistic.Label>{stat.text}</Statistic.Label>
                            </Statistic>
                          );
                        })}
                    </Statistic.Group>
                  ) : (
                    <Statistic
                      color="blue"
                      size="mini"
                      floated="right"
                      className={stats[0].linked ? "stat statLink" : "stat"}
                      onClick={stats[0].callback}
                    >
                      <Statistic.Value>{stats[0].value}</Statistic.Value>
                      <Statistic.Label>{stats[0].text}</Statistic.Label>
                    </Statistic>
                  )}
                </Grid.Column>
                <Grid.Column width={16}>
                  <Divider fitted />
                  <br />
                  <Grid className="store-info">
                    <Grid.Row>
                      <Grid.Column width={9}>
                        <label>Store Contact Info:</label>
                        <div
                          className={
                            canEditStoreInfo
                              ? "storeinfoblock"
                              : "storeinfoblock disabled"
                          }
                        >
                          <List relaxed size="large">
                            <List.Item>
                              <List.Icon name="marker" />
                              <List.Content>
                                {editingAddress && (
                                  <ModalComponent
                                    headerText={"Edit Address"}
                                    shouldBeOpen={this.state.editingAddress}
                                    onClose={() =>
                                      this.setState({ editingAddress: false })
                                    }
                                    className="edit-cost-modal"
                                    contentComponent={() => (
                                      <EditAddressComponent
                                        {...editAddressModalProps}
                                      />
                                    )}
                                  />
                                )}
                                <div>
                                  {store.address1 ? (
                                    <span>
                                      {canEditStoreInfo && (
                                        <span className="editfunction">
                                          <Icon
                                            name="pencil"
                                            link
                                            onClick={() =>
                                              this.setState({
                                                editingAddress: true,
                                              })
                                            }
                                          />
                                        </span>
                                      )}
                                      {store.address1}
                                      {store.address2 && store.address2}
                                      <br />
                                      {store.city}, {store.state} {store.zip}
                                    </span>
                                  ) : (
                                    <>
                                      {canEditStoreInfo ? (
                                        <a
                                          onClick={() =>
                                            this.setState({
                                              editingAddress: true,
                                            })
                                          }
                                        >
                                          Update Address
                                        </a>
                                      ) : (
                                        <span className="nodata">Not Set</span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </List.Content>
                            </List.Item>
                            <List.Item>
                              <List.Icon name="phone" />
                              <List.Content>
                                {canEditStoreInfo && (
                                  <span>
                                    {!this.state.editingPhone ? (
                                      <span className="editfunction">
                                        <Icon
                                          name={
                                            store.phone
                                              ? "pencil"
                                              : "add circle"
                                          }
                                          link
                                          onClick={() =>
                                            this.setState({
                                              editingPhone: true,
                                            })
                                          }
                                        />
                                      </span>
                                    ) : (
                                      <span className="editfunction">
                                        <Icon
                                          name="close"
                                          color="red"
                                          link
                                          onClick={() =>
                                            this.setState({
                                              editingPhone: false,
                                            })
                                          }
                                        />
                                      </span>
                                    )}
                                  </span>
                                )}

                                {this.state.editingPhone ? (
                                  <Form
                                    noValidate
                                    ref="form"
                                    size="small"
                                    onSubmit={this.updatePhone.bind(this)}
                                    className="phone-form"
                                  >
                                    <Grid>
                                      <Grid.Column width={10}>
                                        <MaskedInputComponent
                                          {...{
                                            type: "phone",
                                            value: store.phone,
                                            action: {
                                              icon: "check",
                                              color: "green",
                                            },
                                          }}
                                        />
                                      </Grid.Column>
                                    </Grid>
                                  </Form>
                                ) : (
                                  <>
                                    {store.phone ? (
                                      formatPhone(store.phone)
                                    ) : (
                                      <span className="nodata">Not Set</span>
                                    )}
                                  </>
                                )}
                              </List.Content>
                            </List.Item>
                            <List.Item>
                              <List.Icon name="globe" />

                              <List.Content>
                                {canEditStoreInfo && editingWebsite ? (
                                  <EFDInputComponent
                                    id="editWebsite"
                                    labeled={false}
                                    value={store.website_url}
                                    onSubmit={this.submitWebsite.bind(this)}
                                    onCancel={() =>
                                      this.setState({ editingWebsite: false })
                                    }
                                    type="text"
                                    masked={false}
                                    validations={{
                                      matchRegexp: /^http[s]?\:\/\//,
                                    }}
                                    validationErrors={{
                                      matchRegexp:
                                        "Website must contain link protocol 'https://' or 'http://'",
                                    }}
                                  />
                                ) : (
                                  <div>
                                    {store.website_url ? (
                                      <span>
                                        {canEditStoreInfo && (
                                          <span className="editfunction">
                                            <Icon
                                              name="pencil"
                                              link
                                              onClick={() =>
                                                this.setState({
                                                  editingWebsite: true,
                                                })
                                              }
                                            />
                                          </span>
                                        )}
                                        <a
                                          href={store.website_url}
                                          target="_blank"
                                        >
                                          {store.website_url}
                                        </a>
                                      </span>
                                    ) : (
                                      <>
                                        {canEditStoreInfo ? (
                                          <a
                                            onClick={() =>
                                              this.setState({
                                                editingWebsite: true,
                                              })
                                            }
                                          >
                                            Add Website
                                          </a>
                                        ) : (
                                          <span className="nodata">
                                            No Website Set
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </List.Content>
                            </List.Item>
                          </List>
                        </div>
                        <br />
                        <label>Store Hours:</label>
                        <div
                          className={
                            canEditStoreInfo
                              ? "storeinfoblock hours"
                              : "storeinfoblock hours disabled"
                          }
                        >
                          {canEditStoreInfo && storeHours && (
                            <span className="actionbtns">
                              <Icon
                                link
                                name="pencil"
                                onClick={() => {
                                  this.setState({ editHours: true });
                                }}
                              />
                            </span>
                          )}
                          {storeHours ? (
                            <List relaxed size="small">
                              {storeHours.days.map((hours, index) => {
                                return (
                                  <List.Item key={index}>
                                    <List.Content>
                                      <strong>{hours.day_text}</strong>{" "}
                                      {this.getHourDisplay(hours)}
                                    </List.Content>
                                  </List.Item>
                                );
                              })}
                            </List>
                          ) : canEditStoreInfo ? (
                            store.timezone_name ? (
                              <Button
                                content="Add Store Hours"
                                size="tiny"
                                onClick={() =>
                                  this.setState({ editHours: true })
                                }
                              />
                            ) : (
                              <span>
                                A timezone must be selected before store hours
                                can be entered.
                              </span>
                            )
                          ) : (
                            <span>Not Available</span>
                          )}
                        </div>
                        <br />
                        <label>Primary Contact:</label>
                        <div
                          className={
                            canEditStoreInfo
                              ? "storeinfoblock"
                              : "storeinfoblock disabled"
                          }
                        >
                          <SelectableUserDisplay
                            id="selectable-administrator-field"
                            startingValue={primaryContact}
                            size="large"
                            options={employees
                              .filter((emp) => {
                                return emp.is_active;
                              })
                              .map((emp) => {
                                return {
                                  id: emp.id.toString(),
                                  fullname: emp.user.full_name,
                                  email: emp.user.email,
                                  phone: emp.user.phone,
                                };
                              })}
                            onClick={(data) => {
                              this.modifyStore(store.id, {
                                primary_contact_id: parseInt(
                                  data.currentValue,
                                  10
                                ),
                              });
                            }}
                            canEdit={canEditStoreInfo}
                          />
                        </div>
                        {props.viewType.includes("dealerships") && (
                          <div>
                            <br />
                            <label>Testimonial:</label>
                            <div
                              className={
                                canEditStoreInfo
                                  ? "storeinfoblock testimonial"
                                  : "storeinfoblock testimonial disabled"
                              }
                            >
                              {store.testimonial ? (
                                <div>
                                  {canEditStoreInfo && (
                                    <span className="actionbtns">
                                      <Icon
                                        link
                                        name="pencil"
                                        onClick={() => {
                                          this.setState({
                                            editTestimonial: true,
                                          });
                                        }}
                                      />
                                    </span>
                                  )}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        store.testimonial &&
                                        store.testimonial.length
                                          ? store.testimonial
                                          : "...Add Testimonial",
                                    }}
                                  />
                                </div>
                              ) : canEditStoreInfo ? (
                                <Button
                                  size="tiny"
                                  content="Add Testimonial"
                                  onClick={() =>
                                    this.setState({ editTestimonial: true })
                                  }
                                />
                              ) : (
                                <span className="nodata">Not Set</span>
                              )}
                            </div>
                            <br />
                            <label>Why Us?:</label>
                            <div
                              className={
                                canEditStoreInfo
                                  ? "storeinfoblock testimonial"
                                  : "storeinfoblock testimonial disabled"
                              }
                            >
                              {store.why_us ? (
                                <div>
                                  {canEditStoreInfo && (
                                    <span className="actionbtns">
                                      <Icon
                                        link
                                        name="pencil"
                                        onClick={() => {
                                          this.setState({ editWhyUs: true });
                                        }}
                                      />
                                    </span>
                                  )}
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: store.why_us,
                                    }}
                                  />
                                </div>
                              ) : canEditStoreInfo ? (
                                <Button
                                  size="tiny"
                                  content="Add Why Us"
                                  onClick={() =>
                                    this.setState({ editWhyUs: true })
                                  }
                                />
                              ) : (
                                <span className="nodata">Not Set</span>
                              )}
                            </div>
                          </div>
                        )}
                      </Grid.Column>
                      <Grid.Column width={7}>
                        <label>Region:</label>
                        <div
                          className={
                            canEditStoreInfo
                              ? "storeinfoblock"
                              : "storeinfoblock disabled"
                          }
                        >
                          <EditableSelectableComponent
                            title="Region"
                            id="editable-selectable-regions-field"
                            startingValue={region.id}
                            onClick={(data) => {
                              this.updateRegion(store.id, data.currentValue);
                            }}
                            options={organization.regions.map(
                              (reg: LMI.IRegionGQL): LMI.IESCOption => {
                                return {
                                  text: reg.name,
                                  value: reg.id,
                                };
                              }
                            )}
                            disabled={!canEditStoreInfo}
                          />
                        </div>
                        <br />
                        <label>Time Zone</label>
                        <div
                          className={
                            canEditStoreInfo
                              ? "storeinfoblock"
                              : "storeinfoblock disabled"
                          }
                        >
                          <EditableSelectableComponent
                            title="Timezone"
                            id="editable-selectable-timezone-field"
                            startingValue={timeZones.findIndex(
                              (tz) => tz.utc === store.timezone_name
                            )}
                            onClick={(data) => {
                              const selectedTimeZone =
                                timeZones[data.currentValue].utc;
                              this.modifyStore(store.id, {
                                timezone_name: selectedTimeZone,
                              });
                            }}
                            options={timeZones.map((zone, index) => ({
                              text: zone.value,
                              value: index,
                            }))}
                            disabled={!canEditStoreInfo}
                          />
                        </div>
                        <br />
                        <label>Groups:</label>
                        <div
                          className={
                            canEditStoreInfo
                              ? "storeinfoblock"
                              : "storeinfoblock disabled"
                          }
                        >
                          <StoreGroupComponent
                            groups={organization.groups}
                            storeGroups={store.store_groups}
                            id="store-group-editible-field"
                            onClick={(data) => {
                              if (data.checked) {
                                this.addStoreGroup(store.id, data.orgGroupId);
                              } else {
                                this.removeStoreGroup(
                                  store.id,
                                  data.orgGroupId
                                );
                              }
                            }}
                            canEdit={canEditStoreInfo}
                          />
                        </div>
                        <br />
                        <label>Store Brands:</label>
                        <div
                          className={
                            canEditStoreInfo
                              ? "storeinfoblock testimonial"
                              : "storeinfoblock testimonial disabled"
                          }
                        >
                          {props.store_brands && !this.state.editBrands ? (
                            <div>
                              {props.store_brands.length === 0 ? (
                                <>
                                  {canEditStoreInfo ? (
                                    <Button
                                      content="Add Brands"
                                      size="tiny"
                                      onClick={() =>
                                        this.setState({ editBrands: true })
                                      }
                                    />
                                  ) : (
                                    <span className="nodata">Not Set</span>
                                  )}
                                </>
                              ) : (
                                <List verticalAlign="middle">
                                  {canEditStoreInfo && (
                                    <span className="actionbtns">
                                      <Icon
                                        link
                                        name="pencil"
                                        onClick={() => {
                                          const selectedBrands: any = props.store_brands.map(
                                            (b) => b.vehicle_make.id
                                          );
                                          this.setState({
                                            editBrands: true,
                                            selectedBrands,
                                          });
                                        }}
                                      />
                                    </span>
                                  )}
                                  {props.store_brands.map((brand, key) => {
                                    return (
                                      <List.Item key={key}>
                                        <img
                                          className="ui avatar image"
                                          src={this.getBrandPhoto(
                                            brand.vehicle_make.name
                                          )}
                                          onError={(e: any) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              "https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/Aiga_carrental_cropped.svg/912px-Aiga_carrental_cropped.svg.png";
                                          }}
                                        />
                                        <List.Content>
                                          <List.Header>
                                            {brand.vehicle_make.name}
                                          </List.Header>
                                        </List.Content>
                                      </List.Item>
                                    );
                                  })}
                                </List>
                              )}
                            </div>
                          ) : (
                            <Grid>
                              <Grid.Column width={12}>
                                <VehicleSelector
                                  {...({
                                    spec: "Make",
                                    hideLabel: true,
                                    useItemIdsAsValues: true,
                                    isRequired: true,
                                    isMultiSelect: true,
                                    value: this.state.selectedBrands,
                                    context: { year: moment().year() },
                                    onSelection: (selected) => {
                                      const addedMakes = selected.filter(
                                        (make) =>
                                          this.state.selectedBrands.indexOf(
                                            make
                                          ) === -1
                                      );
                                      let selectedBrands = [
                                        ...this.state.selectedBrands,
                                        ...addedMakes,
                                      ];
                                      const removedMakes = this.state.selectedBrands.filter(
                                        (make) => selected.indexOf(make) === -1
                                      );
                                      if (removedMakes)
                                        selectedBrands = selectedBrands.filter(
                                          (make) =>
                                            removedMakes.indexOf(make) === -1
                                        );
                                      this.setState({ selectedBrands });
                                    },
                                  } as LMI.VehicleSelectorProps)}
                                />
                              </Grid.Column>
                              <Grid.Column width={2}>
                                <Button
                                  content="Save"
                                  positive
                                  onClick={() => this.saveStoreBrands()}
                                />
                              </Grid.Column>
                            </Grid>
                          )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  getBrandPhoto = (brand) => {
    return `https://logo.clearbit.com/${brand}.com`;
  };

  saveStoreBrands = () => {
    const { selectedBrands } = this.state;
    restAPI({
      endpointName: "modify_store_brands",
      urlArgs: [this.props.store.id],
      data: { make_ids: selectedBrands },
      callback: (err, res) => {
        if (!err) this.props.refetchBrands();
        this.setState({ editBrands: false }, () =>
          this.props.addAlert({
            type: err ? "danger" : "success",
            message: err ? err.reason.response.data.message : res.data.message,
            timeout: 3000,
          })
        );
      },
    });
  };

  saveCompanyHours = (hours) => {
    if (!hours) return;
    this.setState({ savingData: true, editHours: false });
    restAPI({
      endpointName: "modifyStoreHours",
      urlArgs: [this.props.store.id],
      data: {
        days: hours.days
          .filter((h) => h.open)
          .map((day) => {
            return {
              day: day.day,
              open_time: day.open_time,
              close_time: day.close_time,
            };
          }),
      },
      callback: (err, res) => {
        if (!err) {
          this.refetchQueryResults();
        }
      },
    });
  };

  submitWebsite = (formData) => {
    if (formData.search(RegularExpressions.ISWEBSITE) >= 0) {
      this.modifyStore(this.props.store.id, { website_url: formData });
      this.setState({ editingWebsite: false });
    } else {
      this.props.addAlert({
        type: "danger",
        message:
          "Website must contain ssl protocol 'https://' or 'http://' to be updated",
        timeout: 3000,
      });
    }
  };

  submitAddress = (formData) => {
    this.modifyStore(this.props.store.id, formData);
    this.setState({ editingAddress: false });
  };

  saveTestimonial = (testimonial) => {
    const testNotNull = testimonial && testimonial.length ? testimonial : "";
    this.modifyStore(this.props.store.id, { testimonial: testNotNull });
  };

  saveWhyUs = (why_us) => {
    const whyUsNotNull = why_us && why_us.length ? why_us : "";
    this.modifyStore(this.props.store.id, { why_us: whyUsNotNull });
  };

  addStorePhotoTask = (photo) => {
    return new Promise((resolve, reject) => {
      const { store } = this.props;
      restAPI({
        endpointName: "addStorePhoto",
        urlArgs: [store.id],
        data: photo,
        callback: (err, res) => {
          if (err) {
            console.error(err);
          }
          resolve(res);
        },
      });
    });
  };

  onSubmitStorePhotosHandler = async (photoObjects) => {
    const requests = photoObjects.map((photo) => {
      return this.addStorePhotoTask({ photo: photo.image });
    });
    await Promise.all(requests);
    this.setState({ addingStoreImages: false });
    this.props.storeRefetch();
  };

  buildHour = (period) => {
    if (!period) return;
    let hour = period.substring(0, 2);
    hour =
      parseInt(hour, 10) > 12
        ? parseInt(hour, 10) - 12
        : hour.charAt(0) === "0"
        ? hour.substring(1, 2)
        : hour;
    const minutes = period.substring(2, 4);
    const amPm = period >= 1200 ? "PM" : "AM";
    return `${hour}:${minutes}${amPm}`;
  };

  getHourDisplay = (period: LMI.IStoreOpeningPeriod) => {
    if (!period.open) {
      return "Closed";
    }
    return `${this.buildHour(period.open_time)}-${this.buildHour(
      period.close_time
    )}`;
  };

  onSubmitLogoHandler(imageObject) {
    const logo = {
      logo: imageObject.image,
    };
    restAPI({
      endpointName: "updateLogo",
      urlArgs: [this.props.store.id],
      data: logo,
      callback: (err, res) => {
        if (!err) {
          this.refetchQueryResults();
        }
      },
    });
    this.setState({ editingImage: false });
  }

  modifyStore(storeId: number, data: any) {
    this.setState({ savingData: true });
    const formData = removeNullProps(data);
    restAPI({
      endpointName: "modifyStore",
      urlArgs: [storeId],
      data: formData,
      callback: (err, res) => {
        if (!err) {
          this.refetchQueryResults();
        }
      },
    });
  }

  updateAutoGroup(storeId: number, auto_group_name: string) {
    restAPI({
      endpointName: "updateAutoGroup",
      urlArgs: [storeId],
      data: { auto_group_name },
      callback: (err, res) => {
        if (!err) {
          this.refetchQueryResults();
        }
      },
    });
  }

  addStoreGroup(storeId: number, groupId: number) {
    restAPI({
      endpointName: "addStoreGroup",
      urlArgs: [storeId, groupId],
      data: null,
      callback: (err, res) => {
        if (!err) {
          this.refetchQueryResults();
        }
      },
    });
  }

  removeStoreGroup(storeId: number, groupId: number) {
    restAPI({
      endpointName: "removeStoreGroup",
      urlArgs: [storeId, groupId],
      data: null,
      callback: (err, res) => {
        if (!err) {
          this.refetchQueryResults();
        }
      },
    });
  }

  updateRegion(storeId: number, regionName: string) {
    restAPI({
      endpointName: "updateRegion",
      urlArgs: [storeId],
      data: { organization_region_id: regionName },
      callback: (err, res) => {
        this.props.addAlert({
          type: err ? "danger" : "success",
          message: err ? err.reason.response.data.message : res.data.message,
          timeout: 3000,
        });
        if (!err) {
          this.refetchQueryResults();
        }
      },
    });
  }

  refetchQueryResults() {
    const { usersRefetch, storeHoursRefetch, storeRefetch } = this.props;
    usersRefetch();
    storeRefetch();
    storeHoursRefetch();
    this.setState({ savingData: false });
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    permissions: state.app.admin.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const StoreTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IStoreQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.store,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          store,
          storeRefetch: refetch,
        };
      },
    }
  ),
  graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.employees,
    {
      skip: (ownProps: any) => !ownProps.storeId,
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, employees, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          employees,
          usersRefetch: refetch,
        };
      },
    }
  ),
  graphql<LMI.ITaskCountProps, any, any, ClassAttributes<any>>(
    gqlQueries.taskCount,
    {
      skip: (ownProps: any) => {
        return !hasPermission(
          ownProps.permissions,
          Permission.ADMIN_EDIT_STORE,
          Session.get("isSuperUser")
        );
      },
      options: (props: any) => {
        return {
          variables: {
            store_id: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_task_count, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          store_task_count,
          taskRefetch: refetch,
        };
      },
    }
  ),
  graphql<LMI.IStoreHoursGQLProps, any, any, ClassAttributes<any>>(
    gqlQueries.storeHours,
    {
      skip: (ownProps: any) => !ownProps.storeId,
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_hours, refetch } }): any => {
        if (loading) return { loading: true };
        if (error)
          return {
            hasErrors: true,
            message: error,
            storeHoursRefetch: refetch,
          };

        return {
          store_hours,
          storeHoursRefetch: refetch,
        };
      },
    }
  ),
  graphql<LMI.IStoreBrandsGQLProps, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.storeBrands,
    {
      skip: (ownProps: any) => !ownProps.storeId,
      options: (props: any) => {
        return {
          variables: {
            store_id: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_makes, refetch } }): any => {
        if (loading) return { loading: true };
        if (error)
          return { hasErrors: true, message: error, refetchBrands: refetch };

        return {
          store_brands: store_makes.store_makes,
          refetchBrands: refetch,
        };
      },
    }
  )
)(StoreTabPanelView) as React.ComponentType<any>;

export default StoreTabPanel;
