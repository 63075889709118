// node_modules
import * as React from "react";
import { connect } from "react-redux";
// redux
import { triggerUABCallback } from "api/redux/actions";
// components
import { Button, Icon, Popup } from "semantic-ui-react";
import "./UniversalActionButton.css";

export class UniversalActionButtonView extends React.Component<LMI.IUABProps, undefined> {
	render() {
		const { subUniversalActionCallback, universalActionCallback, icon, size, tip } = this.props;

		if (!tip) {
			return (
				<div className={`universal-action-button ${subUniversalActionCallback ? "sub" : ""}`}>
					<Button
						className={`${size ? size : "large"}`}
						onClick={() => {
							subUniversalActionCallback ? subUniversalActionCallback() : universalActionCallback();
						}}
					>
						{icon ? icon : <Icon name="add" size={size ? size : "large"} />}
					</Button>
				</div>
			);
		} else {
			return (
				<div className={`universal-action-button ${subUniversalActionCallback ? "sub" : ""}`}>
					<Popup
						inverted={true}
						position="top right"
						className={`universal-action-button-popup`}
						trigger={
							<Button
								className={`${size ? size : "large"}`}
								onClick={() => {
									subUniversalActionCallback ? subUniversalActionCallback() : universalActionCallback();
								}}
							>
								{icon ? icon : <Icon name="add" size={size ? size : "large"} />}
							</Button>
						}
					>
						<span>{tip}</span>
					</Popup>
				</div>
			);
		}
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		universalActionCallback: () => {
			dispatch(triggerUABCallback(true));
		}
	};
};

// @ts-ignore
export const UniversalActionButton: any = connect(mapStateToProps, mapDispatchToProps)(UniversalActionButtonView);
