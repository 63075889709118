// node_modules
import * as React from "react";
import { zeroPlus, targetHasValue } from "client/utils/functions";

import createNumberMask from "text-mask-addons/dist/createNumberMask";
import MaskedInput from "react-text-mask";

import { Button, Form, Input, InputOnChangeData, Divider } from "semantic-ui-react";

export class InspectionItemTypeForm extends React.Component<LMI.IInspectionItemTypeEditorProps, LMI.IInspectionItemTypeEditorState> {
	newItem: LMI.InspectionItemTypeOption = {
		id: 0,
		name: null,
		op_code: null,
		labor: null,
		parts: null,
		hours: null
	};

	constructor(props: LMI.IInspectionItemTypeEditorProps) {
		super(props);

		this.state = {
			item: this.getFormattedItem(props),
			isNew: !props.item,
			hasMatch: null
		};
	}

	getFormattedItem(props: LMI.IInspectionItemTypeEditorProps): LMI.InspectionItemTypeOption {
		const item = props.item ? { ...props.item } : props.startingValue ? { ...this.newItem, ...{ name: props.startingValue } } : this.newItem;
		const needsZeroes = val => (val !== null && val % 1 === 0 ? true : false);
		if (needsZeroes(item.parts)) item.parts = item.parts.toFixed(2);
		if (needsZeroes(item.labor)) item.labor = item.labor.toFixed(2);
		return item;
	}

	updateUnique(field: InputOnChangeData) {
		const match = this.props.inspection_item_options.options.find(i => {
			const checkField = i[field.name];
			if (typeof checkField === "string" && field.value) return checkField.toLowerCase() === field.value.toLowerCase();
		});
		const item = this.state.item;
		item[field.name] = field.value;
		this.setState({
			hasMatch: match && match.is_active ? { match, key: field.name } : null,
			item
		});
	}

	updateItem(field: InputOnChangeData) {
		const item = { ...this.state.item };
		item[field.name] = field.value;
		this.setState({ item });
	}

	isValid() {
		return !this.state.hasMatch && this.state.item && this.state.item.name;
	}

	render() {
		const { item, isNew, hasMatch } = this.state;
		const { name, op_code, labor, parts, hours } = item;
		const { onSave, onUpdate } = this.props;

		const currencyMask = createNumberMask({
			prefix: "$",
			suffix: "",
			includeThousandsSeparator: true,
			thousandsSeparatorSymbol: ",",
			allowDecimal: true,
			decimalSymbol: ".",
			decimalLimit: 2,
			integerLimit: 7,
			allowNegative: false,
			allowLeadingZeroes: false
		});

		const partsValue = zeroPlus(parts);
		const laborValue = zeroPlus(labor);
		const hoursValue = zeroPlus(hours);

		return (
			<div id="InspectionItemTypeEditor">
				<Form>
					<Form.Group widths="equal">
						<Form.Field>
							<label>OP Code</label>
							<Input name="op_code" value={op_code ? op_code : ""} autoComplete="off" onChange={(evt, field) => this.updateUnique(field)} />
							{hasMatch && hasMatch.key === "op_code" ? <span className="unique-err">An item with this OP Code already exists</span> : ""}
						</Form.Field>
						<Form.Field>
							<label className="req-field-label">Name</label>
							<Input name="name" value={name ? name : ""} autoComplete="off" onChange={(evt, field) => this.updateUnique(field)} />
							{hasMatch && hasMatch.key === "name" ? <span className="unique-err">An item with this name already exists</span> : ""}
						</Form.Field>
					</Form.Group>
					<Form.Group widths="equal">
						<Form.Field>
							<label>Parts</label>
							<MaskedInput
								mask={currencyMask}
								name="parts"
								placeholder=""
								value={partsValue !== null ? partsValue : ""}
								onChange={e => {
									const value = e && targetHasValue(e.target) ? e.target.value.replace(/\$|,/g, "") : null;
									this.updateItem({
										name: "parts",
										value
									});
								}}
							/>
						</Form.Field>
						<Form.Field>
							<label>Labor</label>
							<MaskedInput
								mask={currencyMask}
								name="labor"
								placeholder=""
								value={laborValue !== null ? laborValue : ""}
								onChange={e => {
									const value = e && targetHasValue(e.target) ? e.target.value.replace(/\$|,/g, "") : null;
									this.updateItem({
										name: "labor",
										value
									});
								}}
							/>
						</Form.Field>
						<Form.Field>
							<label>Hours</label>
							<Input
								name="hours"
								value={hoursValue !== null ? hoursValue : ""}
								onChange={(e, data) => {
									const hourVal = e && targetHasValue(e.target) ? e.target.value : null;
									this.updateItem({
										name: "hours",
										value: hourVal
									});
								}}
							/>
						</Form.Field>
					</Form.Group>
					<Divider hidden />
					<div style={{ height: "37px" }}>
						<Button
							floated="right"
							color="green"
							disabled={!this.isValid()}
							content="Save Item"
							onClick={e => {
								e.preventDefault();
								if (isNew) onSave(item);
								else if (onUpdate) onUpdate(item);
							}}
						/>
					</div>
				</Form>
			</div>
		);
	}
}

export default InspectionItemTypeForm;
