import track from "react-tracking";
import { gqlQueries } from "gql-imports";
import { AnalyticsEventType, StoreType } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Dropdown, Input, Loader } from "semantic-ui-react";
import { changeSelectedEmployee, changeStore, changeUserType } from "api/redux/actions";
import { stringIsNumber } from "../../utils/functions";
import { Session } from "client/utils/session";

import "./StoreSelector.css";

const StoreSelectorItem = props => (
	<Dropdown.Item
		onClick={(evt, data) => {
			const stores = Session.get("stores");
			const store = stores.find(elm => elm.store_id.toString() === data.value.toString());
			props.onLogin({
				orgId: props.org,
				storeName: data.children,
				storeId: data.value,
				countryCode: store.country_code,
				storeType: store.store_type_id,
				permissions: store.permissions
			});
			const employee = props.employees && props.employees.find(e => e.store.id === data.value);
			props.changeSelectedEmployee(employee ? employee.id : null);
		}}
		value={props.value}>
		{props.name}
	</Dropdown.Item>
);

@track({ event_type: AnalyticsEventType.NAVIGATION, event_subtype: "store-selector" }, { dispatchOnMount: true })
class StoreSelectorMenu extends React.Component<LMI.ISSMProps, undefined> {
	filter(results: any[], filter: string) {
		return results.filter(r => r.store.name.toLowerCase().includes(filter.toLowerCase()));
	}

	render() {
		const Alphebetical = (a, b) => {
			const ta = a.store.name.toUpperCase();
			const tb = b.store.name.toUpperCase();
			return ta < tb ? -1 : ta > tb ? 1 : 0;
		};

		const stores = this.filter(this.props.user.employees ? [].concat(this.props.user.employees) : [], this.props.filterOn);
		const organizations = Object.keys(StoreType)
			.filter(stringIsNumber)
			.map(storetype => {
				return {
					name: StoreType[storetype].replace(/([A-Z])/g, " $1").trim(),
					stores: stores.filter(store => store.store.store_type_id === parseFloat(storetype)).sort(Alphebetical)
				};
			});

		return (
			<Dropdown.Menu scrolling>
				{organizations
					.filter(o => o.stores.length > 0)
					.map((org, index) => {
						return (
							<div key={index}>
								<Dropdown.Header icon={org.name === StoreType[StoreType.Dealership] ? "building outline" : "truck"} content={`${org.name}s`} />
								{org.stores.map((store, jndex) => {
									return (
										<StoreSelectorItem
											key={jndex}
											onLogin={this.props.onLogin}
											name={store.store.name}
											value={store.store.id}
											org={store.store.organization_id}
											employees={this.props.employees}
											changeSelectedEmployee={this.props.changeSelectedEmployee}
											stores={org.stores}
										/>
									);
								})}
								{index < organizations.length ? <Dropdown.Divider /> : ""}
							</div>
						);
					})}
			</Dropdown.Menu>
		);
	}
}

@track({ event_type: AnalyticsEventType.NAVIGATION, event_subtype: "store-selector" }, { dispatchOnMount: false })
class StoreSelectorView extends React.Component<LMI.ISSProps, LMI.ISSState> {
	constructor(props) {
		super(props);

		this.state = {
			user: {},
			filter: "",
			loggingIn: false,
			selecting: false
		};

		// look for click outside the store menu to keep selector closed
		document.addEventListener("click", (e: any) => {
			// only do something if the menu is open
			if (this.state.selecting) {
				const storemenu = document.getElementById("StoreMenu");
				const inside = storemenu.contains(e.target);
				// console.log(`The user clicked ${inside ? "inside" : "outside"} the menu. ${!inside ? "Close the menu." : "Watching for close."}`);
				if (!inside) this.setState({ selecting: false });
			}
		});
	}

	allowSpacesInSearch(e) {
		if (e.keyCode === 32) {
			const value = e.target.value;
			const index = e.target.selectionStart;
			e.target.value = `${value.slice(0, index)} ${value.slice(index)}`;
			e.target.selectionStart = index + 1;
			e.target.selectionEnd = index + 1;
			e.stopPropagation();
		}
	}

	render() {
		const props = this.props;
		if (props.hasErrors) console.log("StoreSelector Data errors:", props.error);
		if (props.loading) return <Loader size="mini" inline inverted active className="inline-loader" />;
		const hasMultiple = props.user && props.user.employees && props.user.employees.length > 1;

		return (
			<span>
				{(props.userType === StoreType.Dealership && hasMultiple) || (!props.superUser && hasMultiple) ? (
					<Dropdown
						id="StoreMenu"
						floating
						labeled
						button
						closeOnBlur
						text={this.props.storeName}
						open={this.state.selecting}
						icon="location arrow"
						className="icon store-selector"
						onOpen={() => {
							this.setState({ selecting: true });
							setTimeout(() => document.getElementById("StoreSearch").focus(), 250);
						}}
						onChange={(e: any) => this.setState({ filter: e.target.value })}>
						<Dropdown.Menu>
							<Input
								id="StoreSearch"
								icon="search"
								iconPosition="left"
								className="search"
								autoComplete="off"
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
								}}
								onKeyUp={e => this.allowSpacesInSearch(e)}
							/>
							<StoreSelectorMenu
								changeSelectedEmployee={empId => this.setState({ selecting: false }, () => props.changeSelectedEmployee(empId))}
								employees={props.employees}
								onLogin={props.onLogin}
								user={this.state.user || {}}
								filterOn={this.state.filter}
							/>
						</Dropdown.Menu>
					</Dropdown>
				) : (
					<span />
				)}
			</span>
		);
	}

	UNSAFE_componentWillMount() {
		if (this.props.user) {
			this.updateData();
		}
	}

	componentDidUpdate(prevProps: any, prevState: any) {
		if (prevProps.user !== this.props.user) this.updateData();
		if (prevProps.storeId !== this.props.storeId) {
			// check if user was selecting from store list
			let redirect = prevState.selecting;
			// check if the store type changed
			if (redirect && prevProps.user && this.props.user) {
				const prevStoreType = prevProps.user.employees.find(em => em.store.id === prevProps.storeId);
				const newStoreType = this.props.user.employees.find(em => em.store.id === this.props.storeId);
				redirect = prevStoreType.store.store_type_id !== newStoreType.store.store_type_id;
			}
			// otherwise stay on current screen (needed for deep linking)
			this.props.onStoreChange(redirect);
		}
	}

	updateData() {
		this.props.tracking.trackEvent({
			event_type: AnalyticsEventType.SUBNAV,
			event_subtype: `store-selector.selected`,
			data: { store_id: this.props.storeId, store_name: this.props.storeName }
		});

		this.setState({ user: this.props.user });
		// If we have a store selected from the last visit use that one if we can.
		let found = false;
		const stores = Session.get("stores");
		const isSuperUser = localStorage.getItem("isu") === "false";

		const urlParams = new URLSearchParams(window.location.search);
		const storeParam = urlParams.get("store");
		const preselected = storeParam ? storeParam : localStorage.getItem("selectedStoreId");

		if (preselected !== undefined) {
			this.props.user.employees.forEach(dealership => {
				if (!found) {
					const dealerId = dealership.store.id;
					if (dealerId && preselected && dealerId.toString() === preselected.toString()) {
						found = true;
						const store = stores.find(elm => elm.store_id.toString() === dealerId.toString());
						this.props.onLogin({
							storeName: dealership.store.name,
							orgId: dealership.store.organization_id,
							storeId: dealership.store.id,
							countryCode: store.country_code,
							storeType: store && store.store_type_id,
							storeCode: store && store.store_code,
							permissions: store && store.permissions
						});
					}
				}
			});
		}
		// Otherwise just load the first one in the list.
		if (!found) {
			const dealership = this.props.user.employees[0];
			if (isSuperUser && dealership && dealership.store) {
				const store = stores.find(elm => elm.store_id.toString() === dealership.store.id.toString());
				this.props.onLogin({
					storeName: dealership.store.name,
					orgId: dealership.store.organization_id,
					storeId: dealership.store.id,
					storeCode: store.store_code,
					storeType: store && store.store_type_id,
					permissions: store && store.permissions,
					countryCode: store.country_code
				});
			} else {
				if (localStorage.getItem("isu") === "false") {
					console.log("User has no stores assigned to their account!!");
				}
			}
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeName: state.app.admin.storeName,
		storeId: state.app.admin.storeId,
		employeeId: state.app.admin.employeeId,
		countryCode: state.app.admin.countryCode
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		onLogin: (data: any) => {
			if (data.orgId) localStorage.setItem("selectedOrgId", data.orgId);
			localStorage.setItem("selectedStoreCode", data.storeCode);
			localStorage.setItem("selectedStoreId", data.storeId);
			localStorage.setItem("selectedStoreCountryCode", data.countryCode);
			dispatch(changeStore(data));
			localStorage.setItem("selectedStoreType", data.storeType);
			dispatch(changeUserType(data.storeType));
		},
		changeSelectedEmployee: employeeId => {
			dispatch(changeSelectedEmployee(employeeId));
		}
	};
};

export const StoreSelector = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.list, {
		options: (props: any) => {
			return {
				variables: {
					userId: props.userId,
					email: props.email
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, user, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				user,
				refetch
			};
		}
	})
)(StoreSelectorView) as React.ComponentType<LMI.ISSProps>;

export default StoreSelector;
