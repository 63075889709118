import { graphql } from "@apollo/react-hoc";
import { DateRangePickerComponent, PresetDirective, PresetsDirective, RangeEventArgs } from "@syncfusion/ej2-react-calendars";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import { gqlQueries } from "gql-imports";
import RenderDataGrid, { DataGridProps, GridColumnHeaders, GridToolOptions } from "client/components/DataGrid/DataGrid";
// node_modules

import { filterDataSet, RelativeDatePipe } from "client/utils/functions";
import { flowRight as compose } from "lodash";
import moment from "moment";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Grid, Icon, Loader, Progress } from "semantic-ui-react";
import { MapsDialog } from "../../../../../../components/MapDialog";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

import "./CompleteTabPanel.css";

@track(
	props => {
		return {
			event_type: AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}completed`
		};
	},
	{ dispatchOnMount: true }
)
export class CompleteTabPanelView extends React.Component<LMI.ISaniTabProps, LMI.ISaniTabState> {
	grid: GridComponent;
	filterIsSet: boolean = false;
	searchKey: string = "";
	groupSettings: LMI.IGridGroupSettings = {
		columns: ["vin"],
		captionTemplate: args => <div title={args.items[0].year_make_model}>{this.groupCaption(args)}</div>,
		showDropArea: false,
		allowGroupReordering: false
	};
	public today = moment();
	public yesterday = moment()
		.subtract(1, "days")
		.startOf("day");
	public oneWeek = moment(new Date())
		.subtract(7, "days")
		.startOf("day")
		.toDate();
	public oneMonth = moment(new Date())
		.subtract(1, "month")
		.startOf("day")
		.toDate();
	public threeMonths = moment(new Date())
		.subtract(3, "months")
		.startOf("day")
		.toDate();
	public sixMonths = moment(new Date())
		.subtract(6, "months")
		.startOf("day")
		.toDate();
	public oneYear = moment(new Date())
		.subtract(1, "year")
		.startOf("day")
		.toDate();

	constructor(props: LMI.ISaniTabProps) {
		super(props);

		this.state = {
			data: null,
			search_is_set: false,
			searchData: [],
			show_lot_location: false,
			detail: null,
			startDate: this.oneWeek,
			endDate: this.today.toDate()
		};
	}

	groupCaption = args => {
		const done = args.items[0].percent_complete >= 100;
		const vin = args.items[0].vin.substr(-8);
		const stock = args.items[0].stock_number;
		return (
			<Grid className="group">
				<Grid.Row>
					<Grid.Column width={1}>
						{args.items[0].location.latitude ? (
							<span>
								<Icon
									id="location"
									style={{ opacity: "1!important", fontSize: "20px" }}
									name="map marker alternate"
									link
									onClick={() => this.setState({ detail: args.items[0], show_lot_location: true })}
								/>
							</span>
						) : (
							<span></span>
						)}
					</Grid.Column>
					<Grid.Column width={2}>
						<img className="photo" src={args.items[0].photo} />
					</Grid.Column>
					<Grid.Column className="steps" width={4}>
						{<Progress className="progress-bar" percent={Math.floor(args.items[0].percent_complete)} progress indicating={!done} color={"green"} />}
						{done && <i className="icon checkmark green" />}
					</Grid.Column>
					<Grid.Column className="ymm" width={7}>
						<b>{args.items[0].year_make_model}</b> - <b>VIN: </b>
						{vin} | <b>STK: </b>
						{stock} | <b>Completed: </b>
						{args.items[0].completed}
					</Grid.Column>{" "}
					<Grid.Column width={2}></Grid.Column>
				</Grid.Row>
			</Grid>
		);
	};

	sortComparer = (reference: string, comparer: string) => {
		// This disables sort on grouping column, please keep it here.
		return -1;
	};

	static formatGridData = requests => {
		return requests
			.sort((a, b) => {
				if (+a.completed_at > +b.completed_at) return -1;
				if (+a.completed_at < +b.completed_at) return 1;
			})
			.map(request => {
				const { inventory_item, sanitation_request_items, completed_at, created_at, created_by_user, completed_by_emp, lead_name, reference_number } = request;
				const details = sanitation_request_items.sort((a, b) => {
					return a.sort_order === b.sort_order ? 0 : +(a.sort_order > b.sort_order) || -1;
				});
				const percent_complete = (details.filter(d => !!d.completed_by_emp).length / details.length) * 100;
				const steps = details.length;
				return {
					year_make_model: inventory_item.year_make_model,
					vin: inventory_item.vin,
					stock_number: inventory_item.stock_number,
					photo: `${inventory_item.primary_photo_full_url}?q=30&w=65`,
					location: { latitude: inventory_item.latitude, longitude: inventory_item.longitude },
					completed: completed_at ? RelativeDatePipe(+completed_at, false, true) : null,
					completed_by: completed_by_emp ? completed_by_emp.user.full_name : null,
					created_at: created_at ? RelativeDatePipe(+created_at, false, true) : null,
					created_by: created_by_user.full_name,
					percent_complete,
					steps,
					details,
					lead_name,
					reference_number
				};
			});
	};

	createGridColumns(data) {
		const defaultProps = {
			textAlign: "left",
			allowFiltering: true,
			allowSorting: false
		};

		const headerData: any = [
			{
				...defaultProps,
				id: "year_make_model",
				headerText: "Year Make Model",
				textAlign: "left",
				allowSorting: false
			},
			{
				...defaultProps,
				id: "vin",
				headerText: "VIN",
				textAlign: "left",
				sortComparer: this.sortComparer,
				allowSorting: false
			},
			{
				...defaultProps,
				id: "stock_number",
				headerText: "Stock #",
				textAlign: "left",
				sortComparer: this.sortComparer,
				allowSorting: false
			},
			{
				...defaultProps,
				id: "created_by",
				headerText: "Created",
				textAlign: "left",
				allowSorting: true,
				cellAttrs: {
					template: props => (
						<span>
							by {props.created_by} {props.created_at}
						</span>
					)
				}
			},
			{
				...defaultProps,
				id: "completed_by",
				headerText: "Completed",
				textAlign: "left",
				allowSorting: true,
				cellAttrs: {
					template: props => {
						if (props.completed_by) {
							return (
								<span>
									by {props.completed_by} {props.completed}
								</span>
							);
						} else if (props.completed) {
							return <span>{props.completed}</span>;
						} else return <span></span>;
					}
				}
			},
			{
				...defaultProps,
				id: "created_at",
				headerText: "Created",
				textAlign: "left",
				allowSorting: false
			},
			{
				...defaultProps,
				id: "photo",
				headerText: "Photo",
				textAlign: "left",
				width: "45",
				allowSorting: false,
				cellAttrs: {
					template: props => <img className="photo" src={props.photo} />
				}
			},
			{
				...defaultProps,
				id: "completed",
				headerText: "Completed",
				textAlign: "left",
				allowSorting: true,
				cellAttrs: {
					template: props => {
						return props.completed ? (
							<span>
								<i className="icon checkmark green" /> {props.completed}
							</span>
						) : (
							<span></span>
						);
					}
				}
			},
			{
				...defaultProps,
				id: "location",
				headerText: "Location",
				textAlign: "center",
				width: "20",
				allowSorting: false,
				cellAttrs: {
					attributes: { class: "location" },
					template: props =>
						props && props.location.latitude ? (
							<Icon
								id="location"
								style={{ opacity: "1!important", fontSize: "20px" }}
								name="map marker alternate"
								link
								onClick={() => this.setState({ detail: props, show_lot_location: true })}
							/>
						) : (
							<></>
						)
				}
			},
			{
				...defaultProps,
				id: "lead_name",
				headerText: "Lead",
				textAlign: "center",
				allowSorting: true,
				cellAttrs: {
					attributes: { class: "leadname" },
					template: props => {
						return <span>{props.lead_name}</span>;
					}
				}
			},
			{
				...defaultProps,
				id: "reference_number",
				headerText: "Ref#",
				width: "35",
				textAlign: "center"
			}
		];

		const headerList = ["year_make_model", "vin", "stock_number", "reference_number", "created_by", "completed_by", "lead_name"];

		return GridColumnHeaders(data, headerData, headerList);
	}

	getDetailTemplate = props => (
		<span className="detail">
			{props.details.map(detail => {
				return (
					<div>
						{detail.completed_by_emp ? <i className="icon checkmark green" /> : <i className="icon clock outline" />}
						{detail.name}
						{
							<span className="grey">
								{" "}
								(completed by {detail.completed_by_emp.user.full_name} on {moment(+detail.completed_at).format("llll")})
							</span>
						}
					</div>
				);
			})}
		</span>
	);

	filterDataSet = searchString => {
		// Set the filter set to all records for local filter
		const searchData = this.state.data
			.filter(i => filterDataSet({ searchString, item: i }))
			.map(item => {
				return item;
			});

		this.setState({ searchData, search_is_set: true }, () => {
			this.grid.searchSettings.key = searchString;
		});

		this.filterIsSet = true;
	};

	resetFilterRanges = () => {
		this.setState({ searchData: [], search_is_set: false, startDate: this.oneWeek, endDate: this.today.toDate() }, async () => {
			await this.props.refetchRequests();
			this.filterIsSet = false;
			this.searchKey = "";
			this.grid.getContent().children[0].scrollTop = 0;
		});
	};

	setGrid(grid) {
		this.grid = grid;
	}

	handleRangeChange = (range: RangeEventArgs): void => {
		if (range.startDate && range.endDate) {
			const startDate = range.startDate;
			const endDate = range.endDate;

			this.setState(
				{
					startDate,
					endDate
				},
				() => {
					this.props.refetchRequests({ completedAtStartDate: startDate, completedAtEndDate: endDate });
				}
			);
		} else this.props.refetchRequests({ completedAtStartDate: null, completedAtEndDate: null }); // Refetch w/o dates
	};

	render() {
		const { data, startDate, endDate } = this.state;
		const { loading, onNoResults } = this.props;

		if (loading) return <Loader active />;

		return (
			<div id="sani-complete-list" className="grid-container">
				<div className="date-range-field">
					<DateRangePickerComponent
						placeholder="Select a range"
						max={this.today.toDate()}
						startDate={startDate}
						endDate={endDate}
						allowEdit={false}
						strictMode={true}
						showClearButton={false}
						change={this.handleRangeChange}
					>
						<PresetsDirective>
							<PresetDirective label="Today" start={this.today.startOf("day").toDate()} end={this.today.toDate()}></PresetDirective>
							<PresetDirective label="Yesterday" start={this.yesterday.toDate()} end={this.yesterday.toDate()}></PresetDirective>
							<PresetDirective label="One Week" start={this.oneWeek} end={this.today.toDate()}></PresetDirective>
							<PresetDirective label="One Month" start={this.oneMonth} end={this.today.toDate()}></PresetDirective>
							<PresetDirective label="Three Months" start={this.threeMonths} end={this.today.toDate()}></PresetDirective>
							<PresetDirective label="One Year" start={this.oneYear} end={this.today.toDate()}></PresetDirective>
						</PresetsDirective>
					</DateRangePickerComponent>
				</div>
				<MapsDialog
					{...{
						open: this.state.show_lot_location,
						title: this.state.detail && `Lot Location - ${this.state.detail.year_make_model}`,
						location:
							this.state.detail && this.state.detail.location.latitude && this.state.detail.location.longitude
								? {
										latitude: this.state.detail.location.latitude,
										longitude: this.state.detail.location.longitude
								  }
								: null,
						actions: [],
						onClose: () => this.setState({ show_lot_location: false })
					}}
				/>
				<RenderDataGrid
					{...({
						source_data: data,
						data: this.state.search_is_set ? this.state.searchData : data,
						cancelSelectionColumns: true,
						columns: this.createGridColumns(data),
						setGrid: grid => this.setGrid(grid),
						filterDataSet: str => this.filterDataSet(str),
						onReset: () => this.resetFilterRanges(),
						tools: [GridToolOptions.grouping, GridToolOptions.sorting, GridToolOptions.filter, GridToolOptions.search, GridToolOptions.detailRow],
						groupSettings: this.groupSettings,
						detailTemplate: this.getDetailTemplate,
						collapseGroupOnLoad: true,
						textwrap: true,
						noresults: onNoResults({ message: "There are no Completed Sanitization Requests for the selected timeframe." })
					} as DataGridProps)}
				/>
			</div>
		);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.sanitation_requests) {
			const formattedData = CompleteTabPanelView.formatGridData(nextProps.sanitation_requests.requests);
			if (formattedData !== prevState.data) {
				return { data: formattedData };
			}
		}
		return null;
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const CompleteTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.sanitationRequests, {
		options: (props: any) => {
			const oneWeek = moment(new Date())
				.subtract(7, "days")
				.startOf("day")
				.toDate();
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					openOnly: false,
					closedOnly: true,
					completedAtStartDate: oneWeek,
					completedAtEndDate: moment()
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, sanitation_requests, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				sanitation_requests,
				refetchRequests: refetch
			};
		}
	})
)(CompleteTabPanelView) as React.ComponentType<any>;

export default CompleteTabPanel;
