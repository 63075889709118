import { getMenuData } from "../Routes/utils";

export const buildTabTarget = (from, to) => from.replace(/\:id\b/g, to);

const setPath = (route, isSub) => {
	let path = route.path;
	// write the parent path by checking for a default sub - otherwise it uses the first subTabs path
	const defaultSub = route.dynamicURL ? (route.defaultSub ? route.defaultSub : route.subTabs[0]) : null;
	if (route.dynamicURL && !isSub) path = buildTabTarget(route.path, defaultSub.toLowerCase());
	else if (isSub && isSub.parentHasTabs) path = buildTabTarget(isSub.parent, route.id);
	return path;
};

const FormatMenuItem = (route: LMI.IAppRouteData, isSub: any = false) => {
	if (route) {
		const { id, name, subTabs, subTabContainer, showNewBadge, menuActions } = route;
		const isNew = showNewBadge ? true : false;
		const path = setPath(route, isSub);
		return { key: id, label: name, path, subTabs, subTabContainer, isNew, menuActions };
	}
};

export const FormatMenuData = routes => {
	return getMenuData()
		.map(routeId => {
			const route = routes.find(r => r.id === routeId);
			if (route) {
				const routeData = FormatMenuItem(route);
				const items =
					routeData && routeData.subTabs
						? routeData.subTabs
								.map(id => {
									const subRoute = FormatMenuItem(
										routes.find(sr => sr.id === id),
										{ parent: route.path, parentHasTabs: route.subTabContainer ? true : false }
									);
									if (subRoute) return subRoute;
								})
								.filter(i => i)
						: [];
				if (route.defaultSub) items.unshift({ ...routeData, ...{ label: route.defaultSub, isNew: false } });
				return {
					...route,
					...routeData,
					...{ items }
				};
			}
		})
		.filter(i => i);
};
