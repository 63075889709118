// node_modules
import * as React from "react";
import track from "react-tracking";
import { useQuery } from "@apollo/client";
import { useGlobals } from "api/redux/hooks";
import { gqlQueries } from "gql-imports";
import { buildTabTarget } from "client/components/Menu/utils";
import { AnalyticsEventType } from "loopmein-shared";

// components
import { UniversalActionModalComponent } from "../../UniversalActionModalComponent";
import { WithUAB } from "../../../../WithUAB";
import { Loader } from "semantic-ui-react";
import AddSaniForm from "./components/AddSaniForm";
import SubTabContainers from "client/components/Routes/SubTabContainers";
import {
  TabsContainer,
  TCProps,
} from "client/pages/admin/components/ReactTabsContainer";

import "./SaniTabs.css";
import "../ReportsTabPanel/ReportsCommon.css";

@track(
  { event_type: AnalyticsEventType.NAVIGATION, event_subtype: "sanitization" },
  { dispatchOnMount: true }
)
export class SaniTabsView extends React.Component<
  LMI.ISaniTabCountProps,
  LMI.ISaniTabCountState
> {
  constructor(props) {
    super(props);

    this.state = {
      hasAddSaniResult: false,
      subRouteId: "sanitization-active",
    };
  }

  getNoResult = ({ message, moveLeft = false }) => {
    const noresults = {
      icon: (
        <img
          className={moveLeft ? "move-left" : ""}
          src="/images/spray-bottle-black.png"
        />
      ),
      text: message,
      action: null,
    };

    return (
      <div className="noresults">
        {this.getUABModal()}
        <div className="icon">{noresults.icon}</div>
        <div className="message">
          {noresults && noresults.text
            ? noresults.text
            : "Sorry, no results were loaded"}
        </div>
      </div>
    );
  };

  getUABModal = () => {
    return (
      <div style={{ textAlign: "right" }}>
        <UniversalActionModalComponent
          headerText="Request Vehicle Sanitization"
          size={this.state.hasAddSaniResult ? "small" : "tiny"}
          contentComponent={() => {
            return (
              <AddSaniForm
                {...{
                  ...this.props,
                  setHasResults: (has) =>
                    this.setState({ hasAddSaniResult: has }),
                }}
              />
            );
          }}
          toggleUABCallback={this.props.toggleUABCallback}
          universalActionCallback={() => {
            this.props.triggerUABCallback(false);
            this.setState({ hasAddSaniResult: false }, () => {
              this.props.refetchRequests();
            });
          }}
        />
      </div>
    );
  };

  render() {
    const { loading, sanitation_request_count } = this.props;

    if (loading) return <Loader active />;

    if (!sanitation_request_count || sanitation_request_count <= 0) {
      return this.getNoResult({
        message: "There are no Sanitization Requests at this time.",
        moveLeft: true,
      });
    }

    const tabProps = { ...this.getTabProps() };

    return (
      <div className="sani-tab-panel panel-content">
        {this.getUABModal()}
        <TabsContainer {...tabProps} />
      </div>
    );
  }

  getTabProps(): TCProps {
    const { subRouteId } = this.state;
    const { storeId, permissions, viewType, refetchRequests } = this.props;
    const tabProps: TCProps = {
      subRouteId,
      subTabContainer: SubTabContainers.sanitization,
      permissions: permissions,
      classes: "sani-tab-panel-tabs",
      viewType: `${viewType} sub-component`,
      dataPassThru: {
        storeId: storeId,
        orgId: parseInt(localStorage.getItem("selectedOrgId"), 10),
        refetchAll: refetchRequests,
        onNoResults: (args) => this.getNoResult(args),
        tracking_path: this.props.tracking.getTrackingData().event_subtype,
      },
      onChange: (tabSelected: string) => this.selectedTab(tabSelected),
    };
    return tabProps;
  }

  selectedTab(tab) {
    this.props.changeSubtab(tab);
    this.props.history.push(buildTabTarget(this.props.match.path, tab));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      subRouteId: nextProps.match
        ? nextProps.match.params.id
        : prevState.subRouteId,
    };
  }

  componentWillUnmount() {
    this.props.changeSubtab(null);
  }
}

const useGQL = (props) => {
  const gql = useQuery(gqlQueries.dealership.sanitationRequestCount, {
    variables: {
      storeId: parseInt(props.storeId, 10),
      openOnly: false,
    },
    fetchPolicy: "no-cache",
  });
  return gql;
};

export const SaniTabsPanel = (props) => {
  const globals = useGlobals(props) as LMI.ISaniTabCountProps;
  const { loading, error, data, refetch } = useGQL(props);
  const showUABOn = [{ viewType: "dealerships" }];
  return (
    <WithUAB showUABOn={showUABOn}>
      <SaniTabsView
        {...{
          ...globals,
          loading,
          hasErrors: !!error,
          message: error,
          sanitation_request_count:
            data?.sanitation_request_count?.request_count,
          refetchRequests: refetch,
        }}
      />
    </WithUAB>
  );
};

export default SaniTabsPanel;
