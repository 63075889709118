import track from "react-tracking";
import * as React from "react";
import { withRouter } from "react-router";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { addAlert } from "api/redux/actions";
import { AnalyticsEventType } from "loopmein-shared";
import { restAPI } from "client/utils/rest";

import { Loading } from "client/components/Loading";
import { Button, Dropdown, Grid, Message, Container, Divider, Checkbox, Header, Icon, TextArea, Input } from "semantic-ui-react";

import "./AddTaskForm.css";

@track({ event_type: AnalyticsEventType.NAVIGATION, event_subtype: "add-task-dialog" }, { dispatchOnMount: true })
export class AddTaskFormView extends React.Component<LMI.AddTaskFormProps, LMI.AddTaskFormState> {
	constructor(props: LMI.AddTaskFormProps) {
		super(props);
		this.state = {
			tasks: [],
			filterSelected: false,
			openCommentEditors: [],
			serviceFilter: null
		};
	}

	getServices(): { services: LMI.AddTaskServiceProps[]; hasSelected: boolean } {
		const { services, open_tasks } = this.props;
		const { tasks, filterSelected, serviceFilter } = this.state;
		const exists: number[] = open_tasks.map(({ service }) => service.id);
		const added: number[] = tasks.map(t => t.service_id);
		const hasSelected: boolean = added && added.length > 0;
		let sservices: LMI.AddTaskServiceProps[] = services.filter(s => !exists.includes(s.id));
		if (serviceFilter) sservices = sservices.filter(s => s.name.toLowerCase().includes(serviceFilter));
		return { services: filterSelected && hasSelected ? sservices.filter(s => added.includes(s.id)) : sservices, hasSelected };
	}

	getService({ id, vendors }: LMI.AddTaskServiceProps): { options: LMI.ISelectOption[]; selected: LMI.AddTaskObject; addingComment: boolean } {
		const { tasks, openCommentEditors } = this.state;
		const options: LMI.ISelectOption[] = vendors.map(({ id, name }) => ({ key: id, text: name, value: id }));
		const selectedTask: LMI.AddTaskObject = tasks.find(task => task.service_id === id);
		const selected: LMI.AddTaskObject = selectedTask ? selectedTask : null;
		const addingComment: boolean = openCommentEditors.includes(id);
		return { options, selected, addingComment };
	}

	getPrimaryVendor(service_id: number): any {
		const service = this.props.services.find(service => service.id == service_id);
		const vendors = service && service.vendors && service.vendors.length;
		if (!service || !vendors) return null;
		const primary = service && service.vendors.find(v => v.is_primary);
		return primary ? primary.id : service.vendors[0].id;
	}

	toggleTaskService({ id, selected }) {
		let tasks = this.state.tasks;
		if (selected) tasks = [...tasks.filter(i => i.service_id !== id), ...[{ service_id: id, assigned_vendor_id: this.getPrimaryVendor(id) }]];
		else tasks = tasks.filter(i => i.service_id !== id);
		const overrides = tasks.length === 0 ? { tasks, filterSelected: false } : { tasks };
		this.setState({ ...this.state, ...overrides });
	}

	toggleTaskVendor({ id, selected, selectedVendor }) {
		const { tasks } = this.state;
		const selector: LMI.AddTaskObject[] = !selected ? [{ service_id: id, assigned_vendor_id: selectedVendor }] : tasks.filter(t => t.service_id === id);
		if (selected && selector) selector[0].assigned_vendor_id = selectedVendor;
		this.setState({ tasks: [...tasks.filter(i => i.service_id !== id), ...selector] });
	}

	toggleCommentEditor({ id, selected }) {
		const { openCommentEditors } = this.state;
		const isSelected = openCommentEditors.includes(id);
		if (selected && !isSelected) this.setState({ openCommentEditors: [...openCommentEditors, ...[id]] });
		else this.setState({ openCommentEditors: openCommentEditors.filter(i => i !== id) });
	}

	setTaskComment({ id, comment }) {
		const tasks = [...this.state.tasks];
		const task = tasks.find(t => t.service_id === id);
		if (task) task.comment = comment;
		this.setState({ tasks });
	}

	saveTasks() {
		const { storeId, inventory_item_id, onSave } = this.props;
		const { tasks } = this.state;
		restAPI({
			endpointName: "create_inventory_item_task",
			urlArgs: [storeId],
			data: { inventory_item_id, tasks },
			callback: (error, result) => {
				if (onSave) onSave();
				this.props.addAlert({
					type: error ? "danger" : "success",
					message: error ? error.message : result.data.message
				});
			}
		});
	}

	getFilters(showSelectedFilter: boolean) {
		const { filterSelected } = this.state;
		return (
			<div className="add-task-filters">
				<strong>Select Services</strong>{" "}
				{showSelectedFilter && (
					<span className="filter-selected" onClick={() => this.setState({ filterSelected: !filterSelected })}>
						{filterSelected ? "Show All" : "Selected"}
					</span>
				)}
				<Input
					id="ServiceFilter"
					className="service-filter"
					placeholder="Filter Services"
					onChange={(e, data) => this.setState({ serviceFilter: data.value.toLowerCase() })}
				/>
			</div>
		);
	}

	render() {
		const { loading, error } = this.props;
		const { tasks } = this.state;

		if (loading) return <Loading />;
		if (error) return <Message content="There was an error loading the form" />;
		if (!this.props.services || (this.props.services && this.props.services.length == 0))
			return (
				<Message>
					There are currently no approved vendor services configured.{" "}
					<Button content="Approve Vendor Services" onClick={() => this.props.history.push("/admin/dealerships/vendors/services")} />
				</Message>
			);

		const { services, hasSelected } = this.getServices();
		const servicesWVendors = services && services.filter(i => i.vendors && i.vendors.length);
		if (!servicesWVendors || servicesWVendors.length <= 0)
			return (
				<div>
					<h5>You currently have no approved vendor services.</h5>
					<Button content="Add Vendors &amp; Services" onClick={() => this.props.history.push(`vendors/addVendor`)} />
				</div>
			);

		return (
			<div id="AddTasksForm">
				{this.getFilters(hasSelected)}
				<div className="service-list">
					{servicesWVendors.map(service => {
						const { options, selected, addingComment } = this.getService(service);
						const { id, name } = service;
						return (
							<Grid key={id} className={`ui segment service-option${selected ? " selected" : ""}`}>
								<Grid.Column width={10}>
									<Header className="service-label" onClick={() => this.toggleTaskService({ id, selected: !selected })}>
										<Checkbox id={id} checked={selected ? true : false} onClick={() => this.toggleTaskService({ id, selected: !selected })} />
										<span className="service-name">{name}</span>
									</Header>
									{selected && (
										<div className="comment-form">
											<span className="comment-toggle" onClick={() => this.toggleCommentEditor({ id, selected: !addingComment })}>
												<Icon name={addingComment ? "caret down" : "caret right"} /> Add Comment
											</span>
											{addingComment && (
												<TextArea
													placeholder="Add Comment"
													value={selected.comment}
													className="comment-field"
													onChange={(e, data) => this.setTaskComment({ id, comment: data.value })}
												/>
											)}
										</div>
									)}
								</Grid.Column>
								<Grid.Column width={6}>
									{selected && (
										<div className="detail-selectors">
											<label className="attached-label">Assigned Vendor</label>
											{options && options.length ? (
												<Dropdown
													search
													selection
													fluid
													className="selectorsy"
													selectOnBlur={false}
													value={selected ? selected.assigned_vendor_id : this.getPrimaryVendor(id)}
													options={options}
													placeholder="Select"
													onChange={(e, data) => this.toggleTaskVendor({ id, selected, selectedVendor: data.value as number })}
												/>
											) : (
												<span className="readonly">No Approved Vendors</span>
											)}
										</div>
									)}
								</Grid.Column>
							</Grid>
						);
					})}
				</div>
				<Container textAlign="right">
					<Divider hidden />
					<Button size="large" positive disabled={tasks.length == 0} onClick={() => this.saveTasks()}>
						Save Tasks
						{tasks.length > 0 && <span className="task-count">{tasks.length}</span>}
					</Button>
				</Container>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};
const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const AddTaskForm = compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, React.ClassAttributes<any>>(gqlQueries.dealership.dealerServicesApprovedVendors, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, dealer_services_with_approved_vendors, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				services: dealer_services_with_approved_vendors,
				refetch
			};
		}
	})
)(AddTaskFormView) as React.ComponentType<any>;

export default AddTaskForm;
