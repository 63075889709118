import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import {
  Button,
  List,
  Checkbox,
  Grid,
  Icon,
  Segment,
  Popup,
  Label,
} from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { DataFilterComponent } from "client/pages/admin/components/DataFilterComponent";
import { JobTitlesSelectComponent } from "../index";
import { stringToInt } from "client/utils/functions";
import "./RolesSelectComponent.css";

export class CustomPermissionsView extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      changedPerms: props.changes ? props.changes : [],
      searchFilter: null,
    };
  }
  render() {
    const searchFilter = this.state.searchFilter
      ? this.state.searchFilter.toLowerCase()
      : null;
    return (
      <div className="permissions-modal">
        <div className="perm-filter">
          <DataFilterComponent
            label="Filter Permissions"
            leftside={true}
            searchFilter={this.state.searchFilter}
            onChange={(p, { value }) => {
              this.setState({
                searchFilter: value,
              });
            }}
          />
        </div>
        <List divided size="large" className="permissions-list">
          {this.props.selectedRole &&
          this.props.su_all_permissions &&
          !this.props.loading
            ? this.props.su_all_permissions
                .filter((perm) => {
                  return (
                    !searchFilter ||
                    perm.description.toLowerCase().includes(searchFilter)
                  );
                })
                .map((perm, index) => {
                  const checked =
                    this.state.changedPerms &&
                    this.state.changedPerms.find((p) => p.id === perm.id)
                      ? this.state.changedPerms.find((p) => p.id === perm.id)
                          .checked
                      : this.props.selectedRole.permissions.find(
                          (p) => p.id === perm.id
                        )
                      ? true
                      : false;
                  return (
                    <List.Item key={index}>
                      <Checkbox
                        label={perm.description}
                        checked={checked}
                        value={perm.id}
                        onChange={(e, data) => {
                          this.togglePermissions(data);
                        }}
                      />
                    </List.Item>
                  );
                })
            : ""}
        </List>
        <Grid>
          <Grid.Column width={12}>
            {this.state.changedPerms.length ? (
              <div>
                <p>If you save you will save the current changes:</p>
                <Segment>
                  <List>
                    {this.state.changedPerms.map((perm, index) => {
                      return (
                        <List.Item key={index}>
                          <Icon
                            name={perm.checked ? "plus" : "close"}
                            color={perm.checked ? "green" : "red"}
                          />
                          <List.Content>{perm.description}</List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                </Segment>
              </div>
            ) : (
              ""
            )}
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <Button
              positive
              disabled={this.state.changedPerms.length ? false : true}
              content="Done"
              onClick={this.sendChanges.bind(this)}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  togglePermissions(data: any) {
    const change = {
      id: data.value,
      checked: data.checked,
      description: data.label,
    };
    const changed = this.state.changedPerms.findIndex(
      (p) => p.id === change.id
    );
    const override = this.state.changedPerms;
    if (changed !== -1) {
      override[changed] = change;
    }
    this.setState((prevState) => ({
      changedPerms:
        changed !== -1 ? override : [change, ...prevState.changedPerms],
    }));
  }
  sendChanges() {
    const changedPerms =
      this.state.changedPerms &&
      this.state.changedPerms
        .filter((p) => {
          const dr =
            (p.checked &&
              this.props.selectedRole.permissions.findIndex(
                (pe) => pe.id === p.id
              ) !== -1) ||
            (!p.checked &&
              this.props.selectedRole.permissions.findIndex(
                (pe) => pe.id === p.id
              ) === -1)
              ? true
              : false;
          return !dr;
        })
        .map((perm) => perm);
    this.props.savePermissions(changedPerms);
  }
}

export class RolesSelectComponentView extends React.Component<
  LMI.SUAdminNewUserRoleProps,
  any
> {
  constructor(props) {
    super(props);
    this.state = RolesSelectComponentView.RoleSelectDefaultState;
  }

  static RoleSelectDefaultState = {
    customizePerms: false,
    selectedRole: null,
    changedPerms: null,
    changedTitle: null,
    changed: false,
    oldRoles: null,
  };

  static getDerivedStateFromProps(
    nextProps: LMI.SUAdminNewUserRoleProps,
    prevState
  ) {
    if (!nextProps.type && prevState.selectedRole)
      return RolesSelectComponentView.RoleSelectDefaultState;
    return null;
  }

  render() {
    const options = this.props.roles
      ? this.props.roles.map((r) => ({
          text: r.name,
          value: r.id,
          key: "role-" + r.id,
        }))
      : [];
    const permsProps = {
      store_type: this.props.type,
      selectedRole: this.state.selectedRole ? this.prepRoles() : null,
      changes: this.state.changedPerms ? this.state.changedPerms : null,
      savePermissions: (data) => {
        this.setState({
          changedPerms: data,
          customizePerms: false,
        });
      },
    };
    const titleProps = {
      ...this.props,
      store_type: this.props.type,
      selectedRole: this.state.selectedRole ? this.prepRoles() : null,
      changes: this.state.changedPerms ? this.state.changedPerms : null,
      saveTitle: (data) => {
        this.setState({
          changedTitle: data,
        });
      },
    };

    return (
      <span>
        <Form.Group>
          {this.state.customizePerms && (
            <ModalComponent
              headerText="Customize Permissions"
              shouldBeOpen={this.state.customizePerms}
              onClose={(evt, data) => {
                this.setState({ customizePerms: false });
              }}
              className="perms-modal"
              contentComponent={() => <CustomPermissions {...permsProps} />}
            />
          )}
          <Form.Field width={11}>
            <Form.Select
              search
              selection
              multiple
              label="Role"
              name="role"
              options={options}
              value={this.state.selectedRole}
              placeholder="Select a Role"
              disabled={options.length ? false : true}
              onChange={(e, data) => {
                if (data.value) {
                  this.setState({
                    selectedRole: data.value,
                    changed: true,
                  });
                }
              }}
              required
            />
          </Form.Field>
          <Form.Field width={5}>
            <Input
              type="hidden"
              name="changed_permissions"
              value={this.state.changedPerms}
            />
            {this.state.changedPerms ? (
              <Popup
                trigger={
                  <Button
                    fluid
                    as="div"
                    labelPosition="right"
                    type="button"
                    style={{ marginTop: "4px" }}
                  >
                    <Button
                      fluid
                      type="button"
                      onClick={() => this.setState({ customizePerms: true })}
                    >
                      Custom Permissions
                    </Button>
                    <Label as="a" basic pointing="left">
                      {this.state.changedPerms.length}
                    </Label>
                  </Button>
                }
                inverted
              >
                <List>
                  {this.state.changedPerms.map((perm, index) => {
                    return (
                      <List.Item key={index}>
                        <Icon
                          name={perm.checked ? "plus" : "close"}
                          color={perm.checked ? "green" : "red"}
                        />
                        <List.Content>{perm.description}</List.Content>
                      </List.Item>
                    );
                  })}
                </List>
              </Popup>
            ) : (
              <Button
                fluid
                type="button"
                onClick={() => this.setState({ customizePerms: true })}
                style={{ marginTop: "4px" }}
                content="Customize Permissions"
                disabled={
                  options.length && this.state.selectedRole ? false : true
                }
              />
            )}
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <JobTitlesSelectComponent {...titleProps} />
          <Input
            type="hidden"
            name="changed_title"
            value={this.state.changedTitle}
          />
          <Input type="hidden" name="changed_role" value={this.state.changed} />
          <Input type="hidden" name="old_roles" value={this.state.oldRoles} />
        </Form.Field>
      </span>
    );
  }

  prepRoles() {
    if (this.props.roles) {
      // init with employee perms
      const custom = {
        permissions: this.props.employee_user
          ? this.props.employee_user.permissions.map((p: any) => {
              return { id: parseFloat(p.permission_id) };
            })
          : [],
      };
      this.state.selectedRole.forEach((ro) => {
        const role = this.props.roles.find((r) => r.id === ro);
        role.permissions.forEach((p) => {
          // add any roles that aren't in the employee perms for any reason
          const dupe = custom.permissions.find((perm) => perm.id === p.id);
          if (!dupe) custom.permissions.push({ id: p.id });
        });
      });
      return custom;
    } else return null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.employee_user &&
      nextProps.roles &&
      !this.state.selectedRole
    ) {
      const roles = nextProps.employee_user.roles.map((role) => {
        const found = nextProps.roles.find((r) => r.name === role.name);
        return found.id;
      });
      this.setState({
        selectedRole: roles,
        oldRoles: roles,
      });
    }
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const CustomPermissions = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<any, any, any, ClassAttributes<any>>(gqlQueries.super.permissions, {
    options: (props: any) => {
      return {
        variables: {
          store_type_id: stringToInt(props.store_type),
        },
        fetchPolicy: "network-only",
      };
    },
    props: ({ data: { error, loading, su_all_permissions, refetch } }): any => {
      if (loading) {
        return { loading: true };
      }
      if (error) {
        return { hasErrors: true };
      }
      return {
        su_all_permissions,
        refetch,
      };
    },
  })
)(CustomPermissionsView) as React.ComponentType<any>;

export const RolesSelectComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IRolesQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.roles,
    {
      skip: (ownProps: any) => !ownProps.type,
      options: (props: any) => {
        return {
          variables: {
            storeType: stringToInt(props.type),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, roles, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true };
        }
        return {
          roles,
          refetch,
        };
      },
    }
  ),
  graphql<LMI.IEmployeeQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.employee,
    {
      skip: (ownProps: any) => !ownProps.empId,
      options: (props: any) => {
        return {
          variables: {
            store_id: stringToInt(props.storeId),
            id: stringToInt(props.empId),
          },
          fetchPolicy: "network-only",
          notifyOnNetworkStatusChange: true,
        };
      },
      props: ({ data: { error, loading, employee_user, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true };
        }
        return {
          employee_user,
          refetch,
        };
      },
    }
  )
)(RolesSelectComponentView) as React.ComponentType<any>;

export default RolesSelectComponent;
