import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";

import { gqlQueries } from "gql-imports";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import CommentForm from "./AddLeaseReturnComment";
import { UniversalActionButton } from "../../../UniversalActionButton";
import { ModalComponent } from "../../../ModalComponent";
import { addAlert } from "api/redux/actions";
import { Comments } from "../../../../../../components/Comments/comments";

import "./CommentTab.css";

export class CommentComponentView extends React.Component<LMI.ILRCommentData, LMI.ILRCommentlistState> {
	constructor(props: LMI.ILRCommentData) {
		super(props);
		this.state = {
			detail_id: null,
			data: [],
			modal_open: false
		};
	}

	static format(data) {
		return data.map(d => {
			return {
				id: d.id,
				first_name: d.first_name,
				last_name: d.last_name,
				user_photo_url: d.user_photo_url,
				comment: d.comment,
				created_at: parseInt(d.created_at, 10)
			};
		});
	}

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
		if (type === "danger") {
			console.log(`Error: ${message}`);
		}
	}

	getModal = () => {
		const { modal_open } = this.state;

		const commentProps = {
			headerText: "Add Comment",
			refetchComments: this.props.refetchComments,
			onClose: () => this.setState({ modal_open: false }),
			closeModalCallback: () => this.setState({ modal_open: false }),
			onUpdateCommentCount: args => this.props.onUpdateCommentCount(args),
			...this.props
		};

		return (
			<ModalComponent
				headerText={commentProps.headerText}
				size="small"
				shouldBeOpen={modal_open}
				onClose={() => {
					this.setState({ modal_open: false });
				}}
				className="comment-item-modal"
				contentComponent={() => <CommentForm {...commentProps} />}
			/>
		);
	};

	render() {
		const { loading, lease_return, store_settings, onSetHeaderColor } = this.props;
		const { data, modal_open } = this.state;

		if (loading || !data) return <Loader active />;

		const UABProps: LMI.IUABProps = {
			subUniversalActionCallback: () => this.setState({ modal_open: true }),
			size: "small",
			tip: "Add a new Comment"
		};

		onSetHeaderColor({ age: lease_return.age, store_settings, is_completed: !!lease_return.completed_at });

		const commentProps: LMI.IComments = {
			data
		};

		if (data.length > 0) {
			return (
				<>
					<div id="Comment" className="comment-panel">
						{modal_open && this.getModal()}
						<Comments {...commentProps} />
					</div>
					<div className="fab-footer">
						<UniversalActionButton {...UABProps} />
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className="noresults" key={0}>
						There are no existing comments for this vehicle.
					</div>
					{modal_open && this.getModal()}
					<div className="fab-footer">
						<UniversalActionButton {...UABProps} />
					</div>
				</>
			);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.lease_return) {
			if (nextProps.lease_return !== prevState.data) {
				const data = CommentComponentView.format(nextProps.lease_return.comments);
				return { lease_return_id: nextProps.lease_return.id, data };
			}
		}
		return null;
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const CommentComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.leaseReturnDetail, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					leaseReturnId: parseInt(props.detail.detail_id, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, lease_return_detail, refetch, fetchMore } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				lease_return: lease_return_detail.lease_return,
				refetchComments: refetch
			};
		}
	})
)(CommentComponentView) as React.ComponentType<any>;

export default CommentComponent;
