import { restAPI } from "../../../../../utils/rest";
// node_modules
import { Loading } from "client/components/Loading";
// components
import { ModalComponent } from "client/pages/admin/components/ModalComponent";

import { formatPhone } from "client/utils/functions";
import * as React from "react";
import { Button, Message } from "semantic-ui-react";
import { DataFilterComponent } from "../../DataFilterComponent";
import { SortableTable } from "../../SortableTable";
import { AddEmployeeModal } from "./components";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

import "./StoreEmployeesTabPanel.css";

@track(
	props => {
		return {
			event_type: props.tracking_path ? AnalyticsEventType.SUBNAV : AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}store-employees`,
			data: { details: props }
		};
	},
	{ dispatchOnMount: true }
)
export class StoreEmployeesTabPanelView extends React.Component<LMI.IStoreEmployeesTPProps, LMI.IStoreEmployeesTPState> {
	constructor(props) {
		super(props);
		this.state = {
			formData: null,
			showMessage: false,
			message: "",
			searchFilter: null,
			addEmployee: false
		};
	}
	render() {
		const props = this.props;

		if (props.loading) {
			return <Loading />;
		}
		if (props.hasErrors) {
			console.log("StoreEmployeesTabPanel Data errors:", props.error);
		}
		const isVendor = props.viewType.includes("vendors");

		const employeesArr: LMI.IStoreEmployeesTable[] = props.employees
			? props.employees.map(emp => {
					const employ = Object.assign({}, emp, {
						isPrimary: false
					});
					return employ;
			  })
			: [];

		if (props.primary_contact && props.primary_contact.primary_contact) {
			const present = employeesArr.find(emp => emp.id === props.primary_contact.primary_contact.id);
			if (!present) {
				const employee: LMI.IStoreEmployeesTable = {
					id: props.primary_contact.primary_contact.id,
					user: {
						full_name: props.primary_contact.primary_contact.user.full_name,
						phone: props.primary_contact.primary_contact.user.phone,
						email: props.primary_contact.primary_contact.user.email
					},
					is_active: true,
					isPrimary: true
				};
				employeesArr.unshift(employee);
			} else {
				employeesArr.find(emp => emp.id === props.primary_contact.primary_contact.id).isPrimary = true;
			}
		}

		let employees = this.mapTableData(employeesArr, props.viewType) || [];
		if (this.state.searchFilter) {
			employees = this.searchFilter(this.state.searchFilter, employees);
		}

		const addEmpProps = {
			storeId: props.vendorId,
			onSubmit: data => {
				this.assignEmployee(data.employee_id);
				this.setState({ addEmployee: false });
			},
			assigned_employees: employees
		};

		return (
			<div className="store-employees-tab-panel  panel-content">
				{isVendor && this.state.addEmployee && (
					<ModalComponent
						headerText="Assign an Employee"
						shouldBeOpen={this.state.addEmployee ? true : false}
						onClose={(evt, data) => {
							this.setState({ addEmployee: null });
						}}
						size="small"
						className="assign-employees"
						contentComponent={() => <AddEmployeeModal {...addEmpProps} />}
					/>
				)}
				<div className="sortable-container">
					<div className="sortable-filter">
						{isVendor && <Button content="Assign an Employee" onClick={() => this.setState({ addEmployee: true })} />}
						<DataFilterComponent
							label="Filter Results"
							searchFilter={this.state.searchFilter}
							onChange={(p, { value }) => {
								this.setState({
									searchFilter: value
								});
							}}
						/>
					</div>
					<div className="sortable-content">
						{employees.length === 0 ? (
							<Message info content="This vendor has not yet assigned any employees to this account." />
						) : (
							<SortableTable tableData={this.buildTableData(employees)} />
						)}
					</div>
				</div>
			</div>
		);
	}

	buildTableData(employees: any[]) {
		const tableHeaders: any = [
			{
				id: "name",
				label: "Name",
				sortable: true
			},
			{
				id: "email",
				label: "Email",
				sortable: true
			},
			{
				id: "phone",
				label: "Phone",
				sortable: true
			}
		];

		return {
			headers: tableHeaders,
			body: { rows: employees }
		};
	}

	mapTableData(employees: LMI.IStoreEmployeesTable[], viewType: string) {
		return employees.map((emp: LMI.IStoreEmployeesTable) => {
			const user = emp.user;
			const employee = {
				name: emp.isPrimary
					? {
							text: user.full_name,
							labeled: "Primary Contact",
							mini: true,
							info: true
					  }
					: user.full_name || "",
				email: user.email || "",
				phone: formatPhone(user.phone) || ""
			};

			return employee;
		});
	}

	searchFilter(search: string, employees: any[]) {
		const srch = search.toLowerCase();
		return employees.filter(emp => {
			return emp.name.toLowerCase().includes(srch) || emp.email.toLowerCase().includes(srch) || emp.phone.toLowerCase().includes(srch);
		});
	}

	assignEmployee(employee: number) {
		restAPI({
			endpointName: "assignEmployeeToDealer",
			urlArgs: [parseInt(this.props.vendorId, 10), parseInt(this.props.storeId, 10), employee],
			data: null,
			callback: (err, res) => {
				this.props.addAlert({
					type: err ? "danger" : "success",
					message: err ? err.reason.response.data.message : "The employee is assigned!",
					timeout: 3000
				});
				if (!err) {
					this.props.refetch();
				}
			}
		});
	}
}

export default StoreEmployeesTabPanelView;
