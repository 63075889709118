import { removeAlert } from "api/redux/actions";
import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Icon, Message } from "semantic-ui-react";
import * as Raven from "raven-js";
import "./AlertComponent.css";

export class AlertComponentView extends React.Component<
  LMI.IAlertsProps,
  undefined
> {
  getTypeProps(): { alertClass: string; messageColor: any; fancyIcon: any } {
    const { type, message, position, fancy } = this.props;
    let messageColor, fancyIcon;
    switch (type) {
      case "success":
        messageColor = "green";
        fancyIcon = "check circle";
        break;
      case "warning":
        messageColor = "yellow";
        fancyIcon = "exclamation triangle";
        break;
      case "danger":
        messageColor = "red";
        fancyIcon = "exclamation circle";
        break;
      default:
        messageColor = "grey";
        fancyIcon = "info circle";
    }
    return {
      alertClass: `${position ? position : AlertPositions.Middle} ${
        message ? "in" : "out"
      } ${fancy ? "fancy" : ""}`,
      messageColor,
      fancyIcon,
    };
  }

  render() {
    const { type, message, timeout, onComplete, fancy } = this.props;
    const hasTimeout = timeout;
    const { alertClass, messageColor, fancyIcon } = this.getTypeProps();

    if (message) {
      if (type === "danger") Raven.captureException(message);

      setTimeout(
        () => {
          if (onComplete) onComplete();
          this.props.finished();
        },
        hasTimeout ? timeout : 2000
      );
    }

    return (
      <div id="alert-message" className={alertClass} onClick={onComplete}>
        {message && (
          <Message color={messageColor}>
            {fancy && <Icon className="fancy-label" name={fancyIcon} />}
            <span>{message && message}</span>
          </Message>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return state.app.admin.alert ? state.app.admin.alert : {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    finished: () => {
      dispatch(removeAlert());
    },
  };
};

export const AlertComponent = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AlertComponentView);
export enum AlertPositions {
  Top = "top",
  Middle = "middle",
  Bottom = "bottom",
}
