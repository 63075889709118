/**
 * Take file from <input type="file"... and return base64 encoded string
 * @param file
 * @param callback
 */
export function getBase64(file, callback) {
	const reader = new FileReader();
	reader.onload = () => {
		callback(reader.result);
	};
	reader.onerror = () => {
		console.error("There was an error reading the file: ", file);
	};
	reader.readAsDataURL(file);
}

export function promiseBase64(file: any): Promise<any> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.onerror = () => {
			const error = `There was an error reading the file: ${file}`;
			console.error(error);
			reject(error);
		};
		reader.readAsDataURL(file);
	});
}

export const getFileObjectFromPath = async (url): Promise<any> => {
	const parts = url.split("/");
	return new Promise((resolve, reject) => {
		try {
			const xhr = new XMLHttpRequest();
			xhr.open("GET", url);
			xhr.responseType = "blob";
			xhr.onload = function() {
				const blob = xhr.response;
				const reader = new FileReader();
				reader.onloadend = function() {
					const base64 = reader.result;
					const type = base64
						.toString()
						.split(";")[0]
						.split(":")[1];
					const file = new File([blob], parts[parts.length - 1], { type });
					resolve({ base64, file });
				};
				reader.readAsDataURL(blob);
			};
			xhr.send();
		} catch (error) {
			reject(error);
		}
	});
};

export async function printWindow(title: string, content: any, inTab = false) {
	const printer = inTab ? window.open() : window.open("", "", "location=yes,height=570,width=900,scrollbars=yes,status=yes");
	const page = `<html><head><title>${title}</title><link rel="stylesheet" href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css" /></head><body style="padding: 15px;">${content}</body></html>`;
	printer.document.write(page);
	printer.document.close();
	printer.focus();
	setTimeout(() => {
		printer.print();
	}, 300);
}

export const humanFileSize = ({ bytes, si = false, dp = 1 }) => {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return bytes + " B";
	}

	const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

	return bytes.toFixed(dp) + " " + units[u];
};

export const generateThumbnail = ({ file, bounding_box }) => {
	if (!bounding_box || bounding_box.length !== 2) {
		throw new Error("You need to give a valid bounding box");
	}
	const reader = new FileReader();
	const canvas = document.createElement("canvas");
	const ctx = canvas.getContext("2d");

	return new Promise((resolve, reject) => {
		reader.onload = event => {
			const img = new Image();
			img.onload = () => {
				const scaleRatio = Math.min(...bounding_box) / Math.max(img.width, img.height);
				let w = img.width * scaleRatio;
				let h = img.height * scaleRatio;
				canvas.width = w;
				canvas.height = h;
				ctx.drawImage(img, 0, 0, w, h);
				return resolve(canvas.toDataURL(file.type));
			};
			img.src = event.target.result as string;
		};
		reader.readAsDataURL(file);
	});
};

export const calculateBase64ImageSize = ({ base64String }) => {
	let padding, inBytes, base64StringLength;
	if (base64String.endsWith("==")) padding = 2;
	else if (base64String.endsWith("=")) padding = 1;
	else padding = 0;

	base64StringLength = base64String.length;
	// console.log("length", base64StringLength);
	inBytes = (base64StringLength / 4) * 3 - padding;
	// console.log("length in bytes", inBytes);
	return inBytes;
};
