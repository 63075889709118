import track from "react-tracking";
import { addAlert } from "api/redux/actions";
// node_modules
import { Form } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { ListInputComponent } from "client/pages/admin/components/ListInputComponent";

import { stringToInt } from "client/utils/functions";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Grid, Header, Icon, Menu, Message, Segment } from "semantic-ui-react";
// components
import { Loading } from "../../../../../components/Loading";
import { restAPI } from "../../../../../utils/rest";
import { AnalyticsEventType } from "loopmein-shared";

import "./ContactSettingsTabPanel.css";

@track(
	props => {
		return {
			event_type: AnalyticsEventType.SUBNAV,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}settings.contact`
		};
	},
	{ dispatchOnMount: true }
)
export class ContactSettingsTabPanelView extends React.Component<LMI.IContactSettingProps, LMI.IContactSettingState> {
	constructor(props) {
		super(props);
		this.state = {
			saving: null,
			saved: [],
			activeTab: 2
		};
	}

	render() {
		const props = this.props;
		// console.log(props);

		if (props.hasErrors) {
			console.log("ContactSettingsTabPanel Data errors:", props.message);
		}
		if (props.loading) {
			return <Loading />;
		}

		// MAY END UP PULLING THESE IN FROM DB OR LOOPMEIN_SHARED
		const dealers = ["CRM_LEAD_ADDRESS", "ACCOUNTS_PAYABLE_USER"];
		const vendors = ["TASK_EVENT_NOTIFICATION_USER", "ACCOUNTS_PAYABLE_USER"];

		const editable = props.storeType.includes("dealerships") ? dealers : vendors;
		const org_store_settings = props.store_settings
			? this.state.activeTab === 2
				? props.store_settings.filter(set => set.store_id === this.props.storeId)
				: props.store_settings.filter(set => set.store_id === null)
			: [];
		const contacts =
			props.store_settings &&
			editable.map(set => {
				const hasValue = org_store_settings.find(setting => setting.name === set);
				const newSet = {
					id: hasValue ? hasValue.id : null,
					name: hasValue ? hasValue.name : set,
					value: hasValue ? hasValue.value : "",
					store_id: hasValue ? hasValue.store_id : null,
					organization_id: hasValue ? hasValue.organization_id : null
				};
				return newSet;
			});

		return (
			<div id="notifications" className="notifications-tab-panel panel-content">
				<Grid>
					<Grid.Column width={12}>
						<Header as="h3">
							<Icon name="alarm" color="grey" />
							<Header.Content>User Notification Defaults</Header.Content>
						</Header>
						<p>Set notifications to be delivered to an email address or multiple email addresses.</p>
					</Grid.Column>
					<Grid.Column width={4}>
						<Menu secondary>
							<Menu.Menu position="right">
								<Menu.Item name="Organization" active={this.state.activeTab === 1} onClick={() => this.setState({ activeTab: 1, saved: [] })} />
								<Menu.Item name="Store" active={this.state.activeTab === 2} onClick={() => this.setState({ activeTab: 2, saved: [] })} />
							</Menu.Menu>
						</Menu>
					</Grid.Column>
				</Grid>
				{contacts && contacts.length ? (
					<div className="notifications">
						<Segment.Group>
							{contacts.map((contact, index) => {
								const itemprops: LMI.IListInputProps = {
									validate: "email",
									items: contact.value,
									placeholder: "If empty this defaults to the Default User",
									onChange: data => {
										const item = {
											name: contact.name,
											value: data
												.reverse()
												.map(i => i.value)
												.join(",") // deliminated string as value
										};
										if (item.value !== contact.value) {
											if (!item.value.length) {
												this.deleteSetting(contact.id, contact.name);
											} else {
												this.saveSetting(item);
											}
											this.setState({
												saving: contact.name
											});
										}
									}
								};

								return (
									<Segment key={index} clearing>
										<Form.Field>
											<span className="savedIndicator">
												{this.state.saving === contact.name && <Icon loading color="green" name="spinner" />}
												{!this.state.saving && this.state.saved.length && this.state.saved.find(i => i === contact.name) ? (
													<span>
														<Icon color="green" name="checkmark" /> Saved
													</span>
												) : (
													""
												)}
											</span>
											<label className="inputLabel">{this.formatLabel(contact.name)}</label>
											<ListInputComponent {...itemprops} />
										</Form.Field>
									</Segment>
								);
							})}
						</Segment.Group>
					</div>
				) : (
					<Message content="There are no notification settings currently saved. Contact your admin to configure notifications." />
				)}
			</div>
		);
	}

	formatLabel(str: string) {
		let formattedString;
		switch (str) {
			case "CRM_LEAD_ADDRESS":
				formattedString = "CRM Lead Email Address";
				break;
			case "ACCOUNTS_PAYABLE_USER":
				formattedString = "Accounts Payable Email Address";
				break;
			default:
				formattedString = str;
				break;
		}
		return formattedString.replace(/_/g, " ").toLowerCase();
	}

	saveSetting(data: any) {
		const setting = data;
		const endpoint = this.state.activeTab === 1 ? "setOrgSettings" : "setStoreSettings";
		const id = this.state.activeTab === 1 ? this.props.stre.organization_id : this.props.storeId;
		restAPI({
			endpointName: endpoint,
			urlArgs: [id],
			data: setting,
			callback: (err, res) => {
				this.props.addAlert({
					type: err ? "danger" : "success",
					message: err ? err.reason.response.data.message : "Setting Saved",
					timeout: 3000
				});
				if (!err) {
					this.props.refetch();
					this.setState(prevState => ({
						saved: [data.name, ...prevState.saved],
						saving: null
					}));
				}
			}
		});
	}

	deleteSetting(id: number, name: string) {
		const endpoint = this.state.activeTab === 1 ? "deleteOrgSetting" : "deleteStoreSetting";
		const entid = this.state.activeTab === 1 ? this.props.stre.organization_id : this.props.storeId;
		restAPI({
			endpointName: endpoint,
			urlArgs: [entid, id],
			data: null,
			callback: (err, res) => {
				this.props.addAlert({
					type: err ? "danger" : "success",
					message: err ? err.reason.response.data.message : "Setting Saved",
					timeout: 3000
				});
				if (!err) {
					this.props.refetch();
					this.setState(prevState => ({
						saved: [name, ...prevState.saved],
						saving: null
					}));
				}
			}
		});
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const ContactSettingsTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.settings_org.store, {
		options: (props: any) => {
			return {
				variables: {
					storeId: stringToInt(props.storeId)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_org_settings_admin, store, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				store_settings: store_org_settings_admin,
				stre: store,
				refetch
			};
		}
	})
)(ContactSettingsTabPanelView) as React.ComponentType<any>;

export default ContactSettingsTabPanel;
