import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { addAlert } from "api/redux/actions";
import { restAPI } from "client/utils/rest";
import { Validation } from "loopmein-shared";
import { handleErrorResponse, handleSuccessResponse, validateDto } from "client/utils/functions";

import { Icon, Form, Input, Button, Segment, Divider, Label } from "semantic-ui-react";
import InspectionTypeSelect from "../InventoryInspection/components/InspectionTypeSelect";
import { InspectionService } from "../InventoryInspection/util";

import "./InspectionTemplateForm.css";

export class InspectionTemplateFormView extends React.Component<LMI.InspectionTemplateFormProps, LMI.InspectionTemplateFormState> {
	constructor(props: LMI.InspectionTemplateFormProps) {
		super(props);
		this.state = {
			new_template: props.template && props.template.id ? false : true,
			template: props.template,
			inspection_type_options: InspectionTemplateFormView.buildTypeOptions(props.types, props.template_items),
			open_item_editor: true,
			dirty: false,
			saving: false,
			saving_items: false,
			valid_template: true
		};
	}

	static buildTypeOptions(types, template_items) {
		return template_items && types ? template_items.map(i => ({ ...i, ...types.find(o => o.id === i.inspection_item_option_id), item_id: i.id })) : [];
	}

	static getDerivedStateFromProps(nextProps: LMI.InspectionTemplateFormProps, prevState: LMI.InspectionTemplateFormState) {
		if (nextProps.template.id && nextProps.template_items && nextProps.template_items.length !== prevState.inspection_type_options.length) {
			const inspection_type_options = InspectionTemplateFormView.buildTypeOptions(nextProps.types, nextProps.template_items);
			return { template: nextProps.template, inspection_type_options };
		} else return null;
	}

	async onSaveTemplate(loadTemplate: boolean = false): Promise<any> {
		const { storeId, on_close, enable_loading, on_load_template } = this.props;
		const { template, new_template, inspection_type_options, dirty } = this.state;
		const load = loadTemplate && enable_loading;

		if (load && !dirty) {
			on_load_template(this.state.template);
			return;
		} else {
			const templateId = template.id;
			const endpointName = templateId ? "edit_inspection_template" : "add_inspection_template";
			const urlArgs = [storeId, ...(templateId ? [templateId] : [])];
			const templateData = { name: template.name, description: template.description };
			const validation = Validation.Auth.Dealer.Inspection;
			const validData = await validateDto(templateId ? validation.EditTemplate : validation.AddTemplate, templateData);

			if (validData)
				this.setState({ saving: true }, async () => {
					restAPI({
						endpointName,
						urlArgs,
						data: validData,
						callback: async (error, result) => {
							this.props.addAlert({
								type: error ? "danger" : "success",
								message: error ? handleErrorResponse({ error }) : handleSuccessResponse({ result }),
								timeout: 3000
							});

							const finish = () => {
								this.setState({ saving: false }, () => {
									if (load) on_load_template(this.state.template);
									else on_close();
								});
							};

							// if it is a new template then do a batch save of configured items
							if (new_template && inspection_type_options.length > 0) {
								this.setState({ template: { ...template, ...{ id: result.data.template.id } } }, async () => {
									await this.addAllItems();
									finish();
								});
							} else finish();
						}
					});
				});
		}
	}

	async addAllItems(): Promise<any> {
		const { inspection_type_options } = this.state;
		return Promise.all(inspection_type_options.map(option => this.addTemplateItem(option)));
	}

	async addTemplateItem(item: LMI.InspectionItemTypeOption) {
		return new Promise((resolve, reject) => {
			this.setState({ saving_items: true }, () => {
				const { inspection_line_item_section_id, id } = item;
				restAPI({
					endpointName: "add_inspection_template_item",
					urlArgs: [this.props.storeId, this.state.template.id],
					data: { inspection_line_item_section_id, inspection_item_option_id: id },
					callback: async (error, result) => {
						this.setState({ saving_items: false }, () => {
							if (error) {
								this.props.addAlert({
									type: "danger",
									message: handleErrorResponse({ error }),
									timeout: 3000
								});
								reject(error);
							} else resolve(result);
						});
					}
				});
			});
		});
	}

	async deleteTemplateItem(item: LMI.InspectionItemTypeOption) {
		return new Promise((resolve, reject) => {
			const { item_id } = item;
			if (!item_id) console.log("no item id configured here");

			if (item_id)
				this.setState({ saving_items: true }, () => {
					restAPI({
						endpointName: "delete_inspection_template_item",
						urlArgs: [this.props.storeId, this.state.template.id, item_id],
						data: null,
						callback: async (error, result) => {
							this.setState({ saving_items: false }, () => {
								if (error) {
									this.props.addAlert({
										type: "danger",
										message: handleErrorResponse({ error }),
										timeout: 3000
									});
									reject();
								} else resolve(result);
							});
						}
					});
				});
		});
	}

	onAddItem(item) {
		if (!item) return;
		const { new_template, inspection_type_options } = this.state;
		const options = [...inspection_type_options, ...[item]];
		this.setState({ inspection_type_options: options, dirty: true }, async () => {
			if (!new_template) {
				await this.addTemplateItem(item);
				if (this.props.on_update) this.props.on_update();
			}
		});
	}

	onDeleteItem(item) {
		if (!item) return;
		const { new_template, inspection_type_options } = this.state;
		this.setState({ saving_items: !new_template, dirty: true, inspection_type_options: inspection_type_options.filter(ti => ti.id !== item.id) }, async () => {
			if (!new_template) {
				await this.deleteTemplateItem(item);
				if (this.props.on_update) this.props.on_update();
			}
		});
	}

	validateTemplateName(name: string) {
		const { templates, template } = this.props;
		let existingNames = [];
		if (templates && templates.length > 0)
			existingNames = templates.filter(i => (template && template.id ? i.id !== template.id : true)).map(t => t.name.toLowerCase());
		this.setState({ valid_template: !existingNames.includes(name.toLowerCase()), dirty: true, template: { ...this.state.template, ...{ name } } });
	}

	render() {
		const { enable_loading, types, permissions, on_close } = this.props;
		const { template, inspection_type_options, open_item_editor, dirty, saving, saving_items, valid_template } = this.state;
		const updateTemplate = (val, name) => this.setState({ dirty: true, template: { ...template, ...{ [name]: val } } });
		const enable_actions = (dirty || enable_loading) && template && template.name;
		const usedOptions = inspection_type_options.map(o => o.id);
		const selectableTypes = types.filter(type => !usedOptions.includes(type.id) && type.is_active);
		const { can_admin } = InspectionService.getPermits({ readonly: false, permissions });

		return (
			<div id="InspectionTemplateForm">
				<Form>
					<Form.Field>
						<label style={{ position: "relative" }}>
							Template Name {!(template && template.name) && <Icon name="asterisk" color="red" />}{" "}
							{valid_template ? "" : <strong className="error-text">This Template name already exists.</strong>}
						</label>
						<Input
							name="name"
							type="text"
							error={!valid_template}
							placeholder="Add Name"
							value={template.name ? template.name : ""}
							onChange={(e, data) => this.validateTemplateName(data.value)}
						/>
					</Form.Field>
					<Form.Field>
						<label>Template Description</label>
						<Input
							name="description"
							placeholder="Add Description"
							value={template.description ? template.description : ""}
							onChange={(e, data) => updateTemplate(data.value, "description")}
						/>
					</Form.Field>
				</Form>
				<Segment>
					<div id="IncludedInspectionItems">
						<h4 className="items-header" onClick={() => this.setState({ open_item_editor: !open_item_editor })}>
							<Icon name={open_item_editor ? "folder open" : "folder"} />{" "}
							{`${inspection_type_options.length} Inspection Item${inspection_type_options.length !== 1 ? "s" : ""}`}
						</h4>
						{saving_items && (
							<span className="saving-indicator">
								<Icon name="spinner" loading /> Saving Items
							</span>
						)}
						{open_item_editor && (
							<div id="NewItemField" className="new-item-form">
								{selectableTypes && selectableTypes.length > 0 ? (
									<InspectionTypeSelect
										{...{
											id: `TypeSelectNewItem`,
											types: selectableTypes,
											is_new: true,
											can_add: can_admin,
											placeholder: "Add Item",
											onSave: new_item => this.onAddItem(new_item),
											customProps: {
												// For now overriding allowing additions
												// TODO: implement item type creation in here
												allowAdditions: false
											}
										}}
									/>
								) : (
									<Label>No more available OP codes</Label>
								)}
							</div>
						)}
						{open_item_editor &&
							inspection_type_options.length > 0 &&
							inspection_type_options.map((i, k) => {
								return (
									<div key={k} className={`included-items${i.is_active ? "" : " not-active"}`}>
										<Icon name="minus square" color="red" link onClick={() => this.onDeleteItem(i)} /> {i.op_code ? <strong>{`${i.op_code} - `}</strong> : ""}
										{i.name}{" "}
										{i.is_active ? (
											""
										) : (
											<span className="not-active-label">
												OP Code deleted -{" "}
												<span className="reactivate" onClick={() => this.reactivateItemType(i)}>
													Reactivate?
												</span>
											</span>
										)}
									</div>
								);
							})}
					</div>
				</Segment>
				<div className="form-actions">
					<Divider />
					<div className="actions">
						{enable_actions && (
							<span>
								{enable_loading && <Button icon="plus" content="Add to Current Inspection" onClick={() => this.onSaveTemplate(true)} />}
								{dirty && (
									<Button
										color="green"
										content="Save Template"
										disabled={saving || !valid_template}
										icon={{ name: saving ? "spinner" : "save", loading: saving ? true : false }}
										onClick={() => this.onSaveTemplate()}
									/>
								)}
							</span>
						)}
						{!dirty && <Button content="Close" onClick={() => on_close()} />}
					</div>
				</div>
			</div>
		);
	}

	reactivateItemType(type) {
		const { inspection_type_options } = this.state;
		if (type?.inspection_item_option_id)
			restAPI({
				endpointName: "edit_inspection_item_option",
				urlArgs: [this.props.storeId, type.inspection_item_option_id],
				data: { is_active: true },
				callback: (error, res) => {
					if (error)
						this.props.addAlert({
							type: "danger",
							message: handleErrorResponse({ error }),
							timeout: 3000
						});
					else {
						const newTypeOptions = [...inspection_type_options];
						const reactivated = newTypeOptions.findIndex(i => i.id == type.id);
						newTypeOptions[reactivated].is_active = true;
						console.log(newTypeOptions[reactivated]);

						this.setState({ inspection_type_options: newTypeOptions }, () => this.props.on_update());
					}
				}
			});
	}
}

const mapStateToProps = state => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const InspectionTemplateForm = compose(connect(mapStateToProps, mapDispatchToProps))(InspectionTemplateFormView);
export default InspectionTemplateForm;
