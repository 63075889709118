import * as React from "react";
import { Segment, Grid, Image } from "semantic-ui-react";
// import { IAgeColors } from "loopmein-shared/bin/features/leaseReturn";

export class LeaseReturnResult extends React.Component<LMI.LmiResultTypeProps, undefined> {
	getCorrectPhoto(detail: LMI.ILeaseReturnGQL) {
		const { vehicle, primary_photo_url } = detail;
		const primaryIsDefault = primary_photo_url.includes("default_vehicle_image.png");
		if (primaryIsDefault && vehicle.stock_image_url) return vehicle.stock_image_url;
		else return primary_photo_url;
	}

	render() {
		const { onSelect } = this.props;
		const detail: LMI.ILeaseReturnGQL = this.props.detail;
		const { vehicle, customer_name } = detail;

		return (
			<Segment key={vehicle.id} raised className="result-tile" onClick={() => onSelect(`lease-returns?id=${detail.lease_return_uuid}`)}>
				<Grid className="summary">
					<Grid.Column width={3}>
						<Image size="small" rounded src={`${this.getCorrectPhoto(detail)}?h=150&w=150&auto=compress`} loading="lazy" />
					</Grid.Column>
					<Grid.Column width={13}>
						<div className="vehicle">
							<div className="details">
								{vehicle.model_year} {vehicle.make} {vehicle.model} {vehicle.style_name ? vehicle.style_name : ""}
							</div>
							<div className="identity">
								<div className="i-label">VIN: {vehicle.vin}</div>
								<div className="i-label">CUSTOMER NAME: {customer_name}</div>
							</div>
						</div>
					</Grid.Column>
				</Grid>
			</Segment>
		);
	}
}

export default LeaseReturnResult;
