import * as Raven from "raven-js";
import { stringToInt } from "./functions";
import { resetStore } from "api/redux/actions";
import { restAPI } from "./rest";
import { Session } from "client/utils/session";

export const hasPermission = (
  permissions: number[],
  permission: number,
  isSuperUser: boolean
) => {
  if (isSuperUser) return true;
  return permissions.indexOf(permission) > -1;
};

export const hasPermissions = (
  hasPermissions: number[],
  checkPermissions: number[],
  isSuperUser: boolean
) => {
  if (isSuperUser) return true;
  return checkPermissions.every((val) => hasPermissions.includes(val));
};

/**
 * Method triggered when the login form is submitted.
 *
 * @param {any} event
 *
 * @memberof userAccess
 */
export const login = (email, password) => {
  restAPI({
    endpointName: "loginUser",
    urlArgs: null,
    data: { email, password },
    callback: (error, result) => {
      const noStoreSet =
        result && result.data && result.data.store_type_ids.length === 0;
      if (error || noStoreSet) {
        localStorage.removeItem("token");
      } else {
        localStorage.setItem("token", result.data.token);
        localStorage.setItem("isu", result.data.is_super_user);

        try {
          resetStore();
          window.open("/", "_parent");
        } catch (e) {
          console.log("Login error: ", e);
          Raven.captureException(e);
        }
      }
    },
  });
};

export const forceLogout = (query) => {
  localStorage.clear();

  Session.set("userId", undefined);
  Session.set("stores", undefined);
  Session.set("storeTypeIds", undefined);
  Session.set("email", undefined);
  Session.set("fullName", undefined);
  Session.set("isSuperUser", undefined);
  Session.set("userType", undefined);

  try {
    resetStore();
    window.open(`/login?${query}`, "_parent");
    return;
  } catch (e) {
    console.log("Logout error: ", e);
    Raven.captureException(e);
  }
};

export const getStoreTypeId = () => {
  const selectedStoreTypeId = localStorage.getItem("selectedStoreType");
  const storeTypeId =
    selectedStoreTypeId !== undefined
      ? selectedStoreTypeId
      : Session.get("storeTypeIds")[0];
  return stringToInt(storeTypeId);
};
