// node_modules
import * as React from "react";
// components
import { Button, Card } from "semantic-ui-react";
import "./AddServiceComponent.css";

export class AddServiceComponent extends React.Component<LMI.IAddServicesTPProps, undefined> {
	render() {
		const props = this.props;

		return (
			<div className="add-services">
				<Card.Group>
					{props.services
						? props.services.map(service => {
								return (
									<Card
										key={service.id}
										header={service.service}
										meta={service.base_price.toLocaleString("en-US", { style: "currency", currency: "USD" })}
										description={service.description}
										extra={<Button content="Approve" size="small" positive onClick={() => props.approveService(service.id)} />}
									/>
								);
						  })
						: ""}
				</Card.Group>
				<br />
			</div>
		);
	}
}

export default AddServiceComponent;
