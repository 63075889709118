// node_modules
import * as React from "react";
import { gqlQueries } from "gql-imports";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { SortableTable, getSortableImage } from "client/pages/admin/components/SortableTable";
import { Loader } from "semantic-ui-react";
import { RelativeDatePipe } from "client/utils/functions";

export class ActivityDialogView extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			reload: null,
			complete: null,
			panelheight: null
		};
	}

	render() {
		const { loading } = this.props;
		if (loading) return <Loader active />;

		const { logs } = this.props.employee_inventory_logs;
		const { panelheight, reload } = this.state;

		return (
			<div id="su-users-list-component" style={{ height: panelheight }}>
				<SortableTable
					message="There is nothing to show!"
					paneDidMount={this.paneDidMount}
					tableData={this.getTableData(this.mapLogs(logs))}
					selectableRows={false}
					multiSelect={false}
				/>
				{(this.props.loading || reload) && (
					<div className={reload ? "lazyloader" : ""}>
						<Loader active />
					</div>
				)}
			</div>
		);
	}

	mapLogs(logs: LMI.IEmployeeLog[]) {
		return logs.map(log => {
			return {
				date: RelativeDatePipe(log.created_at, false, true),
				photo: {
					component: getSortableImage,
					value: log.inv.photo_url
				},
				vehicle: `${log.inv.year} ${log.inv.make} ${log.inv.model}`,
				vin: log.inv.vin,
				stock: log.inv.stock_number,
				description: log.description ? log.description : log.log_type_name
			};
		});
	}

	getTableData(users: any) {
		const headers: any = [
			{
				id: "date",
				label: "Date",
				sortable: true,
				selectable: false,
				collapsing: true
			},
			{
				id: "photo",
				label: "Photo",
				sortable: true,
				selectable: false,
				collapsing: true
			},
			{
				id: "vehicle",
				label: "Vehicle",
				sortable: true,
				selectable: false,
				collapsing: false
			},
			{
				id: "vin",
				label: "VIN Number",
				sortable: true,
				selectable: false,
				collapsing: true
			},
			{
				id: "stock",
				label: "Stock Number",
				sortable: true,
				selectable: false,
				collapsing: true
			},
			{
				id: "description",
				label: "Notes",
				sortable: true,
				selectable: false,
				collapsing: false
			}
		];

		const sortableTableData: LMI.ITableData = {
			headers,
			body: {
				rows: users
			}
		};
		return sortableTableData;
	}

	// Lazy Load - hit the bottom of the window
	isBottom(el) {
		const isdaBottom = el.scrollTop + el.clientHeight >= el.scrollHeight;
		return isdaBottom;
	}

	paneDidMount = node => {
		if (node) node.addEventListener("scroll", () => this.reloadtrigger(node));
	};

	reloadtrigger(node: any) {
		if (this.isBottom(node) && !this.state.reload && !this.state.complete) this.reload();
	}

	reload() {
		if (this.props.employee_inventory_logs) {
			const offset = this.props.employee_inventory_logs && this.props.employee_inventory_logs.logs.length;
			this.setState({ reload: true }, () => {
				this.props.reload(offset, 15).then(prev => {
					const complete = prev.data.employee_inventory_logs.length < 20 ? true : false;
					this.setState({ complete, reload: false });
				});
			});
		}
	}

	setpanelheight() {
		// the body height minus the header and tabs etc...
		const height = document.body.scrollHeight - 280;
		this.setState({
			panelheight: height.toString() + "px"
		});
	}

	componentDidMount() {
		this.setpanelheight();
	}

	UNSAFE_componentWillMount() {
		window.addEventListener("resize", () => {
			this.setpanelheight();
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", () => {
			this.setpanelheight();
		});
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const ActivityDialog = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.employeeActivity, {
		options: (props: any) => {
			return {
				variables: {
					store_id: props.storeId,
					employee_id: props.id,
					limit: 15
				},
				pollInterval: 10000,
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employee_inventory_logs, refetch, fetchMore } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				employee_inventory_logs,
				refetchActivity: refetch,
				reload: (offset, limit) => {
					return fetchMore({
						variables: { offset, limit },
						updateQuery(prev: any, { fetchMoreResult }) {
							const newLogs: any = fetchMoreResult;
							if (!newLogs.employee_inventory_logs.logs.length) return prev;
							else {
								const newLogItems = newLogs?.employee_inventory_logs?.logs;
								const { logs } = prev?.employee_inventory_logs;
								const logObject = {
									employee_inventory_logs: {
										logs: [...logs, ...newLogItems]
									}
								};
								return logObject;
							}
						}
					});
				}
			};
		}
	})
)(ActivityDialogView) as React.ComponentType<any>;

export default ActivityDialog;
