// node_modules
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
// redux
import { addAlert, triggerUABCallback } from "api/redux/actions";
// components
import { LookupVendorSearchComponent } from "./components";
import "./LookupVendorComponent.css";

export class LookupVendorComponentView extends React.Component<LMI.LookupVendorComponentProps, LMI.LookupVendorComponentState> {
	constructor(props) {
		super(props);

		this.state = {
			searching: false,
			selected: false,
			store_code: "",
			store_name: "",
			email: "",
			phone: "",
			first_name: "",
			last_name: "",
			zip: "",
			vendor_modified: false,
			add_vendor: false
		};
	}

	lookupVendors = (field: string, value: string) => {
		const state: any = {};
		state.store_code = "";
		state.store_name = "";
		state.email = "";
		state.phone = "";
		state[field] = value;
		this.setState(state);
	};

	searching = searching => {
		this.setState({ searching, selected: false });
	};

	selectVendor = args => {
		this.lookupVendors("store_code", args.currentTarget.id);
		this.setState({ selected: true, searching: false });
	};

	searchInputChange = data => {
		const state = {};
		state[data.name] = data.value;
		const searching = data.name === "store_code" && data.value.length > 0 ? true : data.value.length > 4 ? true : false;
		this.setState(state);
		if (!searching) {
			this.searching(false);
		} else {
			this.searching(true);
			this.lookupVendors(data.name, data.value);
		}
	};

	addInputChange = data => {
		const state = {};
		state[data.name] = data.value;
		this.setState(state);
	};

	vendorModified = (setting: boolean, mode = "reset") => {
		const state: any = { vendor_modified: setting };
		if (mode === "cancel") {
			state.store_code = "";
		}
		this.setState(state);
	};

	addVendor = () => {
		this.setState({ add_vendor: true });
	};

	resetAddVendor = () => {
		this.setState({
			vendor_modified: false,
			add_vendor: false,
			store_code: "",
			store_name: "",
			email: "",
			phone: ""
		});
	};

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
	}

	render() {
		const { store_code, store_name, email, phone, searching, selected, vendor_modified, add_vendor } = this.state;

		const searchProps = Object.assign({}, this.props, {
			onSearchInputChange: this.searchInputChange,
			onAddInputChange: this.addInputChange,
			onSelectVendor: store_code => this.selectVendor(store_code),
			onVendorModified: (setting, mode) => this.vendorModified(setting, mode),
			onAddVendor: this.addVendor,
			onResetAddVendor: this.resetAddVendor,
			onSendAlert: ({ type, message }) => this.sendAlert({ type, message }),
			searching,
			selected,
			store_code,
			store_name,
			email,
			phone,
			vendor_modified,
			add_vendor
		});

		return (
			<div className="lookup-container">
				<LookupVendorSearchComponent {...searchProps} />
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		closeModalCallback: () => {
			dispatch(triggerUABCallback(false));
		},
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const LookupVendorComponent = compose(connect(mapStateToProps, mapDispatchToProps))(LookupVendorComponentView) as React.ComponentType<any>;

export default LookupVendorComponent;
