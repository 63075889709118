/*
 *  Action types
 */
export const CHANGE_STORE = "App/CHANGE_STORE";
export const LOGIN_USER = "App/LOGIN_USER";
export const TRIGGER_UAB_CALLBACK = "App/TRIGGER_UAB_CALLBACK";
export const ENABLE_UAB_CALLBACK = "App/ENABLE_UAB_CALLBACK";
export const RESET_STORE = "App/RESET_STORE";
export const UPDATE_TOUR = "App/UPDATE_TOUR";
export const UPDATE_ROUTES = "App/UPDATE_ROUTES";
export const UPDATE_CURRENT_USER = "App/UPDATE_CURRENT_USER";
export const CHANGE_TAB = "App/CHANGE_TAB";
export const CHANGE_SUBTAB = "App/CHANGE_SUBTAB";
export const CHANGE_USERTYPE = "App/CHANGE_USERTYPE";
export const CHANGE_SELECTED_EMPLOYEE = "App/CHANGE_SELECTED_EMPLOYEE";
export const ADD_ALERT = "App/ADD_ALERT";
export const REMOVE_ALERT = "App/REMOVE_ALERT";
export const INVENTORY_LOG_UPDATE = "App/INVENTORY_LOG_UPDATE";
export const UPDATE_CURRENT_ROUTE = "App/UPDATE_CURRENT_ROUTE";
export const TOGGLE_QUICK_SEARCH = "App/TOGGLE_QUICK_SEARCH";
export const TOGGLE_ADD_INVENTORY = "App/TOGGLE_ADD_INVENTORY";
export const TOGGLE_HEADER = "App/TOGGLE_HEADER";
export const ENABLE_DATA_SYNC = "App/ENABLE_DATA_SYNC";

/*
 *  Action generators
 */
export function changeStore(data: any) {
  return { type: CHANGE_STORE, data };
}

export function updateRoutes(routes: any) {
  return { type: UPDATE_ROUTES, routes };
}

export function updateCurrentRoute(routeName: string) {
  return { type: UPDATE_CURRENT_ROUTE, routeName };
}

export function updateCurrentUser(user: LMI.IMeGQL) {
  return { type: UPDATE_CURRENT_USER, user };
}

export function loginUser(data: any) {
  return { type: LOGIN_USER, data };
}

export function triggerUABCallback(toggleUABCallback: boolean) {
  return { type: TRIGGER_UAB_CALLBACK, toggleUABCallback: toggleUABCallback };
}

export function enableUABCallback(showUAB: boolean) {
  return { type: ENABLE_UAB_CALLBACK, showUAB };
}

export function updateTour(steps: LMI.ITourStepGQL[]) {
  return { type: UPDATE_TOUR, steps };
}

export function changeTab(tabName: string) {
  return { type: CHANGE_TAB, tabName };
}

export function changeSubtab(tabName: string) {
  return { type: CHANGE_SUBTAB, tabName };
}

export function resetStore() {
  return { type: RESET_STORE };
}

export function changeUserType(userType: number) {
  return { type: CHANGE_USERTYPE, userType };
}

export function changeSelectedEmployee(employeeId: number) {
  return { type: CHANGE_SELECTED_EMPLOYEE, employeeId };
}

export function addAlert(alertProps: LMI.IAlertsProps) {
  return { type: ADD_ALERT, alert: alertProps };
}

export function removeAlert() {
  return { type: REMOVE_ALERT };
}

export function updateInventoryLog(triggerLogUpdate: string) {
  return { type: INVENTORY_LOG_UPDATE, triggerLogUpdate: triggerLogUpdate };
}

export function toggleQuickSearch(quickSearchOpen: boolean) {
  return { type: TOGGLE_QUICK_SEARCH, quickSearchOpen };
}

export function toggleAddInventoryDialog(addInventoryModalOpen: boolean) {
  return {
    type: TOGGLE_ADD_INVENTORY,
    addInventoryModalOpen: addInventoryModalOpen,
  };
}

export function toggleHeader(showHeader: boolean) {
  return { type: TOGGLE_HEADER, showHeader: showHeader };
}

export function enableDataSync(dataSync: LMI.EnabledDataSync) {
  return { type: ENABLE_DATA_SYNC, dataSync };
}

export enum GlobalDispatchKeys {
  changeStore = "changeStore",
  updateRoutes = "updateRoutes",
  updateCurrentRoute = "updateCurrentRoute",
  updateCurrentUser = "updateCurrentUser",
  addAlert = "addAlert",
  removeAlert = "removeAlert",
  updateInventoryLog = "updateInventoryLog",
  updateTour = "updateTour",
  changeSubtab = "changeSubtab",
  toggleQuickSearch = "toggleQuickSearch",
  toggleAddInventoryDialog = "toggleAddInventoryDialog",
  toggleHeader = "toggleHeader",
  enableDataSync = "enableDataSync",
  changeSelectedEmployee = "changeSelectedEmployee",
  changeTab = "changeTab",
  changeUserType = "changeUserType",
  triggerUABCallback = "triggerUABCallback",
  enableUABCallback = "enableUABCallback",
}

// namespace our functions for redux hooks
export const GlobalDispatch = {
  changeStore,
  updateRoutes,
  updateCurrentRoute,
  addAlert,
  removeAlert,
  updateInventoryLog,
  updateTour,
  changeSubtab,
  toggleQuickSearch,
  toggleAddInventoryDialog,
  toggleHeader,
  enableDataSync,
  changeSelectedEmployee,
  changeTab,
  changeUserType,
  updateCurrentUser,
  triggerUABCallback,
  enableUABCallback,
};
