// node_modules
import { formatPhone } from "client/utils/functions";
import * as React from "react";
// components
import { Dropdown, Icon, List } from "semantic-ui-react";
import "./SelectableUserDisplay.css";

export class SelectableUserDisplay extends React.Component<LMI.ISUDProps, LMI.ISUDState> {
	constructor(props) {
		super(props);

		this.state = {
			editing: false,
			currentValue: props.startingValue,
			selectedValue: props.startingValue
		};
	}

	render() {
		const props = this.props;
		const user = props.options.find(item => {
			if (item.id === this.state.currentValue.toString()) {
				return true;
			}
			return false;
		}, this);
		return (
			<div className="selectable-user-display">
				{this.props.canEdit && !this.state.editing ? (
					<span className="actionbtns">
						<Icon
							link
							name="pencil"
							disabled={this.state.editing}
							onClick={() => {
								this.setState({ editing: true });
							}}
						/>
					</span>
				) : (
					""
				)}
				{this.state.editing
					? this.getSelectComponent(
							this.props.id,
							this.props.onClick,
							props.options.map(u => {
								return {
									text: u.fullname,
									value: u.id,
									key: u.id
								};
							})
					  )
					: this.getListComponent(user)}
			</div>
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.startingValue !== this.props.startingValue) {
			this.setState({ currentValue: this.props.startingValue });
		}
	}

	getListComponent(user) {
		if (!user) {
			return <div>Not Set</div>;
		}
		return (
			<List size={this.props.size ? this.props.size : "medium"}>
				<List.Item>
					<List.Header>{user.fullname}</List.Header>
				</List.Item>
				<List.Item icon="mail" content={<a href={`mailto:${user.email || ""}`}>{user.email || "None set"}</a>} />
				<List.Item icon="phone" content={formatPhone(user.phone) || ""} />
			</List>
		);
	}

	getSelectComponent(id: string, onClick: any, options: any[]) {
		return (
			<div className="component-wrapper">
				<span className="actionbtns center">
					<Icon
						link
						name="checkmark"
						color="green"
						onClick={e => {
							this.setState({ editing: false });
							onClick({ currentValue: this.state.selectedValue });
						}}
					/>
					<Icon
						link
						name="cancel"
						color="red"
						onClick={() => {
							this.setState({ editing: false });
						}}
					/>
				</span>
				<Dropdown
					id={id}
					placeholder="Select Primary Contact"
					search
					selection
					options={options}
					className="drop-down"
					onChange={(e, data) => {
						this.setState({ selectedValue: data.value.toString() });
					}}
				/>
			</div>
		);
	}
}
