export class WorkflowServiceCtrl {
	private getElapsed(running_since: any) {
		const now = new Date();
		let since = running_since;
		if (typeof since === "string") {
			const sin = new Date(parseInt(running_since, 10));
			since = sin.getTime();
		}
		return now.getTime() - since;
	}

	public getElapsedTime(running_since: any) {
		return this.getElapsed(running_since);
	}

	private getMsThreshold(dealer_phase) {
		if (dealer_phase) {
			const {
				warning_threshold_days: wDays,
				warning_threshold_hours: wHours,
				warning_threshold_minutes: wMinutes,
				error_threshold_days: eDays,
				error_threshold_hours: eHours,
				error_threshold_minutes: eMinutes
			} = dealer_phase;

			const wDayMS = wDays * 24 * 60 * 60 * 1000;
			const wHoursMS = wHours * 60 * 60 * 1000;
			const wMinutesMS = wMinutes * 60 * 1000;
			const wMS = wDayMS + wHoursMS + wMinutesMS;
			const eDayMS = eDays * 24 * 60 * 60 * 1000;
			const eHoursMS = eHours * 60 * 60 * 1000;
			const eMinutesMS = eMinutes * 60 * 1000;
			const eMS = eDayMS + eHoursMS + eMinutesMS;

			return { warning: wMS, error: eMS };
		}
	}

	public getThreshold(dealer_phases, detail: LMI.IInventoryDetailGQL): { warning: boolean; error: boolean; clear: boolean } {
		if (!dealer_phases || !detail) return { warning: false, error: false, clear: false };
		const msThresholds = this.getMsThreshold(dealer_phases.find(p => p.id === detail.current_phase));
		const elapsedMS = this.getElapsed(detail.phase_timer_running_since);
		if (msThresholds)
			return {
				warning: elapsedMS >= msThresholds.warning,
				error: elapsedMS >= msThresholds.error,
				clear: elapsedMS < msThresholds.warning && elapsedMS < msThresholds.error
			};
	}

	public getPhaseOwners({ dealer_phases, dealer_phasers, detail }): boolean {
		const phasers = [...dealer_phasers.job_title_assignments.map(j => j.phase_id), ...dealer_phasers.user_assignments.map(u => u.phase_id)];
		const hasOwners: number[] = phasers.filter(p => {
			return dealer_phases.map(p => p.id).includes(p);
		});
		return hasOwners.includes(detail.current_phase);
	}

	public isPhaseOwnerOrHasJobTitle({ dealer_phasers, detail, job_title_id }): boolean {
		const user = localStorage.getItem("userId");
		const assigned = dealer_phasers.user_assignments.filter(ua => ua.phase_id == detail.current_phase).map(user => user.user_id);
		const jobTitles = dealer_phasers.job_title_assignments.filter(ja => ja.phase_id == detail.current_phase).map(jta => jta.job_title_id);
		return (assigned && assigned.includes(user)) || (jobTitles && jobTitles.includes(job_title_id));
	}
}

export const WorkflowService = new WorkflowServiceCtrl();
