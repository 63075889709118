import { restAPI } from "../../../../../utils/rest";
import * as React from "react";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { gqlQueries } from "gql-imports";
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Popup,
  Segment,
} from "semantic-ui-react";
import { ModalComponent } from "../../ModalComponent";
import { DragDropListComponent } from "client/pages/admin/components/DragdropListComponent";
import { ChromePicker } from "react-color";
import SaniItemEditor from "./components/SaniItemEditor";
import SaniSettingEditor from "./components/SaniSettingEditor";
import {
  handleErrorResponse,
  handleSuccessResponse,
} from "client/utils/functions";
import { addAlert } from "api/redux/actions";
import { Loading } from "client/components/Loading";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

import "./SaniSettingsTabPanel.css";

@track(
  (props) => {
    return {
      event_type: AnalyticsEventType.SUBNAV,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }settings.sanitization`,
    };
  },
  { dispatchOnMount: true }
)
export class SaniSettingsTabPanelView extends React.Component<
  LMI.ISaniSettingsProps,
  LMI.ISaniSettingsState
> {
  constructor(props) {
    super(props);

    this.state = {
      openSettingEditor: false,
      editDashTheme: false,
      editSetting: null,
      openChecklistEditor: false,
      editChecklistItem: null,
      dashboardTitle: null,
      dashboardTheme: null,
    };
  }

  formatSettingName(name) {
    return name
      .replace(/_/g, " ")
      .toLowerCase()
      .split(" ")
      .map((w) => w[0].toUpperCase() + w.substr(1))
      .join(" ");
  }

  handleChangeComplete = (color, name) => {
    this.setState({ dashboardTheme: color.hex }, () =>
      this.updateSaniSetting({ name, value: color.hex })
    );
  };

  render() {
    const { loading, store_settings, store_sanitation_items } = this.props;
    const modalProps = this.getModalProps();

    const dashboardSettings = [
      "SANITIZATION_DASH_TITLE",
      "SANITIZATION_DASH_THEME",
    ];
    const dashSettings = store_settings
      ? store_settings.filter((ss) => dashboardSettings.includes(ss.name))
      : null;
    const basicSettings = store_settings
      ? store_settings.filter((ss) => !dashboardSettings.includes(ss.name))
      : null;

    if (loading) return <Loading />;

    return (
      <div id="sani-settings" className="panel-content">
        {modalProps && <ModalComponent {...modalProps} />}
        <Grid>
          <Grid.Column width={12}>
            <Header as="h3">
              <Image src={`/images/spray-bottle-black.png`} />
              <Header.Content>Sanitization Settings</Header.Content>
            </Header>
          </Grid.Column>
        </Grid>
        <Segment>
          <Header as="h4" content="Basic Settings" />
          {basicSettings.map((setting, key) => {
            const { store_setting_type, value, name } = setting;

            const isWarning = name === "SANITIZATION_WARNING_MINUTES";
            const isLocationSetting =
              name === "SANI_SHOW_LOCATION_SETTING_DIALOG";
            const sName = isLocationSetting
              ? "SHOW_LOCATION_SETTING_DIALOG"
              : name;

            const settingValue = JSON.parse(value);
            const settingName = this.formatSettingName(sName);

            return (
              <div key={key} className="sani-setting">
                <Popup
                  inverted
                  content={store_setting_type.label}
                  trigger={
                    isLocationSetting ? (
                      <Dropdown
                        compact
                        selection
                        selectOnBlur={false}
                        value={settingValue}
                        options={["Yes", "No"].map((o) => ({
                          key: o,
                          text: o,
                          value: o === "Yes" ? true : false,
                        }))}
                        onChange={(e, data) =>
                          this.updateSaniSetting({ name, value: data.value })
                        }
                      />
                    ) : (
                      <Label
                        color={isWarning ? "yellow" : "orange"}
                        onClick={() =>
                          this.setState({
                            openSettingEditor: true,
                            editSetting: { ...setting, ...{ settingName } },
                          })
                        }
                      >
                        <Icon link name="pencil" /> {`${settingValue} Minutes`}
                      </Label>
                    )
                  }
                />
                <span className="setting-name">{settingName}</span>
              </div>
            );
          })}
        </Segment>
        <Segment>
          <Header as="h4">
            Dashboard Settings{" "}
            <small>
              <a
                target="_blank"
                href={`${
                  process.env.REACT_APP_VEHICLE_DASH_URL
                }/${localStorage.getItem("selectedStoreCode")}`}
                rel="noreferrer"
              >
                <Icon name="eye" />
              </a>
            </small>
          </Header>
          {dashSettings.map((setting, key) => {
            const { store_setting_type, value, name } = setting;

            const isTheme = name === "SANITIZATION_DASH_THEME";
            const settingName = this.formatSettingName(name);

            return (
              <div key={key} className="sani-setting">
                {isTheme ? (
                  <Popup
                    trigger={
                      <span
                        className="color-preview"
                        style={{ backgroundColor: value }}
                      ></span>
                    }
                    content={() => (
                      <ChromePicker
                        disableAlpha={true}
                        color={
                          this.state.dashboardTheme
                            ? this.state.dashboardTheme
                            : value
                        }
                        onChangeComplete={(color) =>
                          this.handleChangeComplete(color, name)
                        }
                      />
                    )}
                    on="click"
                    style={{ padding: 0 }}
                    hideOnScroll
                  />
                ) : (
                  <Popup
                    inverted
                    content={store_setting_type.label}
                    trigger={
                      <Input
                        value={
                          this.state.dashboardTitle
                            ? this.state.dashboardTitle
                            : value
                        }
                        placeholder="Custom Dashboard Title"
                        onChange={(event) =>
                          this.setState({
                            dashboardTitle: event.currentTarget.value,
                          })
                        }
                        action={
                          this.state.dashboardTitle
                            ? {
                                color: "teal",
                                icon: "check",
                                onClick: () =>
                                  this.updateSaniSetting({
                                    name,
                                    value: this.state.dashboardTitle,
                                  }),
                              }
                            : false
                        }
                      />
                    }
                  />
                )}
                <span className="setting-name">{settingName}</span>
              </div>
            );
          })}
        </Segment>
        <Segment>
          <Header as="h4">
            <Button
              className="add-item-btn"
              content="Add Item"
              floated="right"
              size="mini"
              onClick={() => this.setState({ openChecklistEditor: true })}
            />
            Sanitization Checklist
          </Header>
          {!store_sanitation_items ||
          store_sanitation_items.items.length <= 0 ? (
            <Header
              content="There are currently no Sanitization items configured."
              as="h5"
              color="grey"
            />
          ) : (
            <div style={{ clear: "both" }}>
              <div className="tableTop">
                <Grid>
                  <Grid.Column width={2}>SORT/EDIT</Grid.Column>
                  <Grid.Column width={4}>NAME</Grid.Column>
                  <Grid.Column width={10}>DESCRIPTION</Grid.Column>
                </Grid>
              </div>
              <DragDropListComponent
                {...{
                  listName: "saniItemsList",
                  items: store_sanitation_items.items.map((item) => {
                    return {
                      item,
                      key: item.id,
                      name: item.name,
                      description: item.description,
                      className: item.is_active ? "" : "nonactiveitem",
                    };
                  }),
                  editable: true,
                  dragHandle: true,
                  editCallback: (editChecklistItem) =>
                    this.setState({
                      editChecklistItem,
                      openChecklistEditor: true,
                    }),
                  callback: (items) =>
                    this.orderChecklistItems(items.map((i) => i.item)),
                }}
              />
            </div>
          )}
        </Segment>
      </div>
    );
  }

  getModalProps() {
    const {
      editChecklistItem,
      openChecklistEditor,
      editSetting,
      openSettingEditor,
    } = this.state;
    const settingEditorProps: LMI.IModalProps = {
      size: "mini",
      headerText: editSetting ? editSetting.settingName : "Edit Setting",
      shouldBeOpen: openSettingEditor,
      onClose: () =>
        this.setState({ openSettingEditor: false, editSetting: null }),
      contentComponent: () => (
        <SaniSettingEditor
          {...{
            item: editSetting,
            onSave: (data) =>
              this.setState({ openSettingEditor: false }, () =>
                this.updateSaniSetting(data)
              ),
          }}
        />
      ),
    };
    const checklistEditorProps: LMI.IModalProps = {
      size: "mini",
      headerText: `${editChecklistItem ? "Edit" : "Add"} Sanitization Item`,
      shouldBeOpen: openChecklistEditor,
      onClose: () =>
        this.setState({ openChecklistEditor: false, editChecklistItem: null }),
      contentComponent: () => (
        <SaniItemEditor
          {...{
            item: editChecklistItem,
            onSave: (itemData) =>
              this.setState({ openChecklistEditor: false }, () =>
                this.createChecklistItem(itemData)
              ),
            onUpdate: (itemData) =>
              this.setState({ openChecklistEditor: false }, () =>
                this.updateChecklistItem(itemData)
              ),
          }}
        />
      ),
    };
    return openChecklistEditor
      ? checklistEditorProps
      : openSettingEditor
      ? settingEditorProps
      : null;
  }

  getAlertProps(err, res) {
    return {
      type: err ? "danger" : "success",
      message: err
        ? handleErrorResponse({ error: err })
        : handleSuccessResponse({ result: res }),
      timeout: 3000,
    };
  }

  updateSaniSetting(data: any) {
    restAPI({
      endpointName: "setStoreSettings",
      urlArgs: [this.props.storeId],
      data: { name: data.name, value: data.value.toString() },
      callback: (err, res) => {
        this.setState({ editSetting: null, dashboardTitle: null }, () => {
          this.props.addAlert(this.getAlertProps(err, res));
          this.props.refetchSettings();
        });
      },
    });
  }

  createChecklistItem(item: LMI.IStoreSaniItemsGQL) {
    const { name, description } = item;
    restAPI({
      endpointName: "createSanitizationChecklistItem",
      urlArgs: [this.props.storeId],
      data: { name, description },
      callback: (err, res) => {
        this.setState({ editChecklistItem: null }, () => {
          this.props.addAlert(this.getAlertProps(err, res));
          this.props.refetch();
        });
      },
    });
  }

  updateChecklistItem(item: LMI.IStoreSaniItemsGQL) {
    const { id, name, description, sort_order, is_active } = item;
    restAPI({
      endpointName: "updateSanitizationChecklistItem",
      urlArgs: [this.props.storeId, id],
      data: { name, description, sort_order, is_active },
      callback: (err, res) => {
        this.setState({ editChecklistItem: null }, () => {
          this.props.addAlert(this.getAlertProps(err, res));
          this.props.refetch();
        });
      },
    });
  }

  orderChecklistItems(items: LMI.IStoreSaniItemsGQL[]) {
    const orderedItems = { item_ids: items.map((i) => i.id) };
    restAPI({
      endpointName: "sortSanitizationChecklistItems",
      urlArgs: [this.props.storeId],
      data: orderedItems,
      callback: (err, res) => {
        this.props.addAlert(this.getAlertProps(err, res));
        this.props.refetch();
      },
    });
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const SaniSettingsTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IOrgSettingsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.settings.store,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            names: [
              "SANITIZATION_WARNING_MINUTES",
              "SANITIZATION_ERROR_MINUTES",
              "SANI_SHOW_LOCATION_SETTING_DIALOG",
              "SANITIZATION_DASH_THEME",
              "SANITIZATION_DASH_TITLE",
            ],
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_settings, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        return {
          store_settings,
          refetchSettings: refetch,
        };
      },
    }
  ),
  graphql<LMI.IStoreSaniItemsGQLProps, any, any, ClassAttributes<any>>(
    gqlQueries.settings.sani_checklist_items,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, store_sanitation_items, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        return {
          store_sanitation_items,
          refetch,
        };
      },
    }
  )
)(SaniSettingsTabPanelView) as React.ComponentType<any>;

export default SaniSettingsTabPanel;
