import track from "react-tracking";
// node_modules
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";

import { flowRight as compose } from "lodash";
import { AnalyticsEventType, TaskStatus } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { TasksListComponent } from "./components";

import "./TasksTabPanel.css";

@track(
	props => {
		return {
			event_type: props.tracking_path ? AnalyticsEventType.SUBNAV : AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}tasks`
		};
	},
	{ dispatchOnMount: true }
)
export class TasksTabPanelView extends React.Component<LMI.ITasksTabProps, LMI.ITasksTabState> {
	incompleted = [1, 2];
	completed = [3, 4];
	constructor(props: LMI.ITasksTabProps) {
		super(props);
		this.state = {
			showCompleted: false
		};
	}

	render() {
		const props = this.props;
		const { showCompleted } = this.state;
		const taskListProps = Object.assign({}, props, {
			showCompleted,
			taskStatuses: showCompleted ? this.completed : this.incompleted,
			toggleStatus: () => this.setState({ showCompleted: !showCompleted })
		});

		return (
			<div id="task-panel" className="panel-content">
				<TasksListComponent {...taskListProps} />
			</div>
		);
	}

	buildStoreTypes() {
		const allTasks: any = {
			text: "All Tasks",
			value: "all",
			icon: { name: "circle", color: "olive" }
		};
		const openTasks = {
			text: "Open Tasks",
			value: "open",
			icon: { name: "circle", color: "blue" }
		};
		const closedTasks = {
			text: "Closed Tasks",
			value: "closed",
			icon: { name: "circle", color: "orange" }
		};
		const rejected = {
			text: "Rejected",
			value: "reject",
			icon: { name: "circle", color: "red" }
		};
		const declined = {
			text: "Declined",
			value: "decline",
			icon: { name: "circle", color: "red" }
		};
		const taskStatus = [];
		for (const key in TaskStatus) {
			if (isNaN(parseInt(key, 10))) {
				taskStatus.push(key);
			}
		}
		const statusOptions = taskStatus.map((type, index) => {
			const val = TaskStatus[type];
			const color = type === "Unassigned" ? "yellow" : type === "Approved" ? "teal" : type === "Completed" ? "green" : "grey";
			return {
				text: type,
				value: val,
				icon: { name: "circle", color }
			};
		});
		statusOptions.unshift(allTasks, openTasks, closedTasks);
		statusOptions.push(rejected);
		if (this.props.viewType.includes("dealership")) {
			statusOptions.push(declined);
		}
		return statusOptions;
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const TasksTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.vendorsApproved, {
		skip: (ownProps: any) => ownProps.viewType.includes("vendor") || !ownProps.storeId,
		options: (props: any) => {

			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_approved_vendors, store_approved_vendor_services, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				store_approved_vendors,
				store_approved_vendor_services,
				refetch
			};
		}
	})
)(TasksTabPanelView) as React.ComponentType<any>;

export default TasksTabPanel;
