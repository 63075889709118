import * as React from "react";
import { Button, Divider, Icon, Label, Loader, Segment } from "semantic-ui-react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { gqlQueries } from "gql-imports";
import { graphql } from "@apollo/react-hoc";
import { InventoryResult, InvoiceResult, LeaseReturnResult } from "./ResultTypes";
import { toggleQuickSearch } from "api/redux/actions";
import { getPhaseColor, getViewTypeSub } from "client/utils/functions";
import { AnalyticsEventType, StoreType } from "loopmein-shared";
import track from "react-tracking";

export const SearchSegmentColors: any[] = ["blue", "pink", "orange", "grey"];
export enum QuickSearchResultTypes {
	"inventory" = 1,
	"leaseReturns" = 2,
	"invoices" = 3
}

@track(
	props => {
		return {
			event_type: AnalyticsEventType.SUBNAV,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}results`,
			data: { term: props.term }
		};
	},
	{ dispatchOnMount: false }
)
class QuickSearchResultsView extends React.Component<LMI.LmiQuickResultsProps, LMI.LmiQuickResultsState> {
	constructor(props: any) {
		super(props);

		this.state = {
			tabs: [],
			openTab: 0,
			noResults: false,
			loaded: false
		};
	}

	componentDidUpdate() {
		const results = this.props.inventory_results;
		if (results) {
			this.props.tracking.trackEvent({
				event_type: AnalyticsEventType.SUBNAV,
				event_subtype: `${this.props.tracking_path ? this.props.tracking_path + "." : ""}results`,
				data: {
					term: this.props.term,
					result_types: this.props.resultTypes,
					result_count: results.length,
					details: results.map(r => {
						return { vin: r.vin, stock_number: r.stock_number, year_make_model: `${r.year} ${r.make} ${r.model}` };
					})
				}
			});
		}
	}

	static getDerivedStateFromProps(nextProps: LMI.LmiQuickResultsProps, prevState: LMI.LmiQuickResultsState) {
		const nextState = { loaded: true, ...prevState };

		// frisk result types for results
		const frisk = (resultType: QuickSearchResultTypes, results: any[]) => {
			if (!results || results.length <= 0) nextState.tabs = nextState.tabs.filter(i => i !== resultType);
			else if (!prevState.tabs.includes(resultType)) nextState.tabs.push(resultType);
		};

		// check all search result types and derive tabs state with permissions
		frisk(QuickSearchResultTypes.inventory, nextProps.inventory_results);
		frisk(QuickSearchResultTypes.leaseReturns, nextProps.lease_returns);
		frisk(QuickSearchResultTypes.invoices, nextProps.invoice_results);

		// set open tab to first in list or lower in value(top)
		const tabLength = nextState.tabs.length;
		const getFirstTab = tabs => (tabLength > 1 ? Math.min.apply(Math, tabs) : tabs[0]);
		const setFirstTab = !nextState.openTab || !nextState.tabs.includes(prevState.openTab);
		if (setFirstTab) nextState.openTab = getFirstTab(nextState.tabs);

		// derive no results state
		nextState.noResults = nextState.tabs.length <= 0 ? true : false;

		return nextState;
	}

	getResultsTabs() {
		const { tabs, openTab, noResults } = this.state;

		if (noResults) return this.getNoResults();
		else
			return Object.keys(QuickSearchResultTypes)
				.filter(i => tabs.includes(QuickSearchResultTypes[i]))
				.map(type => {
					const section_key = QuickSearchResultTypes[type];
					const section_open = openTab === section_key;
					return [
						<div key="tab" className={`lmi-sections pad ${SearchSegmentColors[section_key - 1]}`}>
							<div
								className={`section-title ${section_open ? "needs-margin-bottom" : ""}`}
								onClick={() => this.setState({ openTab: openTab === section_key ? -1 : section_key })}
							>
								<span className="stitle">
									<Icon link name={section_open ? "angle down" : "angle right"} />
									{type.replace(/([A-Z])/g, " $1")}
								</span>
								<span className={`corner`}>
									<Label circular size="tiny">
										{this.getResults(section_key, true)}
									</Label>
								</span>
							</div>
						</div>,
						<div key="result" className={`results-list ${section_open ? "on" : "off"}`}>
							{this.getResults(section_key)}
						</div>
					];
				});
	}

	getResults(type: QuickSearchResultTypes, getCount = false) {
		const { inventory_results, lease_returns, invoice_results, dealer_phases } = this.props;
		switch (type) {
			case QuickSearchResultTypes.inventory:
				if (getCount) return inventory_results.length;
				return (
					<div className="search-res-list">
						{inventory_results.map(detail => {
							const resultDetail = { ...detail, ...{ current_phase_color: getPhaseColor(dealer_phases, detail.current_phase) } };
							return <InventoryResult key={detail.id} detail={resultDetail} onSelect={this.selectResult.bind(this)} />;
						})}
					</div>
				);
			case QuickSearchResultTypes.leaseReturns:
				if (getCount) return lease_returns.length;
				return (
					<div className="search-res-list">
						{lease_returns.map(detail => {
							return <LeaseReturnResult key={detail.id} detail={detail} onSelect={this.selectResult.bind(this)} />;
						})}
					</div>
				);
			case QuickSearchResultTypes.invoices:
				if (getCount) return invoice_results.length;
				return (
					<div className="search-res-list">
						{invoice_results.map(detail => {
							return <InvoiceResult key={detail.id} detail={detail} onSelect={this.selectResult.bind(this)} />;
						})}
					</div>
				);
			default:
				break;
		}
	}

	selectResult(deeplink: string) {
		// navigate to link passed from the result and close search
		this.props.history.push(`/admin/${getViewTypeSub()}/${deeplink}`);
		this.props.toggleQuickSearch(false);
	}

	getNoResults() {
		const { term } = this.props;
		return (
			<Segment className="no-results" color="red">
				<span className="no-result-text">
					Sorry, there were no results for <strong>{term}</strong>
					<br />
					<small>Please try another search or adjust search criteria.</small>
					<Button className="open-filter-btn" size="small" content="Open Filters" onClick={() => this.props.openFilters()} />
				</span>
			</Segment>
		);
	}

	resultLengthClass() {
		const { tabs } = this.state;
		const classList = ["sng", "dbl", "tpl", "qdr"];
		const index = tabs && tabs.length >= 1 ? tabs.length - 1 : 0;
		return classList[index];
	}

	render() {
		const { loading, hasErrors } = this.props;
		const { noResults } = this.state;

		if (hasErrors) return <Segment color="red" content="We're sorry, there was a problem. Try again later." />;
		if (loading) return <Loader active />;

		return (
			<Segment id="QuickSearchResults" className={`search-results ${this.resultLengthClass()}`}>
				{!noResults && (
					<Divider horizontal className="result-divider">
						<Icon name="search" />
						SEARCH RESULTS
					</Divider>
				)}
				<div className="results-container">{this.getResultsTabs()}</div>
			</Segment>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		userType: state.app.admin.userType,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	toggleQuickSearch: open => {
		dispatch(toggleQuickSearch(open));
	}
});

const QuickSearchResults = compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventorySearchPartDuex, {
		skip: (ownProps: LMI.LmiQuickResultsProps) =>
			!ownProps.resultTypes.includes(QuickSearchResultTypes.inventory) || ownProps.userType !== StoreType.Dealership,
		options: (props: LMI.LmiQuickResultsProps) => {
			return {
				variables: {
					store_ids: parseInt(props.storeId, 10),
					search_text: props.term ? props.term.trim() : null,
					phase: null,
					sold: null,
					limit: props.inTour ? 5 : 50
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inventory_search2, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				inventory_results: inventory_search2.items,
				refetchInventory: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.phases, {
		skip: (ownProps: LMI.LmiQuickResultsProps) =>
			!ownProps.resultTypes.includes(QuickSearchResultTypes.inventory) || ownProps.userType !== StoreType.Dealership,
		options: (props: LMI.LmiQuickResultsProps) => {
			return {
				variables: {
					// Default to BOTH new and used by omitting hasNew, hasUsed vars here
					storeId: parseInt(props.storeId, 10),
					hasNew: null,
					hasUsed: null
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, dealer_phases, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				dealer_phases: dealer_phases.phases,
				refetch_recon: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.leaseReturns, {
		skip: (ownProps: LMI.LmiQuickResultsProps) =>
			!ownProps.resultTypes.includes(QuickSearchResultTypes.leaseReturns) || ownProps.userType !== StoreType.Dealership,
		options: (props: LMI.LmiQuickResultsProps) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					term: props.term,
					completed: false,
					limit: props.inTour ? 5 : 50
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, lease_returns, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				lease_returns: lease_returns.lease_returns,
				refetchLeaseReturns: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.allInvoicesWithTotals, {
		skip: (ownProps: LMI.LmiQuickResultsProps) => !ownProps.resultTypes.includes(QuickSearchResultTypes.invoices) || ownProps.userType !== StoreType.Dealership,
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					term: props.term,
					limit: props.inTour ? 5 : 50
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, dealer_invoices_with_totals, dealer_approvals, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				invoice_results: [...dealer_invoices_with_totals.invoices, ...dealer_approvals],
				refetchInvoices: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.vendor.invoices, {
		skip: (ownProps: LMI.LmiQuickResultsProps) => !ownProps.resultTypes.includes(QuickSearchResultTypes.invoices) || ownProps.userType === StoreType.Dealership,
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					term: props.term,
					limit: props.inTour ? 5 : 50
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, vendor_invoices, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				invoice_results: vendor_invoices,
				refetchInvoices: refetch
			};
		}
	})
)(QuickSearchResultsView) as React.ComponentType<any>;

export default QuickSearchResults;
