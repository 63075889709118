import * as React from "react";
import { Popup } from "semantic-ui-react";

export class DetailTotalTimer extends React.Component<LMI.ITotalTimerProps, LMI.ITotalTimerState> {
	constructor(props: LMI.ITotalTimerProps) {
		super(props);

		this.state = {
			loading: false,
			elapsed_time: null,
			work_elapsed_time: null,
			running: false
		};
	}

	checkInterval() {
		if ((window as any).totalInterval) clearInterval((window as any).totalInterval[`${this.props.id}${this.props.current_phase}`]);
		else (window as any).totalInterval = {};
	}

	componentDidMount() {
		const { id, current_phase, phase_timer_running_since_ms, total_phase_elapsed_ms, dispositioned_at } = this.props;

		this.checkInterval();

		if (id) {
			const now = new Date();

			let elapsed;
			if (dispositioned_at) {
				elapsed = total_phase_elapsed_ms;
			} else if (phase_timer_running_since_ms > 0) {
				// New session, elapsed time not recoreded yet, so calculate it
				if (total_phase_elapsed_ms === 0) {
					elapsed = now.getTime() - phase_timer_running_since_ms;
				} else elapsed = total_phase_elapsed_ms > 0 ? total_phase_elapsed_ms + now.getTime() - phase_timer_running_since_ms : 0;
			} else {
				elapsed = total_phase_elapsed_ms;
			}

			this.setState({ loading: true, elapsed_time: elapsed, running: false }, () => {
				if (!dispositioned_at && phase_timer_running_since_ms) {
					const interval = 1000;
					(window as any).totalInterval[`${id}${current_phase}`] = setInterval(() => {
						this.setState(prevState => ({ running: true, elapsed_time: prevState.elapsed_time + interval }));
					}, interval);
				}
			});
		}
	}

	componentWillUnmount() {
		this.checkInterval();
	}

	calculateElapsedTime(elapsedTime) {
		let ms = elapsedTime;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = Math.floor(ms / 24);
		return {
			days: days.toString().padStart(2, "0"),
			hours: hours.toString().padStart(2, "0"),
			minutes: minutes.toString().padStart(2, "0"),
			seconds: seconds.toString().padStart(2, "0")
		};
	}

	calculateElapsedMinimum(elapsedTime) {
		let ms = elapsedTime;
		const { useFull } = this.props;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = Math.floor(ms / 24);

		if (days > 0) return days + ` D${useFull ? `ay${days > 0 ? "s" : ""}` : ""}`;
		if (hours > 0) return hours + ` H${useFull ? `our${hours > 0 ? "s" : ""}` : ""}`;
		if (minutes > 0) return minutes + ` M${useFull ? `inute${minutes > 0 ? "s" : ""}` : ""}`;
		if (seconds > 0) return `<1 M${useFull ? `inute${minutes > 0 ? "s" : ""}` : ""}`;

		return `0 M${useFull ? "inutes" : ""}`;
	}

	render() {
		const { elapsed_time } = this.state;
		const time = this.calculateElapsedMinimum(elapsed_time);
		const popTime = this.calculateElapsedTime(elapsed_time);

		return (
			<Popup
				inverted
				position="bottom right"
				trigger={<span key={elapsed_time}>{time}</span>}
				content={`Total Recon Time: ${popTime.days}:${popTime.hours}:${popTime.minutes}:${popTime.seconds}`}
			/>
		);
	}
}
