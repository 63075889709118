import { Grid, Icon } from "semantic-ui-react";
import * as React from "react";
import { OldestTimer } from "./OldestTimer";
import { AverageTimer } from "./AverageTimer";

export const Card = props => {
	const { storeId, order, id, description, name, color_code, onOpenDetail, inventory, reconIntervalAverages, final_stage, thresholds, isUp } = props;
	const timerProps = {
		...props,
		store_id: storeId,
		onHandleErrorResponse: error => props.handleErrorResponse(error),
		onAlert: alert => props.sendAlert(alert)
	};

	return (
		<Grid.Column className={`card-column`} onClick={inventory[0] ? onOpenDetail : undefined} data-name={name} data-order={order} data-id={id}>
			<div id={`card-${id}`} style={{ boxShadow: "0 -3px 0 0 " + color_code }} className={`ui card`} data-order={order} data-id={id}>
				<div className="content top">
					<div className="header colored">
						<span className="name">{name}</span>
						<div className="badge">
							{isUp && <Icon name="arrow up" className={`showHide`} />}
							{!isUp && isUp !== null && <Icon name="arrow down" className={`showHide`} />}
							{inventory[0] ? inventory.length : 0}
						</div>
					</div>
				</div>
				<div className="content">
					<h4 className="ui sub header">{description}</h4>
					<div className="ui small feed">
						<div className="event">
							<div className="content">
								<div className="summary">
									<Grid>
										<Grid.Column className="title" width={7}>
											OLDEST:
										</Grid.Column>
										<Grid.Column className="text" width={9}>
											<OldestTimer key={id} {...timerProps} />
										</Grid.Column>
									</Grid>
								</div>
							</div>
						</div>
						<div className="event">
							<div className="content">
								<div className="summary">
									<Grid>
										<Grid.Column className="title" width={7}>
											AVERAGE:
										</Grid.Column>
										<Grid.Column className="text" width={9}>
											<AverageTimer key={id} {...timerProps} />
										</Grid.Column>
									</Grid>
								</div>
							</div>
						</div>
						{reconIntervalAverages ? (
							!final_stage ? (
								<div className="event">
									<div className="content">
										<div className="summary">
											<Grid>
												<Grid.Column className="title" width={7}>
													30 DAY AVG:
												</Grid.Column>
												<Grid.Column
													className={`text ${thresholds && thresholds.error ? "error" : thresholds && thresholds.warning ? "warning" : ""}`}
													width={9}
												>
													{reconIntervalAverages.elapsed.days.toString().padStart(2, "0")}
													<span className="lowlight">d</span> {reconIntervalAverages.elapsed.hours.toString().padStart(2, "0")}
													<span className="lowlight">h</span> {reconIntervalAverages.elapsed.minutes.toString().padStart(2, "0")}
													<span className="lowlight">m</span>
												</Grid.Column>
											</Grid>
										</div>
									</div>
								</div>
							) : (
								<div className="event">
									<div className="content">
										<div className="summary">
											<Grid>
												<Grid.Column className="title" width={7}>
													30 DAY AVG:
												</Grid.Column>
												<Grid.Column
													className={`text ${thresholds && thresholds.error ? "error" : thresholds && thresholds.warning ? "warning" : ""}`}
													width={9}
												>
													{reconIntervalAverages.elapsed.days.toString().padStart(2, "0")}
													<span className="lowlight">d</span>
												</Grid.Column>
											</Grid>
										</div>
									</div>
								</div>
							)
						) : !final_stage ? (
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid>
											<Grid.Column className="title" width={7}>
												30 DAY AVG:
											</Grid.Column>
											<Grid.Column className="text reloading" width={9}>
												00<span className="lowlight">d</span> 00<span className="lowlight">h</span> 00<span className="lowlight">m</span>
											</Grid.Column>
										</Grid>
									</div>
								</div>
							</div>
						) : (
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid>
											<Grid.Column className="title" width={7}>
												30 DAY AVG:
											</Grid.Column>
											<Grid.Column className="text" width={9}>
												00<span className="lowlight">d</span>
											</Grid.Column>
										</Grid>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</Grid.Column>
	);
};
