import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";

import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { restAPI } from "../../../../../../../utils/rest";
import { handleErrorResponse } from "../../../../../../../utils/functions";

import { Button, Container, Select, TextArea } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import { Loading } from "../../../../../../../components/Loading/Loading";

export class PhaseFormView extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {};
	}

	getDealerPhaseOptions = () => {
		let nextPhase;
		const { detail, dealer_phases } = this.props;
		const phase = dealer_phases.find(phase => phase.id === detail.current_phase);
		const currentPhase = phase ? phase : { id: detail.current_phase };
		const options = dealer_phases.map(phase => ({ key: phase.id, value: phase.id, text: phase.name })).filter(phase => currentPhase.id !== phase.key);
		const phasesWithThisReconLevel = dealer_phases.filter((p: any) => p.recon_levels && p.recon_levels.includes(detail.recon_level_id)).map(pp => pp.id);
		for (const p of dealer_phases) {
			// console.log("Get Next Phase", p.id, p.order, phase.order, phasesWithThisReconLevel.indexOf(p.id), p);
			if (p.order > currentPhase.order && phasesWithThisReconLevel.indexOf(p.id) >= 0) {
				nextPhase = p;
				break;
			}
		}
		if (!nextPhase) nextPhase = { id: null, name: null };
		return { options, nextPhase };
	};

	handleSelectPhase(data: any) {
		this.setState({ selected_phase: data.value });
	}

	selectedPhase = selectedPhaseId => {
		return this.props.dealer_phases.find(p => p.id === selectedPhaseId);
	};

	handleSubmitCompletedPhase = () => {
		const { storeId, detail } = this.props;
		const { comment, selected_phase } = this.state;
		let current_phase = selected_phase;
		if (!current_phase) {
			const phaseOptions = this.getDealerPhaseOptions();
			current_phase = phaseOptions.nextPhase.id;
		}
		const data = { current_phase, comment };
		restAPI({
			endpointName: "updateInventoryPhase",
			urlArgs: [storeId, detail.id],
			data,
			callback: async error => {
				if (error) this.props.onAlert({ type: "danger", message: handleErrorResponse(error) });
				else {
					if (this.props.completedPhase) this.props.completedPhase();
					else this.props.onClose();
				}
			}
		});
	};

	handleChangeComment = event => {
		this.setState({ comment: event.currentTarget.value });
	};

	render() {
		const { comment, selected_phase } = this.state;
		const { loading } = this.props;

		if (loading)
			return (
				<Container>
					<Loading />
				</Container>
			);

		if (this.props.dealer_phases.length > 0) {
			const { options, nextPhase } = this.getDealerPhaseOptions();
			const selectedPhase = this.selectedPhase(selected_phase);

			return (
				<Container className="modal-form">
					<Form noValidate onSubmit={this.handleSubmitCompletedPhase.bind(this)}>
						<div>{nextPhase.id ? `Move this vehicle to ${nextPhase.name} or select a different stage below:` : `Select the next stages for this vehicle:`}</div>
						{selected_phase && selectedPhase.final_stage && (
							<div id="selectedPhaseFinal">
								Reconditioning is complete if you choose{" "}
								<b>
									<u>{selectedPhase.name}</u>
								</b>
								, which is a <i>Final Disposition</i>, this also means that all timers will be stopped for this vehicle.
							</div>
						)}
						<div id="phaseBody" className="recon-form">
							<Select
								id="selectPhase"
								search
								className="select more-opts"
								placeholder="Select the next phase"
								options={options}
								defaultValue={nextPhase.id}
								onChange={(event, data) => this.handleSelectPhase(data)}
							/>
							<TextArea id="comment" rows={4} placeholder="Optional Vehicle Note" value={comment} onChange={this.handleChangeComment.bind(this)} />
							<div className="submit-block">
								<Button
									id="complete"
									positive
									content={selected_phase && selectedPhase.final_stage ? `Disposition Vehicle as ${selectedPhase.name}` : "Change Stage"}
								/>
							</div>
						</div>
					</Form>
				</Container>
			);
		} else {
			return <span></span>;
		}
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const PhaseForm = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.phases, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					currentPhaseId: props.detail.current_phase ? props.detail.current_phase : null,
					hasNew: null,
					hasUsed: null
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, dealer_phases, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				dealer_phases: dealer_phases.phases,
				dealer_phasers: dealer_phases.phasers,
				refetchRecon: refetch
			};
		}
	})
)(PhaseFormView) as React.ComponentType<any>;

export default PhaseForm;
