import * as React from "react";
import "react-image-crop/dist/ReactCrop.css";

import "./TabsBadgeComponent.css";

export class TabsBadgeComponent extends React.Component<LMI.IBadgeProps, LMI.IBadgeState> {
	constructor(props: LMI.IBadgeProps) {
		super(props);

		// ToDo: We need to extend this to accept a "badges" array of badge configuration objects so that we can
		// define a group of badges for a page and we will define the selectors for the badge parent nodes (or sthg)
		this.state = {
			badge: props.badge ? props.badge : null
		};
	}

	componentDidMount() {
		const { badge } = this.state;
		const { id, count, side, existing_badge_selector, badge_tab_bar_selector, color, background_color } = badge;

		const existingBadge = document.querySelectorAll(existing_badge_selector);
		if (existingBadge && existingBadge.length > 0) existingBadge[0].parentNode.removeChild(existingBadge[0]);

		if (badge && badge.count) {
			const tabs = document.querySelectorAll(badge_tab_bar_selector);
			const badgeTab = Array.from(tabs, t => t).find(t => (t as HTMLElement).innerText.toLowerCase() === this.state.badge.id.toLowerCase());
			const badge = document.createElement("div");

			badge.className = side && (side === "left" || side === "right") ? `tabs-badge ${side}` : "tabs-badge left";
			badge.id = id;
			badge.innerText = String(count);
			badge.style.color = color;
			badge.style.backgroundColor = background_color;
			badgeTab.appendChild(badge);
		}
	}

	render() {
		return null;
	}
}

export default TabsBadgeComponent;
