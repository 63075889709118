// node_modules
import * as React from "react";
import { escapeRegExp, reduce, filter, debounce } from "lodash";
// components
import { Search, Icon } from "semantic-ui-react";
import "./StoreSelectorComponent.css";

export class StoreSelectorComponent extends React.Component<LMI.IStoreSelectorProps, LMI.IStoreSelectorState> {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			results: null,
			value: null,
			open: false
		};
	}

	formatData = data => {
		// group results by organization id and build data for the search component
		const groupByProp = (xs, key) => {
			return (
				xs &&
				xs.reduce((rv, x) => {
					(rv[x[key]] = rv[x[key]] || []).push({
						key: x.id,
						id: x.id,
						title: x.name,
						description: `Store Code: ${x.store_code}`
					});
					return rv;
				}, {})
			);
		};
		const groupByOrganization = (stores, property) => {
			const groupedItems = groupByProp(stores, property);
			const data: any = {};
			for (const g in groupedItems) {
				const results = groupedItems[g];
				const item = this.props.items.find(i => i.id === results[0].id);
				data[item.organization.name] = {
					id: item.id,
					name: item.organization.name,
					results
				};
			}
			return data;
		};
		return groupByOrganization(data, "organization_id");
	};

	render() {
		const { isLoading, value, results, open } = this.state;
		const stores = this.formatData(this.props.items);
		const showPlaceHolder = !value && this.props.placeholder && !this.state.open;
		const inputsize = this.props.size ? this.props.size : null;
		return (
			<div id="store-selector">
				{showPlaceHolder && (
					<span className={`placeholder ${inputsize}`} onClick={() => this.clickPH()}>
						{this.props.placeholder}
					</span>
				)}
				{value && value.length > 3 && (
					<Icon
						name="close"
						className={`close red ${inputsize}`}
						onClick={() => {
							this.props.onSelection();
							this.setState({ value: null, open: false });
						}}
					/>
				)}
				<Search
					id={this.props.selectorId}
					category
					open={open}
					size={inputsize}
					icon="caret down"
					loading={isLoading}
					className="dropdown"
					value={value ? value : "  "}
					results={results ? results : stores}
					onResultSelect={this.onSelected}
					onSearchChange={debounce(this.onChange, 500, { leading: true })}
					onFocus={() => this.setState({ open: true, value: "  " })}
					onBlur={() => {
						setTimeout(() => {
							const value = this.state.value ? this.state.value.trim() : "";
							this.setState({
								open: false,
								value,
								results: stores
							});
						}, 200);
					}}
				/>
			</div>
		);
	}

	// special placeholder method to integrate span overlaying the search module
	clickPH() {
		this.setState({ open: true });
		const searchy: any = document.getElementById(this.props.selectorId);
		searchy.focus();
	}

	resetComponent = () => this.setState({ isLoading: false, results: null, value: null });

	onSelected = (e, { result }) => {
		const selectedItem = this.props.items.find(i => i.id === result.id);
		this.props.onSelection(selectedItem);
		this.setState({ value: result.title, open: false });
	};

	onChange = (e, { value }) => {
		this.setState({ isLoading: true, value });
		setTimeout(() => {
			if (this.state.value.length < 1) return this.resetComponent();
			const re = new RegExp(escapeRegExp(this.state.value.trim()), "i");
			const isMatch = result => re.test(result.title) || re.test(result.description);
			const alldata = this.formatData(this.props.items);
			const filteredResults = reduce(
				alldata,
				(all, data, name) => {
					const results = filter(data.results, isMatch);
					if (results.length) all[name] = { name, results };
					return all;
				},
				{}
			);
			this.setState({
				isLoading: false,
				results: filteredResults !== null ? filteredResults : alldata
			});
		}, 300);
	};
}
