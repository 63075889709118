// node_modules
import * as React from "react";
import { ClassAttributes } from "react";
import { Dropdown, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { flowRight as compose } from "lodash";

export class EditStageView extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			selected: null,
			dirty: false
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { detail, stages } = nextProps;
		if (detail && stages && detail.current_phase) {
			if (prevState.selected !== detail.current_phase) {
				const selected = stages ? stages.find(stage => stage.id === detail.current_phase) : null;
				return { selected: selected ? selected.id : null };
			}
		}
		return null;
	}

	render() {
		const { assigned_stages, onSubmit, loading, readonly } = this.props;
		const { selected } = this.state;

		if (!assigned_stages || loading)
			return (
				<div className="detail-selectors">
					<Loader className={`loader active`} size="tiny" />
				</div>
			);

		let stageName;
		const stageOptions = [
			{ key: null, text: "Select Stage", value: null },
			...assigned_stages.map((rl, key) => {
				if (selected && selected === rl.id) stageName = rl.name;
				return {
					key,
					text: rl.name,
					value: rl.id
				};
			})
		];
		if (!stageName) stageName = "None Selected";

		return (
			<span className="detail-selectors">
				<span className="attached-label">STAGE</span>
				{!readonly ? (
					<Dropdown
						className="selectorsy"
						placeholder="Select Stage"
						search
						selection
						value={selected}
						options={stageOptions}
						onChange={(e, data) => onSubmit(data.value)}
					/>
				) : (
					<span className="readonly">{stageName}</span>
				)}
			</span>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const EditStage = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.phases, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.detail.store_id, 10),
					currentPhaseId: props.detail.current_phase
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, dealer_phases, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				is_target_stages: dealer_phases.is_target_stages,
				assigned_stages: dealer_phases.phases,
				refetchAssignedPhases: refetch
			};
		}
	})
)(EditStageView) as React.ComponentType<any>;

export default EditStage;
