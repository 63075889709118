import { restAPI } from "../../../utils/rest";
import { Form, Input } from "formsy-semantic-ui-react";
import { login } from "client/utils/userAccess";
import * as React from "react";
import {
  Button,
  Container,
  Header,
  Image,
  Label,
  Message,
} from "semantic-ui-react";
import { handleErrorResponse } from "../../../utils/functions";
import { Session } from "client/utils/session";

import "./ChangePassword.css";

export class ChangePasswordPage extends React.Component<
  LMI.ICPProps,
  LMI.ICPState
> {
  constructor(props) {
    super(props);
    this.state = {
      formData: null,
      show_message: false,
      message_header: "",
      message: "",
      message_type: "red",
      success: null,
      new_password: null,
    };
  }

  onContinue = () => {
    login(Session.get("email"), this.state.new_password);
  };

  render() {
    const {
      success,
      show_message,
      message_header,
      message_type,
      message,
    } = this.state;
    const errorLabel = <Label color="red" pointing="above" />;

    const messageComponent = show_message ? (
      <Message color={message_type}>
        <Message.Header>{message_header}</Message.Header>
        {message}
      </Message>
    ) : null;

    return (
      <Container className="change-password-page page">
        <Container className="change-password-block">
          <Image src="/images/LoopMeIn_LOGO_FINAL.svg" alt="" size="small" />
          <Header as="h1">Change your password</Header>
          {show_message && messageComponent}
          {success ? (
            <Button positive className="continue-btn" onClick={this.onContinue}>
              Continue
            </Button>
          ) : (
            <Form
              noValidate
              onValidSubmit={(formData, reset) => {
                this.onSubmitHandler(formData, reset);
                this.setState({
                  show_message: true,
                  message_header: "Please Wait",
                  message: "Changing password...",
                  message_type: "blue",
                });
              }}
            >
              <Form.Field id="old_password">
                <label>Old Password:</label>
                <Input
                  name="old_password"
                  type="password"
                  autoComplete="false"
                  required
                  validations="isExisty"
                  validationErrors={{
                    isExisty: "Must not be empty",
                    isDefaultRequiredValue: "Old Password is required",
                  }}
                  errorLabel={errorLabel}
                />
              </Form.Field>
              <Form.Field id="new_password">
                <label>New Password:</label>
                <Input
                  name="new_password"
                  type="password"
                  autoComplete="false"
                  required
                  validations="isExisty,minLength:8"
                  validationErrors={{
                    isExisty: "Must not be empty",
                    minLength: "Password must be a minimum of 6 characters.",
                    isDefaultRequiredValue: "New Password is required",
                  }}
                  errorLabel={errorLabel}
                />
              </Form.Field>
              <Form.Field id="new_password_confirm">
                <label>Confirm New Password:</label>
                <Input
                  name="new_password_confirm"
                  type="password"
                  autoComplete="false"
                  required
                  validations="isExisty,equalsField:new_password"
                  validationErrors={{
                    isExisty: "Must not be empty",
                    equalsField: "Must match the new password",
                    isDefaultRequiredValue:
                      "Confirming the new password is required",
                  }}
                  errorLabel={errorLabel}
                />
              </Form.Field>
              <Button positive className="submit-btn" type="submit">
                Change Password
              </Button>
            </Form>
          )}
        </Container>
        <Container className="message" textAlign="center">
          <div>
            <strong>Need help?</strong> You can contact your store administrator
            or{" "}
            <a
              href="https://support.loopmein.app/en"
              target="_blank"
              rel="noreferrer"
            >
              contact us online
            </a>{" "}
            or voice at 435-527-LOOP.
          </div>
        </Container>
      </Container>
    );
  }

  onSubmitHandler(formData, reset) {
    console.log("change password form submitted", formData);
    reset();
    this.setState({ new_password: formData.new_password });
    restAPI({
      endpointName: "changeUserPassword",
      urlArgs: null,
      data: {
        old_password: formData.old_password,
        new_password: formData.new_password,
        new_password_confirm: formData.new_password_confirm,
      },
      callback: (error, result) => {
        if (!error) {
          this.setState({
            show_message: true,
            message_header: "Success!",
            message:
              "Your password was changed successfully.  Please standby while we reload your session.",
            message_type: "green",
            success: true,
          });
          const email = Session.get("email");
          setTimeout(() => login(email, this.state.new_password), 2000);
        } else {
          console.error(error);
          this.setState({
            show_message: true,
            message_header: "Failure!",
            message: handleErrorResponse({
              error,
              message: "Password change attempt failed.",
            }),
            message_type: "red",
            success: false,
          });
        }
      },
    });
  }
}

export default ChangePasswordPage;
