import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { gqlQueries } from "gql-imports";

export const DataSyncQueries = {
	inventory: store_ids => ({
		query: gqlQueries.dealership.allInventory,
		variables: { store_ids }
	})
};

export const watchDataSync = (name: string, client: ApolloClient<NormalizedCacheObject>, sync: DataSyncQuery) => {
	const syncedQuery = client.watchQuery(sync);
	syncedQuery.subscribe(result => console.info(`DATA SYNCED(${name}):`, sync.variables, result.data));
};

export const modifyCacheData = (sync: DataSyncQuery, client: ApolloClient<NormalizedCacheObject>, modifier: (any) => any) => {
	const cached = client.cache.readQuery(sync);
	const data = modifier(cached);
	if (data) client.cache.writeQuery({ query: sync.query, data });
};

export interface DataSyncQuery {
	query: any;
	variables: any;
}
