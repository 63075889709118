import { restAPI } from "../../utils/rest";
import { withRouter } from "react-router";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { Image, Container, Divider, Segment, Header, Icon, Button, Grid, Card, Popup, Input, Checkbox } from "semantic-ui-react";
import { formatCurrency, formatPhone, RelativeDatePipe } from "client/utils/functions";
import { Login } from "../Login";
import { confirmDefault, ConfirmDialog } from "client/components/ConfirmDialog";
import "./Invoice.css";

export class POInvoiceToolView extends React.Component<any, any> {
	invoice: LMI.IInvoiceDetail = null;
	constructor(props: any) {
		super(props);
		this.state = {
			is_paid: false,
			po_number: "",
			loggedIn: false,
			confirm: null,
			submitError: null,
			submitSuccess: null
		};
	}

	_submitPOForm = () => {
		restAPI({
			endpointName: "updateDealerInvoice",
			urlArgs: [parseInt(this.props.match.params.storeId, 10), parseInt(this.props.match.params.id, 10)],
			data: { po_number: this.state.po_number, is_paid: this.state.is_paid },
			callback: (err, res) => {
				if (err) {
					console.log(err);
					this.setState({ submitError: err });
				} else {
					this.setState({ submitSuccess: true });
				}
			}
		});
	};

	loginCallback = () => {
		this.setState({ loggedIn: true });
		window.location.reload();
	};

	render() {
		const { loading, invoice_detail, hasErrors } = this.props;
		const { confirm, submitError, submitSuccess } = this.state;
		const invoiceId = this.props.match && this.props.match.params.id ? this.props.match.params.id : null;
		if (!invoiceId || hasErrors || submitError) return this.getNoInvoiceIdParamMessage();
		if (submitSuccess) return this.getChangesSubmittedMessage();
		if (invoice_detail && invoice_detail.is_received) return this.getHasAlreadyCompletedMessage();

		// check for token and return login if needed
		const loggedIn = localStorage.getItem("token");
		const loginProps = { ...this.props, ...{ norefresh: true, loggedInCallback: () => this.loginCallback() } };
		if (!loggedIn) return <Login {...loginProps} />;

		this.invoice = invoice_detail;
		return (
			<Container id="POInvoiceTool">
				<ConfirmDialog {...confirm} />
				<Divider hidden />
				<Grid centered>
					<Grid.Column>
						<Segment className="invoiceContainer" loading={loading}>
							{loading || !invoice_detail ? this.getloadingPlaceHolder() : this.getInvoicePOForm()}
						</Segment>
					</Grid.Column>
				</Grid>
			</Container>
		);
	}

	getInvoicePOForm = () => {
		const { provider_store } = this.invoice;
		return (
			<div className="invoiceForm">
				<div className="headerContent">
					<Button content="Admin Portal" floated="right" onClick={() => this.goDashboard()} />
					{this.invoice.customer_store.logo_url ? (
						<Image size="tiny" src={this.invoice.customer_store.logo_url} floated="left" loading="lazy" />
					) : (
						<Header content="Invoice" as="h1" />
					)}
				</div>
				<Divider />
				<Grid>
					<Grid.Column width={9} className="invoice-info">
						<span>
							INVOICE# <strong>{this.invoice.invoice_number}</strong>
						</span>
						<span>
							TOTAL <strong>{formatCurrency(parseInt(this.invoice.total, 10), 2)}</strong>
						</span>
						<span>
							<Popup trigger={<Icon name="pencil square" color="blue" />}>
								<span className="approvedSig">Approved Signature</span>
								<Image className="approvedSigImage" src={this.invoice.approved_signature_url} size="medium" loading="lazy" />
							</Popup>
							APPROVED BY<strong>{`${this.invoice.approved_by_user.full_name} on ${RelativeDatePipe(this.invoice.approved_at, false, true)}`}</strong>
						</span>
					</Grid.Column>
					<Grid.Column width={7} className="deet">
						<Card.Group centered>
							<Card>
								<Card.Content>
									<strong>FROM</strong>
									<br />
									{provider_store.logo_url ? <Image floated="left" size="tiny" src={provider_store.logo_url} loading="lazy" /> : ""}
									<Card.Header>{provider_store.name}</Card.Header>
									<Card.Meta>{formatPhone(provider_store.phone)}</Card.Meta>
									<Card.Description>
										{provider_store.address1}
										{provider_store.address2}
										<br />
										{`${provider_store.city}, ${provider_store.state} ${provider_store.zip}`}
									</Card.Description>
								</Card.Content>
							</Card>
						</Card.Group>
					</Grid.Column>
				</Grid>
				<Divider />
				<Header as="h4" content="Please issue a PO number for this Invoice by filling out the form below and clicking submit." />
				<Grid centered columns={3}>
					<Grid.Column width={8}>
						<br />
						<label>PO NUMBER</label>
						<Input
							fluid
							placeholder="Enter a PO Number"
							defaultValue={this.invoice.po_number}
							onChange={(e, data) => this.setState({ po_number: data.value })}
						/>
						<Checkbox label="IS PAID" toggle defaultChecked={this.invoice.is_paid} onChange={(e, data) => this.setState({ is_paid: data.checked })} />
						<Button
							fluid
							content="SUBMIT"
							positive
							disabled={!this.state.po_number}
							onClick={() => {
								this.setState({
									confirm: {
										open: true,
										title: "Submit PO for this Invoice?",
										text: "Please confirm you would like to submit this Invoice PO. This action cannot be undone",
										success: () => this._submitPOForm(),
										failure: () => this.setState({ confirm: confirmDefault })
									}
								});
							}}
						/>
					</Grid.Column>
				</Grid>
				<Divider hidden />
			</div>
		);
	};

	goDashboard = () => {
		this.props.history.push("/admin/dealerships");
		window.location.reload();
	};

	goLogin = () => {
		this.props.history.push("/login");
		window.location.reload();
	};

	getloadingPlaceHolder = () => {
		return <Image className="i-header" src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />;
	};

	getNoInvoiceIdParamMessage = () => {
		const { hasErrors, message } = this.props;
		const { submitError } = this.state;
		return (
			<Container id="POInvoiceTool">
				<Divider hidden />
				<Segment className="invoiceContainer" placeholder>
					<Header icon>
						<Icon name="attention" color="red" />
						There was a problem {submitError ? "saving" : "loading"} this Invoice!
						<Header.Subheader content={hasErrors && message ? message : "Please contact your account administrator."} />
					</Header>
					<Button primary content="Go to Login" onClick={() => this.goLogin()} />
				</Segment>
			</Container>
		);
	};

	getChangesSubmittedMessage = () => {
		return (
			<Container id="POInvoiceTool">
				<Divider hidden />
				<Segment className="invoiceContainer" placeholder>
					<Header icon>
						<Icon name="flag checkered" color="green" />
						This Invoices PO has been submitted to the Vendor's Administrator and Accounts Payable!
						<Header.Subheader content="You may close this window or go to the administration portal." />
					</Header>
					<Button primary content="Go to Admin Portal" onClick={() => this.goDashboard()} />
				</Segment>
			</Container>
		);
	};

	getHasAlreadyCompletedMessage = () => {
		const { invoice_detail } = this.props;
		return (
			<Container id="POInvoiceTool">
				<Divider hidden />
				<Segment className="invoiceContainer" placeholder>
					<Header icon>
						<Icon name="check circle outline" color="green" />
						{`This Invoice has already had it's PO issued and payment was received ${
							invoice_detail.updated_at ? RelativeDatePipe(invoice_detail.updated_at.full) : ""
						}`}
						<Header.Subheader content="If you think this is an error, please contact your account administrator." />
					</Header>
					<Button primary content="Go to Admin Portal" onClick={() => this.goDashboard()} />
				</Segment>
			</Container>
		);
	};
}

export const POInvoiceTool = compose(
	withRouter,
	connect(null, null),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.invoiceDetail, {
		skip: (ownProps: any) => {
			return !localStorage.getItem("token") || (ownProps.match && !ownProps.match.params.id);
		},
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.match.params.storeId, 10),
					invoiceId: parseInt(props.match.params.id, 10)
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, dealer_invoice_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				invoice_detail: dealer_invoice_detail,
				refetch
			};
		}
	})
)(POInvoiceToolView) as React.ComponentType<any>;

export default POInvoiceTool;
