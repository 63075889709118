import { graphql } from "@apollo/react-hoc";
import { Form } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Button, Dropdown } from "semantic-ui-react";
import { restAPI } from "../../../../../../utils/rest";

export class UserAssignmentView extends React.Component<LMI.IUserAssignmentProps, LMI.IUserAssignmentState> {
	constructor(props: LMI.IUserAssignmentProps) {
		super(props);

		this.state = {
			value:
				props.dealer_phasers && props.dealer_phasers.user_assignments
					? props.dealer_phasers.user_assignments.filter(u => u.phase_id === props.phase_id).map(up => up.employee_id)
					: null
		};
	}

	handleChange = (event, { value }) => {
		this.setState({ value });
	};

	handleSubmit = () => {
		const { value } = this.state;
		const { phase_id, storeId } = this.props;

		const data = [{ id: phase_id, employees: value }];

		restAPI({
			endpointName: "modify_store_phases",
			urlArgs: [storeId],
			data,
			callback: error => {
				let alert;
				if (error) {
					alert = { type: "danger", message: this.props.onHandleErrorResponse(error) };
					this.props.onSendAlert(alert);
				}
				this.props.onClose();
				this.props.onRefetch();
			}
		});
	};

	render() {
		const { value } = this.state;
		const { employees } = this.props;

		const options =
			employees &&
			employees.map(j => {
				return { key: j.id, text: j.user.full_name, value: j.id };
			});

		return [
			<Form noValidate>
				<div>Select the employees(s) to assign to this Stage.</div>
				<div id="phaseBody" className="recon-form">
					<Dropdown
						className="select"
						value={value}
						placeholder="Employee"
						loading={!employees}
						fluid
						multiple
						search
						selection
						options={options}
						onChange={this.handleChange}
					/>
				</div>
			</Form>,
			<div className="cta-footer">
				<Button className="submit" id="save" positive content="Save" onClick={this.handleSubmit} />
			</div>
		];
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const UserAssignment = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.employeesByIsActive, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					isActive: true
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employees, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				employees,
				refetch
			};
		}
	})
)(UserAssignmentView) as React.ComponentType<any>;

export default UserAssignment;
