import * as React from "react";
import { Button, Divider, Icon, Label } from "semantic-ui-react";

export class ActivateGroupComponent extends React.Component<any, any> {
	render() {
		return (
			<div id="activate-group">
				<p>
					By clicking activate you will launch events to invite any users created under this organization. Each user will recieve an email inviting them to
					activate their account by creating a password.
				</p>
				<Label size="large">
					<Icon name="home" />
					New Stores
					<Label.Detail>4</Label.Detail>
				</Label>
				<Label size="large">
					<Icon name="user" />
					New Employees
					<Label.Detail>10</Label.Detail>
				</Label>
				<Divider />
				<div style={{ textAlign: "right" }}>
					<Button size="large" positive>
						<Icon name="toggle on" />
						Activate
					</Button>
				</div>
			</div>
		);
	}
}

export default ActivateGroupComponent;
