import { graphql } from "@apollo/react-hoc";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import { ColumnDirective, ColumnsDirective, GridComponent, SelectionSettingsModel } from "@syncfusion/ej2-react-grids";
import "@syncfusion/ej2-react-grids/styles/material.css";
import { gqlQueries } from "gql-imports";
import { flowRight as compose } from "lodash";
import moment from "moment";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Button, Loader } from "semantic-ui-react";
import { removeNullProps } from "client/utils/functions";
import { SystemLogType } from "loopmein-shared";
import { VehicleNoteForm } from "../../../VehicleNoteForm";
import { ModalComponent } from "../../../ModalComponent";
import { restAPI } from "../../../../../../utils/rest";

export class WorklogGridComponentView extends React.Component<LMI.IInventoryDetailTabProps, LMI.IInventoryDetailTabState> {
	grid: GridComponent;
	cellSelectionSettings: SelectionSettingsModel = { cellSelectionMode: "Box", type: "Single", mode: "Both" };

	constructor(props) {
		super(props);
		this.state = {
			selectedRowIndex: null,
			alert: null,
			addNote: false
		};
	}

	format(worklogs: any): LMI.IInventoryWorklogDetailGQL[] {
		return worklogs.map(item => {
			return {
				added: moment(item.created_at).isValid() ? moment(item.created_at).format("MM/DD/YY hA") : "",
				description: item.description,
				comment: item.comment,
				photo_url: item.photo_url ? item.photo_url : null,
				added_by: item.created_by_user.full_name
			};
		});
	}

	cellSelection = (args: any) => {
		args.cancel = true;
	};

	getDescriptionTemplate = (props): any => {
		const URLRegEx = /(\b(https?|http):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
		const linkedDescription: string = props.description ? props.description.replace(URLRegEx, "<a href='$1' target='_blank'>$1</a>") : "";
		const linkedComment: string = props.comment ? props.comment.replace(URLRegEx, "<a href='$1' target='_blank'>$1</a>") : "";
		return (
			<div>
				{props.photo_url ? (
					<div className="vehicle-image">
						<img src={`${props.photo_url}?h=100`} alt="" />
					</div>
				) : (
					""
				)}
				{linkedComment && linkedComment !== "N/A" ? <div className="comment" dangerouslySetInnerHTML={{ __html: linkedComment }}></div> : ""}
				{linkedDescription ? <div dangerouslySetInnerHTML={{ __html: linkedDescription }}></div> : ""}
				<div className="who-when">
					{props.added_by} on {props.added}
				</div>
			</div>
		);
	};

	getWrapSettings: any = () => {
		return { wrapMode: "Content" };
	};

	addNoteContent() {
		return (
			<div id="Confirm">
				<VehicleNoteForm
					{...{
						cost: false,
						files: false,
						btnText: "Add Note",
						onSubmit: data => this.addVehicleNote(data)
					}}
				/>
			</div>
		);
	}

	addVehicleNote = async formData => {
		const { inventory_detail, storeId, refetch } = this.props;
		const properties = removeNullProps(formData.noteForm);
		const data = { type: SystemLogType.VEHICLE_NOTE, description: properties.description };
		restAPI({
			endpointName: "addInventoryNote",
			urlArgs: [storeId, inventory_detail.id],
			data: data,
			callback: (error, result) => {
				this.setState({ addNote: false }, () => {
					refetch();
					this.grid.refresh();
				});
			}
		});
	};

	render() {
		const { inventory_detail, canAddWorkLogs } = this.props;
		const readyWorklogs = inventory_detail ? this.format(inventory_detail.work_logs) : null;

		if (!readyWorklogs) {
			return <Loader className={`loader active`} size="small" />;
		}

		return (
			<div className="worklogs-sub-tab">
				{this.state.addNote && (
					<ModalComponent
						size="small"
						headerText="Add Vehicle Note"
						shouldBeOpen={true}
						className="confirmation-modal"
						onClose={() => this.setState({ addNote: null })}
						contentComponent={() => this.addNoteContent()}
					/>
				)}
				<span className="floating-btn btm-rgt">
					{canAddWorkLogs ? <Button content="Add Note" icon="add" onClick={() => this.setState({ addNote: true })} /> : <span />}
				</span>
				<GridComponent
					className="grid-component"
					enableHover={false}
					allowSelection={false}
					allowSorting={true}
					selectionSettings={this.cellSelectionSettings}
					cellSelecting={this.cellSelection}
					dataSource={readyWorklogs}
					allowGrouping={false}
					allowPaging={false}
					allowFiltering={false}
					allowTextWrap={true}
					textWrapSettings={this.getWrapSettings}
					ref={g => (this.grid = g)}
					height="100%"
					gridLines="Both"
				>
					<ColumnsDirective>
						<ColumnDirective field="comment" headerText="Description" template={this.getDescriptionTemplate} textAlign="Left" />
					</ColumnsDirective>
				</GridComponent>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const InventoryNotes = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventoryDetail, {
		options: (props: any) => {
			return {
				variables: {
					store_id: parseInt(props.storeId, 10),
					inventory_item_id: props.inventory_item_id
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, inventory_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				inventory_detail,
				refetch
			};
		}
	})
)(WorklogGridComponentView) as React.ComponentType<any>;

export default InventoryNotes;
