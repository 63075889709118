import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";

import { gqlQueries } from "gql-imports";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Grid, Icon, Loader } from "semantic-ui-react";
import { InspectionItemForm } from "./AddLeaseReturnInspectionItem";
import { LightboxComponent } from "../../../../../../components/ImageHandling/LightboxComponent";
import { ModalComponent } from "../../../ModalComponent";
import { handleErrorResponse } from "../../../../../../utils/functions";
import { UniversalActionButton } from "../../../UniversalActionButton";
import { addAlert } from "api/redux/actions";
import { restAPI } from "../../../../../../utils/rest";

import "./InspectionTab.css";

export class InspectionComponentView extends React.Component<LMI.ILRInspectionViewProps, LMI.ILRInspectionState> {
	private _confirmTimeout: number = 3000;
	private _countdown: number = this._confirmTimeout / 1000;

	constructor(props: LMI.ILRInspectionViewProps) {
		super(props);
		this.state = {
			confirm_delete_id: null,
			countdown: null,
			lightbox_open: false,
			detail_id: null,
			data: [],
			completed: false,
			modal_open: false,
			loading: false,
			photo_index: null,
			mode: null
		};
	}

	static format(data) {
		return data.map(d => {
			return {
				id: d.id,
				comment: d.comment,
				name: d.lease_return_inspection_type.name,
				description: d.lease_return_inspection_type.description,
				image: d.full_image_url
			};
		});
	}

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
		if (type === "danger") {
			console.log(`Error: ${message}`);
		}
	}

	getLightBox = () => {
		const { lightbox_open, photo_index, data } = this.state;
		const photos = data.filter((d, index) => index === photo_index).map(d => d.image);
		const lightboxProps = {
			photos,
			onCloseLightbox: () => this.setState({ lightbox_open: false, photo_index: null })
		};

		return lightbox_open && <LightboxComponent {...lightboxProps} />;
	};

	getModal = () => {
		const { modal_open, detail_id, mode } = this.state;
		const { lease_return } = this.props;

		let inspectionProps;

		if (mode !== "create") {
			const edit_inspection_index = detail_id ? detail_id : null;
			if (edit_inspection_index == null) return false;

			const comment = lease_return.lease_return_inspections[edit_inspection_index].comment;
			const condition_type =
				lease_return.lease_return_inspections[edit_inspection_index].lease_return_inspection_type &&
				lease_return.lease_return_inspections[edit_inspection_index].lease_return_inspection_type.id;
			const fileHandles = [lease_return.lease_return_inspections[edit_inspection_index].full_image_url];
			const inspectionItemId: number = parseInt(lease_return.lease_return_inspections[edit_inspection_index].id, 10);

			inspectionProps = {
				inspection_id: inspectionItemId,
				mode: "edit",
				headerText: "Modify Inspection Item",
				refetchInspections: this.props.refetchInspections,
				edit_inspection_index,
				comment,
				condition_type,
				fileHandles,
				onClose: () => this.setState({ modal_open: false }),
				closeModalCallback: () => this.setState({ modal_open: false }),
				...this.props
			};
		} else
			inspectionProps = {
				mode: "create",
				headerText: "Create Inspection Item",
				refetchInspections: this.props.refetchInspections,
				onClose: () => this.setState({ modal_open: false }),
				closeModalCallback: () => this.setState({ modal_open: false }),
				...this.props
			};

		return (
			<ModalComponent
				headerText={inspectionProps.headerText}
				size="small"
				shouldBeOpen={modal_open}
				onClose={() => {
					this.setState({ modal_open: false });
				}}
				className="inspection-item-modal"
				contentComponent={() => <InspectionItemForm {...inspectionProps} />}
			/>
		);
	};

	editItem = event => {
		if (!this.props.can_edit) return false;
		const exclude = ["IMG", "I", "SPAN", "EM"];
		if (!exclude.includes(event.target.tagName)) {
			const index = event.currentTarget.getAttribute("data-id");
			this.setState({ modal_open: true, mode: "edit", detail_id: index });
		}
	};

	removeConfirm = id => {
		if (this.state.confirm_delete_id) {
			this.removeInspection(id);
		} else {
			let countdown = this._countdown;
			let countdownInterval;
			this.setState({ confirm_delete_id: id, countdown }, () => {
				setTimeout(() => this.setState({ confirm_delete_id: null }), this._confirmTimeout);
				countdownInterval = setInterval(() => {
					if (countdown > 0) {
						countdown--;
						this.setState({ countdown });
					} else clearInterval(countdownInterval);
				}, 1000);
			});
		}
	};

	removeInspection = id => {
		this.setState({ loading: true });
		const { confirm_delete_id } = this.state;
		const { detail, storeId } = this.props;
		if (id === confirm_delete_id) {
			restAPI({
				endpointName: "deleteLeaseReturnInspection",
				urlArgs: [storeId, detail.detail_id, confirm_delete_id],
				data: null,
				callback: async (error, result) => {
					if (error) {
						this.sendAlert({ type: "danger", message: handleErrorResponse({ error }) });
					} else {
						await this.props.refetchInspections();
						this.setState({ confirm_delete_id: null, countdown: null, loading: false });
					}
				}
			});
		}
	};

	render() {
		const { loading, can_edit, lease_return, store_settings, onSetHeaderColor } = this.props;
		const { data, modal_open, confirm_delete_id, countdown } = this.state;

		if (loading || !data) return <Loader active />;

		const UABProps: LMI.IUABProps = {
			subUniversalActionCallback: () => this.setState({ modal_open: true, mode: "create" }),
			size: "small",
			tip: "Add a new inspection item"
		};

		onSetHeaderColor({ age: lease_return.age, store_settings, is_completed: !!lease_return.completed_at });

		if (data.length > 0) {
			return (
				<>
					{this.getLightBox()}
					<div id="Inspection" className="inspection-panel">
						{modal_open && this.getModal()}
						<div className="inspection">
							<Grid className="list">
								{data.map((item, index) => {
									return (
										<Grid.Row key={index} className={`item no-select ${!can_edit ? "disabled" : ""}`} onClick={this.editItem.bind(this)} data-id={index}>
											<Grid.Column className="image" width={6}>
												{item.image ? (
													<img
														onClick={() => this.setState({ lightbox_open: true, photo_index: index })}
														src={`${item.image}?q=50&w=150&ch=Save-Data&auto=compress`}
													/>
												) : (
													<Icon name="photo" />
												)}
											</Grid.Column>
											<Grid.Column className="content" width={8}>
												<div className="name">{item.name}</div>
												<div className="comment">{item.comment}</div>
											</Grid.Column>
											<Grid.Column width={2}>
												{can_edit && (
													<i
														className={`no-select icon ${confirm_delete_id === item.id ? "trash alternate" : "delete"}`}
														onClick={this.removeConfirm.bind(this, item.id)}
													/>
												)}
												{confirm_delete_id === item.id && (
													<span className="confirm no-select" onClick={this.removeInspection.bind(this, item.id)}>
														<em>Confirm? {countdown}</em>
													</span>
												)}
											</Grid.Column>
										</Grid.Row>
									);
								})}
							</Grid>
						</div>
					</div>
					<div className="fab-footer">
						<UniversalActionButton {...UABProps} />
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className="noresults">There are no existing inspection items for this vehicle.</div>
					{modal_open && this.getModal()}
					<div className="fab-footer">
						<UniversalActionButton {...UABProps} />
					</div>
				</>
			);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.lease_return) {
			if (nextProps.lease_return !== prevState.data) {
				const data = InspectionComponentView.format(nextProps.lease_return.lease_return_inspections);
				return { lease_return_id: nextProps.lease_return.id, data };
			}
		}
		return null;
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const InspectionComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.leaseReturnDetail, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					leaseReturnId: parseInt(props.detail.detail_id, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, lease_return_detail, refetch, fetchMore } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				lease_return: lease_return_detail.lease_return,
				refetchInspections: refetch
			};
		}
	})
)(InspectionComponentView) as React.ComponentType<any>;

export default InspectionComponent;
