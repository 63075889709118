import * as React from "react";
import { Popup } from "semantic-ui-react";

export class GridPhaseTimer extends React.Component<
  LMI.IPhaseTimerProps,
  LMI.IPhaseTimerState
> {
  constructor(props: LMI.IPhaseTimerProps) {
    super(props);

    this.state = {
      loading: false,
      elapsed_time: null,
      running: false,
    };
  }

  clearPhaseTimer() {
    if ((window as any).gridPhaseInterval)
      clearInterval(
        (window as any).gridPhaseInterval[
          `${this.props.id}${this.props.current_phase}`
        ]
      );
  }

  componentWillUnmount() {
    this.clearPhaseTimer();
  }

  componentDidMount() {
    const { id, current_phase, phase_timer_running_since_ms } = this.props;

    if ((window as any).gridPhaseInterval) this.clearPhaseTimer();
    else (window as any).gridPhaseInterval = {};

    if (id) {
      const now = new Date();
      const elapsed = phase_timer_running_since_ms
        ? now.getTime() - phase_timer_running_since_ms
        : 0;

      this.setState(
        { loading: true, elapsed_time: elapsed, running: false },
        () => {
          if (phase_timer_running_since_ms) {
            const interval = 1000;
            (window as any).gridPhaseInterval[
              `${id}${current_phase}`
            ] = setInterval(() => {
              this.setState((prevState) => ({
                running: true,
                elapsed_time: prevState.elapsed_time + interval,
              }));
            }, interval);
          }
        }
      );
    }
  }

  phaseThreshold(elapsedMS) {
    const { dealer_phases, current_phase } = this.props;
    const phase = dealer_phases.find((p) => p.id === current_phase);
    const msThresholds = this.calculateMilliseconds(phase);
    return {
      warning: elapsedMS > msThresholds.warning,
      error: elapsedMS > msThresholds.error,
    };
  }

  calculateMilliseconds(phase) {
    const {
      warning_threshold_days: wDays,
      warning_threshold_hours: wHours,
      warning_threshold_minutes: wMinutes,
      error_threshold_days: eDays,
      error_threshold_hours: eHours,
      error_threshold_minutes: eMinutes,
    } = phase;

    const wDayMS = wDays * 24 * 60 * 60 * 1000;
    const wHoursMS = wHours * 60 * 60 * 1000;
    const wMinutesMS = wMinutes * 60 * 1000;
    const wMS = wDayMS + wHoursMS + wMinutesMS;
    const eDayMS = eDays * 24 * 60 * 60 * 1000;
    const eHoursMS = eHours * 60 * 60 * 1000;
    const eMinutesMS = eMinutes * 60 * 1000;
    const eMS = eDayMS + eHoursMS + eMinutesMS;
    return { warning: wMS, error: eMS };
  }

  calculateElapsedTime(elapsedTime) {
    let ms = elapsedTime;

    // convert milliseconds to seconds
    ms = ms / 1000;
    const seconds = Math.floor(ms % 60);
    ms = ms / 60;
    const minutes = Math.floor(ms % 60);
    ms = ms / 60;
    const hours = Math.floor(ms % 24);
    const days = Math.floor(ms / 24);
    return {
      days: days.toString().padStart(2, "0"),
      hours: hours.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
    };
  }

  calculateElapsedMinimum(elapsedTime) {
    let ms = elapsedTime;
    const { useFull } = this.props;

    // convert milliseconds to seconds
    ms = ms / 1000;
    const seconds = Math.floor(ms % 60);
    ms = ms / 60;
    const minutes = Math.floor(ms % 60);
    ms = ms / 60;
    const hours = Math.floor(ms % 24);
    const days = Math.floor(ms / 24);

    if (days > 0)
      return days + ` D${useFull ? `ay${days > 0 ? "s" : ""}` : ""}`;
    if (hours > 0)
      return hours + ` H${useFull ? `our${hours > 0 ? "s" : ""}` : ""}`;
    if (minutes > 0)
      return minutes + ` M${useFull ? `inute${minutes > 0 ? "s" : ""}` : ""}`;
    if (seconds > 0)
      return `<1 M${useFull ? `inute${minutes > 0 ? "s" : ""}` : ""}`;

    return `0 M${useFull ? "inutes" : ""}`;
  }

  render() {
    const { elapsed_time } = this.state;
    const { dealer_phases, current_phase, classes } = this.props;

    const thresholds = this.phaseThreshold(elapsed_time);
    const time = this.calculateElapsedMinimum(elapsed_time);
    const popTime = this.calculateElapsedTime(elapsed_time);

    const phase = dealer_phases.find((p) => p.id === current_phase);

    return (
      <Popup
        position="bottom right"
        inverted
        trigger={
          <div
            id={`phaseDays-${this.props.id}`}
            className={`${classes ? classes : "phase-days"} ${
              phase.final_stage
                ? "gray"
                : thresholds.error
                ? "error"
                : thresholds.warning
                ? "warning"
                : ""
            }`}
          >
            <span key={elapsed_time}>{time}</span>
          </div>
        }
        content={`${
          phase && phase.final_stage
            ? "Time Since Disposition:"
            : "Current Stage Time:"
        } ${popTime.days}:${popTime.hours}:${popTime.minutes}:${
          popTime.seconds
        }`}
      />
    );
  }
}
