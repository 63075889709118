import { Form, Input } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { FileUploadComponent } from "client/pages/admin/components/FileUploadComponent";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import {
  formatPhone,
  getStoreTypeAdminRoleID,
  stringToInt,
  removeNullProps,
} from "client/utils/functions";
import { timeZones } from "client/utils/globals";
import { StoreType } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  List,
  Loader,
  Segment
} from "semantic-ui-react";
import { PrimaryContactComponent } from "../PrimaryContactComponent/PrimaryContactComponent";
import { restAPI } from "../../../../../utils/rest";
import { EditStoreComponent } from "./components/StoreInfoEditForm";

import "./StoreInfoComponent.css";
export class StoreInfoComponentView extends React.Component<
  LMI.SUAdminStoreProps,
  LMI.SUAdminStoreState
> {
  constructor(props) {
    super(props);

    this.state = {
      addStore: props.addStore,
      editStoreInfo: false,
      uploadLogo: false,
      addService: false,
      editService: null,
      editPrimary: false,
      setPrimary: null,
    };
  }

  render() {
    if (this.props.loading) {
      return (
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      );
    }

    const store = this.props.store && this.props.store;
    const vendorServices =
      this.props.su_vendor_services && this.props.su_vendor_services;
    const organizations =
      this.props.organizations && this.props.organizations.length
        ? this.mapOptions(this.props.organizations)
        : [];
    const regions =
      store &&
      store.organization &&
      store.organization.regions &&
      store.organization.regions.length
        ? this.mapOptions(store.organization.regions)
        : [];
    const employees =
      store && store.employees && store.employees.length
        ? store.employees.map((emp) => ({
            key: emp.id,
            text: emp.user.full_name,
            value: emp.id,
          }))
        : [];
    const allServices =
      this.props.su_all_services &&
      this.props.su_all_services.map((service, index) => ({
        key: index,
        text: service.name,
        value: service.id,
      }));
    const fileProps = {
      filesToAccept: "image/png, image/jpeg",
      onSubmit: (file, callback) => {
        this.uploadImage(file, callback);
      },
      onClose: () => {
        this.setState({ uploadLogo: false });
      },
    };
    const primaryProps: LMI.SUAdminPrimaryContact = {
      store_id: this.state.setPrimary && this.state.setPrimary.store_id,
      info_screen: true,
      nevermind: () => {
        this.setState({
          setPrimary: false,
        });
      },
      callback: (formData) => this.savePrimaryContact(formData),
    };

    return (
      <div id="store-info-component" className="page">
        {this.state.uploadLogo ? (
          <ModalComponent
            headerText="Upload Store Logo"
            shouldBeOpen={this.state.uploadLogo}
            onClose={(evt, data) => {
              this.setState({ uploadLogo: false });
            }}
            className="upload-image-modal"
            size="small"
            contentComponent={() => <FileUploadComponent {...fileProps} />}
          />
        ) : (
          ""
        )}
        {store && !this.state.setPrimary ? (
          <Grid>
            <Grid.Column width={3} textAlign="center">
              <Image src={store.logo_url} size="small" loading="lazy" />
              <br />
              <br />
              {this.state.editStoreInfo && (
                <Label
                  as="a"
                  onClick={() => this.setState({ uploadLogo: true })}
                >
                  <Icon name="image" /> Upload
                </Label>
              )}
            </Grid.Column>
            <Grid.Column width={13}>
              <span className="store-tools">
                <Checkbox
                  toggle
                  checked={store.is_active}
                  label={store.is_active ? "Active" : "Not Active"}
                  onChange={(e, data) => this.toggleActive(data)}
                />
              </span>
              {!this.state.editStoreInfo ? (
                <div>
                  <Header as="h3">
                    <div>
                      {store.name}
                      <Label>{store.store_type.name}</Label>
                    </div>
                    <div className="sub-header">
                      Store Code: <strong>{store.store_code}</strong> | Store
                      ID: <strong>{store.id}</strong>
                    </div>
                  </Header>
                </div>
              ) : (
                <Header as="h3" content="Edit Store Info " />
              )}
              {this.state.editStoreInfo ? (
                <EditStoreComponent
                  store={store}
                  onClose={() => this.setState({ editStoreInfo: false })}
                  updateStore={this.updateStore}
                />
              ) : (
                <address>
                  <Icon
                    link
                    name="edit"
                    color="blue"
                    className="action-icon"
                    onClick={() => {
                      this.setState({
                        editStoreInfo: true,
                      });
                    }}
                  />
                  {store.address1 && store.city && store.state && store.zip ? (
                    <span>
                      <strong>Contact Info:</strong>
                      <br />
                      {store.address1}
                      <br />
                      {store.city} {store.state}, {store.zip}
                      <br />
                      {formatPhone(store.phone)}
                    </span>
                  ) : (
                    <Button
                      primary
                      content="Edit Store Info"
                      onClick={() => this.setState({ editStoreInfo: true })}
                    />
                  )}
                  {store.timezone_name ? (
                    <span>
                      <br />
                      <br />
                      <strong>Timezone:</strong> {store.timezone_name}
                    </span>
                  ) : (
                    ""
                  )}
                </address>
              )}
            </Grid.Column>
            <Grid.Column width={16}>
              <Divider horizontal>Store Settings</Divider>
              {store ? (
                <div id="moreStoreInfo">
                  <Grid
                    columns={
                      store.store_type.id === StoreType.Dealership ? 3 : 2
                    }
                    divided
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h4">Organization:</Header>
                        {store.organization && (
                          <Label size="large">{store.organization.name}</Label>
                        )}
                        {!store.organization && this.props.organizations ? (
                          <Form
                            noValidate
                            size="tiny"
                            onSubmit={this.updateStore.bind(this)}
                          >
                            <Form.Field>
                              <Form.Select
                                search
                                name="organization_id"
                                options={organizations}
                                placeholder="Set an Organization"
                              />
                            </Form.Field>
                            <Button positive floated="right" content="Save" />
                          </Form>
                        ) : (
                          <span>
                            {!store.organization && (
                              <small>Nothing to show, try again later</small>
                            )}
                          </span>
                        )}
                      </Grid.Column>
                      {store.store_type.id === StoreType.Dealership && (
                        <Grid.Column>
                          <Header as="h4">Region:</Header>
                          {store.region && (
                            <Label size="large">{store.region.name}</Label>
                          )}
                          {!store.region && regions.length ? (
                            <Form
                              noValidate
                              size="tiny"
                              onSubmit={this.updateStore.bind(this)}
                            >
                              <Form.Field>
                                <Form.Select
                                  search
                                  name="organization_region_id"
                                  options={regions}
                                  placeholder="Set a Region"
                                />
                              </Form.Field>
                              <Button positive floated="right" content="Save" />
                            </Form>
                          ) : (
                            <span>
                              {!store.region && (
                                <small>
                                  This Organization has no regions setup
                                </small>
                              )}
                            </span>
                          )}
                        </Grid.Column>
                      )}
                      <Grid.Column>
                        <Header as="h4">
                          Primary Contact:{" "}
                          {!employees.length && (
                            <Icon
                              name="plus circle"
                              color="green"
                              link
                              onClick={() =>
                                this.setState({
                                  setPrimary: { store_id: this.props.store.id },
                                })
                              }
                            />
                          )}
                        </Header>
                        {store.primary_contact && !this.state.editPrimary && (
                          <Label size="large">
                            <Icon
                              link
                              onClick={() =>
                                this.setState({ editPrimary: true })
                              }
                              name="pencil"
                            />{" "}
                            {store.primary_contact.user.full_name}
                          </Label>
                        )}
                        {(!store.primary_contact && employees.length) ||
                        (this.state.editPrimary && employees.length) ? (
                          <Form
                            noValidate
                            size="tiny"
                            onSubmit={this.updateStore.bind(this)}
                          >
                            <Form.Field>
                              <Form.Select
                                search
                                name="primary_contact_id"
                                options={employees}
                                placeholder="Set a Primary Contact"
                              />
                            </Form.Field>
                            <Button positive floated="right" content="Save" />
                          </Form>
                        ) : (
                          <span>
                            {!store.primary_contact && (
                              <small>This Store has no employees</small>
                            )}
                          </span>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid columns={1}>
                    <Grid.Row>
                      <Grid.Column>
                        {store.store_type.id === StoreType.Dealership && (
                          <Segment secondary className="info-segments">
                            <Header as="h4">Groups:</Header>
                            {store.organization &&
                            store.organization.groups.length ? (
                              <List>
                                {store.organization.groups.map(
                                  (group, index) => {
                                    return (
                                      <List.Item key={index}>
                                        <List.Content>
                                          <Checkbox
                                            checked={
                                              store.store_groups.find(
                                                (gr) =>
                                                  gr.organization_group_id ===
                                                  group.id
                                              )
                                                ? true
                                                : false
                                            }
                                            label={group.name}
                                            value={group.id}
                                            onChange={(e, data) => {
                                              this.toggleGroup(data, store.id);
                                            }}
                                          />
                                        </List.Content>
                                      </List.Item>
                                    );
                                  }
                                )}
                              </List>
                            ) : (
                              <span>
                                {store.organization
                                  ? store.organization.name
                                  : "This auto group"}{" "}
                                has no groups!
                              </span>
                            )}
                          </Segment>
                        )}
                      </Grid.Column>
                      {store.store_type.id !== StoreType.Dealership ? (
                        <Grid.Column>
                          {this.state.addService ? (
                            <Segment secondary className="info-segments">
                              <Header as="h4">
                                <Icon
                                  link
                                  name="close"
                                  color="red"
                                  className="pull-right"
                                  onClick={() =>
                                    this.setState({ addService: null })
                                  }
                                />
                                Add a Service
                              </Header>
                              <Form
                                noValidate
                                onSubmit={this.saveService.bind(this)}
                              >
                                <Form.Field>
                                  <Form.Select
                                    search
                                    label="Service"
                                    name="service_id"
                                    options={allServices}
                                    placeholder="Select a Service"
                                  />
                                </Form.Field>
                                <Form.Field>
                                  <label>Base Price</label>
                                  <Input name="base_price" />
                                </Form.Field>
                                <Button positive content="Add Service" />
                              </Form>
                            </Segment>
                          ) : (
                            <Segment secondary className="info-segments">
                              <Header as="h4">
                                <span className="pull-right add-service-icon">
                                  <Icon
                                    circular
                                    inverted
                                    link
                                    color="green"
                                    name="plus"
                                    onClick={() =>
                                      this.setState({ addService: true })
                                    }
                                  />
                                </span>
                                Services:
                              </Header>
                              {vendorServices && vendorServices.length ? (
                                <div className="service-list">
                                  {vendorServices.map((serv, index, arr) => {
                                    if (serv.is_active) {
                                      return (
                                        <Segment
                                          key={index}
                                          size="tiny"
                                          attached={
                                            index === 0
                                              ? "top"
                                              : index + 1 === arr.length
                                              ? "bottom"
                                              : true
                                          }
                                        >
                                          {this.state.editService === index ? (
                                            <span>
                                              <Form
                                                noValidate
                                                onSubmit={(data, e) =>
                                                  this.updateService(
                                                    data,
                                                    serv.service.id
                                                  )
                                                }
                                              >
                                                {serv.service.name}
                                                <Icon
                                                  link
                                                  name="close"
                                                  color="red"
                                                  className="pull-right"
                                                  onClick={() =>
                                                    this.setState({
                                                      editService: null,
                                                    })
                                                  }
                                                />
                                                <Divider />
                                                <Form.Field>
                                                  <label>Base Price</label>
                                                  <Input
                                                    name="base_price"
                                                    value={serv.base_price}
                                                  />
                                                </Form.Field>
                                                <Button
                                                  content="Update"
                                                  primary
                                                  type="submit"
                                                />
                                                <Button
                                                  content="Delete"
                                                  color="red"
                                                  type="button"
                                                  onClick={() =>
                                                    this.deleteService(
                                                      serv.service.id
                                                    )
                                                  }
                                                />
                                              </Form>
                                            </span>
                                          ) : (
                                            <span>
                                              <Icon
                                                link
                                                name="pencil"
                                                color="blue"
                                                onClick={() =>
                                                  this.setState({
                                                    editService: index,
                                                  })
                                                }
                                              />{" "}
                                              {serv.service.name}{" "}
                                              <span className="pull-right">
                                                ${serv.base_price}
                                              </span>
                                            </span>
                                          )}
                                        </Segment>
                                      );
                                    }
                                  })}
                                </div>
                              ) : (
                                <p>
                                  There are no approved services for this vendor
                                </p>
                              )}
                            </Segment>
                          )}
                        </Grid.Column>
                      ) : (
                        ""
                      )}
                    </Grid.Row>
                  </Grid>
                </div>
              ) : (
                ""
              )}
            </Grid.Column>
          </Grid>
        ) : (
          <span>
            {this.state.setPrimary ? (
              <PrimaryContactComponent {...primaryProps} />
            ) : (
              ""
            )}
          </span>
        )}
      </div>
    );
  }

  saveService(data: any) {
    data.vendor_id = this.props.id;
    if (typeof data.base_price === "number") {
      data.base_price = data.base_price.toString();
    }

    restAPI({
      endpointName: "suAddVendorService",
      urlArgs: null,
      data,
      callback: (err, res) => {
        if (!err) {
          this.props.refetch();
        } else {
          console.log(err);
        }
        this.setState({ addService: false });
      },
    });
  }

  updateService(data: any, serviceId: number) {
    const datas = data;
    if (typeof data.base_price === "number") {
      datas.base_price = datas.base_price.toString();
    }

    restAPI({
      endpointName: "suUpdateVendorService",
      urlArgs: [this.props.id, serviceId],
      data: datas,
      callback: (err, res) => {
        if (!err) {
          this.props.refetch();
        } else {
          console.log(err);
        }
        this.setState({ editService: null });
      },
    });
  }

  deleteService(serviceId: number) {
    const setInactive = {
      is_active: false,
    };
    restAPI({
      endpointName: "suUpdateVendorService",
      urlArgs: [this.props.id, serviceId],
      data: setInactive,
      callback: (err, res) => {
        if (!err) {
          this.props.refetch();
        }
        this.setState({ editService: null });
      },
    });
  }

  mapOptions(array: any) {
    if (array.length) {
      return array.map((item) => ({
        key: item.id,
        text: item.name,
        value: item.id,
      }));
    }
  }

  toggleGroup(data: any, storeId: number) {
    if (data.checked) {
      restAPI({
        endpointName: "addStoreGroup",
        urlArgs: [storeId, data.value],
        data: null,
        callback: (err, res) => {
          if (!err) {
            this.props.refetchStore();
          }
        },
      });
    } else {
      restAPI({
        endpointName: "removeStoreGroup",
        urlArgs: [storeId, data.value],
        data: null,
        callback: (err, res) => {
          if (!err) {
            this.props.refetchStore();
          }
        },
      });
    }
  }

  toggleActive(data: any) {
    const datas = { is_active: data.checked };
    this.updateStore(datas);
  }

  updateStore(data: any) {
    if (data.timezone_name) {
      const selectedTimeZone = timeZones.find(
        (tz) => tz.value === data.timezone_name
      );
      if (selectedTimeZone) data.timezone_name = selectedTimeZone.utc;
    }
    restAPI({
      endpointName: "modifyStore",
      urlArgs: [this.props.store.id],
      data: removeNullProps(data),
      callback: (error, result) => {
        if (!error) {
          this.setState({ editStoreInfo: false, editPrimary: false });
          this.props.refetchStore();
        }
      },
    });
  }

  uploadImage(imageObject, callback) {
    const logo = {
      logo: imageObject.image,
    };
    restAPI({
      endpointName: "updateLogo",
      urlArgs: [this.props.store.id],
      data: logo,
      callback: (err, res) => {
        if (!err) {
          this.props.refetchStore();
          callback();
        }
      },
    });
    this.setState({ uploadLogo: false });
  }

  savePrimaryContact(data: any) {
    this.setState({ setPrimary: null });
    if (data.user_found) {
      const employee = { primary_contact_id: data.user_found.id };
      this.updateStore(employee);
    } else {
      const newUser = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone: data.phone,
        role_ids: [getStoreTypeAdminRoleID(this.props.store.store_type.id)],
        permissions: [],
      };
      restAPI({
        endpointName: "addStoreEmployee",
        urlArgs: [data.store_id],
        data: newUser,
        callback: (error, result) => {
          if (!error) {
            const employee = { primary_contact_id: result.data.employeeId };
            this.updateStore(employee);
          }
        },
      });
    }
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const StoreInfoComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.StoreAdminGQL, any, any, ClassAttributes<any>>(
    gqlQueries.super.store,
    {
      options: (props: any) => {
        return {
          variables: {
            id: stringToInt(props.id),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true };
        }
        return {
          store,
          refetchStore: refetch,
        };
      },
    }
  ),
  graphql<LMI.ServicesAdminGQL, any, any, ClassAttributes<any>>(
    gqlQueries.super.services,
    {
      options: (props: any) => {
        return {
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, su_all_services, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true };
        }
        return {
          su_all_services,
          refetch,
        };
      },
    }
  ),
  graphql<LMI.VendorServiceAdminGQL, any, any, ClassAttributes<any>>(
    gqlQueries.super.vendorServices,
    {
      options: (props: any) => {
        return {
          variables: {
            vendor_id: stringToInt(props.id),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, su_vendor_services, refetch },
      }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true };
        }
        return {
          su_vendor_services,
          refetch,
        };
      },
    }
  )
)(StoreInfoComponentView) as React.ComponentType<any>;

export default StoreInfoComponent;
