import * as React from "react";
import { connect } from "react-redux";
import { triggerUABCallback } from "api/redux/actions";
import { Container } from "semantic-ui-react";
import { EmployeeDataComponent, SelectStoresComponent } from "./components";
import { Session } from "client/utils/session";

import "./AddEmployeeFormComponent.css";

enum FormStates {
  EMPLOYEE,
  STORES,
}

export class AddEmployeeFormComponentView extends React.Component<
  LMI.IAEFCProps,
  LMI.IAEFCState
> {
  constructor(props) {
    super(props);

    this.state = {
      formState: FormStates.EMPLOYEE,
      user: null,
      permissions: [],
    };
  }

  render() {
    let renderContent = <div>No content</div>;
    let props = {};
    switch (this.state.formState) {
      case FormStates.STORES:
        props = {
          buttonLabel: "Add Employee",
          email: Session.get("email"),
          userId: Session.get("userId"),
          orgId: this.props.orgId,
          userInfo: this.state.user,
          onSubmit: (permissions: any[]) => {
            this.props.onCreate(this.state.user, permissions);
            this.props.closeModalCallback();
          },
        };
        renderContent = <SelectStoresComponent {...props} />;
        break;
      default:
        props = {
          buttonLabel: "Select Permissions",
          orgId: this.props.orgId,
          onSubmit: (formData: any) => {
            this.setState({ formState: FormStates.STORES, user: formData });
          },
        };
        renderContent = <EmployeeDataComponent {...props} />;
    }

    return (
      <Container className="add-employee-form-component">
        {renderContent}
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModalCallback: () => {
      dispatch(triggerUABCallback(false));
    },
  };
};

export const AddEmployeeFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEmployeeFormComponentView) as React.ComponentType<any>;

export default AddEmployeeFormComponent;
