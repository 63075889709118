// node_modules
// components
import { graphql } from "@apollo/react-hoc";
import { Form, Input } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { MaskedInputComponent } from "client/pages/admin/components/MaskedInputComponent";
import { stringToInt, validateEmail } from "client/utils/functions";
import { RegularExpressions } from "client/utils/regex";
import { getStoreTypeId } from "client/utils/userAccess";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Button, Container, Label } from "semantic-ui-react";
import "./EmployeeDataComponent.css";

const mapStateToProps = (state: any) => ({});
const mapDispatchToProps = (dispatch: any) => ({});
export class EEmailInputView extends React.Component<any, any> {
	render() {
		return (
			<Input
				name="email"
				validations="isEmail"
				validationError="This is not an email"
				onChange={(e, data) => this.checkEmail(data)}
				placeholder="Enter a valid email"
				required
			/>
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value && this.props.existing_user) this.props.setValues(this.props.existing_user);
	}

	checkEmail(data: any) {
		const val = validateEmail(data.value) ? data.value : null;
		this.props.setEmailValue(val);
	}
}

export const EEmailInput = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.checkUserExists, {
		skip: (ownProps: any) => !ownProps.value,
		options: (props: any) => {
			return {
				variables: {
					email: props.value,
					store_id: stringToInt(localStorage.getItem("selectedStoreId"))
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, existing_user, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				existing_user,
				refetch
			};
		}
	})
)(EEmailInputView) as React.ComponentType<LMI.EUserCheckGQL>;

export class EmployeeDataComponentView extends React.Component<LMI.IEDCProps, LMI.IEDCState> {
	constructor(props) {
		super(props);

		this.state = {
			currentEmailValue: null,
			foundUser: null
		};
	}
	render() {
		const errorLabel = <Label color="red" pointing="above" />;
		const jobOptions = this.props.job_titles && this.props.job_titles.map((title, index) => ({ key: index, text: title.name, value: title.id }));

		return (
			<div className="employee-data-component">
				<div className="edc-header" />
				<div className="edc-content">
					<Form id="AddEmployeeForm" noValidate ref="form" onValidSubmit={this.onSubmitHandler.bind(this)}>
						<Form.Field width={6}>
							<label>
								Email {this.state.foundUser && <small className="green">This email belongs to a current LMI user!</small>} <span className="red-text">*</span>
							</label>
							<EEmailInput
								{...{
									value: this.state.currentEmailValue,
									setEmailValue: data => this.setState({ currentEmailValue: data, foundUser: null }),
									setValues: data => this.setState({ foundUser: data }),
									validations: {
										matchRegexp: RegularExpressions.EMAIL
									},
									validationErrors: {
										matchRegexp: "Valid Email address is required",
										isDefaultRequiredValue: "Email is required"
									},
									errorLabel
								} as any}
							/>
						</Form.Field>
						<Form.Group widths="equal">
							<Form.Field>
								<label>
									First Name <span className="red-text">*</span>
								</label>
								<Input
									name="first_name"
									required
									validationErrors={{
										isDefaultRequiredValue: "First name is required"
									}}
									errorLabel={errorLabel}
									value={this.state.foundUser && this.state.foundUser.user.first_name}
									disabled={!this.state.currentEmailValue || this.state.foundUser}
								/>
							</Form.Field>
							<Form.Field>
								<label>
									Last Name <span className="red-text">*</span>
								</label>
								<Input
									name="last_name"
									required
									validationErrors={{
										isDefaultRequiredValue: "Last name is required"
									}}
									errorLabel={errorLabel}
									value={this.state.foundUser && this.state.foundUser.user.last_name}
									disabled={!this.state.currentEmailValue || this.state.foundUser}
								/>
							</Form.Field>
						</Form.Group>
						<Form.Group widths="equal">
							<Form.Field>
								<label>Mobile Phone</label>
								<MaskedInputComponent
									{...{
										type: "phone",
										value: this.state.foundUser && this.state.foundUser.user.phone,
										disabled: !this.state.currentEmailValue || this.state.foundUser ? true : false
									}}
								/>
							</Form.Field>
							<Form.Field>
								{this.props.job_titles && this.state.currentEmailValue ? (
									<span>
										<Form.Select
											label="Job Title"
											errorLabel={errorLabel}
											search
											name="job_title_id"
											options={jobOptions}
											placeholder="Select a Job Title"
											required
											validationErrors={{
												isDefaultRequiredValue: "Job title is required"
											}}
										/>
									</span>
								) : (
									<span />
								)}
							</Form.Field>
						</Form.Group>
						<Container textAlign="right">
							<Button type="submit" positive size="large">
								Setup Store Permissions
							</Button>
						</Container>
					</Form>
				</div>
			</div>
		);
	}

	onSubmitHandler(formData, reset) {
		this.props.onSubmit(formData);
	}
}

export const EmployeeDataComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.jobTitles, {
		options: (props: any) => {
			const storeTypeId = getStoreTypeId();
			return {
				variables: {
					storeTypeId
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, job_titles, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				job_titles: job_titles.job_titles,
				refetch
			};
		}
	})
)(EmployeeDataComponentView) as React.ComponentType<any>;

export default EmployeeDataComponent;
