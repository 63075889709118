import { restAPI } from "../../../../../utils/rest";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { Button, Checkbox, Dimmer, Icon, List, Loader, Table } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { stringToInt } from "client/utils/functions";

import "./TitlesAlertsComponent.css";

export class TitlesAlertsComponentView extends React.Component<LMI.SUAdminTitlesAlerts, LMI.SUAdminTitlesState> {
	constructor(props) {
		super(props);

		this.state = {
			storeTypeId: null,
			selectedTitle: null,
			addingTitle: false,
			alertFilter: null,
			disableEditing: false
		};
	}

	// manage selected title while switching store types and setting new alerts when adding/deleting job title alerts
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.store_type !== nextProps.store_type) {
			this.setState({ selectedTitle: null });
		} else {
			if (this.state.selectedTitle) {
				this.setState(prevState => ({
					selectedTitle: {
						...prevState.selectedTitle,
						alerts: nextProps.titles.find(title => title.id === this.state.selectedTitle.id).job_title_alerts
					}
				}));
			}
		}
	}

	render() {
		const titlesAlerts = this.props;
		return (
			<div id="titles-alerts-super-component">
				<Table celled padded verticalAlign="top">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Job Titles</Table.HeaderCell>
							<Table.HeaderCell>
								{this.state.selectedTitle && (
									<Form className="alert-filter">
										<Input
											size="mini"
											name="filter"
											placeholder="Filter Alerts"
											value={this.state.alertFilter}
											icon={this.state.alertFilter ? <Icon name="close" color="red" link onClick={() => this.setState({ alertFilter: null })} /> : false}
											onChange={(e, data) => this.setState({ alertFilter: data.value })}
										/>
									</Form>
								)}
								<span className={this.state.selectedTitle ? "table-perm-title" : ""}>Alerts</span>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							{this.state.addingTitle ? (
								<Table.Cell>
									<Form onSubmit={this.addTitle.bind(this)}>
										<Icon link name="close" color="red" className="close-right" onClick={() => this.setState({ addingTitle: false })} />
										<Form.Field>
											<label>Job Title Name</label>
											<Input name="name" placeholder="Name of new job title" />
										</Form.Field>
										<Form.Field>
											<label>Description</label>
											<Input name="description" />
										</Form.Field>
										<Form.Field>
											<Form.Select search label="Store Type" name="store_type_id" options={titlesAlerts.storeTypes} placeholder="Select an Store Type" />
										</Form.Field>
										<Button positive content="Save New Job Title" />
									</Form>
								</Table.Cell>
							) : (
								<Table.Cell>
									<List divided selection size="large">
										{titlesAlerts.titles
											? titlesAlerts.titles.map((title, index) => {
													return (
														<List.Item
															key={index}
															active={this.state.selectedTitle && this.state.selectedTitle.id === title.id ? true : false}
															onClick={() => {
																this.setState({
																	selectedTitle: {
																		alerts: title.job_title_alerts,
																		id: title.id
																	}
																});
															}}
														>
															<List.Content>
																<span>{title.name}</span>
															</List.Content>
														</List.Item>
													);
											  })
											: ""}
									</List>
									<Button primary onClick={() => this.setState({ addingTitle: true, selectedTitle: null })}>
										Add Title
									</Button>
								</Table.Cell>
							)}
							<Table.Cell>
								<Dimmer active={this.state.disableEditing} inverted>
									<Loader inverted>Saving</Loader>
								</Dimmer>
								<List divided size="large" className="permissions-list">
									{this.state.selectedTitle && titlesAlerts.alert_types && !this.props.loading
										? titlesAlerts.alert_types
												.filter(alert => (this.state.alertFilter ? alert.description.toLowerCase().indexOf(this.state.alertFilter.toLowerCase()) > -1 : alert))
												.map((alert, index) => {
													return (
														<List.Item key={index}>
															<Checkbox
																label={alert.description}
																checked={this.state.selectedTitle.alerts.find(p => p.alert_type_id === alert.id) ? true : false}
																value={alert.id}
																onChange={(e, data) => {
																	this.togglePermissions(data);
																}}
															/>
														</List.Item>
													);
												})
										: ""}
								</List>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</div>
		);
	}

	addTitle(data: any) {
		for (const prop in data) {
			if (data[prop] === null || data[prop] === undefined) {
				delete data[prop];
			}
		}
		restAPI({
			endpointName: "suAddTitle",
			urlArgs: null,
			data,
			callback: (err, res) => {
				if (!err) {
					this.setState({ addingTitle: false });
					this.props.refetch();
				}
			}
		});
	}

	togglePermissions(data: any) {
		this.setState({ disableEditing: true });
		const titleAlert = {
			alert_type_id: data.value,
			job_title_id: this.state.selectedTitle.id
		};
		const method = data.checked ? "suAddJobTitleAlert" : "suDeleteJobTitleAlert";
		restAPI({
			endpointName: method,
			urlArgs: null,
			data: titleAlert,
			callback: (err, res) => {
				if (!err) {
					this.props.refetch();
					setTimeout(() => {
						this.setState({ disableEditing: false });
					}, 500);
				}
			}
		});
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const TitlesAlertsComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.AlertTypesGQL, any, any, ClassAttributes<any>>(gqlQueries.alertTypes, {
		options: (props: any) => {
			return {
				variables: {
					storeTypeId: stringToInt(props.store_type)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, alert_types, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				alert_types,
				refetch
			};
		}
	}),
	graphql<LMI.IJobTitlesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.jobTitles, {
		options: (props: any) => {
			return {
				variables: {
					storeTypeId: stringToInt(props.store_type)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, job_titles, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				titles: job_titles.job_titles,
				refetch
			};
		}
	})
)(TitlesAlertsComponentView) as React.ComponentType<any>;

export default TitlesAlertsComponent;
