import { MapsDialog } from "client/components/MapDialog";
import * as React from "react";
import { Icon, Popup } from "semantic-ui-react";

export class InventoryLocationPinDialog extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			viewInvLocation: null
		};
	}

	render() {
		const props = this.props;
		const { viewInvLocation } = this.state;
		const vehicle =
			viewInvLocation && viewInvLocation.vehicle_title
				? viewInvLocation.vehicle_title
				: viewInvLocation && viewInvLocation.year_make_model
				? viewInvLocation.year_make_model
				: "Vehicle";

		if (props && props.latitude)
			return (
				<div>
					{viewInvLocation && (
						<MapsDialog
							{...{
								open: viewInvLocation ? true : false,
								title: `Lot Location - ${vehicle}`,
								location: {
									latitude: viewInvLocation.latitude,
									longitude: viewInvLocation.longitude
								},
								actions: [],
								onClose: () => this.setState({ viewInvLocation: null })
							}}
						/>
					)}

					<Popup
						inverted
						content={"Click to View Location"}
						trigger={
							<Icon
								className="cancel-row-selection"
								style={{ opacity: "1!important", fontSize: "23px" }}
								name="map marker alternate"
								link
								onClick={() => this.setState({ viewInvLocation: props })}
							/>
						}
					/>
				</div>
			);
		else return <span />;
	}
}

export default InventoryLocationPinDialog;
