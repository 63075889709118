import InventoryDetail from "client/pages/admin/components/InventoryDetailComponent/InventoryDetailComponent";
import {
  AccessoriesReportTabPanel,
  ActiveTabPanel,
  AuctionsTabPanel,
  BusinessDocumentsTabPanel,
  ClientsTabPanel,
  CompleteTabPanel,
  ContactSettingsTabPanel,
  ContactTabPanel,
  EmployeesTabPanel,
  GroupsTabPanel,
  InspectionSettingsPanel,
  InventoryTabPanel,
  InvoicesTabPanel,
  LeadsTabPanel,
  LeaseReturnSettingsTabPanel,
  LeaseReturnTabPanel,
  ListsTabPanel,
  MessagesTabPanel,
  OrgSettingsTabPanel,
  ProfileSettingsTabPanel,
  ReconSettingsTabPanel,
  ReconTabPanel,
  RegionsTabPanel,
  ReportsTabPanel,
  SaniSettingsTabPanel,
  SaniTabsPanel,
  ServicesTabPanel,
  StageBreakdownTabPanel,
  StoreEmployeesTabPanel,
  StoreSettingsTabPanel,
  StoreTabPanel,
  TasksTabPanel,
  VehDocTabPanel,
  VendorServicesTabPanel,
  VendorsTabPanel,
  VendorTabPanel,
} from "client/pages/admin/components/tabs";
import UserSettingsTabs from "client/pages/admin/components/UserSettingsTabs/UserSettingsTabs";
import { Permission, StoreType } from "loopmein-shared";
import SubTabContainers from "./SubTabContainers";
import { Session } from "client/utils/session";

const MainMenuOrder = [
  "clients",
  "workflow",
  "inventory",
  "lease-returns",
  "sanitization",
  "open-tasks",
  "invoices",
  "vendors",
  "reports",
  "store-employees",
  "vendor-services",
  "vendor",
  "store",
  "vehicle-documents",
  "business-documents",
  "settings",
];

export const RouteData: LMI.IAppRouteData[] = [
  {
    id: "accessories-report",
    name: "Accessories",
    component: AccessoriesReportTabPanel,
    permissions: [Permission.LIST_REPORTS],
    storeType: [StoreType.Dealership],
  },
  {
    id: "assigned-employees",
    name: "Assigned Employees",
    component: StoreEmployeesTabPanel,
    permissions: [Permission.ADMIN_LIST_EMPLOYEES],
  },
  {
    id: "auctions",
    name: "Auctions",
    component: AuctionsTabPanel,
    permissions: [Permission.ADMIN_LIST_AUCTIONS],
  },
  {
    id: "business-documents",
    name: "Business Documents",
    component: BusinessDocumentsTabPanel,
    permissions: [Permission.ADMIN_LIST_VENDOR_DOCUMENTS],
    storeType: [StoreType.Vendor],
    routeIcon: "/images/financial-document.svg",
    menuIndex: MainMenuOrder.indexOf("business-documents"),
  },
  {
    id: "clients",
    name: "Clients",
    component: ClientsTabPanel,
    permissions: [Permission.ADMIN_LIST_CLIENTS],
    storeType: [StoreType.Vendor, StoreType.InternalVendor],
    subTabContainer: SubTabContainers.clients,
    subTabs: [
      "contact",
      "services",
      "invoices",
      "open-tasks",
      "assigned-employees",
    ],
    dynamicURL: true,
    routeIcon: "/images/dealership.png",
    menuIndex: MainMenuOrder.indexOf("clients"),
  },
  {
    id: "clients-invoices",
    name: "Invoices",
    component: InvoicesTabPanel,
    permissions: [Permission.LIST_INVOICES],
    storeType: [StoreType.Vendor, StoreType.InternalVendor],
  },
  {
    id: "contact",
    name: "Contact",
    component: ContactTabPanel,
    permissions: [Permission.ADMIN_VIEW_VENDOR, Permission.ADMIN_VIEW_CLIENT],
  },
  {
    id: "contact-settings",
    name: "Notifications",
    component: ContactSettingsTabPanel,
    permissions: [Permission.LIST_DEFAULT_CONTACTS],
    storeType: [StoreType.Dealership],
  },
  {
    id: "dealer-leads",
    name: "Leads",
    component: LeadsTabPanel,
    permissions: [Permission.LIST_LEADS],
    storeType: [StoreType.Dealership],
  },
  {
    id: "employees",
    name: "Employees",
    component: StoreEmployeesTabPanel,
    permissions: [Permission.ADMIN_LIST_EMPLOYEES],
  },
  {
    id: "groups",
    name: "Groups",
    component: GroupsTabPanel,
    permissions: [Permission.ADMIN_GROUPS],
    storeType: [StoreType.Dealership],
  },
  {
    id: "inspection-settings",
    name: "Inspections",
    component: InspectionSettingsPanel,
    permissions: [Permission.ADMIN_INSPECTIONS],
    storeType: [StoreType.Dealership],
  },
  {
    id: "inventory",
    name: "Inventory",
    component: InventoryTabPanel,
    permissions: [Permission.LIST_INVENTORY],
    storeType: [StoreType.Dealership],
    routeIcon: "/images/inventory.png",
    menuIndex: MainMenuOrder.indexOf("inventory"),
    menuActions: [
      {
        text: "Add Inventory",
        icon: "add circle",
        color: "green",
        action: "toggleAddInventoryDialog",
        permissions: [Permission.ADD_INVENTORY],
      },
    ],
  },
  {
    id: "inventory-detail",
    name: "Inventory Detail",
    component: InventoryDetail,
    permissions: [Permission.LIST_INVENTORY],
    storeType: [StoreType.Dealership],
    dynamicURL: true,
    routeIcon: "/images/workflow.png",
  },
  {
    id: "invoices",
    name: "Invoices",
    component: InvoicesTabPanel,
    permissions: [Permission.LIST_INVOICES],
    routeIcon: "/images/financial-document.svg",
    menuIndex: MainMenuOrder.indexOf("invoices"),
  },
  {
    id: "lease-returns",
    name: "Lease Returns",
    component: LeaseReturnTabPanel,
    permissions: [Permission.LIST_LEASE_RETURNS],
    storeType: [StoreType.Dealership],
    routeIcon: "/images/lease_return.png",
    menuIndex: MainMenuOrder.indexOf("lease-returns"),
  },
  {
    id: "lease-return-settings",
    name: "Lease Returns",
    component: LeaseReturnSettingsTabPanel,
    permissions: [Permission.ADMIN_LEASE_RETURNS],
    storeType: [StoreType.Dealership],
  },
  {
    id: "messages",
    name: "Messages",
    component: MessagesTabPanel,
    permissions: [Permission.ADMIN_LIST_BROADCASTS],
  },
  {
    id: "open-tasks",
    name: "Tasks",
    component: TasksTabPanel,
    permissions: [Permission.LIST_TASKS],
    routeIcon: "/images/tasks.png",
    menuIndex: MainMenuOrder.indexOf("open-tasks"),
  },
  {
    id: "overrides",
    name: "Overrides",
    component: StoreSettingsTabPanel,
    permissions: [],
    accessCallback: () => {
      if (localStorage.getItem("su_token")) return true;
      return false;
    },
  },
  {
    id: "regions",
    name: "Regions",
    component: RegionsTabPanel,
    permissions: [Permission.ADMIN_REGIONS],
    storeType: [StoreType.Dealership],
  },
  {
    id: "reports",
    name: "Reports",
    component: ReportsTabPanel,
    permissions: [Permission.LIST_REPORTS],
    storeType: [StoreType.Dealership],
    subTabContainer: SubTabContainers.reports,
    subTabs: ["stage-breakdown", "accessories-report", "dealer-leads"],
    dynamicURL: true,
    routeIcon: "/images/document.svg",
    menuIndex: MainMenuOrder.indexOf("reports"),
  },
  {
    id: "sanitization",
    name: "Sanitization",
    component: SaniTabsPanel,
    permissions: [Permission.LIST_SANITIZATIONS],
    storeType: [StoreType.Dealership],
    subTabContainer: SubTabContainers.sanitization,
    subTabs: ["sanitization-active", "sanitization-complete"],
    dynamicURL: true,
    routeIcon: "/images/spray-bottle-black.png",
    menuIndex: MainMenuOrder.indexOf("sanitization"),
    subMenu: {
      svg: "/images/spray-bottle-black.png",
      color: "#ddd",
      style: {
        width: "100px",
        height: "100px",
        bottom: "10px",
        right: "5px",
      },
    },
  },
  {
    id: "sanitization-active",
    name: "Active",
    component: ActiveTabPanel,
    permissions: [Permission.LIST_SANITIZATIONS],
    storeType: [StoreType.Dealership],
  },
  {
    id: "sanitization-complete",
    name: "Complete",
    component: CompleteTabPanel,
    permissions: [Permission.LIST_SANITIZATIONS],
    storeType: [StoreType.Dealership],
  },
  {
    id: "sanitization-settings",
    name: "Sanitization",
    component: SaniSettingsTabPanel,
    permissions: [Permission.ADMIN_SANITIZATION],
    storeType: [StoreType.Dealership],
  },
  {
    id: "services",
    name: "Services",
    component: ServicesTabPanel,
    permissions: [
      Permission.ADMIN_LIST_VENDOR_APPROVED_SERVICES,
      Permission.ADMIN_LIST_SERVICES,
    ],
  },
  {
    id: "settings",
    name: "Settings",
    component: ListsTabPanel,
    permissions: [
      Permission.ADMIN_REGIONS,
      Permission.ADMIN_GROUPS,
      Permission.ADMIN_NOTIFICATIONS,
      Permission.ADMIN_WORKFLOW,
      Permission.ADMIN_LEASE_RETURNS,
      Permission.ADMIN_SANITIZATION,
      Permission.LIST_DEFAULT_CONTACTS,
      Permission.ADMIN_LIST_AUCTIONS,
      Permission.ADMIN_INSPECTIONS,
    ],
    subTabContainer: SubTabContainers.settings,
    subTabs: [
      "regions",
      "groups",
      "auctions",
      "contact-settings",
      "sanitization-settings",
      "workflow-settings",
      "inspection-settings",
      "lease-return-settings",
      "overrides",
    ],
    dynamicURL: true,
    routeIcon: "/images/settings-cog.svg",
    menuIndex: MainMenuOrder.indexOf("settings"),
    subMenu: {
      svg: "/images/settings-cog.svg",
      color: "#ddd",
      style: {
        width: "120px",
        height: "120px",
        bottom: "10px",
        right: "10px",
      },
    },
  },
  {
    id: "settings-admin",
    name: "User Settings",
    component: UserSettingsTabs,
    permissions: [],
    subTabContainer: SubTabContainers.userSettings,
    userRoute: true,
    subTabs: ["settings-profile"],
    dynamicURL: true,
    accessCallback: () => {
      if (localStorage.getItem("token")) return true;
      return false;
    },
  },
  {
    id: "settings-profile",
    name: "User Settings",
    component: ProfileSettingsTabPanel,
    permissions: [],
    accessCallback: () => {
      const userId = Session.get("userId");
      if (userId || Session.get("isSuperUser")) {
        return true;
      }
      return false;
    },
  },
  {
    id: "settings-store",
    name: "Store",
    component: StoreSettingsTabPanel,
    permissions: [Permission.ADMIN_EDIT_STORE],
  },
  {
    id: "settings-organization",
    name: "Organization",
    component: OrgSettingsTabPanel,
    permissions: [Permission.ADMIN_ORGANIZATION],
  },
  {
    id: "stage-breakdown",
    name: "Stage Breakdown",
    component: StageBreakdownTabPanel,
    permissions: [Permission.LIST_STAGES, Permission.STAGE_MANAGER],
    storeType: [StoreType.Dealership],
  },
  {
    id: "store",
    name: "Dealership",
    component: StoreTabPanel,
    permissions: [Permission.ADMIN_VIEW_STORE],
    storeType: [StoreType.Dealership],
    routeIcon: "/images/dealership.png",
    menuIndex: MainMenuOrder.indexOf("store"),
    subTabs: ["vehicle-documents"],
    defaultSub: "Dealership Info",
  },
  {
    id: "store-employees",
    name: "Employees",
    component: EmployeesTabPanel,
    permissions: [Permission.ADMIN_LIST_EMPLOYEES],
    routeIcon: "/images/technician-with-glasses.svg",
    menuIndex: MainMenuOrder.indexOf("store-employees"),
  },
  {
    id: "vendor",
    name: "Vendor",
    component: VendorTabPanel,
    permissions: [Permission.ADMIN_VIEW_STORE],
    storeType: [StoreType.Vendor, StoreType.InternalVendor],
    routeIcon: "/images/technician_with_wrench.svg",
    menuIndex: MainMenuOrder.indexOf("vendor"),
  },
  {
    id: "vendor-services",
    name: "Services",
    component: VendorServicesTabPanel,
    permissions: [Permission.ADMIN_LIST_SERVICES],
    routeIcon: "/images/services.png",
    menuIndex: MainMenuOrder.indexOf("vendor-services"),
  },
  {
    id: "vendors",
    name: "Vendors",
    component: VendorsTabPanel,
    permissions: [Permission.ADMIN_LIST_VENDORS],
    storeType: [StoreType.Dealership],
    subTabContainer: SubTabContainers.vendors,
    subTabs: [
      "contact",
      "services",
      "vendors-employees",
      "invoices",
      "vendors-business-documents",
      "open-tasks",
    ],
    dynamicURL: true,
    routeIcon: "/images/vendors.png",
    menuIndex: MainMenuOrder.indexOf("vendors"),
    subMenu: {
      svg: "/images/technician_with_wrench.svg",
      color: "#ddd",
      style: {
        width: "120px",
        height: "120px",
        bottom: "10px",
        right: "10px",
      },
    },
  },
  {
    id: "vendors-business-documents",
    name: "Business Documents",
    component: BusinessDocumentsTabPanel,
    permissions: [Permission.ADMIN_LIST_VENDOR_DOCUMENTS],
    storeType: [StoreType.Dealership],
  },
  {
    id: "vendors-employees",
    name: "Employees",
    component: StoreEmployeesTabPanel,
    permissions: [Permission.ADMIN_LIST_VENDOR_EMPLOYEES],
  },
  {
    id: "vehicle-documents",
    name: "Vehicle Documents",
    component: VehDocTabPanel,
    permissions: [Permission.VIEW_VEHICLE_DOCUMENT],
    storeType: [StoreType.Dealership],
    routeIcon: "/images/financial-document.svg",
  },
  {
    id: "workflow",
    name: "Workflow",
    component: ReconTabPanel,
    permissions: [Permission.LIST_STAGES, Permission.STAGE_MANAGER],
    storeType: [StoreType.Dealership],
    dynamicURL: true,
    routeIcon: "/images/workflow.png",
    menuIndex: MainMenuOrder.indexOf("workflow"),
    defaultSub: "Stages",
    subTabs: ["stage-breakdown"],
    subMenu: {
      svg: "/images/workflow.png",
      color: "#ddd",
      style: {
        width: "100px",
        height: "100px",
        bottom: "10px",
        right: "10px",
      },
    },
  },
  {
    id: "workflow-settings",
    name: "Workflow",
    component: ReconSettingsTabPanel,
    permissions: [Permission.ADMIN_WORKFLOW],
    storeType: [StoreType.Dealership],
  },
];
