import * as React from "react";
import { Dropdown, Label } from "semantic-ui-react";
import { validateEmail } from "../../../../utils/functions";

import "./ListInputComponent.css";

export class ListInputComponent extends React.Component<LMI.IListInputProps, LMI.IListInputState> {
	constructor(props) {
		super(props);
		this.state = {
			opts: [],
			selected: [],
			error: false,
			errorMessage: null
		};
	}

	render() {
		return (
			<div id="list-input-component">
				<Dropdown
					search
					selection
					multiple
					icon={null}
					allowAdditions
					additionLabel="Click enter to add "
					noResultsMessage={null}
					error={this.state.error}
					options={this.state.opts}
					value={this.state.selected}
					placeholder={this.props.placeholder}
					onChange={(e, data) => this.changed(data)}
					onAddItem={(e, data) => this.addItem(data)}
				/>
				{this.state.errorMessage !== null && (
					<Label basic color="red" pointing="left">
						{this.state.errorMessage}
					</Label>
				)}
			</div>
		);
	}

	changed(data: any) {
		// handling lack of onRemoveItem in Semantic Dropdown component props
		const matchedOpts = [];
		const missingOpts = [];
		this.state.opts.forEach(opt => {
			if (data.value.indexOf(opt.value) === -1) {
				missingOpts.push(opt);
			} else {
				if (!matchedOpts.find(op => op.value === opt.value)) {
					matchedOpts.push(opt);
				}
			}
		});

		if (missingOpts.length) {
			this.setState(
				{
					opts: matchedOpts
				},
				() => this.props.onChange(this.state.opts)
			);
		}
	}

	addItem(data: any) {
		let setState = true;
		let message = null;

		if (this.props.validate && this.props.validate === "email") {
			const isValid = validateEmail(data.value);
			setState = isValid;
			message = !isValid ? "Must be a valid email" : null;
		}

		this.setState(prevState => ({
			opts: prevState.opts,
			error: !setState,
			errorMessage: message
		}));

		// update parent
		if (setState) {
			this.props.onChange([{ text: data.value, value: data.value }, ...this.state.opts]);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const createItems = items => {
			if (typeof items === "string") return items.split(",");
			return items;
		};
		const items = nextProps.items.length ? createItems(nextProps.items) : [];
		return {
			selected: items,
			opts: items.map(i => ({ text: i, value: i }))
		};
	}
}
