import { restAPI } from "../../../../../../../../../utils/rest";
import { addAlert } from "api/redux/actions";
import { gqlQueries } from "gql-imports";
import { stringToInt } from "client/utils/functions";
import { getStoreTypeId, hasPermission } from "client/utils/userAccess";
import { Permission } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Button, Checkbox, Dropdown, Header, Icon, Label, List, Loader, Popup } from "semantic-ui-react";
import { Session } from "client/utils/session";

import "./JobAlertsComponent.css";

export class JobAlertsComponentView extends React.Component<LMI.IJobAlertsProps, LMI.IJobAlertsState> {
	constructor(props) {
		super(props);
		this.state = {
			jobTitleId: null,
			editJob: false,
			alerts: null
		};
	}

	render() {
		const props = this.props;
		const employee = props.employee_user;
		const jobTitleId = employee && employee.job_title_id;
		const canEditEmployee = hasPermission(props.requestingUserPermissions, Permission.ADMIN_EDIT_EMPLOYEE, Session.get("isSuperUser"));
		const jobOptions =
			props.job_titles &&
			props.job_titles.map((title, index) => ({
				key: index,
				text: title.name,
				value: title.id
			}));
		const alerts = employee ? employee.employee_alert_types : [];
		const jobTitle = employee && employee.job_title;
		if (props.loading) {
			return (
				<div>
					<br />
					<br />
					<Loader active inline="centered" />
				</div>
			);
		}

		return (
			<div id="job-alerts-component">
				<Header as="h4" content="Job Title" />
				{jobTitleId && !this.state.editJob ? (
					<div>
						<Label size="large">
							{canEditEmployee && <Icon name="wrench" />} {jobTitle.name}
						</Label>
						{canEditEmployee && <Icon name="pencil" color="blue" link onClick={() => this.setState({ editJob: true })} />}
						{jobTitle.description && (
							<p className="description">
								<small>
									<strong>Job Description:</strong> {jobTitle.description}
								</small>
							</p>
						)}
					</div>
				) : (
					canEditEmployee && (
						<div>
							<Dropdown
								scrolling
								button
								floating
								labeled
								icon="wrench"
								className="icon"
								value={jobTitleId && !this.state.jobTitleId ? jobTitleId : this.state.jobTitleId}
								options={jobOptions}
								placeholder="Select a Job Title"
								onChange={(e, data) => {
									const jobTitleId = parseInt(data.value.toString(), 10);
									this.setState({ jobTitleId }, () => this.props.onRefresh());
								}}
							/>
							{this.state.jobTitleId !== null && <Button content="Save" positive onClick={() => this.saveJobTitle()} />}
						</div>
					)
				)}
				<Header as="h4" content="Alerts" />
				<List verticalAlign="middle" className="alerts">
					{this.state.alerts &&
						this.state.alerts.map(alert => {
							return (
								<List.Item key={alert.id}>
									<List.Content>
										<Checkbox
											toggle
											label={
												<Popup
													hideOnScroll
													content={<p dangerouslySetInnerHTML={{ __html: alert.description }} />}
													trigger={<label>{alert.display_name}</label>}
												/>
											}
											disabled={!canEditEmployee}
											name={alert.id.toString()}
											checked={alerts.find(jobAlert => jobAlert.alert_type_id === alert.id) ? true : false}
											onClick={(e, data) => {
												if (canEditEmployee) {
													this.toggleAlert(data);
												}
											}}
										/>
									</List.Content>
								</List.Item>
							);
						})}
				</List>
			</div>
		);
	}

	getAlertTypes() {
		restAPI({
			endpointName: "getAlertTypes",
			urlArgs: null,
			data: { storetype: localStorage.getItem("selectedStoreType") },
			callback: (err, res) => {
				if (!err)
					this.setState({
						alerts: res.data.alert_types
					});
				else {
					this.props.addAlert({
						type: "danger",
						message: err.reason.response.data.message,
						timeout: 3000
					});
				}
			}
		});
	}

	toggleAlert(data: any) {
		const alertId = parseInt(data.name, 10);
		const change = {
			add_or_remove: data.checked ? "A" : "R"
		};

		restAPI({
			endpointName: "toggleEmployeeJobAlerts",
			urlArgs: [this.props.storeId, this.props.id, alertId],
			data: change,
			callback: (err, res) => {
				if (err)
					this.props.addAlert({
						type: "danger",
						message: err.reason.response.data.message,
						timeout: 3000
					});
				this.props.refetchEmployee();
			}
		});
	}

	saveJobTitle() {
		const newJob = {
			job_title_id: this.state.jobTitleId
		};
		restAPI({
			endpointName: "updateStoreEmployee",
			urlArgs: [this.props.storeId.toString(), this.props.id],
			data: newJob,
			callback: (err, res) => {
				if (!err) {
					this.props.onRefresh();
					this.props.refetchEmployee();

					this.setState({
						jobTitleId: null,
						editJob: false
					});
				} else
					this.props.addAlert({
						type: "danger",
						message: err.reason.response.data.message,
						timeout: 3000
					});
			}
		});
	}

	componentDidMount() {
		this.getAlertTypes();
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const JobAlertsComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IJobTitlesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.jobTitles, {
		options: (props: any) => {
			return {
				variables: {
					storeTypeId: getStoreTypeId()
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, job_titles, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				job_titles: job_titles.job_titles,
				refetch
			};
		}
	}),
	graphql<LMI.IEmployeeQueryProps, any, any, ClassAttributes<any>>(gqlQueries.employee, {
		options: (props: any) => {
			return {
				variables: {
					store_id: stringToInt(props.storeId),
					id: stringToInt(props.id)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employee_user, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true, message: error };
			}
			return {
				employee_user,
				refetchEmployee: refetch
			};
		}
	})
)(JobAlertsComponentView) as React.ComponentType<any>;

export default JobAlertsComponent;
