import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { Button, Card, Divider, Grid, Header, Icon } from "semantic-ui-react";
import { Form, TextArea, Input } from "formsy-semantic-ui-react";
import { DragDropListComponent } from "client/pages/admin/components/DragdropListComponent";
import { removeNullProps } from "client/utils/functions";
import { restAPI } from "../../../../../utils/rest";
import "./ServicesComponent.css";

export class ServicesComponentView extends React.Component<
  LMI.SUAdminServicesProps,
  LMI.SUAdminServicesState
> {
  constructor(props) {
    super(props);

    this.state = {
      editService: null,
      addService: false,
    };
  }

  render() {
    const services = this.props;
    const listProps: LMI.IDDListProps = {
      listName: "masterServices",
      items: this.mapListItems(services.su_all_services),
      editable: true,
      dragHandle: true,
      editCallback: (data) => {
        this.setState({
          editService: data,
        });
      },
      callback: (data) => this.reOrderServices(data),
    };

    return (
      <div id="master-services-component">
        <Header as="h3">Services List</Header>
        {this.state.addService || this.state.editService ? (
          <div className="form-card">
            <Divider />
            <Card>
              <Card.Content>
                <Header as="h4">
                  {this.state.addService ? "Add New " : "Edit"} Service{" "}
                  <Icon
                    name="close"
                    color="red"
                    className="action-icon"
                    link
                    onClick={() =>
                      this.setState({ addService: null, editService: null })
                    }
                  />
                </Header>
                <Form

                  noValidate
                  onSubmit={
                    this.state.addService
                      ? this.addService.bind(this)
                      : this.updateService.bind(this)
                  }
                >
                  <Form.Field>
                    <label>Service</label>
                    <Input
                      name="name"
                      value={
                        this.state.editService && this.state.editService.name
                      }
                      placeholder="Name of new service"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Description</label>
                    <TextArea
                      rows={3}
                      cols={40}
                      name="description"
                      value={
                        this.state.editService &&
                        this.state.editService.description
                      }
                      placeholder="Description of service"
                    />
                  </Form.Field>
                  <Button positive content="Save Service" />
                  {this.state.editService && (
                    <Input
                      type="hidden"
                      name="id"
                      value={this.state.editService.id}
                    />
                  )}
                </Form>
              </Card.Content>
            </Card>
          </div>
        ) : (
          <div id="service-list">
            {services && services.su_all_services && (
              <span>
                <div className="tableTop">
                  <Grid>
                    <Grid.Column width={1}>SORT/EDIT</Grid.Column>
                    <Grid.Column width={4}>SERVICE</Grid.Column>
                    <Grid.Column width={11}>DESCRIPTION</Grid.Column>
                  </Grid>
                </div>
                <DragDropListComponent {...listProps} />
              </span>
            )}
            <Divider />
            <Button primary onClick={() => this.setState({ addService: true })}>
              Add Service
            </Button>
          </div>
        )}
      </div>
    );
  }

  mapListItems(services: LMI.SUAdminServices[]) {
    const items =
      services &&
      services.map((serv) => {
        return {
          key: serv.id,
          name: serv.name,
          description: serv.description,
          item: serv,
        };
      });

    return items;
  }

  reOrderServices(services: any[]) {
    services.forEach((service, index) => {
      const nindex = (index + 1) * 10;
      if (service.item.sort_order !== nindex) {
        const serviceitem = {
          id: service.item.id,
          sort_order: nindex,
        };
        restAPI({
          endpointName: "suUpdateService",
          urlArgs: [serviceitem.id],
          data: serviceitem,
          callback: (err, res) => {
            if (err) {
              console.log(err);
            }
          },
        });
      }
    });
    this.props.refetch();
  }

  updateService(data: any, updatingsort: boolean) {
    const body = removeNullProps(data);
    restAPI({
      endpointName: "suUpdateService",
      urlArgs: [data.id],
      data: body,
      callback: (err, res) => {
        if (!err) {
          this.setState({ editService: null });
          this.props.refetch();
        }
      },
    });
  }

  addService(data: any) {
    const body = removeNullProps(data);
    body.sort_order = this.props.su_all_services.length * 10;

    restAPI({
      endpointName: "suAddService",
      urlArgs: null,
      data: body,
      callback: (err, res) => {
        if (!err) {
          this.setState({ addService: null });
          this.props.refetch();
        }
      },
    });
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const ServicesComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.ServicesAdminGQL, any, any, ClassAttributes<any>>(
    gqlQueries.super.services,
    {
      options: (props: any) => {
        return {
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, su_all_services, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true };
        }
        return {
          su_all_services,
          refetch,
        };
      },
    }
  )
)(ServicesComponentView) as React.ComponentType<any>;

export default ServicesComponent;
