import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { Loader } from "semantic-ui-react";
import { Comments } from "../../../../../../../components/Comments/comments";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

import "./detail.css";

@track(
	props => {
		return {
			event_type: props.tracking_path ? AnalyticsEventType.SUBNAV : AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}notes`
		};
	},
	{ dispatchOnMount: false }
)
export class StageBreakdownNotesView extends React.Component<LMI.IStageBreakdownNotesProps, LMI.IStageBreakdownNotesState> {
	constructor(props: LMI.IStageBreakdownNotesProps) {
		super(props);

		this.state = {
			data: null
		};
	}

	componentDidUpdate() {
		if (this.props.notes) {
			this.props.tracking.trackEvent({
				event_type: AnalyticsEventType.SUBNAV,
				event_subtype: `${this.props.tracking_path ? this.props.tracking_path + "." : ""}notes`,
				data: { details: { notes: this.props.notes } }
			});
		}
	}

	static formatData = ({ data }) => {
		return data.map((item, i) => {
			return {
				...item,
				comment: item.data.comment,
				description: item.data.description
			};
		});
	};

	render() {
		const { loading } = this.props;
		const { data } = this.state;

		if (loading) return <Loader className={`loader active`} size="small" />;

		const commentProps: LMI.IComments = {
			less_descriptive: false,
			is_timestamp: true,
			include_time: true,
			data
		};

		return <Comments {...commentProps} />;
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const result = {};
		if (nextProps.notes) {
			const formattedData = StageBreakdownNotesView.formatData({
				data: nextProps.notes
			});
			if (formattedData !== prevState.data) {
				result["data"] = formattedData;
			}
		}
		return result;
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const StageBreakdownNotes = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.stageBreakdownDetailNotes, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.store_id, 10),
					inventoryId: props.inventory_id
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, stage_breakdown_detail_notes, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				notes: stage_breakdown_detail_notes.notes,
				refetchSearch: refetch
			};
		}
	})
)(StageBreakdownNotesView) as React.ComponentType<any>;
