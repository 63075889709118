import { restAPI } from "../../../../../utils/rest";
import { gqlQueries } from "gql-imports";
import { Loading } from "client/components/Loading";
import { DataFilterComponent } from "client/pages/admin/components/DataFilterComponent";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { UniversalActionModalComponent } from "client/pages/admin/components/UniversalActionModalComponent";

import { hasPermission } from "client/utils/userAccess";
import { Permission } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Button, Divider, Dropdown, Icon, Item } from "semantic-ui-react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { enableUABCallback, triggerUABCallback } from "api/redux/actions";
import { withUAB } from "../../../../../pages/WithUAB";
import {
  getSortableCheckbox,
  getSortableEditable,
  SortableTable,
} from "../../SortableTable";
import { EditVendorServiceComponent } from "./components";
import { confirmDefault, ConfirmDialog } from "client/components/ConfirmDialog";
import { Session } from "client/utils/session";

import "./VendorServicesTabPanel.css";

export class VendorServicesTabPanelView extends React.Component<
  LMI.IVendorServicesTPProps,
  LMI.IVendorServicesTPState
> {
  inactiveServices: LMI.IVendorServicesListGQL[] = [];

  constructor(props) {
    super(props);

    this.state = {
      searchFilter: "",
      formData: null,
      editCost: null,
      approveService: false,
      confirm: confirmDefault,
      alert: null,
      newServices: [],
    };
  }

  render() {
    const props = this.props;

    if (props.loading) return <Loading />;
    if (props.hasErrors) {
      console.log("VendorServicesTabPanel Data errors:", props.error);
    }
    const canAdd = hasPermission(
      props.permissions,
      Permission.ADMIN_ADD_SERVICE,
      Session.get("isSuperUser")
    );
    let services: LMI.IVendorServicesTableRow[] =
      props.provided_services &&
      this.mapVendorServicesData(props.provided_services);

    if (this.state.searchFilter) {
      services = this.searchFilter(this.state.searchFilter, services);
    }

    const editCostModalProps: LMI.IEditServiceCostProps = {
      service: this.state.editCost,
      editService: (data, serviceId) => {
        const service = {
          base_price: parseInt(data.base_price, 10),
          services: serviceId,
        };
        this.addService(service);
        this.setState({ editCost: null });
      },
    };

    return (
      <div className="vendor-services panel-content">
        <ConfirmDialog {...this.state.confirm} />
        <UniversalActionModalComponent
          toggleUABCallback={props.toggleUABCallback}
          universalActionCallback={() => {
            this.setState({ newServices: [] });
            props.universalActionCallback();
          }}
          contentComponent={this.getModalForm.bind(this)}
          headerText="+ Add Services"
        />
        {this.state.editCost && (
          <ModalComponent
            headerText={this.state.editCost.name}
            shouldBeOpen={this.state.editCost ? true : false}
            onClose={(evt, data) => {
              this.setState({ editCost: null });
            }}
            size="small"
            className="edit-cost-modal"
            contentComponent={() => (
              <EditVendorServiceComponent {...editCostModalProps} />
            )}
          />
        )}
        <div className="sortable-container">
          <div className="sortable-filter">
            {(services && services.length > 0) || this.state.searchFilter ? (
              <DataFilterComponent
                label="Filter Results"
                searchFilter={this.state.searchFilter}
                onChange={(p, { value }) => {
                  this.setState({
                    searchFilter: value,
                  });
                }}
              />
            ) : (
              <span />
            )}
          </div>
          <div className="sortable-content">
            <SortableTable
              filter={this.state.searchFilter}
              message={
                "There are currently no services setup" +
                (canAdd
                  ? ". Use the [+] button on the top right to add a service."
                  : ".")
              }
              tableData={this.buildTableData(services)}
            />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    // Should we show the UAB to this user?
    if (
      !hasPermission(
        this.props.permissions,
        Permission.ADMIN_ADD_SERVICE,
        Session.get("isSuperUser")
      )
    ) {
      this.props.disableUAB();
    }
  }

  getModalForm(UABProps) {
    const { services } = this.props;
    const { newServices } = this.state;
    const disabled = newServices.length == 0;
    const currencyMask = createNumberMask({
      prefix: "$",
      suffix: "",
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ",",
      allowDecimal: true,
      decimalSymbol: ".",
      decimalLimit: 2,
      integerLimit: 7,
      allowNegative: false,
      allowLeadingZeroes: false,
    });

    return (
      <div className="multi-service-form">
        <div className="service-selection">
          <Dropdown
            fluid
            multiple
            search
            selection
            placeholder="Search/Select Service(s)"
            value={newServices ? newServices.map((i) => i.id) : []}
            options={this.inactiveServices.map((service, key) => {
              return {
                key,
                value: service.id,
                text: service.name,
              };
            })}
            onChange={(e, data: any) =>
              this.setState({
                newServices: data.value.map((id) => {
                  const existing = newServices.find((s) => s.id === id);
                  const base_price = existing ? existing.base_price : null;
                  return { id, base_price };
                }),
              })
            }
          />
          <Divider />
        </div>
        <div className="service-items">
          <Item.Group divided>
            {newServices &&
              newServices.map((newService, key) => {
                const service = services.find(
                  (service) => service.id === newService.id
                );
                return (
                  <Item key={key}>
                    <Item.Content>
                      <div className="loading">
                        {newService.saving ? (
                          <Icon loading name="spinner" />
                        ) : (
                          ""
                        )}
                        {newService.saved ? (
                          <Icon name="check" color="green" />
                        ) : (
                          ""
                        )}
                        {newService.error ? (
                          <Icon name="close" color="red" />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="ui input fluid costForm">
                        <MaskedInput
                          mask={currencyMask}
                          name="base_price"
                          placeholder="Base Price"
                          value={service.base_price}
                          onChange={(e) => {
                            const base_price =
                              e.target.value !== ""
                                ? parseFloat(
                                    e.target.value.replace(/\$|,/g, "")
                                  )
                                : null;
                            this.setState({
                              newServices: newServices.map((service) => {
                                if (service.id === newService.id)
                                  return { ...service, ...{ base_price } };
                                else return service;
                              }),
                            });
                          }}
                        />
                      </div>
                      <Item.Header as="h5">{service.name}</Item.Header>
                      <Item.Meta>
                        <span className={newService.error ? "error" : ""}>
                          {newService.error
                            ? newService.error
                            : service.description}
                        </span>
                      </Item.Meta>
                    </Item.Content>
                  </Item>
                );
              })}
          </Item.Group>
        </div>
        <div className="service-control">
          <Button.Group>
            <Button
              color="blue"
              className="submit-btn"
              type="submit"
              disabled={disabled}
              onClick={() => this.saveNewServices()}
            >
              Save and Add More
            </Button>
            <Button.Or />
            <Button
              positive
              className="submit-btn"
              type="submit"
              disabled={disabled}
              onClick={() => this.saveNewServices(true)}
            >
              Save and Close
            </Button>
          </Button.Group>
          <Button content="Cancel" onClick={UABProps.universalActionCallback} />
        </div>
      </div>
    );
  }

  buildTableData(services: LMI.IVendorServicesTableRow[]) {
    const tableHeaders: any = [
      {
        id: "name",
        label: "Name",
        sortable: true,
      },
      {
        id: "description",
        label: "Description",
        sortable: true,
      },
      {
        id: "cost",
        label: "Base Cost",
        sortable: true,
      },
      {
        id: "isActive",
        label: "Active?",
        collapsing: true,
        sortable: false,
      },
    ];

    return {
      headers: tableHeaders,
      body: { rows: services },
    };
  }

  mapVendorServicesData(
    services: LMI.SUAdminVendorServices[]
  ): LMI.IVendorServicesTableRow[] {
    const Alphebetical = (a, b) => {
      const ta = a.name.toUpperCase();
      const tb = b.name.toUpperCase();
      return ta < tb ? -1 : ta > tb ? 1 : 0;
    };

    this.inactiveServices = this.props.services
      ? this.props.services
          .filter((service) => {
            const hasService = services.findIndex(
              (vs) => vs.service && vs.service.id === service.id
            );
            if (hasService == -1) return service;
          })
          .sort(Alphebetical)
      : [];

    return services.map((service: LMI.SUAdminVendorServices) => {
      return {
        name: service.service.name || "",
        description: service.service.description || "",
        cost: {
          component: getSortableEditable,
          value: `${service.base_price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}`,
          editable: hasPermission(
            this.props.permissions,
            Permission.ADMIN_EDIT_SERVICE,
            Session.get("isSuperUser")
          ),
          float: "right",
          editcallback: () => {
            const editService = Object.assign({}, service, {
              name: service.service.name,
              id: service.service.id,
            });
            this.setState({ editCost: editService });
          },
        },
        isActive: {
          value: service.is_active || false,
          component: getSortableCheckbox,
          disabled: !hasPermission(
            this.props.permissions,
            Permission.ADMIN_REMOVE_SERVICE,
            Session.get("isSuperUser")
          ),
          callback: (data) => {
            if (service.is_active)
              this.setState({
                confirm: {
                  open: true,
                  title: "Are you sure you want to deactivate this service?",
                  text: "This action can be undone.",
                  success: () => {
                    data.success();
                    this.setState({ confirm: confirmDefault }, () =>
                      this.removeService(service.service.id)
                    );
                  },
                  failure: () => this.setState({ confirm: confirmDefault }),
                },
              });
            else this.reEnableService(service.service.id);
          },
        },
      };
    });
  }

  searchFilter(
    search: string,
    services: LMI.IVendorServicesTableRow[]
  ): LMI.IVendorServicesTableRow[] {
    const srch = search.toLowerCase();
    return services.filter((service) => {
      return (
        service.name.toLowerCase().includes(srch) ||
        service.description.toLowerCase().includes(srch) ||
        service.cost.value.toLowerCase().includes(srch)
      );
    });
  }

  onSubmitHandler(formData, reset) {
    reset();
    setTimeout(this.props.universalActionCallback, 100);
    const serviceData = {
      base_price: parseInt(formData.base_price, 10),
      services: formData.service,
    };

    this.addService(serviceData);
  }

  async saveNewServices(closeAfter = false) {
    const { newServices } = this.state;
    let errors = false;
    for (const service of newServices) {
      try {
        await this.saveService(service);
      } catch (error) {
        this.setState({
          newServices: this.state.newServices.map((serv) => {
            if (service.id === serv.id)
              return { ...serv, ...{ error: error.data.message } };
            else return serv;
          }),
        });
        errors = true;
      }
    }
    if (!errors)
      setTimeout(() => {
        const state = { newServices: [] };
        this.setState(
          state,
          () => closeAfter && this.props.universalActionCallback()
        );
      }, 300);
  }

  async saveService(newService) {
    return new Promise<void>(async (resolve, reject) => {
      const { newServices } = this.state;
      const setServiceState = (serv, saving, saved = false) => {
        return newServices.map((service) => {
          if (service.id === serv.id)
            return { ...service, ...{ saving, saved } };
          else return service;
        });
      };
      const saving = { newServices: setServiceState(newService, true) };
      const saved = { newServices: setServiceState(newService, false, true) };
      const errored = {
        newServices: setServiceState(newService, false, false),
      };
      this.setState(saving, () => {
        restAPI({
          endpointName: "addService",
          urlArgs: [parseInt(this.props.storeId, 10), newService.id],
          data: {
            base_price: newService.base_price ? newService.base_price : 0,
          },
          callback: (err, res) => {
            this.setState(err ? errored : saved, () => {
              this.props.refetch();

              if (res) resolve();
              else if (err) reject(err.reason.response.data.message);
            });
          },
        });
      });
    });
  }

  addService(data: any) {
    const storeId = parseInt(this.props.storeId, 10);
    const serviceId = parseInt(data.services, 10);
    const basePrice = Object.assign(
      {},
      { base_price: parseInt(data.base_price, 10) }
    );
    restAPI({
      endpointName: "addService",
      urlArgs: [storeId, serviceId],
      data: basePrice,
      callback: (err, res) => {
        if (err) {
          const AlertProps: LMI.IAlertsProps = {
            type: "danger",
            message: err.reason.response.data.message,
            timeout: 3000,
            onComplete: () => {
              this.setState({
                alert: null,
              });
            },
          };
          this.setState({ alert: AlertProps });
        }
        this.props.refetch();
      },
    });
  }

  removeService(serviceId: number) {
    restAPI({
      endpointName: "removeService",
      urlArgs: [Number(this.props.storeId), serviceId],
      data: null,
      callback: (err, res) => {
        if (err) {
          console.log("There was an error while removing the service", err);
        } else {
          this.props.refetch();
        }
      },
    });
  }

  reEnableService(serviceId: number) {
    restAPI({
      endpointName: "enableService",
      urlArgs: [Number(this.props.storeId), serviceId],
      data: null,
      callback: (err, res) => {
        if (err) {
          console.log("There was an error while re-enabling the service", err);
        } else {
          this.props.refetch();
        }
      },
    });
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    toggleUABCallback: state.app.admin.toggleUABCallback,
    permissions: state.app.admin.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    universalActionCallback: () => {
      dispatch(triggerUABCallback(false));
    },
    disableUAB: () => {
      dispatch(enableUABCallback(false));
    },
  };
};

const showUABOn = [{ viewType: "vendors" }];
const VendorServicesTabPanelViewUAB: any = withUAB(
  VendorServicesTabPanelView,
  showUABOn
);

export const VendorServicesTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IAllVendorServicesQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.vendor.services,
    {
      options: (props: any) => {
        return {
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, services, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          services,
          refetchServices: refetch,
        };
      },
    }
  ),
  graphql<LMI.IVendorServicesQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.vendor.vendorServices,
    {
      options: (props: any) => {
        return {
          variables: {
            id: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };
        return {
          stre: store,
          refetch,
        };
      },
    }
  ),
  graphql<LMI.IVendorServicesQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.vendor.allVendorServices,
    {
      options: (props: any) => {
        return {
          variables: {
            vendor_id: parseInt(props.storeId, 10),
            active_only: false,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, vendor_services, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          provided_services: vendor_services.provided_services,
          refetch,
        };
      },
    }
  )
)(VendorServicesTabPanelViewUAB) as React.ComponentType<any>;

export default VendorServicesTabPanel;
