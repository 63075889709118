import * as React from "react";
import { Dropdown, Label, Icon, DropdownProps } from "semantic-ui-react";

interface InspectionTypeSelectProps extends LMI.InspectionItemTypeSelectorProps, DropdownProps {
	customProps?: DropdownProps;
}

export class InspectionTypeSelect extends React.Component<InspectionTypeSelectProps, undefined> {
	getOptions() {
		const { types, selected_id, templates } = this.props;
		let options: any = types
			.filter(i => i.is_active || i.id === selected_id)
			.map((op, key) => ({
				key,
				text: op.op_code_with_name,
				value: op.id
			}));

		if (templates && templates.length)
			options = [
				...templates.map(template => ({
					key: `t-${template.id}`,
					value: `t-${template.id}`,
					text: template.name,
					className: "template-option",
					icon: { name: "th list", color: "pink", className: "template-icon" }
				})),
				...options
			];

		const currentlySelected = selected_id ? options.find(o => o.value === selected_id) : { value: "" };
		return { options, currentlySelected };
	}

	onTypeSelect(value: any) {
		const { types, templates, onSave, onLoadTemplate } = this.props;
		if (typeof value == "string") {
			const templateId = value.replace(/t-/g, "");
			if (templates && templates.length > 0) {
				const selectedTemplate = templates.find(i => i.id == parseInt(templateId));
				if (selectedTemplate) onLoadTemplate(selectedTemplate);
			}
		} else if (typeof value == "number") onSave(types.find(type => type.id === value));
	}

	getTypeSelect() {
		const { id, disabled, clearable, placeholder, can_add, customProps, onAddItem, onOpen, onClose } = this.props;
		const { options, currentlySelected } = this.getOptions();
		const props = {
			placeholder,
			options,
			disabled,
			clearable,
			value: currentlySelected.value,
			onOpen,
			onClose,
			onAddItem,
			onChange: (e, data) => this.onTypeSelect(data.value),
			className: "inv-type-selector",
			...customProps
		};

		return (
			<Dropdown
				id={id}
				key={id}
				fluid
				search
				allowAdditions={can_add}
				scrolling
				button
				selection
				upward={false}
				selectOnBlur={false}
				additionPosition="bottom"
				additionLabel={
					<span>
						<Icon name="add circle" color="green" /> ADD{" "}
					</span>
				}
				{...props}
			/>
		);
	}

	render() {
		const { types } = this.props;
		if (!types || (types && types.length == 0)) return <Label content="No Types Configured" />;
		return (
			<div id="TypeCatagorySelect" className="type-catagory">
				{this.getTypeSelect()}
			</div>
		);
	}
}

export default InspectionTypeSelect;
