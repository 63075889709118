import * as React from "react";
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Loader,
  Segment,
} from "semantic-ui-react";
import { handleErrorResponse } from "../../../../../../utils/functions";
import * as Raven from "raven-js";
import { addAlert } from "api/redux/actions";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { restAPI } from "../../../../../../utils/rest";

import "./AddLeaseReturnForm.css";

export class AddLeaseReturnFormView extends React.Component<
  LMI.IALRFVProps,
  LMI.IALRFVState
> {
  constructor(props: LMI.IALRFVProps) {
    super(props);

    this.state = {
      validating: true,
      selected: null,
      customer_name: null,
      searching: false,
    };
  }

  sendAlert({ type, message }) {
    this.props.addAlert({
      type,
      message,
      timeout: 3000,
    });
  }

  getEmptyResult() {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name="search" />
          Sorry, no inventory currently matches your search
        </Header>
        <Segment.Inline>
          <Button onClick={() => this.props.searchAgain("")}>
            Search Again
          </Button>
        </Segment.Inline>
      </Segment>
    );
  }

  getInventoryItem(item: LMI.IInventoryGQL, selected = false) {
    const image = item.stock_image_url
      ? `${item.stock_image_url}?h=60&w=104&auto=compress&txt=STOCK%20IMAGE&txt-size=12&txt-color=ffffff57&txt-align=middle,center&txt-font=Futura%20Condensed%20Medium`
      : `https://loopmein-dev.imgix.net/default_vehicle_image.png`;
    return (
      <Grid.Row
        key={item.id}
        onClick={() => this.setState({ selected: item })}
        className={`validation-form ${selected ? "selected" : "selectable"}`}
      >
        <Grid.Column width={3}>
          <Image id="stockImage" src={image} rounded loading="lazy" />
        </Grid.Column>
        <Grid.Column width={13}>
          <Grid.Row id="vin">
            <b>VIN:</b> {item.vin}
          </Grid.Row>
          <Grid.Row className="ymm">
            <b>Vehicle: </b>
            {item.model_year} {item.make} {item.model}
          </Grid.Row>
          <Grid.Row id="stock">
            <b>Trim:</b> {item.trim}
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    const { searchAgain, onCreateLeaseReturn, VIN } = this.props;
    const { inventory_results, customer_name, validating } = this.state;

    if (validating)
      return (
        <Dimmer active inverted>
          <Loader inverted>Validating VIN</Loader>
        </Dimmer>
      );

    if (inventory_results && inventory_results[0]) {
      const inventoryItem = inventory_results[0];
      return (
        <div className="selected-item">
          <Grid>
            {this.getInventoryItem(inventoryItem, true)}
            <Grid.Row>
              <Grid.Column width={5}>
                <Input
                  id="customerName"
                  onChange={(e, data) =>
                    this.setState({ customer_name: data.value })
                  }
                  placeholder="Customer Name (required)"
                />
              </Grid.Column>
              <Grid.Column width={11}>
                <Container textAlign="right">
                  <Button
                    type="submit"
                    size="large"
                    onClick={() => searchAgain("")}
                  >
                    Try Another VIN
                  </Button>
                  <Button
                    type="submit"
                    positive
                    size="large"
                    disabled={!customer_name}
                    onClick={() =>
                      onCreateLeaseReturn(inventoryItem, customer_name)
                    }
                  >
                    Create Lease Return
                  </Button>
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      );
    } else return <div>No result for VIN: {VIN}</div>;
  }

  componentDidMount() {
    restAPI({
      endpointName: "validate_vin",
      urlArgs: [this.props.storeId, this.props.VIN],
      data: null,
      callback: (error, result) => {
        if (error) {
          this.setState({ saving: false });
          this.sendAlert({
            type: "error",
            message: handleErrorResponse({ error }),
          });
          Raven.captureException(error);
          console.error(error);
        } else {
          if (result.data.vehicle) {
            this.setState(
              {
                validating: false,
                inventory_results: [result.data.vehicle],
              },
              () => this.props.setHasResults(false)
            );
          } else {
            this.setState({ validating: false }, () => {
              this.props.searchAgain(
                `No matching vehicle found with VIN: ${this.props.VIN}`
              );
            });
          }
        }
      },
    });
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const AddLeaseReturnForm = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(AddLeaseReturnFormView);

export default AddLeaseReturnForm;
