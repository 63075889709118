import { closest, enableRipple } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DateRangePickerComponent, PresetDirective, PresetsDirective } from "@syncfusion/ej2-react-calendars";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import {
	Column,
	ColumnDirective,
	ColumnsDirective,
	CommandColumn,
	ExcelExport,
	Filter,
	GridComponent,
	Group,
	GroupSettingsModel,
	Inject,
	IRow,
	Page,
	PdfExport,
	Search,
	SelectionSettingsModel,
	Sort,
	Toolbar
} from "@syncfusion/ej2-react-grids";
import "@syncfusion/ej2-react-grids/styles/material.css";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { Form, Input } from "formsy-semantic-ui-react";
import { addAlert } from "api/redux/actions";

import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { Button, Confirm, Loader, Message, Segment } from "semantic-ui-react";
import { restAPI } from "../../../../../utils/rest";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

import "./AccessoriesReportTabPanel.css";

// Add button ripple effect
enableRipple(true);

@track({ event_type: AnalyticsEventType.NAVIGATION, event_subtype: "accessories-report" }, { dispatchOnMount: true })
export class AccessoriesReportView extends React.Component<LMI.IReportsTPProps, any> {
	state: any = {};
	grid: GridComponent;
	dialog: DialogComponent;
	cellSelectionSettings: SelectionSettingsModel = { cellSelectionMode: "Box", type: "Single", mode: "Cell" };
	groupOptions: GroupSettingsModel = { columns: ["responsible_employee_name"] };

	// Preselected date ranges
	today: Date = new Date(new Date().toDateString());
	weekStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))).toDateString());
	weekEnd: Date = new Date(
		new Date(new Date().setDate(new Date(new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))).getDate() + 6)).toDateString()
	);
	monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
	monthEnd: Date = this.today;
	lastStart: Date = new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).toDateString());
	lastEnd: Date = this.today;
	yearStart: Date = new Date(new Date(new Date().setDate(new Date().getDate() - 365)).toDateString());
	yearEnd: Date = this.today;

	customCommentsAttributes: any = { class: "custom-comments" };

	constructor(props) {
		super(props);
		this.state = {
			accessories: null,
			showAccDialog: false,
			notes_title: "",
			notes: "",
			accRecovered: false,
			accUnrecovered: true,
			accBoth: false,
			accSummary: null,
			cancelOnConfirm: null,
			noneFound: false
		};
	}

	UNSAFE_componentWillMount(): void {
		this.updateGridData();
	}

	formatAccessory(accessories: any): LMI.IAccessoryDetails {
		const filterReceived = this.state.accRecovered ? true : this.state.accUnrecovered ? false : null;
		const summary = { recovered: 0, unrecovered: 0, canceled: 0 };
		accessories.forEach(item => {
			if (item.received_at !== null) {
				summary.recovered++;
			}
			if (item.received_at === null) {
				summary.unrecovered++;
			}
			if (item.canceled_at !== null) {
				summary.canceled++;
			}
		});
		this.setState({ accSummary: summary });

		if (filterReceived !== null) {
			if (filterReceived) {
				accessories = accessories.filter(item => item.received_at !== null);
			} else {
				accessories = accessories.filter(item => item.received_at === null);
			}
		}
		return accessories
			.filter(item => item.is_active)
			.map(item => {
				return {
					id: item.id,
					inventory_item_id: item.inventory_item_id,
					responsible_employee_name: item.responsible_employee_name,
					stock_number: item.stock_number,
					year: item.year,
					make: item.make,
					model: item.model,
					item_desc: item.accessory_name,
					comments: item.comments,
					date_added: moment(item.created_at).isValid() ? moment(item.created_at).format("L") : "",
					date_received: moment(item.received_at).isValid() ? moment(item.received_at).format("L") : "",
					received_at: item.received_at,
					received_by: item.received_by_name,
					canceled_at: item.canceled_at,
					canceled_by: item.canceled_by,
					sold: !item.inventory_is_active
				};
			});
	}

	fileExpComplete = args => {
		args.promise.then(e => {
			const blobURL = URL.createObjectURL(e.blobData);
			window.open(blobURL);
		});
	};

	onToolbarClick(args) {
		switch (args.item.id) {
			case "expand":
				this.grid.groupModule.expandAll();
				break;
			case "collapse":
				this.grid.groupModule.collapseAll();
				break;
			case "reset":
				this.setState({ accessories: null, accRecovered: false, accUnrecovered: true, accBoth: false }, () => {
					this.updateGridData();
					this.grid.clearFiltering();
				});
				break;
			case "pdfexport":
				const exportProperties: any = {
					pageOrientation: "Landscape",
					theme: {
						header: {
							fromTop: 0,
							height: 130
						},
						record: {
							fontColor: "#000000",
							fontName: "Calibri",
							fontSize: 8,
							bold: false,
							maxWidth: 80
						},
						caption: {
							fontColor: "#4a4a4a",
							fontName: "Calibri",
							fontSize: 8,
							bold: true
						}
					}
				};
				// @ts-ignore
				this.grid.columns[2]["visible"] = false;
				// @ts-ignore
				this.grid.columns[3]["visible"] = false;
				// @ts-ignore
				this.grid.columns[4]["visible"] = false;
				this.grid.pdfExport(exportProperties, null, null, true);
				break;
			case "excelexport":
				this.grid.excelExport(null, null, null, false);
				break;
			default:
				break;
		}
	}

	onReceived = (args: any): void => {
		const rowObj: IRow<Column> = this.grid.getRowObjectFromUID(closest(args.target, ".e-row").getAttribute("data-uid"));
		const data: LMI.INotes = rowObj.data as LMI.INotes;
		this.setState({
			showAccDialog: true,
			notes: { id: data.id, inventory_item_id: data.inventory_item_id, comments: "", title: "Add Receive Notes", mode: "receive" }
		});
	};

	onDelete = (args: any): void => {
		const rowObj: IRow<Column> = this.grid.getRowObjectFromUID(closest(args.target, ".e-row").getAttribute("data-uid"));
		const data: LMI.INotes = rowObj.data as LMI.INotes;
		this.setState({
			cancelOnConfirm: data
		});
	};

	onLog = (args: any): void => {
		const rowObj: IRow<Column> = this.grid.getRowObjectFromUID(closest(args.target, ".e-row").getAttribute("data-uid"));
		const data: LMI.INotes = rowObj.data as LMI.INotes;
		this.setState({
			showAccDialog: true,
			notes: { id: data.id, inventory_item_id: data.inventory_item_id, comments: "", title: "Add Accessory Log", mode: "log" }
		});
	};

	onDateRangeSelect = (args: any): void => {
		if (args.startDate && args.endDate) {
			const startDate = moment(args.startDate, "M/D/YYYY").format("YYYY-MM-DD 00:00:00ZZ");
			const endDate = moment(args.endDate, "M/D/YYYY").format("YYYY-MM-DD 00:00:00ZZ");
			this.setState({ accStartDate: startDate, accEndDate: endDate }, () => this.updateGridData());
		} else if (args.event && args.event.target.classList.contains("e-clear-icon")) {
			this.setState({ accStartDate: null, accEndDate: null }, () => this.updateGridData());
		}
	};

	queryCellInfo = args => {
		if (args.column.headerText === "Actions") {
			args.cell.classList.add("action-cell");
			if (args.data.date_received) {
				args.cell.firstChild.children[0].disabled = true;
				args.cell.firstChild.children[0].classList.add("e-disabled");
				args.cell.firstChild.children[1].disabled = true;
				args.cell.firstChild.children[1].classList.add("e-disabled");
			}
		}
	};

	dialogClose = () => {
		this.setState({ showAccDialog: false, notes: "" });
	};

	onOverlayClick = () => {
		this.setState({ showAccDialog: false, notes: "" });
	};

	submitNotesForm = data => {
		// event.preventDefault();
		this.setState({ showAccDialog: false, notes: "" });
		// data.mode = comments || receive || log
		switch (data.mode) {
			case "comments":
				this.onUpdateAccessory(data, "Comments successfully updated");
				break;
			case "receive":
				this.onReceiveAccessory(data, "Accessory successfully received");
				break;
			case "log":
				this.onLogAccessory(data, "Accessory log successfully added");
				break;
			default:
				break;
		}
	};

	onUpdateAccessory = (data, successMessage) => {
		restAPI({
			endpointName: "updateStoreAccessory",
			urlArgs: [this.props.storeId, data.inventory_item_id, data.id],
			data: { comments: data.comments },
			callback: (error, result) => {
				let alert;
				if (error) {
					alert = { type: "danger", message: `Error updating accessory: ${error}` };
				} else {
					alert = { type: "success", message: `${successMessage}` };
				}
				this.sendAlert(alert);
				this.updateGridData();
			}
		});
	};

	onReceiveAccessory = (data, successMessage) => {
		restAPI({
			endpointName: "receiveStoreAccessory",
			urlArgs: [this.props.storeId, data.inventory_item_id, data.id],
			data: { comment: data.comments },
			callback: (error, result) => {
				let alert;
				if (error) {
					alert = { type: "danger", message: `Error receiving accessory: ${error}` };
				} else {
					alert = { type: "success", message: `${successMessage}` };
				}
				this.sendAlert(alert);
				this.updateGridData();
			}
		});
	};

	onLogAccessory = (data, successMessage) => {
		restAPI({
			endpointName: "logStoreAccessory",
			urlArgs: [this.props.storeId, data.inventory_item_id, data.id],
			data: { comment: data.comments },
			callback: (error, result) => {
				let alert;
				if (error) {
					alert = { type: "danger", message: `Error logging accessory: ${error}` };
				} else {
					alert = { type: "success", message: `${successMessage}` };
				}
				this.sendAlert(alert);
				this.updateGridData();
			}
		});
	};

	onCancelAccessory = (data, successMessage) => {
		restAPI({
			endpointName: "cancelStoreAccessory",
			urlArgs: [this.props.storeId, data.inventory_item_id, data.id],
			data: null,
			callback: (error, result) => {
				let alert;
				if (error) {
					alert = { type: "danger", message: `Error updating accessory: ${error}` };
				} else {
					alert = { type: "success", message: `${successMessage}` };
				}
				this.sendAlert(alert);
				this.updateGridData();
				this.setState({ cancelOnConfirm: null });
			}
		});
	};

	updateGridData = () => {
		const { accStartDate: startDate, accEndDate: endDate } = this.state;
		const data = { start_date: startDate, end_date: endDate };

		restAPI({
			endpointName: "getStoreAccessories",
			urlArgs: [this.props.storeId],
			data,
			callback: (error, result) => {
				this.setState({ accessories: this.formatAccessory(result.data) });
			}
		});
	};

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
	}

	onChangeNotes = event => {
		const notes = Object.assign(this.state.notes, { comments: event.target.value });
		this.setState({ notes });
	};

	getStockTemplate(data: any): JSX.Element {
		return (
			<div className="stock-wrapper" style={{ width: "inherit", height: "inherit" }}>
				{data.stock_number}
				{data.sold && <span className="e-badge e-badge-success badge">sold</span>}
			</div>
		);
	}

	selectFilter = event => {
		switch (event.target.id) {
			case "recovered":
				this.setState({ accRecovered: true, accUnrecovered: false, accBoth: false, accessories: null }, () => this.updateGridData());
				break;
			case "unrecovered":
				this.setState({ accRecovered: false, accUnrecovered: true, accBoth: false, accessories: null }, () => this.updateGridData());
				break;
			case "both":
				this.setState({ accRecovered: false, accUnrecovered: false, accBoth: true, accessories: null }, () => this.updateGridData());
				break;
			default:
				break;
		}
	};

	toolbarOptions = (): any => {
		return [
			{ tooltipText: "Expand All Groups", prefixIcon: "e-icons e-expand", id: "expand" },
			{ tooltipText: "Collapse All Groups", prefixIcon: "e-icons e-collapse", id: "collapse" },
			{ text: "Search", id: "search" },
			{ text: "Excel", tooltipText: "Excel Export", prefixIcon: "e-icons e-excel", id: "excelexport", align: "Right" },
			{ text: "PDF", tooltipText: "PDF Export", prefixIcon: "e-icons e-pdf", id: "pdfexport", align: "Right" }
		];
	};

	cellSelection = (args: any) => {
		// Comments column is cellIndex 6
		if (args.cellIndex.cellIndex === 6) {
			const { comments, id, inventory_item_id } = args.data;
			this.setState({ showAccDialog: true, notes: { id, inventory_item_id, comments, title: "Edit Comments", mode: "comments" } });
		}
		args.cancel = true;
	};

	getActionCommands = () => {
		return [
			{ buttonOption: { cssClass: "e-flat e-receive-button", iconCss: "e-receive e-icons", click: this.onReceived.bind(this) }, title: "Mark Received" },
			{ buttonOption: { cssClass: "e-flat e-delete-button", iconCss: "e-delete e-icons", click: this.onDelete.bind(this) }, title: "Delete Accessory" },
			{ buttonOption: { cssClass: "e-flat e-log-button", iconCss: "e-log e-icons", click: this.onLog.bind(this) }, title: "Add Worklog" }
		];
	};

	getButtonGroupTemplate = () => {
		return (
			<div className="e-btn-group toolbar-buttongroup">
				<ButtonComponent
					id="unrecovered"
					className={this.state.accUnrecovered ? "toolbar-button-selected" : "toolbar-button"}
					content="Unrecovered"
					onClick={this.selectFilter.bind(this)}
				/>
				<ButtonComponent
					id="recovered"
					className={this.state.accRecovered ? "toolbar-button-selected" : "toolbar-button"}
					content="Recovered"
					onClick={this.selectFilter.bind(this)}
				/>
				<ButtonComponent
					id="both"
					className={this.state.accBoth ? "toolbar-button-selected" : "toolbar-button"}
					content="Both"
					onClick={this.selectFilter.bind(this)}
				/>
			</div>
		);
	};

	getSummaryTemplate = () => {
		const { unrecovered, recovered, canceled } = this.state.accSummary;
		return (
			<div className="summary-group accessories">
				<div className="summary-item">Unrecovered: {unrecovered}</div>
				<div className="summary-item left-border">Recovered: {recovered}</div>
				<div className="summary-item left-border">Canceled: {canceled}</div>
			</div>
		);
	};

	getDateRangeTemplate = () => {
		return (
			<DateRangePickerComponent id="daterangepicker" cssClass="customCSS" placeholder="Select 'Date Added' range" change={this.onDateRangeSelect}>
				<PresetsDirective>
					<PresetDirective label="Today" start={this.today} end={this.today} />
					<PresetDirective label="This Week" start={this.weekStart} end={this.weekEnd} />
					<PresetDirective label="This Month" start={this.monthStart} end={this.monthEnd} />
					<PresetDirective label="Last Month" start={this.lastStart} end={this.lastEnd} />
					<PresetDirective label="Last Year" start={this.yearStart} end={this.yearEnd} />
				</PresetsDirective>
			</DateRangePickerComponent>
		);
	};

	getDialogTemplate = () => {
		return (
			<DialogComponent
				width="550px"
				isModal={true}
				overlayClick={this.onOverlayClick}
				target=".grid-container"
				visible={this.state.showAccDialog}
				close={() => this.dialogClose}
				ref={dialog => (this.dialog = dialog)}
			>
				<Form className="dialog-form" onSubmit={this.submitNotesForm.bind(this)}>
					<Form.Field>
						<label>{this.state.notes.title}</label>
						<Input type="text" name="comments" value={this.state.notes.comments} onBlur={this.onChangeNotes} />
						<Input type="hidden" className="hidden" name="inventory_item_id" value={this.state.notes.inventory_item_id} />
						<Input type="hidden" className="hidden" name="id" value={this.state.notes.id} />
						<Input type="hidden" className="hidden" name="mode" value={this.state.notes.mode} />
					</Form.Field>
					<Segment basic textAlign="right">
						<Button.Group>
							<Button type="button" onClick={this.dialogClose}>
								Cancel
							</Button>
							<Button.Or />
							<Button positive className="submit-btn" type="submit">
								Save
							</Button>
						</Button.Group>
					</Segment>
				</Form>
			</DialogComponent>
		);
	};

	onBeforeDataBound = (args): void => {
		setTimeout(() => {
			if (args.count > 0) {
				if (this.state.noneFound) this.setState({ noneFound: false });
				const fn = () => {
					this.grid.showSpinner();
					this.grid.off("toolbar-refresh", fn);
				};
				this.grid.on("toolbar-refresh", fn);
			} else if (args.count <= 0) {
				this.showNotFound();
				this.grid.hideSpinner();
			}
		}, 500);
	};

	showNotFound() {
		if (!this.state.noneFound) {
			this.setState({
				noneFound: {
					message: "Currently No Accessories"
				}
			});
		}
	}

	noResults() {
		if (this.state.noneFound) {
			return (
				<div id="no-results" className="top in" style={{ top: "420px" }}>
					<Message content={this.state.noneFound.message} />
				</div>
			);
		}
	}

	render() {
		const { accessories, accSummary } = this.state;
		if (!accessories) return <Loader active={true} />;
		return (
			<div className="grid-container searchable-grid">
				{this.noResults()}
				<Confirm
					open={this.state.cancelOnConfirm ? true : false}
					size="tiny"
					header="Please Confirm"
					content="Cancel this Accessory? This action cannot be undone."
					cancelButton="Nevermind"
					confirmButton="Yes, Confirm"
					onCancel={() => this.setState({ cancelOnConfirm: null })}
					onConfirm={() => this.onCancelAccessory(this.state.cancelOnConfirm, "Accessory successfully deleted")}
				/>
				{accSummary && this.getSummaryTemplate()}
				<div className="toolbar-group accessories">
					{this.getDateRangeTemplate()}
					{this.getButtonGroupTemplate()}
				</div>
				{this.state.showAccDialog ? this.getDialogTemplate() : ""}
				<GridComponent
					enableHover={false}
					allowSelection={true}
					allowSorting={true}
					selectionSettings={this.cellSelectionSettings}
					cellSelecting={this.cellSelection}
					dataSource={accessories}
					// beforeDataBound={this.onBeforeDataBound}
					allowGrouping={true}
					allowPaging={false}
					allowFiltering={false}
					toolbar={this.toolbarOptions()}
					allowPdfExport={true}
					allowExcelExport={true}
					toolbarClick={this.onToolbarClick.bind(this)}
					pdfExportComplete={this.fileExpComplete.bind(this)}
					excelExportComplete={this.fileExpComplete.bind(this)}
					groupSettings={this.groupOptions}
					queryCellInfo={this.queryCellInfo}
					ref={g => (this.grid = g)}
					height="100%"
					gridLines="Both"
				>
					<ColumnsDirective>
						<ColumnDirective field="responsible_employee_name" headerText="Salesperson" clipMode="EllipsisWithTooltip" width="110" textAlign="Left" />
						<ColumnDirective field="stock_number" headerText="Stock" width="115" template={this.getStockTemplate} textAlign="Left" />
						<ColumnDirective field="year" headerText="Year" width="90" textAlign="Center" />
						<ColumnDirective field="make" headerText="Make" width="100" textAlign="Left" />
						<ColumnDirective field="model" headerText="Model" width="130" textAlign="Left" />
						<ColumnDirective field="item_desc" headerText="Item Desc" width="140" textAlign="Left" />
						<ColumnDirective
							field="comments"
							headerText="Comments"
							clipMode="EllipsisWithTooltip"
							customAttributes={this.customCommentsAttributes}
							width="180"
							textAlign="Left"
							allowFiltering={false}
						/>
						<ColumnDirective field="date_added" headerText="Added" width="110" textAlign="Center" />
						<ColumnDirective field="date_received" headerText="Received" width="110" textAlign="Left" />
						<ColumnDirective field="received_by" headerText="Received By" width="130" textAlign="Left" />
						<ColumnDirective headerText="Actions" width="110" clipMode="Clip" commands={this.getActionCommands()} allowFiltering={false} textAlign="Left" />
					</ColumnsDirective>
					<Inject services={[Group, Toolbar, Search, Filter, Sort, ExcelExport, PdfExport, Page, CommandColumn]} />
				</GridComponent>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const AccessoriesReportTabPanel = compose(connect(mapStateToProps, mapDispatchToProps))(AccessoriesReportView) as React.ComponentType<any>;

export default AccessoriesReportTabPanel;
