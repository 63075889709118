// node_modules
import { SearchComponent } from "client/pages/admin/components/SearchComponent";

import { gqlQueries } from "gql-imports";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import ReconInventorySearchResults from "./ReconInventorySearchResults";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

@track(
	props => {
		return { event_type: AnalyticsEventType.NAVIGATION, event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}search-view` };
	},
	{ dispatchOnMount: true }
)
export class ReconInventorySearchView extends React.Component<LMI.IReconInventorySearchProps, LMI.IReconInventorySearchState> {
	constructor(props: any) {
		super(props);

		this.state = {
			searchFilter: null
		};
	}

	render() {
		const { onClose, history, dealer_phases, final_phases } = this.props;
		const { searchFilter } = this.state;

		const trackingData = this.props.tracking.getTrackingData();
		const trackingPath = trackingData ? trackingData.event_subtype : "recon.inventory.search-view";
		const searchProps: LMI.ISearchProps = {
			autoFocus: true,
			placeholder: "Search by vehicle spec (vin, stock, year, make, model, etc)",
			onSubmit: data => this.setState({ searchFilter: data.term }),
			onEmpty: () => this.setState({ searchFilter: null }),
			tracking_path: trackingPath
		};

		const searchResultProps = {
			dealer_phases,
			final_phases,
			term: searchFilter,
			onClose,
			history,
			tracking_path: trackingPath
		};

		return (
			<div id="stage-inventory-search">
				<SearchComponent {...searchProps} />
				{searchFilter && <ReconInventorySearchResults {...searchResultProps} />}
				<span id="SearchForTour" onClick={() => this.setState({ searchFilter: "tour" })} />
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => ({});

const ReconInventorySearch = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.phases, {
		options: (props: any) => {
			return {
				variables: {
					// Default to BOTH new and used by omitting hasNew, hasUsed vars here
					storeId: parseInt(props.storeId, 10),
					hasNew: null,
					hasUsed: null
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, dealer_phases, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				dealer_phases: dealer_phases.phases,
				final_phases: dealer_phases.phases ? dealer_phases.phases.filter(i => i.final_stage).map(ph => ph.id) : [],
				refetch_recon: refetch
			};
		}
	})
)(ReconInventorySearchView) as React.ComponentType<any>;

export default ReconInventorySearch;
