import ToursIntroPopup, {
  ToursIntroPopupProps,
} from "../../../../../../components/Tours/ToursIntroPopup";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";

import track from "react-tracking";
import moment from "moment";
import * as React from "react";
import { ClassAttributes } from "react";
import { AnalyticsEventType, InventoryType } from "loopmein-shared";
import { createElement, enableRipple } from "@syncfusion/ej2-base";
import { rangeBucketDefaults } from "client/utils/globals";
import { formatCurrency, multiTokenFilter } from "client/utils/functions";
import { addAlert } from "api/redux/actions";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { gqlQueries } from "gql-imports";
import { graphql } from "@apollo/react-hoc";
import { printWindow } from "client/utils/fileHandling";

import RenderDataGrid, {
  DataGridProps,
  GridColumnHeaders,
  GridToolOptions,
} from "client/components/DataGrid/DataGrid";
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Popup,
  SemanticICONS,
  Table,
} from "semantic-ui-react";
import { Filter, GridComponent, IFilter } from "@syncfusion/ej2-react-grids";
import { Loading } from "client/components/Loading";
import { DialogComponent } from "@syncfusion/ej2-react-popups";

import { GridPhaseTimer } from "./GridPhaseTimer";
import { GridTotalTimer } from "./GridTotalTimer";
import { GridWorkTimer } from "./GridWorkTimer";
import { WorkflowService } from "../../../WorkflowTools";
import {
  InventoryDetail,
  VehiclePhoto,
} from "../../../InventoryDetailComponent";
import { Session } from "client/utils/session";

import "../../InventoryTabPanel/InventoryTabPanel.css";
import "./ReconInventory.css";

enableRipple(true);

@track(
  {
    event_type: AnalyticsEventType.NAVIGATION,
    event_subtype: "recon.inventory",
  },
  { dispatchOnMount: true }
)
export class ReconInventoryView extends React.Component<
  LMI.IReconInventoryProps,
  LMI.IReconInventoryState
> {
  buckets = ["grey", "grey", "orange", "red"];
  actions = ["Export"];
  dialog: DialogComponent;
  grid: GridComponent;
  holdInventory = [];
  abortController = new AbortController();
  vehicleFilter: any;
  workInterval: any;

  constructor(props: LMI.IReconInventoryProps) {
    super(props);

    const collapsed =
      localStorage.getItem("stage_collapsed") === "true" ? true : false;
    this.state = {
      detail_open: props.path ? true : false,
      detail: null,
      addVehicle: false,
      searchData: [],
      search_is_set: false,
      update_key: null,
      detail_id: null,
      sub_id: null,
      vehicleSearchString: null,
      collapsed: collapsed ? collapsed : false,
    };
  }

  setGrid(grid, selected) {
    this.grid = grid;
    this.props.setGrid(grid);
    if (
      selected &&
      this.props.inventory_results &&
      this.props.inventory_results.length > 0
    )
      setTimeout(() => this.grid.selectRow(selected), 600);
  }

  handleErrorResponse(error: any) {
    if (error.reason && error.reason.response && error.reason.response.data) {
      return error.reason.response.data.message;
    } else {
      console.log(error);
      return "There was an error.";
    }
  }

  sendAlert({ type, message }) {
    this.props.addAlert({
      type,
      message,
      timeout: 3000,
    });
    if (type === "danger") {
      console.log(`Error: ${message}`);
    }
  }

  getReconLevelName(detail: any): string {
    if (!detail.recon_level_id) return "";
    const level =
      this.props.recon_levels &&
      this.props.recon_levels.find((rl) => rl.id === detail.recon_level_id);
    return level ? level.name : "Not Set";
  }

  selectNewTopRow = () => {
    // Select new top row
    if (this.props.inventory_results && this.props.inventory_results.length > 0)
      setTimeout(() => this.grid.selectRow(0), 1000);
  };

  getBucket = (age, ranges) => {
    const bucket = { class: this.buckets[0], id: 0 };
    if (ranges && ranges.length) {
      ranges.forEach((range, index) => {
        if (age >= range[0]) {
          if (range[1] && range[1] >= age) {
            bucket.class = this.buckets[index];
            bucket.id = index + 1;
          } else if (!range[1]) {
            bucket.class = this.buckets[index];
            bucket.id = index + 1;
          }
        }
      });
    }
    return bucket;
  };

  getEOM(age) {
    const remainder = moment().endOf("month");
    const now = moment();
    const diff = remainder.diff(now, "days");
    return age + diff;
  }

  formatGridData(inventory: LMI.IInventoryGQL[]): LMI.IInventoryGQL[] {
    // tslint:disable-next-line:one-variable-per-declaration
    let newRanges, usedRanges, certifiedRanges, bucket, eom;
    if (this.props.store_settings && this.props.store_settings.length) {
      this.props.store_settings.forEach((setting) => {
        if (setting.name === "USED_INVENTORY_RANGE_BUCKET") {
          usedRanges = setting.value
            ? JSON.parse(setting.value)
            : rangeBucketDefaults.USED_INVENTORY_RANGE_BUCKET;
        } else if (setting.name === "NEW_INVENTORY_RANGE_BUCKET") {
          newRanges = setting.value
            ? JSON.parse(setting.value)
            : rangeBucketDefaults.NEW_INVENTORY_RANGE_BUCKET;
        } else if (setting.name === "CERTIFIED_INVENTORY_RANGE_BUCKET") {
          certifiedRanges = setting.value
            ? JSON.parse(setting.value)
            : rangeBucketDefaults.CERTIFIED_INVENTORY_RANGE_BUCKET;
        }
      });
    }
    if (inventory) {
      this.holdInventory = inventory.map((item, i) => {
        const age = item.days_in_stock > 0 ? item.days_in_stock : 0;
        switch (item.condition) {
          case "Used":
            bucket = this.getBucket(age, usedRanges);
            eom = this.getBucket(this.getEOM(age), usedRanges);
            break;
          case "New":
            bucket = this.getBucket(age, newRanges);
            eom = this.getBucket(this.getEOM(age), newRanges);
            break;
          case "Certified":
            bucket = this.getBucket(age, certifiedRanges);
            eom = this.getBucket(this.getEOM(age), certifiedRanges);
            break;
          default:
            bucket = this.getBucket(age, newRanges);
            eom = this.getBucket(this.getEOM(age), newRanges);
            break;
        }

        return {
          id: item.id,
          inventory_id: item.inventory_id,
          is_active: item.is_active,
          days_in_stock: item.days_in_stock > 0 ? item.days_in_stock : 0,
          bucket,
          eom,
          condition: item.condition,
          vin: item.vin,
          short_vin: item.short_vin,
          stock_number: item.stock_number,
          photo_url: item.photo_url,
          year: item.year,
          make: item.make,
          model: item.model,
          trim: item.trim,
          miles: item.miles,
          body_style: item.body_style,
          exterior_color_shortened: item.exterior_color_shortened,
          interior_color_shortened: item.interior_color_shortened,
          selling_price: formatCurrency(parseFloat(item.selling_price), 0),
          msrp: formatCurrency(parseFloat(item.msrp), 0),
          vehicle_title: `${item.year} ${item.make} ${item.model} ${
            item.trim ? item.trim : ""
          }`,
          latitude: item.latitude,
          longitude: item.longitude,
          flagged_by: item.flagged_by,
          flagged_at: item.flagged_at,
          flagged_by_user: item.flagged_by_user,
          action: "",
          current_phase_name: item.current_phase_name,
          current_phase: item.current_phase,
          current_work_elapsed_ms: item.current_work_elapsed_ms,
          total_work_elapsed_ms: item.total_work_elapsed_ms,
          work_timer_running_since: item.work_timer_running_since,
          work_timer_running_since_ms: item.work_timer_running_since_ms,
          current_phase_elapsed_ms: item.current_phase_elapsed_ms,
          total_phase_elapsed_ms: item.total_phase_elapsed_ms,
          phase_timer_running_since: item.phase_timer_running_since,
          phase_timer_running_since_ms: item.phase_timer_running_since_ms,
          recon_level_id: item.recon_level_id,
          recon_started_at_ms: item.recon_started_at_ms,
          dispositioned_at: item.dispositioned_at,
          work_timer_started_by_name: item.work_timer_started_by_name,
          recalled_at: item.recalled_at,
          recalled_by_initials: item.recalled_by_initials,
          inventory_type_id: item.inventory_type_id,
        };
      });

      // Sort by elapsed phase time
      // @ts-ignore
      this.holdInventory.sort((a, b) => {
        const aElapsed = WorkflowService.getElapsedTime(
          a.phase_timer_running_since_ms
        );
        const bElapsed = WorkflowService.getElapsedTime(
          b.phase_timer_running_since_ms
        );
        return aElapsed < bElapsed ? 1 : -1;
      });

      return this.holdInventory;
    } else {
      return [];
    }
  }

  getVehicleTemplate = (props: any) => {
    const { collapsed } = this.state;
    const reconLevel = this.getReconLevelName(props);
    const timerProps = {
      ...this.props,
      ...props,
      store_id: this.props.storeId,
      grid: this.grid,
      onHandleErrorResponse: (error) => this.handleErrorResponse(error),
      onAlert: (alert) => this.sendAlert(alert),
      setUpdateKey: (isRunning) => this.setUpdateKey(isRunning),
    };

    return (
      <div className="recon-vehicle">
        <Grid rows={3} columns={2} textAlign="left" className="photo-section">
          <Grid.Row>
            <Grid.Column width={16} className="stock-column">
              <div
                className={`stock-number vehicle-id ${collapsed ? "tiny" : ""}`}
              >
                <Popup
                  content={
                    <div>
                      <div>
                        {props.year} {props.make} {props.model}
                      </div>
                      <div>VIN: {props.vin}</div>
                    </div>
                  }
                  inverted
                  trigger={<span>{props.stock_number}</span>}
                />
                {reconLevel && !collapsed && (
                  <span className="recon-level"> - {reconLevel}</span>
                )}
                {!collapsed && this.getTypeMarker(props.inventory_type_id)}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column
              width={5}
              className={`photo-column ${collapsed ? "wider" : ""}`}
            >
              <VehiclePhoto
                detail={props}
                imageClass="vehicle-photo"
                photoParams={`&q=50&w=90&ch=Save-Data`}
                rounded={true}
                enableSoldBanner={true}
                bannerScale={40}
              />
              {collapsed && (
                <Grid className="timers">
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <GridPhaseTimer
                        key={this.props.id}
                        {...{ ...timerProps, classes: "phase-days-collapsed" }}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <GridTotalTimer
                        key={this.props.id}
                        {...{ ...timerProps, classes: "total-days-collapsed" }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Grid.Column>
            {!collapsed && (
              <Grid.Column width={11} className="content-column">
                <GridPhaseTimer key={this.props.id} {...timerProps} />
                <GridTotalTimer key={this.props.id} {...timerProps} />
                <Popup
                  content={`${props.year} ${props.make} ${props.model}`}
                  inverted
                  trigger={
                    <div className="yearMakeModel">
                      {props.year} {props.make} {props.model}
                    </div>
                  }
                />
                <div>{props.exterior_color_shortened}</div>
                <div>VIN: {props.vin}</div>
                <div>Odometer: {props.miles}</div>
              </Grid.Column>
            )}
          </Grid.Row>
          {!collapsed && (
            <Grid.Row id="gridWorkTimeRow">
              <GridWorkTimer key={this.props.id} {...timerProps} />
            </Grid.Row>
          )}
        </Grid>
      </div>
    );
  };

  getTypeMarker(type: InventoryType) {
    const wholesale = type === InventoryType.Wholesale;
    if (wholesale) return (
      <span
        className={`inventory-type-marker initial ${
          wholesale ? "wholesale" : ""
        } inline`}
      >
        {InventoryType[type][0]}
      </span>
    );
  }

  getTotalTemplate = (props, useFull = false): any => {
    const { storeId } = this.props;
    const timerProps = {
      ...this.props,
      ...props,
      store_id: storeId,
      grid: this.grid,
      useFull: typeof useFull === "boolean" ? useFull : false,
      onHandleErrorResponse: (error) => this.handleErrorResponse(error),
      onAlert: (alert) => this.sendAlert(alert),
      setUpdateKey: (isRunning) => this.setUpdateKey(isRunning),
    };
    return <GridTotalTimer key={this.props.id} {...timerProps} />;
  };

  getPhaseTemplate = (props, useFull = false): any => {
    const { storeId, dealer_phases } = this.props;
    const phase =
      props.detail &&
      dealer_phases &&
      dealer_phases.find((p) => p.id === props.detail.current_phase);

    const timerProps = {
      phase,
      ...this.props,
      ...props,
      store_id: storeId,
      grid: this.grid,
      useFull: typeof useFull === "boolean" ? useFull : false,
      onHandleErrorResponse: (error) => this.handleErrorResponse(error),
      onAlert: (alert) => this.sendAlert(alert),
      setUpdateKey: (isRunning) => this.setUpdateKey(isRunning),
    };
    return <GridPhaseTimer key={this.props.id} {...timerProps} />;
  };

  handleSelectRow(data) {
    if (data) {
      if (window.location.search) {
        const params = new URLSearchParams(window.location.search);
        const inventoryId = params.get("id");
        if (data.inventory_id !== inventoryId)
          this.props.history.push(window.location.pathname);
      }
      this.setState({ detail: data, detail_id: data.id, detail_open: true });
    }
  }

  selectInventoryItem(items, allItems) {
    if (this.state.detail_open) {
      const update_key = new Date();
      const update: any = { update_key: update_key.toString() };
      const detailId = items.indexOf(this.state.detail.id);

      if (detailId < 0 && items.length > 0)
        update.detail = allItems.find((i) => i.id === items[0]);
      else update.detail = allItems[0];

      this.setState(update, () => {
        this.grid.selectedRowIndex = detailId >= 0 ? detailId : 0;
      });
    }
  }

  getVehicleFilter(gridInventory) {
    return {
      ui: {
        create: (args: { target: Element; column: object }) => {
          const { vehicleSearchString } = this.state;
          const attrs: any = { placeholder: "Filter by Vehicle" };
          if (vehicleSearchString) attrs.value = vehicleSearchString;
          this.vehicleFilter = createElement("input", {
            id: "VehicleFilter",
            className: "e-input",
            attrs,
          });
          args.target.appendChild(this.vehicleFilter);

          // listen for clear btn click to clear the filter string
          const cancelbtn = document.getElementsByClassName(
            "e-flmenu-cancelbtn"
          );
          // only one e-flmenu-cancelbtn in this screen
          const clear: any = cancelbtn[0];
          clear.onclick = () => {
            this.setState({ vehicleSearchString: null }, () => {
              const noResults = document.getElementById("FilterNoResults");
              if (noResults) noResults.remove();
              this.selectInventoryItem([], gridInventory);
            });
          };

          return this.vehicleFilter;
        },
        read: (args: {
          element: any;
          column: any;
          operator: string;
          fltrObj: Filter;
        }) => {
          const vehicleSearchString: string = this.vehicleFilter.value;
          this.setState({ vehicleSearchString }, () => {
            let noResults = document.getElementById("FilterNoResults");
            const searchFiltered = multiTokenFilter({
              searchString: vehicleSearchString,
              array: gridInventory,
            });
            const filterMatchedIds =
              searchFiltered.length > 0
                ? searchFiltered.map((inv) => inv.id)
                : null;
            if (filterMatchedIds) {
              args.fltrObj.filterByColumn(
                args.column.field,
                args.operator,
                filterMatchedIds
              );
              if (noResults) noResults.remove();
              this.selectInventoryItem(filterMatchedIds, gridInventory);
            } else {
              // show no results and create if it isn't already created
              if (!noResults) {
                const listArea = document.getElementsByClassName("e-content");
                noResults = createElement("div", {
                  id: "FilterNoResults",
                  className: "ui negative message",
                });
                listArea[0].appendChild(noResults);
              }
              args.fltrObj.filterByColumn(
                args.column.field,
                args.operator,
                vehicleSearchString
              );
              noResults.innerHTML = `No Results for "<strong>${vehicleSearchString}</strong>"`;
            }
          });
        },
        // this write function is required by syncfusion for some reason
        write: () => console.log("Write Vehicle Filter"),
      },
    } as IFilter;
  }

  createGridColumns(gridInventory: any[], phase: any) {
    const vehicle = {
      id: "id",
      headerText: "vehicle",
      textAlign: "left",
      allowFiltering: true,
      allowSorting: false,
      filter: this.getVehicleFilter(gridInventory),
      cellAttrs: {
        template: this.getVehicleTemplate,
        attributes: { id: "lightboxworthy", class: "custom-photos" },
      },
    };

    const headerData = [];
    headerData.push(vehicle);

    return GridColumnHeaders(
      gridInventory,
      headerData,
      headerData.map((h) => h.id)
    );
  }

  setUpdateKey(isRunning) {
    this.setState({ update_key: isRunning });
  }

  selectAction(option: any) {
    const { recon_phase_name } = this.props;
    const autoGrid = document.getElementById("PrintStageInventory").innerHTML;

    switch (option) {
      case "export":
        printWindow(recon_phase_name, autoGrid, false);
        break;
      case "share":
        console.log(this.props);
        break;
      default:
        break;
    }
  }

  actionsButton() {
    const stageOptions = this.actions.map((text, key) => ({
      key,
      text,
      value: text.toLowerCase(),
    }));
    return (
      <div className="stage-options">
        <Button.Group color="grey" floated="right">
          <Dropdown button className="button icon" icon="ellipsis vertical">
            <Dropdown.Menu>
              <Dropdown.Menu scrolling>
                {stageOptions.map((option, key) => (
                  <Dropdown.Item
                    key={key}
                    {...option}
                    onClick={() => this.selectAction(option.value)}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown>
        </Button.Group>
      </div>
    );
  }

  printableInventory(): any {
    const { inventory_results, recon_phase_name } = this.props;
    const grid_inventory = inventory_results
      ? this.formatGridData(inventory_results)
      : null;

    const stores = Session.get("stores");
    const store = stores.find((s) => s.store_id === this.props.storeId);

    return (
      <div id="PrintStageInventory" className="hide-for-print">
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              Stage: <Header content={recon_phase_name} color="blue" />
            </Grid.Column>
            <Grid.Column textAlign="right">
              Store: <Header content={store.store_name} color="blue" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Inventory</Table.HeaderCell>
                  <Table.HeaderCell>Stage Time</Table.HeaderCell>
                  <Table.HeaderCell>Total Time</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {grid_inventory.map((inventory, k) => {
                  const {
                    year,
                    make,
                    model,
                    trim,
                    photo_url,
                    exterior_color_shortened,
                    miles,
                    stock_number,
                    recon_level_id,
                    short_vin,
                  } = inventory;
                  const reconLevel =
                    this.props.recon_levels &&
                    this.props.recon_levels.find(
                      (i) => i.id === recon_level_id
                    );
                  const reconName =
                    reconLevel && reconLevel.name ? reconLevel.name : "";
                  return (
                    <Table.Row key={k}>
                      <Table.Cell>
                        <Header as="h4" image>
                          <Image
                            src={photo_url}
                            rounded
                            size="large"
                            loading="lazy"
                          />
                          <Header.Content>
                            {`${year} ${make} ${model} ${trim ? trim : ""} ${
                              reconName ? `- ${reconName}` : ""
                            }`}
                            <Header.Subheader>
                              {`Stock: ${stock_number} | VIN: ${short_vin}`}
                              <br />
                              {`Color: ${exterior_color_shortened} | Odo: ${miles}`}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell>
                        {this.getPhaseTemplate(inventory, false)}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getTotalTemplate(inventory, false)}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  getPhaseOptions = (dealer_phases) => {
    return (
      dealer_phases.length > 0 &&
      dealer_phases
        .filter((d) => d.inventory.length > 0 && d.inventory[0])
        .map((d) => {
          return {
            key: d.id,
            text: d.name,
            value: d.id,
            content: (
              <Header
                content={d.name}
                subheader={`${d.inventory.length} vehicles`}
              />
            ),
          };
        })
    );
  };

  toggleCollapsed = () => {
    this.setState(
      (prevState) => ({
        collapsed: !prevState.collapsed,
      }),
      () => {
        localStorage.setItem(
          "stage_collapsed",
          this.state.collapsed.toString()
        );
      }
    );
  };

  render() {
    const { update_key, detail_id, collapsed } = this.state;
    const {
      inventory_results,
      dealer_phases,
      recon_detail_open,
      store_settings,
      hide_on_sold_and_dispositioned,
    } = this.props;
    const grid_inventory = inventory_results
      ? this.formatGridData(inventory_results)
      : null;

    // setting our detail based on the newly formatted grid data in case the detail in state is missing data
    const detail =
      grid_inventory && detail_id
        ? grid_inventory.find((i) => i.id === detail_id)
        : null;
    const phase =
      detail &&
      dealer_phases &&
      dealer_phases.find((p) => p.id === detail.current_phase);

    if (!grid_inventory || !dealer_phases || !store_settings)
      return <Loading />;

    const phaseOptions = this.getPhaseOptions(dealer_phases);
    const selectedRowIndex = detail
      ? grid_inventory.findIndex((i) => i.id === detail.id)
      : grid_inventory.length > 0
      ? 0
      : null;
    const collapser = `triangle ${
      collapsed ? "right" : "left"
    }` as SemanticICONS;

    // @ts-ignore
    return (
      <div className="panel-content" key={`${collapsed}`}>
        <div className="breadcrumb">
          {this.printableInventory()}
          <Grid columns="equal">
            <Grid.Column>
              <Button
                labelPosition="left"
                icon
                className="go-back"
                color="blue"
                onClick={this.props.onCloseDetail}
              >
                <Icon name="arrow left" />
                Back to Stages
              </Button>
            </Grid.Column>
            <Grid.Column id="selectPhase" floated="right">
              {this.actionsButton()}
              <span id="selectPhaseLabel">Viewing Stage:</span>
              <Dropdown
                className="select-phase"
                value={recon_detail_open}
                placeholder="Change Stages"
                loading={!dealer_phases}
                fluid
                search
                selection
                options={phaseOptions}
                onChange={this.props.onHandleChangePhase.bind(this)}
              />
            </Grid.Column>
          </Grid>
        </div>

        <Grid centered className="recon grid-container inventory">
          <Grid.Row className="grid-inventory-row">
            {grid_inventory ? (
              <Grid.Column
                className={`recon list-column detail ${
                  collapsed ? "collapsed" : ""
                }`}
              >
                <RenderDataGrid
                  {...({
                    update_key, // if this changes, the grid will reload
                    setGrid: (grid) => this.setGrid(grid, selectedRowIndex),
                    gridLines: "Horizontal",
                    total: inventory_results.length,
                    data: this.state.search_is_set
                      ? this.state.searchData
                      : grid_inventory,
                    columns: this.createGridColumns(grid_inventory, phase),
                    tools: [
                      GridToolOptions.selection,
                      GridToolOptions.sorting,
                      GridToolOptions.filter,
                      GridToolOptions.search,
                    ],
                    selectedRowIndex,
                    textwrap: true,
                    filterSettings: { type: "Menu" },
                    onSelect: (data) => this.handleSelectRow(data),
                    noresults: {
                      icon: "truck",
                      text: "No inventory found, add some!",
                      action: {
                        text: "Add Inventory",
                        callback: () => this.setState({ addVehicle: true }),
                      },
                    },
                  } as DataGridProps)}
                />
                <div
                  id={`divider`}
                  className={`${collapsed ? "collapsed" : ""}`}
                >
                  <ToursIntroPopup
                    {...({
                      route: "/workflow",
                      tourId: 8,
                      name: "StageSidebarCollapser",
                      popupProps: {
                        position: "bottom left",
                        className: "bring-top",
                      },
                      trigger: () => (
                        <Popup
                          content={`${
                            collapsed ? "Expand" : "Collapse"
                          } Sidebar`}
                          inverted
                          trigger={
                            <Icon
                              id="stageCollapser"
                              name={collapser}
                              size="large"
                              onClick={this.toggleCollapsed}
                            />
                          }
                        />
                      ),
                    } as ToursIntroPopupProps)}
                  />
                </div>
              </Grid.Column>
            ) : (
              <span />
            )}
            <Grid.Column
              className={`recon detail-column ${collapsed ? "collapsed" : ""}`}
            >
              {detail && (
                <Grid className="detail-container">
                  <Grid.Row
                    id="Detailrow"
                    columns={2}
                    className="recon detail-row bottom"
                    style={{ padding: 0 }}
                  >
                    <Grid.Column style={{ padding: 0 }} width={16}>
                      <div style={{ padding: "7px 7px 0 13px" }}>
                        <InventoryDetail
                          hide_on_sold_and_dispositioned={
                            hide_on_sold_and_dispositioned
                          }
                          inventory_id={detail.inventory_id}
                          is_workflow_screen={true}
                          setUpdateKey={(update_key) => {
                            this.props.refetchInventory();
                            setTimeout(
                              () => this.setState({ update_key }),
                              1000
                            );
                          }}
                        />
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  subscriptionRefresh() {
    this.props.refetchInventory && this.props.refetchInventory();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sub_id !== this.state.sub_id) {
      this.subscriptionRefresh();
    }
    if (
      this.props.inventory_results &&
      this.props.inventory_results.length == 0
    )
      this.props.history.push("/admin/dealerships/workflow/stages");
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let derived = prevState;
    const params = new URLSearchParams(nextProps.history.location.search);
    const inventoryId = params.get("id");
    if (inventoryId && nextProps.inventory_results) {
      const inventory = nextProps.inventory_results.find(
        (inv) => inv.inventory_id === inventoryId
      );
      if (inventory)
        derived = {
          detail: inventory,
          detail_id: inventory.id,
          detail_open: true,
        };
    } else {
      if (
        nextProps.dealer_phases_sub &&
        nextProps.dealer_phases_sub.id !== prevState.sub_id
      ) {
        derived = {
          sub_id: nextProps.dealer_phases_sub.id,
          ...derived,
        };
      } else if (
        prevState.detail_open &&
        nextProps.inventory_results &&
        nextProps.inventory_results.length > 0
      ) {
        const detailInList = nextProps.inventory_results.find(
          (i) => i.id === prevState.detail_id
        );
        if (!detailInList) {
          derived.detail_id = nextProps.inventory_results[0].id;
          derived.detail = nextProps.inventory_results[0];
        }
      }
    }
    return derived;
  }

  componentWillUnmount(): void {
    this.abortController.abort();
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const ReconInventory = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.inventorySearchPartDuex,
    {
      skip: (ownProps: any) => !ownProps.recon_detail_open,
      options: (props: any) => {
        return {
          variables: {
            store_ids: parseInt(props.storeId, 10),
            phase: parseInt(props.recon_detail_open, 10),
            sold: null,
            hide_on_sold_and_dispositioned:
              props.hide_on_sold_and_dispositioned,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, inventory_search2, refetch, fetchMore },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true };

        return {
          inventory_results: inventory_search2.items,
          refetchInventory: refetch,
          reload: (variables) => {
            return fetchMore({
              variables,
              updateQuery(prev: any, { fetchMoreResult }) {
                return fetchMoreResult;
              },
            });
          },
        };
      },
    }
  ),
  graphql<any, any, any, ClassAttributes<any>>(gqlQueries.settings.store, {
    options: (props: any) => {
      return {
        variables: {
          storeId: parseInt(props.storeId, 10),
          names: [
            "USED_INVENTORY_RANGE_BUCKET",
            "NEW_INVENTORY_RANGE_BUCKET",
            "CERTIFIED_INVENTORY_RANGE_BUCKET",
            "TECHNICIAN_JOB_TITLE_IDS",
          ],
        },
        fetchPolicy: "network-only",
      };
    },
    props: ({ data: { error, loading, store_settings, refetch } }): any => {
      if (loading) return { loading: true };
      if (error) return { hasErrors: true, message: error };
      return {
        store_settings,
        refetch,
      };
    },
  })
)(ReconInventoryView) as React.ComponentType<any>;

export default ReconInventory;
