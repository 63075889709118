import { Grid, Loader } from "semantic-ui-react";
import * as React from "react";

export const NewCard = ({ loading, onAddCard }) => {
	return (
		<Grid.Column className={`card-column new`} onClick={onAddCard}>
			<div id={`card-new`} className={`ui card _new`}>
				<div className="content card-body">
					<div className="ui small feed">
						<div className="event">
							<div className="content">
								<div className="summary new">{loading ? <Loader active /> : <i className="icon add" />}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Grid.Column>
	);
};
