// node_modules
import { Form, Input } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { sortBy } from "lodash";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
// components
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import { restAPI } from "../../../../../utils/rest";
import "./AppSettingsComponent.css";

export class AppSettingsComponentView extends React.Component<LMI.SUAdminAppSettingProps, LMI.SUAdminAppSettingState> {
	constructor(props) {
		super(props);

		this.state = {
			editSettings: false,
			addingSetting: false
		};
	}

	render() {
		const settings = this.props;

		return (
			<div id="app-setting-component" className="page">
				<Grid>
					<Grid.Row columns={2}>
						{settings && settings.su_app_settings && settings.su_app_settings.length
							? settings.su_app_settings.map((setting, index) => {
									return (
										<Grid.Column key={index}>
											<Form noValidate size="small" onSubmit={this.updateSettings.bind(this)}>
												<Form.Field>
													<label>{setting.name}</label>
													<Input name="value" value={setting.value} action={{ icon: "save" }} />
												</Form.Field>
												<Form.Field>
													<Input name="id" value={setting.id} type="hidden" />
												</Form.Field>
											</Form>
										</Grid.Column>
									);
							  })
							: ""}
					</Grid.Row>
				</Grid>
				{this.state.addingSetting ? (
					<Grid>
						<Grid.Column width={8}>
							<Segment>
								<Header as="h3">Add a new App Setting</Header>
								<Form noValidate onSubmit={this.addSetting.bind(this)}>
									<Form.Field>
										<label>Setting Name</label>
										<Input name="name" placeholder="Name of Setting" />
									</Form.Field>
									<br />
									<Form.Field>
										<label>Setting Value</label>
										<Input name="value" placeholder="Value of Setting" />
									</Form.Field>
									<br />
									<Button positive content="Save New Setting" />
								</Form>
							</Segment>
						</Grid.Column>
					</Grid>
				) : (
					<Button primary onClick={() => this.setState({ addingSetting: true })}>
						Add Setting
					</Button>
				)}
			</div>
		);
	}

	addSetting(data: any) {
		restAPI({
			endpointName: "suAddAppSetting",
			urlArgs: null,
			data,
			callback: (err, res) => {
				if (!err) {
					this.props.refetch();
					this.setState({ addingSetting: false });
				}
			}
		});
	}

	updateSettings(data: any) {
		restAPI({
			endpointName: "suUpdateAppSetting",
			urlArgs: [data.id],
			data,
			callback: (err, res) => {
				if (!err) {
					this.props.refetch();
				}
			}
		});
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const AppSettingsComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.AppSettingsAdminGQL, any, any, ClassAttributes<any>>(gqlQueries.super.settings, {
		options: (props: any) => {
			return {
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, su_app_settings, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				su_app_settings: sortBy(su_app_settings, "name"),
				refetch
			};
		}
	})
)(AppSettingsComponentView) as React.ComponentType<any>;

export default AppSettingsComponent;
