import { Form } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { VehicleSelector } from "client/components/VehicleSelector";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { VehicleDocumentScope } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import Iframe from "react-iframe";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Dimmer,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Loader,
  Message,
  Progress,
  Segment,
  Statistic,
} from "semantic-ui-react";
import { promiseBase64 } from "../../utils/fileHandling";
import { confirmDefault, ConfirmDialog } from "../ConfirmDialog";
import { removeNullProps } from "client/utils/functions";
import { restAPI } from "../../utils/rest";
import { DropZone } from "../DropZone/DropZone";

import "./VehicleDocument.css";

export class VehicleDocumentUploadView extends React.Component<
  LMI.VehDocUploadProps,
  LMI.VehDocUploadState
> {
  progressTiming;

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      files: props.fileProps
        ? [
            {
              document: props.fileProps,
              file: null,
            },
          ]
        : null,
      fileProps: props.fileProps,
      selectedType: null,
      years: [],
      isUploading: false,
      isUpdating: false,
      fileNameRequired: false,
      uploadFile: props.fileProps ? false : true,
      acceptedFiles: false,
      progressTimers: null,
      previewURL: null,
      confirm: confirmDefault,
      massEdit: false,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { fileProps, loading } = nextProps;
    if (fileProps && !loading) {
      return {
        uploadFile: false,
        fileProps,
        files: [
          {
            document: fileProps,
            file: null,
          },
        ],
      };
    }
    return null;
  }

  componentWillUnmount() {
    if (this.progressTiming) {
      clearInterval(this.progressTiming);
    }
  }

  componentDidMount() {
    this.getYears();
  }

  render() {
    const {
      loading,
      vehicle_document_types,
      borderless,
      canDelete,
      superuser,
    } = this.props;
    const {
      isUploading,
      isUpdating,
      files,
      fileProps,
      uploadFile,
      confirm,
      massEdit,
    } = this.state;
    const filesToAccept = "image/png, image/jpeg, application/pdf";
    const validDocumentUploads = this.getValidDocumentsCount();
    const vehTypeOptions = vehicle_document_types
      ? vehicle_document_types.map((i) => {
          return {
            text: i.name,
            value: i.id,
          };
        })
      : [];

    if (loading) {
      return <Loader />;
    }

    if (isUpdating) {
      return (
        <Dimmer inverted active>
          <Loader indeterminate>Updating Document</Loader>
        </Dimmer>
      );
    }

    return (
      <div id="fileupload">
        <ConfirmDialog {...confirm} />
        {this.state.previewURL !== null && (
          <ModalComponent
            headerText="Document Preview"
            shouldBeOpen={this.state.previewURL ? true : false}
            onClose={(evt, data) => {
              this.setState({ previewURL: null });
            }}
            contentComponent={() => this.getPreviewFrame()}
          />
        )}

        {!isUploading && (
          <Button.Group attached="top">
            <Button
              size="small"
              active={uploadFile}
              onClick={() => {
                this.clearDocumentsForm();
                this.setState({ uploadFile: true });
              }}
            >
              Upload File(s)
            </Button>
            <Button
              size="small"
              active={!uploadFile}
              onClick={() => {
                this.clearDocumentsForm();
                this.setState({
                  uploadFile: false,
                  files: [
                    {
                      document: fileProps ? fileProps : {},
                    },
                  ],
                });
              }}
            >
              Document URL
            </Button>
          </Button.Group>
        )}
        <Segment
          className={`${files && uploadFile ? "" : "attached"} ${
            borderless ? "borderless" : ""
          }`}
        >
          <div className="file-uploader">
            {uploadFile && !isUploading && (
              <DropZone
                accept={filesToAccept}
                multiple={true}
                className="dropzone"
                onDrop={(files) =>
                  this.uploadFiles(
                    files.map((file) =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    )
                  )
                }
              />
            )}
          </div>

          {files && files.length > 1 && superuser ? (
            <div>
              <Checkbox
                toggle
                id="mass"
                name="mass_edit"
                defaultChecked={massEdit}
                label="Copy document config accross all uploads? (won't set document name and trim fields)"
                onChange={(e, data) =>
                  this.setState({ massEdit: data.checked })
                }
              />
              <Divider hidden />
            </div>
          ) : (
            ""
          )}

          {files && !isUploading ? (
            files.map((file: LMI.DocumentFileObject, index) => {
              return (
                <Grid
                  key={index}
                  className={files.length > 1 ? "multiple-files" : ""}
                >
                  <Grid.Column width={uploadFile ? 12 : 16}>
                    <Form
                      id={`DocumentForm${index}`}
                      encType="multipart/form-data"
                      noValidate
                    >
                      {!uploadFile && (
                        <Form.Field required>
                          <Form.Input
                            name="file_url"
                            label="File Url"
                            value={file.document && file.document.full_url}
                            placeholder="Add URL to Document"
                            action={
                              file.document &&
                              file.document.full_url && {
                                icon: "eye",
                                onClick: (e) => {
                                  e.stopPropagation();
                                  this.setState({
                                    previewURL: file.document.full_url,
                                  });
                                },
                              }
                            }
                            required
                            onChange={(e, data) => {
                              const file_url = this.validatedFileUrl(
                                data.value
                              );
                              this.assignFileProperty({ file_url }, index);
                            }}
                          />
                        </Form.Field>
                      )}
                      {!canDelete && (
                        <Form.Field>
                          <Form.Select
                            label="Document Type"
                            name="vehicle_document_type_id"
                            options={vehTypeOptions}
                            placeholder="Select a document type"
                            value={
                              file.document &&
                              file.document.vehicle_document_type_id
                            }
                            onChange={(e, data) => {
                              if (
                                file.document.vehicle_document_type_id !==
                                data.value
                              ) {
                                const changeValue = parseInt(
                                  data.value.toString(),
                                  10
                                );
                                const selectedName = data.options.find(
                                  (o) => o.value === changeValue
                                );
                                this.setState({
                                  files: files.map((item, fileIndex) => {
                                    item.fileNameRequired =
                                      selectedName.text === "Other" &&
                                      fileIndex === index
                                        ? true
                                        : false;
                                    if (
                                      fileIndex === index ||
                                      this.state.massEdit
                                    ) {
                                      item.document = {
                                        ...item.document,
                                        ...{
                                          vehicle_document_type_id: changeValue,
                                        },
                                      };
                                    }
                                    return item;
                                  }),
                                });
                              }
                            }}
                            required
                          />
                        </Form.Field>
                      )}
                      <Form.Field>
                        <Form.Input
                          label="Document Name"
                          name="custom_file_name"
                          value={file.document && file.document.name}
                          placeholder={`Name of Document (${
                            file.fileNameRequired ? "Required" : "Optional"
                          })`}
                          required={file.fileNameRequired ? true : false}
                          onChange={(e, data) => {
                            this.assignFileProperty(
                              { file_name: data.value },
                              index
                            );
                          }}
                        />
                      </Form.Field>
                      {file.document && file.document.vehicle_document_type_id
                        ? this.getDocumentFormFields(
                            file.document.vehicle_document_type_id,
                            { file, index }
                          )
                        : ""}
                    </Form>
                  </Grid.Column>
                  {uploadFile ? (
                    <Grid.Column width={4}>
                      <div className="previews">
                        <div className="file">
                          <Button
                            circular
                            icon="close"
                            className="close-preview"
                            color="red"
                            size="tiny"
                            onClick={() => this.removeFile(file.file)}
                          />
                          {this.getPreview(file.file)}
                          <span className="file-name">{file.file.name}</span>
                          <Statistic size="mini" color="grey">
                            <Statistic.Value>
                              {file.file.type.split("/").pop()}
                            </Statistic.Value>
                            <Statistic.Label>
                              {this.getHumanFileSize(file.file.size)}
                            </Statistic.Label>
                          </Statistic>
                        </div>
                      </div>
                    </Grid.Column>
                  ) : (
                    ""
                  )}
                  {canDelete && (
                    <div className="delete-btn-container">
                      <Button
                        type="button"
                        className="floated-delete-btn"
                        color="red"
                        content="Delete Document"
                        onClick={() =>
                          this.setState({
                            confirm: {
                              open: true,
                              title: "Delete Document",
                              text:
                                "Please confirm you would like to delete this document. This action cannot be undone",
                              success: () => {
                                this.deleteThisDocument();
                                this.setState({ confirm: confirmDefault });
                              },
                              failure: () =>
                                this.setState({ confirm: confirmDefault }),
                            },
                          })
                        }
                      />
                    </div>
                  )}
                </Grid>
              );
            })
          ) : (
            <span />
          )}

          {files && this.state.isUploading ? (
            <Segment>
              <Header
                as="h3"
                textAlign="center"
                content={`Uploading Document${
                  files.length > 1 ? "s" : ""
                } (this can take a while)`}
              />
              {files.map((file, index) => {
                const percent =
                  this.state.progressTimers && this.state.progressTimers[index]
                    ? this.state.progressTimers[index].value
                    : 0;
                return (
                  <Message
                    icon
                    key={index}
                    className="uploadFrames"
                    color={file.uploadError ? "red" : null}
                  >
                    <span className="preview">
                      {this.getPreview(file.file)}
                    </span>
                    <Message.Content>
                      <Message.Header>{`${
                        file.file_name
                          ? file.file_name
                          : `Document #${index + 1}`
                      }`}</Message.Header>
                      {this.state.progressTimers && !file.uploadError && (
                        <div>
                          <br />
                          <Progress
                            percent={percent}
                            active={file.uploading}
                            autoSuccess
                          >
                            {percent === 100
                              ? "Successfully Uploaded to the Server"
                              : `${index + 1} of ${files.length}`}
                          </Progress>
                        </div>
                      )}
                      {file.uploadError && (
                        <div>
                          <p>{file.uploadError}</p>
                          <Button
                            color="red"
                            size="small"
                            content="Edit Document"
                            onClick={() => this.inspectErroredDocuments()}
                          />
                        </div>
                      )}
                    </Message.Content>
                  </Message>
                );
              })}
              <Button
                content="Done"
                onClick={() => this.clearDocumentsForm()}
              />
            </Segment>
          ) : (
            <span />
          )}

          {this.props.fileProps ? (
            <Button
              className="control-btns"
              positive
              onClick={() => this.updateDocument()}
              content="Save Changes"
            />
          ) : validDocumentUploads > 0 && !isUploading ? (
            <Button.Group className="control-btns">
              <Button type="button" onClick={() => this.clearDocumentsForm()}>
                Cancel
              </Button>
              <Button.Or />
              {validDocumentUploads > 1 ? (
                <Button
                  className="submit-btn"
                  as="div"
                  labelPosition="right"
                  onClick={() => this.uploadAllDocuments()}
                >
                  <Button positive>Upload Documents</Button>
                  <Label
                    as="a"
                    basic
                    color="green"
                    pointing="left"
                    content={validDocumentUploads}
                  />
                </Button>
              ) : (
                <Button
                  className="submit-btn"
                  positive
                  onClick={() => this.uploadAllDocuments()}
                >
                  Upload Document
                </Button>
              )}
            </Button.Group>
          ) : (
            <span />
          )}
        </Segment>
      </div>
    );
  }

  validatedFileUrl = (value) => {
    let youTubeLink = null;
    if (value.includes("https://www.youtube.com/watch?v=")) {
      youTubeLink = value.replace(/\watch\?v=\b/, "embed/");
    } else if (value.includes("https://youtu.be/")) {
      youTubeLink = value.replace(
        /https:\/\/youtu\.be\//,
        "https://www.youtube.com/embed/"
      );
    }
    return youTubeLink ? youTubeLink : value;
  };

  /**
   * Get the required document feilds based on their doctype
   * @param {number} docType - Document Type ID
   * @param {LMI.DocumentFileObject} file - the file data
   * @param {number} index - location in state files array
   */
  getDocumentFormFields = (docType: number, { file, index }) => {
    const docTypeObject: LMI.IInventoryDocumentTypesQueryProps =
      this.props.all_vehicle_document_types &&
      this.props.all_vehicle_document_types.find((type) => type.id === docType);
    let formFields;
    if (docTypeObject) {
      switch (docTypeObject.vehicle_document_scope_id) {
        case VehicleDocumentScope.MakeModelsYears:
          formFields = (
            <span>
              <Form.Field>
                <h4>Vehicle Info</h4>
                <label className="req-field-label">Year</label>
                <Dropdown
                  clearable
                  fluid
                  search
                  selection
                  multiple
                  name="years"
                  options={this.state.years}
                  value={file.document.years ? file.document.years : []}
                  placeholder="Select a vehicle year"
                  disabled={!this.state.years}
                  onChange={(e, data) => {
                    this.setState({
                      files: this.state.files.map((item, fileIndex) => {
                        if (fileIndex === index || this.state.massEdit) {
                          item.document.years = data.value as number[];
                        }
                        return item;
                      }),
                    });
                  }}
                  required
                />
              </Form.Field>
              <VehicleSelector
                {...({
                  spec: "Make",
                  value: file.document.make,
                  isRequired: true,
                  isMultiSelect: false,
                  context: {
                    year: file.document.years,
                  },
                  onSelection: (make) => {
                    this.setState({
                      files: this.state.files.map((item, fileIndex) => {
                        if (fileIndex === index || this.state.massEdit) {
                          item.document.make = make as string;
                          item.document.models = [];
                        }
                        return item;
                      }),
                    });
                  },
                } as LMI.VehicleSelectorProps)}
              />
              <VehicleSelector
                {...({
                  spec: "Model",
                  value:
                    file.document.models && file.document.models.length > 0
                      ? file.document.models.map((f) => {
                          return f.name && f.name.toString().toLowerCase();
                        })
                      : [],
                  isRequired: true,
                  isDisabled: file.document.make ? false : true,
                  isMultiSelect: true,
                  context: {
                    year: file.document.years,
                    make: file.document.make,
                  },
                  onSelection: (selected) => {
                    if (selected) {
                      this.setState({
                        files: this.state.files.map((item, fileIndex) => {
                          if (fileIndex === index || this.state.massEdit) {
                            item.document.models = selected.map((i) => {
                              return { name: i.name ? i.name : i };
                            });
                          }
                          return item;
                        }),
                      });
                    }
                  },
                } as LMI.VehicleSelectorProps)}
              />
              {file.document.models && file.document.models.length > 0
                ? file.document.models.map((model, modelIndex) => {
                    return (
                      <VehicleSelector
                        key={modelIndex}
                        {...{
                          spec: "Trim",
                          trimLabel: model.name,
                          value: model.trim,
                          isRequired: false,
                          isDisabled: file.document.models ? false : true,
                          isMultiSelect: false,
                          context: {
                            year: file.document.years,
                            make: file.document.make,
                            model: model.name,
                          },
                          onSelection: (selectedTrim) => {
                            if (typeof selectedTrim === "string") {
                              this.setState({
                                files: this.state.files.map(
                                  (item, fileIndex) => {
                                    // edit the right file
                                    if (fileIndex === index) {
                                      item.document.models = item.document.models.map(
                                        (i, iindex) => {
                                          // only set trim for correct model
                                          if (iindex === modelIndex) {
                                            // not sure why I couldn't just mutate the item but it's making me create a new object
                                            return {
                                              name: i.name,
                                              trim:
                                                selectedTrim === "nullOption"
                                                  ? null
                                                  : selectedTrim,
                                            };
                                          }
                                          return i;
                                        }
                                      );
                                    }
                                    return item;
                                  }
                                ),
                              });
                            }
                          },
                        }}
                      />
                    );
                  })
                : ""}
            </span>
          );
          break;
        case VehicleDocumentScope.Vin:
          formFields = (
            <Header as="h4">
              <small>VIN:</small> {file.document && file.document.vin}
            </Header>
          );
          break;
        case VehicleDocumentScope.Inventory:
          formFields = (
            <Header as="h4">
              <small>Inventory ID:</small>{" "}
              {file.document && file.document.inventory_item_id}
            </Header>
          );
          break;
        case VehicleDocumentScope.StoreGroupCondition:
          const isOrgLevel =
            this.props.fileProps &&
            file.document &&
            file.document.organization_id !== null
              ? true
              : false;
          formFields = (
            <span>
              <Form.Field>
                <label>Condition</label>
                <Form.Select
                  options={["All", "New", "Used", "Certified"].map((o, i) => {
                    return {
                      key: i,
                      text: o,
                      value: i,
                    };
                  })}
                  name="condition"
                  value={file.document && Conditions[file.document.condition]}
                  placeholder="All, New, Used, Certified (defaults to All)"
                  onChange={(e, data) => {
                    let condition = null;
                    switch (data.value) {
                      case Conditions.All:
                        condition = null;
                        break;
                      case Conditions.New:
                        condition = "New";
                        break;
                      case Conditions.Used:
                        condition = "Used";
                        break;
                      case Conditions.Certified:
                        condition = "Certified";
                        break;
                    }
                    this.setState({
                      files: this.state.files.map((f, i) => {
                        const document = { ...f.document, ...{ condition } };
                        return i === index ? { ...f, ...{ document } } : f;
                      }),
                    });
                  }}
                />
              </Form.Field>
              {!this.props.superuser && this.props.store_brands ? (
                <Form.Field>
                  <label>Make</label>
                  <Form.Select
                    options={this.props.store_brands.map((o, i) => {
                      return {
                        key: i,
                        text: o.vehicle_make.name,
                        value: o.vehicle_make.name.toLowerCase(),
                      };
                    })}
                    name="make"
                    value={file.document && file.document.make}
                    placeholder="Select a Vehicle Make"
                    onChange={(e, data) => {
                      this.setState({
                        files: this.state.files.map((item, fileIndex) => {
                          if (fileIndex === index || this.state.massEdit) {
                            item.document.make = data.value.toString();
                          }
                          return item;
                        }),
                      });
                    }}
                  />
                </Form.Field>
              ) : (
                ""
              )}
              <Form.Group>
                <Form.Field>
                  <Checkbox
                    toggle
                    name="is_group_level"
                    defaultChecked={isOrgLevel}
                    label="Set at the Organization Level"
                    onChange={(e, data) => {
                      const is_group_level = data.checked;
                      this.setState({
                        files: this.state.files.map((f, i) => {
                          const document = {
                            ...f.document,
                            ...{ is_group_level },
                          };
                          return i === index ? { ...f, ...{ document } } : f;
                        }),
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
            </span>
          );
          break;
        default:
          break;
      }
      return formFields;
    }
  };

  getPreviewFrame = () => {
    return <Iframe url={this.state.previewURL} width="100%" height="500px" />;
  };

  getPhotoTemplate = (props): any => {
    return (
      <div className="vehicle-image">
        <img
          src={`${
            props.full_url.includes("imgix")
              ? props.full_url
              : props.default_image_url
          }?h=50&fm=png`}
          alt={props.name}
        />
      </div>
    );
  };

  getPreview = (file) => {
    if (!file) {
      return <Icon name="mouse pointer" size="massive" />;
    } else if (file.type === "application/pdf") {
      return <Icon name="file pdf" size="massive" />;
    } else {
      return (
        <Image
          src={file.preview}
          rounded
          size="medium"
          alt="Document Preview"
          className="imagedoc"
        />
      );
    }
  };

  /**
   * Upload a file and set as state Document Object
   * @param ufile - file object
   */
  uploadFiles = (ufile) => {
    const document: LMI.VehicleDocumentProps = {};
    let files = ufile.map((file) => {
      const exist =
        this.state.files &&
        this.state.files.findIndex((i) => i.file.name === file.name) !== -1;
      if (!exist) {
        return {
          document,
          file,
        } as LMI.DocumentFileObject;
      }
    });

    if (this.state.files) {
      files = [...files, ...this.state.files];
    }

    this.setState({ files });
  };

  // Remove a file from the state Vehicle Documents Array
  removeFile = (rfile) => {
    const files =
      this.state.files.length > 0
        ? this.state.files.filter((f) => f.file.name !== rfile.name)
        : null;
    this.setState({ files });
  };

  /**
   * Get number of valid uploads are currently ready for upload
   */
  getValidDocumentsCount = () => {
    if (!this.state.files || this.state.files.length === 0) {
      return false;
    }
    let count = 0;
    this.state.files.forEach((file) => {
      if (file.document && file.document.vehicle_document_type_id) {
        const requiredFields = this.getRequiredFields(
          file.document.vehicle_document_type_id
        );
        let hasAllRequiredFields = true;
        requiredFields.split(", ").forEach((k) => {
          // to match an or use a / in the requiredFields enum and this will match either of the fields
          if (k.match(/\//gm)) {
            hasAllRequiredFields = false;
            const regex = /[^\/\s][^\/]*[^\/\s]*/gm;
            let m;
            while ((m = regex.exec(k)) !== null) {
              if (m.index === regex.lastIndex) regex.lastIndex++;
              const userInput = file.document[m[0]];
              const userInputHasValue = userInput
                ? Array.isArray(userInput) && userInput.length <= 0
                  ? false
                  : true
                : false;
              hasAllRequiredFields =
                userInputHasValue && !hasAllRequiredFields
                  ? true
                  : hasAllRequiredFields;
            }
          } else {
            if (!file.document[k] && hasAllRequiredFields) {
              hasAllRequiredFields = false;
            }
            // check that arrays have values
            if (
              Array.isArray(file.document[k]) &&
              file.document[k].length <= 0
            ) {
              hasAllRequiredFields = false;
            }
          }
        });
        if (hasAllRequiredFields) {
          count += 1;
        }
      }
    });
    return count;
  };

  /**
   * Assign file property in state
   * @param {string} property - Property Name to update
   * @param {number} index - location of file object to update
   */
  assignFileProperty = (property, index) => {
    this.setState({
      files: this.state.files.map((item, fileIndex) => {
        if (fileIndex === index) {
          return { ...item, ...property };
        }
        return item;
      }),
    });
  };

  /**
   * Fetch required fields based on the documents type id
   * @param {string} typeId - Documents Type Id
   * returns comma delimited string of required property names
   */
  getRequiredFields = (typeId) => {
    const docTypeObject: LMI.IInventoryDocumentTypesQueryProps =
      this.props.all_vehicle_document_types &&
      this.props.all_vehicle_document_types.find((type) => type.id === typeId);
    let requiredFields = "";
    if (docTypeObject) {
      switch (docTypeObject.vehicle_document_scope_id) {
        case VehicleDocumentScope.MakeModelsYears:
          requiredFields = RequiredFields.MakeModelsYears;
          break;
        case VehicleDocumentScope.Inventory:
          requiredFields = RequiredFields.Inventory;
          break;
        case VehicleDocumentScope.StoreGroupCondition:
          requiredFields = RequiredFields.StoreGroupCondition;
          break;
        default:
          break;
      }
    }
    return requiredFields;
  };

  /**
   * Massage a Document Object data and build for delivery to the backend
   * @param {LMI.DocumentFileObject} documentObject Required
   * returns a ShippableDocumentObject
   */
  formatDocumentObject = async (documentObject: LMI.DocumentFileObject) => {
    let document = null;
    const file = documentObject.file;
    const vehicle_document_type_id = {
      vehicle_document_type_id:
        documentObject.document.vehicle_document_type_id,
    };
    const name = documentObject.file_name
      ? { name: documentObject.file_name }
      : null;
    const external_url = documentObject.file_url
      ? { external_url: documentObject.file_url }
      : null;
    if (file) {
      document = { document: await promiseBase64(file) };
    }

    // select the correct required fields based on local enum (RequiredFields)
    const requiredFields = this.getRequiredFields(
      documentObject.document.vehicle_document_type_id
    );

    let documentData = {};
    if (requiredFields) {
      // build array from RequiredFields enum and set to document data object
      requiredFields.split(",").forEach((k) => {
        if (k.match(/\//gm)) {
          const regex = /[^\/\s][^\/]*[^\/\s]*/gm;
          let m;
          while (regex.exec(k) !== null) {
            m = regex.exec(k);
            if (m.index === regex.lastIndex) regex.lastIndex++;
            const userInput = documentObject.document[m[0]];
            const userInputHasValue = userInput
              ? Array.isArray(userInput) && userInput.length <= 0
                ? false
                : true
              : false;
            if (userInputHasValue) {
              documentData[m[0]] = userInput;
            }
          }
        } else {
          documentData[k] = documentObject.document[k];
        }
      });
    } else {
      // or just send the document (might not need to do this or set it to null)
      documentData = documentObject.document;
    }

    documentData = {
      ...documentData,
      ...external_url,
      ...documentObject.document,
    };

    const shippableDocumentObject: LMI.VehicleDocumentProps = {
      ...name,
      ...document,
      ...vehicle_document_type_id,
      ...documentData,
    };

    // not in required fields enum but was a part of the original object
    if (shippableDocumentObject.model) {
      delete shippableDocumentObject.model;
    }

    return shippableDocumentObject as ShippableDocumentObject;
  };

  submitDocument = async (document, index) => {
    return new Promise(async (resolve) => {
      this.assignFileProperty({ uploading: true }, index);

      // stagger the upload indicators (just for fun)
      setTimeout(() => {
        this.addProgressTimer(index);
      }, index * 500);

      const { superuser, storeId } = this.props;
      if (superuser) {
        restAPI({
          endpointName: "suAddDocument",
          urlArgs: null,
          data: document,
          callback: (err, res) => {
            resolve({
              istatus: err ? "error" : "success",
              fileIndex: index,
              ...res,
              ...err,
            });
            this.assignFileProperty({ uploading: false }, index);
          },
        });
      } else {
        restAPI({
          endpointName: "uploadVehicleDocument",
          urlArgs: [storeId],
          data: document,
          callback: (err, res) => {
            resolve({
              istatus: err ? "error" : "success",
              fileIndex: index,
              ...res,
              ...err,
            });
            this.assignFileProperty({ uploading: false }, index);
          },
        });
      }
    });
  };

  uploadAllDocuments = async () => {
    if (this.state.files) {
      this.startProgressTimer();
      try {
        const shippableDocuments = this.state.files.map(async (file, index) => {
          const newDocument = (await this.formatDocumentObject(
            file
          )) as ShippableDocumentObject;
          return this.submitDocument(newDocument, index);
        });
        const result = await Promise.all(shippableDocuments);
        result.forEach((res: any) => {
          if (res.istatus === "success") {
            this.setState({
              progressTimers: this.state.progressTimers.map((t) => {
                if (t.identifier === `file${res.fileIndex}`) {
                  t.value = 100;
                }
                return t;
              }),
            });
          }

          if (res.istatus === "error") {
            this.setState({
              files: this.state.files.map((i, index) => {
                if (index === res.fileIndex) {
                  i.uploadError = res.reason.response.data.message;
                }
                return i;
              }),
            });
          }
        });
        this.props.onSubmit("Files were uploaded.");
      } catch (err) {
        console.log(err);
      }
    }
  };

  updateDocument = async () => {
    if (this.state.files) {
      this.setState({ isUpdating: true });
      const documentObject = this.state.files[0] as LMI.DocumentFileObject;
      let formData: any = {
        name: documentObject.file_name,
        condition: documentObject.document.condition,
        is_group_level: documentObject.document.is_group_level,
      };
      const doc = documentObject.document;
      if (doc.years || doc.make || doc.models) {
        formData = {
          ...formData,
          ...{
            years: documentObject.document.years,
            models: documentObject.document.models,
            make: documentObject.document.make,
          },
        };
      }

      if (this.props.superuser) {
        restAPI({
          endpointName: "suUpdateDocument",
          urlArgs: [documentObject.document.id],
          data: removeNullProps(formData),
          callback: (err, res) => {
            if (!err) {
              this.props.onSubmit(res.data);
            } else {
              const AlertProps: LMI.IAlertsProps = {
                type: !err ? "success" : "danger",
                message: !err
                  ? res.data.message
                  : err.reason.response.data.message,
                timeout: 3000,
                onComplete: () => {
                  this.setState({
                    alert: null,
                  });
                },
              };

              this.setState({
                alert: AlertProps,
                isUpdating: false,
              });
            }
          },
        });
      } else {
        restAPI({
          endpointName: "updateVehicleDocument",
          urlArgs: [this.props.storeId, documentObject.document.id],
          data: removeNullProps(formData),
          callback: (err, res) => {
            if (!err) {
              this.props.onSubmit(res.data);
            } else {
              const AlertProps: LMI.IAlertsProps = {
                type: !err ? "success" : "danger",
                message: !err
                  ? res.data.message
                  : err.reason.response.data.message,
                timeout: 3000,
                onComplete: () => {
                  this.setState({
                    alert: null,
                  });
                },
              };

              this.setState({
                alert: AlertProps,
                isUpdating: false,
              });
            }
          },
        });
      }
    }
  };

  deleteThisDocument = () => {
    const documentId = this.props.fileProps.id;
    if (this.props.superuser) {
      restAPI({
        endpointName: "suDeleteDocument",
        urlArgs: [documentId],
        data: null,
        callback: (err, res) => {
          const AlertProps: LMI.IAlertsProps = {
            type: !err ? "success" : "danger",
            message: !err ? res.data.message : err.reason.response.data.message,
            timeout: 3000,
            onComplete: () => {
              this.setState({
                alert: null,
              });
            },
          };

          this.setState({
            alert: AlertProps,
            isUploading: false,
          });

          if (!err) {
            this.props.onDelete();
          }
        },
      });
    } else {
      restAPI({
        endpointName: "deleteVehicleDocument",
        urlArgs: [this.props.storeId, documentId],
        data: null,
        callback: (err, res) => {
          const AlertProps: LMI.IAlertsProps = {
            type: !err ? "success" : "danger",
            message: !err ? res.data.message : err.reason.response.data.message,
            timeout: 3000,
            onComplete: () => {
              this.setState({
                alert: null,
              });
            },
          };

          this.setState({
            alert: AlertProps,
            isUploading: false,
          });

          if (!err) {
            this.props.onDelete();
          }
        },
      });
    }
  };

  getHumanFileSize = (bytes) => {
    if (Math.abs(bytes) < 1000) {
      return bytes + " B";
    }
    var units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    var u = -1;
    do {
      bytes /= 1000;
      ++u;
    } while (Math.abs(bytes) >= 1000 && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
  };

  clearDocumentsForm = () => {
    this.setState({
      files: null,
      isUploading: false,
      progressTimers: null,
      acceptedFiles: false,
    });
  };

  getYears = async () => {
    const min = 1941;
    const max = new Date().getFullYear() + 2;
    // @ts-ignore
    const years = Array.apply(null, { length: max + 1 - min }).map((_, idx) => {
      const value = idx + min;
      return {
        text: value.toString(),
        value,
      };
    });
    years.reverse();
    this.setState({ years });
  };

  addProgressTimer = (index) => {
    const newTimer = [{ identifier: `file${index}`, value: 0 }];
    const progressTimers = this.state.progressTimers
      ? [...this.state.progressTimers, ...newTimer]
      : newTimer;
    this.setState({ progressTimers });
  };

  inspectErroredDocuments = () => {
    const files = this.state.files.filter((f) => f.uploadError);
    this.setState({
      files,
      isUploading: false,
      progressTimers: null,
      acceptedFiles: true,
      isUpdating: true,
    });
  };

  startProgressTimer = () => {
    this.progressTiming = setInterval(() => {
      if (!this.state.files) {
        clearInterval(this.progressTiming);
      }
      if (this.state.progressTimers) {
        const progressTimers = this.state.progressTimers.map((i) => {
          i.value = i.value < 90 ? (i.value += 2) : i.value;
          return i;
        });
        this.setState({ progressTimers, isUploading: true });

        const allEqual = (arr) => arr.every((v) => v.value === arr[0].value);
        if (
          allEqual(this.state.progressTimers) &&
          this.state.progressTimers[0].value === 100
        ) {
          clearInterval(this.progressTiming);
        }
      }
    }, 300);
  };
}

interface ShippableDocumentObject {
  name: string;
  document: string;
  vehicle_document_type_id: number;
  is_group_level?: boolean;
  customer_can_view?: boolean;
  inventory_item_id?: number;
  vin?: string;
  condition?: string;
  years?: number[];
  make?: string;
  models?: [
    {
      name: string;
      trim: string;
    }
  ];
}

enum RequiredFields {
  MakeModelsYears = "years/make",
  Vin = "vin",
  Inventory = "inventory_item_id",
  StoreGroupCondition = "vehicle_document_type_id",
}

enum Conditions {
  "All" = 0,
  "New" = 1,
  "Used" = 2,
  "Certified" = 3,
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const VehicleDocumentUpload = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<
    LMI.IInventoryDocumentTypesQueryProps,
    any,
    any,
    ClassAttributes<any>
  >(gqlQueries.vehicleDocumentTypes, {
    options: (props: LMI.VehDocUploadProps) => {
      return {
        variables: {
          store_id: !props.superuser ? props.storeId : null,
          scopes: props.fileProps
            ? null
            : props.superuser
            ? [VehicleDocumentScope.MakeModelsYears]
            : [VehicleDocumentScope.StoreGroupCondition],
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
      };
    },
    props: ({
      data: { error, loading, vehicle_document_types, refetch },
    }): any => {
      if (loading) return { loading: true };
      if (error) return { hasErrors: true };

      return {
        vehicle_document_types,
        refetch_types: refetch,
      };
    },
  }),
  graphql<LMI.IStoreBrandsGQLProps, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.storeBrands,
    {
      skip: (ownProps: LMI.VehDocUploadProps) => ownProps.superuser,
      options: (props: any) => {
        return {
          variables: {
            store_id: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_makes, refetch } }): any => {
        if (loading) return { loading: true };
        if (error)
          return { hasErrors: true, message: error, refetchBrands: refetch };

        return {
          store_brands: store_makes.store_makes,
          refetchBrands: refetch,
        };
      },
    }
  )
)(VehicleDocumentUploadView) as React.ComponentType<any>;

export default VehicleDocumentUpload;
