// node_modules
import * as React from "react";
import { StoreType } from "loopmein-shared";
import { timeZones } from "client/utils/globals";
// components
import { Button, Card, Divider, Icon } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import StateSelector from "client/components/StateSelector/StateSelector";
import { GroupsComponent } from "./components/GroupsComponent/GroupsComponent";
import { RegionsComponent } from "./components/RegionsComponent/RegionsComponent";

import "./AddStoreComponent.css";
import { MaskedInputComponent } from "client/pages/admin/components/MaskedInputComponent";
export class AddStoreComponent extends React.Component<LMI.SUAdminAddStoreProps, LMI.SUAdminAddStoreState> {
	constructor(props) {
		super(props);
		this.state = {
			regions: null,
			groups: null,
			employees: null,
			selectedGroups: null,
			addemp: false,
			selectedGroup: this.props.selectedOrg && this.props.selectedOrg.id,
			selectedStoreType: null,
			selectedState: null,
			selectedTimeZone: null
		};

		this.handleChangeOrg = this.handleChangeOrg.bind(this);
	}

	handleChangeOrg(value) {
		if (this.state.selectedGroup !== value) {
			this.setState({ selectedGroup: value });
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedOrg !== this.props.selectedOrg) {
			this.setState({ selectedGroup: this.props.selectedOrg.id });
		}
	}

	render() {
		const organizations = this.props.organizations;
		const groupDropdown = organizations ? organizations.map(org => ({ key: org.id, text: org.name, value: org.id })) : [];
		const groupComponentProps: LMI.SUAdminGroupsProps = {
			id: this.state.selectedGroup,
			onCheck: (data: any) => this.addtoGroups(data)
		};
		const regionComponentProps: LMI.SUAdminRegionsProps = {
			id: this.state.selectedGroup
		};

		const { selectedGroup, selectedStoreType, selectedState } = this.state;

		const getRequiredLabel = val => {
			return (
				<label>
					{val} <span className="red-text">*</span>
				</label>
			);
		};

		return (
			<Form noValidate ref="form" id="add-store-form" onSubmit={this.addStore.bind(this)}>
				{organizations ? (
					<Card fluid>
						<Card.Content>
							<Card.Header>
								<Icon link name="close" color="red" className="close-right" onClick={() => this.props.nevermind()} />
								Add a new store
							</Card.Header>
						</Card.Content>
						<Card.Content className="new-store-form">
							<Form.Group widths="equal">
								<Form.Field>
									<Form.Select
										search
										label={getRequiredLabel("Organization")}
										name="organization_id"
										options={groupDropdown}
										value={this.props.selectedOrg && this.props.selectedOrg.id}
										placeholder="Select an Organization"
										onChange={(e, { value }) => this.handleChangeOrg(value)}
									/>
								</Form.Field>
								{this.state.selectedGroup && this.state.selectedStoreType === StoreType.Dealership && <RegionsComponent {...regionComponentProps} />}
								<Form.Field>
									<Form.Select
										search
										label={getRequiredLabel("Store Type")}
										name="store_type_id"
										value={this.state.selectedStoreType}
										options={this.props.storeTypes}
										placeholder="Select a Store Type"
										onChange={(e, { value }) => this.setState({ selectedStoreType: parseInt(value.toString(), 10) })}
									/>
								</Form.Field>
							</Form.Group>
							<Divider />
							<Form.Group widths="equal">
								<Form.Field>
									{getRequiredLabel("Store Name")}
									<Input name="name" placeholder="Store Name" />
								</Form.Field>
								<Form.Field>
									{getRequiredLabel("Phone")}
									<MaskedInputComponent {...{ type: "phone", value: "", placeholder: "Add Phone" }} />
								</Form.Field>
								<Form.Field>
									<Form.Select
										search
										label={getRequiredLabel("Time Zone")}
										name="timezone_name"
										value={this.state.selectedTimeZone}
										options={timeZones.map(({ value, text }) => ({ value, text: value, key: text }))}
										placeholder="Select a Time Zone"
										onChange={(e, { value }) => this.setState({ selectedTimeZone: value })}
									/>
								</Form.Field>
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Field>
									<label>Address 1</label>
									<Input name="address1" placeholder="Street Address" />
								</Form.Field>
								<Form.Field>
									<label>Address 2</label>
									<Input name="address2" placeholder="Street Address" />
								</Form.Field>
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Field>
									<label>City</label>
									<Input name="city" placeholder="City" />
								</Form.Field>
								<Form.Field>
									{getRequiredLabel("State/Province")}
									<StateSelector
										{...{
											value: null,
											placeholder: "Select a State/Province",
											onChange: data => this.setState({ selectedState: data })
										}}
									/>
								</Form.Field>
								<Form.Field>
									<label>Zip</label>
									<Input name="zip" placeholder="Zip Code" />
								</Form.Field>
							</Form.Group>
							{this.state.selectedGroup && this.state.selectedStoreType === StoreType.Dealership && <GroupsComponent {...groupComponentProps} />}
						</Card.Content>
						<Card.Content extra>
							<Button positive disabled={!selectedGroup || !selectedStoreType || !selectedState} content="Save New Store" />
						</Card.Content>
					</Card>
				) : (
					""
				)}
			</Form>
		);
	}

	addtoGroups(data: any) {
		if (data.checked) {
			const newGroup = [data.value];
			this.setState({
				selectedGroups: this.state.selectedGroups ? [...this.state.selectedGroups, ...newGroup] : newGroup
			});
		} else {
			const selectGroups = this.state.selectedGroups;
			const itemIndex = selectGroups.findIndex(group => group === data.value);
			if (itemIndex !== -1) {
				selectGroups.splice(itemIndex, 1);
				this.setState({
					selectedGroups: selectGroups
				});
			}
		}
	}

	addStore(data: any) {
		const newStore = data;
		newStore.groups = this.state.selectedGroups ? this.state.selectedGroups : [];
		newStore.state = this.state.selectedState.value;
		if (newStore.timezone_name) {
			const timezone = timeZones.find(tz => tz.value === this.state.selectedTimeZone);
			if (timezone) newStore.timezone_name = timezone.utc;
		}
		this.props.create_state(newStore);
	}
}

export default AddStoreComponent;
