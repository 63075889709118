import * as React from "react";
import { useEffect, useState } from "react";
import { calculateElapsedDHMS, getWarningColor } from "../../../../../../utils/functions";
import { Popup } from "semantic-ui-react";

export const Timer = props => {
	const [age, setAge] = useState(+props.age);
	const barColor = getWarningColor({ settings: props.settings, created_at: +props.age, is_completed: props.is_completed });

	useEffect(() => {
		const interval = setInterval(() => {
			setAge(age => age + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Popup
			inverted
			on="hover"
			position="bottom right"
			trigger={
				<span id="age" className={barColor}>
					{calculateElapsedDHMS({ age, label: false, useFull: false })}
				</span>
			}
		>
			<span>{calculateElapsedDHMS({ age, label: true, useFull: true })}</span>
		</Popup>
	);
};
