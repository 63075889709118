import { checkComponentUpdate } from "client/utils/internal";

import * as React from "react";
import track from "react-tracking";
import { ClassAttributes } from "react";
import { withUAB } from "client/pages/WithUAB";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { addAlert, enableUABCallback, triggerUABCallback } from "api/redux/actions";
import { restAPI } from "../../../../../utils/rest";
import { AnalyticsEventType, InventoryType, Permission, SystemLogType } from "loopmein-shared";
import { formatCurrency, removeNullProps } from "client/utils/functions";
import { hasPermission } from "client/utils/userAccess";
import { closest, enableRipple } from "@syncfusion/ej2-base";
import { sliderDefaults } from "client/utils/globals";
import { Session } from "client/utils/session";

import RenderDataGrid, { DataGridProps, GridColumnHeaders, GridToolOptions } from "client/components/DataGrid/DataGrid";
import AddInventoryComponent from "client/components/AddInventory/AddInventoryComponent";
import InventoryDetail from "client/pages/admin/components/InventoryDetailComponent/InventoryDetailComponent";
import { Grid, Icon, Image } from "semantic-ui-react";
import { Column, GridComponent, IRow } from "@syncfusion/ej2-react-grids";
import { Loading } from "client/components/Loading";
import { ModalComponent } from "../../ModalComponent";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { UniversalActionModalComponent } from "../../UniversalActionModalComponent";
import { VehicleNoteForm } from "../../VehicleNoteForm/VehicleNoteForm";
import { MapsDialog } from "client/components/MapDialog";
import { DataSyncQueries, modifyCacheData } from "client/components/DataSync/util";
import { InventoryFilters } from "./Components/InventoryFilters";
import { InventoryData } from "./InventoryDataManager";

import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";
import "./InventoryTabPanel.css";
import "./Components/InventoryComponents.css";

enableRipple(true);

@track({ event_type: AnalyticsEventType.NAVIGATION, event_subtype: "inventory" }, { dispatchOnMount: true })
export class InventoryTabPanelView extends React.Component<LMI.IInventoryProps, LMI.IInventoryState> {
	dialog: DialogComponent;
	grid: GridComponent;

	debugComponentReloadLooping = false;

	constructor(props: any) {
		super(props);

		this.state = {
			detail: null,
			detail_open: props.sharePath ? true : false,
			selected_row_index: null,
			print_ready: props.sharePath ? true : false,
			show_dialog: false,
			lightbox_open: false,
			filter_condition: "ALL",
			show_lot_location: null,
			age_range: [sliderDefaults.age_range[0], sliderDefaults.age_range[1]],
			price_range: [sliderDefaults.price_range[0], sliderDefaults.price_range[1]],
			slider_set: false,
			addVehicle: false,
			showSold: false,
			confirm: null,
			note_modal: null,
			search_text: null,
			grid_inventory: null,
			update_key: new Date(),
			dataSyncEnabled: false
		};
	}

	print = (args: any) => {
		// Hide menu
		args.target.parentElement.classList.remove("visible");
		window.print();
	};

	setGrid(grid) {
		this.grid = grid;
	}

	handleErrorResponse(error: any) {
		if (error.reason && error.reason.response && error.reason.response.data) {
			return error.reason.response.data.message;
		} else {
			return "There was an error.";
		}
	}

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
		if (type === "danger") console.log(`Error: ${message}`);
	}

	closeDetail = () => {
		// tell the grid to deselect and then set back to default (null)
		setTimeout(() => {
			this.resetPath();
			this.setState({
				detail_open: null,
				detail: null,
				// update_key: new Date(),
				selected_row_index: null
			});
		}, 0);
	};

	resetPath() {
		if (window.location.search) this.props.history.replace(this.props.history.location.pathname);
	}

	getCleanTotal() {
		const inventory = this.props.inventory_results;
		console.log("Get Clean Total", inventory.length);
		return inventory
			.filter(item => {
				const sellingPrice = item.selling_price ? parseFloat(item.selling_price) : 0;
				// console.log("selling price", sellingPrice >= sliderDefaults.price_range[0] && sellingPrice <= sliderDefaults.price_range[1]);
				return sellingPrice >= sliderDefaults.price_range[0] && sellingPrice <= sliderDefaults.price_range[1];
			})
			.filter(item => {
				// console.log("age", item.days_in_stock, item.days_in_stock >= sliderDefaults.age_range[0] && item.days_in_stock <= sliderDefaults.age_range[1]);
				const age = item.days_in_stock >= 0 ? item.days_in_stock : 0;
				return age >= sliderDefaults.age_range[0] && age <= sliderDefaults.age_range[1];
			}).length;
	}

	getVinfo = (args: any) => {
		const rowObj: IRow<Column> = this.grid.getRowObjectFromUID(closest(args.target, ".e-row").getAttribute("data-uid"));
		const data: any = rowObj.data;
		const itemId: number = data.id;
		const { inventory_results } = this.props;
		if (inventory_results && itemId) {
			const { inventory_id, vin } = inventory_results.find(i => i.id === itemId);
			const storeCode = localStorage.getItem("selectedStoreCode");
			if (vin && storeCode) {
				// This is how vinfo customers lookup vehicles from their site
				window.open(`${process.env.REACT_APP_DOX_URL}/vin/${vin}?store_code=${storeCode}`, "_blank");
			} else if (inventory_id) {
				// ToDo: Remove this once the vin lookup above has been tested for some time
				// if for some reason we don't have a vin and store code, lookup using inventory_id
				window.open(`${process.env.REACT_APP_DOX_URL}/inv/${inventory_id}`, "_blank");
			} else {
				console.log("Couldn't find the dang item");
			}
		}
	};

	getMaxSellingPrice = () => {
		const { inventory_results } = this.props;
		let maxSellingPrice = 0;
		if (inventory_results) {
			inventory_results.forEach(item => {
				const itemSellingPrice = parseFloat(item.selling_price);
				if (maxSellingPrice < itemSellingPrice) {
					maxSellingPrice = itemSellingPrice;
				}
			});
		}
		return maxSellingPrice;
	};

	getSliderticks = (mode: string) => {
		if (mode === "age") {
			return {
				placement: "After",
				largeStep: 250,
				smallStep: 100,
				showSmallTicks: true
			};
		} else {
			const max_selling_price = this.getMaxSellingPrice();
			return {
				placement: "After",
				largeStep: max_selling_price ? max_selling_price : sliderDefaults.price_range[1],
				smallStep: 5000,
				showSmallTicks: false
			};
		}
	};

	onAgeSliderChanged = (args: any) => {
		setTimeout(
			() =>
				this.setState({
					age_range: args.value,
					slider_set: true,
					update_key: new Date()
				}),
			500
		);
	};

	onPriceSliderChanged = (args: any) => {
		setTimeout(
			() =>
				this.setState({
					price_range: args.value,
					slider_set: true,
					update_key: new Date()
				}),
			500
		);
	};

	toggleSold = (checkbox: any) => {
		this.setState({ showSold: checkbox.checked });
		this.loadFilteredData({ search_text: this.state.search_text, sold: checkbox.checked, limit: checkbox.checked ? 250 : null });
	};

	searchInventory = search_text => {
		this.setState({ search_text });
		this.loadFilteredData({ search_text, sold: this.state.showSold, limit: this.state.showSold ? 250 : null });
	};

	resetFilterRanges = () => {
		const age_range = sliderDefaults.age_range;
		const price_range = sliderDefaults.price_range;
		this.setState({
			age_range,
			price_range,
			slider_set: false,
			search_text: null,
			showSold: false,
			detail: null,
			detail_open: false,
			selected_row_index: null,
			update_key: new Date()
		});
		this.loadFilteredData({ search_text: null, sold: false, limit: null });
	};

	getCustomFilters = (hasLoaded: number) => {
		const { inventory_results } = this.props;
		const { showSold, age_range, price_range, detail_open, search_text } = this.state;
		if (inventory_results && inventory_results.length > 0) {
			const getMaxSellingPrice = () => {
				let maxSellingPrice = 0;
				if (inventory_results)
					inventory_results.forEach(item => {
						const itemSellingPrice = parseFloat(item.selling_price);
						if (maxSellingPrice < itemSellingPrice) maxSellingPrice = itemSellingPrice;
					});
				return maxSellingPrice;
			};

			return (
				<InventoryFilters
					{...{
						hide: detail_open,
						disabled: hasLoaded <= 0,
						showSold,
						age_range,
						price_range,
						max_selling_price: getMaxSellingPrice(),
						onAgeSliderChanged: this.onAgeSliderChanged.bind(this),
						onPriceSliderChanged: this.onPriceSliderChanged.bind(this),
						toggleSold: this.toggleSold.bind(this),
						search_text: search_text ? search_text : "",
						searchChanged: this.searchInventory.bind(this)
					}}
				/>
			);
		}
	};

	getAddInventoryForm(props) {
		return <AddInventoryComponent {...props} />;
	}

	handleSelectRow = async (detail, grid_inventory) => {
		if (detail) {
			if (window.location.search) this.props.history.replace(this.props.history.location.pathname);
			const selected_row_index = detail
				? grid_inventory.findIndex(i => i.id === detail.id)
				: this.state.selected_row_index
				? this.state.selected_row_index
				: -1;
			const isLocationCell = (this.grid as any).target && (this.grid as any).target.id.includes("location");
			const isActionCell = (this.grid as any).target && (this.grid as any).target.classList.contains("action");
			this.setState({
				detail,
				detail_open: isLocationCell || isActionCell ? false : true,
				selected_row_index
			});
		} else if (this.state.selected_row_index >= 0 && this.grid.selectedRowIndex !== this.state.selected_row_index)
			this.grid.selectRow(this.state.selected_row_index);
	};

	getColumnHeaders() {
		return [
			"days_in_stock",
			"condition",
			"photo_url",
			"stock_number",
			"vin",
			"year",
			"make",
			"model",
			"trim",
			"odo",
			"body_style",
			"interior_color_shortened",
			"selling_price",
			"location",
			"flagged_by",
			"action"
		];
	}

	getPrintableColumns() {
		const headers = this.getColumnHeaders();
		return headers
			.filter(h => !["photo_url", "action", "location", "flagged_by"].includes(h))
			.map((i, index) => {
				const shortened = [
					{
						ch: "days_in_stock",
						short: "age"
					},
					{
						ch: "interior_color_shortened",
						short: "interior"
					},
					{
						ch: "stock_number",
						short: "stock#"
					},
					{
						ch: "body_style",
						short: "body"
					},
					{
						ch: "selling_price",
						short: "price"
					}
				];
				const hasShortened = shortened.find(s => s.ch === i);
				const header = hasShortened ? hasShortened.short : i;
				return {
					index,
					field: i,
					headerText: header.split("_").join(" ").toUpperCase()
				};
			});
	}

	createGridColumns(gridInventory, dealerPhases: any) {
		const headerData = [
			{
				id: "days_in_stock",
				headerText: "age",
				width: "30",
				textAlign: "center",
				allowFiltering: true,
				cellAttrs: {
					template: props => (
						<div
							style={{
								color: `${props.bucket && props.bucket.class ? props.bucket.class : "grey"}`
							}}>
							{props.days_in_stock > 0 ? props.days_in_stock : 0}
						</div>
					),
					attributes: { class: "age" }
				}
			},
			{
				id: "condition",
				headerText: "Cond",
				width: "55",
				textAlign: "center",
				allowFiltering: true,
				cellAttrs: {
					template: props =>
						props.condition ? (
							<span className="vehicle-id">
								{props.condition}
								<br />
								{this.getTypeMarker(props.inventory_type_id)}
							</span>
						) : (
							<></>
						)
				}
			},
			{
				id: "photo_url",
				headerText: "photo",
				width: "50",
				textAlign: "center",
				cellAttrs: {
					template: props =>
						!props.photo_url.includes("default") ? (
							<span className="vehicle-image">
								<img src={`${props.photo_url}?q=30&w=65`} />
							</span>
						) : (
							<></>
						),
					attributes: { id: "lightboxworthy", class: "custom-photos" }
				}
			},
			{
				id: "selling_price",
				headerText: "price",
				allowFiltering: true,
				textAlign: "center",
				width: "55",
				cellAttrs: {
					template: props => <span>{formatCurrency(props.selling_price, 0)}</span>
				}
			},
			{
				id: "year",
				allowFiltering: true,
				textAlign: "center",
				width: "40"
			},
			{
				id: "make",
				allowFiltering: true
			},
			{
				id: "model",
				allowFiltering: true
			},
			{
				id: "trim",
				allowFiltering: true
			},
			{
				id: "odo",
				allowFiltering: true,
				textAlign: "center",
				width: "55"
			},
			{
				id: "stock_number",
				headerText: "stock / vin",
				// width: "90",
				cellAttrs: {
					template: props =>
						props.stock_number || props.short_vin ? (
							<span className="vehicle-id">
								{props.stock_number}
								<br />
								{props.short_vin}
							</span>
						) : (
							<></>
						),
					attributes: { class: "custom-stkvin" }
				}
			},
			{
				id: "body_style",
				headerText: "Body",
				allowFiltering: true
			},
			{
				id: "interior_color_shortened",
				headerText: "colors",
				allowFiltering: false,
				width: "90",
				cellAttrs: {
					template: props =>
						props.exterior_color_shortened || props.interior_color_shortened ? (
							<span>
								{props.interior_color_shortened}
								<br />
								{props.exterior_color_shortened}
							</span>
						) : (
							<></>
						),
					attributes: { class: "custom-colors" },
					clipMode: "Clip"
				}
			},
			{
				id: "vin",
				headerText: "vin",
				width: "55",
				textAlign: "center",
				allowFiltering: false,
				visible: false
			},
			{
				id: "location",
				headerText: "Location",
				width: "45",
				textAlign: "center",
				allowFiltering: false,
				cellAttrs: {
					template: props =>
						props && props.latitude ? (
							<Icon
								id="location"
								style={{ opacity: "1!important", fontSize: "23px" }}
								name="map marker alternate"
								link
								onClick={() =>
									this.setState({
										show_lot_location: {
											latitude: props.latitude,
											longitude: props.longitude
										}
									})
								}
							/>
						) : (
							<></>
						)
				}
			},
			{
				id: "flagged_by",
				headerText: "Flags",
				allowFiltering: false,
				width: "80",
				textAlign: "center",
				cellAttrs: {
					template: props => {
						return (
							<div className="inv-flags">
								{props.flagged_by ? (
									<span className="flag">
										<i className="yellow flag icon" />
										<br />
										<b>{props.flagged_by_user.full_name.split(" ").map(f => f.split("")[0].toUpperCase())}</b>
									</span>
								) : (
									""
								)}
								{props.recalled_at ? (
									<span className={`flag ${props.flagged_by ? "recall" : ""}`}>
										<Image src="/images/recall_icon.svg" alt="Recall" className="small-recall-icon flag" />
										<b>{props.recalled_by_initials}</b>
									</span>
								) : (
									""
								)}
							</div>
						);
					},
					attributes: { class: "custom-flagged" }
				}
			},
			{
				id: "action",
				allowFiltering: false,
				textAlign: "center",
				width: "65",
				commands: [
					{
						buttonOption: {
							cssClass: "e-flat e-note-button",
							iconCss: "e-note e-icons action",
							click: this.openNoteModal.bind(this)
						},
						title: "Add Note"
					},
					{
						buttonOption: {
							cssClass: "e-flat action vinfo-icon-btn",
							content: '<img src="/images/vinfo_icon.svg" />',
							click: this.getVinfo.bind(this)
						},
						title: "Get Vinfo"
					}
				]
			}
		];
		const headerList = this.getColumnHeaders();

		// Add phases and/or recon if dealer is using them
		const { dealer_phases } = this.props;
		if (dealer_phases) {
			const { phasers, phases } = dealer_phases;
			const hasRecon = phasers && phasers.recon_assignments && phasers.recon_assignments.length;
			const hasPhases = phases && phases.length;

			if (hasRecon) {
				headerData.push({
					id: "recon_level_name",
					headerText: "Workflow",
					allowFiltering: true,
					textAlign: "center",
					width: "75",
					cellAttrs: {
						template: props => <span>{props.recon_level_name ? props.recon_level_name : InventoryData.getReconLevelNameById(props.recon_level_id)}</span>,
						attributes: { class: "stage" }
					}
				} as any);
				headerList.splice(2, 0, "recon_level_name");
			}

			if (hasPhases) {
				headerData.push({
					id: "current_phase_name",
					headerText: "Stage",
					allowFiltering: true,
					textAlign: "center",
					width: "85",
					cellAttrs: {
						template: props => <span>{props.current_phase_name ? props.current_phase_name : InventoryData.getStageNameById(props.current_phase)}</span>,
						attributes: { class: "stage" }
					}
				});
				headerList.splice(2, 0, "current_phase_name");
			}
		}
		return GridColumnHeaders(gridInventory, headerData, headerList);
	}

	getTypeMarker(type: InventoryType) {
		const wholesale = type === InventoryType.Wholesale;
		if (wholesale) return <span className={`inventory-type-marker initial ${wholesale ? "wholesale" : ""} floating`}>{InventoryType[type][0]}</span>;
	}

	openNoteModal = (args: any) => {
		const rowObj: IRow<Column> = this.grid.getRowObjectFromUID(closest(args.target, ".e-row").getAttribute("data-uid"));
		const data: any = rowObj.data;
		const itemId: number = data.id;
		this.setState({
			note_modal: { title: "Add Vehicle Note", item_id: itemId }
		});
	};

	addVehicleNote = async (formData, itemid) => {
		const { storeId, refetchInventory } = this.props;
		const properties = removeNullProps(formData.noteForm);
		const data = {
			type: SystemLogType.VEHICLE_NOTE,
			description: properties.description
		};
		restAPI({
			endpointName: "addInventoryNote",
			urlArgs: [storeId, itemid],
			data,
			callback: (error, result) => {
				setTimeout(() => {
					this.sendAlert({
						type: error ? "danger" : "success",
						message: error ? error.content.message : result.data.message
					});
					refetchInventory();
					this.grid.refresh();
				}, 500);
			}
		});
	};

	addNoteContent() {
		const { note_modal } = this.state;
		const itemId = note_modal.item_id;
		return (
			<div id="Confirm">
				<span>
					<VehicleNoteForm
						{...{
							cost: false,
							files: false,
							btnText: "Add Note",
							onSubmit: data => this.setState({ note_modal: null }, () => this.addVehicleNote(data, itemId))
						}}
					/>
				</span>
			</div>
		);
	}

	render() {
		const {
			print_ready,
			detail_open,
			filter_condition,
			addVehicle,
			note_modal,
			grid_inventory,
			show_lot_location,
			update_key,
			selected_row_index,
			dataSyncEnabled
		} = this.state;
		const { storeId, dealer_phases, loading, toggleUABCallback, universalActionCallback, refetchInventory, sharePath } = this.props;
		let detail = this.state.detail;

		if (sharePath && grid_inventory) {
			const pathItems = sharePath.split("/");
			if (pathItems.includes("share")) {
				const shareDetail = grid_inventory.find(i => i.inventory_id === pathItems[pathItems.length - 1]);
				if (shareDetail) detail = shareDetail;
			}
		}

		if (loading || (sharePath && detail_open && !detail)) return <Loading />;

		const jobTitleSetting = this.props.store_settings.find(setting => setting.name === "TECHNICIAN_JOB_TITLE_IDS");
		const jobTitleIds = Array.from(jobTitleSetting.value.split(",")).map(item => Number(item));

		const inventoryProps: LMI.IInventoryProps = {
			canAddWorkLogs: hasPermission(this.props.permissions, Permission.ADD_WORK_LOG, Session.get("isSuperUser")),
			onCloseLightbox: () => this.setState({ lightbox_open: false }),
			getBucket: (age, ranges) => InventoryData.getBucket(age, ranges),
			getEOM: age => InventoryData.getEOM(age),
			onHandleErrorResponse: error => this.handleErrorResponse(error),
			onAlert: alert => this.sendAlert(alert),
			detailIsOpen: detail_open === false ? -1 : detail_open,
			filter_condition,
			inventory_item_id: detail ? detail.id : null,
			detail,
			print_ready,
			dealer_phases,
			storeId: storeId ? storeId.toString() : localStorage.getItem("selectedStoreId").toString(),
			jobTitleIds,
			tracking_path: this.props.tracking.getTrackingData().event_subtype,
			...this.props
		};

		const addInventoryModalProps: LMI.IGlobalAddInventoryProps = {
			isSub: true,
			setUpdateKey: update_key => {
				this.setState({ update_key }, () => this.props.refetchInventory());
			},
			onClose: () =>
				this.setState({ addVehicle: false }, () => {
					universalActionCallback();
					refetchInventory();
				})
		};

		const data = InventoryData.get();
		const DataGridProps: DataGridProps = {
			setGrid: grid => this.setGrid(grid),
			update_key,
			data,
			total: this.getCleanTotal(),
			columns: this.createGridColumns(grid_inventory, inventoryProps.dealer_phases),
			tools: [
				...[
					GridToolOptions.grouping,
					GridToolOptions.selection,
					GridToolOptions.pdfexport,
					GridToolOptions.excelexport,
					GridToolOptions.sorting,
					GridToolOptions.summary,
					GridToolOptions.infiniteScroll,
					GridToolOptions.resetButton,
					GridToolOptions.filter
				],
				...(dataSyncEnabled ? [GridToolOptions.hasDataSync] : [])
			],
			selectedRowIndex: selected_row_index,
			textwrap: true,
			cancelSelectionColumns: true,
			onSelect: item => this.handleSelectRow(item, grid_inventory),
			onReset: this.resetFilterRanges.bind(this),
			pdfExportOptions: {
				columns: this.getPrintableColumns()
			},
			noresults: {
				icon: "truck",
				text: "No inventory found, add some!",
				action: {
					text: "Add Inventory",
					callback: () => this.setState({ addVehicle: true })
				}
			}
		};

		return (
			<Grid centered className="grid-container inventory">
				{note_modal && (
					<ModalComponent
						size="small"
						headerText={note_modal ? note_modal.title : null}
						shouldBeOpen={true}
						className="note-modal"
						onClose={() => this.setState({ note_modal: null })}
						contentComponent={() => this.addNoteContent()}
					/>
				)}
				<MapsDialog
					{...{
						actions: [],
						open: show_lot_location ? true : false,
						location: show_lot_location,
						title: `Vehicle Location`,
						onClose: () => this.setState({ show_lot_location: null })
					}}
				/>
				<UniversalActionModalComponent
					size="tiny"
					scrolling={false}
					headerText="Add Inventory"
					toggleUABCallback={toggleUABCallback || addVehicle}
					contentComponent={() => this.getAddInventoryForm(addInventoryModalProps)}
					universalActionCallback={() => {
						this.setState({ addVehicle: false });
						universalActionCallback();
					}}
				/>
				<Grid.Row className="grid-inventory-row">
					{!print_ready && grid_inventory && (
						<Grid.Column className={`list-column${detail_open ? " detail" : ""}`}>
							{this.getCustomFilters(data.length)}
							<RenderDataGrid {...DataGridProps} />
						</Grid.Column>
					)}
					{detail && detail_open && (
						<Grid.Column className="detail-column">
							<Grid className="detail-container inventory">
								<Grid.Row className="detail-row bottom">
									<Grid.Column>
										<InventoryDetail
											hide_on_sold_and_dispositioned={dealer_phases.hide_on_sold_and_dispositioned}
											inventory_id={detail.inventory_id}
											close_btn={true}
											closeBtnCallback={() => this.closeDetail()}
											modifyGridItem={this.modifyGridItem.bind(this)}
											setUpdateKey={update_key => {
												this.resetPath();
												this.setState({ update_key }, () => this.props.refetchInventory());
											}}
											{...(this.props as LMI.InventoryDetailProps)}
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
					)}
				</Grid.Row>
			</Grid>
		);
	}

	modifyGridItem({ item }: LMI.ModifyGridItemProps) {
		if (item) InventoryData.updateItem(item);
	}

	modifyCache({ id, name, val }) {
		modifyCacheData(DataSyncQueries.inventory(this.props.storeId), this.props.client, cache => {
			let items = cache && cache.inventory_search;
			if (items && items.length) {
				const item = items.find(i => i.id === id);
				if (item) {
					item[name] = val;
					items = [...items, ...[item]];
				}
				return { inventory_search: items };
			}
		});
	}

	loadFilteredData(variables: Object) {
		this.props.refetchInventory({ ...variables });
		setTimeout(() => this.setState({ update_key: new Date() }), 50);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.storeId !== this.props.storeId) this.setState({ detail: null, detail_open: false });
		if (this.debugComponentReloadLooping) checkComponentUpdate(this.props, prevProps, this.state, prevState);
	}

	static gridInventoryData(props: LMI.IInventoryProps, state: LMI.IInventoryState): LMI.IInventoryGQL[] {
		const { inventory_results, store_settings, recon_levels, dealer_phases } = props;
		const { price_range, age_range, filter_condition, grid_inventory } = state;

		let gridData = [];
		if (inventory_results?.length) {
			const data = inventory_results;

			// Condition, age range, and price range filters are being done here, maybe this logic should live in the InventoryFilters component?
			if (data && data.length > 0)
				gridData = data
					.filter(item => (filter_condition === "ALL" ? true : item.condition.toUpperCase() === filter_condition.toUpperCase()))
					.filter(item => {
						const sellingPrice = item.selling_price ? parseFloat(item.selling_price) : 0;
						return sellingPrice >= price_range[0] && sellingPrice <= price_range[1];
					})
					.filter(item => {
						const age = item.days_in_stock >= 0 ? item.days_in_stock : 0;
						return age >= age_range[0] && age <= age_range[1];
					});
		}

		const needsInventory = grid_inventory?.length > 0 ? grid_inventory.length !== gridData.length || grid_inventory[0].id !== gridData[0].id : true;
		const hasConfigProps = store_settings && recon_levels && dealer_phases;
		const setInventory = needsInventory && hasConfigProps;

		return setInventory ? InventoryData.set(gridData, { store_settings, recon_levels, dealer_phases }) : grid_inventory;
	}

	static getDerivedStateFromProps(nextProps: LMI.IInventoryProps, prevState: LMI.IInventoryState) {
		let derived: LMI.IInventoryState = prevState;

		const getSetting = (name: string) => {
			const { store_settings } = nextProps;
			const setting = store_settings.find(set => set.name === name);
			return setting ? JSON.parse(setting.value) : null;
		};

		if (nextProps.store_settings)
			derived.dataSyncEnabled =
				JSON.parse(getSetting("DATA_SYNC_INVENTORY_ENABLED")) && (derived.search_text === "" || derived.search_text === null) && !derived.showSold;

		if (nextProps.inventory_results) {
			const grid_inventory = InventoryTabPanelView.gridInventoryData(nextProps, prevState);
			derived = { ...derived, ...{ grid_inventory } };

			// when detail present check if in list and clear selection to avoid errors
			if (prevState.detail && prevState.detail_open) {
				const detId = prevState.detail.id;
				// double check that the item wasn't deleted
				const detailInList = detId && grid_inventory.find(i => i.id === detId);
				if (grid_inventory && !detailInList) {
					derived.detail = null;
					derived.detail_open = false;
				}
			}

			// use params to set a deeplinked inventory detail
			if (window.location.search) {
				const params = new URLSearchParams(window.location.search);
				const selected_row_index = nextProps.inventory_results.findIndex(i => i.inventory_id === params.get("id"));
				// use the deeplinked_inv detail if available otherwise use selected row in the inventory result
				const detail = nextProps.deeplinked_inv ? nextProps.deeplinked_inv : selected_row_index >= 0 ? nextProps.inventory_results[selected_row_index] : null;
				if (detail)
					derived = {
						...derived,
						...{
							selected_row_index,
							detail_open: true,
							detail
						}
					};
			}
		}
		return derived;
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions,
		toggleUABCallback: state.app.admin.toggleUABCallback,
		addInventoryModalOpen: state.app.admin.addInventoryModalOpen,
		dataSync: state.app.admin.dataSync
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		universalActionCallback: () => {
			dispatch(triggerUABCallback(false));
		},
		disableUAB: () => {
			dispatch(enableUABCallback(false));
		},
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

const showUABOn = [{ viewType: "dealerships", permissions: [Permission.ADD_INVENTORY] }];
const InventoryTabPanelViewUAB: any = withUAB(InventoryTabPanelView, showUABOn);

export const InventoryTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventorySearchPartDuex, {
		options: (props: any) => {
			// if this is a print request, pull the selected store id from localstorage
			const selectedStoreIdForPrint = localStorage.getItem("selectedStoreId");
			return {
				variables: {
					store_ids: parseInt(props.storeId ? props.storeId : selectedStoreIdForPrint, 10),
					phase: null,
					sold: false,
					search_text: null
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inventory_search2, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				inventory_results: inventory_search2.items,
				refetchInventory: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventoryDetail, {
		skip: (ownProps: any) => (window.location.search ? false : true),
		options: (props: any) => {
			const selectedStoreIdForPrint = localStorage.getItem("selectedStoreId");
			const store_id = parseInt(props.storeId ? props.storeId : selectedStoreIdForPrint, 10);
			const params = new URLSearchParams(window.location.search);
			const inv_guid = params.get("id");
			return {
				variables: { store_id, inv_guid },
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, inventory_detail } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				deeplinked_inv: inventory_detail
			};
		}
	}),
	graphql<LMI.IOrgSettingsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.settings.store, {
		options: (props: any) => {
			// if this is a print request, pull the selected store id from localstorage
			const selectedStoreIdForPrint = localStorage.getItem("selectedStoreId");
			return {
				variables: {
					storeId: parseInt(props.storeId ? props.storeId : selectedStoreIdForPrint, 10),
					names: [
						"USED_INVENTORY_RANGE_BUCKET",
						"NEW_INVENTORY_RANGE_BUCKET",
						"CERTIFIED_INVENTORY_RANGE_BUCKET",
						"TECHNICIAN_JOB_TITLE_IDS",
						"DATA_SYNC_INVENTORY_ENABLED"
					]
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_settings, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				store_settings,
				refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.phases, {
		options: (props: any) => {
			const selectedStoreIdForPrint = localStorage.getItem("selectedStoreId");
			return {
				variables: {
					storeId: parseInt(props.storeId ? props.storeId : selectedStoreIdForPrint, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, dealer_phases, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				dealer_phases,
				refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.reconLevels, {
		options: (props: any) => {
			const selectedStoreIdForPrint = localStorage.getItem("selectedStoreId");
			return {
				variables: {
					storeId: parseInt(props.storeId ? props.storeId : selectedStoreIdForPrint, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_recon_levels, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				recon_levels: store_recon_levels.recon_levels
			};
		}
	})
)(InventoryTabPanelViewUAB) as React.ComponentType<LMI.IInventoryProps>;

export default InventoryTabPanel;
