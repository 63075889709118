// node_modules
import * as React from "react";
// components
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import { CropperAspectRatios, CropperComponent } from "./components/CropperComponent";

import "./FileDropComponent.css";

export class FileDropComponent extends React.Component<LMI.IFileDropProps, LMI.IFileDropState> {
	private _fileSizeMax = this.props.fileSizeMaxMB ? this.props.fileSizeMaxMB * 1000000 : 2000000;

	constructor(props) {
		super(props);

		this.state = {
			cropping: false,
			croppee: null,
			cropIndex: null,
			alert: null,
			previews: null,
			documents: null,
			is_dragging: false,
			is_dropping: false,
			is_uploading: false,
			badFiles: false,
			loading: false
		};
	}

	componentDidMount(): void {
		this.props.fileHandles && this.props.fileHandles.length > 0 && this.formatPassedInFiles();
	}

	formatPassedInFiles = async () => {
		this.setState({ loading: true });
		const { fileHandles, getFileObjectFromPath } = this.props;
		if (fileHandles.length > 0) {
			const existingDocuments: any[] = await Promise.all(fileHandles.filter(f => f).map(f => getFileObjectFromPath(f)));
			const previews: any[] = existingDocuments.map(e => e.base64);
			const documents: any[] = existingDocuments.map(e => e.file);
			this.setState({ previews, documents, loading: false });
		}
	};

	onDragEnter = args => {
		args.preventDefault();
		args.stopPropagation();

		if (!this.state.is_dragging) {
			this.setState({ is_dragging: true });
		}
	};

	onDragLeave = args => {
		args.preventDefault();
		args.stopPropagation();

		if (this.state.is_dragging) {
			this.setState({ is_dragging: false });
		}
	};

	onDrop = (event: any) => {
		event.persist();
		event.preventDefault();
		event.stopPropagation();
		if (event.currentTarget.id === "documentWrapper") {
			this.setState({ is_dropping: true }, async () => {
				const { fileTypes, multiple, generateThumbnail } = this.props;
				const dt = event.dataTransfer;
				let keepCount: number = 0;
				const files: string[] = Object.keys(dt.files)
					.map(key => dt.files[key])
					.filter((f, index) => {
						if ((!multiple && keepCount > 0) || f.size > this._fileSizeMax) return false;
						const keepMe: boolean = fileTypes.includes(f.type);
						if (keepMe) keepCount++;
						return keepMe;
					});
				const badFiles = dt.files.length > files.length;
				let previews;
				if (files && generateThumbnail) {
					previews = await this.getPreviews(files);
				}
				this.setState({ badFiles, previews, documents: files ? files : null, is_dragging: false, is_dropping: false }, () =>
					this.props.onReturnFileHandles(files)
				);
			});
		}
	};

	getPreviews = async (files: any): Promise<any[]> => {
		const { generateThumbnail } = this.props;
		return await Promise.all(
			files.map(f => {
				if (this.isImage(f)) {
					return generateThumbnail({
						file: f,
						bounding_box: [75, 75]
					});
				} else {
					return "";
				}
			})
		);
	};

	onBrowseFile = async (args: any) => {
		const { generateThumbnail } = this.props;
		const files = Object.keys(args.currentTarget.files).map(key => args.currentTarget.files[key]);

		let previews;
		if (files && generateThumbnail) {
			previews = await this.getPreviews(files);
		}

		this.setState({ previews, documents: files ? files : null, is_dragging: false }, () => this.props.onReturnFileHandles(files));
	};

	cropMe = async ({ file, index }) => {
		const { promiseBase64 } = this.props;
		const cFile = await promiseBase64(file);
		this.setState({ cropping: true, croppee: cFile, cropIndex: index });
	};

	removeFile = file => {
		const documents = this.state.documents.filter(d => d.name !== file.name);
		this.setState({ documents }, () => this.props.onReturnFileHandles(documents));
	};

	isImage = file => file.type.split("/")[0] === "image";

	getDroppedGrid = ({ documents }) => {
		const { previews } = this.state;
		const { humanFileSize } = this.props;
		return documents.map((file, index) => {
			const isImage = this.isImage(file);
			return (
				<Grid.Row className="dropped-row" key={index}>
					{previews && (
						<Grid.Column width={2} className="left thumbnail">
							{isImage ? <img src={previews[index]} /> : <i className="icon file" />}
						</Grid.Column>
					)}
					<Grid.Column width={previews ? 12 : 14} className="left">
						<div>{file.name}</div>
						{humanFileSize && <div>{humanFileSize({ bytes: file.cropsize ? file.cropsize : file.size, si: true, dp: 2 })}</div>}
					</Grid.Column>
					<Grid.Column width={1}>
						<i className="icon crop" onClick={this.cropMe.bind(this, { file, index })} />
					</Grid.Column>
					<Grid.Column width={1}>
						<i className="icon delete" onClick={this.removeFile.bind(this, file)} />
					</Grid.Column>
				</Grid.Row>
			);
		});
	};

	saveCrop = croppedImage => {
		const { previews, documents } = this.state;
		const { calculateBase64ImageSize } = this.props;
		documents[this.state.cropIndex].cropped = croppedImage;
		documents[this.state.cropIndex].cropsize = calculateBase64ImageSize({ base64String: croppedImage });
		previews[this.state.cropIndex] = croppedImage;
		this.setState({ previews, cropping: false, croppee: null, cropIndex: null }, () => {
			this.props.onReturnFileHandles(documents);
		});
	};

	onCancelUploadFiles = () => {
		this.setState({ documents: null });
	};

	render() {
		const { dropMessage, dropIcon, fileTypes, multiple } = this.props;
		const { documents, is_dragging, cropping, croppee, badFiles, loading } = this.state;
		const dropped = documents && documents.length > 0;

		if (loading)
			return (
				<Dimmer active inverted>
					<Loader inverted />
				</Dimmer>
			);

		if (cropping) {
			return (
				<CropperComponent
					{...{
						src: { preview: croppee },
						crop: {
							unit: "%",
							width: 100,
							aspect: CropperAspectRatios.square
						},
						onSave: croppedImage => this.saveCrop(croppedImage),
						onClose: () => this.setState({ cropping: false })
					}}
				/>
			);
		}

		return (
			<div
				id="documentWrapper"
				className="document-wrapper"
				onDrop={event => this.onDrop(event)}
				onDragEnter={this.onDragEnter}
				onDragOver={this.onDragEnter}
				onDragLeave={this.onDragLeave}
			>
				<div className="file-dropper">
					<div className={is_dragging ? "dropper dragdrop" : dropped ? "dropper dropped" : "dropper"}>
						{is_dragging ? (
							"Now, drop your file(s)"
						) : dropped ? (
							<Grid className="dropped-grid" columns={3} divided="vertically">
								{this.getDroppedGrid({ documents })}
								<Grid.Row className="dropped-row">
									<Grid.Column width={9} />
									<Grid.Column width={6} className="action">
										<Button className="button right" onClick={this.onCancelUploadFiles}>
											Clear All
										</Button>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						) : (
							<div>
								<i className={`icon ${dropIcon ? dropIcon : "files"}`} />
								{dropMessage}
								<input id="documentInput" type="file" name="" onChange={this.onBrowseFile.bind(this)} multiple />
							</div>
						)}
					</div>
					<div id="fileTypes" className={badFiles ? "error" : ""}>
						{`Allowed: ${fileTypes.map(f => f.split("/")[1].toUpperCase()).join(", ")}; Size: ${this._fileSizeMax / 1000000} MB or Less`}
					</div>
					<div id="multiple">Multiple Allowed: {multiple ? "Yes" : "No"}</div>
				</div>
			</div>
		);
	}
}
