import { restAPI } from "../../../../../utils/rest";
import * as React from "react";
import { ClassAttributes } from "react";
import { gqlQueries } from "gql-imports";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";

import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { Loading } from "../../../../../components/Loading";
import { DataFilterComponent } from "../../DataFilterComponent";
import { getSortableCheckbox, getSortableDropdown, getSortableEditable, SortableTable } from "../../SortableTable";
import { AddServiceComponent, EditCostComponent } from "./components";
import { addAlert } from "api/redux/actions";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

import "./ServicesTabPanel.css";

@track(
	props => {
		return {
			event_type: props.tracking_path ? AnalyticsEventType.SUBNAV : AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}services`,
			data: { details: props }
		};
	},
	{ dispatchOnMount: true }
)
export class ServicesTabPanelView extends React.Component<LMI.IServicesTPProps, LMI.IServicesTPState> {
	constructor(props) {
		super(props);
		this.state = {
			searchFilter: null,
			approveService: false,
			editCost: null,
			isVendor: props.viewType === "vendors sub-component"
		};
	}

	render() {
		const props = this.props;

		if (props.hasErrors) {
			console.log("GQL Errors", props.message);
			return null;
		}
		if (props.loading) return <Loading />;

		let services: LMI.IServicesRowInterface[] = this.formatServiceData(props.approvedStoreServices);
		if (this.state.searchFilter) {
			services = this.searchFilter(this.state.searchFilter, services);
		}

		const serviceTableData = this.getTableData(services);
		const availableServices = this.approvedVendorServices(props.approvedStoreServices, props.store.services);
		const hasAvailableUnApprovedServices = props.approvedStoreServices.length < props.store.services.length;
		const addServiceModalProps = {
			services: availableServices,
			approveService: serviceId => {
				this.addApproveVendorService(serviceId);
				if (hasAvailableUnApprovedServices) {
					this.setState({ approveService: false });
				}
			}
		};
		const editCostModalProps: LMI.IEditServiceCostProps = {
			service: this.state.editCost,
			editService: (data, serviceId) => {
				const formData = {
					base_price: data.base_price.toString()
				};
				this.vendorUpdateService(formData, serviceId);
				this.setState({ editCost: null });
			}
		};

		return (
			<div className="services-tab-panel panel-content">
				{this.state.approveService && (
					<ModalComponent
						scrolling
						headerText="Vendor Services"
						shouldBeOpen={this.state.approveService && hasAvailableUnApprovedServices}
						onClose={(evt, data) => {
							this.setState({ approveService: false });
						}}
						className="manage-service-modal"
						contentComponent={() => <AddServiceComponent {...addServiceModalProps} />}
						style={{ minHeight: "400px", maxHeight: "500px" }}
					/>
				)}
				{this.state.editCost && (
					<ModalComponent
						headerText={this.state.editCost.vendor_service.service.name + " (" + props.storeName + ")"}
						shouldBeOpen={this.state.editCost && this.state.isVendor}
						onClose={(evt, data) => {
							this.setState({ editCost: null });
						}}
						size="small"
						className="edit-cost-modal"
						contentComponent={() => <EditCostComponent {...editCostModalProps} />}
					/>
				)}
				<div className="sortable-container">
					<div className="sortable-filter">
						{hasAvailableUnApprovedServices && !this.state.isVendor ? (
							<Button className="addServiceBtn" onClick={() => this.setState({ approveService: true })}>
								Approve Services
							</Button>
						) : (
							<span />
						)}
						{serviceTableData.body.rows.length > 0 || this.state.searchFilter ? (
							<DataFilterComponent
								label="Filter Results"
								searchFilter={this.state.searchFilter}
								onChange={(p, { value }) => {
									this.setState({
										searchFilter: value
									});
								}}
							/>
						) : (
							<span />
						)}
					</div>
					<div className="sortable-content">
						<SortableTable filter={this.state.searchFilter} message="Currently no approved services to show. Approve one!" tableData={serviceTableData} />
					</div>
				</div>
			</div>
		);
	}

	// map array of already approved services for add services modal
	approvedVendorServices(approvedServices: any[], vendorServices: any[]) {
		return vendorServices
			.filter(service => !approvedServices.find(serv => service.id === serv.vendor_service.id))
			.map(service => {
				return {
					base_price: service.base_price,
					service: service.service.name,
					id: service.id,
					description: service.service.description
				};
			});
	}

	searchFilter(search: string, data: any[]) {
		const srch = search.toLowerCase();
		return data.filter(service => {
			return (
				service.description.toLowerCase().includes(srch) ||
				service.cost
					.toString()
					.toLowerCase()
					.includes(srch)
			);
		});
	}

	formatServiceData(services: any[]): LMI.IServicesRowInterface[] {
		const employeeOptions = this.props.employees && this.props.employees.map(pro => ({ text: pro.user.full_name, value: pro.id }));
		const employeeDropdown = (view: any, index: number, key: string, callback: any) => {
			return getSortableDropdown(view, index, key, employeeOptions, callback);
		};
		return services.map((service, index) => {
			const serviceObj: LMI.IServicesRowInterface = {
				description: service.vendor_service.service.name,
				cost: {
					component: getSortableEditable,
					value: `${service.base_price.toLocaleString("en-US", { style: "currency", currency: "USD" })}`,
					editable: this.state.isVendor,
					float: "right",
					editcallback: () => {
						this.setState({ editCost: service });
					}
				},
				is_primary: {
					value: service.is_primary,
					component: getSortableCheckbox,
					callback: data => {
						this.togglePrimary(data, service.id);
					}
				}
			};
			if (!this.state.isVendor) {
				serviceObj.active = {
					value: service.is_active,
					component: getSortableCheckbox,
					callback: data => {
						this.toggleActive(data, service.id);
					}
				};
			} else {
				serviceObj.employee = {
					component: employeeDropdown,
					value: service.default_vendor_employee_id,
					placeholder: !service.employee ? "Not Assigned" : "Select an Employee",
					callback: data => {
						const formData = {
							default_vendor_employee_id: data.data.value
						};
						this.vendorUpdateService(formData, service.vendor_service.id);
					}
				};
			}
			return serviceObj;
		});
	}

	getTableData(services: LMI.IServicesRowInterface[]) {
		const headers: LMI.IHeaderCellData[] = [
			{
				id: "description",
				label: "Service",
				sortable: true
			},
			{
				id: "cost",
				label: "Cost",
				sortable: true
			},
			{
				id: "is_primary",
				label: "Primary",
				sortable: false,
				collapsing: true
			}
		];

		if (!this.state.isVendor) {
			const isActive = {
				id: "active",
				label: "Active",
				sortable: false,
				collapsing: true
			};
			headers.push(isActive);
		} else {
			const employee = {
				id: "employee",
				label: "Employee",
				sortable: true
			};
			headers.push(employee);
		}

		return {
			headers,
			body: {
				rows: services
			}
		};
	}

	toggleActive(checked: any, serviceId: any) {
		const data = {
			is_active: checked.state
		};
		this.updateApprovedService(serviceId, data);
	}

	togglePrimary(checked: any, serviceId: any) {
		const data = {
			is_primary: checked.state
		};
		this.updateApprovedService(serviceId, data);
	}

	addApproveVendorService(serviceId: number) {
		const data = {
			vendor_service_id: serviceId
		};
		restAPI({
			endpointName: "addApproveVendorService",
			urlArgs: [this.props.storeId],
			data,
			callback: (err, res) => {
				if (err) {
					this.props.addAlert({
						type: "danger",
						message: err.reason.response.data.message,
						timeout: 3000
					});
				}
				this.props.refetchServices();
				this.props.refetch();
			}
		});
	}

	removeApprovedVendorService(serviceId: number) {
		restAPI({
			endpointName: "removeApproveVendorService",
			urlArgs: [this.props.storeId, serviceId],
			data: null,
			callback: (err, res) => {
				if (err) {
					this.props.addAlert({
						type: "danger",
						message: err.reason.response.data.message,
						timeout: 3000
					});
				}
				this.props.refetchServices();
				this.props.refetch();
			}
		});
	}
	updateApprovedService(serviceId: number, data: any) {
		restAPI({
			endpointName: "approveVendorService",
			urlArgs: [this.props.storeId, serviceId],
			data: data,
			callback: (err, res) => {
				if (err) {
					this.props.addAlert({
						type: "danger",
						message: err.reason.response.data.message,
						timeout: 3000
					});
				}
				this.props.refetchServices();
				this.props.refetch();
			}
		});
	}

	vendorUpdateService(data: any, serviceId: number) {
		const storeId = parseInt(this.props.storeId, 10);
		restAPI({
			endpointName: "updateApprovedService",
			urlArgs: [this.props.vendorId, storeId, serviceId],
			data: data,
			callback: (err, res) => {
				if (err) {
					this.props.addAlert({
						type: "danger",
						message: err.reason.response.data.message,
						timeout: 3000
					});
				}
				this.props.refetchServices();
				this.props.refetch();
			}
		});
	}
}

const mapStateToProps = (state: any, ownProps) => {
	return {
		// use the clientId passed from the clients tab as storeId for vendors
		storeId: ownProps.clientId ? ownProps.clientId : state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const ServicesTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.employees, {
		skip: (ownProps: any) => ownProps.viewType.includes("dealerships"),
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.vendorId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employees, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				employees,
				refetchEmployees: refetch
			};
		}
	}),
	graphql<LMI.IStoreApprovedVendorServicesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.vendorApprovedServices, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.viewType.includes("vendors") ? props.clientId : props.storeId, 10),
					vendorId: parseInt(props.vendorId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_approved_vendor_services, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				approvedStoreServices: store_approved_vendor_services,
				refetchServices: refetch
			};
		}
	}),
	graphql<LMI.IVendorStoreServicesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.vendor.vendorServices, {
		options: (props: any) => {
			return {
				variables: {
					id: parseInt(props.vendorId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				store,
				refetch
			};
		}
	})
)(ServicesTabPanelView) as React.ComponentType<any>;

export default ServicesTabPanel;
