import { restAPI } from "../../../../../utils/rest";
// node_modules
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { addAlert } from "api/redux/actions";
// components
import { Button, Grid } from "semantic-ui-react";
import { NewUserComponent, UsersListComponent } from "./components";
import { SearchComponent } from "client/pages/admin/components/SearchComponent";
import { StoreSelectorComponent } from "client/pages/admin/components/StoreSelectorComponent";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

import "./UsersComponent.css";

@track({ event_type: AnalyticsEventType.NAVIGATION, event_subtype: "users" }, { dispatchOnMount: true })
export class UsersComponentView extends React.Component<LMI.SUAdminUsersProps, LMI.SUAdminUsersState> {
	constructor(props) {
		super(props);
		this.state = {
			addUser: false,
			selectedStores: null,
			searchTerm: null,
			selectingMultiple: false
		};
	}

	render() {
		const stores = this.props.su_stores
			? this.props.su_stores.map((store, i) => ({ key: i, text: store.name, value: store.id, type: store.store_type_id, orgId: store.organization_id }))
			: [];
		const searchProps: LMI.ISearchProps = {
			placeholder: "Search for a user",
			onSubmit: data => {
				this.setState({ searchTerm: data.term, addUser: false });
			},
			onEmpty: () => {
				this.setState({ searchTerm: null });
			},
			tracking_path: this.props.tracking.getTrackingData().event_subtype
		};
		// user management component props
		const listProps: LMI.SUAdminUserListProps = {
			filter_stores: this.state.selectedStores && this.state.selectedStores.length ? this.state.selectedStores : null,
			search: this.state.searchTerm,
			multiSelect: this.state.selectingMultiple,
			allStores: this.props.su_stores,
			stores
		};
		const newUserProps: LMI.SUAdminNewUserProps = {
			stores,
			allStores: this.props.su_stores,
			success: formData => this.saveNewUser(formData),
			nevermind: () => this.setState({ addUser: false })
		};

		const storeSelectProps = {
			selectorId: "searchyUserStore",
			items: this.props.su_stores,
			placeholder: "Filter by Store",
			onSelection: selected => {
				const selectedStores =
					selected &&
					[selected].map((store, i) => {
						return store.id;
					});
				this.setState({
					selectedStores,
					addUser: false
				});
			}
		};
		return (
			<div id="su-users-component">
				<Grid className="tools">
					<Grid.Column width={4}>
						<SearchComponent {...searchProps} />
					</Grid.Column>
					<Grid.Column width={5}>
						<StoreSelectorComponent {...storeSelectProps} />
					</Grid.Column>
					<Grid.Column width={5}>
						<Button
							toggle
							active={this.state.selectingMultiple}
							onClick={() => this.setState({ selectingMultiple: this.state.selectingMultiple ? false : true })}
						>
							Select Multiple
						</Button>
					</Grid.Column>
					<Grid.Column width={2} textAlign="right">
						<Button
							icon={this.state.addUser ? "close" : "plus"}
							content={this.state.addUser ? "Cancel" : "Add User"}
							onClick={() => this.setState({ addUser: this.state.addUser ? false : true })}
						/>
					</Grid.Column>
				</Grid>
				{this.state.addUser ? <NewUserComponent {...newUserProps} /> : <UsersListComponent {...listProps} />}
			</div>
		);
	}

	saveNewUser(formData: any) {
		const permissions = {
			changed: formData.changed_permissions
				? formData.changed_permissions.map(change => {
						return {
							id: change.id,
							add_or_remove: change.checked ? "A" : "R"
						};
				  })
				: [],
			id: formData.store,
			roles: formData.role
		};
		const newUser: any = {
			first_name: formData.first_name,
			last_name: formData.last_name,
			email: formData.email,
			job_title_id: formData.job_title,
			role_ids: permissions.roles,
			permissions: permissions.changed
		};
		if (formData.phone) {
			newUser.phone = formData.phone;
		}

		restAPI({
			endpointName: "addStoreEmployee",
			urlArgs: [formData.store],
			data: newUser,
			callback: (error, result) => {
				if (!error) {
					this.setState({
						addUser: false
					});
					this.props.refetchStores();
				} else {
					this.props.addAlert({
						type: "danger",
						message: error.reason.response.data.message,
						timeout: 3000
					});
				}
			}
		});
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const UsersComponent = compose(connect(mapStateToProps, mapDispatchToProps))(UsersComponentView);
export default UsersComponent;
