import * as React from "react";
import { hasPermission } from "client/utils/userAccess";
import { Permission } from "loopmein-shared";
import { restAPI } from "client/utils/rest";
import { EditReconLevel, EditStage } from "./";
import { Session } from "client/utils/session";

export class WorkflowTools extends React.Component<
  LMI.InventoryDetailProps,
  undefined
> {
  renderWorkflowTools() {
    const { detail, dealer_phases, recon_levels } = this.props;
    const canViewReconLevel = recon_levels && recon_levels.length > 0;
    const canViewReconStage = dealer_phases && dealer_phases.length > 0;
    const canChangeReconLevel =
      detail.condition.toLowerCase() !== "new" &&
      detail.is_active &&
      hasPermission(
        this.props.permissions,
        Permission.EDIT_INVENTORY,
        Session.get("isSuperUser")
      );
    const canChangeReconStage = hasPermission(
      this.props.permissions,
      Permission.STAGE_MANAGER,
      Session.get("isSuperUser")
    );
    return (
      <div>
        {canViewReconLevel && (
          <span className="recon-level">
            <EditReconLevel
              {...{
                readonly: !canChangeReconLevel,
                recon_levels,
                detail,
                onSubmit: (data) => this.editReconLevel(data, detail),
              }}
            />
          </span>
        )}
        {canViewReconStage && (
          <span className="recon-stage">
            <EditStage
              {...{
                readonly: !canChangeReconStage,
                stages: dealer_phases,
                detail,
                onSubmit: (phase_id) => this.editReconStage(phase_id, detail),
              }}
            />
          </span>
        )}
      </div>
    );
  }

  editReconLevel(new_recon_level: number, detail): void | boolean {
    const { storeId, refetchDetail, setUpdateKey } = this.props;
    if (!new_recon_level) return false;
    restAPI({
      endpointName: "editInventoryReconLevel",
      urlArgs: [storeId, detail.id, new_recon_level],
      data: null,
      callback: (error, result) => {
        refetchDetail();
        setTimeout(() => {
          if (setUpdateKey) setUpdateKey(new Date());
          this.sendAlert({
            type: error ? "danger" : "success",
            message: error ? error.message : result.data.message,
          });
        }, 400);
      },
    });
  }

  editReconStage(phase_id, detail): void | boolean {
    const stageData = { current_phase: phase_id, comment: null };
    const { storeId, refetchDetail, setUpdateKey } = this.props;
    if (!phase_id) return false;

    restAPI({
      endpointName: "updateInventoryPhase",
      urlArgs: [storeId, detail.id],
      data: stageData,
      callback: (error, result) => {
        refetchDetail();
        setTimeout(() => {
          if (setUpdateKey) setUpdateKey(new Date());
          this.sendAlert({
            type: error ? "danger" : "success",
            message: error ? error.content.message : result.data.message,
          });
        }, 400);
      },
    });
  }

  sendAlert({ type, message }) {
    this.props.addAlert({
      type,
      message,
      timeout: 3000,
    });
    if (type === "danger") {
      console.log(`Error: ${message}`);
    }
  }

  render() {
    return <div id="workflowTools">{this.renderWorkflowTools()}</div>;
  }
}

export default WorkflowTools;
