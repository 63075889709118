import { combineReducers } from "redux";

import {
  CHANGE_STORE,
  LOGIN_USER,
  TRIGGER_UAB_CALLBACK,
  ENABLE_UAB_CALLBACK,
  RESET_STORE,
  UPDATE_TOUR,
  CHANGE_TAB,
  CHANGE_SUBTAB,
  CHANGE_USERTYPE,
  CHANGE_SELECTED_EMPLOYEE,
  ADD_ALERT,
  REMOVE_ALERT,
  INVENTORY_LOG_UPDATE,
  UPDATE_ROUTES,
  UPDATE_CURRENT_ROUTE,
  TOGGLE_QUICK_SEARCH,
  TOGGLE_ADD_INVENTORY,
  TOGGLE_HEADER,
  ENABLE_DATA_SYNC,
  UPDATE_CURRENT_USER,
} from "./actions";

const initialAdminState: LMI.IAdminReduxState = {
  storeId: "",
  storeName: "Pick a Store",
  userType: null,
  permissions: [],
  routes: [],
  currentRoute: "administration",
  currentUser: null,
  showUAB: false,
  toggleUABCallback: false,
  steps: null,
  tabSelected: null,
  subtabSelected: null,
  employeeId: null,
  alert: null,
  quickSearchOpen: false,
  addInventoryModalOpen: false,
  showHeader: true,
  dataSync: {
    inventory: false,
  },
};

function adminReducer(
  state: LMI.IAdminReduxState = initialAdminState,
  action: any
) {
  switch (action.type) {
    case CHANGE_STORE:    
      return Object.assign({}, state, {
        storeName: action.data.storeName,
        storeId: action.data.storeId,
        permissions: action.data.permissions,
      });
    case LOGIN_USER:
      return Object.assign({}, state, {
        storeName: action.data.storeName,
      });
    case UPDATE_ROUTES:
      return Object.assign({}, state, {
        routes: action.routes,
      });
    case UPDATE_CURRENT_ROUTE:
      return Object.assign({}, state, {
        currentRoute: action.routeName,
      });
    case UPDATE_CURRENT_USER:
      return Object.assign({}, state, {
        currentUser: action.user,
      });
    case TRIGGER_UAB_CALLBACK:
      return Object.assign({}, state, {
        toggleUABCallback: action.toggleUABCallback,
      });
    case ENABLE_UAB_CALLBACK:
      return Object.assign({}, state, {
        showUAB: action.showUAB,
      });
    case UPDATE_TOUR:
      return Object.assign({}, state, {
        steps: action.steps,
      });
    case CHANGE_TAB:
      return Object.assign({}, state, {
        tabSelected: action.tabName,
      });
    case CHANGE_SUBTAB:
      return Object.assign({}, state, {
        subtabSelected: action.tabName,
      });
    case CHANGE_USERTYPE:
      return Object.assign({}, state, {
        userType: action.userType,
      });
    case CHANGE_SELECTED_EMPLOYEE:
      return Object.assign({}, state, {
        employeeId: action.employeeId,
      });
    case ADD_ALERT:
      return Object.assign({}, state, {
        alert: action.alert,
      });
    case REMOVE_ALERT:
      return Object.assign({}, state, {
        alert: null,
      });
    case INVENTORY_LOG_UPDATE:
      return Object.assign({}, state, {
        triggerLogUpdate: action.triggerLogUpdate,
      });
    case TOGGLE_QUICK_SEARCH:
      return Object.assign({}, state, {
        quickSearchOpen: action.quickSearchOpen,
      });
    case TOGGLE_ADD_INVENTORY:
      return Object.assign({}, state, {
        addInventoryModalOpen: action.addInventoryModalOpen,
      });
    case TOGGLE_HEADER:
      return Object.assign({}, state, {
        showHeader: action.showHeader,
      });
    case ENABLE_DATA_SYNC:
      return Object.assign({}, state, {
        dataSync: action.dataSync,
      });
    case RESET_STORE:
      return initialAdminState;
    default:
      return state;
  }
}

export const appReducer = combineReducers({
  admin: adminReducer,
});
