import _ from "underscore";
import * as React from "react";
import { useGlobals } from "api/redux/hooks";
import { hasPermissions } from "client/utils/userAccess";
import { Session } from "client/utils/session";

// Eventually delete this function in favor of the WithUAB component below
export function withUAB(WrappedComponent, showUABOn) {
	return class extends React.Component {
		render() {
			return (
				<WithUAB showUABOn={showUABOn}>
					<WrappedComponent {...this.props} />
				</WithUAB>
			);
		}
	};
}

export const useUAB = (config: useUABConfig[], props: any) => {
	const { enableUABCallback } = useGlobals();
	React.useEffect(() => {
		let showUAB;
		let canUAB = true;
		const { viewType, permissions } = props;

		if (viewType) showUAB = _.findWhere(config, { viewType });
		if (permissions && showUAB?.permissions) canUAB = hasPermissions(permissions, showUAB.permissions, Session.get("isSuperUser"));

		enableUABCallback(!!showUAB && canUAB);
		return () => enableUABCallback(false);
	}, []);
};

// (W)ith UAB functional component uses the useUAB hook instead of doing all the stuff above
export const WithUAB = ({ showUABOn, children }) => {
	useUAB(showUABOn, children.props);
	return children;
};

interface useUABConfig {
	viewType?: string;
	permissions?: string[];
}
