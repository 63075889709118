import * as React from "react";
import { formatCurrency } from "client/utils/functions";
import { InspectionLineItemStatus } from "loopmein-shared";
import { InspectionService } from "../util";

import { Label, Dropdown, Grid, Popup } from "semantic-ui-react";
import { InspectionTypeSelect, InspectionTemplateSelector, InspectionItem } from "./";

import ToursIntroPopup, { ToursIntroPopupProps } from "client/components/Tours/ToursIntroPopup";

export class InspectionItemsForm extends React.Component<LMI.IReconInspectionItemsFormProps, LMI.IReconInspectionItemsFormState> {
	constructor(props: LMI.IReconInspectionItemsFormProps) {
		super(props);

		this.state = {
			new_item: null,
			tour: null
		};
	}

	getInspectionItems(inspection: LMI.IReconInspection): LMI.IInspectionLineItem[] {
		const items = inspection && inspection.inspection_line_items;
		return items ? items : this.state.tour ? this.tourSampleItems() : [];
	}

	getActions() {
		if (!this.props.statuses || this.props.statuses.length < 0) return [];
		const batchUpdatableStatus = [
			InspectionLineItemStatus.Approved,
			InspectionLineItemStatus.Declined,
			InspectionLineItemStatus.Completed,
			InspectionLineItemStatus.Rework
		];
		const items = this.props.inspection && this.props.inspection.inspection_line_items;

		const friskActionable = status => {
			if (items) {
				const itemsActionable = items.filter(i => InspectionService.getStatusActionItems(i, status.id));
				return itemsActionable.length > 0;
			} else if (this.state.tour) return true;
			else return false;
		};

		return this.props.statuses
			.filter(i => batchUpdatableStatus.includes(i.id))
			.filter(i => friskActionable(i))
			.map((s, key) => ({
				key,
				text: s.name,
				value: s.id,
				label: { style: { background: `#${s.color_code}` }, empty: true, circular: true }
			}));
	}

	async createItem(): Promise<void> {
		const { create_item } = this.props;
		const { new_item } = this.state;
		const item: any = {
			item: new_item,
			inspection_item_option_id: new_item.id,
			parts: new_item.parts,
			labor: new_item.labor,
			hours: new_item.hours
		};
		create_item(item);
		this.setState({ new_item: null });
	}

	async actionSelected(status: number): Promise<void> {
		const sectionItems = this.getInspectionItems(this.props.inspection);
		if (sectionItems && sectionItems.length > 0) {
			const items = sectionItems.filter(i => InspectionService.getStatusActionItems(i, status)).map(i => i.id);
			if (items.length > 0) this.props.update_status({ items, status });
			else console.log("no items to update");
		}
	}

	newItemForm(types) {
		const { can_admin, can_edit, add_item_option, templates, load_template } = this.props;
		const { new_item } = this.state;

		if (can_edit)
			return (
				<div id="NewItemField" className="new-item-form">
					<InspectionTypeSelect
						{...{
							id: `TypeSelectNewItem`,
							types,
							templates,
							is_new: true,
							can_add: can_admin,
							disabled: !can_edit,
							selected_id: new_item ? new_item.id : null,
							clearable: true,
							placeholder: "Add Item",
							onSave: new_item => new_item && this.setState({ new_item }, () => this.createItem()),
							onAddItem: (e, data) => data && add_item_option(data.value, null),
							onLoadTemplate: template => load_template(template),
							customProps: {
								icon: "add",
								...this.tourTypeSelectProps()
							}
						}}
					/>
				</div>
			);
	}

	itemsTableHeader(inspectionItems: LMI.IInspectionLineItem[], types) {
		const { parts, labor, total, hours } = InspectionService.getItemsTotals(inspectionItems);
		const totalsClass = `head-totals blue`;

		return (
			<div className="price-breakdown">
				<Grid>
					<Grid.Row style={{ paddingTop: "0.5em" }}>
						<Grid.Column width={10}>
							<Grid style={{ margin: 0 }}>
								<Grid.Column width={7}>{this.props.can_edit ? this.newItemForm(types) : "STATUS/TYPE"}</Grid.Column>
								<Grid.Column width={6} />
								<Grid.Column width={3} className="center-labels">
									CATEGORY
								</Grid.Column>
							</Grid>
						</Grid.Column>
						<Grid.Column width={6}>
							<Grid id="PriceBreakdown" style={{ height: "66px", paddingTop: "4px" }}>
								<Grid.Column width={4}>
									<div className="arrow-left"></div>
									<div className="price-label">
										PARTS $ <div className={totalsClass}>{parts ? ` ${formatCurrency(parts, 2)}` : "0"}</div>
									</div>
								</Grid.Column>
								<Grid.Column width={4}>
									<div className="arrow-left"></div>
									<div className="price-label">
										LABOR $ <div className={totalsClass}>{labor ? ` ${formatCurrency(labor, 2)}` : "0"}</div>
									</div>
								</Grid.Column>
								<Grid.Column width={4}>
									<div className="arrow-left"></div>
									<div className="price-label">
										HOURS <div className={totalsClass}>{hours ? ` ${hours.toFixed(1)}` : "0"}</div>
									</div>
								</Grid.Column>
								<Grid.Column width={4}>
									<div className="price-label">
										TOTAL <div className={totalsClass}>{total ? ` ${formatCurrency(total, 2)}` : "0"}</div>
									</div>
								</Grid.Column>
							</Grid>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>
		);
	}

	getReconLevel(str: string): string {
		const { recon_level } = this.props;
		const replacement = recon_level ? recon_level.name : "Default";
		return str.replace(new RegExp("{{reconLevel}}"), replacement);
	}

	render() {
		const { inspection, inspection_item_type_options, can_approve, can_admin } = this.props;
		const inspectionItems: LMI.IInspectionLineItem[] = this.getInspectionItems(inspection);
		const itemsCount: number = inspectionItems.length;
		const itemTypes = inspection_item_type_options ? inspection_item_type_options : [];
		const hasItems = inspectionItems && inspectionItems.length > 0;
		const actions = this.getActions();

		return (
			<div className="forms pad blue open">
				{hasItems && (
					<span id="BulkActions" className="section-actions">
						{can_admin && <InspectionTemplateSelector {...this.props} />}
						{can_approve && actions.length > 0 && (
							<Popup
								content="Bulk Actions Menu"
								inverted
								position="top right"
								hideOnScroll
								trigger={
									<Dropdown floating button labeled icon={{ name: "bolt" }} className="options-button" direction="left" {...this.tourActionMenuProps()}>
										<Dropdown.Menu className="action-menu">
											<Dropdown.Header>SET BULK STATUS</Dropdown.Header>
											{actions.map(option => (
												<Dropdown.Item key={option.value} {...option} onClick={() => this.actionSelected(option.value)} />
											))}
										</Dropdown.Menu>
									</Dropdown>
								}
							/>
						)}
					</span>
				)}
				<div className="section-title">
					<ToursIntroPopup
						{...({
							route: "/inspections",
							name: "InspectionUpdateIntro",
							popupProps: {
								position: "left center",
								className: "bring-top"
							},
							trigger: () => <span className="stitle">{this.getReconLevel("{{reconLevel}} Inspection Items")}</span>
						} as ToursIntroPopupProps)}
					/>
					<span className="corner">
						{itemsCount > 0 && (
							<Label circular size="tiny">
								{itemsCount}
							</Label>
						)}
					</span>
				</div>
				<div className="section-items">
					{hasItems ? (
						[
							<div key="grid-header" id="InspectionItems" className="items-header-grid">
								{this.itemsTableHeader(inspectionItems, itemTypes)}
							</div>,
							<div key="grid-list" className="inspection-items-list">
								{inspectionItems.map((item: LMI.IInspectionLineItem, index) => {
									const includeSelectedOption = inspection_item_type_options
										? inspection_item_type_options.filter(i => i.id === item.inspection_item_option_id)
										: [];
									const types = [...includeSelectedOption, ...itemTypes];
									return (
										<div key={item.id} id={`InspectionItem${index + 1}`}>
											<InspectionItem
												{...({
													item,
													types,
													...this.props
												} as LMI.InspectionItem)}
											/>
										</div>
									);
								})}
							</div>
						]
					) : (
						<div className="new-item-only">{this.newItemForm(itemTypes)}</div>
					)}
					{this.tourCtrl()}
				</div>
			</div>
		);
	}

	tourSampleItems() {
		const inspection_item_option = this.props.inspection_item_type_options[0];
		return [
			{
				comment: "Add Comment/Reason Here",
				hours: 1,
				id: 1492,
				inspection_item_option,
				inspection_item_option_id: inspection_item_option.id,
				inspection_line_item_section_id: 3,
				inspection_line_item_status: { id: 1, name: "Needs Estimate", __typename: "InspectionLineItemStatus" },
				inspection_line_item_status_id: 1,
				is_approved: false,
				is_declined: false,
				labor: 22,
				parts: 12,
				total: 34
			}
		];
	}

	tourTypeSelectProps() {
		let props;
		if (this.state.tour == TourSteps.addItem) props = { open: true };
		else if (this.state.tour == TourSteps.addItemType) props = { open: true, searchQuery: "New Item Type" };
		else if (this.state.tour == TourSteps.priceActions) props = { searchQuery: "" };
		else props = { searchQuery: undefined };
		return props;
	}

	tourActionMenuProps() {
		return this.state.tour == TourSteps.openActions ? { open: true } : {};
	}

	tourCtrl() {
		return (
			<>
				<span id="OpenTypeDropdown" onClick={() => this.setState({ tour: TourSteps.addItem })} />
				<span id="AddItemTypeSample" onClick={() => this.setState({ tour: TourSteps.addItemType })} />
				<span id="CloseTypeDropdown" onClick={() => this.setState({ tour: TourSteps.priceActions })} />
				<span id="OpenBulkActionMenu" onClick={() => this.setState({ tour: TourSteps.openActions })} />
				<span id="CloseBulkActionMenu" onClick={() => this.setState({ tour: TourSteps.itemList })} />
				<span id="CloseInspectionTour" onClick={() => this.setState({ tour: null })} />
			</>
		);
	}
}

enum TourSteps {
	addItem = 1,
	addItemType = 2,
	priceActions = 3,
	openActions = 4,
	itemList = 5
}

export default InspectionItemsForm;
