import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { addAlert } from "../../../api/redux/actions";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import {
  Button,
  Checkbox,
  Container,
  Dimmer,
  Divider,
  Header,
  Icon,
  Image,
  Item,
  Loader,
  Segment,
} from "semantic-ui-react";
import { restAPI } from "../../utils/rest";
import "./Unsubscribe.css";

export interface setGetVariables {
  id: string;
  checked: boolean;
}

export class UnsubscribeView extends React.Component<
  LMI.UnsubscribeViewProps,
  LMI.UnsubscribeViewState
> {
  nostoreimage =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/300px-No_image_available.svg.png";
  constructor(props: LMI.UnsubscribeViewProps) {
    super(props);
    this.state = {
      selectedItems: [],
    };
  }

  setGetSelected = ({ id, checked }: setGetVariables) => {
    let selectedItems = this.state.selectedItems;
    const selectedHasItem = this.state.selectedItems.indexOf(id) > -1;
    if (checked && !selectedHasItem) {
      selectedItems.push(id);
    } else if (!checked && selectedHasItem) {
      selectedItems = this.state.selectedItems.filter((i) => i !== id);
    }
    return selectedItems;
  };

  submitUnsubscribables = () => {
    if (this.state.selectedItems && this.state.selectedItems.length > 0) {
      restAPI({
        endpointName: "unsubscribeVehicleShares",
        urlArgs: null,
        data: this.state.selectedItems,
        callback: (error, result) => {
          this.props.addAlert({
            type: error ? "danger" : "success",
            message: error
              ? "We are sorry, there was a problem unsubscribing"
              : result.data.message,
            timeout: 3000,
          });
          this.props.refetch();
        },
      });
    } else {
      this.props.addAlert({
        type: "warning",
        message: "You haven't selected any vehicles to be unsubscribed from",
        timeout: 3000,
      });
    }
  };

  selectAllVehicles = (checked) => {
    if (checked) {
      const selectedItems = [];
      this.props.customer_subscriptions.stores.forEach((i) => {
        i.inventory_subscriptions.forEach((ii) => {
          selectedItems.push(ii.id);
        });
      });
      this.setState({ selectedItems });
    } else {
      this.setState({ selectedItems: [] });
    }
  };

  render() {
    if (this.props.hasErrors)
      console.log(`Unsubscribe Data errors: ${this.props.error}`);
    if (this.props.loading) {
      return (
        <Dimmer inverted active>
          <Loader indeterminate>Loading</Loader>
        </Dimmer>
      );
    }

    const subscriptions: LMI.UnsubscribableStore[] =
      this.props.customer_subscriptions &&
      this.props.customer_subscriptions.stores;
    if (subscriptions.length === 0) {
      return (
        <Container id="unsubscribe">
          <br />
          <Segment placeholder>
            <Header icon>
              <Icon name="thumbs up" />
              You have unsubscribed from all vehicles!
            </Header>
          </Segment>
        </Container>
      );
    }

    return (
      <Container id="unsubscribe">
        <Header
          as="h2"
          className="main"
          content="Which items would you like to unsubscribe from?"
        />
        <Checkbox
          className="checkAll"
          label="Unsubscribe from All Vehicles"
          onChange={(e, data) => this.selectAllVehicles(data.checked)}
        />
        <Divider />
        <Item.Group>
          {subscriptions &&
            subscriptions.map((s: LMI.UnsubscribableStore, key: number) => {
              return (
                <Item key={key}>
                  <Item.Content>
                    <Item.Header>
                      <Image
                        size="tiny"
                        className="store-logo"
                        src={s.store_logo ? s.store_logo : this.nostoreimage}
                        loading="lazy"
                      />
                      <span className="item-header">{s.store_name}</span>
                      <span className="item-header-sub">
                        Select Vehicles from this Dealership below
                      </span>
                    </Item.Header>
                    <Item.Description>
                      {s.inventory_subscriptions
                        ? s.inventory_subscriptions.map((ss, k) => {
                            return (
                              <Checkbox
                                key={k}
                                label={ss.description}
                                checked={
                                  this.state.selectedItems.indexOf(ss.id) !== -1
                                }
                                onChange={(e, data: any) =>
                                  this.setState({
                                    selectedItems: this.setGetSelected({
                                      id: ss.id,
                                      checked: data.checked,
                                    }),
                                  })
                                }
                              />
                            );
                          })
                        : ""}
                    </Item.Description>
                    <Divider />
                  </Item.Content>
                </Item>
              );
            })}
        </Item.Group>
        <div className="submit-button">
          <Button
            size="huge"
            content="SUBMIT"
            onClick={() => this.submitUnsubscribables()}
            positive={
              this.state.selectedItems && this.state.selectedItems.length > 0
            }
          />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const Unsubscribe = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<any, any, any, ClassAttributes<any>>(gqlQueries.unsubscribableUser, {
    options: (props: LMI.UnsubscribeViewProps) => {
      const query = props.location.search.substring(1);
      const queryStrings = query.split("&");
      const queryValues = queryStrings.map((v) => {
        const val = v.split("=");
        return val[1];
      });
      const variables = {
        lookupValue: queryValues[0],
        lookupType: queryValues[1],
      };
      return {
        variables,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
      };
    },
    props: ({
      data: { error, loading, customer_subscriptions, refetch },
    }): any => {
      if (loading) return { loading: true };
      if (error) return { hasErrors: true, message: error };
      return {
        customer_subscriptions,
        refetch,
      };
    },
  })
)(UnsubscribeView) as React.ComponentType<any>;

export default Unsubscribe;
