// node_modules
import * as React from "react";
// components
import { Button } from "semantic-ui-react";
import { Form, TextArea } from "formsy-semantic-ui-react";

export class InvoiceCommentComponent extends React.Component<LMI.IInvoiceCommentProps, undefined> {
	render() {
		const props = this.props;

		return (
			<div id="invoice-comment">
				<Form onSubmit={this.submitForm.bind(this)}>
					<Form.Field>
						<TextArea rows={3} cols={40} name="comment" value={props.invoice && props.invoice.comment} placeholder="Write your comment and click save" />
						<Button positive content="Save Comment" />
					</Form.Field>
				</Form>
			</div>
		);
	}

	submitForm(formData: any) {
		this.props.onSubmit(formData, this.props.invoice.id);
	}
}

export default InvoiceCommentComponent;
