import { graphql } from "@apollo/react-hoc";
import { FormControlLabel } from "@material-ui/core";
import { gqlQueries } from "gql-imports";
import { addAlert } from "api/redux/actions";

import { formatPhone } from "client/utils/functions";
import { hasPermission } from "client/utils/userAccess";
import { flowRight as compose } from "lodash";
import {
  AnalyticsEventType,
  AutoApproveTasks,
  Permission,
} from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Dropdown,
  Header,
  Icon,
  Image,
  List,
  Message,
  Popup,
} from "semantic-ui-react";
import {
  confirmDefault,
  ConfirmDialog,
} from "../../../../../components/ConfirmDialog";
import { Loading } from "../../../../../components/Loading";
import { restAPI } from "../../../../../utils/rest";
import track from "react-tracking";
import { Session } from "client/utils/session";

import "./ContactTabPanel.css";

@track(
  (props) => {
    return {
      event_type: props.tracking_path
        ? AnalyticsEventType.SUBNAV
        : AnalyticsEventType.NAVIGATION,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }contact`,
      data: { details: props },
    };
  },
  { dispatchOnMount: true }
)
export class ContactTabPanelView extends React.Component<
  LMI.IContactTPProps,
  LMI.IContactTPState
> {
  constructor(props: LMI.IContactTPProps) {
    super(props);
    this.state = {
      confirmDialog: confirmDefault,
      inviteResent: null,
    };
  }

  sendUserInvite(user: any, storeId: string, invited: any) {
    restAPI({
      endpointName: "inviteStoreEmployeeUser",
      urlArgs: [storeId.toString(), user.id],
      data: null,
      callback: (err, res) => {
        const message = !err
          ? "An email invitation was sent to " + user.full_name + "!"
          : err.reason.response.data.message;
        this.props.addAlert({
          type: !err ? "success" : "danger",
          message,
          timeout: 5000,
        });
        this.setState({ inviteResent: true });
      },
    });
  }

  getAutoApprovedOptions() {
    const options = [];
    for (const text in AutoApproveTasks) {
      if (isNaN(parseInt(text, 10))) {
        options.push({
          text: text.replace(/([A-Z])/g, " $1"),
          key: text,
          value: text,
        });
      }
    }
    return options;
  }

  render() {
    const props = this.props;
    const { store } = this.props;
    const { confirmDialog } = this.state;
    const canEditVendor = hasPermission(
      this.props.permissions,
      Permission.ADMIN_EDIT_VENDOR,
      Session.get("isSuperUser")
    );
    if (!props.vendorId) {
      return <p>No store selected.</p>;
    }
    if (props.hasErrors) {
      console.log("ContactTabPanel Data errors:", props.error);
    }
    if (props.loading || !props.store) {
      return <Loading />;
    }

    const autoApproveOptions = this.getAutoApprovedOptions();

    return (
      <div className="contact-tab-panel panel-content">
        <div className="tab-header">
          <div className="contact-image">
            <img
              src={
                props.store && props.store.logo_url
                  ? props.store.logo_url + "?w=150&auto=compress"
                  : ""
              }
              className={
                props.store &&
                props.store.logo_url &&
                props.store.logo_url.includes("default_vendor_logo.png")
                  ? ""
                  : ""
              }
            />
          </div>
          <div className="contact-details">
            <List>
              <List.Item>
                <Header as="h3">{props.store && props.store.name}</Header>
              </List.Item>
              <List.Item>
                Store Code:{" "}
                <span className="bold">
                  {props.store && props.store.store_code
                    ? props.store.store_code
                    : props.store_code}
                </span>
              </List.Item>
              {props.store && props.store.website_url && (
                <List.Item>
                  <List.Icon name="globe" />
                  <List.Content>
                    <a
                      href={`${"https://" + props.store.website_url}`}
                      target="_blank"
                    >
                      {props.store.website_url}
                    </a>
                  </List.Content>
                </List.Item>
              )}
            </List>
          </div>
          {props.viewType === "dealerships sub-component" && canEditVendor && (
            <FormControlLabel
              className="right-toggle"
              control={
                <Checkbox
                  toggle
                  checked={props.is_active}
                  className="pad"
                  onClick={(e, data) =>
                    this.setState({
                      confirmDialog: {
                        open: true,
                        title:
                          (props.is_active ? "Deactivate" : "Activate") +
                          " Vendor?",
                        text:
                          "Please confirm you would like to " +
                          (props.is_active
                            ? "deactivate this vendor.  NOTE: Any open tasks assigned to this vendor WILL BECOME UNASSIGNED!"
                            : "activate this vendor."),
                        success: () => {
                          this.toggleVendorActive(data);
                          this.setState({ confirmDialog: confirmDefault });
                        },
                        failure: () =>
                          this.setState({ confirmDialog: confirmDefault }),
                      },
                    })
                  }
                />
              }
              label={props.is_active ? "Active" : "Not Active"}
              labelPlacement="start"
            />
          )}

          <ConfirmDialog {...confirmDialog} />
          {props.viewType === "dealerships sub-component" && canEditVendor && (
            <FormControlLabel
              className="right-toggle"
              control={
                <Dropdown
                  style={{ marginLeft: "10px", width: "120px" }}
                  placeholder="Pick an Option"
                  compact
                  selection
                  selectOnBlur={false}
                  options={["Yes", "No"].map((s, key) => {
                    return { key, value: key == 0 ? true : false, text: s };
                  })}
                  value={props.auto_approve_task_requests}
                  onChange={(e, data) =>
                    this.autoApproveSettingSelected(
                      data,
                      VendorAutoApproveSetting.taskRequests
                    )
                  }
                />
              }
              label="Auto-Approve Task Requests?"
              labelPlacement="start"
            />
          )}
          {props.viewType === "dealerships sub-component" &&
            canEditVendor &&
            autoApproveOptions && (
              <FormControlLabel
                className="right-toggle"
                control={
                  <Dropdown
                    style={{ marginLeft: "10px", width: "120px" }}
                    placeholder="Pick an Option"
                    compact
                    selection
                    selectOnBlur={false}
                    options={autoApproveOptions}
                    value={AutoApproveTasks[props.auto_approve_tasks]}
                    onChange={(e, data) =>
                      this.autoApproveSettingSelected(
                        data,
                        VendorAutoApproveSetting.tasks
                      )
                    }
                  />
                }
                label="Bypass Invoicing?"
                labelPlacement="start"
              />
            )}
        </div>
        <Divider horizontal>Primary Contact</Divider>
        {props.store &&
        props.store.primary_contact &&
        props.store.primary_contact.user ? (
          <div className="primary-contact-details">
            {props.viewType === "dealerships sub-component" &&
            store.primary_contact &&
            !store.primary_contact.user.last_login_date &&
            !this.state.inviteResent ? (
              <Button
                color="blue"
                labelPosition="left"
                icon
                className="resend-btn"
                onClick={() =>
                  this.sendUserInvite(
                    store.primary_contact.user,
                    this.props.storeId,
                    false
                  )
                }
              >
                <Icon name="send" />
                {"Resend Invite"}
              </Button>
            ) : (
              this.state.inviteResent && (
                <Button
                  color="grey"
                  labelPosition="left"
                  icon
                  className="resend-btn"
                  disabled
                >
                  <Icon name="clock outline" />
                  {"Invite Sent"}
                </Button>
              )
            )}
            <div className="user-icon">
              {props.store.primary_contact.user.photo_url ? (
                <Image
                  src={
                    props.store.primary_contact.user.photo_url +
                    "?h=120&w=120&facepad=2&faceindex=1&fit=facearea"
                  }
                  loading="lazy"
                />
              ) : (
                <Icon name="user" size="massive" color="grey" />
              )}
            </div>
            <div className="employee-contact-details">
              <List>
                <List.Item className="employee-name">
                  <List.Icon name="user" />
                  <List.Content>
                    <span className="bold">
                      {props.store.primary_contact.user.full_name}
                    </span>
                  </List.Content>
                </List.Item>
                <List.Item className="employee-email">
                  <List.Icon name="mail" />
                  <List.Content>
                    <a
                      href={"mailto:" + props.store.primary_contact.user.email}
                    >
                      {props.store.primary_contact.user.email}
                    </a>
                  </List.Content>
                </List.Item>
                <List.Item className="employee-phone">
                  <List.Icon name="phone" />
                  <List.Content>
                    {formatPhone(props.store.primary_contact.user.phone)}
                  </List.Content>
                </List.Item>
                <List.Item className="employer-ein">
                  <List.Icon name="archive" />
                  <List.Content>
                    <Popup
                      trigger={
                        <a>
                          {props.approved_vendor_detail &&
                            props.approved_vendor_detail.ein_or_ssn}
                        </a>
                      }
                      content="SSN/EIN"
                    />
                  </List.Content>
                </List.Item>
              </List>
            </div>
          </div>
        ) : (
          <Message
            info
            style={{ textAlign: "left" }}
            icon="user circle outline"
            header={props.store && props.store.name}
            content="currently has no primary contact saved."
          />
        )}
        <Divider horizontal>Quick Stats</Divider>
        {props.approved_vendor_detail && (
          <div className="quick-stats-container">
            <Card className="card-1">
              <Card.Content
                header={props.approved_vendor_detail.open_task_count}
              />
              <Card.Content description="Open Tasks" />
            </Card>
            <Card className="card-2">
              <Card.Content
                header={props.approved_vendor_detail.closed_task_count}
              />
              <Card.Content description="Closed Tasks" />
            </Card>
            <Card className="card-3">
              <Card.Content
                header={
                  props.approved_vendor_detail.open_task_count +
                  props.approved_vendor_detail.closed_task_count
                }
              />
              <Card.Content description="Total Tasks" />
            </Card>
          </div>
        )}
      </div>
    );
  }

  selectAutoApprovalSetting(value, setting) {
    let data;

    if (setting === VendorAutoApproveSetting.tasks) {
      data = { auto_approve_tasks: AutoApproveTasks[value] };
    } else if (setting === VendorAutoApproveSetting.taskRequests) {
      data = { auto_approve_task_requests: value };
    }

    restAPI({
      endpointName: "setAutoApproveVendor",
      urlArgs: [this.props.storeId, parseInt(this.props.vendorId, 10)],
      data,
      callback: (err, res) => {
        if (!err) {
          this.props.refetchAll();
        }
      },
    });
  }

  autoApproveSettingSelected(action, setting) {
    let confirmDialog;
    const actions = {
      open: true,
      success: () =>
        this.setState({ confirmDialog: confirmDefault }, () =>
          this.selectAutoApprovalSetting(action.value, setting)
        ),
      failure: () => this.setState({ confirmDialog: confirmDefault }),
    };
    switch (setting) {
      case VendorAutoApproveSetting.tasks:
        confirmDialog = this.tasksConfirmationAA(action, actions);
        break;
      case VendorAutoApproveSetting.taskRequests:
        if (action.value) {
          confirmDialog = {
            title: "Auto-Approve All Task Requests?",
            text:
              "By choosing this setting all task requests will be automatically approved from this Vendor.",
            ...actions,
          };
        } else {
          this.selectAutoApprovalSetting(action.value, setting);
          return;
        }
        break;
      default:
        break;
    }
    this.setState({ confirmDialog });
  }

  tasksConfirmationAA(action, actions) {
    let confirm;
    const selected: any = AutoApproveTasks[action.value];

    switch (selected) {
      case AutoApproveTasks.No:
        confirm = {
          title: "Enable Invoicing?",
          text:
            "Are you sure you want to enable invoicing of tasks for this vendor?",
          ...actions,
        };
        break;
      case AutoApproveTasks.Yes:
        confirm = {
          title: "Bypass Invoicing?",
          text:
            "Are you sure you want to bypass invoicing of all completed tasks for this vendor?",
          ...actions,
        };
        break;
      case AutoApproveTasks.OnlyIfZero:
        confirm = {
          title: "Bypass Invoicing if $0?",
          text:
            "Are you sure you want to bypass invoicing of $0 tasks for this vendor?",
          ...actions,
        };
        break;
      default:
        console.log("Nothing is configured for this action item");
        return;
    }

    return confirm;
  }

  toggleVendorActive(data: any) {
    const active = {
      is_active: data.checked,
    };
    restAPI({
      endpointName: "toggleVendorActive",
      urlArgs: [this.props.storeId, parseInt(this.props.vendorId, 10)],
      data: active,
      callback: (err, res) => {
        if (!err) {
          this.props.refetchAll();
        }
      },
    });
  }
}

enum VendorAutoApproveSetting {
  "tasks" = "tasks",
  "taskRequests" = "taskRequests",
}

const mapStateToProps = (state: any, ownProps) => {
  return {
    // use the clientId passed from the clients tab as storeId for vendors
    storeId: ownProps.clientId ? ownProps.clientId : state.app.admin.storeId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const ContactTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IContactQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.vendorsContact,
    {
      skip: (ownProps: any) =>
        ownProps.viewType === "vendors sub-component" ||
        ownProps.viewType === "ivendors sub-component",
      options: (props: any) => {
        return {
          variables: {
            vendorId: parseInt(props.vendorId, 10),
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, store, approved_vendor_detail, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, error };
        return {
          store,
          approved_vendor_detail,
          refetch,
        };
      },
    }
  ),
  graphql<LMI.IVendorContactQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.vendor.dealerContact,
    {
      skip: (ownProps: any) =>
        ownProps.viewType === "dealerships sub-component",
      options: (props: any) => {
        return {
          variables: {
            vendorId: parseInt(props.vendorId, 10),
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, store, customer_dealer_detail, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, error };
        return {
          store,
          customer_dealer_detail,
          refetch,
        };
      },
    }
  )
)(ContactTabPanelView) as React.ComponentType<any>;

export default ContactTabPanel;
