import "@syncfusion/ej2-react-richtexteditor/styles/material.css";
import * as React from "react";
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  RichTextEditor,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";
import { Button, Divider, Icon } from "semantic-ui-react";

export interface RichTextComponentProps {
  value: string;
  placeholder: string;
  readOnly?: boolean;
  onSave: (val: string) => void;
  onClose: () => void;
}

export class RichTextComponent extends React.Component<
  RichTextComponentProps,
  undefined
> {
  render() {
    const { placeholder, readOnly } = this.props;
    let richTextObj: RichTextEditor = null;
    const toolbarSettings: object = {
      enable: true,
      items: [
        "Bold",
        "Italic",
        "Underline",
        "|",
        "FontSize",
        "FontColor",
        "|",
        "Formats",
        "Alignments",
        "Outdent",
        "Indent",
        "|",
        "ClearFormat",
        "|",
        "Undo",
        "Redo",
      ],
    };

    return (
      <div id="html-editor">
        <RichTextEditorComponent
          ref={(richtexteditor) => {
            richTextObj = richtexteditor;
          }}
          toolbarSettings={toolbarSettings}
          placeholder={placeholder}
          readonly={readOnly}
          value={this.props.value}
          height="236"
        >
          <Inject services={[Toolbar, Image, Link, HtmlEditor]} />
        </RichTextEditorComponent>
        <div style={{ height: "46px" }}>
          <Divider hidden />
          <Button
            compact
            primary
            floated="right"
            onClick={() => {
              this.props.onSave(richTextObj.value);
            }}
          >
            <Icon name="save" />
            Save
          </Button>
          <Button
            type="button"
            compact
            floated="right"
            onClick={() => {
              this.props.onClose();
            }}
          >
            <Icon name="cancel" />
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}

export default RichTextComponent;
