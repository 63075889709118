import { restAPI, restAPIDirect } from "../../../../../../../utils/rest";
import * as FileSaver from "file-saver";
import { gqlQueries } from "gql-imports";
import { Loading } from "client/components/Loading";
import {
  getSortableImage,
  getSortableToolTip,
  SortableTable,
} from "client/pages/admin/components/SortableTable";
import { formatPhone } from "client/utils/functions";
import moment from "moment";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Loader } from "semantic-ui-react";
import { addAlert } from "api/redux/actions";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

import "./LeadsListComponent.css";

@track(
  (props) => {
    return {
      event_type: AnalyticsEventType.NAVIGATION,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }list`,
    };
  },
  { dispatchOnMount: true }
)
export class LeadsListComponentView extends React.Component<
  LMI.ILeadsListProps,
  LMI.ILeadsListState
> {
  constructor(props) {
    super(props);
    this.state = {
      reload: null,
      complete: null,
    };
  }

  render() {
    const props = this.props;

    if (props.hasErrors) console.log("LeadsTabPanel GQL Errors", props.message);
    if (props.loading || !this.props.leads) return <Loading />;
    const leads: LMI.ILeadsTableRow[] =
      props.leads && this.formatLeadData(props.leads, props.viewType);
    return [
      <SortableTable
        key="leadsList"
        tableid="leads-list"
        paneDidMount={this.paneDidMount}
        message={
          this.props.searchFilter
            ? "No results for your search. Try adjusting your search or date range."
            : "No Leads available"
        }
        tableData={this.getTableData(leads, props.viewType)}
      />,
      <span key="reload">
        {this.state.reload && (
          <div className="lazyloader">
            <Loader active />
          </div>
        )}
      </span>,
    ];
  }

  mapLeadData(lead: LMI.ILeadProps) {
    const donotcontactphone =
      this.props.do_not_contact.length &&
      this.props.do_not_contact.find((c) => c.value === lead.customer_phone);
    const donotcontactemail =
      this.props.do_not_contact.length &&
      this.props.do_not_contact.find((c) => c.value === lead.customer_email);
    const currentprice =
      lead.inventory_item.selling_price !== lead.price
        ? {
            component: getSortableToolTip,
            value: lead.inventory_item.selling_price
              ? lead.inventory_item.selling_price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              : "",
            mainicon:
              lead.inventory_item.selling_price > lead.price
                ? "chevron up"
                : "chevron down",
            mainiconcolor:
              lead.inventory_item.selling_price > lead.price ? "blue" : "green",
            tipvalue:
              lead.inventory_item.selling_price > lead.price
                ? "This price has gone up!"
                : "This price has gone down!",
          }
        : lead.inventory_item.selling_price
        ? lead.inventory_item.selling_price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        : "";

    const data: LMI.ILeadsTableRow = {
      thumb: {
        component: getSortableImage,
        value: lead.inventory_item.photo_url,
      },
      inventory_item: {
        year: lead.inventory_item.year,
        make: lead.inventory_item.make,
        model: lead.inventory_item.model,
        vin:
          "VIN: ..." +
          lead.inventory_item.vin.substr(lead.inventory_item.vin.length - 8),
        stock_number: lead.inventory_item.stock_number
          ? "STOCK #: " + lead.inventory_item.stock_number
          : "",
      },
      name: {
        component: getSortableToolTip,
        value: lead.customer_name,
        mainicon:
          donotcontactphone || donotcontactemail ? "alarm mute" : "alarm",
        mainiconcolor: donotcontactphone || donotcontactemail ? "red" : "green",
        tipvalue:
          donotcontactphone || donotcontactemail
            ? "Click to remove this lead from the 'do not contact' list."
            : "Click to add this lead to the 'do not contact' list.",
        callback: () => {
          const updatables: any = [];
          const oleads = this.props.leads.filter((l) => {
            return (
              (l.customer_email && l.customer_email === lead.customer_email) ||
              (l.customer_phone && l.customer_phone === lead.customer_phone)
            );
          });
          oleads.forEach((le) => {
            if (le.customer_phone && !updatables.includes(le.customer_phone)) {
              updatables.push(le.customer_phone);
            }
            if (le.customer_email && !updatables.includes(le.customer_email)) {
              updatables.push(le.customer_email);
            }
          });
          updatables.forEach((le) => {
            const isListed = this.props.do_not_contact.find(
              (c) => c.value === le
            );
            const toggle = isListed ? true : false;
            this.toggleDoNotContact(isListed, le, toggle);
          });
        },
      },
      phone:
        donotcontactphone || donotcontactemail
          ? "XXX-XXX-XXXX"
          : formatPhone(lead.customer_phone),
      email:
        donotcontactphone || donotcontactemail
          ? "XXX@XXX.XXX"
          : lead.customer_email,
      contact: moment(parseInt(lead.lead_created_at, 10)).format("LL"),
      salesperson: lead.salesperson.full_name,
      price: lead.price
        ? lead.price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        : "",
      currentprice,
      age: lead.age_in_days === 0 ? "Today" : lead.age_in_days,
    };
    return data;
  }

  formatLeadData(leads: any[], viewType: string): LMI.ILeadsTableRow[] {
    return leads.map(this.mapLeadData, this);
  }

  getTableData(leads, viewType) {
    const headers: LMI.IHeaderCellData[] = [
      {
        id: "thumb",
        label: "Photo",
        sortable: true,
        collapsing: true,
      },
      {
        id: "inventory_item",
        label: "Inventory Item",
        sortable: true,
        collapsing: true,
      },
      {
        id: "name",
        label: "Lead Name",
        sortable: true,
      },
      {
        id: "phone",
        label: "Lead Phone",
        sortable: true,
        collapsing: true,
      },
      {
        id: "email",
        label: "Lead Email",
        sortable: true,
      },
      {
        id: "contact",
        label: "Date of Contact",
        sortable: true,
        collapsing: true,
      },
      {
        id: "salesperson",
        label: "Salesperson Name",
        sortable: true,
        collapsing: true,
      },
      {
        id: "price",
        label: "Starting Price",
        sortable: true,
        collapsing: true,
      },
      {
        id: "currentprice",
        label: "Current Price",
        sortable: true,
      },
      {
        id: "age",
        label: "Age (in days)",
        sortable: true,
        collapsing: true,
      },
    ];

    const sortableTableData: LMI.ITableData = {
      headers: [],
      body: {
        rows: [],
      },
    };
    return Object.assign({}, sortableTableData, {
      headers: headers.map((th: any) => {
        return Object.assign({}, th);
      }),
      body: {
        rows: leads,
      },
    });
  }

  // Lazy Load - hit the bottom of the window
  isBottom(el) {
    const isdaBottom = el.scrollTop + el.clientHeight >= el.scrollHeight;
    return isdaBottom;
  }

  paneDidMount = (node) => {
    if (node) node.addEventListener("scroll", () => this.reloadtrigger(node));
  };

  reloadtrigger(node: any) {
    if (this.isBottom(node) && !this.state.reload && !this.state.complete) {
      this.setState({ reload: true });
      const prev = this.props.leads.length;
      this.props.reload(this.props.leads.length).then(() => {
        const complete = prev === this.props.leads.length ? true : false;
        this.setState({ complete, reload: false });
      });
    }
  }

  toggleDoNotContact(contid: any, method: any, toggle: boolean) {
    if (!toggle) {
      const formData = { value: method };
      restAPI({
        endpointName: "addDoNotContact",
        urlArgs: [parseInt(this.props.storeId, 10)],
        data: formData,
        callback: (err, res) => {
          if (err)
            this.props.addAlert({
              type: "danger",
              message: err.reason.response.data.message,
              timeout: 3000,
            });
          this.props.refetchDoNotContact();
        },
      });
    } else {
      restAPI({
        endpointName: "removeDoNotContact",
        urlArgs: [parseInt(this.props.storeId, 10), contid.id],
        data: null,
        callback: (err, res) => {
          if (err)
            this.props.addAlert({
              type: "danger",
              message: err.reason.response.data.message,
              timeout: 3000,
            });
          this.props.refetchDoNotContact();
        },
      });
    }
  }

  exportList() {
    this.props.exportCallback(this.props.leads.length);

    // generate the leads spreadsheet
    restAPIDirect({
      fullEndpoint: `${process.env.REACT_APP_REPORTS_URL}/leads-sheet`,
      method: "post",
      data: {
        auth: process.env.REACT_APP_REPORTS_USER,
        leads: this.props.leads,
      },
      callback: (error, result) => {
        if (error)
          this.props.addAlert({
            type: error ? "danger" : "success",
            message: error.message,
            timeout: 3000,
          });
        else {
          // parse and create blob and then save the file
          const data = result.data;
          const fileName = "leads.xlsx";
          const byteArray = new Uint8Array(data.file.data);
          const file = new Blob([byteArray], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          FileSaver.saveAs(file, fileName);
        }
      },
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.searchFilter !== this.props.searchFilter ||
      nextProps.empFilter !== this.props.empFilter
    ) {
      this.props.exportCallback(20);
    }
  }

  componentDidUpdate() {
    if (this.props.exportList && this.props.leads) this.exportList();
  }
}

const mapStateToProps = (state: any) => {
  return {
    topLevelStoreId: state.app.admin.storeId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const LeadsListComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.ILeadsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.leads,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            userId: props.empFilter && props.empFilter.toString(),
            term: props.searchFilter && props.searchFilter,
            limit: props.limit ? props.limit : 20,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, dealer_leads, refetch, fetchMore },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        return {
          leads: dealer_leads as LMI.ILeadProps[],
          refetch,
          reload: (cursor) => {
            return fetchMore({
              variables: {
                limit: 20,
                offset: cursor,
              },
              updateQuery(prev: LMI.ILeadsQueryProps, { fetchMoreResult }) {
                const newLeads: any = fetchMoreResult;
                if (!newLeads.dealer_leads.length) {
                  return prev;
                } else {
                  return Object.assign({}, prev, {
                    dealer_leads: [
                      ...prev.dealer_leads,
                      ...newLeads.dealer_leads,
                    ],
                  });
                }
              },
            });
          },
        };
      },
    }
  ),
  graphql<LMI.IDoNotContactProps, any, any, ClassAttributes<any>>(
    gqlQueries.donotcontact,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, do_not_contact, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          do_not_contact,
          refetchDoNotContact: refetch,
        };
      },
    }
  )
)(LeadsListComponentView) as React.ComponentType<any>;

export default LeadsListComponent;
