import moment from "moment";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { rangeBucketDefaults } from "client/utils/globals";

class InventoryDataService {
	static config;
	static inventory: DataManager;

	get(): LMI.IInventoryGQL[] {
		const loaded = InventoryDataService.inventory;
		const data = loaded ? loaded.executeLocal(new Query()) : null;
		return data as LMI.IInventoryGQL[];
	}

	set(data, config): LMI.IInventoryGQL[] {
		InventoryDataService.config = config;
		InventoryDataService.inventory = new DataManager(this.format(data));
		return this.get();
	}

	updateItem(updateItem) {
		const item = this.formatItem(updateItem);
		InventoryDataService.inventory.update("id", item);
	}

	private format(data) {
		return data.map(i => this.formatItem(i));
	}

	private formatItem = item => {
		const { bucket, eom } = this.formatBucket(item);
		return {
			...item,
			eom,
			bucket,
			days_in_stock: item.days_in_stock > 0 ? item.days_in_stock : 0,
			vehicle_title: `${item.year} ${item.make} ${item.model} ${item.trim ? item.trim : ""}`,
			action: "",
			location: item.latitude ? true : false,
			recon_level_name: this.getReconLevelNameById(item.recon_level_id),
			current_phase_name: this.getStageNameById(item.current_phase)
		};
	};

	getReconLevelNameById(id: number) {
		const { recon_levels } = InventoryDataService.config;
		if (recon_levels && id) {
			const levelIndex = recon_levels.findIndex(i => i.id == id);
			return levelIndex >= 0 ? recon_levels[levelIndex].name : "";
		} else return "";
	}

	getStageNameById(id: number) {
		const { dealer_phases } = InventoryDataService.config;
		const phases = dealer_phases?.phases;
		if (phases && id) {
			const levelIndex = phases.findIndex(i => i.id == id);
			return levelIndex >= 0 ? phases[levelIndex].name : "";
		} else return "";
	}

	private formatBucket = item => {
		// tslint:disable-next-line:one-variable-per-declaration
		let newRanges, usedRanges, certifiedRanges, eom, bucket;
		const age = item.days_in_stock >= 0 ? item.days_in_stock : 0;
		const { store_settings } = InventoryDataService.config;

		if (store_settings && store_settings.length) {
			store_settings.forEach(setting => {
				if (setting.name === "USED_INVENTORY_RANGE_BUCKET") {
					usedRanges = setting.value ? JSON.parse(setting.value) : rangeBucketDefaults.USED_INVENTORY_RANGE_BUCKET;
				} else if (setting.name === "NEW_INVENTORY_RANGE_BUCKET") {
					newRanges = setting.value ? JSON.parse(setting.value) : rangeBucketDefaults.NEW_INVENTORY_RANGE_BUCKET;
				} else if (setting.name === "CERTIFIED_INVENTORY_RANGE_BUCKET") {
					certifiedRanges = setting.value ? JSON.parse(setting.value) : rangeBucketDefaults.CERTIFIED_INVENTORY_RANGE_BUCKET;
				}
			});
		}

		switch (item.condition) {
			case "Used":
				bucket = this.getBucket(age, usedRanges);
				eom = this.getBucket(this.getEOM(age), usedRanges);
				break;
			case "New":
				bucket = this.getBucket(age, newRanges);
				eom = this.getBucket(this.getEOM(age), newRanges);
				break;
			case "Certified":
				bucket = this.getBucket(age, certifiedRanges);
				eom = this.getBucket(this.getEOM(age), certifiedRanges);
				break;
			default:
				bucket = this.getBucket(age, newRanges);
				eom = this.getBucket(this.getEOM(age), newRanges);
				break;
		}

		return { eom, bucket };
	};

	getBucket = (age, ranges) => {
		const buckets = ["grey", "grey", "orange", "red"];
		const bucket = { class: buckets[0], id: 0 };
		if (ranges && ranges.length) {
			ranges.forEach((range, index) => {
				if (age >= range[0]) {
					if (range[1] && range[1] >= age) {
						bucket.class = buckets[index];
						bucket.id = index + 1;
					} else if (!range[1]) {
						bucket.class = buckets[index];
						bucket.id = index + 1;
					}
				}
			});
		}
		return bucket;
	};

	getEOM = age => {
		const remainder = moment().endOf("month");
		const now = moment();
		const diff = remainder.diff(now, "days");
		return age + diff;
	};
}

export const InventoryData = new InventoryDataService();
