import track from "react-tracking";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { enableUABCallback, triggerUABCallback } from "api/redux/actions";

import { hasPermission } from "client/utils/userAccess";
import { flowRight as compose } from "lodash";
import { AnalyticsEventType, Permission } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Dropdown, Header, Icon, List, Message, Segment } from "semantic-ui-react";
import { Loading } from "../../../../../components/Loading";
import { withUAB } from "../../../../../pages/WithUAB";
import { UniversalActionModalComponent } from "../../UniversalActionModalComponent";
import { LookupVendorComponent, VendorsTabsComponent } from "./components";
import { Session } from "client/utils/session";

import "./VendorsTabPanel.css";

@track({ event_type: AnalyticsEventType.NAVIGATION, event_subtype: "vendors" }, { dispatchOnMount: true })
export class VendorsTabPanelView extends React.Component<LMI.IVendorsTPProps, LMI.IVendorsTPState> {
	static activeFilterOptions = ["Active", "Inactive"].map((v, i) => ({
		key: v,
		text: v,
		value: i === 0 ? "true" : "false"
	}));
	static storedVendor = "approvedVendorSelected";

	constructor(props) {
		super(props);

		this.state = {
			vendorId: null,
			stateActive: true,
			approvedVendors: []
		};
	}

	render() {
		const { hasErrors, message, loading, refetch, reload, viewType, store_approved_vendors, toggleUABCallback, universalActionCallback } = this.props;
		const { approvedVendors, stateActive, vendorId } = this.state;

		if (hasErrors) {
			console.log("Vendors Tab GQL Errors", message);
			return null;
		}

		if (loading) return <Loading />;

		const tabsProps = Object.assign({}, this.props, {
			vendorId,
			tracking_path: this.props.tracking.getTrackingData().event_subtype
		});
		const newVendorProps = Object.assign({}, this.props, {
			onSubmit: () => refetch()
		});

		return (
			<div className="vendors-tab-panel panel-content">
				{viewType.includes("sub-component") ? (
					""
				) : (
					<div style={{ textAlign: "right" }}>
						<UniversalActionModalComponent
							toggleUABCallback={toggleUABCallback}
							universalActionCallback={() => {
								refetch();
								universalActionCallback(false);
							}}
							contentComponent={() => this.getModalForm(newVendorProps)}
							headerText="Create/Update Vendor"
						/>
					</div>
				)}
				<div className="vendor-list">
					<Segment className="approved-vendors-container">
						<span className="filter-active">
							Show{" "}
							<Dropdown
								inline
								direction="left"
								options={VendorsTabPanelView.activeFilterOptions}
								value={stateActive !== null ? stateActive.toString() : "null"}
								onChange={(e, data) => {
									reload(data.value === "true" ? true : false).then(() => {
										const vendorId = store_approved_vendors[0] && store_approved_vendors[0].vendor.id.toString();
										this.setState({
											stateActive: data.value === "true" ? true : false,
											vendorId
										});
									});
								}}
							/>
						</span>
						<Header size="tiny">
							<Icon name="wrench" />
							Approved Vendors
						</Header>
						{approvedVendors && approvedVendors.length ? (
							<List selection divided>
								{this.getVendorList(approvedVendors)}
							</List>
						) : (
							<Message
								style={{ textAlign: "left", margin: "20px auto", width: "90%" }}
								icon="warning circle"
								header="Nothing to Show"
								content={"There are no " + (stateActive ? "(Active)" : "(Inactive)") + " approved vendors for this store."}
							/>
						)}
					</Segment>
					{vendorId && vendorId !== null && <VendorsTabsComponent {...tabsProps} />}
				</div>
			</div>
		);
	}

	getVendorList = vendors => {
		return vendors.map((listItem: LMI.IApprovedVendorInterface) => {
			// true if this is the selected item
			const selected = this.state.vendorId === listItem.id.toString();
			return (
				<List.Item
					key={listItem.id}
					className={selected ? "highlight-vendor" : null}
					onClick={() => {
						if (this.state.vendorId !== listItem.id.toString()) {
							localStorage.setItem(VendorsTabPanelView.storedVendor, listItem.id.toString());
							this.setState({ vendorId: listItem.id.toString() });
						}
					}}>
					<List.Content>
						<List.Header>{listItem.name}</List.Header>
						<List.Description>{listItem.services}</List.Description>
					</List.Content>
				</List.Item>
			);
		});
	};

	getModalForm(props) {
		return <LookupVendorComponent {...props} />;
	}

	componentDidMount() {
		// Should we show the UAB to this user?
		if (!hasPermission(this.props.permissions, Permission.ADMIN_ADD_VENDOR, Session.get("isSuperUser"))) this.props.disableUAB();

		// Check for add vendor page to launch Add Vendor Services UAB
		this.checkRouteId(this.props.match);
	}

	checkRouteId(match) {
		if (match) {
			const { params } = match;
			if (params.id && params.id === "addVendor") this.props.universalActionCallback(true);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let approvedVendors = prevState.approvedVendors;
		let vendorId = null;
		if (nextProps.store_approved_vendors && nextProps.store_approved_vendors.length > 0) {
			if (prevState.approvedVendors.length !== nextProps.store_approved_vendors.length) {
				approvedVendors = nextProps.store_approved_vendors.map((sap: LMI.IStoreApprovedVendorsGQL) => {
					const services = sap.store_approved_vendor_services.map(x => x.service.name).join(", ");
					return {
						id: sap.vendor.id,
						name: sap.vendor.name,
						is_active: sap.vendor.is_active,
						services
					};
				});
			}
			const lsValue: number = parseInt(localStorage.getItem(VendorsTabPanelView.storedVendor), 10);
			const found = approvedVendors.find(v => v.id.toString() === `${lsValue}`);
			vendorId = !isNaN(lsValue) && lsValue > -1 && found ? `${lsValue}` : approvedVendors[0].id.toString();
		}
		return { approvedVendors, vendorId };
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		toggleUABCallback: state.app.admin.toggleUABCallback,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		universalActionCallback: (state: boolean) => {
			dispatch(triggerUABCallback(state));
		},
		disableUAB: () => {
			dispatch(enableUABCallback(false));
		}
	};
};

const showUABOn = [{ viewType: "dealerships" }];
const VendorsTabPanelViewUAB: any = withUAB(VendorsTabPanelView, showUABOn);

export const VendorsTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IVendorsApprovedQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.vendorsApproved, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		// TODO: remove store_approved_vendor_services - this is available within store_approved_vendors
		props: ({ data: { error, loading, store_approved_vendors, store_approved_vendor_services, refetch, fetchMore } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				store_approved_vendors,
				store_approved_vendor_services,
				refetch,
				reload: isActive => {
					return fetchMore({
						variables: { isActive },
						updateQuery(prev: LMI.IStoreQueryProps, { fetchMoreResult }: any) {
							return fetchMoreResult;
						}
					});
				}
			};
		}
	})
)(VendorsTabPanelViewUAB) as React.ComponentType<any>;

export default VendorsTabPanel;
