// node_modules
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
// components
import { Form } from "formsy-semantic-ui-react";
import { stringToInt } from "client/utils/functions";

export class RegionsComponentView extends React.Component<LMI.SUAdminRegionsProps, any> {
	render() {
		const regions = this.props.su_organizations && this.props.su_organizations[0].regions;
		const regionOptions = regions && regions.map((region, index) => ({ key: region.id, text: region.name, value: region.id }));
		return (
			<span>
				{regions && regionOptions && regionOptions.length ? (
					<Form.Field>
						<Form.Select search placeholder="Select Region" label="Region" name="organization_region_id" options={regionOptions} />
					</Form.Field>
				) : (
					""
				)}
			</span>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const RegionsComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.super.organization, {
		options: (props: any) => {
			return {
				variables: {
					id: stringToInt(props.id)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, su_organizations, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				su_organizations,
				refetch
			};
		}
	})
)(RegionsComponentView) as React.ComponentType<any>;

export default RegionsComponent;
