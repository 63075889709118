import * as React from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Icon, Popup, Segment, Input } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

@track(
	props => {
		return {
			event_type: AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}share-modal`,
			data: { link: props.link }
		};
	},
	{ dispatchOnMount: true }
)
export class InvoiceModalComponent extends React.Component<LMI.IInvoiceModalProps, undefined> {
	render() {
		const props = this.props;

		return (
			<div id="invoice-modal">
				{props.sharing ? (
					<div className="share-invoice">
						<h3>Copy your invoice url</h3>
						<Popup
							trigger={<Input name="invoice" fluid value={props.link} onFocus={this.handleFocus} />}
							content="Copied!"
							on="focus"
							position="top center"
							inverted
						/>
						<Divider horizontal>Or</Divider>
						<h3>We can email it for you</h3>
						<Segment secondary>
							<Form onSubmit={this.submitForm.bind(this)}>
								<Form.Field>
									<label>Email</label>
									<Form.Input name="email" type="email" required />
									<Button positive content="Send" />
								</Form.Field>
							</Form>
						</Segment>
					</div>
				) : (
					<div className="view-invoice">
						<h2>Your invoice is ready to view!</h2>
						<p>Click the view button to open your invoice in a new window.</p>
						<Divider />
						<div style={{ textAlign: "right" }}>
							<Button as={Link} href={props.link} target="_blank" onClick={() => props.onClose()} color="green" inverted>
								<Icon name="unhide" /> View Invoice
							</Button>
						</div>
					</div>
				)}
			</div>
		);
	}

	handleFocus(event) {
		event.target.select();
		document.execCommand("copy");
	}

	submitForm(formData: any) {
		this.props.tracking.trackEvent({
			event_type: AnalyticsEventType.ACTION,
			event_subtype: `${this.props.tracking_path ? this.props.tracking_path + "." : ""}submitForm`,
			data: { details: formData }
		});
		this.props.onSubmit(formData);
	}
}

export default InvoiceModalComponent;
