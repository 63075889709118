import { useTabCount } from "../hooks";

export const InventoryTab = ({ tab, props }) => {
  const badge = useTabCount(tab, props);
  const label = tab.toUpperCase();
  return (
    <span className={badge ? "badged" : ""}>
      {label}
      {badge ? <span className="tab-badge">{badge}</span> : ""}
    </span>
  );
};
