import { hasPermission } from "client/utils/userAccess";
import { InspectionLineItemStatus, Permission } from "loopmein-shared";
import { Session } from "client/utils/session";

export class InspectionServiceCtrl {
	public confirmStatuses = [InspectionLineItemStatus.Approved, InspectionLineItemStatus.Completed, InspectionLineItemStatus.Declined];
	public formatInspectionTypeItemOption(item: LMI.InspectionItemTypeOption): LMI.InspectionItemTypeOption {
		const format = prop => {
			if (prop) {
				if (prop === "") return null;
				else if (typeof prop === "string") return parseFloat(prop);
				else return prop;
			} else return null;
		};
		item.parts = format(item.parts);
		item.labor = format(item.labor);
		item.hours = format(item.hours);
		return item;
	}

	public getItemsTotals = (items: LMI.IInspectionLineItem[]): any => {
		const totals = { parts: 0, labor: 0, total: 0, hours: 0 };
		const ignoredStatuses = [InspectionLineItemStatus.Declined, InspectionLineItemStatus.UnableToRepair];
		const countItems = items && items.filter(i => !ignoredStatuses.includes(i.inspection_line_item_status_id));
		if (countItems && countItems.length > 0)
			countItems.forEach(i => {
				totals.parts = +totals.parts + +i.parts;
				totals.labor = +totals.labor + +i.labor;
				totals.total = +totals.total + +i.total;
				totals.hours = +totals.hours + +i.hours;
			});
		return totals;
	};

	public sectionHeadToggle(open: boolean, section_id: number) {
		const headers: any = document.querySelectorAll(".items-header-grid");
		if (headers && headers.length > 0)
			headers.forEach(element => {
				if (open) {
					// pull the curent section header to the top
					const zIndex = element.id === section_id.toString() ? 9 : 0;
					element.setAttribute("style", `z-index: ${zIndex};`);
				} else element.removeAttribute("style");
			});
	}

	public getStatusActionItems(item: LMI.IInspectionLineItem, status: InspectionLineItemStatus) {
		switch (status) {
			case InspectionLineItemStatus.Approved:
				return (
					item.inspection_line_item_status_id == InspectionLineItemStatus.NeedsEstimate ||
					item.inspection_line_item_status_id == InspectionLineItemStatus.WaitingOnApproval
				);
			case InspectionLineItemStatus.Declined:
				return item.inspection_line_item_status_id != InspectionLineItemStatus.Completed;
			case InspectionLineItemStatus.Completed:
				return (
					item.inspection_line_item_status_id == InspectionLineItemStatus.Approved || item.inspection_line_item_status_id == InspectionLineItemStatus.Rework
				);
		}
	}

	public friskStatusOptions(options: LMI.IReconInspectionStatus[], can_approve: boolean) {
		const approvalStatuses = [InspectionLineItemStatus.Approved, InspectionLineItemStatus.Declined];
		if (can_approve) return options;
		else return options.filter(i => !approvalStatuses.includes(i.id));
	}

	public getPermits({
		readonly,
		permissions
	}: {
		readonly: boolean;
		permissions: any[];
	}): {
		can_edit: boolean;
		can_approve: boolean;
		can_remove: boolean;
		can_admin: boolean;
	} {
		const frisk = permission => {
			if (readonly) return false;
			else return hasPermission(permissions, permission, Session.get("isSuperUser"));
		};
		return {
			can_edit: frisk(Permission.EDIT_INSPECTION),
			can_approve: frisk(Permission.APPROVE_INSPECTION),
			can_remove: frisk(Permission.REMOVE_INSPECTION),
			can_admin: frisk(Permission.ADMIN_INSPECTIONS)
		};
	}
}

export const InspectionService = new InspectionServiceCtrl();
