import track from "react-tracking";
// node_modules
import { gqlQueries } from "gql-imports";

import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
// components
import { Loading } from "../../../../../components/Loading";
import { DataFilterComponent } from "../../DataFilterComponent";
import { SortableTable } from "../../SortableTable";
import { AnalyticsEventType } from "loopmein-shared";

import "./OrgSettingsTabPanel.css";

@track(
	props => {
		return {
			event_type: AnalyticsEventType.SUBNAV,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}settings.org`
		};
	},
	{ dispatchOnMount: true }
)
export class OrgSettingsTabPanelView extends React.Component<LMI.IOrgSettingsTPProps, LMI.IOrgSettingsTPState> {
	constructor(props) {
		super(props);
		this.state = {
			searchFilter: null
		};
	}

	render() {
		const props = this.props;

		if (props.hasErrors) {
			console.log("OrgSettingsTabPanel Data errors:", props.message);
		}
		if (!props.store_settings || props.loading) {
			return <Loading />;
		}

		let settings: any[] = props.store_settings.length ? this.formatTableData(props.store_settings, props.viewType) : [];

		if (this.state.searchFilter) {
			settings = this.searchFilter(this.state.searchFilter, settings);
		}

		return (
			<div className="org-settings-tab-panel panel-content">
				<DataFilterComponent
					label="Filter Results"
					searchFilter={this.state.searchFilter}
					onChange={(p, { value }) => {
						this.setState({
							searchFilter: value
						});
					}}
				/>
				<SortableTable tableData={this.getTableData(settings, props.viewType)} />
			</div>
		);
	}

	searchFilter(search: string, data: any[]) {
		const srch = search.toLowerCase();
		return data.filter(service => {
			return service.auctionHouse.toLowerCase().includes(srch);
		});
	}

	getTableData(settings, viewType) {
		const tableHeaders: any = [
			{
				id: "name",
				label: "Setting",
				sortable: true
			},
			{
				id: "value",
				label: "Value",
				sortable: true
			}
		];

		const sortableTableData: LMI.ITableData = {
			headers: [].concat(tableHeaders),
			body: {
				rows: []
			}
		};
		return Object.assign({}, sortableTableData, {
			body: {
				rows: settings
			}
		});
	}

	formatTableData(settings: LMI.ISettingGQL[], viewType: string): LMI.IOrgSettingsTableRow[] {
		return settings.map(
			(setting): LMI.IOrgSettingsTableRow => {
				return {
					name: setting.name,
					value: setting.value
				};
			}
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const OrgSettingsTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IOrgSettingsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.settings.store, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_settings, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				store_settings,
				refetch
			};
		}
	})
)(OrgSettingsTabPanelView) as React.ComponentType<any>;

export default OrgSettingsTabPanel;
