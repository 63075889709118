// node_modules
import * as React from "react";
// components
import {
  Button,
  Form,
  Input,
  InputOnChangeData,
  Checkbox,
  CheckboxProps,
  Divider,
} from "semantic-ui-react";

export class SaniItemEditor extends React.Component<
  LMI.ISaniItemEditorProps,
  LMI.ISaniItemEditorState
> {
  newItem: LMI.IStoreSaniItemsGQL = {
    id: 0,
    name: null,
    description: null,
    is_active: true,
  };

  constructor(props) {
    super(props);

    const item = props.item ? props.item : this.newItem;
    this.state = {
      item,
      isNew: !props.item,
    };
  }

  updateItem(field: InputOnChangeData) {
    const item = { ...this.state.item };
    item[field.name] = field.value;
    this.setState({ item });
  }

  toggleEnabled(toggle: CheckboxProps) {
    const { item } = this.state;
    item.is_active = toggle.checked;
    this.setState({ item });
  }

  render() {
    const { item, isNew } = this.state;
    const { name, description, is_active } = item;
    const { onSave, onUpdate } = this.props;

    return (
      <div id="SaniItemEditor">
        <Form>
          <Form.Field>
            <label>Name</label>
            <Input
              name="name"
              value={name ? name : ""}
              onChange={(evt, field) => this.updateItem(field)}
            />
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <Input
              name="description"
              value={description ? description : ""}
              onChange={(evt, field) => this.updateItem(field)}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              toggle
              label="Enabled"
              disabled={isNew}
              checked={is_active}
              onChange={(e, data) => this.toggleEnabled(data)}
            />
          </Form.Field>
          <Divider hidden />
          <Button
            color="green"
            disabled={!name}
            content="Save Item"
            onClick={() => (isNew ? onSave(item) : onUpdate(item))}
          />
        </Form>
      </div>
    );
  }
}

export default SaniItemEditor;
