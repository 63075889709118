import { gqlQueries } from "gql-imports";
import { VehicleDocumentGrid, VehicleDocumentUpload } from "client/components/VehicleDocuments";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import Iframe from "react-iframe";
import Form from "react-jsonschema-form";
import { connect } from "react-redux";
import { addAlert } from "api/redux/actions";
import { Button, Container, Divider, Dropdown, Grid, Icon, Segment, Statistic, Transition } from "semantic-ui-react";
import { formSchemas } from "../../../../../utils/globals";
import { ModalComponent } from "../../../components/ModalComponent";
import { DocumentsCountsComponent } from "./components/DocumentCountsDialog";
import { restAPI } from "../../../../../utils/rest";

import "./DocumentsComponent.css";

export class DocumentsComponentView extends React.Component<any, LMI.VehDocState> {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			panelheight: {
				container: null,
				grid: null,
				preview: null
			},
			addDocument: false,
			editDocument: false,
			viewDocumentStats: false,
			addVehicleTypes: false,
			selectedDocument: null,
			selectedDocumentIndex: null,
			activeFilters: {
				years: null,
				makes: null,
				models: null
			}
		};
	}

	render() {
		const { vehicle_documents } = this.props;
		const { loading } = this.state;

		return (
			<div id="SUDocumentGrid" className="page" style={{ height: this.state.panelheight.container }}>
				<ModalComponent
					headerText="Add Vehicle Type"
					shouldBeOpen={this.state.addVehicleTypes}
					onClose={(evt, data) => {
						this.setState({ addVehicleTypes: false });
					}}
					contentComponent={() => this.getVehicleTypesForm()}
					loading={loading}
					loading_message="Saving Vehicle Type..."
				/>

				<ModalComponent
					headerText="Document Counts"
					className="nominheight"
					shouldBeOpen={this.state.viewDocumentStats}
					onClose={(evt, data) => {
						this.setState({ viewDocumentStats: false });
					}}
					contentComponent={() => <DocumentsCountsComponent />}
					loading={loading}
					loading_message="Loading Document Counts..."
				/>

				<h2>
					<Button
						floated="right"
						className="floating-btn vehicle-types"
						color={"green"}
						onClick={() =>
							this.setState({
								addVehicleTypes: true
							})
						}
					>
						Add Vehicle Type
					</Button>
					<Button
						floated="right"
						className="floating-btn document"
						icon={this.state.addDocument ? true : false}
						circular={this.state.addDocument ? true : false}
						color={this.state.addDocument ? null : "green"}
						onClick={() =>
							this.setState({
								addDocument: this.state.addDocument ? false : true,
								selectedDocument: null,
								selectedDocumentIndex: null,
								editDocument: false
							})
						}
					>
						{this.state.addDocument ? <Icon name="close" /> : "Add Document"}
					</Button>
					Documents
					<Icon link name="info circle" color="blue" onClick={() => this.setState({ viewDocumentStats: true })} />
				</h2>
				<div id="fileupload" style={{ height: "97%" }}>
					<Grid className="grid-container">
						<Grid.Column width={this.state.selectedDocument || this.state.addDocument ? 8 : 16}>
							{this.getFilters()}
							<VehicleDocumentGrid
								{...({
									superuser: true,
									vehicle_documents: {
										documents: vehicle_documents && this.hasActiveFilter() ? vehicle_documents.documents : []
									},
									selectedDocumentIndex: this.state.selectedDocumentIndex,
									gridheight: this.state.panelheight.grid,
									onSelect: ({ selectedDocument, selectedDocumentIndex }) => {
										this.setState({ selectedDocument, selectedDocumentIndex, addDocument: false });
									},
									onFilterSelect: activeFilters => {
										this.setState({ activeFilters });
										this.props.refetch_documents({
											year: activeFilters.years,
											make: activeFilters.makes,
											model: activeFilters.models
										});
									}
								} as LMI.VehDocGridProps)}
							/>
						</Grid.Column>
						<Transition visible={this.state.selectedDocument || this.state.addDocument ? true : false} animation="fade" duration={500}>
							<Grid.Column width={8}>
								<Segment className="documentInfo" style={{ height: this.state.panelheight.preview }}>
									{this.state.selectedDocument && (
										<Container>
											<h3>
												{!this.state.editDocument && (
													<Button
														icon={true}
														floated="right"
														size="small"
														className={"preview-link"}
														onClick={() => this.setState({ selectedDocument: null, selectedDocumentIndex: -1 })}
													>
														<Icon name="close" />
													</Button>
												)}
												<Button
													icon={this.state.editDocument ? true : false}
													floated="right"
													size="small"
													className="preview-link"
													onClick={() => this.setState({ editDocument: this.state.editDocument ? false : true })}
												>
													{this.state.editDocument ? <Icon name="close" /> : "Edit Document"}
												</Button>
												Document Info
											</h3>
											<Divider />
										</Container>
									)}
									{this.renderUploadForm()}
									{this.state.selectedDocument && !this.state.editDocument ? (
										<Container>
											<Grid>
												<Grid.Column width={10}>
													{this.state.selectedDocument.name && (
														<div className="doc-item">
															<span className="label">Name:</span>
															{this.state.selectedDocument.name}
														</div>
													)}
													{this.state.selectedDocument.type && (
														<div className="doc-item">
															<span className="label">DocType:</span>
															{this.state.selectedDocument.type}
														</div>
													)}
													{this.state.selectedDocument.identifier && (
														<div className="doc-item">
															<span className="label">Identifier:</span>
															{this.state.selectedDocument.identifier}
														</div>
													)}
													{this.state.selectedDocument.vin && (
														<div className="doc-item">
															<span className="label">VIN:</span>
															{this.state.selectedDocument.vin}
														</div>
													)}
												</Grid.Column>
												<Grid.Column width={6}>
													<Statistic>
														<Statistic.Value>{this.getDocumentType()}</Statistic.Value>
													</Statistic>
												</Grid.Column>
											</Grid>
											<Divider />
											<Divider />

											<h3>
												Document Preview{" "}
												<a href={this.state.selectedDocument.full_url} target="_blank" className="ui button small preview-link right" rel="noreferrer">
													Open in a New Tab
												</a>
											</h3>
											<Iframe url={this.state.selectedDocument.full_url} height="500px" width="100%" id="documentPreview" position="relative" allowFullScreen />
										</Container>
									) : (
										""
									)}
								</Segment>
							</Grid.Column>
						</Transition>
					</Grid>
				</div>
			</div>
		);
	}

	hasActiveFilter = (): boolean => {
		const { activeFilters } = this.state;
		let hasActiveFilter = false;
		if (activeFilters) {
			for (const filter in activeFilters) {
				if (activeFilters[filter] !== null) {
					hasActiveFilter = true;
					break;
				}
			}
		}
		return hasActiveFilter;
	};

	handleSubmit = data => {
		this.setState({ loading: true });
		restAPI({
			endpointName: "suAddVehicleType",
			urlArgs: null,
			data: data.formData,
			callback: error => {
				let alert;
				if (error) {
					alert = { type: "danger", message: `Error adding vehicle type: ${error}` };
				} else {
					alert = { type: "success", message: `Vehicle type added!` };
				}
				this.sendAlert(alert);
			}
		});
	};

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000,
			onComplete: () => {
				this.setState({
					loading: false
				});
			}
		});
	}

	getVehicleTypesForm = () => {
		const { schema, uiSchema, validate } = formSchemas.vehicle_types;

		return (
			<>
				<Form
					schema={schema}
					showErrorList={false}
					liveValidate={true}
					validate={validate}
					noHtml5Validate={true}
					uiSchema={uiSchema}
					onSubmit={this.handleSubmit}
				>
					<div>
						<button className="ui button green" type="submit">
							Submit
						</button>
					</div>
				</Form>
			</>
		);
	};

	renderUploadForm = () => {
		const { storeId, all_vehicle_document_types } = this.props;
		const { addDocument, editDocument } = this.state;
		const currentMode = addDocument ? 1 : editDocument ? 2 : 0;
		const vehDocUploadProps: LMI.VehDocUploadProps =
			currentMode === 2
				? {
						// edit document props
						superuser: true,
						fileProps: this.state.selectedDocument,
						storeId: this.props.storeId,
						borderless: true,
						canDelete: true,
						all_vehicle_document_types: this.props.all_vehicle_document_types,
						onSubmit: data => {
							this.props.refetch_documents().then(() => {
								if (this.state.selectedDocument) {
									const newDocumentData = this.props.vehicle_documents.documents.find(d => d.id === this.state.selectedDocument.id);
									const selectedDocument = { ...this.state.selectedDocument, ...newDocumentData };
									this.setState({
										selectedDocument,
										editDocument: false
									});
								}
							});
						},
						onDelete: () => {
							this.setState({
								editDocument: false,
								selectedDocument: null
							});
							this.props.refetch_documents();
						}
				  }
				: currentMode === 1
				? {
						// add document props
						superuser: true,
						storeId,
						borderless: true,
						all_vehicle_document_types,
						onSubmit: data => {
							this.props.refetch_documents();
						}
				  }
				: null;
		return currentMode ? <VehicleDocumentUpload {...vehDocUploadProps} /> : "";
	};

	getDocumentType = () => {
		const fileExt = this.state.selectedDocument.full_url.split(".").pop();
		return fileExt && fileExt.includes("com") ? "Misc" : fileExt;
	};

	getFilters = () => {
		const { vehicle_document_filters } = this.props;
		const { activeFilters } = this.state;
		return (
			<Grid className="vehicle-doc-filters">
				{vehicle_document_filters &&
					activeFilters &&
					Object.keys(vehicle_document_filters).map((filter, i) => {
						const options = vehicle_document_filters[filter];
						if (typeof options !== "string") {
							const currentValue = activeFilters[filter];
							return (
								<Grid.Column key={i} width={4} className="filter">
									<Dropdown
										fluid
										search
										selection
										className="selectorius"
										placeholder={`Filter ${filter}`}
										value={currentValue}
										options={options.map((opt, ii) => {
											return { key: ii, value: opt, text: opt };
										})}
										onChange={(e, data) => {
											const filters = activeFilters;
											filters[filter] = data.value;
											this.setState({ activeFilters: filters });
											this.props.refetch_documents({
												year: activeFilters.years,
												make: activeFilters.makes,
												model: activeFilters.models
											});
										}}
									/>
								</Grid.Column>
							);
						}
					})}
			</Grid>
		);
	};

	setpanelheight = () => {
		// the body height minus the header and tabs etc...
		const containerheight = document.body.scrollHeight - 225;
		const gridheight = document.body.scrollHeight - 444;
		const previewheight = document.body.scrollHeight - 307;
		this.setState({
			panelheight: {
				container: `${containerheight.toString()}px`,
				grid: `${gridheight.toString()}px`,
				preview: `${previewheight.toString()}px`
			}
		});
	};

	componentDidMount() {
		this.setpanelheight();
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const DocumentsComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IInventoryDocumentTypesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.vehicleDocumentTypes, {
		options: (props: any) => {
			return {
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, vehicle_document_types, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				all_vehicle_document_types: vehicle_document_types,
				refetch_all_types: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.super.vehicleDocuments, {
		options: (props: any) => {
			return {
				variables: {
					limit: 200,
					offset: 0
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, vehicle_documents_su, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				vehicle_documents: vehicle_documents_su,
				refetch_documents: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.vehicleDocumentFilters, {
		options: (props: any) => {
			return { fetchPolicy: "network-only" };
		},
		props: ({ data: { error, loading, vehicle_document_filters, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				vehicle_document_filters,
				refetchFilters: refetch
			};
		}
	})
)(DocumentsComponentView) as React.ComponentType<any>;

export default DocumentsComponent;
