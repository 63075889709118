// node_modules
import * as React from "react";
import { Loader } from "semantic-ui-react";
import "./Loading.css";

/*
 * A reusable loading component
 */

// export const Loading = () => (
// 	<div className="Loading">
// 		<div className="Loading-bounce Loading-bounce--1" />
// 		<div className="Loading-bounce Loading-bounce--2" />
// 		<div className="Loading-bounce Loading-bounce--3" />
// 	</div>
// );
export const Loading = () => (
	<Loader active size="massive">
		Loading
	</Loader>
);

export default Loading;
