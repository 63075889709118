import { restAPI } from "../../../../../utils/rest";
import { gqlQueries } from "gql-imports";

import { formatPhone } from "client/utils/functions";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import {
  Button,
  Dropdown,
  Grid,
  Icon,
  Image,
  List,
  Segment,
} from "semantic-ui-react";
import { Loading } from "../../../../../components/Loading";
import { AlertPositions } from "../../AlertComponent";
import { EditableFieldDisplay } from "../../EditableFieldDisplay";
import { FileUploadComponent } from "../../FileUploadComponent";
import { ModalComponent } from "../../ModalComponent";
import { addAlert } from "api/redux/actions";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";
import { Session } from "client/utils/session";

import "./ProfileSettingsTabPanel.css";

@track(
  (props) => {
    return {
      event_type: AnalyticsEventType.SUBNAV,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }settings.profile`,
    };
  },
  { dispatchOnMount: true }
)
export class ProfileSettingsTabPanelView extends React.Component<
  LMI.IProfileSettingsTPProps,
  LMI.IProfileSettingsTPState
> {
  constructor(props) {
    super(props);
    this.state = {
      editingImage: false,
      notificationType: "email",
      photo_url: null,
      email: null,
    };
  }

  render() {
    const props = this.props;
    const userImage = {
      mWidth: 200,
    };

    if (props.hasErrors) {
      console.log("ProfileSettingsTabPanel Data errors:", props.message);
    }
    if (!props.user || props.loading) {
      return <Loading />;
    }

    const fileProps = {
      filesToAccept: "image/png, image/jpeg",
      onSubmit: this.onSubmitFileHandler.bind(this),
      onClose: () => {
        this.setState({ editingImage: false });
      },
    };

    return (
      <div className="profile-settings-tab-panel panel-content">
        {this.state.editingImage ? (
          <ModalComponent
            headerText="Upload Image"
            shouldBeOpen={this.state.editingImage}
            onClose={(evt, data) => {
              this.setState({ editingImage: false });
            }}
            className="upload-image-modal"
            contentComponent={() => <FileUploadComponent {...fileProps} />}
          />
        ) : (
          ""
        )}
        <Grid className="tools">
          <Grid.Column width={16} style={{ margin: "5" }}>
            <h3>Contact Details:</h3>

            <div className="column-comp">
              <div className="user-icon">
                {props.user.photo_url ? (
                  <div>
                    <Image
                      src={
                        this.state.photo_url
                          ? this.state.photo_url + "?w=" + userImage.mWidth
                          : props.user.photo_url + "?w=" + userImage.mWidth
                      }
                      loading="lazy"
                    />
                    <Button
                      className="change-image-btn"
                      onClick={() => {
                        this.setState({ editingImage: true });
                      }}
                    >
                      change
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Icon name="user" size="massive" color="grey" />
                    <Button
                      className="change-image-btn"
                      onClick={() => {
                        this.setState({ editingImage: true });
                      }}
                    >
                      change
                    </Button>
                  </div>
                )}
              </div>
              <div className="employee-contact-details">
                <List>
                  <List.Item className="employee-info employee-first-name">
                    <List.Content>
                      <EditableFieldDisplay
                        title="First Name"
                        id="editable-user-first-name-field"
                        startingValue={props.user.first_name}
                        onClick={(data) => {
                          this.updateUser({
                            first_name: data.currentValue,
                          });
                        }}
                        canEdit={true}
                      />
                    </List.Content>
                  </List.Item>
                  <List.Item className="employee-info employee-last-name">
                    <List.Content>
                      <EditableFieldDisplay
                        title="Last Name"
                        id="editable-user-last-name-field"
                        startingValue={props.user.last_name}
                        onClick={(data) => {
                          this.updateUser({
                            last_name: data.currentValue,
                          });
                        }}
                        canEdit={true}
                      />
                    </List.Content>
                  </List.Item>
                  <List.Item className="employee-info employee-email">
                    <List.Content>
                      <EditableFieldDisplay
                        title="Email"
                        id="editable-user-email-field"
                        startingValue={
                          this.state.email
                            ? this.state.email
                            : this.props.user.email
                        }
                        onClick={(data) => {
                          this.setState({ email: data.currentValue });
                          this.updateUser({
                            email: data.currentValue,
                          });
                        }}
                        canEdit={true}
                      />
                    </List.Content>
                  </List.Item>
                  <List.Item className="employee-info employee-phone">
                    <List.Content>
                      <EditableFieldDisplay
                        title="Phone"
                        id="phone"
                        startingValue={formatPhone(props.user.phone)}
                        masked={true}
                        onClick={(data) => {
                          this.updateUser({
                            phone: data.currentValue,
                          });
                        }}
                        canEdit={true}
                      />
                    </List.Content>
                  </List.Item>
                </List>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <div className="profile-settings">
          <Segment id="notification-setting">
            <label>How would you like to receive communications?</label>
            <Dropdown
              className="notification-setting-selector"
              selection
              options={[
                { value: "email", text: "Email" },
                { value: "push", text: "Push Notification" },
                { value: "sms", text: "SMS" },
                { value: "none", text: "None" },
              ]}
              value={this.state.notificationType}
              onChange={(e, data) => {
                this.setState({ notificationType: data.value.toString() });
                this.changeUserSetting({
                  name: "NOTIFICATION_TYPE",
                  value: data.value.toString(),
                });
              }}
            />
          </Segment>
        </div>
      </div>
    );
  }

  componentDidUpdate(
    prevProps: LMI.IProfileSettingsTPProps,
    prevState: LMI.IProfileSettingsTPState
  ) {
    if (prevProps.my_user_settings !== this.props.my_user_settings) {
      const setting = this.getSettingByName(
        "NOTIFICATION_TYPE",
        this.props.my_user_settings
      );
      this.setState({ notificationType: setting ? setting.value : "email" });
    }
  }

  getSettingByName(name: string, settings: LMI.ISettingGQL[]): LMI.ISettingGQL {
    return settings.find((setting: LMI.ISettingGQL) => {
      return setting.name === name;
    });
  }

  onSubmitFileHandler(imageObject) {
    this.updateUser(imageObject);
    this.setState({ editingImage: false });
  }

  updateUser(data: any) {
    restAPI({
      endpointName: "updateUser",
      urlArgs: null,
      data,
      callback: (err, res) => {
        this.props.refetch();
        if (!err) {
          const prop = this.updateUserProp(data, res);
          this.setState({ photo_url: res.data.user.photo_url });
          this.showAlert("success", prop);
        } else {
          const errorMessage: string = this.errorUserProp(data);
          this.setState({ email: this.props.user.email }); // Reset the email
          this.showAlert("danger", errorMessage);
        }
      },
    });
  }

  updateUserProp(data: any, res: any): any {
    const key = Object.keys(data)[0].toString();
    switch (key) {
      case "email":
        return "Email successfully updated.";
      case "file_name":
        return "Photo successfully updated.";
      default:
        break;
    }
  }

  errorUserProp(data: any): string {
    const key = Object.keys(data)[0].toString();
    switch (key) {
      case "email":
        return "An account with email " + data.email + " already exists.";
      case "file_name":
        return "Error updating photo.";
      default:
        break;
    }
  }

  showAlert(type, message) {
    this.props.addAlert({
      type,
      message,
      position: AlertPositions.Bottom,
    });
  }

  changeUserSetting(setting: LMI.ISettingGQL) {
    restAPI({
      endpointName: "changeUserSetting",
      urlArgs: null,
      data: setting,
      callback: (err, res) => {
        if (!err) {
          this.props.refetch();
        }
      },
    });
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    permissions: state.app.admin.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const ProfileSettingsTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IProfileSettingsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.user,
    {
      options: (props: any) => {
        return {
          variables: {
            id: Session.get("userId"),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, user, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        return {
          user,
          refetch,
        };
      },
    }
  ),
  graphql<LMI.IUserSettingsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.settings.user,
    {
      options: () => {
        return {
          variables: {
            name: "NOTIFICATION_TYPE", // Array of strings for multiple settings
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, my_user_settings, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        return {
          my_user_settings,
          refetch,
        };
      },
    }
  )
)(ProfileSettingsTabPanelView) as React.ComponentType<any>;

export default ProfileSettingsTabPanel;
