// node_modules
import * as React from "react";
// components
import { Header, Form } from "semantic-ui-react";
import { StoreSelectorComponent } from "client/pages/admin/components/StoreSelectorComponent";
import "./StoreSelectComponent.css";

export class StoreSelectComponent extends React.Component<
  LMI.SUStoreSelectProps,
  any
> {
  render() {
    const storeSelectProps = {
      selectorId: "searchyStore",
      items: this.props.stores,
      placeholder: "Select a Store",
      onSelection: (selected) => {
        this.props.storeChange(selected);
      },
    };
    return (
      <Form.Field>
        {!this.props.disabled && (
          <label>
            Store <span className="red-text">*</span>
          </label>
        )}
        {!this.props.disabled ? (
          <StoreSelectorComponent {...storeSelectProps} />
        ) : (
          <Header as="h3">
            <span className="normal">At</span>{" "}
            {this.props.selectedStore && this.props.selectedStore.text}
          </Header>
        )}
      </Form.Field>
    );
  }
}

export default StoreSelectComponent;
