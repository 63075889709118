import * as React from "react";
import { ClassAttributes } from "react";
import { withRouter } from "react-router";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { Column, GridComponent } from "@syncfusion/ej2-react-grids";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import moment from "moment";
import RenderDataGrid, { DataGridProps, GridColumnHeaders, GridToolOptions } from "../../../../../../../components/DataGrid/DataGrid";
import { Icon, Loader } from "semantic-ui-react";
import { AnalyticsEventType, Months } from "loopmein-shared";
import { ModalComponent } from "../../../../ModalComponent";
import { CellSelectEventArgs } from "@syncfusion/ej2-grids";
import * as Raven from "raven-js";
import { StageBreakdownNotes } from "./notes";
import track from "react-tracking";

import "./reconLevelDetail.css";

@track(
	props => {
		const { month, phase_id, recon_level_selected } = props;
		return {
			event_type: props.tracking_path ? AnalyticsEventType.SUBNAV : AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}recon-level.detail`,
			data: { month, phase_id, recon_level_selected }
		};
	},
	{ dispatchOnMount: true }
)
export class StageBreakdownReconLevelDetailGridView extends React.Component<
	LMI.IStageBreakdownReconLevelDetailGridProps,
	LMI.IStageBreakdownReconLevelDetailGridState
> {
	grids: GridComponent[] = [];
	customCommentsAttributes: any = { class: "custom-comments" };
	selectableColumns: string[] = ["stock_number", "log_count"];

	constructor(props: LMI.IStageBreakdownDetailGridProps) {
		super(props);
		this.state = {
			modal_open: false,
			modal: null,
			completed: [],
			wip: []
		};
	}

	componentWillUnmount() {
		this.grids.map(grid => {
			grid.clearSelection();
			grid = null;
		});
	}

	setGrid(grid) {
		this.grids.push(grid);
	}

	calculateDaysFromThreshold = threshold => {
		const unit = threshold.text.split(" ")[1];
		const time = threshold.number;
		let ms = 0;

		switch (unit) {
			case "S":
				ms = time * 1000;
				break;
			case "M":
				ms = time * 60 * 1000;
				break;
			case "H":
				ms = time * 60 * 60 * 1000;
				break;
			case "D":
				ms = time * 24 * 60 * 60 * 1000;
				break;
			default:
				break;
		}
		return StageBreakdownReconLevelDetailGridView.calculateElapsedDays(ms);
	};

	static calculateElapsedDays(elapsedTime, precision = 2) {
		let ms = elapsedTime;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = ms / 24;

		const elapsedResult = {
			days: days < 0 ? (Math.floor(Math.abs(days) * 100) * -1) / 100 : days.toFixed(precision).padStart(2, "0"),
			hours: hours < 0 ? (Math.floor(Math.abs(hours) * 100) * -1) / 100 : hours.toFixed(precision).padStart(2, "0"),
			minutes: minutes < 0 ? (Math.floor(Math.abs(minutes) * 100) * -1) / 100 : minutes.toFixed(precision).padStart(2, "0"),
			seconds: seconds < 0 ? (Math.floor(Math.abs(seconds) * 100) * -1) / 100 : seconds.toFixed(precision).padStart(2, "0")
		};
		return elapsedResult;
	}

	static formatData = ({ data, warning, critical }) => {
		return data.map((item, i) => {
			const currentTime = StageBreakdownReconLevelDetailGridView.calculateElapsedDays(item.total_phase_elapsed_ms);
			return {
				stock_number: item.stock_number,
				year: item.year,
				make: item.make,
				model: item.model,
				trim: item.trim,
				start_time: item.phase_start_time ? moment(parseInt(item.phase_start_time, 10)).format("MM/DD/YY hh:mmA") : null,
				created_by_user: item.created_by_user ? item.created_by_user : "System User",
				stop_time: item.phase_stop_time ? moment(parseInt(item.phase_stop_time, 10)).format("MM/DD/YY hh:mmA") : null,
				updated_by_user: item.updated_by_user,
				current_time: currentTime,
				current_unit: currentTime,
				total_elapsed: StageBreakdownReconLevelDetailGridView.calculateElapsedDays(item.total_elapsed_ms, 0),
				log_count: item.log_count,
				inventory_id: item.inventory_id,
				warning,
				critical,
				recon_level_name: item.recon_level_name
			};
		});
	};

	getProductiveTemplate = (props: any) => {
		return `${props.total_elapsed.days > 0 ? props.total_elapsed.days + "d" : ""}${props.total_elapsed.hours > 0 ? " " + props.total_elapsed.hours + "h" : ""}${
			props.total_elapsed.minutes > 0 ? " " + props.total_elapsed.minutes + "m" : ""
		}${props.total_elapsed.seconds > 0 ? " " + props.total_elapsed.seconds + "s" : ""}`;
	};

	createGridColumns(source, data) {
		const defaultProps = {
			textAlign: "center",
			allowFiltering: false,
			allowSorting: false
		};

		const headerData: any = [
			{
				...defaultProps,
				id: "stock_number",
				headerText: "Stock #",
				textAlign: "left",
				cellAttrs: {
					template: (props: any) => {
						return (
							<span className={`stock-number active`} data-column="stock_number">
								{props.stock_number}
							</span>
						);
					}
				},
				valueAccessor: (field: string, data: any, column: Column) => {
					return data.stock_number;
				}
			},
			{
				...defaultProps,
				id: "year",
				headerText: "Year",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "make",
				headerText: "Make",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "model",
				headerText: "Model",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "trim",
				headerText: "Trim",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "start_time",
				headerText: "Date In",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "created_by_user",
				headerText: "By",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "stop_time",
				headerText: "Date Out",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "updated_by_user",
				headerText: "By",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "recon_level_name",
				headerText: "Recon Level",
				textAlign: "left"
			},
			{
				...defaultProps,
				id: "current_time",
				headerText: "Time",
				textAlign: "right",
				width: "50",
				cellAttrs: {
					template: (props: any) => {
						const warning: any = props.warning && this.calculateDaysFromThreshold(props.warning);
						const critical: any = props.critical && this.calculateDaysFromThreshold(props.critical);
						return (
							<span
								style={{
									color:
										critical && parseFloat(critical.days) < parseFloat(props.current_time.days)
											? "red"
											: warning && parseFloat(warning.days) < parseFloat(props.current_time.days)
											? "orange"
											: "black"
								}}
							>
								{props.current_time ? this.calculateElapsedMinimum(props.current_time, true).split(" ")[0] : ""}
							</span>
						);
					}
				},
				valueAccessor: (field: string, data: any, column: Column) => {
					return this.calculateElapsedMinimum(data.current_time, true).split(" ")[0];
				}
			},
			{
				...defaultProps,
				id: "current_unit",
				headerText: "Units",
				textAlign: "left",
				valueAccessor: (field: string, data: any, column: Column) => {
					return this.calculateElapsedMinimum(data.current_time, true).split(" ")[1];
				}
			},
			{
				...defaultProps,
				id: "total_elapsed",
				headerText: "Productive",
				textAlign: "right",
				cellAttrs: {
					template: this.getProductiveTemplate
				},
				valueAccessor: (field: string, data: any, column: Column) => {
					return `${data.total_elapsed.days > 0 ? data.total_elapsed.days + "d" : ""}${
						data.total_elapsed.hours > 0 ? " " + data.total_elapsed.hours + "h" : ""
					}${data.total_elapsed.minutes > 0 ? " " + data.total_elapsed.minutes + "m" : ""}${
						data.total_elapsed.seconds > 0 ? " " + data.total_elapsed.seconds + "s" : ""
					}`;
				}
			},
			{
				...defaultProps,
				id: "log_count",
				headerText: "Notes",
				textAlign: "center",
				width: "35",
				customAttributes: this.customCommentsAttributes,
				cellAttrs: {
					template: (props: any) => {
						return <span data-column="log_count">{props.log_count ? <Icon name="comments" /> : ""}</span>;
					}
				},
				valueAccessor: (field: string, data: any, column: Column) => {
					return data.log_count;
				}
			}
		];

		return GridColumnHeaders(data, headerData, [
			"stock_number",
			"year",
			"make",
			"model",
			"trim",
			"start_time",
			"created_by_user",
			"stop_time",
			"updated_by_user",
			"recon_level_name",
			"current_time",
			"current_unit",
			"total_elapsed",
			"log_count"
		]);
	}

	calculateElapsedMinimum({ days, hours, minutes, seconds }, useFull) {
		// console.log(elapsedTime, days, hours, minutes, seconds);
		if (days > 0) return days + ` D${useFull ? `ay${days > 1 ? "s" : ""}` : ""}`;
		if (hours > 0) return hours + ` H${useFull ? `our${hours > 1 ? "s" : ""}` : ""}`;
		if (minutes > 0) return minutes + ` M${useFull ? `inute${minutes > 1 ? "s" : ""}` : ""}`;
		if (seconds > 0) return `<1 M${useFull ? `inute${minutes > 1 ? "s" : ""}` : ""}`;

		return `0 M${useFull ? "inutes" : ""}`;
	}

	closeModal = () => {
		this.setState({ modal_open: false });
	};

	clearSelection = index => {
		this.grids[index].clearSelection();
	};

	handleSelect = (args: CellSelectEventArgs, index: number): boolean => {
		try {
			if (!args || !args.currentCell || !args.currentCell.firstChild || !(args.currentCell.firstChild as HTMLElement).getAttribute) {
				args.cancel = true;
				this.clearSelection(index);
				return false;
			}

			const column = (args.currentCell.firstChild as HTMLElement).getAttribute("data-column");
			const isSelectable = this.selectableColumns.indexOf(column) >= 0;

			if (!isSelectable || (column === "log_count" && !(args.data as any).log_count)) {
				args.cancel = true;
				this.clearSelection(index);
				return false;
			} else {
				if (column === "stock_number") {
					this.props.history.push(`/admin/dealerships/inventory?id=${(args.data as any).inventory_id}`);
				} else this.setState({ modal_open: true, modal: { inventory_id: (args.data as any).inventory_id } });
			}
		} catch (error) {
			Raven.captureException(error);
			console.error(error);
		}
	};

	render() {
		const { modal_open, completed, modal } = this.state;
		const { loading, warning, critical, month, year, store_id, count, days } = this.props;

		if (loading || !completed) return <Loader className={`loader active`} size="small" />;

		return (
			<div className="stage-breakdown-recon-level-detail-grid">
				{modal_open && (
					<ModalComponent
						headerText="Vehicle Notes"
						size="small"
						shouldBeOpen={modal_open}
						onClose={() => this.closeModal()}
						className="stage-breakdown-notes-modal"
						scrolling={true}
						contentComponent={() => (
							<StageBreakdownNotes
								{...{
									store_id,
									inventory_id: modal.inventory_id,
									tracking_path: this.props.tracking.getTrackingData().event_subtype
								}}
							/>
						)}
					/>
				)}
				<div className="top-grid">
					<div className="grid-head">
						<div className="head-row">
							<div>
								Count: <span className="associated">{count}</span>
							</div>
							<div>
								Avg Days: <span className="associated">{parseFloat(days).toFixed(2)}</span>
							</div>
						</div>
						<div className="key">
							<div className="key-head">Timer Settings</div>
							<div className="key-row warning">
								Warning: <span className="warning">{warning ? warning.text : "Not Set"}</span>
							</div>
							<div className="key-row critical">
								Critical: <span className="critical">{critical ? critical.text : "Not Set"}</span>
							</div>
						</div>
					</div>
					<RenderDataGrid
						{...({
							rowHeight: "25",
							gridLines: "Both",
							total: completed.length,
							data: completed,
							columns: this.createGridColumns("breakdown", completed),
							tools: [GridToolOptions.excelexport, GridToolOptions.sorting, GridToolOptions.title, GridToolOptions.cellselection],
							grid_title: { text: `COMPLETED - ${completed.length} UNITS` },
							textwrap: false,
							setGrid: grid => this.setGrid(grid),
							onSelect: args => this.handleSelect(args, 1),
							noresults: {
								icon: "truck",
								text: `There were no Completed vehicles in ${Months[month]} ${year ? year : ""}`
							}
						} as DataGridProps)}
					/>
				</div>
			</div>
		);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const result = {};
		if (nextProps.completed) {
			const formattedCompletedData = StageBreakdownReconLevelDetailGridView.formatData({
				data: nextProps.completed,
				critical: nextProps.critical,
				warning: nextProps.warning
			});
			if (formattedCompletedData !== prevState.completed) {
				result["completed"] = formattedCompletedData;
			}
		}
		return result;
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const StageBreakdownReconLevelDetailGrid = compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventorySearchPartDuex, {
		options: (props: any) => {
			return {
				variables: {
					store_ids: parseInt(props.store_id, 10),
					phase: parseInt(props.phase_id, 10),
					sold: false
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, inventory_search2, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				inventory_results: inventory_search2.items,
				refetchSearch: refetch
			};
		}
	}),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.stageBreakdownReconLevelDetail, {
		options: (props: any) => {
			return {
				variables: {
					reconLevelId: props.recon_level_id == null ? null : parseInt(props.recon_level_id, 10),
					storeId: parseInt(props.store_id, 10),
					month: parseInt(props.month, 10)
				},
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, stage_breakdown_recon_level_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				completed: stage_breakdown_recon_level_detail.completed,
				refetchDetail: refetch
			};
		}
	})
)(StageBreakdownReconLevelDetailGridView);
