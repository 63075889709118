// node_modules
import * as React from "react";
// components
import {
  Button,
  Form,
  Input,
  InputOnChangeData,
  Divider,
} from "semantic-ui-react";

export class ReconItemEditor extends React.Component<
  LMI.IReconItemEditorProps,
  LMI.IReconItemEditorState
> {
  newItem: LMI.IReconLevelItem = {
    id: 0,
    name: null,
  };

  constructor(props) {
    super(props);

    const item = props.item ? props.item : this.newItem;
    this.state = {
      item,
      isNew: !props.item,
    };
  }

  updateItem(field: InputOnChangeData) {
    const item = { ...this.state.item };
    item[field.name] =
      field.type === "number" ? parseInt(field.value, 10) : field.value;
    this.setState({ item });
  }

  render() {
    const { item, isNew } = this.state;
    const { name } = item;
    const { onSave, onUpdate } = this.props;

    return (
      <div id="SaniItemEditor">
        <Form>
          <Form.Field>
            <label>Level</label>
            <Input
              name="name"
              value={name ? name : ""}
              onChange={(evt, field) => this.updateItem(field)}
            />
          </Form.Field>
          <Form.Field>
            <Input
              name="warning_threshold_days"
              type="number"
              label="Warning Days"
              labelPosition="right"
              value={
                item.warning_threshold_days ? item.warning_threshold_days : ""
              }
              onChange={(e, data) => {
                data["type"] = "number";
                this.updateItem(data);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Input
              name="error_threshold_days"
              type="number"
              label="Critical Days"
              labelPosition="right"
              value={item.error_threshold_days ? item.error_threshold_days : ""}
              onChange={(e, data) => {
                data["type"] = "number";
                this.updateItem(data);
              }}
            />
          </Form.Field>
          <Divider hidden />
          <Button
            color="green"
            disabled={!name}
            content="Save Item"
            onClick={() => (isNew ? onSave(item) : onUpdate(item))}
          />
        </Form>
      </div>
    );
  }
}

export default ReconItemEditor;
