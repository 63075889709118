import { useQuery } from "@apollo/react-hooks";
import { useGlobals } from "api/redux/hooks";
import { gqlQueries } from "gql-imports";
import { HeaderComponent } from "./HeaderComponent";

const useGQL = () => {
	const query = useQuery(gqlQueries.me, {
		skip: !!localStorage.getItem("token") === false,
		fetchPolicy: "network-only"
	});
	if (query.error) console.error(query.error);
	return query;
};

export const Header = (props: LMI.IHeaderProps) => {
	const globals = useGlobals(props) as LMI.IHeaderProps;
	const { loading, error, data, refetch } = useGQL();

	const headerProps = {
		...globals,
		loading,
		hasErrors: error ? true : false,
		me: data?.me,
		refetch
	};

	return <HeaderComponent {...headerProps} />;
};

export default Header;
