import * as React from "react";
import { Dropdown } from "semantic-ui-react";

import "./EditableSelectableComponent.css";

export class EditableSelectableComponent extends React.Component<LMI.IESCProps, LMI.IESCState> {
	constructor(props) {
		super(props);

		this.state = {
			currentValue: props.startingValue,
			selectedValue: props.startingValue
		};
	}

	render() {
		const props = this.props;
		const objVal = this.getObjVal(this.state.currentValue);
		return (
			<Dropdown
				className="fullWidth"
				selection
				options={props.options}
				value={objVal ? objVal.value : null}
				text={objVal ? objVal.text : null}
				disabled={props.disabled}
				onChange={(e, data) => {
					const nextValue = parseInt(data.value.toString(), 10);
					this.setState({
						currentValue: nextValue
					});
					this.props.onClick({
						currentValue: this.getObjVal(nextValue).value
					});
				}}
			/>
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.startingValue !== this.props.startingValue) {
			this.setState({ currentValue: this.props.startingValue });
		}
	}

	getObjVal(currentValue) {
		return this.props.options.length > 0
			? this.props.options.find(objVal => {
					if (objVal.value === currentValue) {
						return true;
					}
					return false;
			  }, this)
			: { value: "", text: "Not Set" };
	}
}
