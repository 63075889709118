import * as React from "react";
import { Label } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { MaskedInputComponent } from "client/pages/admin/components/MaskedInputComponent";
import "./EFDInputComponent.css";

export class EFDInputComponent extends React.Component<LMI.IEFDInputComponent, undefined> {
	render() {
		const props = this.props;
		const errorLabel = <Label color="red" pointing="above" />;
		const action = {
			color: "green",
			icon: "checkmark"
		};
		return (
			<div className={props.labeled ? "component-wrapper" : "component-wrapper nolabel"}>
				<Form id={`${props.id}Form`} noValidate onSubmit={this.onSubmitHandler.bind(this)}>
					{this.props.masked ? (
						<MaskedInputComponent
							{...{
								value: props.value,
								type: props.id,
								required: true,
								action,
								size: "small"
							}}
						/>
					) : (
						<Input
							name={props.id}
							fluid={false}
							defaultValue={props.value}
							required
							size="small"
							action={action}
							validations={this.props.validations}
							validationErrors={this.props.validationErrors}
							errorLabel={errorLabel}
							type={props.type}
						/>
					)}
				</Form>
			</div>
		);
	}

	onSubmitHandler(formData, reset) {
		this.props.onSubmit(formData[this.props.id]);
		reset();
	}
}
