import track from "react-tracking";
import * as React from "react";
import { Icon } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { AnalyticsEventType } from "loopmein-shared";

import "./SearchComponent.css";

@track(
	props => {
		return {
			event_type: props.tracking_path ? AnalyticsEventType.SUBNAV : AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}search-component`
		};
	},
	{ dispatchOnMount: true }
)
export class SearchComponent extends React.Component<LMI.ISearchProps, LMI.ISearchState> {
	constructor(props) {
		super(props);
		this.state = {
			searching: false,
			term: null
		};
	}

	render() {
		return (
			<div id="searchbox" className="searchbox">
				<Form id="search-form" onSubmit={this.handleSubmit.bind(this)}>
					<Form.Field>
						{this.state.searching && <span className="sign">Hit Enter</span>}
						<Input
							id="searchbox-input"
							name="term"
							autoFocus={this.props.autoFocus}
							placeholder={this.props.placeholder}
							value={this.state.term}
							onKeyDown={this.handleSubmitInput}
							onChange={(e, data) => this.handleChange(data)}
						/>
					</Form.Field>
				</Form>
				{this.state.searching && (
					<Icon
						link
						className="exitSearch"
						name="close"
						color="red"
						onClick={() => {
							this.setState({ searching: false, term: null });
							this.props.onEmpty();
						}}
					/>
				)}
			</div>
		);
	}

	handleSubmitInput = e => {
		if (this.props.autoFocus && e.keyCode === 13) {
			const target = e.target;
			setTimeout(() => target.blur(), 300);
		}
	};

	handleChange(data) {
		if (!data.value.length) this.props.onEmpty();
		this.setState({ searching: data.value.length > 2 ? true : false, term: data.value });
	}

	handleSubmit(data: any) {
		this.setState({ searching: false });
		this.props.onSubmit(data);
		this.props.tracking.trackEvent({
			event_type: AnalyticsEventType.ACTION,
			event_subtype: `${this.props.tracking_path ? this.props.tracking_path + "." : ""}search`,
			data: { details: data }
		});
	}
}
