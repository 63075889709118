import { restAPI } from "../../../../../../../../utils/rest";
import * as React from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Menu } from "semantic-ui-react";

import { cloneObject } from "client/utils/functions";
import * as Raven from "raven-js";

import "./LookupVendorSelectedComponent.css";

export class LookupVendorSelectedComponentView extends React.Component<LMI.LookupVendorSelectedComponentViewProps, LMI.LookupVendorSelectedComponentViewState> {
	constructor(props) {
		super(props);

		this.state = {
			vendor_backup: null,
			vendor: null
		};
	}

	UNSAFE_componentWillMount(): void {
		this.getVendor();
	}

	getVendor = () => {
		const { storeId: store_id, store_code } = this.props;
		restAPI({
			endpointName: "getVendorByStoreCode",
			urlArgs: [store_id, store_code],
			data: null,
			callback: (error, result) => {
				const store = result.data.store;
				store["services"] = result.data.services;
				this.setState({ vendor: store });
			}
		});
	};

	approveService = args => {
		const state: any = {};
		if (!this.state.vendor_backup) {
			state.vendor_backup = cloneObject(this.state.vendor);
		}

		state.vendor = cloneObject(this.state.vendor);
		state.vendor.services =
			state.vendor &&
			state.vendor.services &&
			state.vendor.services.map((item, index) => {
				if (args.currentTarget.id === item.id.toString()) {
					item.is_approved = !item.is_approved;
				}
				return item;
			});
		this.setState(state);
		this.props.onVendorModified(true);
	};

	getVendorGrid = vendor => {
		const services = vendor.services;

		return services.length > 0 ? (
			services.map((item, index) => {
				return (
					<Menu.Item className={item.is_approved ? "active" : ""} key={index} id={item.id} onClick={this.approveService}>
						<Checkbox checked={item.is_approved} label={item.service.name} />
						<span className="description">({item.service.description})</span> <span className="float-right">{item.is_approved && "approved"}</span>
					</Menu.Item>
				);
			})
		) : (
			<Menu.Item>This vendor has no approved services.</Menu.Item>
		);
	};

	resetVendor = (mode = "reset") => {
		const vendorBackup = Object.assign({}, this.state.vendor_backup);
		this.setState({ vendor: vendorBackup, vendor_backup: null });
		this.props.onVendorModified(false, mode);
	};

	submitVendor = () => {
		const { vendor } = this.state;
		const { storeId, onSendAlert } = this.props;
		const updated_services = vendor.services.map(service => {
			return {
				id: service.id,
				base_price: parseInt(service.base_price, 10),
				is_approved: service.is_approved
			};
		});

		const vendor_id = vendor.id;
		const store_id = storeId;

		try {
			restAPI({
				endpointName: "add_vendor",
				urlArgs: [store_id, vendor_id],
				data: { is_active: true },
				callback: async (error, result) => {
					await Promise.all(
						updated_services.map(
							async (vendorService): Promise<any> => {
								if (vendorService.is_approved) {
									try {
										restAPI({
											endpointName: "add_vendor_service",
											urlArgs: [store_id],
											data: { vendor_service_id: vendorService.id },
											callback: (error, result) => {
												if (error) {
													const alert = { type: "danger", message: error.reason.response.data.message };
													onSendAlert(alert);
												} else {
													const alert = { type: "success", message: "Vendor successfully approved for this dealership" };
													onSendAlert(alert);
												}
												this.resetVendor("cancel");
											}
										});
									} catch (e) {
										Raven.captureException(e);
										return e.toString();
									}
								}
							}
						)
					);
				}
			});
		} catch (e) {
			Raven.captureException(e);
			return e.toString();
		}
	};

	render() {
		const { vendor } = this.state;
		const { vendor_modified } = this.props;

		return (
			<div className="selected-container">
				<Menu fluid vertical>
					<Menu.Item className="header">
						Select Approved Services for this Vendor
						{vendor_modified && (
							<span className="float-right link" onClick={() => this.resetVendor()}>
								Reset
							</span>
						)}
					</Menu.Item>
					{vendor && this.getVendorGrid(vendor)}
				</Menu>
				<div className="button-group">
					<Button.Group>
						<Button onClick={() => this.resetVendor("cancel")}>Cancel</Button>
						<Button.Or text="OR" />
						<Button positive onClick={this.submitVendor}>
							Approve Vendor
						</Button>
					</Button.Group>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const LookupVendorSelectedComponent = connect(mapStateToProps, mapDispatchToProps)(LookupVendorSelectedComponentView) as React.ComponentType<any>;

export default LookupVendorSelectedComponent;
