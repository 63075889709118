import * as React from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import "./ConfirmDialog.css";

export const confirmDefault: LMI.ConfirmDialogProps = {
	open: false,
	title: null,
	text: null,
	cancelText: "Cancel",
	confirmText: "Confirm",
	extendClass: "",
	success: null,
	failure: null
};

export class ConfirmDialog extends React.Component<LMI.ConfirmDialogProps, any> {
	render() {
		const { open, title, text, confirmText, cancelText, extendClass, success, failure } = this.props;
		return (
			<Modal open={open} className={"confirm-dialog " + extendClass} size="mini">
				<Modal.Content>
					<Header as="h3" content={title} />
					<p>{text}</p>
					<Button color="red" content={cancelText ? cancelText : "Cancel"} onClick={() => failure()} />
					<Button color="green" content={confirmText ? confirmText : "Confirm"} onClick={() => success()} />
				</Modal.Content>
			</Modal>
		);
	}
}

export default ConfirmDialog;
