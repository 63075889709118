import { L10n } from "@syncfusion/ej2-base";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import {
	ActionEventArgs,
	Column,
	ColumnDirective,
	ColumnModel,
	ColumnsDirective,
	CommandColumn,
	DetailRow,
	ExcelExport,
	Filter,
	GridComponent,
	Group,
	Inject,
	Page,
	PdfExport,
	Search,
	SelectionSettingsModel,
	Sort,
	Toolbar
} from "@syncfusion/ej2-react-grids";
import "@syncfusion/ej2-react-grids/styles/material.css";
// node_modules
import * as React from "react";
import { Checkbox, Header, Icon, Message, Popup, Segment } from "semantic-ui-react";
import { FormControlLabel } from "@material-ui/core";
import { CellSelectEventArgs, InfiniteScroll, RowSelectEventArgs } from "@syncfusion/ej2-grids";
import { RelativeDatePipe } from "client/utils/functions";

// import Timer = NodeJS.Timer;

import "./DataGrid.css";

L10n.load({
	"en-US": {
		grid: {
			EmptyRecord: "&nbsp;" // Determines what text is displayed when a grid is loading or has no records
		}
	}
});
/**
 * Dynamically build Grid Columns based on the configuration that is passed in
 * @param data Required - data to populate into data grid
 * @param customOptions Optional - array of DataGridColumnOptions to overide the default
 * @param columnList Optional - can either be boolean or array of data props to show
 * @returns returns a list of column headers DataGridColumnSchema[]
 */
export function GridColumnHeaders(data: any[], customOptions: DataGridColumnOptions[] = null, columnList: string[] = null): DataGridColumnSchema[] {
	const dataSample = data && data[0];
	if (!dataSample) return [];

	let columnHeaders = [];
	for (const property in dataSample) {
		const customOption: any = customOptions ? customOptions.find(option => option.id === property) : null;
		const selectPropValue = (property, optDefault) => (customOption && typeof customOption[property] !== "undefined" ? customOption[property] : optDefault);
		const header = {
			field: customOption ? customOption.id : property,
			headerText: selectPropValue("headerText", property).split("_").join(" ").toUpperCase(),
			width: selectPropValue("width", "70"),
			textAlign: selectPropValue("textAlign", "left"),
			allowFiltering: selectPropValue("allowFiltering", false),
			allowSorting: selectPropValue("allowSorting", true),
			visible: selectPropValue("visible", true),
			filter: selectPropValue("filter", true),
			cellAttrs: selectPropValue("cellAttrs", null),
			valueAccessor: selectPropValue("valueAccessor", null),
			customAttributes: selectPropValue("customAttributes", null),
			sortComparer: selectPropValue("sortComparer", null),
			columns: selectPropValue("columns", null),
			commands: selectPropValue("commands", null)
		} as DataGridColumnSchema;

		if ((columnList && columnList.indexOf(header.field) !== -1) || !columnList) {
			columnHeaders.push(header);
		}
	}

	if (columnList) {
		// sort column headers based on position in columnList array
		columnHeaders = columnHeaders.sort((a, b) => {
			return columnList.indexOf(a.field) - columnList.indexOf(b.field);
		});
	}

	return columnHeaders as DataGridColumnSchema[];
}

/**
 * Wrapper to render a syncfusion data grid
 * @param data Required - data to populate into data grid
 * @param columns Required - Use GridColumnHeaders() function to generate column headers
 * @param tools Required - pass array of GridToolOptions (enum) to setup toolbar
 * @param selectedRowIndex Optional - select an item in the data array
 * @param onSelect Optional - Callback when selection is activated via tools
 * @param onReset Optional - Callback for Clear Filters Button
 * @param noresults Optional - Set no results text and optional action button
 *
 * noresults example:
 * ```
 * noresults: { icon, text, action: { text, callback } }
 * ```
 */
export class RenderDataGrid extends React.Component<DataGridProps, DataGridState> {
	target;
	grid: GridComponent;
	dataSource: Object[];
	scroll: number;
	filterSettings: any = { type: "CheckBox" };
	rowSelectionSettings: SelectionSettingsModel = { cellSelectionMode: "Box", type: "Single", mode: "Row", enableToggle: false };
	cellSelectionSettings: SelectionSettingsModel = { type: "Single", mode: "Cell", enableToggle: false };
	multipleCellSelectionSettings: SelectionSettingsModel = { type: "Multiple", mode: "Cell", enableToggle: false };
	element: any;
	groupModule: any;
	selectTimeout: NodeJS.Timer;
	isInitial: boolean = false;

	toolList: any[] = [
		{ id: "expand", tooltipText: "Expand All Groups", prefixIcon: "e-icons e-expand" },
		{ id: "collapse", tooltipText: "Collapse All Groups", prefixIcon: "e-icons e-collapse" },
		{ id: "search", text: "Search" },
		{ id: "reset", text: "RESET", cssClass: "reset-grid", align: "Right" },
		{ id: "excelexport", text: "Excel", tooltipText: "Excel Export", prefixIcon: "e-icons e-excel", align: "Right" },
		{ id: "pdfexport", text: "PDF", tooltipText: "PDF Export", prefixIcon: "e-icons e-pdf", align: "Right" }
	];

	constructor(props) {
		super(props);

		this.state = {
			noResults: null,
			syncData: null
		};

		this.rowSelected = this.rowSelected.bind(this);
		this.cellSelected = this.cellSelected.bind(this);
	}

	load(e) {
		this.element.onclick = e => {
			this.target = e.target;
		};
	}

	/**
	 * Prepares Grid Component Properties based on passed array of tools selection
	 * @param {GridToolOptions[]} tools Required
	 */
	buildGridComponentProperties = (tools: GridToolOptions[]): GridComponentTools => {
		const { data, selectedRowIndex, textwrap, groupSettings, detailTemplate, filterSettings, rowHeight }: DataGridProps = this.props;
		const services: any[] = [];
		this.dataSource = null;
		this.dataSource = data;
		const properties: any = {
			ref: g => (this.grid = g),
			dataSource: this.dataSource,
			pageSettings: { pageSize: 20 },
			height: "100%",
			selectedRowIndex,
			rowHeight: rowHeight ? rowHeight : "30",
			gridLines: this.props.gridLines ? this.props.gridLines : "Both",
			dataBound: this.dataBound.bind(this),
			allowTextWrap: textwrap ? textwrap : false,
			enableHover: false,
			allowSelection: false,
			allowNotificationToggle: false,
			allowSummary: false,
			allowTitle: false,
			collapseGroupOnLoad: this.props.collapseGroupOnLoad ? this.props.collapseGroupOnLoad : null,
			beforeDataBound: this.onBeforeDataBound,
			onReset: () => this.props.onReset(),
			filterDataSet: str => this.props.filterDataSet(str),
			onGrouping: mode => (this.props.onGrouping ? this.props.onGrouping(mode) : null),
			onUngrouping: mode => (this.props.onUngrouping ? this.props.onUngrouping(mode) : null),
			onToggleNotifications: value => this.props.onToggleNotifications(value),
			rowDataBound: args => (this.props.onRowDataBound ? this.props.onRowDataBound(args) : null),
			queryCellInfo: args => (this.props.queryCellInfo ? this.props.queryCellInfo(args) : null)
		};

		if (tools?.length && data?.length) {
			const hasData = data && data.length > 0;
			properties.toolbar = [];
			properties.toolbarClick = this.onToolbarClick.bind(this);
			services.push(Toolbar);
			services.push(CommandColumn);

			tools.forEach(tool => {
				switch (tool) {
					case GridToolOptions.notification:
						properties.allowNotificationToggle = true;
						break;
					case GridToolOptions.search:
						properties.toolbar.push(this.toolList[2]);
						services.push(Search);
						break;
					case GridToolOptions.grouping:
						properties.allowGrouping = true;
						properties.groupSettings = groupSettings;
						properties.toolbar.push(this.toolList[0]);
						properties.toolbar.push(this.toolList[1]);
						services.push(Group);
						break;
					case GridToolOptions.selection:
						properties.enableHover = true;
						properties.allowSelection = true;
						properties.selectionSettings = this.rowSelectionSettings;
						properties.rowSelected = this.rowSelected;
						if (this.props.cancelSelectionColumns) {
							console.log("Cancel Selection");
							properties.rowSelecting = this.deselecting;
							properties.rowDeselecting = this.deselecting;
						}
						break;
					case GridToolOptions.cellselection:
						properties.enableHover = false;
						properties.allowSelection = true;
						properties.selectionSettings = this.cellSelectionSettings;
						properties.cellSelected = this.cellSelected;
						break;
					case GridToolOptions.multicellselection:
						properties.enableHover = false;
						properties.allowSelection = true;
						properties.selectionSettings = this.multipleCellSelectionSettings;
						properties.cellSelected = this.cellSelected;
						break;
					case GridToolOptions.excelexport:
						// only show export button if items exist
						if (hasData) {
							properties.allowExcelExport = true;
							properties.toolbar.push(this.toolList[4]);
							services.push(ExcelExport);
						}
						break;
					case GridToolOptions.pdfexport:
						// only show export button if items exist
						if (hasData) {
							properties.allowPdfExport = true;
							properties.toolbar.push(this.toolList[5]);
							properties.pdfExportComplete = this.fileExpComplete.bind(this);
							services.push(PdfExport);
						}
						break;
					case GridToolOptions.sorting:
						properties.allowSorting = true;
						services.push(Sort);
						break;
					case GridToolOptions.filter:
						properties.allowFiltering = true;
						properties.filterSettings = filterSettings ? filterSettings : this.filterSettings;
						services.push(Filter);
						break;
					case GridToolOptions.paging:
						properties.allowPaging = true;
						services.push(Page);
						break;
					case GridToolOptions.detailRow:
						properties.detailTemplate = detailTemplate;
						services.push(DetailRow);
						break;
					case GridToolOptions.summary:
						properties.allowSummary = true;
						break;
					case GridToolOptions.title:
						properties.allowTitle = true;
						break;
					case GridToolOptions.infiniteScroll:
						properties.enableInfiniteScrolling = true;
						services.push(InfiniteScroll);
						break;
					case GridToolOptions.resetButton:
						properties.toolbar.push(this.toolList[3]);
						break;
					default:
						break;
				}
			});

			// sort tools here so we don't have to do it in the controller
			if (properties.toolbar.length > 0) {
				const toolOrder = this.toolList.map(i => i.id);
				properties.toolbar = properties.toolbar.sort((a, b) => {
					return toolOrder.indexOf(a.id) - toolOrder.indexOf(b.id);
				});
			}
		}
		return { properties, services } as GridComponentTools;
	};

	/**
	 * Handles dynamic rendering of Grid Column Directive
	 * @param {DataGridColumnSchema} { field, headerText, width, textAlign, allowFiltering, allowSorting, cellType } Required
	 * @param {number} key index of item in the grids data mapping
	 */
	renderGridColumn = (
		{
			field,
			headerText,
			width,
			textAlign,
			allowFiltering,
			allowSorting,
			visible,
			filter,
			cellAttrs,
			valueAccessor,
			sortComparer,
			columns,
			commands
		}: DataGridColumnSchema,
		key: number
	) => {
		const columnProps: any = {
			key,
			field,
			filter,
			headerText,
			width,
			textAlign,
			allowFiltering,
			allowSorting,
			visible,
			sortComparer,
			columns,
			valueAccessor
		};

		if (commands) columnProps.commands = commands;
		if (cellAttrs) {
			if (cellAttrs.template) columnProps.template = cellAttrs.template;
			if (cellAttrs.attributes) columnProps.customAttributes = cellAttrs.attributes;
			if (cellAttrs.clipMode) columnProps.clipMode = cellAttrs.clipMode;
		}
		return <ColumnDirective {...columnProps} />;
	};

	getNotificationToggle = () => {
		return (
			<FormControlLabel
				className="left-toggle summary-item"
				control={
					<Checkbox
						toggle
						checked={this.props.notifications_enabled}
						className="pad"
						onClick={(e, data) => {
							this.props.onToggleNotifications(data.checked);
						}}
					/>
				}
				label="Enable Notifications"
				labelPlacement="end"
			/>
		);
	};

	getSummary = ({ properties }) => {
		return (
			<div id="GridSummary" className="summary-group">
				{properties.allowNotificationToggle && this.getNotificationToggle()}
				<div id="loadedRecordCount" className="summary-item"></div>
				{this.props.tools.includes(GridToolOptions.hasDataSync) && (
					<span id="SyncBtn">
						<Popup
							id="SyncBtnPopup"
							trigger={<Icon name="refresh" size="large" link onClick={() => this.setState({ syncData: new Date() })} />}
							content={this.dataSyncContent()}
							position="left center"
							inverted
						/>
					</span>
				)}
			</div>
		);
	};

	dataSyncContent(): any {
		const { syncData } = this.state;
		return (
			<div>
				<h4 id="SyncDataPopTitle" style={{ margin: 0 }}></h4>
				<small>{syncData ? `Last Updated: ${RelativeDatePipe(syncData)}` : "Click to update"}</small>
			</div>
		);
	}

	getTitle = ({ properties }) => {
		return (
			<div className="grid-title" style={this.props.grid_title.style ? this.props.grid_title.style : {}}>
				{this.props.grid_title.text}
			</div>
		);
	};

	getNoResults() {
		const { noresults, data }: DataGridProps = this.props;
		const { noResults }: DataGridState = this.state;

		if (!data || (data && data.length <= 0 && noResults)) {
			if (noresults) {
				if (noresults.hide) {
					return <span></span>;
				} else {
					return (
						<div id="no-results" className="top in">
							<Segment>
								<Header icon>
									<Icon name={noresults.icon} />
									{noresults && noresults.text ? noresults.text : "Sorry, no results were loaded"}
								</Header>
							</Segment>
						</div>
					);
				}
			} else {
				return (
					<div id="no-results" className="top in">
						<Message>No Results</Message>
					</div>
				);
			}
		}
	}

	render() {
		const { columns, tools }: DataGridProps = this.props;
		const { properties, services }: GridComponentTools = this.buildGridComponentProperties(tools);
		return (
			<div id="GridContainer" className="grid-container searchable-grid">
				{properties.allowTitle ? this.getTitle({ properties }) : ""}
				{properties.allowSummary ? this.getSummary({ properties }) : ""}
				{this.getNoResults()}
				{columns && (
					<GridComponent load={this.load} actionBegin={this.actionHandler} actionComplete={this.actionHandler} {...properties}>
						<ColumnsDirective>
							{columns.map((col, index) => {
								return this.renderGridColumn(col, index);
							})}
						</ColumnsDirective>
						<Inject services={services} />
					</GridComponent>
				)}
			</div>
		);
	}

	onBeforeDataBound = (args): void => {
		// track scrolling so we don't interrupt their position
		if (this.scroll > 0 && this.grid) {
			const gridContent = this.grid.getContent();
			if (gridContent.firstElementChild) gridContent.firstElementChild.scrollTop = this.scroll;
		}
		if (args.count <= 0) {
			this.showNotFound();
			this.grid.hideSpinner();
		} else {
			if (this.state.noResults) this.setState({ noResults: false });
			const fn = () => {
				this.grid.showSpinner();
				this.grid.off("toolbar-refresh", fn);
			};
			this.grid.on("toolbar-refresh", fn);
			this.grid.hideSpinner();
		}
	};

	dataBound() {
		if (this.isInitial && this.props.collapseGroupOnLoad) this.grid.groupModule.collapseAll();
		if (this.props.tools?.indexOf(GridToolOptions.summary) >= 0 && (this.grid.selectedRowIndex === null || this.grid.selectedRowIndex == -1)) {
			const filteredCount = (this.grid?.getFilteredRecords() as any[]).length;
			const gridParent = this.grid?.element?.parentElement.querySelector("#loadedRecordCount") as HTMLElement;
			if (gridParent)
				gridParent.innerText =
					(this.grid?.dataSource as any[]).length !== filteredCount && filteredCount > 0
						? `Filtered: ${filteredCount}`
						: `Records Loaded: ${(this.grid.dataSource as any[]).length}`;
		}

		if (this.grid?.toolbar[GridToolOptions.search]) {
			const proxy = this;
			const searchInput = this.grid?.element?.querySelector("#" + this.grid.element.getAttribute("id") + "_searchbar") as HTMLInputElement;
			if (searchInput) {
				searchInput.addEventListener("keyup", function (e) {
					if (e.key === "Enter") {
						proxy.props.filterDataSet(this.value);
					}
				});
			}
			const searchButton = this.grid?.element?.querySelector("#" + this.grid.element.getAttribute("id") + "_searchbutton") as HTMLInputElement;
			if (searchButton) {
				searchButton.addEventListener("click", e => {
					proxy.props.filterDataSet(searchInput.value);
				});
			}
		}
	}

	async actionHandler(args: ActionEventArgs) {
		/**
		 * grouping actionBegin
		 * grouping actionComplete
		 * ungrouping actionBegin
		 * ungrouping actionComplete
		 */
		console.log("Action Handler:", args);
		if (args.requestType === "grouping" && args.type === "actionBegin") {
			this.props.onGrouping(args.type);
		} else if (args.requestType === "ungrouping" && args.type === "actionBegin") {
			this.props.onUngrouping(args.type);
		} else if (args.requestType === "grouping" && args.type === "actionComplete") {
			setTimeout(() => this.groupModule.collapseAll(), 2000);
		} else if (args.requestType === "searching") {
			// Cancel the built in search event because we're using our own
			args.cancel = true;
		}
	}

	showNotFound() {
		if (!this.state.noResults) {
			this.setState({
				noResults: true
			});
		}
	}

	cellSelected(args: CellSelectEventArgs) {
		const onSelect = this.props.onSelect;
		if (onSelect) this.props.onSelect(args);
	}

	rowSelected(args: RowSelectEventArgs) {
		if (this.grid) {
			/** Get the selected row indexes */
			const selectedrowindex: number[] = this.grid.getSelectedRowIndexes();
			// console.log("selected:", selectedrowindex);
			if (selectedrowindex[0] !== null && selectedrowindex[selectedrowindex.length - 1] >= 0) {
				/** Get the selected records. */
				const selectedrecords: object[] = this.grid.getSelectedRecords();
				this.props.onSelect(selectedrecords[selectedrecords.length - 1]);
			}
		}
	}

	deselecting(e) {
		if (this.target && this.target.classList && this.target.classList.contains("cancel-row-selection")) {
			this.target = null;
			e.cancel = true;
		}
	}

	fileExpComplete = args => {
		args.promise.then(e => {
			const blobURL = URL.createObjectURL(e.blobData);
			window.open(blobURL);
		});
	};

	onToolbarClick(args) {
		switch (args.item.id) {
			case "expand":
				this.grid.groupModule.expandAll();
				if (this.props.detailTemplate) this.grid.detailExpandAll();
				break;
			case "collapse":
				this.grid.groupModule.collapseAll();
				if (this.props.detailTemplate) this.grid.detailCollapseAll();
				break;
			case "all":
				setTimeout(() => this.props.setAllRecords(), 500);
				break;
			case "reset":
				if (this.props.onReset) this.props.onReset();
				if (this.props.data && this.props.data.length > 0) this.grid.clearFiltering();
				break;
			case "pdfexport":
				const { columns, pdfExportOptions }: DataGridProps = this.props;
				const exportProperties: any = {
					// adding a field checker for any field that contains a photo to be skipped
					// until we add support in print for images in the grid
					columns: pdfExportOptions.columns ? pdfExportOptions.columns : columns.filter(c => !c.field.includes("photo")).map(i => i.field),
					pageOrientation: "Landscape",
					theme: {
						header: {
							fromTop: 0,
							height: 130
						},
						record: {
							fontColor: "#000000",
							fontName: "Calibri",
							fontSize: 7,
							bold: false,
							maxWidth: 80
						},
						caption: {
							fontColor: "#4a4a4a",
							fontName: "Calibri",
							fontSize: 8,
							bold: true
						}
					}
				};
				// @ts-ignore
				this.grid.columns[2]["visible"] = false;
				this.grid.pdfExport(exportProperties, null, null, true);
				break;
			case "excelexport":
				this.grid.excelExport(null, null, null, false);
				break;
			default:
				break;
		}
	}

	shouldComponentUpdate(nextProps: DataGridProps, nextState) {
		const hasSelected = nextProps.selectedRowIndex >= 0 && nextProps.selectedRowIndex !== null;
		const force_update = this.props.update_key !== nextProps.update_key || this.state.syncData !== nextState.syncData;

		let shouldI =
			!this.grid ||
			force_update ||
			this.grid.selectedRowIndex < 0 ||
			this.grid.selectedRowIndex === null ||
			this.props.data.length !== nextProps.data.length ||
			this.props.total !== nextProps.total ||
			this.state.noResults !== nextState.noResults ||
			this.props.notifications_enabled !== nextProps.notifications_enabled;

		// lets wait to update if the user is scrolling through the list (when they have datasync)
		if (nextProps.tools.includes(GridToolOptions.hasDataSync)) {
			// check if needs to skip update
			const skip = !force_update && !hasSelected;
			const syncBtn = document.getElementById("SyncBtn") as HTMLElement;
			const icon = syncBtn && (syncBtn.firstChild as HTMLElement);

			if (icon) {
				if (skip) {
					shouldI = false;
					// make the icon grab some attention
					icon.classList.add("loading", "hasSync");
					setTimeout(() => icon.classList.remove("loading"), 1000);
				} else icon.classList.remove("hasSync");
			}
		}

		if (this.grid) {
			// ctrl selected in grid based on props
			if (hasSelected && this.grid.selectedRowIndex !== nextProps.selectedRowIndex) {
				clearTimeout(this.selectTimeout);
				this.selectTimeout = setTimeout(() => this.grid.selectRow(nextProps.selectedRowIndex), 500);
			} else if (!hasSelected && this.grid.selectedRowIndex >= 0) this.grid.clearSelection();
		}

		return shouldI;
	}

	initCustomSpinner() {
		// add custom spinner classes
		const spinner = document.querySelector(".e-spinner-inner") as HTMLElement;
		if (spinner) {
			spinner.classList.add("ui", "large", "active", "loader");

			// hide grid spinner SVG
			// would remove but the component needs it rendered for some reason
			const builtInSpinner = spinner.firstChild as HTMLElement;
			builtInSpinner.style.visibility = "hidden";
		}
	}

	componentDidMount() {
		window.addEventListener("scroll", this.handleScroll, true);
		this.props.setGrid(this.grid);
		this.initCustomSpinner();
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	handleScroll = () => {
		if (this.grid) {
			// if has data sync check for scroll position and update
			const gridContent = this.grid.getContent();
			const scrollPosition = gridContent.firstElementChild.scrollTop;
			this.scroll = scrollPosition;
			if (scrollPosition == 0 && this.props.tools.includes(GridToolOptions.hasDataSync)) this.setState({ syncData: new Date() });
			if (this.props.handleScroll) this.props.handleScroll(this.grid.element.children[4].children[0]);
		}
	};
}

export default RenderDataGrid;

export interface DataGridProps {
	rowHeight?: string;
	toolbar?: [any];
	total?: number;
	grid_title?: {
		text: string;
		style?: any;
	};
	gridLines?: string;
	data: any;
	columns: DataGridColumnSchema[];
	tools: GridToolOptions[];
	selectedRowIndex?: number;
	notifications_enabled?: boolean;
	textwrap?: boolean;
	searchSettings?: any;
	filterSettings?: any;
	onSelect?: (data: any) => void;
	onCellSelect?: (event: any) => void;
	onReset?: () => void;
	handleScroll?: (data: any) => void;
	filterDataSet?: (data: string) => void;
	onToggleNotifications?: (value: boolean) => void;
	onGrouping?: (mode: string) => void;
	onUngrouping?: (mode: string) => void;
	onRowDataBound?: (args: any) => void;
	queryCellInfo?: (args: any) => void;
	setGrid?: (grid: any) => void;
	setAllProps?: () => void;
	setAllRecords?: () => void;
	bindCustomFilters?: () => Node;
	update_key?: string;
	collapseGroupOnLoad?: boolean;
	isInitial?: boolean;
	noresults?: {
		hide?: boolean;
		text?: string;
		icon?: any;
		action?: {
			text: string;
			callback: () => void;
		};
	};
	source_data?: any;
	selectRowOnCellSelection?: boolean;
	cancelSelectionColumns?: boolean;
	detailTemplate?: any;
	groupSettings?: any;
	pdfExportOptions?: {
		columns: any[];
	};
	commands?: () => void;
	dataSource?: any[];
}

interface DataGridState {
	noResults: boolean;
	syncData: any;
}

interface DataGridColumnSchema {
	field: string;
	headerText?: string;
	width?: string;
	textAlign?: string;
	allowFiltering?: boolean;
	allowSorting?: boolean;
	filter: any;
	sortComparer?: (reference: string, comparer: string) => number;
	visible?: boolean;
	cellAttrs?: CellAttrsOptions;
	valueAccessor?: (field: string, data: any, column: Column) => any;
	columns?: ColumnModel[];
	commands?: any;
}

interface DataGridColumnOptions {
	id: string;
	headerText?: string;
	width?: string;
	textAlign?: string;
	allowFiltering?: boolean;
	allowSorting?: boolean;
	visible?: boolean;
	cellAttrs?: CellAttrsOptions;
	valueAccessor?: (field: string, data: any, column: Column) => any;
}

interface CellAttrsOptions {
	template?: any;
	attributes?: any;
	clipMode?: string;
}

interface GridComponentTools {
	properties: any;
	services: any;
}

export enum GridToolOptions {
	search = 0,
	grouping = 1,
	selection = 2,
	pdfexport = 3,
	excelexport = 4,
	sorting = 5,
	filter = 6,
	paging = 7,
	cellselection = 8,
	notification = 9,
	detailRow = 10,
	summary = 11,
	title = 12,
	multicellselection = 13,
	infiniteScroll = 14,
	resetButton = 15,
	hasDataSync = 16
}
