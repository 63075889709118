// node_modules
import * as React from "react";
import { gqlQueries } from "gql-imports";
import { useGlobals } from "api/redux/hooks";
import { useQuery } from "@apollo/client";

// components
import {
  VehicleDocumentGrid,
  VehicleDocumentUpload,
} from "client/components/VehicleDocuments";
import {
  Button,
  Confirm,
  Container,
  Divider,
  Grid,
  Icon,
  Message,
  Segment,
  Statistic,
  Transition,
} from "semantic-ui-react";
import { WithUAB } from "../../../../WithUAB";
import Iframe from "react-iframe";

import "./VehicleDocumentsTabPanel.css";

export class VehDocTabPanelView extends React.Component<any, LMI.VehDocState> {
  constructor(props) {
    super(props);

    this.state = {
      addDocument: false,
      editDocument: false,
      selectedDocument: null,
      selectedDocumentIndex: null,
      confirm_new_tab: false,
      opened_new_tab: false,
      gridHeight: null,
      docInfoHeight: null,
    };
  }

  static getDerivedStateFromProps(nextProps: any, prevState: LMI.VehDocState) {
    const addDocument = nextProps.toggleUABCallback;
    let docInfoHeight = prevState.docInfoHeight;
    let gridHeight = prevState.gridHeight;
    if (!docInfoHeight && !gridHeight) {
      docInfoHeight = (document.body.scrollHeight - 200).toString();
      gridHeight = (document.body.scrollHeight - 334).toString();
    }
    console.log(nextProps.toggleUABCallback);

    if (nextProps.toggleUABCallback)
      return {
        addDocument,
        selectedDocument: null,
        selectedDocumentIndex: null,
        editDocument: false,
      };
    return { addDocument, docInfoHeight, gridHeight };
  }

  render() {
    const { vehicle_documents, triggerUABCallback } = this.props;
    const {
      selectedDocument,
      selectedDocumentIndex,
      editDocument,
      addDocument,
      confirm_new_tab,
      opened_new_tab,
      gridHeight,
      docInfoHeight,
    } = this.state;

    return (
      <div id="StoreDocumentGrid">
        {addDocument && (
          <Button
            floated="right"
            className="floating-btn"
            icon={true}
            circular={true}
            onClick={() => triggerUABCallback(false)}
          >
            <Icon name="close" />
          </Button>
        )}

        <div id="fileupload">
          <Grid className="grid-container">
            <Grid.Column width={selectedDocument || addDocument ? 8 : 16}>
              {vehicle_documents &&
              vehicle_documents.documents &&
              vehicle_documents.documents.length ? (
                <VehicleDocumentGrid
                  {...({
                    superuser: false,
                    vehicle_documents: {
                      documents: vehicle_documents
                        ? vehicle_documents.documents
                        : [],
                    },
                    selectedDocumentIndex,
                    gridheight: `${gridHeight}px`,
                    onSelect: ({ selectedDocument, selectedDocumentIndex }) => {
                      this.setState({
                        selectedDocument,
                        selectedDocumentIndex,
                        addDocument: false,
                        editDocument: false,
                        confirm_new_tab: true,
                        opened_new_tab: false,
                      });
                    },
                  } as LMI.VehDocGridProps)}
                />
              ) : (
                <Message content="There are currently no documents uploaded" />
              )}
            </Grid.Column>
            <Transition
              visible={selectedDocument || addDocument ? true : false}
              animation="fade"
              duration={500}
            >
              <Grid.Column width={8}>
                <Segment
                  className="documentInfo"
                  style={{ height: `${docInfoHeight}px` }}
                >
                  {selectedDocument && (
                    <Container>
                      <h3>
                        {!editDocument && (
                          <Button
                            icon={true}
                            floated="right"
                            size="small"
                            className={"preview-link"}
                            onClick={() =>
                              this.setState({
                                selectedDocument: null,
                                selectedDocumentIndex: -1,
                              })
                            }
                          >
                            <Icon name="close" />
                          </Button>
                        )}
                        <Button
                          icon={editDocument ? true : false}
                          floated="right"
                          size="small"
                          className="preview-link"
                          onClick={() =>
                            this.setState({
                              editDocument: editDocument ? false : true,
                            })
                          }
                        >
                          {editDocument ? (
                            <Icon name="close" />
                          ) : (
                            "Edit Document"
                          )}
                        </Button>
                        Document Info
                      </h3>
                      <Divider />
                    </Container>
                  )}
                  {this.renderUploadForm()}
                  {selectedDocument && !editDocument ? (
                    <Container>
                      <Grid>
                        <Grid.Column width={10}>
                          {selectedDocument.name && (
                            <div className="doc-item">
                              <span className="label">Name:</span>
                              {selectedDocument.name}
                            </div>
                          )}
                          {selectedDocument.type && (
                            <div className="doc-item">
                              <span className="label">DocType:</span>
                              {selectedDocument.type}
                            </div>
                          )}
                          {selectedDocument.identifier && (
                            <div className="doc-item">
                              <span className="label">Identifier:</span>
                              {selectedDocument.identifier}
                            </div>
                          )}
                          {selectedDocument.vin && (
                            <div className="doc-item">
                              <span className="label">VIN:</span>
                              {selectedDocument.vin}
                            </div>
                          )}
                        </Grid.Column>
                        <Grid.Column width={6}>
                          <Statistic>
                            <Statistic.Value>
                              {this.getDocumentType()}
                            </Statistic.Value>
                          </Statistic>
                        </Grid.Column>
                      </Grid>
                      <Divider />
                      <Divider />

                      <h3>
                        Document Preview{" "}
                        <a
                          target="_blank"
                          className="ui button small preview-link right"
                          onClick={this.navigateNewTab.bind(
                            this,
                            selectedDocument.full_url
                              ? selectedDocument.full_url
                              : selectedDocument.external_url,
                            "_blank"
                          )}
                        >
                          Open in a New Tab
                        </a>
                      </h3>
                      {!selectedDocument.full_url &&
                      selectedDocument.external_url ? (
                        <span>
                          <Confirm
                            open={confirm_new_tab}
                            size="tiny"
                            header="Please Confirm"
                            content={`Is it OK to open this document in a new tab?`}
                            cancelButton="No"
                            confirmButton="Yes, Confirm"
                            onCancel={() =>
                              this.setState({
                                confirm_new_tab: false,
                                opened_new_tab: false,
                              })
                            }
                            onConfirm={this.navigateNewTab.bind(
                              this,
                              selectedDocument.external_url,
                              "_blank"
                            )}
                          />
                          {opened_new_tab && (
                            <p>The document was opened in a new tab.</p>
                          )}
                        </span>
                      ) : (
                        <Iframe
                          url={selectedDocument.full_url}
                          height="500px"
                          width="100%"
                          id="documentPreview"
                          position="relative"
                          allowFullScreen
                        />
                      )}
                    </Container>
                  ) : (
                    ""
                  )}
                </Segment>
              </Grid.Column>
            </Transition>
          </Grid>
        </div>
      </div>
    );
  }

  navigateNewTab = (href, newTab) => {
    const a = document.createElement("a");
    a.href = href;
    if (newTab) {
      a.setAttribute("target", "_blank");
    }
    a.click();
    this.setState({ confirm_new_tab: false, opened_new_tab: true });
  };

  renderUploadForm = () => {
    const { storeId, all_vehicle_document_types } = this.props;
    const { addDocument, editDocument } = this.state;
    const currentMode = addDocument ? 1 : editDocument ? 2 : 0;
    const vehDocUploadProps: LMI.VehDocUploadProps =
      currentMode === 2
        ? {
            // edit document props
            superuser: false,
            fileProps: this.state.selectedDocument,
            storeId: this.props.storeId,
            borderless: true,
            canDelete: true,
            all_vehicle_document_types: this.props.all_vehicle_document_types,
            onSubmit: (data) => {
              this.props.refetch_documents().then(() => {
                if (this.state.selectedDocument) {
                  const newDocumentData = this.props.vehicle_documents.documents.find(
                    (d) => d.id === this.state.selectedDocument.id
                  );
                  const selectedDocument = {
                    ...this.state.selectedDocument,
                    ...newDocumentData,
                  };
                  this.setState({
                    selectedDocument,
                    editDocument: false,
                  });
                }
              });
            },
            onDelete: () => {
              this.setState({
                editDocument: false,
                selectedDocument: null,
              });
              this.props.refetch_documents();
            },
          }
        : currentMode === 1
        ? {
            // add document props
            superuser: false,
            storeId,
            borderless: true,
            all_vehicle_document_types,
            onSubmit: (data) => {
              this.props.refetch_documents();
            },
          }
        : null;

    return currentMode ? <VehicleDocumentUpload {...vehDocUploadProps} /> : "";
  };

  getDocumentType = () => {
    const { selectedDocument } = this.state;
    const fileExt =
      selectedDocument.full_url && selectedDocument.full_url.split(".").pop();
    return fileExt && fileExt.includes("com") ? "Misc" : fileExt;
  };
}

const useGQL = (props) => {
  const docTypes = useQuery(gqlQueries.vehicleDocumentTypes, {
    variables: {
      store_id: props.storeId,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  const documents = useQuery(gqlQueries.dealerVehicleDocuments, {
    variables: {
      store_id: props.storeId,
      org_id: props.orgId,
    },
    fetchPolicy: "network-only",
  });

  const loading = docTypes.loading ?? documents.loading;
  const error = docTypes.error ?? documents.error;

  return {
    loading,
    error,
    all_vehicle_document_types: docTypes?.data?.vehicle_document_types,
    vehicle_documents: documents?.data?.dealer_vehicle_documents,
    refetch_all_types: docTypes.refetch,
    refetch_documents: documents.refetch,
  };
};

export const VehDocTabPanel = (props) => {
  const globals = useGlobals(props);
  const {
    loading,
    error,
    all_vehicle_document_types,
    vehicle_documents,
    refetch_all_types,
    refetch_documents,
  } = useGQL(props);

  const showUABOn = [{ viewType: "dealerships" }];

  return (
    <WithUAB showUABOn={showUABOn}>
      <VehDocTabPanelView
        {...{
          ...globals,
          loading,
          hasErrors: !!error,
          all_vehicle_document_types,
          vehicle_documents,
          refetch_all_types,
          refetch_documents,
        }}
      />
    </WithUAB>
  );
};

export default VehDocTabPanel;
