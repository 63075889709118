// node_modules
import { graphql } from "@apollo/react-hoc";
import "@syncfusion/ej2-base/styles/material.css";
import { gqlQueries } from "gql-imports";
import { Loading } from "client/components/Loading";
import { RelativeDatePipe } from "client/utils/functions";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { ClassAttributes } from "react";
import Lightbox from "react-image-lightbox";
import { connect } from "react-redux";
import { Header, Icon, Image, Popup, Table } from "semantic-ui-react";

import "./TasksDetailComponent.css";

export class TaskDetailPanel extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			detail: null
		};
	}

	render() {
		const { onClose, loading, dealer_task_detail, onSelect } = this.props;
		const { inventory_item } = this.props.detail;
		if (loading) return <Loading />;

		const worklogProps = {
			dealer_task_detail,
			inventory_item,
			onSelect
		};

		return [
			<Icon key="close" name="close" size="large" link onClick={() => onClose()} className="task-close" />,
			<div key="details" id="TaskDetail" className="task-detail-panel">
				<WorkLogsList {...worklogProps} />
			</div>
		];
	}
}

export class WorkLogsList extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			openLightbox: null
		};
	}

	render() {
		const { loading, inventory_item: detail, dealer_task_detail } = this.props;
		const { openLightbox } = this.state;

		if (loading) return <Loading />;

		const work_logs = dealer_task_detail && dealer_task_detail.work_logs;
		const noLogs = !work_logs || work_logs.length <= 0;

		return (
			<div id="WorkLogs">
				{openLightbox && <Lightbox mainSrc={openLightbox} onCloseRequest={() => this.setState({ openLightbox: null })} />}
				<Table className="notes-table" celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell colSpan={2}>
								Work Logs for {detail.year_make_model} {detail.stock_number ? `(Stock#: ${detail.stock_number})` : ""}
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body className="logs">
						{noLogs && (
							<Table.Row>
								<Table.Cell className="no-logs">
									<Icon name="comment" color="grey" size="big" />
									<Header as="h4" color="grey" content="No work logs have been created for this task" />
								</Table.Cell>
							</Table.Row>
						)}
						{work_logs &&
							work_logs.map(l => {
								return (
									<Table.Row key={l.id}>
										<Table.Cell>
											<div className="log-item">
												<div className="body">
													<Header as="h4" content={l.comment && l.comment !== "N/A" && l.comment !== "" ? l.comment : l.description} />
													{l.photo_url && (
														<div onClick={() => this.setState({ openLightbox: l.photo_url })}>
															<Image src={`${l.photo_url}?fit=crop&w=400&h=160&auto=compress,enhance`} className="log-img" loading="lazy" />
														</div>
													)}
												</div>
												<div className="author">
													<Popup
														wide
														trigger={
															<em className="auth-label">
																{l.created_by_user.full_name} - {RelativeDatePipe(l.created_at)}
															</em>
														}
													>
														<div className="auth-popup">
															<Image src={l.created_by_user.photo_url} circular size="mini" floated="left" loading="lazy" />
															<strong>{l.created_by_user.full_name}</strong>
															<br />
															<small>{RelativeDatePipe(l.created_at)}</small>
														</div>
													</Popup>
												</div>
											</div>
										</Table.Cell>
									</Table.Row>
								);
							})}
					</Table.Body>
				</Table>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const TaskDetailComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.vendor.tasksDetail, {
		options: (props: any) => {
			return {
				variables: {
					dealer_id: +props.detail.store_id,
					task_id: +props.detail.id
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, dealer_task_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				dealer_task_detail,
				refetch
			};
		}
	})
)(TaskDetailPanel) as React.ComponentType<any>;

export default TaskDetailComponent;
