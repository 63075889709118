import track from "react-tracking";
import moment from "moment";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { AnalyticsEventType, Permission } from "loopmein-shared";
import { gqlQueries } from "gql-imports";
import { Session } from "client/utils/session";
import { withRouter } from "react-router";
import { hasPermission } from "client/utils/userAccess";
import { getSortableIcon } from "../../../../SortableTable/SortableTable";
import { addAlert } from "../../../../../../../../api/redux/actions";
import { restAPI } from "../../../../../../../utils/rest";
import { formatCurrency, handleErrorResponse, stringToInt } from "client/utils/functions";

import { Loading } from "client/components/Loading";
import { Loader } from "semantic-ui-react";
import { InvoiceDialog } from "../../../../InvoiceDialogComponent/InvoiceDialogComponent";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { getSortableLink, SortableTable } from "client/pages/admin/components/SortableTable";
import { InvoiceCommentComponent, InvoiceModalComponent } from "../index";

import "react-datepicker/dist/react-datepicker.css";
import "./InvoicesListComponent.css";

@track(
	props => {
		return {
			event_type: props.tracking_path ? AnalyticsEventType.SUBNAV : AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}list`
		};
	},
	{ dispatchOnMount: true }
)
export class InvoicesListComponentView extends React.Component<LMI.IInvoicesListProps, LMI.IInvoicesListState> {
	canViewInvoice = hasPermission(this.props.permissions, Permission.VIEW_INVOICE, Session.get("isSuperUser"));
	canEditInvoice = hasPermission(this.props.permissions, Permission.EDIT_INVOICE, Session.get("isSuperUser"));

	constructor(props) {
		super(props);
		this.state = {
			commenting: null,
			viewShare: null,
			reload: null,
			complete: null,
			viewInvoiceDialog: null
		};
	}
	static getDerivedStateFromProps(nextProps: LMI.IInvoicesListProps, prevState: LMI.IInvoicesListState) {
		let viewInvoiceDialog = prevState.viewInvoiceDialog;
		if (nextProps.deeplinked_inv) viewInvoiceDialog = nextProps.deeplinked_inv;
		return { viewInvoiceDialog };
	}

	render() {
		const props = this.props;
		if (props.hasErrors) {
			console.log("InvoicesTabPanel GQL Errors", props.message);
			return (
				<div className="invoices-tab-panel panel-content error-message">
					<p>There are no invoices for this client.</p>
				</div>
			);
		}

		if (props.loading) {
			return <Loading />;
		}

		// check for vendor ID and filter if it exists
		const invoices: LMI.IInvoiceTableRow[] = props.invoiceObj && this.formatInvoiceData(props.invoiceObj, props.viewType);
		const modalProps: LMI.IInvoiceModalProps = {
			link: this.state.viewShare && this.state.viewShare.link,
			sharing: this.state.viewShare && this.state.viewShare.sharing,
			onSubmit: (formData: any) => {
				this.emailInvoice(formData, this.state.viewShare.invoiceId);
				this.setState({
					viewShare: null
				});
			},
			onClose: () => this.setState({ viewShare: null }),
			tracking_path: this.props.tracking.getTrackingData().event_subtype
		};

		const commentProps: LMI.IInvoiceCommentProps = {
			invoice: this.state.commenting,
			onSubmit: (formData: any, id: number) => {
				this.saveComment(formData, id);
				this.setState({
					commenting: null
				});
			},
			onClose: () => this.setState({ commenting: null })
		};

		return (
			<div className={`invoices-list ${this.props.viewType.includes("sub-component") ? "sub" : ""}`}>
				{this.state.viewInvoiceDialog ? (
					<InvoiceDialog
						{...{
							refetchInvoices: this.props.refetch,
							permissions: this.props.permissions,
							viewType: this.props.viewType,
							storeId: this.props.storeId,
							invoice: this.state.viewInvoiceDialog,
							onClose: () => {
								this.props.history.replace(this.props.history.location.pathname);
								this.setState({ viewInvoiceDialog: null });
							},
							tracking_path: this.props.tracking.getTrackingData().event_subtype
						}}
					/>
				) : (
					""
				)}
				{this.state.viewShare && (
					<ModalComponent
						headerText={this.state.viewShare.sharing ? "Invoice Sharing" : "Your browser is set to block pop-ups!"}
						shouldBeOpen={this.state.viewShare ? true : false}
						onClose={(evt, data) => {
							this.setState({ viewShare: null });
						}}
						size="small"
						className="region-details"
						contentComponent={() => <InvoiceModalComponent {...modalProps} />}
					/>
				)}
				{this.state.commenting && (
					<ModalComponent
						headerText={"Comment for Invoice #" + this.state.commenting.invoice_number}
						shouldBeOpen={this.state.commenting ? true : false}
						onClose={(evt, data) => {
							this.setState({ commenting: null });
						}}
						size="small"
						className="comment-details"
						contentComponent={() => <InvoiceCommentComponent {...commentProps} />}
					/>
				)}
				<SortableTable
					paneDidMount={this.paneDidMount}
					message={
						this.props.searchFilter
							? "No results for your search. Try adjusting your search or date range."
							: this.props.vendorName
							? this.props.vendorName + " currently has no invoices."
							: "No Invoices available"
					}
					tableData={this.getTableData(invoices, props.viewType)}
				/>
				{this.state.reload && (
					<div className="lazyloader">
						<Loader active />
					</div>
				)}
			</div>
		);
	}

	searchFilter(search: string, data: any[]) {
		const srch = search.toLowerCase();
		return data.filter(service => {
			return (
				service.invoiceNumber.value.toLowerCase().includes(srch) ||
				service.approvedBy.toLowerCase().includes(srch) ||
				service.amount.toString().toLowerCase().includes(srch)
			);
		});
	}

	mapInvoiceData(invoice: LMI.IDealerInvoicesGQL) {
		const providerStoreName: string = invoice.provider_store ? invoice.provider_store.name : undefined;
		const customerStoreName: string = invoice.customer_store ? invoice.customer_store.name : undefined;
		const data: LMI.IInvoiceTableRow = {
			invoiceNumber: {
				component: getSortableLink,
				value: invoice.invoice_number,
				addClass: "actionable",
				callback: () => {
					return this.getInvoicePDF(invoice.invoice_id, false);
				}
			},
			vendorOrStoreName: providerStoreName || customerStoreName,
			dateCreated: invoice.created_at ? moment(invoice.created_at.full).format("LL") : null,
			dateApproved: invoice.approved_at ? moment(parseInt(invoice.approved_at, 10)).format("LL") : null,
			approvedBy: invoice.approved_by_user ? invoice.approved_by_user.full_name : "",
			amount: formatCurrency(invoice.total, 2),
			poNumber: invoice.po_number,
			is_paid: {
				component: getSortableIcon,
				name: invoice.is_paid ? "check" : null,
				color: "green"
			},
			is_received: {
				component: getSortableIcon,
				name: invoice.is_received ? "check" : null,
				color: "green"
			},
			actions: {
				component: getSortableLink,
				value: `View${invoice.is_paid || !this.canEditInvoice ? "" : "/Edit"}`,
				addClass: this.canViewInvoice ? "actionable" : "hidden",
				callback: () => {
					if (this.canViewInvoice) this.setState({ viewInvoiceDialog: invoice });
				},
				value2: "Share",
				addClass2: "actionable",
				callback2: () => this.getInvoicePDF(invoice.invoice_id, true)
			}
		};
		// comments for those with permission
		if (hasPermission(this.props.permissions, Permission.EDIT_INVOICE_COMMENT, Session.get("isSuperUser"))) {
			data.invoiceNumber.imageIconAction = "/images/commentbubble.png";
			data.invoiceNumber.imageIconActionPopupText = invoice.comment ? invoice.comment : "Add a Comment";
			data.invoiceNumber.imageIconStyle = {
				margin: 0,
				width: "22px",
				clear: "left",
				cursor: "pointer",
				opacity: "0.6"
			};
			data.invoiceNumber.imageIconCallback = () => {
				this.setState({
					commenting: invoice
				});
			};
		}
		if (this.props.viewType.includes("sub-component")) {
			delete data.vendorOrStoreName;
		}
		return data;
	}

	formatInvoiceData(invoicesWithTotal: LMI.IDealerInvoicesWithTotalsGQL, viewType: string): LMI.IInvoiceTableRow[] {
		return invoicesWithTotal.invoices.map(this.mapInvoiceData, this);
	}

	getTableData(invoices, viewType) {
		const headers: LMI.IHeaderCellData[] = [
			{
				id: "invoiceNumber",
				label: "Invoice #",
				sortable: true
			},
			{
				id: "vendorOrStoreName",
				label: this.props.viewType.includes("vendors") ? "Client" : "Vendor",
				sortable: true
			},
			{
				id: "dateCreated",
				label: "Date Created",
				sortable: true
			},
			{
				id: "dateApproved",
				label: "Date Approved",
				sortable: true
			},
			{
				id: "approvedBy",
				label: "Approved By",
				sortable: true
			},
			{
				id: "amount",
				label: "Amount",
				sortable: true
			},
			{
				id: "poNumber",
				label: "PO Number",
				sortable: true,
				collapsing: true
			},
			{
				id: "is_paid",
				label: "Paid",
				sortable: false,
				collapsing: true
			},
			{
				id: "is_received",
				label: "Received",
				sortable: false,
				collapsing: true
			},
			{
				id: "actions",
				label: "Actions",
				sortable: false,
				collapsing: true
			}
		];

		const sortableTableData: LMI.ITableData = {
			headers: [],
			body: {
				rows: []
			}
		};

		return Object.assign({}, sortableTableData, {
			headers: headers
				.filter((th: any) => {
					if (th.id === "vendorOrStoreName" && viewType.includes("sub-component")) {
						return false;
					}
					return true;
				})
				.map((th: any) => {
					let data;
					if (th.id === "vendorOrStoreName") {
						data = Object.assign({}, th, {
							vendorOrStoreName: viewType.includes("dealerships") ? "Vendor" : "Store"
						});
					} else {
						data = Object.assign({}, th);
					}
					return data;
				}),
			body: {
				rows: invoices
			}
		});
	}

	getInvoicePDF(invoice_id: string, sharing: boolean) {
		return new Promise((resolve, reject) => {
			invoice_id = invoice_id.toString();
			restAPI({
				endpointName: "get_invoice_pdf",
				urlArgs: [invoice_id],
				data: null,
				callback: (error, result) => {
					if (error) {
						console.log("There was an error fetching the invoice", error);
						reject(error);
					} else {
						if (sharing) {
							this.setState({
								viewShare: {
									sharing: true,
									link: result.data.url,
									invoiceId: invoice_id
								}
							});
						} else {
							const pdfwindow = window.open(result.data.url, "_blank");
							if (!pdfwindow || pdfwindow.closed || typeof pdfwindow.closed === "undefined") {
								this.setState({
									viewShare: {
										sharing: false,
										link: result.data.url,
										invoiceId: invoice_id
									}
								});
							}
						}
						resolve(null);
					}
				}
			});
		});
	}

	getStoreId() {
		// the store identifier is inconsistent depending on which view type is
		// calling the component
		return this.props.viewType === "vendors sub-component" ? parseInt(this.props.vendorId, 10) : parseInt(this.props.storeId, 10);
	}

	emailInvoice(formData: any, invoiceId: string) {
		const storeId = this.getStoreId();
		const data = {
			email: formData.email
		};
		restAPI({
			endpointName: "share_invoice",
			urlArgs: [storeId, invoiceId],
			data,
			callback: (err, res) => {
				this.props.addAlert({
					type: !err ? "success" : "danger",
					message: !err ? res.content.message : handleErrorResponse(err),
					timeout: 3000
				});
			}
		});
	}

	saveComment(data: any, invoice_id: number) {
		const store_id = this.getStoreId();
		restAPI({
			endpointName: "updateVendorInvoice",
			urlArgs: [store_id, invoice_id],
			data,
			callback: (err, res) => {
				this.props.addAlert({
					type: !err ? "success" : "danger",
					message: !err ? res.data.message : handleErrorResponse(err),
					timeout: 3000
				});
				this.props.refetch();
			}
		});
	}

	// Lazy Load - hit the bottom of the window
	isBottom(el) {
		const isdaBottom = el.scrollTop + el.clientHeight >= el.scrollHeight;
		return isdaBottom;
	}

	paneDidMount = node => {
		if (node) {
			node.addEventListener("scroll", () => this.reloadtrigger(node));
			this.props.getInvoiceTotals({
				grandTotal: this.props.grandTotal,
				invoiceCount: this.props.invoiceCount
			});
		}
	};

	reloadtrigger(node: any) {
		if (this.isBottom(node) && !this.state.reload && !this.state.complete) {
			this.setState({ reload: true });
			const offset = this.props.invoiceObj.invoices.length;
			this.props.reload(offset).then(() => {
				const complete = offset === this.props.invoiceObj.invoices.length ? true : false;
				this.setState({ complete, reload: false });
			});
		}
	}
}

const mapStateToProps = (state: any, ownProps) => {
	return {
		topLevelStoreId: state.app.admin.storeId,
		// use the clientId passed from the clients tab as storeId for vendors
		storeId: ownProps.viewType === "vendors sub-component" && ownProps.clientId ? ownProps.clientId : state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const InvoicesListComponent = compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IDealershipsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.allInvoicesWithTotals, {
		skip: (ownProps: any) => {
			return ownProps.viewType.includes("vendors") || !ownProps.storeId;
		},
		options: (props: any) => {
			return {
				variables: {
					storeId: stringToInt(props.storeId),
					vendorId: stringToInt(props.vendorId),
					start_date: props.startDate && props.startDate,
					end_date: props.endDate && props.endDate,
					term: props.searchFilter && props.searchFilter,
					limit: 20
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, dealer_invoices_with_totals, dealer_approvals, refetch, fetchMore, networkStatus } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			// combine the dealers needs approval and dealers approved invoices and sort them by created date
			// https://github.com/JointSoft/loopmein-web/issues/1319
			const sortInvoices = (a, b) => b - a;
			const invoices = [...dealer_invoices_with_totals.invoices, ...dealer_approvals].sort((a, b) => {
				if (a && a.created_at && b && b.created_at) return sortInvoices(new Date(a.created_at.full), new Date(b.created_at.full));
			});

			return {
				invoiceObj: { ...dealer_invoices_with_totals, ...{ invoices } },
				grandTotal: dealer_invoices_with_totals.grand_total_cost,
				invoiceCount: dealer_invoices_with_totals.total_count,
				refetch,
				networkStatus,
				reload: offset => {
					return fetchMore({
						variables: { offset },
						updateQuery(prev: LMI.IDealershipsQueryProps, { fetchMoreResult }) {
							const newInvoices: any = fetchMoreResult;
							if (newInvoices.dealer_invoices_with_totals.invoices.length <= 0) return prev;
							const dealer_invoices_with_totals = prev.dealer_invoices_with_totals;
							const invoices = [...dealer_invoices_with_totals.invoices, ...newInvoices.dealer_invoices_with_totals.invoices];
							const returnables = {
								...dealer_invoices_with_totals,
								...{ invoices }
							};
							return {
								dealer_invoices_with_totals: returnables,
								dealer_approvals: [...prev.dealer_approvals, ...fetchMoreResult.dealer_approvals]
							};
						}
					});
				}
			};
		}
	}),
	graphql<LMI.IVendorInvoicesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.vendor.invoices, {
		skip: (ownProps: any) => {
			return ownProps.viewType.includes("dealerships") || !ownProps.storeId;
		},
		options: (props: any) => {
			return {
				variables: {
					storeId: props.viewType === "vendors sub-component" ? parseInt(props.vendorId, 10) : parseInt(props.storeId, 10),
					customerId: props.viewType === "vendors sub-component" ? parseInt(props.storeId, 10) : null,
					start_date: props.startDate && props.startDate,
					end_date: props.endDate && props.endDate,
					term: props.searchFilter && props.searchFilter,
					limit: 20
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, vendor_invoices, refetch, fetchMore, networkStatus } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				invoiceObj: { invoices: vendor_invoices },
				refetch,
				networkStatus,
				reload: cursor => {
					return fetchMore({
						variables: {
							offset: cursor
						},
						updateQuery(prev: LMI.IVendorInvoicesQueryProps, { fetchMoreResult }) {
							const newTasks: any = fetchMoreResult;
							if (!newTasks.vendor_invoices.length) {
								return prev;
							} else {
								return Object.assign({}, prev, {
									vendor_invoices: [...prev.vendor_invoices, ...newTasks.vendor_invoices]
								});
							}
						}
					});
				}
			};
		}
	}),
	graphql<LMI.IDealerInvoiceGQLProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.invoiceDetail, {
		skip: (ownProps: any) => (window.location.search && ownProps.viewType.includes("dealerships") ? false : true),
		options: (props: any) => {
			const params = new URLSearchParams(window.location.search);
			const invoiceGuid = params.get("id");
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					invoiceGuid
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, dealer_invoice_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				deeplinked_inv: dealer_invoice_detail,
				refetch
			};
		}
	}),
	graphql<LMI.IVendorInvoiceGQLProps, any, any, ClassAttributes<any>>(gqlQueries.vendor.invoiceDetail, {
		skip: (ownProps: any) => (window.location.search && ownProps.viewType.includes("vendors") ? false : true),
		options: (props: any) => {
			const params = new URLSearchParams(window.location.search);
			const invoiceGuid = params.get("id");
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					invoiceGuid
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, vendor_invoice_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				deeplinked_inv: vendor_invoice_detail,
				refetch
			};
		}
	})
)(InvoicesListComponentView) as React.ComponentType<any>;

export default InvoicesListComponent;
