import moment from "moment";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { restAPI } from "client/utils/rest";
import { RelativeDatePipe } from "client/utils/functions";
import { hasPermission } from "client/utils/userAccess";
import { Permission } from "loopmein-shared";

import { Button, Icon, Image, Input, Popup, Reveal } from "semantic-ui-react";
import { MapsDialog } from "client/components/MapDialog";
import { confirmDefault, ConfirmDialog } from "client/components/ConfirmDialog";
import { Session } from "client/utils/session";

import "./Tools.css";
interface DetailToolsState {
	show_lot_location: any;
	has_location_set: boolean;
	open_sani_add: boolean;
	sani_input: string;
	open_sani_history: boolean;
	confirm: any;
}

export class DetailToolsView extends React.Component<LMI.InventoryDetailProps, DetailToolsState> {
	popupProps: any = {
		position: "bottom center",
		hideOnScroll: true
	};
	constructor(props: LMI.InventoryDetailProps) {
		super(props);
		const has_location_set = props.detail && props.detail.longitude && props.detail.latitude ? true : false;
		let show_lot_location = false;
		if (window.location.search && has_location_set) {
			const params = new URLSearchParams(window.location.search);
			if (params.get("openMap")) show_lot_location = true;
		}
		this.state = {
			show_lot_location,
			has_location_set,
			open_sani_add: false,
			sani_input: "",
			open_sani_history: false,
			confirm: confirmDefault
		};
	}

	saniTool = () => {
		const { detail, permissions } = this.props;
		const { open_sani_add, sani_input } = this.state;
		const canCreateSanitization = hasPermission(permissions, Permission.ADD_SANITIZATION, false);
		const saniInProgress = detail.sanitization_in_progress;
		const popupText = saniInProgress ? "Sanitization in Progress" : "Vehicle Sanitization";
		let popupProps = this.popupProps;
		if (!saniInProgress && open_sani_add) {
			popupProps = {
				...popupProps,
				...{
					open: true,
					hoverable: true,
					onClose: () => this.setState({ open_sani_add: false })
				}
			};
		}

		return (
			<Popup
				key="SaniTool"
				{...popupProps}
				trigger={
					<div id="VehicleSanitizationTool" className={`tool-icon green${saniInProgress ? " active" : ""}`}>
						<Reveal animated="fade">
							<Reveal.Content visible>
								<Image
									src={`/images/spraybottle_${saniInProgress ? "blue" : "gray"}.svg`}
									className="sani-icon"
									onClick={() => {
										if (canCreateSanitization && !saniInProgress) this.setState({ open_sani_add: true });
									}}
								/>
							</Reveal.Content>
							<Reveal.Content hidden>
								<Image src="/images/spraybottle_blue.svg" className="sani-icon" />
							</Reveal.Content>
						</Reveal>
					</div>
				}>
				{canCreateSanitization && open_sani_add ? (
					<div className="sani-form">
						<h4 style={{ textAlign: "center", marginBottom: 0 }}>Request Sanitization</h4>
						<Input placeholder="Lead Name (optional)" onChange={event => this.setState({ sani_input: event.currentTarget.value })} value={sani_input} />
						<Button content="Submit Request" color="green" size="small" onClick={() => this.submitSaniRequest()} disabled={saniInProgress} />
					</div>
				) : (
					<div>
						<h5 style={{ margin: 0 }}>{popupText}</h5>
						<small>
							{detail.last_sanitized_at && (
								<span>
									Last Sanitization: <strong>{RelativeDatePipe(detail.last_sanitized_at, false, true)}</strong>
								</span>
							)}
							{canCreateSanitization && !saniInProgress ? <div>Click to Request</div> : ""}
						</small>
					</div>
				)}
			</Popup>
		);
	};

	recallTool = () => {
		const { detail, permissions } = this.props;
		const recall_detail = detail.recalled_at ? detail : null;
		const canAddRecall = hasPermission(permissions, Permission.ADD_RECALL, Session.get("isSuperUser"));
		const canRemoveRecall = hasPermission(permissions, Permission.REMOVE_RECALL, Session.get("isSuperUser"));

		return (
			<Popup
				key="RecallTool"
				{...this.popupProps}
				trigger={
					<div
						id="VehicleRecallTool"
						className={`tool-icon red${recall_detail ? " active" : ""}`}
						onClick={() => {
							if ((recall_detail && canRemoveRecall) || (!recall_detail && canAddRecall)) this.toggleRecalled();
						}}>
						<Reveal animated="fade">
							<Reveal.Content visible>
								<Image src={`/images/recall_icon${recall_detail ? "" : "_gray"}.svg`} className="recall-icon" />
							</Reveal.Content>
							<Reveal.Content hidden>
								<Image src="/images/recall_icon.svg" className="recall-icon" />
							</Reveal.Content>
						</Reveal>
					</div>
				}>
				{!recall_detail ? (
					<span>
						<h5 style={{ margin: 0 }}>Vehicle Recall</h5>
						{canAddRecall ? <small>Click to Request</small> : <small>None currently added</small>}
					</span>
				) : (
					<span>
						<h5 style={{ margin: 0 }}>{"Recalled by " + recall_detail?.recalled_by_user?.full_name}</h5>
						<small>on {moment(parseFloat(recall_detail.recalled_at)).format("llll")}</small>
						<br />
						{canRemoveRecall && <small>(Click to Remove)</small>}
					</span>
				)}
			</Popup>
		);
	};

	flagTool = () => {
		const { detail } = this.props;
		const { flagged_at, flagged_by_user } = detail;
		const hasFlag = flagged_at && flagged_by_user;

		return (
			<Popup
				key="FlagTool"
				{...this.popupProps}
				trigger={
					<div id="VehicleFlaggedTool" className={`tool-icon yellow${hasFlag ? " active" : ""}`} onClick={() => this.toggleFlagged(hasFlag)}>
						<Reveal animated="fade">
							<Reveal.Content visible>
								<Icon name="flag" className={`flag-icon ${hasFlag ? " has-flag" : ""}`} style={{ background: "white" }} />
							</Reveal.Content>
							<Reveal.Content hidden>
								<Icon name="flag" className={`flag-icon has-flag`} />
							</Reveal.Content>
						</Reveal>
					</div>
				}>
				{!hasFlag ? (
					<span>
						<h5 style={{ margin: 0 }}>Vehicle Flagging</h5>
						<small>Click to flag out</small>
					</span>
				) : (
					<span>
						<h5 style={{ margin: 0 }}>{"Flagged by " + flagged_by_user.full_name}</h5>
						<small>on {moment(parseFloat(flagged_at)).format("llll")}</small>
					</span>
				)}
			</Popup>
		);
	};

	render() {
		const { year, make, model, latitude, longitude } = this.props.detail;
		const { has_location_set, show_lot_location, confirm } = this.state;
		const hasLocationSet = latitude && longitude;
		const location = hasLocationSet ? { latitude, longitude } : null;

		return [
			<ConfirmDialog key="confirm" {...confirm} />,
			<MapsDialog
				key="MapDialog"
				{...{
					location,
					actions: [],
					open: show_lot_location,
					title: `Lot Location - ${year} ${make} ${model}`,
					onClose: () =>
						this.setState({ show_lot_location: false }, () => {
							if (window.location.search) {
								const params = new URLSearchParams(window.location.search);
								if (params.get("openMap")) this.props.history.replace(this.props.history.location.pathname);
							}
						})
				}}
			/>,
			<span key="DetailTools" id="DetailToolPanel">
				<Popup
					key="LocationTool"
					{...this.popupProps}
					content={!has_location_set ? "No Location Found" : "Click to see lot location"}
					trigger={
						<div
							id="VehicleLocationTool"
							className={`tool-icon blue${has_location_set ? " active" : ""}`}
							onClick={() => this.setState({ show_lot_location: has_location_set })}>
							<Image src={`/images/lot_icon_${!has_location_set ? "gray" : "blue"}.svg`} className="lot-map-pin" />
						</div>
					}
				/>
				{this.saniTool()}
				{this.recallTool()}
				{this.flagTool()}
			</span>
		];
	}

	submitSaniRequest = () => {
		const { detail, storeId, refetchDetail } = this.props;
		const { sani_input } = this.state;
		restAPI({
			endpointName: "createSanitizationRequest",
			urlArgs: [storeId],
			data: { inventory_items: [{ id: detail.id, lead_name: sani_input }] },
			callback: (error, result) => {
				refetchDetail();
				this.setState({ sani_input: "", open_sani_add: false }, () => {
					this.sendAlert({
						type: error ? "danger" : "success",
						message: error ? error.message : result.data.message
					});
				});
			}
		});
	};

	toggleRecalled() {
		const { storeId, detail, refetchDetail } = this.props;
		this.setState({
			confirm: {
				open: true,
				title: `Please Confirm`,
				text: `Are you sure you want to ${detail.recalled_at ? "remove the" : "add a"} recall on this vehicle?`,
				success: () => {
					restAPI({
						endpointName: detail.recalled_at ? "inv_remove_recall" : "inv_add_recall",
						urlArgs: [storeId, detail.id],
						data: null,
						callback: (error, result) => {
							refetchDetail();
							this.setState({ confirm: confirmDefault }, () => {
								this.sendAlert({
									type: error ? "danger" : "success",
									message: error ? error.message : result.data.message
								});
							});
						}
					});
				},
				failure: () => this.setState({ confirm: confirmDefault })
			}
		});
	}

	toggleFlagged(hasFlag) {
		const { storeId, detail, refetchDetail } = this.props;
		const text =
			hasFlag && localStorage.getItem("userId") !== detail.flagged_by
				? `Are you sure you want to flag ${detail.flagged_by_user.full_name} off this vehicle?`
				: `Are you sure you want flag ${hasFlag ? "off" : "on"} this vehicle?`;

		this.setState({
			confirm: {
				open: true,
				title: `Please Confirm`,
				text,
				success: () => {
					restAPI({
						endpointName: hasFlag ? "inv_flag_in" : "inv_flag_out",
						urlArgs: [storeId, detail.id],
						data: null,
						callback: (error, result) => {
							refetchDetail();
							this.setState({ confirm: confirmDefault }, () => {
								this.sendAlert({
									type: error ? "danger" : "success",
									message: error ? error.message : result.data.message
								});
							});
						}
					});
				},
				failure: () => this.setState({ confirm: confirmDefault })
			}
		});
	}

	sendAlert({ type, message }) {
		const { setUpdateKey, addAlert } = this.props;
		setTimeout(() => {
			setUpdateKey(new Date());
			addAlert({
				type,
				message,
				timeout: 3000
			});
			if (type === "danger") console.error(`Error: ${message}`);
		}, 400);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const DetailTools = compose(connect(mapStateToProps, mapDispatchToProps))(DetailToolsView) as React.ComponentType<any>;

export default DetailTools;
