import { DateRangePickerComponent, PresetDirective, PresetsDirective, RangeEventArgs } from "@syncfusion/ej2-react-calendars";
import { SearchComponent } from "client/pages/admin/components/SearchComponent";

import { formatCurrency } from "client/utils/functions";
// node_modules
import * as React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Grid } from "semantic-ui-react";
import { InvoicesListComponent } from "./components";
import moment from "moment";
import track from "react-tracking";
import { AnalyticsEventType } from "loopmein-shared";

import "./InvoicesTabPanel.css";

@track(
	props => {
		return {
			event_type: props.tracking_path ? AnalyticsEventType.SUBNAV : AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}invoices`
		};
	},
	{ dispatchOnMount: true }
)
export class InvoicesTabPanel extends React.Component<LMI.IInvoicesTPProps, LMI.IInvoicesTPState> {
	public today = moment(new Date()).toDate();
	public oneWeek = moment(new Date())
		.subtract(7, "days")
		.toDate();
	public oneMonth = moment(new Date())
		.subtract(1, "month")
		.toDate();
	public threeMonths = moment(new Date())
		.subtract(3, "months")
		.toDate();
	public sixMonths = moment(new Date())
		.subtract(6, "months")
		.toDate();
	public oneYear = moment(new Date())
		.subtract(1, "year")
		.toDate();

	constructor(props) {
		super(props);
		this.state = {
			startDate: null,
			endDate: null,
			searchFilter: null,
			grandTotal: 0,
			invoiceCount: 0,
			loaded: false
		};
		this.handleRangeChange = this.handleRangeChange.bind(this);
	}

	onGetInvoiceTotals(data: any) {
		this.setState({ grandTotal: data.grandTotal, invoiceCount: data.invoiceCount });
	}

	render() {
		const searchProps: LMI.ISearchProps = {
			placeholder: "Search Invoices",
			onSubmit: data => {
				this.setState({ searchFilter: data.term });
			},
			onEmpty: () => {
				this.setState({ searchFilter: null });
			},
			tracking_path: this.props.tracking.getTrackingData().event_subtype
		};
		const ListProps = Object.assign({}, this.props, {
			startDate: this.state.startDate ? this.state.startDate : null,
			endDate: this.state.endDate ? this.state.endDate : null,
			searchFilter: this.state.searchFilter ? this.state.searchFilter : null,
			getInvoiceTotals: data => {
				this.onGetInvoiceTotals(data);
			},
			tracking_path: this.props.tracking.getTrackingData().event_subtype
		});

		const isDealerSubComponent: boolean = this.props.viewType.includes("sub-component") && this.props.viewType.includes("dealer");

		return (
			<div className="invoices-tab-panel">
				<div className="upper-panel">
					<Grid className="tools">
						<Grid.Row centered columns={3} className="mozilla-float-fix">
							<Grid.Column floated="left">
								<SearchComponent {...searchProps} />
							</Grid.Column>
							{isDealerSubComponent && Boolean(this.state.invoiceCount) && (
								<Grid.Column>
									<div className="bottom centered">
										{this.state.invoiceCount}
										<span className="light"> Invoice(s) at </span>
										{formatCurrency(this.state.grandTotal, 2)}
									</div>
								</Grid.Column>
							)}
							<Grid.Column floated="right" className="date-range">
								<div className="range-field">{this.getRangeSelector()}</div>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
				<InvoicesListComponent {...ListProps} />
			</div>
		);
	}

	getRangeSelector() {
		if (this.state.loaded)
			return (
				<DateRangePickerComponent
					id="InvoiceDateRange"
					placeholder="Select a range"
					max={this.today}
					startDate={this.state.startDate}
					endDate={this.state.endDate}
					allowEdit={false}
					strictMode={true}
					showClearButton={false}
					change={this.handleRangeChange}
				>
					<PresetsDirective>
						<PresetDirective label="One Week" start={this.oneWeek} end={this.today}></PresetDirective>
						<PresetDirective label="One Month" start={this.oneMonth} end={this.today}></PresetDirective>
						<PresetDirective label="Three Months" start={this.threeMonths} end={this.today}></PresetDirective>
						<PresetDirective label="One Year" start={this.oneYear} end={this.today}></PresetDirective>
					</PresetsDirective>
				</DateRangePickerComponent>
			);
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ loaded: true });
		}, 200);
	}

	handleRangeChange(range: RangeEventArgs): void {
		this.setState({
			startDate: new Date(range.startDate),
			endDate: new Date(range.endDate)
		});
	}
}

export default InvoicesTabPanel;
