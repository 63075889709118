import { restAPI } from "../../../../../utils/rest";
import { gqlQueries } from "gql-imports";
import {
  addAlert,
  enableUABCallback,
  triggerUABCallback,
} from "api/redux/actions";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { UniversalActionModalComponent } from "client/pages/admin/components/UniversalActionModalComponent";

import { formatAddress, formatPhone } from "client/utils/functions";
import { hasPermission } from "client/utils/userAccess";
import { AnalyticsEventType, Permission } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Loading } from "../../../../../components/Loading";
import { withUAB } from "../../../../WithUAB";
import { DataFilterComponent } from "../../DataFilterComponent";
import {
  getSortableCheckbox,
  getSortableEditable,
  SortableTable,
} from "../../SortableTable";
import {
  AuctionDetailsComponent,
  FailedDeleteModalComponent,
} from "./components";
import track from "react-tracking";
import { Session } from "client/utils/session";

import "./AuctionsTabPanel.css";

@track(
  (props) => {
    return {
      event_type: AnalyticsEventType.SUBNAV,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }settings.auctions`,
    };
  },
  { dispatchOnMount: true }
)
export class AuctionsTabPanelView extends React.Component<
  LMI.IAuctionsTPProps,
  LMI.IAuctionsTPState
> {
  private static _formatErrorMessage(error) {
    if (error) {
      if (error.reason.response && error.reason.response.data) {
        return error.reason.response.data.message;
      }

      if (typeof error.reason === "string") {
        if (error.reason === "Match failed") {
          return "Validation error, a required field was probably missing.  Please complete all required fields.";
        }
        return error.reason;
      }

      // Not sure of the shape
      return "There was an error.";
    }
    return "There was an error; 'error: undefined'";
  }

  constructor(props) {
    super(props);
    this.state = {
      searchFilter: null,
      editAuction: null,
      failedDelete: null,
    };
  }

  render() {
    const props = this.props;

    if (props.hasErrors) {
      console.log("AuctionsTabPanel Data errors:", props.message);
    }
    if (!props.auction_houses || props.loading) {
      return <Loading />;
    }

    const stores = Session.get("stores");
    const currentStoreName =
      props.storeId &&
      stores.find((st) => st.store_id === props.storeId).store_name;
    const canView = hasPermission(
      this.props.permissions,
      Permission.ADMIN_VIEW_AUCTION,
      Session.get("isSuperUser")
    );
    const canEdit = hasPermission(
      this.props.permissions,
      Permission.ADMIN_ADD_AUCTION,
      Session.get("isSuperUser")
    );

    let auction_houses: any[] = props.auction_houses.length
      ? this.formatData(props.auction_houses, props.viewType)
      : [];
    if (this.state.searchFilter) {
      auction_houses = this.searchFilter(
        this.state.searchFilter,
        auction_houses
      );
    }

    const modalProps: LMI.IAuctionHouseDetails = {
      auctionhouse: this.state.editAuction,
      onCreate: (data: any) => this.createAuctionHouse(data),
      onSave: (data: any, ahId: number) => this.updateAuctionHouse(data, ahId),
      closeCallback: () => {
        if (!this.state.editAuction) {
          props.universalActionCallback();
        } else {
          this.setState({
            editAuction: null,
          });
        }
      },
    };

    const failedDeleteProps: LMI.IFailedDeleteProps = {
      auctionhouse: this.state.failedDelete,
      disable: (id: number) => {
        const data = {
          is_active: false,
        };
        this.updateAuctionHouse(data, id);
        this.setState({
          failedDelete: null,
        });
      },
      close: () => {
        this.setState({
          failedDelete: null,
        });
      },
    };

    return (
      <div className="auctions-tab-panel panel-content">
        {this.state.editAuction && (
          <ModalComponent
            headerText={this.state.editAuction.name}
            shouldBeOpen={this.state.editAuction ? true : false}
            onClose={(evt, data) => {
              this.setState({ editAuction: null });
            }}
            className="auctionhouse-details"
            contentComponent={() => this.getModalForm(modalProps)}
          />
        )}
        {this.state.failedDelete && (
          <ModalComponent
            headerText="Ooops!"
            shouldBeOpen={this.state.failedDelete ? true : false}
            onClose={(evt, data) => {
              this.setState({ failedDelete: null });
            }}
            className="failed-delete"
            size="small"
            contentComponent={() => (
              <FailedDeleteModalComponent {...failedDeleteProps} />
            )}
          />
        )}
        <UniversalActionModalComponent
          toggleUABCallback={props.toggleUABCallback}
          universalActionCallback={props.universalActionCallback}
          contentComponent={() => this.getModalForm(modalProps)}
          headerText="Add Auction House"
        />
        <div className="sortable-container">
          {auction_houses.length > 0 || this.state.searchFilter ? (
            <div className="sortable-filter">
              <DataFilterComponent
                label="Filter Results"
                searchFilter={this.state.searchFilter}
                onChange={(p, { value }) => {
                  this.setState({
                    searchFilter: value,
                  });
                }}
              />
            </div>
          ) : (
            ""
          )}
          <div className="sortable-content">
            {canView && (
              <SortableTable
                filter={this.state.searchFilter}
                message={
                  currentStoreName +
                  " currently has no auction houses setup" +
                  (canEdit
                    ? ". Use the [+] button on the top right to add an auction house."
                    : ".")
                }
                tableData={this.getTableData(auction_houses, props.viewType)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  getModalForm(props) {
    return <AuctionDetailsComponent {...props} />;
  }

  searchFilter(search: string, data: any[]) {
    const srch = search.toLowerCase();
    return data.filter((service) => {
      return (
        service.auctionHouse.toLowerCase().includes(srch) ||
        service.contact.toLowerCase().includes(srch) ||
        service.address.toLowerCase().includes(srch) ||
        service.phone.toLowerCase().includes(srch)
      );
    });
  }

  getTableData(auction_houses, viewType) {
    const tableHeaders: any = [
      {
        id: "tools",
        label: "",
        sortable: false,
        collapsing: true,
      },
      {
        id: "auctionHouse",
        label: "Auction House",
        sortable: true,
      },
      {
        id: "contact",
        label: "Contact",
        sortable: true,
      },
      {
        id: "address",
        label: "Address",
        sortable: true,
      },
      {
        id: "phone",
        label: "Mobile Phone",
        sortable: true,
      },
      {
        id: "isActive",
        label: "Active?",
        sortable: false,
        collapsing: true,
      },
    ];

    const sortableTableData: LMI.ITableData = {
      headers: [].concat(tableHeaders),
      body: {
        rows: [],
      },
    };
    return Object.assign({}, sortableTableData, {
      body: {
        rows: auction_houses,
      },
    });
  }

  formatData(
    auction_houses: LMI.IAuctionHouseGQL[],
    viewType: string
  ): LMI.IAuctionHouseTableRow[] {
    return auction_houses.map(
      (ah: LMI.IAuctionHouseGQL): LMI.IAuctionHouseTableRow => {
        return {
          tools: {
            component: getSortableEditable,
            editable: hasPermission(
              this.props.permissions,
              Permission.ADMIN_EDIT_AUCTION,
              Session.get("isSuperUser")
            ),
            removable: hasPermission(
              this.props.permissions,
              Permission.ADMIN_REMOVE_AUCTION,
              Session.get("isSuperUser")
            ),
            editcallback: () => {
              this.setState({
                editAuction: ah,
              });
            },
            removecallback: () => {
              this.deleteAuctionHouse(ah.id);
            },
          },
          auctionHouse: ah.name,
          contact: ah.contact_name,
          address: formatAddress(ah),
          phone: formatPhone(ah.phone),
          isActive: hasPermission(
            this.props.permissions,
            Permission.ADMIN_EDIT_AUCTION,
            Session.get("isSuperUser")
          )
            ? {
                value: ah.is_active,
                component: getSortableCheckbox,
                callback: (data) => {
                  this.setAuctionHouseActive(data, ah.id);
                },
              }
            : ah.is_active
            ? "Yes"
            : "No",
        };
      }
    );
  }

  componentDidMount() {
    // Should we show the UAB to this user?
    if (
      !hasPermission(
        this.props.permissions,
        Permission.ADMIN_ADD_AUCTION,
        Session.get("isSuperUser")
      )
    ) {
      this.props.disableUAB();
    }
  }

  createAuctionHouse(formData) {
    for (const prop in formData) {
      if (formData[prop] === null || formData[prop] === undefined) {
        delete formData[prop];
      }
    }
    restAPI({
      endpointName: "addAuctionHouse",
      urlArgs: [parseInt(this.props.storeId, 10)],
      data: formData,
      callback: (error, res) => {
        if (error)
          this.props.addAlert({
            type: "danger",
            message: AuctionsTabPanelView._formatErrorMessage(error),
            timeout: 3000,
          });
        this.props.refetch();
      },
    });
    setTimeout(this.props.universalActionCallback, 100);
  }

  updateAuctionHouse(formData: any, auction_house_id: number) {
    for (const prop in formData) {
      if (formData[prop] === null || formData[prop] === undefined) {
        delete formData[prop];
      }
    }
    restAPI({
      endpointName: "updateAuctionHouse",
      urlArgs: [parseInt(this.props.storeId, 10), auction_house_id],
      data: formData,
      callback: (err, res) => {
        if (err)
          this.props.addAlert({
            type: "danger",
            message: err.reason.response.data.message,
            timeout: 3000,
          });
        this.props.refetch();
      },
    });
    this.setState({
      editAuction: null,
    });
  }

  deleteAuctionHouse(auction_house_id: number) {
    restAPI({
      endpointName: "deleteAuctionHouse",
      urlArgs: [parseInt(this.props.storeId, 10), auction_house_id],
      data: null,
      callback: (err, res) => {
        if (err) {
          this.props.addAlert({
            type: "danger",
            message: err.reason.response.data.message,
            timeout: 3000,
          });
        } else {
          if (!res.data.deleted) {
            this.setState({
              failedDelete: this.props.auction_houses.find(
                (ah) => ah.id === auction_house_id
              ),
            });
          }
        }
        this.props.refetch();
      },
    });
  }

  setAuctionHouseActive(data, auctionHouseId: number) {
    const flag = {
      is_active: data.state,
    };
    this.updateAuctionHouse(flag, auctionHouseId);
  }
}

const showUABOn = [{ viewType: "lists-component" }];
const AuctionsTabPanelViewUAB: any = withUAB(AuctionsTabPanelView, showUABOn);

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    toggleUABCallback: state.app.admin.toggleUABCallback,
    permissions: state.app.admin.permissions,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    universalActionCallback: () => {
      dispatch(triggerUABCallback(false));
    },
    disableUAB: () => {
      dispatch(enableUABCallback(false));
    },
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const AuctionsTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IAuctionsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.auctionHouses,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, auction_houses, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        return {
          auction_houses,
          refetch,
        };
      },
    }
  )
)(AuctionsTabPanelViewUAB) as React.ComponentType<any>;

export default AuctionsTabPanel;
