// node_modules
import * as React from "react";
// components
import { Button, Segment } from "semantic-ui-react";

export class FailedDeleteModalComponent extends React.Component<LMI.IFailedDeleteProps, undefined> {
	render() {
		const props = this.props;

		return (
			<div id="failed-delete">
				We're sorry, we can't delete <strong>{props.auctionhouse.name}</strong> because it is being used in other places.
				<br />
				{props.auctionhouse.is_active ? (
					<span>
						<br />
						<strong>Would you like to disable it instead?</strong>
						<Segment basic>
							<Button.Group>
								<Button positive onClick={() => props.disable(props.auctionhouse.id)}>
									Yes
								</Button>
								<Button.Or />
								<Button type="button" onClick={() => props.close()}>
									Cancel
								</Button>
							</Button.Group>
						</Segment>
					</span>
				) : (
					<Segment basic>
						<Button type="button" content="Close" onClick={() => props.close()} />
					</Segment>
				)}
			</div>
		);
	}
}

export default FailedDeleteModalComponent;
