import { gqlQueries } from "gql-imports";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { stringToInt } from "client/utils/functions";
import { hasPermission } from "client/utils/userAccess";
import { Permission } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import {
  Accordion,
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Loader,
  Message,
} from "semantic-ui-react";
import { PermissionsModalComponent } from "../PermissionsModalComponent/PermissionsModalComponent";
import { Session } from "client/utils/session";

import "./RolesPermissionsComponent.css";

export class RolesPermissionsComponentView extends React.Component<
  LMI.IEmpDRPProps,
  LMI.IEmpDRPState
> {
  constructor(props) {
    super(props);
    this.state = {
      editPermissions: false,
      showPerms: false,
    };
  }

  render() {
    const props = this.props;
    const selectedStore = props.user.user.employees.find(
      (emp) => emp.store_id === props.storeId
    );
    const canEditEmployee = hasPermission(
      props.requestingUserPermissions,
      Permission.ADMIN_EDIT_EMPLOYEE,
      Session.get("isSuperUser")
    );
    const currentUser = localStorage.getItem("userId");
    const modalProps = {
      employeePermissions: props.roles && props.roles.permissions,
      employeeRoles: props.roles && props.roles.roles,
      storeId: props.storeId,
      employeeId: props.empId,
      onRefresh: () => props.refetch(),
    };

    if (props.loading) {
      return (
        <div>
          <br />
          <br />
          <Loader active inline="centered" />
        </div>
      );
    }

    return (
      <div className="roles-permissions-component">
        {this.state.editPermissions ? (
          <ModalComponent
            scrolling={true}
            headerText={
              props.user.user.full_name +
              " Access Role at: " +
              selectedStore.store.name
            }
            shouldBeOpen={this.state.editPermissions}
            onClose={(evt, data) => {
              this.setState({ editPermissions: false });
              // this.props.onRefresh();
            }}
            className="edit-permissions-modal"
            contentComponent={() => (
              <PermissionsModalComponent {...modalProps} />
            )}
          />
        ) : (
          ""
        )}
        {props.roles && props.roles.roles.length && !props.hasErrors ? (
          <Grid className="admintab">
            <Grid.Column width={10}>
              <Header as="h4">Access Roles</Header>
              <List>
                {props.roles.roles.map((role, index) => {
                  return (
                    <List.Item key={index}>
                      <List.Content>
                        <Label size="large">
                          <Icon name="users" /> {role.name}
                        </Label>
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
            </Grid.Column>
            <Grid.Column width={6}>
              {currentUser !== props.user.user.id &&
                canEditEmployee && ( // the current user cannot edit their own roles/permissions
                  <Button
                    compact
                    primary
                    size="small"
                    className="editbtn"
                    floated="right"
                    onClick={() => {
                      this.setState({ editPermissions: true });
                    }}
                  >
                    <Icon name="write" />
                    Edit
                  </Button>
                )}
            </Grid.Column>
            <Grid.Column width={16}>
              <Accordion>
                <Accordion.Title
                  active={this.state.showPerms}
                  index={0}
                  onClick={() =>
                    this.setState({
                      showPerms: this.state.showPerms ? false : true,
                    })
                  }
                >
                  <Icon name="dropdown" />
                  Permissions
                </Accordion.Title>
                <Accordion.Content active={this.state.showPerms}>
                  <List
                    divided
                    verticalAlign="middle"
                    className="permissions-list"
                  >
                    {props.roles.permissions.map((permission, index) => {
                      return (
                        <List.Item key={index}>
                          <List.Content>{permission.description}</List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                </Accordion.Content>
              </Accordion>
            </Grid.Column>
          </Grid>
        ) : (
          <div className="noRoles">
            {props.hasErrors ? (
              <Message negative>
                <Message.Header>
                  We're sorry, there was a problem.
                </Message.Header>
                <p>{props.error}</p>
              </Message>
            ) : (
              <Container textAlign="center">
                <br />
                <Header as="h3">This employee has no role yet!</Header>
                <Button
                  icon
                  labelPosition="right"
                  onClick={() => {
                    this.setState({ editPermissions: true });
                  }}
                >
                  Add a Role
                  <Icon name="add square" />
                </Button>
              </Container>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const RolesPermissionsComponent = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.employeePermissions,
    {
      options: (props: any) => {
        return {
          variables: {
            store_id: stringToInt(props.storeId),
            id: stringToInt(props.empId),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, roles, refetch } }): any => {
        if (loading) {
          return { loading: true };
        }
        if (error) {
          return { hasErrors: true, error: error.graphQLErrors[0].message };
        }
        return {
          roles,
          refetch,
        };
      },
    }
  )
)(RolesPermissionsComponentView) as React.ComponentType<any>;

export default RolesPermissionsComponent;
