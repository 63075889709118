import * as React from "react";
import track from "react-tracking";
import { ClassAttributes } from "react";
import { gqlQueries } from "gql-imports";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { addAlert } from "api/redux/actions";
import { restAPI } from "../../../../../utils/rest";
import {
  handleErrorResponse,
  handleSuccessResponse,
  sortByProperty,
} from "client/utils/functions";
import { AnalyticsEventType } from "loopmein-shared";

import { Loading } from "client/components/Loading";
import {
  Button,
  Dropdown,
  Grid,
  Header,
  Image,
  Popup,
  Segment,
} from "semantic-ui-react";
import { ReconItemEditor } from "./components";
import { DragDropListComponent } from "client/pages/admin/components/DragdropListComponent";
import { ModalComponent } from "../../ModalComponent";

import "./ReconSettingsTabPanel.css";

enum editableLists {
  reconLevel = 1,
}

@track(
  (props) => {
    return {
      event_type: AnalyticsEventType.SUBNAV,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }settings.workflow`,
    };
  },
  { dispatchOnMount: true }
)
export class ReconSettingsTabPanelView extends React.Component<
  LMI.IReconSettingsProps,
  LMI.IReconSettingsState
> {
  constructor(props) {
    super(props);

    this.state = {
      openListEditor: -1,
      reconLevelItem: null,
    };
  }

  render() {
    const { openListEditor } = this.state;
    const { loading, store_settings, recon_levels } = this.props;
    const modalProps = this.getModalProps(openListEditor);

    if (loading) return <Loading />;

    return (
      <div id="sani-settings" className="panel-content">
        {modalProps && <ModalComponent {...modalProps} />}
        <Grid>
          <Grid.Column width={12}>
            <Header as="h3">
              <Image src={`/images/workflow.png`} />
              <Header.Content>Workflow Settings</Header.Content>
            </Header>
          </Grid.Column>
        </Grid>
        <Segment>
          <Header as="h4" content="Basic Settings" />
          {store_settings
            .sort(sortByProperty("name"))
            .reverse()
            .map((setting, key) => {
              const { store_setting_type, value, name } = setting;
              const settingValue = JSON.parse(value);
              const settingOptions = this.getSettingOptions(setting);
              const settingName = name
                .replace(/_/g, " ")
                .toLowerCase()
                .split(" ")
                .map((w) => w[0].toUpperCase() + w.substr(1))
                .join(" ");

              if (!settingOptions || settingOptions.length <= 0) {
                return (
                  <div key={key} className="ui message info small">
                    {`Setup ${
                      name === "DEFAULT_RECON_LEVEL_USED"
                        ? "Recon Levels below"
                        : "Workflow Stages in the Workflow tab"
                    } to set `}
                    <strong>{`${settingName}`}</strong> Setting.
                  </div>
                );
              }

              return (
                <div key={key} className="sani-setting">
                  <Popup
                    inverted
                    content={store_setting_type.label}
                    trigger={
                      <Dropdown
                        compact
                        selection
                        placeholder="Select"
                        selectOnBlur={false}
                        value={settingValue}
                        options={settingOptions}
                        onChange={(e, data) =>
                          this.updateSetting({ name, value: data.value })
                        }
                      />
                    }
                  />
                  <span className="setting-name">{settingName}</span>
                </div>
              );
            })}
        </Segment>
        <Segment>
          <Header as="h4">
            <Button
              className="add-item-btn"
              content="Add Level"
              floated="right"
              size="mini"
              onClick={() =>
                this.setState({ openListEditor: editableLists.reconLevel })
              }
            />
            Recon Levels
          </Header>
          {!recon_levels || recon_levels.length <= 0 ? (
            <Header
              content="There are currently no workflow levels configured."
              as="h5"
              color="grey"
            />
          ) : (
            <div style={{ clear: "both" }}>
              <div className="tableTop">
                <Grid>
                  <Grid.Column width={2}>SORT/EDIT/DELETE</Grid.Column>
                  <Grid.Column width={3}>NAME</Grid.Column>
                  <Grid.Column width={2}>WARNING</Grid.Column>
                  <Grid.Column width={2}>CRITICAL</Grid.Column>
                </Grid>
              </div>
              <DragDropListComponent
                {...{
                  listName: "reconLevelList",
                  editable: true,
                  deletable: true,
                  dragHandle: true,
                  hideDesc: true,
                  showThresholds: true,
                  items: recon_levels.map((item) => {
                    return {
                      item,
                      key: item.id,
                      name: item.name,
                      warning_threshold_days: item.warning_threshold_days,
                      error_threshold_days: item.error_threshold_days,
                    };
                  }),
                  editCallback: (reconLevelItem) =>
                    this.setState({
                      reconLevelItem,
                      openListEditor: editableLists.reconLevel,
                    }),
                  deleteCallback: (editReconLevel) =>
                    this.deleteReconLevel(editReconLevel),
                  callback: (items) =>
                    this.sortReconLevelItems(items.map((i) => i.item)),
                }}
              />
            </div>
          )}
        </Segment>
      </div>
    );
  }

  getSettingOptions(setting: LMI.IStoreSetting): any[] {
    // everything is getting phase/stage options unless it is recon level
    const settingOptions =
      setting.name === "DEFAULT_RECON_LEVEL_USED"
        ? this.props.recon_levels
        : this.props.dealer_phases;
    return settingOptions.map((o) => ({
      key: o.id,
      text: o.name,
      value: o.id,
    }));
  }

  getModalProps(openListEditor) {
    return openListEditor === editableLists.reconLevel
      ? this.getReconEditorProps()
      : null;
  }

  getReconEditorProps() {
    const { reconLevelItem } = this.state;
    const editorProps: LMI.IModalProps = {
      size: "mini",
      headerText: `${reconLevelItem ? "Edit" : "Add"} Recon Level`,
      shouldBeOpen: true,
      onClose: () =>
        this.setState({ openListEditor: -1, reconLevelItem: null }),
      contentComponent: () => (
        <ReconItemEditor
          {...{
            item: reconLevelItem,
            onSave: (itemData) =>
              this.setState({ openListEditor: -1 }, () =>
                this.createReconLevel(itemData)
              ),
            onUpdate: (itemData) =>
              this.setState({ openListEditor: -1 }, () =>
                this.updateReconLevel(itemData)
              ),
          }}
        />
      ),
    };
    return editorProps;
  }

  getAlertProps(err, res) {
    return {
      type: err ? "danger" : "success",
      message: err
        ? handleErrorResponse({ error: err })
        : handleSuccessResponse({ result: res }),
      timeout: 3000,
    };
  }

  updateSetting(data: any) {
    restAPI({
      endpointName: "setStoreSettings",
      urlArgs: [this.props.storeId],
      data: { name: data.name, value: data.value.toString() },
      callback: (err, res) => {
        this.props.addAlert(this.getAlertProps(err, res));
        this.props.refetchSettings();
      },
    });
  }

  createReconLevel(item: any) {
    restAPI({
      endpointName: "createReconLevelItem",
      urlArgs: [this.props.storeId],
      data: item,
      callback: (err, res) => {
        this.setState({ reconLevelItem: null }, () => {
          this.props.addAlert(this.getAlertProps(err, res));
          this.props.refetchLevels();
        });
      },
    });
  }

  updateReconLevel(item: any) {
    const { id } = item;
    restAPI({
      endpointName: "updateReconLevelItem",
      urlArgs: [this.props.storeId, id],
      data: item,
      callback: (err, res) => {
        this.setState({ reconLevelItem: null }, () => {
          this.props.addAlert(this.getAlertProps(err, res));
          this.props.refetchLevels();
        });
      },
    });
  }

  deleteReconLevel(item: any) {
    restAPI({
      endpointName: "deleteReconLevelItem",
      urlArgs: [this.props.storeId, item.id],
      data: null,
      callback: (err, res) => {
        this.props.addAlert(this.getAlertProps(err, res));
        this.props.refetchLevels();
      },
    });
  }

  sortReconLevelItems(items: any[]) {
    const orderedItems = { ids: items.map((i) => i.id) };
    restAPI({
      endpointName: "sortReconLevelItems",
      urlArgs: [this.props.storeId],
      data: orderedItems,
      callback: (err, res) => {
        this.props.addAlert(this.getAlertProps(err, res));
        this.props.refetchLevels();
      },
    });
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

const settings = [
  "DEFAULT_STAGE_NEW",
  "DEFAULT_STAGE_USED",
  "DEFAULT_RECON_LEVEL_USED",
];
export const ReconSettingsTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IOrgSettingsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.settings.store,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            names: settings,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_settings, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        const start = settings
          .map((setting, index) => {
            return {
              name: setting,
              value: null,
              store_setting_type: { label: "Select an Option" },
            };
          })
          .filter((i) => {
            const hasSetting =
              store_settings && store_settings.find((ei) => ei.name === i.name);
            return !hasSetting;
          });

        return {
          store_settings: [...start, ...store_settings].reverse(),
          refetchSettings: refetch,
        };
      },
    }
  ),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.reconLevels,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, store_recon_levels, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          recon_levels: store_recon_levels.recon_levels,
          refetchLevels: refetch,
        };
      },
    }
  ),
  graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.phases, {
    options: (props: any) => {
      return {
        variables: {
          storeId: parseInt(props.storeId, 10),
        },
        fetchPolicy: "network-only",
      };
    },
    props: ({ data: { error, loading, dealer_phases, refetch } }): any => {
      if (loading) return { loading: true };
      if (error) return { hasErrors: true, message: error };

      return {
        dealer_phases: dealer_phases.phases,
        refetch,
      };
    },
  })
)(ReconSettingsTabPanelView) as React.ComponentType<any>;

export default ReconSettingsTabPanel;
