import { graphql } from "@apollo/react-hoc";
import { GridComponent } from "@syncfusion/ej2-react-grids";
import { gqlQueries, gqlSubscriptions } from "gql-imports";

import { flowRight as compose } from "lodash";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Button, Grid, Loader } from "semantic-ui-react";
import { ModalComponent } from "../../ModalComponent";
import { EditableCard, NewCard, ReconCard } from "./components";
import JobTitleAssignment from "./components/JobTitleAssignment";
import ReconInventory from "./components/ReconInventory";
import ReconLevelAssignment from "./components/ReconLevelAssignment";
import { UserAssignment } from "./components/UserAssignment";
import { hasPermission } from "../../../../../utils/userAccess";
import { AnalyticsEventType, Permission } from "loopmein-shared";
import { addAlert } from "api/redux/actions";
import { restAPI } from "../../../../../utils/rest";
import history from "client/utils/history";
import ReconInventorySearch from "./components/ReconInventorySearch";
import ToursIntroPopup, {
  ToursIntroPopupProps,
} from "client/components/Tours/ToursIntroPopup";
import track from "react-tracking";
import StageDestination from "./components/StageDestination";
import { Session } from "client/utils/session";

import "./ReconTabPanel.css";

@track(
  { event_type: AnalyticsEventType.NAVIGATION, event_subtype: "recon" },
  { dispatchOnMount: true }
)
export class ReconTabPanelView extends React.Component<
  LMI.IReconTabProps,
  LMI.IReconTabState
> {
  grid: GridComponent;
  abortController = new AbortController();

  constructor(props: LMI.IReconTabProps) {
    super(props);

    this.state = {
      editable: false,
      modal_open: false,
      modal: {},
      draggable: false,
      recon_detail_open: null,
      recon_phase_name: "none",
      add_card: [],
      card_loading: false,
      page_loading: false,
      sub_id: null,
    };
  }

  toggleEditable() {
    // This must be here to dragndrop properly.
    this.props.refetch_recon();

    this.setState((prevState) => ({
      editable: !prevState.editable,
      add_card: [],
      draggable: false,
    }));
  }

  handleErrorResponse(error: any) {
    if (error.reason && error.reason.response && error.reason.response.data) {
      return error.reason.response.data.message;
    } else {
      return "There was an error.";
    }
  }

  sendAlert({ type, message }) {
    this.props.addAlert({
      type,
      message,
      timeout: 3000,
    });

    if (type === "danger") {
      console.log(`Error: ${message}`);
    }
  }

  handleModalOpen = (props) => {
    this.setState({
      modal_open: true,
      modal: {
        phase_id: props.id,
        tool_name: props.toolName,
        title: `Modify ${props.toolName.toUpperCase()} ${
          props.name && "for " + props.name.toUpperCase()
        }`,
      },
    });
  };

  openInventorySearchModal(hideDimmer = false) {
    this.setState({
      modal_open: true,
      modal: {
        hideDimmer,
        tool_name: "search",
        title: "Search Inventory in Workflow",
      },
    });
  }

  toggleCardBody = () => {
    // Shrink the cards so we can see them all during dnd
    const cards = document.querySelectorAll(".card-body");
    [].slice.call(cards).forEach((card) => {
      if (!card.classList.contains("minimize")) {
        this.setState({ draggable: true }, () => {
          card.classList.add("minimize");
        });
      } else {
        this.setState({ draggable: false }, () => {
          card.classList.remove("minimize");
        });
      }
    });
  };

  openDetail = (event) => {
    const phaseName = event.currentTarget.getAttribute("data-name");
    const phaseId = parseInt(event.currentTarget.getAttribute("data-id"), 10);
    if (history) history.push(`/admin/dealerships/workflow/${phaseId}`);
    this.setState({ recon_detail_open: phaseId, recon_phase_name: phaseName });
  };

  closeDetail = () => {
    if (history) history.push(`/admin/dealerships/workflow/stages`);
    this.setState({ recon_detail_open: null }, () => {
      this.clearTimers();
      this.props.refetch_recon();
    });
  };

  clearTimers = () => {
    for (const index in (window as any).workInterval)
      clearInterval((window as any).workInterval[index]);
    for (const index in (window as any).phaseInterval)
      clearInterval((window as any).phaseInterval[index]);
  };

  modalForm = () => {
    const { modal } = this.state;
    const modalProps = {
      ...this.props,
      phase_id: modal.phase_id,
      onClose: () => this.closeModal(),
      onRefetch: () => this.refetchRecon(),
      onHandleErrorResponse: (error) => this.handleErrorResponse(error),
      onSendAlert: (alert) => this.sendAlert(alert),
    };

    switch (modal.tool_name.toLowerCase()) {
      case "workflow":
        return <ReconLevelAssignment {...modalProps} />;
      case "job titles":
        return <JobTitleAssignment {...modalProps} />;
      case "users":
        return <UserAssignment {...modalProps} />;
      case "stage destinations":
        return <StageDestination {...modalProps} />;
      case "search":
        return (
          <ReconInventorySearch
            tracking_path={this.props.tracking.getTrackingData().event_subtype}
            onClose={() => this.setState({ modal: null, modal_open: false })}
            history={history}
          />
        );
      default:
        break;
    }
  };

  closeModal = () => {
    this.setState({ modal_open: false, add_card: [] });
  };

  addCard = () => {
    this.handleNewCardLoading();
    const add_card = [...this.state.add_card];
    restAPI({
      endpointName: "insert_empty_store_phases",
      urlArgs: [this.props.storeId],
      data: null,
      callback: (error, result) => {
        let alert;
        if (error) {
          alert = { type: "danger", message: this.handleErrorResponse(error) };
          this.sendAlert(alert);
        }
        add_card.push({
          id: result.data.phase.id,
          name: result.data.phase.name
            ? result.data.phase.name
            : `New Stage ${result.data.phase.id}`,
        });
        this.setState({ add_card });
        this.handleNewCardLoading();
      },
    });
  };

  refetchRecon = () => {
    this.setState({ page_loading: true });
    setTimeout(
      () =>
        this.setState({ add_card: [], page_loading: false }, () => {
          this.props.refetch_recon();
          this.props.refetch_recon_avg();
        }),
      500
    );
  };

  handleNewCardLoading = () => {
    this.setState((prevState) => ({
      card_loading: !prevState.card_loading,
    }));
  };

  setGrid(grid) {
    this.grid = grid;
    // setTimeout(() => this.grid.selectRow(0), 200);
  }

  handleChangePhase = (event, { value }) => {
    this.grid.clearSelection();
    this.props.history.push(`/admin/dealerships/workflow/${value}`);
  };

  render() {
    const {
      dealer_phases,
      dealer_phasers,
      recon_interval_averages,
      storeId,
    } = this.props;
    const {
      recon_detail_open,
      recon_phase_name,
      add_card,
      editable,
      draggable,
      modal_open,
      modal,
      card_loading,
      page_loading,
    } = this.state;

    const canEdit = hasPermission(
      this.props.permissions,
      Permission.WORKFLOW_MANAGER,
      Session.get("isSuperUser")
    );

    const newCardProps = {
      onAddCard: () => this.addCard(),
      loading: card_loading,
    };

    const fillColumns = [];
    if (!dealer_phases) {
      return <Loader content="Updating..." active />;
    } else {
      for (let i = dealer_phases.length + 1; i < 5; i++) {
        fillColumns.push(i);
      }
    }

    if (recon_detail_open) {
      const detailProps = {
        onAlert: (alert) => this.sendAlert(alert),
        onHandleErrorResponse: (error) => this.handleErrorResponse(error),
        onCloseDetail: this.closeDetail,
        onClearTimers: this.clearTimers,
        onHandleChangePhase: this.handleChangePhase,
        recon_detail_open,
        recon_phase_name,
        setGrid: (grid) => this.setGrid(grid),
        ...this.props,
      };

      return <ReconInventory {...detailProps} />;
    }

    return (
      <div id="recon-panel" className="scrollable-el">
        {modal_open && (
          <ModalComponent
            headerText={modal.title}
            size="medium"
            shouldBeOpen={modal_open}
            onClose={() => this.closeModal()}
            className="phase-modal"
            hideDimmer={modal.hideDimmer}
            contentComponent={() => this.modalForm()}
          />
        )}

        {!editable && dealer_phases.length > 0 && (
          <span>
            <span id="averageTotal" className="floating-btn">
              {recon_interval_averages &&
              recon_interval_averages.avg_total_recon_elapsed ? (
                <span>
                  <span style={{ color: "darkgray" }}>Avg Recon:</span>{" "}
                  {recon_interval_averages.avg_total_recon_elapsed.days}{" "}
                  <span style={{ color: "lightgray" }}>Days</span>{" "}
                  {recon_interval_averages.avg_total_recon_elapsed.hours}{" "}
                  <span style={{ color: "lightgray" }}>Hours</span>{" "}
                  {recon_interval_averages.avg_total_recon_elapsed.minutes}{" "}
                  <span style={{ color: "lightgray" }}>Minutes</span>
                </span>
              ) : (
                <span style={{ color: "darkgray" }}>
                  Avg Recon: updating...
                </span>
              )}
            </span>
            {this.tourControl()}
            {dealer_phases.length > 0 && (
              <ToursIntroPopup
                {...({
                  route: "/admin/dealerships/workflow/stages",
                  name: "reconSearchIntro",
                  disableTriggerElemId: "LaunchReconSearch",
                  trigger: () => {
                    return (
                      <Button
                        basic
                        id="LaunchReconSearch"
                        color="blue"
                        icon="search"
                        floated="right"
                        className={`floating-btn ${!canEdit ? "loner" : ""}`}
                        onClick={() => this.openInventorySearchModal()}
                      />
                    );
                  },
                } as ToursIntroPopupProps)}
              />
            )}
            {canEdit && (
              <>
                <Button
                  floated="right"
                  id="refresh"
                  loading={page_loading}
                  className="floating-btn"
                  icon={true}
                  circular={false}
                  color="blue"
                  basic
                  onClick={() => this.refetchRecon()}
                >
                  <i className="icon refresh" />
                </Button>
                <Button
                  floated="right"
                  id="drag"
                  className="floating-btn"
                  icon={!editable}
                  circular={false}
                  color="blue"
                  onClick={() => {
                    if (editable) this.toggleCardBody();
                    else
                      this.setState({ editable: true }, () =>
                        this.toggleCardBody()
                      );
                  }}
                >
                  {draggable ? "Done Re-ordering" : "Re-order"}
                </Button>
              </>
            )}
          </span>
        )}
        {canEdit && (
          <Button
            floated="right"
            id="edit"
            className="floating-btn"
            icon={true}
            circular={false}
            color="blue"
            onClick={() => this.toggleEditable()}
          >
            <i className={`${editable ? "" : "icon edit"}`} />{" "}
            {editable ? "Done" : "Edit"}
          </Button>
        )}
        {!editable && dealer_phases.length <= 0 && (
          <div className="no-stages">
            You haven't added any Stages yet,{" "}
            <Button color="green" onClick={() => this.toggleEditable()}>
              Add a Stage
            </Button>{" "}
            now.
          </div>
        )}

        <Grid className="tools" doubling columns={5}>
          {dealer_phases.length > 0 &&
            dealer_phases.map((phase) => {
              const reconProps = {
                ...phase,
                storeId,
                user_assignments: dealer_phasers.user_assignments,
                job_title_assignments: dealer_phasers.job_title_assignments,
                recon_assignments: dealer_phasers.recon_assignments,
                editable,
                draggable,
                onSendAlert: (alert) => this.sendAlert(alert),
                onHandleErrorResponse: (error) =>
                  this.handleErrorResponse(error),
                onHandleModalOpen: (props) => this.handleModalOpen(props),
                onOpenDetail: this.openDetail,
                refetchRecon: () => this.refetchRecon(),
                loading: card_loading,
                reconIntervalAverages:
                  recon_interval_averages &&
                  recon_interval_averages.phases.find(
                    (p) => p.store_phase_id === phase.id
                  ),
                recon_levels: this.props.recon_levels,
              };
              return <ReconCard {...reconProps} key={phase.id} />;
            })}

          {add_card.length > 0 &&
            add_card.map((phase) => {
              const addCardProps = {
                id: phase.id,
                name: phase.name,
                storeId,
                onSendAlert: (alert) => this.sendAlert(alert),
                onHandleErrorResponse: (error) =>
                  this.handleErrorResponse(error),
                onHandleModalOpen: (props) => this.handleModalOpen(props),
                refetchRecon: () => this.refetchRecon(),
              };

              return <EditableCard {...addCardProps} />;
            })}
          {editable && <NewCard {...newCardProps} />}
          {dealer_phases.length > 0 &&
            dealer_phases.length < 5 &&
            fillColumns.map((f) => (
              <Grid.Column className="card-column"></Grid.Column>
            ))}
        </Grid>
      </div>
    );
  }

  tourControl() {
    return (
      <>
        <span
          id="OpenReconSearchMenuTour"
          onClick={() => this.openInventorySearchModal(true)}
        />
        <span
          id="CloseReconSearchTour"
          onClick={() => this.setState({ modal: null, modal_open: false })}
        />
      </>
    );
  }

  subscriptionRefresh() {
    this.props.refetch_recon && this.props.refetch_recon();
    this.props.refetch_recon_avg && this.props.refetch_recon_avg();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sub_id !== this.state.sub_id) {
      this.subscriptionRefresh();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let recon_detail_open = null;
    let recon_phase_name = null;

    if (nextProps.dealer_phases) {
      const navSelected = nextProps.match ? nextProps.match.params.id : null;
      if (navSelected !== "stages") {
        const recon_phase = nextProps.dealer_phases.find(
          (phase) => phase.id === parseInt(navSelected, 10)
        );
        if (recon_phase) {
          recon_detail_open = recon_phase.id;
          recon_phase_name = recon_phase.name;
        }
      }
    }

    if (
      nextProps.dealer_phases_sub &&
      nextProps.dealer_phases_sub.id !== prevState.sub_id
    ) {
      return {
        sub_id: nextProps.dealer_phases_sub.id,
        loading: true,
        recon_detail_open,
        recon_phase_name,
      };
    }
    return { recon_detail_open, recon_phase_name };
  }

  componentWillUnmount(): void {
    this.clearTimers();
    this.abortController.abort();
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addAlert: (alert: LMI.IAlertsProps) => {
      dispatch(addAlert(alert));
    },
  };
};

export const ReconTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IDealerPhasesSubProps, any, any, ClassAttributes<any>>(
    gqlSubscriptions.dealer.vehicle_workflow_update,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
        };
      },
      props: ({ data: { error, loading, vehicle_workflow_update } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          dealer_phases_sub: vehicle_workflow_update.result,
        };
      },
    }
  ),
  graphql<LMI.IDealerPhasesSubProps, any, any, ClassAttributes<any>>(
    gqlSubscriptions.dealer.phase_updated,
    {
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
        };
      },
      props: ({ data: { error, loading, phase_updated } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          phase_update_sub: phase_updated.result,
        };
      },
    }
  ),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.reconLevels,
    {
      skip: (ownProps: any) => !ownProps.storeId,
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, store_recon_levels } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          recon_levels: store_recon_levels.recon_levels,
        };
      },
    }
  ),
  graphql<LMI.IDealerPhasesQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.phases,
    {
      skip: (ownProps: any) =>
        ownProps.viewType.includes("vendor") || !ownProps.storeId,
      options: (props: any) => {
        return {
          variables: {
            // Default to BOTH new and used by omitting hasNew, hasUsed vars here
            storeId: parseInt(props.storeId, 10),
            hasNew: null,
            hasUsed: null,
          },
          fetchPolicy: "no-cache",
        };
      },
      props: ({ data: { error, loading, dealer_phases, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          hide_on_sold_and_dispositioned:
            dealer_phases.hide_on_sold_and_dispositioned,
          dealer_phases: dealer_phases.phases,
          dealer_phasers: dealer_phases.phasers,
          refetch_recon: refetch,
        };
      },
    }
  ),
  graphql<LMI.IReconIntervalAvgQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.reconIntervalAvg,
    {
      skip: (ownProps: any) => !ownProps.storeId,
      options: (props: any) => {
        return {
          variables: {
            storeId: parseInt(props.storeId, 10),
            intervalDays: 30,
          },
          fetchPolicy: "no-cache",
        };
      },
      props: ({
        data: { error, loading, recon_interval_averages, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };

        return {
          recon_interval_averages,
          refetch_recon_avg: refetch,
        };
      },
    }
  )
)(ReconTabPanelView) as React.ComponentType<any>;

export default ReconTabPanel;
