import * as React from "react";
import track from "react-tracking";
import { gqlQueries } from "gql-imports";
import { useQuery } from "@apollo/client";
import { useGlobals } from "api/redux/hooks";
import { hasPermission } from "client/utils/userAccess";
import { Session } from "client/utils/session";
import { restAPI } from "../../../../../utils/rest";
import { AnalyticsEventType, Permission } from "loopmein-shared";

import { Loading } from "../../../../../components/Loading";
import { WithUAB } from "../../../../../pages/WithUAB";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { UniversalActionModalComponent } from "client/pages/admin/components/UniversalActionModalComponent";
import { DataFilterComponent } from "../../DataFilterComponent";
import { RegionsDetailsComponent } from "./components";
import {
  getSortableCheckbox,
  getSortableEditable,
  SortableTable,
} from "../../SortableTable";

import "./RegionsTabPanel.css";
@track(
  (props) => {
    return {
      event_type: AnalyticsEventType.SUBNAV,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }settings.regions`,
    };
  },
  { dispatchOnMount: true }
)
export class RegionsTabPanelView extends React.Component<
  LMI.IRegionsTPProps,
  LMI.IRegionsTPState
> {
  constructor(props) {
    super(props);
    this.state = {
      searchFilter: null,
      editRegion: null,
    };
  }

  render() {
    const props = this.props;

    if (props.hasErrors) {
      console.log("RegionsTabPanel Data errors:", props.message);
    }
    if (!props.stre || props.loading) {
      return <Loading />;
    }

    const stores = Session.get("stores");
    const currentStoreName =
      props.storeId &&
      stores.find((st) => st.store_id === props.storeId).store_name;

    const canView = hasPermission(
      this.props.permissions,
      Permission.ADMIN_VIEW_REGION,
      Session.get("isSuperUser")
    );
    const canEdit = hasPermission(
      this.props.permissions,
      Permission.ADMIN_ADD_REGION,
      Session.get("isSuperUser")
    );

    let regions: any[] =
      props.stre.organization && props.stre.organization.regions
        ? this.formatRegionData(props.stre.organization.regions, props.viewType)
        : [];

    if (this.state.searchFilter) {
      regions = this.searchFilter(this.state.searchFilter, regions);
    }

    const modalProps: LMI.IRegionsDetailsProps = {
      region: this.state.editRegion,
      onSave: (data, regionId) => this.updateRegion(data, regionId),
      onCreate: (data) => this.createRegion(data),
      closeCallback: () => {
        if (!this.state.editRegion) {
          props.triggerUABCallback(false);
        } else {
          this.setState({
            editRegion: null,
          });
        }
      },
    };

    return (
      <div className="regions-tab-panel panel-content">
        <UniversalActionModalComponent
          toggleUABCallback={props.toggleUABCallback}
          universalActionCallback={() => props.triggerUABCallback(false)}
          contentComponent={() => this.getModalForm(modalProps)}
          headerText="Add a Region"
          size="small"
        />
        {this.state.editRegion && (
          <ModalComponent
            headerText={this.state.editRegion.name}
            shouldBeOpen={!!this.state.editRegion}
            onClose={(evt, data) => {
              this.setState({ editRegion: null });
            }}
            size="small"
            className="region-details"
            contentComponent={() => this.getModalForm(modalProps)}
          />
        )}
        <div className="sortable-container">
          {regions.length > 0 || this.state.searchFilter ? (
            <div className="sortable-filter">
              <DataFilterComponent
                label="Filter Results"
                searchFilter={this.state.searchFilter}
                onChange={(p, { value }) => {
                  this.setState({
                    searchFilter: value,
                  });
                }}
              />
            </div>
          ) : (
            <span />
          )}
          <div className="sortable-content">
            {canView && (
              <SortableTable
                filter={this.state.searchFilter}
                message={
                  currentStoreName +
                  " currently has no regions setup" +
                  (canEdit
                    ? ". Use the [+] button on the top right to add a new region."
                    : ".")
                }
                tableData={this.getTableData(regions, props.viewType)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  getModalForm(props) {
    return <RegionsDetailsComponent {...props} />;
  }

  searchFilter(search: string, data: any[]) {
    const srch = search.toLowerCase();
    return data.filter((service) => {
      return (
        service.name.toLowerCase().includes(srch) ||
        (service.regionId &&
          service.regionId.toString().toLowerCase().includes(srch))
      );
    });
  }

  getTableData(regions, viewType) {
    const headers: any = [
      {
        id: "tools",
        label: "",
        sortable: false,
        collapsing: true,
      },
      {
        id: "name",
        label: "Name",
        sortable: true,
      },
      {
        id: "active",
        label: "Active?",
        sortable: false,
        collapsing: true,
      },
    ];

    const sortableTableData: LMI.ITableData = {
      headers,
      body: {
        rows: [],
      },
    };
    return Object.assign({}, sortableTableData, {
      body: {
        rows: regions,
      },
    });
  }

  formatRegionData(regions: any[], viewType: string): LMI.IRegionTableRow[] {
    return regions.map(
      (region): LMI.IRegionTableRow => {
        return {
          tools: {
            component: getSortableEditable,
            editable: hasPermission(
              this.props.permissions,
              Permission.ADMIN_EDIT_REGION,
              Session.get("isSuperUser")
            ),
            editcallback: () => {
              this.setState({
                editRegion: region,
              });
            },
          },
          name: region.name,
          active: {
            value: region.is_active,
            component: getSortableCheckbox,
            disabled: !hasPermission(
              this.props.permissions,
              Permission.ADMIN_REMOVE_REGION,
              Session.get("isSuperUser")
            ),
            callback: (data) => this.toggleActive(data, region.id),
          },
        };
      }
    );
  }

  toggleActive(formData: any, regionId: number) {
    const data = {
      is_active: formData.state,
    };
    this.updateRegion(data, regionId);
  }

  createRegion(formData: any) {
    restAPI({
      endpointName: "storeCreateOrgRegion",
      urlArgs: [this.props.stre.organization.id],
      data: formData,
      callback: (err, res) => {
        if (err) {
          this.props.addAlert({
            type: "danger",
            message: err.reason.response.data.message,
            timeout: 3000,
          });
        }
        this.props.refetch();
        this.props.triggerUABCallback(false);
      },
    });
  }

  updateRegion(formData: any, regionId: number) {
    restAPI({
      endpointName: "storeUpdateOrgRegion",
      urlArgs: [this.props.stre.organization.id, regionId],
      data: formData,
      callback: (err, res) => {
        if (err) {
          this.props.addAlert({
            type: "danger",
            message: err.reason.response.data.message,
            timeout: 3000,
          });
        }
        this.props.refetch();
        this.setState({
          editRegion: null,
        });
      },
    });
  }
}

const useGQL = (props) => {
  const gql = useQuery(gqlQueries.dealership.listsRegions, {
    variables: {
      storeId: parseInt(props.storeId, 10),
    },
    fetchPolicy: "network-only",
  });
  return gql;
};

export const RegionsTabPanel = (props) => {
  const globals = useGlobals(props) as any;
  const { loading, error, data, refetch } = useGQL(props);
  const showUABOn = [
    { viewType: "lists-component", permissions: [Permission.ADMIN_ADD_REGION] },
  ];
  return (
    <WithUAB showUABOn={showUABOn}>
      <RegionsTabPanelView
        {...{
          ...globals,
          loading,
          hasErrors: !!error,
          message: error,
          stre: data?.store,
          refetch,
        }}
      />
    </WithUAB>
  );
};

export default RegionsTabPanel;
