import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import moment from "moment";
// components
import { ColumnDirective, ColumnsDirective, GridComponent, SelectionSettingsModel } from "@syncfusion/ej2-react-grids";
// node_modules
import { gqlQueries } from "gql-imports";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { formatCurrency, orderByProperty } from "../../../../../../utils/functions";
import { Loading } from "../../../../../../components/Loading";
import { formatPhone } from "client/utils/functions";
import { Header, Message } from "semantic-ui-react";

export class LeadsGridComponentView extends React.Component<LMI.IInventoryLeadsTabProps, LMI.IInventoryLeadsTabState> {
	grid: GridComponent;
	cellSelectionSettings: SelectionSettingsModel = { cellSelectionMode: "Box", type: "Single", mode: "Both" };

	constructor(props) {
		super(props);
		this.state = {
			selectedRowIndex: null,
			alert: null
		};
	}

	format(leads: any): LMI.IInventoryLeadDetailGQL[] {
		return orderByProperty(leads, "lead_created_at", "DESC").map(item => {
			const donotcontactphone =
				this.props.do_not_contact && this.props.do_not_contact.length && this.props.do_not_contact.find(c => c.value === item.customer_phone);
			const donotcontactemail =
				this.props.do_not_contact && this.props.do_not_contact.length && this.props.do_not_contact.find(c => c.value === item.customer_email);

			return {
				salesperson: item.salesperson.full_name,
				name: item.customer_name,
				email: donotcontactemail || donotcontactphone ? "XXX@XXX.XXX" : item.customer_email,
				phone: donotcontactemail || donotcontactphone ? "XXX-XXX-XXXX" : formatPhone(item.customer_phone),
				age: item.age_in_days,
				created: moment(parseInt(item.lead_created_at, 10)).isValid() ? moment(parseInt(item.lead_created_at, 10)).format("L") : "",
				price: item.price ? formatCurrency(item.price, 2) : "",
				views: item.number_of_views
			};
		});
	}

	cellSelection = (args: any) => {
		args.cancel = true;
	};

	getWrapSettings: any = () => {
		return { wrapMode: "Content" };
	};

	render() {
		const { dealer_leads } = this.props;
		const readyLeads = dealer_leads ? this.format(dealer_leads) : null;

		if (!readyLeads) {
			return <Loading />;
		}

		return (
			<>
				{readyLeads.length == 0 ? (
					<Message>
						<Header color="grey">There are currently no leads for this vehicle.</Header>
					</Message>
				) : (
					<GridComponent
						className="grid-component"
						enableHover={false}
						allowSelection={false}
						allowSorting={true}
						selectionSettings={this.cellSelectionSettings}
						cellSelecting={this.cellSelection}
						dataSource={readyLeads}
						allowGrouping={false}
						allowPaging={false}
						allowFiltering={false}
						allowTextWrap={true}
						textWrapSettings={this.getWrapSettings}
						ref={g => (this.grid = g)}
						height="100%"
						gridLines="Both"
					>
						<ColumnsDirective>
							<ColumnDirective field="salesperson" headerText="Salesperson" textAlign="Left" />
							<ColumnDirective field="name" headerText="Name" textAlign="Left" />
							<ColumnDirective field="email" headerText="Email" textAlign="Left" />
							<ColumnDirective field="phone" headerText="Phone" textAlign="Left" />
							<ColumnDirective field="age" headerText="Age" textAlign="Left" />
							<ColumnDirective field="created" headerText="Created" textAlign="Left" />
							<ColumnDirective field="price" headerText="Price" textAlign="Left" />
							<ColumnDirective field="views" headerText="Views" textAlign="Left" />
						</ColumnsDirective>
					</GridComponent>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const InventoryLeads = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IInventoryLeadsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.inventoryLeads, {
		options: (props: any) => {
			return {
				variables: {
					store_id: parseInt(props.storeId, 10),
					inventory_item_id: props.inventory_item_id
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, dealer_leads, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				dealer_leads,
				refetch
			};
		}
	}),
	graphql<LMI.IDoNotContactProps, any, any, ClassAttributes<any>>(gqlQueries.donotcontact, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, do_not_contact, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				do_not_contact,
				refetchDoNotContact: refetch
			};
		}
	})
)(LeadsGridComponentView) as React.ComponentType<any>;

export default InventoryLeads;
