import { restAPI } from "../../../../../utils/rest";
import { gqlQueries } from "gql-imports";
import { Loading } from "client/components/Loading";
import { FileUploadComponent } from "client/pages/admin/components/FileUploadComponent";

import { stringToInt } from "client/utils/functions";
import { hasPermission } from "client/utils/userAccess";
import { AnalyticsEventType, Permission } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import {
  Card,
  Dimmer,
  Divider,
  Grid,
  Header,
  Icon,
  List,
  Loader,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import { enableUABCallback, triggerUABCallback } from "api/redux/actions";
import { withUAB } from "../../../../../pages/WithUAB";
import { FileCard } from "../../FileCardComponent";
import track from "react-tracking";
import { Session } from "client/utils/session";

import "./BusinessDocumentsTabPanel.css";

@track(
  (props) => {
    return {
      event_type: props.tracking_path
        ? AnalyticsEventType.SUBNAV
        : AnalyticsEventType.NAVIGATION,
      event_subtype: `${
        props.tracking_path ? props.tracking_path + "." : ""
      }business-documents`,
    };
  },
  { dispatchOnMount: true }
)
export class BusinessDocumentsTabPanelView extends React.Component<
  LMI.IBusinessDocumentsTPProps,
  any
> {
  constructor(props) {
    super(props);

    this.state = {
      savingDocument: false,
    };
  }
  render() {
    const props = this.props;

    if (props.hasErrors) {
      console.log("VendorTabPanel Data errors:", props.error);
      return (
        <Message
          warning
          header={props.error}
          content="Please contact your account adminstrator"
        />
      );
    }

    if (props.loading) {
      return <Loading />;
    }

    const isDealer = props.viewType.includes("dealerships");
    const documents: LMI.IVendorDocument[] = isDealer
      ? props.approved_vendor_detail && props.approved_vendor_detail.documents
      : props.admin_vendor && props.admin_vendor.documents;

    const requiredList = props.vendor_missing_documents;
    const missingDocs =
      props.document_types &&
      props.document_types
        .filter((doc) => {
          const hasDocument = documents
            ? documents.find((d) => d.document_type_id === doc.id)
            : null;
          if (doc.one_active_max && !hasDocument) {
            return doc;
          }
        })
        .map((doc) => {
          return doc;
        });

    const one_max =
      props.document_types &&
      props.document_types
        .filter((doc) => {
          const hasDocument = documents
            ? documents.find((d) => d.document_type_id === doc.id)
            : null;
          if (doc.one_active_max && hasDocument) {
            return doc;
          }
        })
        .map((doc) => {
          return doc;
        });

    const fileProps = {
      docs: one_max,
      filesToAccept: "image/png, image/jpeg, application/pdf",
      onSubmit: this.onSubmitFileHandler.bind(this),
      onClose: () => {
        this.props.universalActionCallback();
      },
      allowName: true,
      documentmode: true,
    };

    return (
      <div className="business-documents-tab-panel panel-content">
        {props.viewType !== "dealerships sub-component" ? (
          <div style={{ textAlign: "right" }}>
            <Modal
              className="business-documents-modal"
              open={this.props.toggleUABCallback}
              closeIcon="close"
              onClose={(evt, data) => {
                this.props.universalActionCallback();
              }}
            >
              <Header icon="plus" content="Add Document" />
              <Modal.Content>
                <FileUploadComponent {...fileProps} />
              </Modal.Content>
            </Modal>
          </div>
        ) : (
          ""
        )}
        <Grid>
          <Grid.Column width={requiredList.length ? 12 : 16}>
            {documents && documents.length > 0 ? (
              <Card.Group
                stackable
                itemsPerRow={
                  documents.length % 2 === 0
                    ? documents.length >= 4
                      ? 4
                      : 2
                    : documents.length >= 3
                    ? 3
                    : 2
                }
              >
                {documents &&
                  documents.map(
                    (document: LMI.IAVDocumentGQL, index: number) => {
                      const fileprops: LMI.IFileCardProps = {
                        type: document.mime_type,
                        id: document.document_type_id,
                        name: document.description,
                        url: document.document_url,
                        classes: "documents-card",
                        hidetrash: this.props.viewType.includes("dealerships")
                          ? true
                          : false,
                        expiration: document.expiration_date,
                        onDelete: () => {
                          this.removeDocument(document.id);
                        },
                      };
                      return <FileCard key={index} {...fileprops} />;
                    }
                  )}
              </Card.Group>
            ) : (
              <Message
                info
                content="There are currently no documents uploaded by this store."
              />
            )}
          </Grid.Column>
          {missingDocs.length && (
            <Grid.Column width={4}>
              <Segment>
                <Header as="h4">
                  <Icon name="exclamation circle" color="red" />
                  <Header.Content>Required Documents</Header.Content>
                </Header>
                <Divider />
                <List divided relaxed>
                  {missingDocs &&
                    missingDocs.map((doc) => {
                      return (
                        <List.Item key={doc.id}>
                          <List.Icon
                            name="file alternate outline"
                            size="large"
                            verticalAlign="middle"
                          />
                          <List.Content>
                            <List.Header>{doc.name}</List.Header>
                            <List.Description>
                              Missing this document
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      );
                    })}
                </List>
              </Segment>
            </Grid.Column>
          )}
          {this.state.savingDocument && (
            <Dimmer active>
              <Loader size="large">Saving Document</Loader>
            </Dimmer>
          )}
        </Grid>
      </div>
    );
  }

  componentDidMount() {
    // Should we show the UAB to this user?
    if (
      !hasPermission(
        this.props.permissions,
        Permission.ADMIN_ADD_VENDOR_DOCUMENT,
        Session.get("isSuperUser")
      )
    ) {
      this.props.disableUAB();
    }
  }

  onSubmitFileHandler(imageObject) {
    this.addDocument(imageObject);
    this.props.universalActionCallback();
  }

  addDocument(formData) {
    const docPackage: any = {
      document: formData.image,
      description: formData.file_name,
      document_type_id: formData.document_type_id,
    };
    if (formData.expiration_date) {
      docPackage.expiration_date = formData.expiration_date;
    }

    this.setState({ savingDocument: true });

    restAPI({
      endpointName: "addDocument",
      urlArgs: [Number(this.props.storeId)],
      data: docPackage,
      callback: (err, res) => {
        if (err) {
          console.log("There was an error while adding the new document", err);
        } else {
          this.props.refetch();
          this.props.refreshBadge();
          this.setState({ savingDocument: false });
        }
      },
    });
  }

  removeDocument(documentID: number) {
    restAPI({
      endpointName: "removeDocument",
      urlArgs: [Number(this.props.storeId), documentID],
      data: null,
      callback: (err, res) => {
        if (err) {
          console.log("There was an error while removing the document", err);
        } else {
          this.props.refreshBadge();
          this.props.refetch();
        }
      },
    });
  }
}

const mapStateToProps = (state: any) => {
  return {
    storeId: state.app.admin.storeId,
    permissions: state.app.admin.permissions,
    toggleUABCallback: state.app.admin.toggleUABCallback,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    universalActionCallback: () => {
      dispatch(triggerUABCallback(false));
    },
    disableUAB: () => {
      dispatch(enableUABCallback(false));
    },
  };
};

const showUABOn = [{ viewType: "vendors" }];
const BusinessDocumentsTabPanelViewUAB: any = withUAB(
  BusinessDocumentsTabPanelView,
  showUABOn
);

export const BusinessDocumentsTabPanel = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<LMI.IDocumentTypesGQL, any, any, ClassAttributes<any>>(
    gqlQueries.documentTypes,
    {
      options: (props: any) => {
        return {
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, document_types, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, error };

        return {
          document_types,
          refetchDocTypes: refetch,
        };
      },
    }
  ),
  graphql<LMI.IAdminVendorDocsQueryProps, any, any, ClassAttributes<any>>(
    gqlQueries.vendor.admin.docs,
    {
      skip: (ownProps: any) => ownProps.viewType.includes("dealerships"),
      options: (props: any) => {
        const vendorId: number =
          props.viewType === "dealerships sub-component"
            ? parseInt(props.vendorId, 10)
            : parseInt(props.storeId, 10);
        return {
          variables: {
            vendorId,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, admin_vendor, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, error };

        return {
          admin_vendor,
          refetch,
        };
      },
    }
  ),
  graphql<LMI.IVendorAllDocumentsGQL, any, any, ClassAttributes<any>>(
    gqlQueries.vendor.documents,
    {
      skip: (ownProps: any) =>
        ownProps.viewType.includes("vendors") || !ownProps.vendorId,
      options: (props: any) => {
        return {
          variables: {
            vendorId: stringToInt(props.vendorId),
            storeId: stringToInt(props.storeId),
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, approved_vendor_detail, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, error };

        return {
          approved_vendor_detail,
          refetch,
        };
      },
    }
  ),
  graphql<LMI.IMissingDocsGQL, any, any, ClassAttributes<any>>(
    gqlQueries.vendor.missingDocuments,
    {
      skip: (ownProps: any) => !ownProps.storeId,
      options: (props: any) => {
        return {
          variables: {
            vendorId: props.storeId,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({
        data: { error, loading, vendor_missing_documents, refetch },
      }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, error };

        return {
          vendor_missing_documents,
          queryComplete: true,
          refetchMissing: refetch,
        };
      },
    }
  )
)(BusinessDocumentsTabPanelViewUAB) as React.ComponentType<any>;

export default BusinessDocumentsTabPanel;
