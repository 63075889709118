import * as React from "react";
import { Loader, Popup } from "semantic-ui-react";

export class PhaseTimer extends React.Component<LMI.IPhaseTimerProps, LMI.IPhaseTimerState> {
	phaseInterval: any;

	constructor(props: LMI.IPhaseTimerProps) {
		super(props);

		this.state = {
			loading: false,
			phase_elapsed_time: null
		};
	}

	componentDidMount() {
		if (this.props.id) {
			this.getPhaseTimer();
		}
	}

	componentWillUnmount(): void {
		clearInterval(this.phaseInterval);
	}

	getPhaseTimer() {
		const { phase_timer_running_since_ms } = this.props;

		const now = new Date();

		const elapsed = now.getTime() - phase_timer_running_since_ms;

		this.setState({
			phase_elapsed_time: elapsed
		});

		const interval = 1000;
		this.phaseInterval = setInterval(() => {
			if (phase_timer_running_since_ms) {
				this.setState(prevState => ({ phase_elapsed_time: prevState.phase_elapsed_time + interval }));
			}
		}, interval);
	}

	calculateElapsedTime(elapsedTime) {
		let ms = elapsedTime;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = Math.floor(ms / 24);
		return {
			days: days.toString().padStart(2, "0"),
			hours: hours.toString().padStart(2, "0"),
			minutes: minutes.toString().padStart(2, "0"),
			seconds: seconds.toString().padStart(2, "0")
		};
	}

	render() {
		const { phase_elapsed_time } = this.state;
		const { phase_timer_running_since_ms, phase } = this.props;

		if (phase_timer_running_since_ms && !phase_elapsed_time) {
			return <Loader className={`loader active right`} size="small" />;
		} else {
			const { days, hours, minutes, seconds } = this.calculateElapsedTime(phase_elapsed_time);
			return (
				<Popup
					trigger={
						<span>
							{days}:{hours}:{minutes}
						</span>
					}
					content={
						<div>
							<div>
								<strong>{phase && phase.final_stage ? "Time Since Disposition:" : "Current Stage Time:"}</strong>
							</div>
							{`${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`}
						</div>
					}
					position="bottom left"
					size="mini"
					inverted
				/>
			);
		}
	}
}
