import { graphql } from "@apollo/react-hoc";
import { Form } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Button, Dropdown } from "semantic-ui-react";
import { restAPI } from "../../../../../../utils/rest";

export class StageDestinationView extends React.Component<LMI.IStageDestinationProps, LMI.IStageDestinationState> {
	constructor(props: LMI.IStageDestinationProps) {
		super(props);

		this.state = {
			value: null
		};
	}

	handleChange = (event, { value }) => {
		this.setState({ value });
	};

	handleSubmit = () => {
		const { value } = this.state;
		const { phase_id, storeId } = this.props;

		const data = [{ id: phase_id, stage_destinations: value }];

		restAPI({
			endpointName: "modify_store_phases",
			urlArgs: [storeId],
			data,
			callback: async error => {
				let alert;
				if (error) {
					alert = { type: "danger", message: this.props.onHandleErrorResponse(error) };
					this.props.onSendAlert(alert);
				}
				await this.props.refetchAssignedPhases({ currentPhaseId: phase_id });
				this.props.onClose();
			}
		});
	};

	render() {
		const { value } = this.state;
		const { dealer_phases, assigned_phases } = this.props;

		const assignedValue = assigned_phases ? assigned_phases.map(a => a.id) : [];
		const options =
			dealer_phases &&
			dealer_phases.map(p => {
				return { key: p.id, text: p.name, value: p.id };
			});

		return [
			<Form noValidate>
				<div>Select the destination(s) to assign to this Stage.</div>
				<div id="phaseBody" className="recon-form">
					<Dropdown
						className="select"
						value={value ? value : assignedValue}
						placeholder="Destination Stage"
						loading={!assigned_phases}
						fluid
						multiple
						search
						selection
						options={options}
						onChange={this.handleChange}
					/>
				</div>
			</Form>,
			<div className="cta-footer">
				<Button className="submit" id="save" positive content="Save" onClick={this.handleSubmit} />
			</div>
		];
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const StageDestination = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.stageEditorAssignments, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					stageId: props.phase_id
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, stage_workflow_admin, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				assigned_phases: stage_workflow_admin.stage.target_stages,
				refetchAssignedPhases: refetch
			};
		}
	})
)(StageDestinationView) as React.ComponentType<any>;

export default StageDestination;
