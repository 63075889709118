import * as React from "react";
import { useEffect, useState } from "react";
import { calculateElapsedDHMS, getWarningColor } from "../../../../../../utils/functions";
import { Popup, Progress, SemanticCOLORS } from "semantic-ui-react";

export const RowTimer = props => {
	const { settings, created_at_raw, is_completed, percent_complete } = props;
	const [age, setAge] = useState(+created_at_raw);
	const barColor = getWarningColor({ settings, created_at: +age, is_completed }) as SemanticCOLORS;

	useEffect(() => {
		const interval = setInterval(() => {
			setAge(age => age + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<span>
			<Popup
				trigger={
					<Progress
						label={calculateElapsedDHMS({ age, label: false, useFull: false })}
						className={`progress-bar`}
						percent={Math.floor(percent_complete)}
						color={barColor}
					/>
				}
				content={<span>{calculateElapsedDHMS({ age, label: true, useFull: true })}</span>}
				position="top center"
				size="tiny"
				on="hover"
				inverted
			/>
		</span>
	);
};
