import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { addAlert } from "api/redux/actions";

import { Dropdown, DropdownProps, Icon, Popup } from "semantic-ui-react";
import { ModalComponent } from "../../ModalComponent";
import { InspectionTemplateForm } from "../../InspectionTemplates";
import { hasPermission } from "client/utils/userAccess";
import { Permission } from "loopmein-shared";
import { Session } from "client/utils/session";

interface TemplateSelectorProps extends LMI.IReconInspectionItemsFormProps, DropdownProps {
	customProps?: DropdownProps;
}

export class InspectionTemplateSelectorView extends React.Component<TemplateSelectorProps, LMI.InspectionTemplateSelectorState> {
	constructor(props: LMI.IReconInspectionItemsFormProps) {
		super(props);

		this.state = {
			new_template: null,
			edit_template: null
		};
	}

	static getDerivedStateFromProps(nextProps: TemplateSelectorProps, prevState: LMI.InspectionTemplateSelectorState) {
		// update current edit_template if open
		if (nextProps.templates && nextProps.templates.length && prevState.edit_template) {
			return {
				edit_template: nextProps.templates.find(template => template.id == prevState.edit_template.id)
			};
		} else return null;
	}

	formatTemplateOptions() {
		const { templates } = this.props;
		if (templates && templates.length > 0)
			return templates.map((s, key) => ({
				key,
				text: s.name,
				value: s.id,
				icon: { name: "paste", color: "blue" }
			}));
		else return null;
	}

	onEditTemplate(templateId: number) {
		const { templates } = this.props;
		const edit_template = templates.find(t => t.id == templateId);
		if (edit_template) this.setState({ edit_template });
	}

	onSaveAs() {
		this.setState({
			new_template: {
				name: "",
				description: "",
				inspection_template_items: this.props.inspection.inspection_line_items.map(item => {
					const { id, inspection_item_option_id, inspection_line_item_section_id } = item;
					return {
						id,
						inspection_item_option_id,
						inspection_line_item_section_id
					};
				})
			}
		});
	}

	getModalProps(): LMI.IModalProps {
		const { templates, inspection_item_type_options, refetchTemplates, load_template } = this.props;
		const { new_template, edit_template } = this.state;
		const template = new_template ? new_template : edit_template ? edit_template : null;

		return {
			size: "small",
			shouldBeOpen: new_template || edit_template ? true : false,
			headerText: new_template ? "Save Current Inspection as Template" : "Edit Template",
			onClose: () => this.setState({ new_template: null, edit_template: null }),
			contentComponent: () => {
				if (new_template || edit_template) {
					return (
						<InspectionTemplateForm
							types={inspection_item_type_options}
							templates={templates}
							template={template}
							template_items={new_template ? new_template.inspection_template_items : edit_template ? edit_template.inspection_template_items : []}
							on_close={() => this.setState({ new_template: null, edit_template: null }, () => refetchTemplates())}
							on_update={() => refetchTemplates()}
							enable_loading={edit_template ? true : false}
							on_load_template={template => this.setState({ new_template: null, edit_template: null }, () => load_template(template))}
						/>
					);
				}
			}
		};
	}

	render() {
		const { loading, hasErrors, inspection, customProps, permissions } = this.props;
		const templates = this.formatTemplateOptions();

		const hasSavedTemplates = templates && templates.length > 0;
		const canSaveInspectionItems =
			inspection && inspection.inspection_line_items.length > 0 && hasPermission(permissions, Permission.ADMIN_INSPECTIONS, Session.get("isSuperUser"));

		if (!hasErrors && !loading)
			return [
				<span key="newTemplateModak">
					<ModalComponent {...this.getModalProps()} />
				</span>,
				<Popup
					key="templateSelector"
					inverted
					position="top right"
					content="Inspection Templates"
					trigger={
						<Dropdown floating button labeled icon={{ name: "th list" }} className="options-button template-select" direction="left" {...customProps}>
							<Dropdown.Menu className="action-menu">
								{canSaveInspectionItems && <Dropdown.Header>INSPECTION TEMPLATES</Dropdown.Header>}
								{canSaveInspectionItems && (
									<Dropdown.Item onClick={() => this.onSaveAs()}>
										<Icon name="save outline" color="green" /> Save As...
									</Dropdown.Item>
								)}
								{hasSavedTemplates && <Dropdown.Header>SAVED TEMPLATES</Dropdown.Header>}
								{hasSavedTemplates &&
									templates.map(option => <Dropdown.Item key={option.value} {...option} onClick={() => this.onEditTemplate(option.value)} />)}
							</Dropdown.Menu>
						</Dropdown>
					}
				/>
			];
		else return <span />;
	}
}

const mapStateToProps = state => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const InspectionTemplateSelector = compose(connect(mapStateToProps, mapDispatchToProps))(InspectionTemplateSelectorView);
export default InspectionTemplateSelector;
