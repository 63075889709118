import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";

import { gqlQueries } from "gql-imports";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Grid, Loader } from "semantic-ui-react";
import { LightboxComponent } from "../../../../../../components/ImageHandling/LightboxComponent";
import { handleErrorResponse, orderByProperty } from "../../../../../../utils/functions";
import DocumentForm from "./AddLeaseReturnDocument";
import { UniversalActionButton } from "../../../UniversalActionButton";
import { ModalComponent } from "../../../ModalComponent";
import { addAlert } from "api/redux/actions";
import { restAPI } from "../../../../../../utils/rest";

import "./DocumentTab.css";

export class DocumentComponentView extends React.Component<LMI.ILRDocumentViewProps, LMI.ILRDocumentState> {
	private _confirmTimeout: number = 3000;
	private _countdown: number = this._confirmTimeout / 1000;

	constructor(props: LMI.ILRDocumentViewProps) {
		super(props);
		this.state = {
			confirm_delete_id: null,
			lightbox_open: false,
			photo_index: null,
			detail_id: null,
			data: [],
			countdown: null,
			loading: false,
			modal_open: false
		};
	}

	static format(data) {
		const lease_return_photos = [...data];
		return orderByProperty(lease_return_photos, "sort_order", "ASC")
			.filter(d => d.url.indexOf("default_vehicle_image.png") < 0)
			.map(d => {
				return {
					id: d.id,
					url: `${d.url}`
				};
			});
	}

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
		if (type === "danger") {
			console.log(`Error: ${message}`);
		}
	}

	getModal = () => {
		const { modal_open } = this.state;

		const documentProps = {
			headerText: "Add Document",
			refetchDocuments: this.props.refetchDocuments,
			onClose: () => this.setState({ modal_open: false }),
			closeModalCallback: () => this.setState({ modal_open: false }),
			...this.props
		};

		return (
			<ModalComponent
				headerText={documentProps.headerText}
				size="small"
				shouldBeOpen={modal_open}
				onClose={() => {
					this.setState({ modal_open: false });
				}}
				className="document-item-modal"
				contentComponent={() => <DocumentForm {...documentProps} />}
			/>
		);
	};

	getLightBox = () => {
		const { lightbox_open, photo_index, data } = this.state;
		const photos = data.map(d => d.url);
		const lightboxProps = {
			photos,
			photo_index,
			onCloseLightbox: () => this.setState({ lightbox_open: false, photo_index: null })
		};

		return lightbox_open && <LightboxComponent {...lightboxProps} />;
	};

	removeConfirm = id => {
		if (this.state.confirm_delete_id) {
			this.removeDocument(id);
		} else {
			let countdown = this._countdown;
			let countdownInterval;
			this.setState({ confirm_delete_id: id, countdown }, () => {
				setTimeout(() => this.setState({ confirm_delete_id: null }), this._confirmTimeout);
				countdownInterval = setInterval(() => {
					if (countdown > 0) {
						countdown--;
						this.setState({ countdown });
					} else clearInterval(countdownInterval);
				}, 1000);
			});
		}
	};

	removeDocument = id => {
		this.setState({ loading: true });
		const { confirm_delete_id } = this.state;
		const { detail, storeId } = this.props;

		if (id === confirm_delete_id) {
			restAPI({
				endpointName: "deleteLeaseReturnDocument",
				urlArgs: [storeId, detail.detail_id, confirm_delete_id],
				data: null,
				callback: async (error, result) => {
					if (error) {
						this.sendAlert({ type: "danger", message: handleErrorResponse({ error }) });
					} else {
						await this.props.refetchDocuments();
						this.setState({ confirm_delete_id: null, loading: false });
					}
				}
			});
		}
	};

	render() {
		const { loading, can_edit, lease_return, store_settings, onSetHeaderColor } = this.props;
		const { data, confirm_delete_id, countdown, modal_open } = this.state;

		if (loading || !data) return <Loader active />;

		const UABProps: LMI.IUABProps = {
			subUniversalActionCallback: () => this.setState({ modal_open: true }),
			size: "small",
			tip: "Add a new Document"
		};

		onSetHeaderColor({ age: lease_return.age, store_settings, is_completed: !!lease_return.completed_at });

		if (data.length > 0) {
			return (
				<>
					{this.getLightBox()}
					<div id="Document" className="document-panel">
						{modal_open && this.getModal()}
						<div className="documents">
							<Grid className="list">
								{data.map((item, index) => {
									return (
										<div className="item" key={index}>
											<div
												className={`image`}
												onClick={() => {
													if (!this.state.confirm_delete_id) {
														this.setState({ lightbox_open: true, photo_index: index });
													}
												}}>
												<div className={`mask ${confirm_delete_id === item.id ? "confirming" : ""} ${!can_edit ? "disabled" : ""}`} />
												<img src={`${item.url}?&w=150&ch=Save-Data&auto=compress`} />
											</div>
											{can_edit && !confirm_delete_id && (
												<i
													className={`no-select icon ${confirm_delete_id === item.id ? "trash alternate" : "delete"}`}
													onClick={this.removeConfirm.bind(this, item.id)}
												/>
											)}
											{confirm_delete_id === item.id && (
												<span className="confirm no-select" onClick={this.removeDocument.bind(this, item.id)}>
													<em>Confirm? {countdown}</em>
												</span>
											)}
										</div>
									);
								})}
							</Grid>
						</div>
					</div>
					<div className="fab-footer">
						<UniversalActionButton {...UABProps} />
					</div>
				</>
			);
		} else {
			return (
				<>
					<div className="noresults" key={0}>
						There are no existing documents for this vehicle.
					</div>
					{modal_open && this.getModal()}
					<div className="fab-footer">
						<UniversalActionButton {...UABProps} />
					</div>
				</>
			);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.lease_return) {
			if (nextProps.lease_return !== prevState.data) {
				const data = DocumentComponentView.format(nextProps.lease_return.lease_return_photos);
				return { lease_return_id: nextProps.lease_return.id, data };
			}
		}
		return null;
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const DocumentComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.leaseReturnDetail, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					leaseReturnId: parseInt(props.detail.detail_id, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, lease_return_detail, refetch, fetchMore } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				lease_return: lease_return_detail.lease_return,
				refetchDocuments: refetch
			};
		}
	})
)(DocumentComponentView) as React.ComponentType<any>;

export default DocumentComponent;
