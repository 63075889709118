import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";

import { gqlQueries } from "gql-imports";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Grid, Loader, Popup, Radio } from "semantic-ui-react";
import { handleErrorResponse, orderByProperty, RelativeDatePipe } from "../../../../../../utils/functions";
import { addAlert } from "api/redux/actions";
import { restAPI } from "../../../../../../utils/rest";

import "./ChecklistTab.css";

export class ChecklistComponentView extends React.Component<LMI.ILRChecklistViewProps, LMI.ILRChecklistState> {
	constructor(props: LMI.ILRChecklistViewProps) {
		super(props);
		this.state = {
			detail_id: null,
			data: [],
			completed: false
		};
	}

	static format(data) {
		const checklist = [...data];
		return orderByProperty(checklist, "sort_order", "ASC");
	}

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
		if (type === "danger") {
			console.log(`Error: ${message}`);
		}
	}

	handleItemToggle = ({ checked, index, item }) => {
		if (!this.props.can_edit) return false;

		const items = [...this.state.data];
		items[index].value = checked;
		this.setState({ data: items }, () => {
			restAPI({
				endpointName: "updateLeaseReturnChecklistItem",
				urlArgs: [this.props.storeId, this.props.detail.detail_id, item.id],
				data: { value: checked },
				callback: (error, result) => {
					if (error) {
						this.sendAlert({
							type: "error",
							message: handleErrorResponse({ error, message: "Could not update toggle." })
						});
					} else {
						this.props.refetchChecklist();
						this.props.onCompleteChecklistItem(items.filter(c => !c.value).length);
					}
				}
			});
		});
	};

	render() {
		const { loading, can_edit, lease_return, store_settings, onSetHeaderColor } = this.props;
		const { data } = this.state;

		if (loading || !data) return <Loader active />;

		onSetHeaderColor({ age: lease_return.age, store_settings, is_completed: !!lease_return.completed_at });

		if (data.length) {
			return [
				<div id="Checklist" className="checklist-panel">
					<div className="checklist">
						<Grid className="list">
							{data.map((item, index) => {
								return (
									<Grid.Row columns={2} key={index} className="item">
										<Grid.Column width={14}>
											<div>
												<Popup trigger={<b>{item.name}</b>} content={item.description ? item.description : item.name} size="tiny" inverted />
											</div>
											{item.updated_by_user && item.value && (
												<div className="who-when">
													Completed by {item.updated_by_user && `${item.updated_by_user.full_name} ${RelativeDatePipe(item.updated_at, false, true)}`}
												</div>
											)}
										</Grid.Column>
										<Grid.Column width={2} textAlign="right">
											<Radio
												toggle
												disabled={!can_edit}
												checked={item.value}
												onClick={(e, data) => {
													return this.handleItemToggle({ checked: data.checked, index, item });
												}}
											/>
										</Grid.Column>
									</Grid.Row>
								);
							})}
						</Grid>
					</div>
				</div>
			];
		} else {
			return (
				<div className="noresults" key={0}>
					There are no existing checklist items for this vehicle.
				</div>
			);
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.lease_return) {
			if (nextProps.lease_return !== prevState.data) {
				const data = ChecklistComponentView.format(nextProps.lease_return.lease_return_checklist_items);
				return { lease_return_id: nextProps.lease_return.id, data };
			}
		}
		return null;
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const ChecklistComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.ILRChecklistViewProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.leaseReturnDetail, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					leaseReturnId: parseInt(props.detail.detail_id, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, lease_return_detail, refetch, fetchMore } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				lease_return: lease_return_detail.lease_return,
				refetchChecklist: refetch
			};
		}
	})
)(ChecklistComponentView) as React.ComponentType<any>;

export default ChecklistComponent;
