import * as React from "react";
import ApolloClient from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import { useQuery } from "@apollo/react-hooks";
import { DataSyncQuery, watchDataSync } from "./util";

interface DataSyncProps {
  name: string;
  enabled: boolean;
  pollInterval: number;
  sync: DataSyncQuery;
  client: ApolloClient<NormalizedCacheObject>;
  debug?: boolean;
}

export const DataSync = ({
  name,
  sync,
  pollInterval,
  client,
  debug,
}: DataSyncProps) => {
  let status = "idle";
  const { query, variables } = sync;
  const { loading, error, data } = useQuery(query, { variables, pollInterval });
  if (loading) status = "Loading";
  if (error) status = "Error";
  if (data) {
    status = "Polling";
    if (debug) watchDataSync(name, client, sync);
  }
  if (debug) console.info(`SYNC STATUS(${name}): ${status}`);
  return <span data-sync-name={name} />;
};
