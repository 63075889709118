import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { addAlert } from "api/redux/actions";
import { flowRight as compose } from "lodash";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Button, Container, Dimmer, Dropdown, Grid, Header, Input, Loader } from "semantic-ui-react";
import { FileDropComponent } from "../../../../../../components/FileHandling";
import { calculateBase64ImageSize, generateThumbnail, getFileObjectFromPath, humanFileSize, promiseBase64 } from "../../../../../../utils/fileHandling";
import { handleErrorResponse } from "../../../../../../utils/functions";
import { restAPI } from "../../../../../../utils/rest";

import "./AddLeaseReturnInspectionItem.css";

export class InspectionItemView extends React.Component<LMI.ILRAddInspectionViewProps, LMI.ILRAddInspectionViewState> {
	constructor(props: LMI.ILRAddInspectionViewProps) {
		super(props);

		this.state = {
			selected: null,
			condition_type: props.condition_type ? props.condition_type : null,
			comment: props.comment ? props.comment : null,
			fileHandles: props.fileHandles ? props.fileHandles : null,
			uploading: false,
			creating: false,
			editing: false,
			saving: false
		};
	}

	getOptions = () => {
		const { lease_return_inspection_types } = this.props;
		if (lease_return_inspection_types && lease_return_inspection_types.length > 0) {
			return lease_return_inspection_types.map(o => {
				return {
					key: o.id,
					text: o.name,
					value: o.id,
					content: <Header content={o.name} subheader={o.description ? o.description : ""} />
				};
			});
		}
	};

	handleChange = (event: any, data: any) => {
		this.setState({ comment: data.value });
	};

	handleChangeSelect = (event: any, data: any) => {
		this.setState({ condition_type: data.value });
	};

	setFileHandles = fileHandles => {
		this.setState({ fileHandles });
	};

	createInspectionItem = () => {
		const { condition_type, comment, fileHandles } = this.state;
		if (condition_type && typeof condition_type === "number") {
			this.setState({ creating: true }, async () => {
				const { storeId, detail } = this.props;

				const hasFile = fileHandles && fileHandles.length > 0;
				const isCropped = hasFile && fileHandles[0].cropped;

				const data = {
					lease_return_inspection_type_id: condition_type,
					comment,
					image: hasFile ? (isCropped ? fileHandles[0].cropped : await promiseBase64(fileHandles[0])) : null
				};
				restAPI({
					endpointName: "createLeaseReturnInspection",
					urlArgs: [storeId, detail.detail_id],
					data,
					callback: async (error, result) => {
						if (error) {
							this.sendAlert({ type: "danger", message: handleErrorResponse({ error }) });
						} else {
							await this.props.refetchInspections();
							await this.props.closeModalCallback();
						}
						this.setState({ saving: false, creating: false });
					}
				});
			});
		}
	};

	editInspectionItem = () => {
		this.setState({ editing: true }, async () => {
			const { condition_type, comment, fileHandles } = this.state;
			const { storeId, detail, inspection_id } = this.props;

			const hasFile = fileHandles && fileHandles.length > 0;
			const isCropped = hasFile && fileHandles[0].cropped;

			let base64Image: any;
			if (typeof fileHandles[0] === "string") {
				base64Image = fileHandles.length > 0 ? await getFileObjectFromPath(fileHandles[0])["base64"] : null;
			} else {
				base64Image = fileHandles.length > 0 ? await promiseBase64(fileHandles[0]) : null;
			}
			const data = {
				lease_return_inspection_type_id: condition_type,
				comment,
				image: hasFile ? (isCropped ? fileHandles[0].cropped : base64Image) : null
			};
			restAPI({
				endpointName: "editLeaseReturnInspection",
				urlArgs: [storeId, detail.detail_id, inspection_id],
				data,
				callback: async (error, result) => {
					if (error) {
						this.sendAlert({ type: "danger", message: handleErrorResponse({ error }) });
					} else {
						await this.props.refetchInspections();
						await this.props.onClose();
					}
					this.setState({ saving: false, editing: false });
				}
			});
		});
	};

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
		if (type === "danger") {
			console.log(`Error: ${message}`);
		}
	}

	render() {
		const { condition_type, comment, creating, editing, fileHandles } = this.state;
		const { loading, mode } = this.props;

		if (loading || creating || editing)
			return (
				<Dimmer active inverted>
					<Loader inverted />
				</Dimmer>
			);

		const fileDropProps = {
			fileHandles,
			multiple: false,
			fileSizeMaxMB: 2,
			fileTypes: ["image/png", "image/jpg", "image/jpeg"],
			enableCropping: true,
			dropIcon: "photo",
			dropMessage: "Drop your photo or click here to upload",
			onReturnFileHandles: this.setFileHandles,
			generateThumbnail: (args: any) => generateThumbnail(args),
			humanFileSize: (args: any) => humanFileSize(args),
			calculateBase64ImageSize: (args: any) => calculateBase64ImageSize(args),
			promiseBase64: (args: any) => promiseBase64(args),
			getFileObjectFromPath: (args: any) => getFileObjectFromPath(args)
		};

		return (
			<div className="add-inspection-item">
				<Grid>
					<Grid.Row id="formRow">
						<Grid.Column width={11}>
							<div className="input-wrapper">
								<div className="lmi-label">COMMENT</div>
								<Input className="lmi-input" name="comment" autoFocus value={comment} onChange={(e, data) => this.handleChange(e, data)} />
							</div>
						</Grid.Column>
						<Grid.Column width={5}>
							<div className="input-wrapper">
								<div className="lmi-select-label">CONDITION TYPE</div>
								<Dropdown
									className="select"
									data-name="condition_type"
									value={condition_type}
									placeholder="Choose Condition Type"
									fluid
									selection
									options={this.getOptions()}
									onChange={this.handleChangeSelect.bind(this)}
								/>
							</div>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row id="fileRow">
						<Grid.Column width={16}>
							<FileDropComponent {...fileDropProps} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Container textAlign="right">
							<Button
								id="create"
								type="submit"
								positive
								size="large"
								disabled={condition_type ? false : true}
								onClick={mode === "edit" ? this.editInspectionItem.bind(this) : this.createInspectionItem.bind(this)}
							>
								{mode === "edit" ? "Update" : "Create"}
							</Button>
						</Container>
					</Grid.Row>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const InspectionItemForm = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.leaseReturnInspectionTypes, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, lr_inspection_types, refetch, fetchMore } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				lease_return_inspection_types: lr_inspection_types.lr_inspection_types,
				refetchStatuses: refetch
			};
		}
	})
)(InspectionItemView) as React.ComponentType<any>;

export default InspectionItemForm;
