import { restAPI } from "../../utils/rest";
// node_modules
import * as React from "react";
// components
import { Button, Container, Header, Image, Divider, Segment, Grid, Icon, Label, Popup } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { formatPhone } from "client/utils/functions";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";
import { FileUploadComponent } from "client/pages/admin/components/FileUploadComponent";
import { Redirect } from "react-router";

import "./Register.css";

export class Register extends React.Component<LMI.IRegisterProps, LMI.IRegisterState> {
	constructor(props) {
		super(props);
		this.state = {
			editProfilePic: false,
			profilePic: null,
			inputType: ["password", "password"],
			redirect: false,
			activateError: false,
			fetchError: false,
			user: null,
			activating: false
		};
	}
	render() {
		const { redirect, activateError, user } = this.state;
		const errorLabel = <Label color="red" pointing="above" />;

		const fileProps = {
			filesToAccept: "image/png, image/jpeg",
			onSubmit: imageObject => {
				this.setState({ editProfilePic: false, profilePic: imageObject.image });
			},
			onClose: () => {
				this.setState({ editProfilePic: false });
			}
		};

		if (redirect) {
			return <Redirect to="/login" push />;
		}

		if (activateError) {
			return (
				<Container id="activation-error">
					<Header as="h2" icon>
						<Icon name="warning sign" color="red" />
						We're sorry, there was an error :(
						<Header.Subheader>Please contact your administrator or try again later.</Header.Subheader>
					</Header>
				</Container>
			);
		}

		return (
			<Container id="user-register">
				{this.state.editProfilePic && (
					<ModalComponent
						headerText="Upload Image"
						shouldBeOpen={this.state.editProfilePic}
						onClose={(evt, data) => {
							this.setState({ editProfilePic: false });
						}}
						className="upload-image-modal"
						size="small"
						contentComponent={() => <FileUploadComponent {...fileProps} />}
					/>
				)}
				{user && !this.state.activating ? (
					<Container className="register-block">
						<Image src="/images/LoopMeIn_LOGO_FINAL.png" size="small" />
						<Divider />
						<Header as="h1" content="Welcome to LoopMeIn!" />
						<Segment className="user-panel" textAlign="left" raised onClick={() => this.setState({ editProfilePic: true })}>
							<Popup
								trigger={
									<Label color="blue" corner="right">
										<Icon name="user circle" />
									</Label>
								}
								content="New User Info Card"
							/>
							<Grid>
								<Grid.Column width={4} textAlign="center">
									{user.photo_url ? (
										<Image src={user.photo_url} rounded size="small" loading="lazy" />
									) : (
										<span>
											{this.state.profilePic ? (
												<Image src={this.state.profilePic} rounded size="small" loading="lazy" />
											) : (
												<Icon.Group size="huge">
													<Icon size="big" name="circle" color="grey" />
													<Icon name="user" inverted />
													<Icon corner name="add circle" color="green" />
												</Icon.Group>
											)}
										</span>
									)}
								</Grid.Column>
								<Grid.Column width={12}>
									<Header content={user.full_name} as="h3" />
									<span className="info">
										<Icon name="mail" /> {user.email}
										<br />
									</span>
									{user.phone && (
										<span className="info">
											<Icon name="phone square" /> {formatPhone(user.phone)}
										</span>
									)}
									<br />
									<br />
									{!this.state.profilePic && (
										<small>
											<Label>Click this box to upload your profile picture</Label>
										</small>
									)}
								</Grid.Column>
							</Grid>
						</Segment>
						<Header as="h2" content="One final step to activate your account..." style={{ margin: "32px 0 2px" }} />
						<p>Please create your new password to access LoopMeIn web and mobile apps.</p>
						<div className="password-form">
							<Form noValidate onValidSubmit={this.activate.bind(this)}>
								<Form.Field>
									<label>
										Password{" "}
										<Icon
											link
											name={this.state.inputType[0] === "password" ? "unhide" : "hide"}
											onClick={() => {
												const inputType = this.state.inputType[0] === "password" ? ["text", this.state.inputType[1]] : ["password", this.state.inputType[1]];
												this.setState({
													inputType
												});
											}}
										/>
									</label>
									<Input
										name="password"
										placeholder="Enter a new password at least 8 characters long"
										type={this.state.inputType[0]}
										validations={{
											minLength: 8
										}}
										validationErrors={{
											minLength: "Password must be at least 8 characters long.",
											isDefaultRequiredValue: "A New Password is Required"
										}}
										errorLabel={errorLabel}
										required
									/>
								</Form.Field>
								<Form.Field>
									<label>
										Confirm Password{" "}
										<Icon
											link
											name={this.state.inputType[1] === "password" ? "unhide" : "hide"}
											onClick={() => {
												const inputType = this.state.inputType[1] === "password" ? [this.state.inputType[0], "text"] : [this.state.inputType[0], "password"];
												this.setState({
													inputType
												});
											}}
										/>
									</label>
									<Input
										name="password_confirm"
										placeholder="Retype your new password to confirm"
										type={this.state.inputType[1]}
										validations={{
											equalsField: "password"
										}}
										validationErrors={{
											equalsField: "Confirm Password must match your password field",
											isDefaultRequiredValue: "Confirm Password is Required"
										}}
										errorLabel={errorLabel}
										required
									/>
								</Form.Field>
								<Button type="submit" positive content="Activate My Account" size="large" />
							</Form>
						</div>
					</Container>
				) : (
					<Container id="fetch-error">
						{this.state.fetchError ? (
							<Header as="h2" icon textAlign="center">
								<Icon color="red" name="warning circle" />
								<Header.Content>We could not find your records!</Header.Content>
								<Header.Subheader>Please contact your account administrator.</Header.Subheader>
							</Header>
						) : (
							<Header as="h2" icon textAlign="center">
								<Icon loading color="blue" name="spinner" />
								<Header.Content>{this.state.activating ? "Activating Your Account" : "Finding your user record."}</Header.Content>
							</Header>
						)}
					</Container>
				)}
			</Container>
		);
	}

	componentDidMount() {
		const atoken = this.props.match && this.props.match.params.id;
		restAPI({
			endpointName: "fetchUserActivate",
			urlArgs: [atoken],
			data: null,
			callback: (err, res) => {
				if (!err) {
					this.setState({ user: res.data && res.data.user });
				} else {
					this.setState({ fetchError: true });
				}
			}
		});
	}

	activate(formData: any) {
		this.setState({ activating: true });
		let image = {};
		if (this.state.profilePic) {
			image = { image: this.state.profilePic };
		}
		const activate = Object.assign({}, formData, image);
		const atoken = this.props.match && this.props.match.params.id;
		restAPI({
			endpointName: "activateUserAccount",
			urlArgs: [atoken],
			data: activate,
			callback: (err, res) => {
				this.setState({ activating: false });
				if (!err) {
					this.setState({ redirect: true });
				} else {
					console.log(err);
					this.setState({ activateError: true });
				}
			}
		});
	}
}

export default Register;
