// node_modules
import { BusinessDocumentType } from "loopmein-shared";
import moment from "moment";
import * as React from "react";
// components
import { Button, Card, Icon, Image, Label } from "semantic-ui-react";
import "./FileCardComponent.css";

export class FileCard extends React.Component<LMI.IFileCardProps, undefined> {
	render() {
		const props = this.props;
		return (
			<Card className={props.classes}>
				<div className="imgContainer">{this.getIconOrImage(props)}</div>
				{!props.hidetrash && (
					<Button className="delete-file-btn" icon onClick={props.onDelete}>
						<Icon name="trash alternate" />
					</Button>
				)}
				{props.expiration && (
					<Label color={this.getDaysuntil(props.expiration) <= 0 ? "red" : "blue"} attached="top left" className="expiration">
						<Icon name="clock" />{" "}
						{this.getDaysuntil(props.expiration) <= 0 ? <span>Expired</span> : <span>Expires in {this.getDaysuntil(props.expiration)} days</span>}
					</Label>
				)}
				<Card.Content>
					<Card.Header>
						{props.url ? (
							<a href={props.url} target="_blank">
								{this.getNameFromType(props.id, props.name)}
							</a>
						) : (
							props.name
						)}
						{props.meta ? <Card.Meta>{props.meta}</Card.Meta> : ""}
					</Card.Header>
				</Card.Content>
			</Card>
		);
	}

	getDaysuntil(date: any) {
		const expires = moment(date);
		const today = moment();
		const daysuntil = expires.diff(today, "days");
		return daysuntil;
	}

	getNameFromType(id: number, name: string): string {
		switch (id) {
			case BusinessDocumentType.BusinessLicense:
				return "Business License";
			case BusinessDocumentType.ProofOfInsurance:
				return "Proof of Insurance";
			case BusinessDocumentType.Bond:
				return "Bond";
			case BusinessDocumentType.W9:
				return "W-9";
			case BusinessDocumentType.Other:
				return name;
			default:
				break;
		}
	}

	getIconOrImage(props: LMI.IFileCardProps): any {
		if (props.type.indexOf("image/") > -1) {
			return <Image src={props.url + "?h=300&w=255&auto=compress%20enhance"} loading="lazy" />;
		} else {
			return <Image src={props.url + "?h=300&w=255&auto=compress%20enhance"} loading="lazy" />;
		}
	}
}

export default FileCard;
