import { restAPI } from "../../../../../../../../utils/rest";

// node_modules
import { Form } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { cloneObject, formatPhone, orderByProperty } from "client/utils/functions";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
// components
import { connect } from "react-redux";
import { Button, Grid, Input, Label, Menu } from "semantic-ui-react";
import { LookupVendorListComponent } from "./LookupVendorListComponent";

import "./LookupVendorSearchComponent.css";

export class LookupVendorSearchComponentView extends React.Component<LMI.LookupVendorSearchComponentViewProps, LMI.LookupVendorSearchComponentViewState> {
	searchFocus: Input;

	constructor(props) {
		super(props);

		this.state = {
			updated_services: null,
			submitting: false
		};
	}

	componentDidMount() {
		this.searchFocus.focus();
	}

	onFocus = args => {
		this.props.onSearchInputChange({ name: args.currentTarget.name, value: args.currentTarget.value });
	};

	submitVendor = (data: any) => {
		let { updated_services: updatedServices } = this.state;
		updatedServices = updatedServices && updatedServices.filter(us => us.is_approved);
		if (!updatedServices || updatedServices.length <= 0) {
			const alert = { type: "warning", message: "You must select at least one approved service for this vendor" };
			this.props.onSendAlert(alert);
			return false;
		}
		this.setState({ submitting: true });
		data.services = updatedServices.filter(service => service.is_approved).map(service => service.id);
		data.company_phone = data.phone.replace(/-/g, "");
		data.company_name = data.store_name;
		restAPI({
			endpointName: "createNewVendor",
			urlArgs: [this.props.storeId],
			data,
			callback: (error, result) => {
				if (!error) {
					const alert = { type: "success", message: "Vendor created successfully" };
					this.props.onSendAlert(alert);
					this.resetServices();
				} else {
					const alert = { type: "danger", message: error.reason.response.data.message };
					this.props.onSendAlert(alert);
				}
				this.setState({ submitting: false });
			}
		});
	};

	addVendorReady = (): boolean => {
		const { store_name, email, phone } = this.props;
		return !!(store_name && email && phone);
	};

	toggleService = (args: any): void => {
		const { services } = this.props;
		const { updated_services } = this.state;
		let updatedServices = cloneObject(updated_services ? updated_services : services);
		updatedServices =
			updatedServices &&
			updatedServices.map(us => {
				if (us.id.toString() === args.currentTarget.id) {
					us["is_approved"] = !us["is_approved"];
				}
				return us;
			});
		this.setState({ updated_services: updatedServices });
	};

	toggleAllServices = (args: any): void => {
		const { services } = this.props;
		const { updated_services } = this.state;
		let updatedServices = cloneObject(updated_services ? updated_services : services);
		updatedServices =
			updatedServices &&
			updatedServices.map(us => {
				us["is_approved"] = !us["is_approved"];
				return us;
			});
		this.setState({ updated_services: updatedServices });
	};

	resetServices = () => {
		this.setState({ updated_services: null });
		this.props.onResetAddVendor();
	};

	render() {
		const { props } = this;
		const { searching, selected, store_code, store_name, email, phone, first_name, last_name, zip, vendor_modified, add_vendor, onAddVendor, services } = props;
		const { updated_services } = this.state;
		const errorLabel = <Label color="red" pointing />;

		const orderedServices = services && orderByProperty(cloneObject(updated_services ? updated_services : services), "name", "ASC");

		const storeNameProps = Object.assign({}, props, { store_name });
		const storeCodeProps = Object.assign({}, props, { store_code });
		const emailProps = Object.assign({}, props, { email });
		const phoneProps = Object.assign({}, props, { phone });

		return (
			<div className="search-container">
				<h2>{add_vendor ? "Create a New Vendor" : selected ? "Approve Vendor for this Store" : "Search for Existing Vendor"}</h2>
				<Button className="right" primary onClick={onAddVendor} disabled={add_vendor}>
					Create Vendor
				</Button>
				{add_vendor && (
					<div className="add-vendor-container">
						<Form onValidSubmit={this.submitVendor.bind(this)} loading={this.state.submitting}>
							<Grid>
								<Grid.Row columns={3}>
									<Grid.Column>
										<Form.Input
											label="Company Name"
											name="store_name"
											placeholder="Vendor Name"
											value={store_name}
											onChange={(e, data) => this.props.onAddInputChange(data)}
											required
											validationErrors={{ isDefaultRequiredValue: "Store Name is Required" }}
											errorLabel={errorLabel}
										/>
									</Grid.Column>
									<Grid.Column>
										<Form.Input
											label="Contact First Name"
											name="first_name"
											placeholder="First Name"
											value={first_name}
											onChange={(e, data) => this.props.onAddInputChange(data)}
											required
											validations="isWords"
											validationErrors={{ isWords: "No numbers or special characters", isDefaultRequiredValue: "First Name is Required" }}
											errorLabel={errorLabel}
										/>
									</Grid.Column>
									<Grid.Column>
										<Form.Input
											label="Contact Last Name"
											name="last_name"
											placeholder="Last Name"
											value={last_name}
											onChange={(e, data) => this.props.onAddInputChange(data)}
											validations="isWords"
											validationErrors={{ isWords: "No numbers or special characters" }}
											errorLabel={errorLabel}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={3}>
									<Grid.Column>
										<Form.Input
											label="Zip Code"
											name="zip"
											placeholder="Zip Code"
											value={zip}
											onChange={(e, data) => this.props.onAddInputChange(data)}
											// validationErrors={{ isDefaultRequiredValue: "Zip is Required" }}
											errorLabel={errorLabel}
										/>
									</Grid.Column>
									<Grid.Column>
										<Form.Input
											label="Contact Email"
											name="email"
											placeholder="Email"
											value={email}
											onChange={(e, data) => this.props.onAddInputChange(data)}
											required
											validations="isEmail"
											validationErrors={{ isEmail: "Email is not valid", isDefaultRequiredValue: "Email is Required" }}
											errorLabel={errorLabel}
										/>
									</Grid.Column>
									<Grid.Column>
										<Form.Input
											label="Contact Phone"
											name="phone"
											placeholder="Phone"
											value={formatPhone(phone)}
											onChange={(e, data) => this.props.onAddInputChange(data)}
											required
											validationErrors={{ isDefaultRequiredValue: "Phone is Required" }}
											errorLabel={errorLabel}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={1}>
									<Grid.Column>
										<span className="float-right link" onClick={this.toggleAllServices}>
											Toggle Approve All
										</span>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={1}>
									<Grid.Column className="services">
										<Menu vertical>
											{orderedServices &&
												orderedServices.map(service => (
													<Menu.Item id={service.id} className={service.is_approved ? "active" : ""} key={service.id} onClick={this.toggleService}>
														<b>{service.name}</b>
														{`${service.description ? ` (${service.description})` : ""}`}
														<span className="float-right">{service.is_approved && "approved"}</span>
													</Menu.Item>
												))}
										</Menu>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={1}>
									<Grid.Column>
										<Button.Group className="button-group-right">
											<Button onClick={this.resetServices}>Cancel</Button>
											<Button.Or text="OR" />
											<Button positive type="submit" disabled={!this.addVendorReady()}>
												Create Vendor
											</Button>
										</Button.Group>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					</div>
				)}
				{!add_vendor && (
					<Grid>
						<Grid.Row columns={3}>
							<Grid.Column>
								<Input
									name="store_name"
									placeholder="Vendor Name (min 5 characters)"
									value={store_name}
									onChange={(e, data) => this.props.onSearchInputChange(data)}
									onFocus={this.onFocus}
									disabled={vendor_modified}
									ref={s => (this.searchFocus = s)}
								/>
								{searching && store_name && <LookupVendorListComponent {...storeNameProps} />}
							</Grid.Column>
							<Grid.Column>
								<Input
									name="email"
									placeholder="Employee Email (min 5 characters)"
									value={email}
									onChange={(e, data) => this.props.onSearchInputChange(data)}
									onFocus={this.onFocus}
									disabled={vendor_modified}
								/>
								{searching && email && <LookupVendorListComponent {...emailProps} />}
							</Grid.Column>
							<Grid.Column>
								<Input
									name="phone"
									placeholder="Employee Phone (min 5 characters)"
									value={phone}
									onChange={(e, data) => this.props.onSearchInputChange(data)}
									onFocus={this.onFocus}
									disabled={vendor_modified}
								/>
								{searching && phone && <LookupVendorListComponent {...phoneProps} />}
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={1}>
							<Grid.Column>
								<Input
									name="store_code"
									placeholder="Vendor Store Code"
									value={store_code}
									onChange={(e, data) => this.props.onSearchInputChange(data)}
									onFocus={this.onFocus}
									disabled={vendor_modified}
								/>
								{(searching || selected) && store_code && <LookupVendorListComponent {...storeCodeProps} />}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const LookupVendorSearchComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IServicesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.vendor.services, {
		options: (props: any) => {
			return {
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, services, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				services,
				refetch
			};
		}
	})
)(LookupVendorSearchComponentView) as React.ComponentType<any>;

export default LookupVendorSearchComponent;
