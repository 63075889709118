// node_modules
import * as React from "react";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
// import { logAction } from "../../../../../../utils/analytics";
import { flowRight as compose } from "lodash";
import { gqlQueries } from "gql-imports";
import { Loader, Dimmer, Segment, Feed, List } from "semantic-ui-react";
import { RelativeDatePipe, getWarningColor, calculateElapsedMinimum } from "client/utils/functions";
import { RowTimer } from "../../../tabs/SaniTabPanel/components/RowTimer";

export class InventorySaniHistoryView extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	getProgressTemplate = props => {
		const percent_complete = (props.sanitation_request_items.filter(d => !!d.completed_by_emp).length / props.sanitation_request_items.length) * 100;
		const elapsed = calculateElapsedMinimum(props.created_at);
		const rowProps = {
			settings: this.props.store_settings,
			is_completed: props.completed_at,
			created_at_raw: +props.created_at,
			percent_complete,
			elapsed,
			key: props.created_at
		};

		return <RowTimer {...rowProps} />;
	};

	render() {
		const { loading, sanitation_requests, store_settings } = this.props;
		if (loading)
			return (
				<Dimmer active>
					<Loader />
				</Dimmer>
			);

		const { requests } = sanitation_requests;
		return (
			<div className="sanitization-history">
				{requests.map((req, key) => {
					const complete = req.completed_at && req.completed_by_emp ? true : false;
					const title = complete
						? `Completed ${req.completed_by_emp && req.completed_by_emp.user ? `by ${req.completed_by_emp.user.full_name}` : ""}`
						: `Requested by ${req.created_by_user.full_name}`;
					const subtitle = complete ? RelativeDatePipe(req.completed_at, false, true) : RelativeDatePipe(req.created_at, false, true);
					const color = getWarningColor({ settings: store_settings, created_at: +req.created_at, is_completed: req.completed_at ? true : false });

					return (
						<Segment key={key}>
							<Feed>
								<Feed.Event>
									<Feed.Label className={`ind-img ${color}`} image={`/images/spray-bottle-white.png`} />
									<Feed.Content className="sani-vehicle-detail">
										{req.lead_name && (
											<span className="lead-name">
												Lead Name: <b>{req.lead_name}</b>
											</span>
										)}
										{!complete && <span className="progress">{this.getProgressTemplate(req)}</span>}
										<Feed.Date content={subtitle} />
										<Feed.Summary>{title}</Feed.Summary>
										<Feed.Extra>
											<List>
												{req.sanitation_request_items.map((item, ikey) => {
													return (
														<List.Item key={ikey}>
															<List.Icon color="grey" name={item.completed_at ? "check circle" : "circle outline"} />
															<List.Content>{item.name}</List.Content>
														</List.Item>
													);
												})}
											</List>
										</Feed.Extra>
									</Feed.Content>
								</Feed.Event>
							</Feed>
						</Segment>
					);
				})}
			</div>
		);
	}

	// componentDidMount(): void {
	// 	logAction("Inventory Sanitization History Modal");
	// }
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const SaniHistory = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.ISaniQueryGQL, any, any, ClassAttributes<any>>(gqlQueries.dealership.sanitationRequests, {
		options: (props: any) => {
			const variables = {
				storeId: props.storeId,
				openOnly: false,
				vin: props.detail.vin
			};

			return {
				variables,
				fetchPolicy: "no-cache"
			};
		},
		props: ({ data: { error, loading, sanitation_requests, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				sanitation_requests,
				refetchRequests: refetch
			};
		}
	}),
	graphql<LMI.IOrgSettingsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.settings.store, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					names: ["SANITIZATION_WARNING_MINUTES", "SANITIZATION_ERROR_MINUTES"]
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_settings, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				store_settings,
				refetch
			};
		}
	})
)(InventorySaniHistoryView) as React.ComponentType<any>;

export default SaniHistory;
