// node_modules
import * as React from "react";
// components
import { Input } from "semantic-ui-react";
import "./DataFilterComponent.css";

export class DataFilterComponent extends React.Component<LMI.IDFCProps, undefined> {
	render() {
		return (
			<div className={!this.props.leftside ? "searchFilter" : ""}>
				<Input
					ref="search-filter"
					className="search-filter"
					name="search-filter"
					placeholder={this.props.label}
					value={this.props.searchFilter ? this.props.searchFilter : ""}
					onChange={this.props.onChange}
					type={this.props.useNumbers ? "number" : "string"}
				/>
			</div>
		);
	}
}

export default DataFilterComponent;
