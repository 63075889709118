import * as React from "react";
import track from "react-tracking";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { withRouter } from "react-router";
import {
  AnalyticsEventType,
  InventoryConditionString,
  InventoryType,
} from "loopmein-shared";
import { restAPI } from "client/utils/rest";
import { Validator } from "loopmein-shared/bin/globals/validation";
import { RegularExpressions } from "loopmein-shared/bin/globals/regex";

import { Form, Select, Input } from "formsy-semantic-ui-react";
import { confirmDefault, ConfirmDialog } from "client/components/ConfirmDialog";
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Image,
  Label,
  Loader,
} from "semantic-ui-react";

@track({ event_type: AnalyticsEventType.NAVIGATION, event_subtype: "inventory.edit_inventory" }, { dispatchOnMount: true })
export class EditInventoryFormView extends React.Component<LMI.InventoryDetailUpdateForm, LMI.IEditInventoryState> {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			vinputValue: props.detail.vin,
			stinputValue: props.detail.stock_number,
			milesinputValue: props.detail.miles,
			confirm: null,
			canSubmit: false
		};
	}

	handleErrorResponse(error: any) {
		if (error.reason && error.reason.response && error.reason.response.data) {
			return error.reason.response.data.message;
		} else {
			return "There was an error.";
		}
	}

	checkVinSubmit = async (formData): Promise<any> => {
		const vinLegit = await Validator.vinCheckDigit(formData.vin);
		if (vinLegit.isValid) {
			this.submitForm(formData);
		} else {
			this.setState({
				confirm: {
					open: true,
					title: `VIN Check Failed`,
					text: `${vinLegit.message}`,
					cancelText: "Try Again",
					confirmText: "Confirm",
					success: () => {
						this.submitForm(formData);
					},
					failure: () => {
						this.setState({ confirm: confirmDefault });
						document.getElementById("vinput").focus();
					}
				}
			});
		}
	};

	disableSubmit = () => {
		this.setState({ canSubmit: false });
	};

	enableSubmit = () => {
		this.setState({ canSubmit: true });
	};

	private submitForm = async formData => {
		this.setState({ loading: true });
		restAPI({
			endpointName: "inv_manual_edit",
			urlArgs: [this.props.storeId, this.props.detail.id],
			data: formData,
			callback: this.props.onUpdate.bind(this)
		});
	};

	getOptions(options: Object, useNumber = true) {
		return Object.keys(options)
			.filter(key => {
				const isNumber = isNaN(Number(options[key]));
				return useNumber ? !isNumber : isNumber;
			})
			.map(text => ({
				text,
				value: options[text]
			}));
	}

	render() {
		const errorLabel = <Label color="red" pointing="above" />;
		const { detail } = this.props;
		const { loading, vinputValue, stinputValue, confirm } = this.state;

		if (loading) {
			return (
				<Dimmer active>
					<Loader />
				</Dimmer>
			);
		}

		return (
			<div id="EditInventory">
				<Grid>
					<Grid.Column width={4}>
						<Image src={detail.photo_url} floated="left" rounded size="small" loading="lazy" />
					</Grid.Column>
					<Grid.Column width={12}>
						<Header>
							<small>
								{detail.year} {detail.make} {detail.model}
							</small>
							<br />
							{vinputValue}
						</Header>
					</Grid.Column>
				</Grid>
				<Form id="EditInventoryForm" ref="form" onValid={this.enableSubmit} onInvalid={this.disableSubmit} onValidSubmit={this.checkVinSubmit.bind(this)}>
					<ConfirmDialog extendClass="lower" {...confirm} />
					<Grid columns="equal">
						<Grid.Column>
							<Form.Field>
								<label>
									Stock # <span className="red-text">*</span>
								</label>
								<Input
									id="stinput"
									name="stock_number"
									value={stinputValue}
									validations={{
										matchRegexp: RegularExpressions.STOCK_NUMBER,
										minLength: 3,
										maxLength: 25
									}}
									validationErrors={{
										matchRegexp: "Stock Number must be alphanumeric",
										minLength: "Must be at least 3 digits",
										maxLength: "Can only be 25 digits",
										isDefaultRequiredValue: "Stock # is required"
									}}
									errorLabel={errorLabel}
									onChange={(e, data) => this.setState({ stinputValue: data.value.toUpperCase() })}
									required
								/>
							</Form.Field>
						</Grid.Column>
					</Grid>
					<Grid columns="equal" style={{ marginTop: 0 }}>
						<Grid.Column>
							<Form.Field>
								<label>
									Condition <span className="red-text">*</span>
								</label>
								<Select
									fluid
									selectOnBlur={false}
									name="condition"
									defaultValue={this.props.detail.condition}
									options={this.getOptions(InventoryConditionString, false)}
								/>
							</Form.Field>
						</Grid.Column>
						<Grid.Column>
							<Form.Field>
								<label>
									Sale Type <span className="red-text">*</span>
								</label>
								<Select
									fluid
									name="inventory_type_id"
									required
									defaultValue={this.props.detail.inventory_type_id}
									selectOnBlur={false}
									options={this.getOptions(InventoryType)}
								/>
							</Form.Field>
						</Grid.Column>
						<Grid.Column>
							<Form.Field>
								<label>Odometer</label>
								<Input
									id="milesinput"
									type="number"
									value={this.state.milesinputValue}
									name="miles"
									onChange={(e, data) => this.setState({ milesinputValue: parseInt(data.value) })}
								/>
							</Form.Field>
						</Grid.Column>
					</Grid>
					<Input id="vinput" name="vin" value={vinputValue} type="hidden" />
					<Container textAlign="right">
						<Button type="submit" positive size="large" disabled={!this.state.canSubmit}>
							Submit
						</Button>
					</Container>
				</Form>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const EditInventoryForm = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(EditInventoryFormView);
export default EditInventoryForm;
