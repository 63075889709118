// node_modules
import * as React from "react";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import { Icon, List, Grid, Label } from "semantic-ui-react";
import "./DragDropListComponent.css";

const DragHandle = SortableHandle(() => <Icon name="sort" color="grey" className="dragger" />);
const SortableItem = SortableElement(({ item, index, props }) => {
	return (
		<List.Item key={index} className={item.className}>
			<Grid>
				<Grid.Column width={2}>
					{props.dragHandle && <DragHandle />}
					{props.editable && <Icon name="pencil" link color="blue" onClick={() => props.editCallback(item.item)} />}
					{props.deletable && <Icon name="trash alternate outline" link color="red" onClick={() => props.deleteCallback(item.item)} />}
				</Grid.Column>
				<Grid.Column width={3}>{item.name}</Grid.Column>
				{props.showThresholds && (
					<>
						<Grid.Column width={2}>
							<Label className="warning">{`${item.warning_threshold_days} Days`}</Label>
						</Grid.Column>
						<Grid.Column width={2}>
							<Label className="critical">{`${item.error_threshold_days} Days`}</Label>
						</Grid.Column>
					</>
				)}
				{!props.hideDesc ? (
					<Grid.Column width={item.action ? 5 : 8} className="serviceDesc">
						<em>{item.description ? <span>{item.description}</span> : "No description"}</em>
					</Grid.Column>
				) : (
					""
				)}
				{item.action && <Grid.Column width={3}>{item.action}</Grid.Column>}
			</Grid>
		</List.Item>
	);
});
const SortableList = SortableContainer(({ items, props }) => {
	return (
		<List divided size="large" relaxed="very">
			{items.map((item, index) => (
				<SortableItem key={`item-${index}`} item={item} index={index} props={props} />
			))}
		</List>
	);
});

export class DragDropListComponent extends React.Component<LMI.IDDListProps, LMI.IDDListState> {
	constructor(props) {
		super(props);
		this.state = {
			items: props.items
		};
	}

	render() {
		return (
			<div id={`drag-drop-list-${this.props.listName}`} className="drag-list">
				<SortableList
					items={this.state.items}
					props={this.props}
					onSortEnd={this.onSortEnd}
					useDragHandle={this.props.dragHandle ? this.props.dragHandle : false}
				/>
			</div>
		);
	}

	onSortEnd = ({ oldIndex, newIndex }) => {
		const { items } = this.state;
		this.setState({
			items: arrayMove(items, oldIndex, newIndex)
		});
		this.props.callback(this.state.items);
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		return { items: nextProps.items };
	}
}

export default DragDropListComponent;
