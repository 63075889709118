import * as React from "react";
import { Image } from "semantic-ui-react";

interface VehiclePhotoProps {
	detail: LMI.IInventoryGQL;
	imageClass?: string;
	clicked?: () => void;
	photoParams?: string;
	rounded?: boolean;
	enableSoldBanner?: boolean;
	bannerScale?: number;
}

export class VehiclePhoto extends React.Component<VehiclePhotoProps, any> {
	constructor(props: any) {
		super(props);
		this.state = {};
	}

	static getSoldBannerParams(scale) {
		return `&mark=https://lmi-images.s3.amazonaws.com/misc/sold-badge.png&mark-scale=${scale}&mark-fit=scale&mark-pad=0`;
	}

	render() {
		const { detail, imageClass, clicked, photoParams, rounded, bannerScale, enableSoldBanner } = this.props;
		let vehiclePhoto = `${detail.photo_url}?auto=compress${photoParams ? photoParams : ""}`;

		if (enableSoldBanner) {
			const isSold = !detail.is_active ? VehiclePhoto.getSoldBannerParams(bannerScale ? bannerScale : 30) : "";
			vehiclePhoto = `${vehiclePhoto}${isSold}`;
		}

		return <Image src={vehiclePhoto} loading="lazy" className={imageClass} alt={detail.stock_number} onClick={() => clicked && clicked()} rounded={rounded} />;
	}
}

export default VehiclePhoto;
