// node_modules

import * as React from "react";
// components
import { SortableTable } from "../../SortableTable";
import "./MessagesTabPanel.css";

const tableData: any[] = [
	{
		dateSent: "5/6/2017",
		message: "This is a test message.",
		who: "8 people"
	},
	{
		dateSent: "4/18/2017",
		message: "Tornado warning!!!",
		who: "Everyone"
	}
];

const tableHeaders: any = [
	{
		id: "dateSent",
		label: "Date Sent",
		sortable: true
	},
	{
		id: "message",
		label: "Message",
		sortable: true
	},
	{
		id: "who",
		label: "Who",
		sortable: true
	}
];

const sortableTableData: LMI.ITableData = {
	headers: tableHeaders,
	body: {
		rows: Array.from(tableData)
	}
};

export class MessagesTabPanel extends React.Component<LMI.IMessagesTPProps, undefined> {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="messages-tab-panel panel-content">
				<SortableTable tableData={sortableTableData} />
			</div>
		);
	}
}

export default MessagesTabPanel;
