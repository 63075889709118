import track from "react-tracking";
import * as React from "react";
import { debounce, flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { Button, Checkbox, Dimmer, Divider, Grid, Icon, Input, Label, Popup } from "semantic-ui-react";
import QuickSearchResults, { QuickSearchResultTypes, SearchSegmentColors } from "./QuickSearchResults";
import { toggleQuickSearch } from "api/redux/actions";
import { AnalyticsEventType } from "loopmein-shared";
import ToursIntroPopup, { ToursIntroPopupProps } from "../Tours/ToursIntroPopup";

import "./QuickSearch.css";

@track(
	props => {
		return {
			event_type: AnalyticsEventType.NAVIGATION,
			event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}quick-search`
		};
	},
	{ dispatchOnMount: true }
)
export class QuickSearchView extends React.Component<LMI.IAdminReduxState, LMI.LmiQuickSearchState> {
	constructor(props: LMI.IAdminReduxState) {
		super(props);

		this.state = {
			open: props.quickSearchOpen,
			searchFilter: "",
			openFilterPanel: false,
			resultTypes: [],
			permittedTypes: [],
			inTour: false
		};
	}

	static getDerivedStateFromProps(nextProps: LMI.IAdminReduxState, prevState: LMI.LmiQuickSearchState) {
		const open = nextProps.quickSearchOpen;
		const { resultTypes, permittedTypes } = QuickSearchView.setResultTypes(nextProps);
		return {
			open,
			searchFilter: !open ? "" : prevState.searchFilter,
			permittedTypes,
			resultTypes
		};
	}

	static setResultTypes(props) {
		const types = QuickSearchView.resultTypeStore();
		const permittedTypes = QuickSearchView.checkPermits(props);
		if (permittedTypes.length == 0) return { resultTypes: [], permittedTypes };
		const frisk = types.filter(type => permittedTypes.includes(type));
		// if no types are present use first permitted
		const resultTypes = frisk.length > 0 ? frisk : [permittedTypes[0]];
		return { resultTypes, permittedTypes };
	}

	static resultTypeStore() {
		let store: any = localStorage.getItem("quickSearchResultTypes");
		return store ? JSON.parse(store) : [];
	}

	static checkPermits(props: LMI.IAdminReduxState) {
		// each result type should have a route for travel (which has already been screened by permissions)
		const resultTypes = Object.values(QuickSearchResultTypes).filter(i => typeof QuickSearchResultTypes[i] !== "number");
		return resultTypes.filter(rt => {
			const type = QuickSearchResultTypes[rt];
			// massage the resultType enum to match against the route id
			return props.routes.findIndex(route => route.id === type.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)) !== -1;
		});
	}

	toggleSearch() {
		const open = !this.state.open;
		this.props.toggleQuickSearch(open);
	}

	getFilterPanel(showResults: boolean) {
		const { resultTypes, permittedTypes } = this.state;
		return (
			<div className={`search-filters${showResults ? " flat" : ""}`}>
				<Divider horizontal className="result-divider">
					<Icon name="filter" />
					RESULT TYPES
				</Divider>
				<Grid padded centered columns="equal">
					<Grid.Row>
						{permittedTypes.map(resultKey => {
							const resultType = QuickSearchResultTypes[resultKey];
							const resultName = this.resultName(resultType);
							const checked = resultTypes.includes(resultKey) ? true : false;
							const toggle = () => {
								const resultTypeState = checked ? resultTypes.filter(i => i !== resultKey) : [...resultTypes, ...[resultKey]];
								localStorage.setItem("quickSearchResultTypes", JSON.stringify(resultTypeState));
								return resultTypeState;
							};
							if (resultType)
								return (
									<Grid.Column key={resultType}>
										<Checkbox label={resultName} checked={checked} onChange={() => this.setState({ resultTypes: toggle() })} />
									</Grid.Column>
								);
						})}
					</Grid.Row>
				</Grid>
			</div>
		);
	}

	resultName(resultType) {
		if (resultType) return resultType.charAt(0).toUpperCase() + resultType.slice(1).replace(/([A-Z])/g, " $1");
	}

	filterPopContent(resultTypes: QuickSearchResultTypes[]) {
		return (
			<div>
				<div style={{ fontSize: "10px" }}>CURRENTLY SEARCHING</div>
				<Label.Group>
					{resultTypes &&
						resultTypes.map((type, i) => {
							return (
								<Label key={`t${i}`} size="tiny" color={SearchSegmentColors[type - 1]} horizontal>
									{this.resultName(QuickSearchResultTypes[type])}
								</Label>
							);
						})}
				</Label.Group>
			</div>
		);
	}

	render() {
		const { open, searchFilter, resultTypes, openFilterPanel, permittedTypes, inTour } = this.state;
		const showResults = (open && searchFilter && searchFilter.length > 2) || (searchFilter && inTour);

		if (permittedTypes.length <= 0) return <span />;
		const currentResultTypes = resultTypes.filter(i => permittedTypes.includes(i));

		return (
			<span id="QuickSearch">
				<ToursIntroPopup
					{...({
						route: "/feature-tours",
						tourId: 5,
						name: "quickSearchIntro",
						popupProps: {
							position: "bottom right",
							className: "bring-top"
						},
						disableTriggerElemId: "LMIQuickSearchBtn",
						trigger: () => (
							<Button id="LMIQuickSearchBtn" className="quick-search-btn" icon={{ name: "search", color: "blue" }} onClick={() => this.toggleSearch()} />
						)
					} as ToursIntroPopupProps)}
				/>
				<Dimmer className="search-dimmer" active={open} onClick={() => this.props.toggleQuickSearch(false)} />
				{open && (
					<div className="search-pop">
						<Input
							id="SearchInput"
							name="search"
							placeholder="Search LoopMeIn"
							icon={{ name: "search", color: "blue" }}
							iconPosition="left"
							onFocus={() => this.setState({ openFilterPanel: false })}
							onChange={debounce((e, data) => this.setState({ searchFilter: data.value }), 350)}
							autoFocus={true}
							autoComplete="off"
						/>
						<Popup
							className="filter-popup"
							position="bottom right"
							content={this.filterPopContent(currentResultTypes)}
							trigger={
								<Button
									id="SearchFilterBtn"
									circular
									icon={openFilterPanel ? "close" : "filter"}
									className={`filter-btn ${openFilterPanel ? "open" : ""}`}
									onClick={() => this.setState({ openFilterPanel: !openFilterPanel })}
								/>
							}
						/>
						<Label circular size="tiny" className="filter-count" content={currentResultTypes.length} />
						{openFilterPanel && this.getFilterPanel(showResults)}
						{showResults && (
							<QuickSearchResults
								tracking_path={this.props.tracking.getTrackingData().event_subtype}
								term={searchFilter}
								resultTypes={resultTypes}
								openFilters={() => this.setState({ openFilterPanel: true })}
								inTour={inTour}
							/>
						)}
					</div>
				)}
				{this.getTourElems()}
			</span>
		);
	}

	getTourElems() {
		return (
			<>
				<span id="OpenQuickSearch" onClick={() => this.setState({ inTour: true }, () => this.props.toggleQuickSearch(true))} />
				<span id="OpenFilterTour" onClick={() => this.setState({ openFilterPanel: true })} />
				<span id="QuickSearchTour" onClick={() => this.setState({ searchFilter: "2019" })} />
				<span id="CloseQuickSearchTour" onClick={() => this.setState({ inTour: false }, () => this.props.toggleQuickSearch(false))} />
			</>
		);
	}

	keyPressCtrl(event) {
		// open on (ctrl + shift + s)
		if (event.ctrlKey && event.shiftKey && event.keyCode === 83) this.props.toggleQuickSearch(true);
		// close on escape
		if (event.keyCode === 27) this.props.toggleQuickSearch(false);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.keyPressCtrl.bind(this), false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyPressCtrl.bind(this), false);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		quickSearchOpen: state.app.admin.quickSearchOpen,
		routes: state.app.admin.routes
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		toggleQuickSearch: open => {
			dispatch(toggleQuickSearch(open));
		}
	};
};

export const QuickSearch = compose(connect(mapStateToProps, mapDispatchToProps))(QuickSearchView) as React.ComponentType<any>;

export default QuickSearch;
