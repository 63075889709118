// node_modules
import * as React from "react";
// components
import { SortableTable } from "../../SortableTable";
import "./TransportationTabPanel.css";

const tableData: any[] = [
	{
		description: "Glass Chip",
		cost: "$15/each",
		last30days: "14",
		total: "107",
		priority: "primary"
	},
	{
		description: "Windshield Repair",
		cost: "TBD",
		last30days: "2",
		total: "8",
		priority: "primary"
	},
	{
		description: "Headlight Restoration",
		cost: "$5/each",
		last30days: "0",
		total: "2",
		priority: "primary"
	},
	{
		description: "Buffing",
		cost: "$40/each",
		last30days: "0",
		total: "0",
		priority: "backup"
	}
];

const tableHeaders: any = [
	{
		id: "description",
		label: "Service Description",
		sortable: true
	},
	{
		id: "cost",
		label: "Cost",
		sortable: true
	},
	{
		id: "last30days",
		label: "Last 30 Days",
		sortable: true
	},
	{
		id: "total",
		label: "Total",
		sortable: true
	},
	{
		id: "priority",
		label: "Priority",
		sortable: true
	}
];

const sortableTableData: LMI.ITableData = {
	headers: tableHeaders,
	body: {
		rows: Array.from(tableData)
	}
};

export class TransportationTabPanel extends React.Component<LMI.ITransportationTPProps, undefined> {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="transportation-tab-panel panel-content">
				<SortableTable tableData={sortableTableData} />
			</div>
		);
	}
}

export default TransportationTabPanel;
