// node_modules
import "react-image-lightbox/style.css";

import * as React from "react";
import Lightbox from "react-image-lightbox";
import { Loading } from "../Loading";

export class PhotoCarousel extends React.Component<LMI.LBPhotoCarouselProps, LMI.LBPhotoCarouselState> {
	constructor(props: LMI.LBPhotoCarouselProps) {
		super(props);

		this.state = {
			active: props.photo_index ? props.photo_index : 0,
			photos: [],
			captions: []
		};
	}

	static getDerivedStateFromProps(nextProps: LMI.LBPhotoCarouselProps) {
		const { photos } = nextProps;
		if (photos && photos.length > 0) {
			const mapProp = prop => photos.map(obj => obj[prop]);
			return { photos: mapProp("url"), captions: mapProp("caption") };
		} else return null;
	}

	render() {
		const { active, photos, captions } = this.state;
		if (!photos || photos.length <= 0)
			return (
				<div className="lightbox-loading">
					<Loading />
				</div>
			);

		const caption = captions[active];
		const next = (active + 1) % photos.length;
		const prev = (active + photos.length - 1) % photos.length;

		return (
			<Lightbox
				imageCaption={caption ? caption.caption : null}
				imageTitle={caption ? caption.title : null}
				mainSrc={photos[active]}
				nextSrc={photos[next]}
				prevSrc={photos[prev]}
				onCloseRequest={() => this.props.onCloseLightbox()}
				onMovePrevRequest={() => this.setState({ active: prev })}
				onMoveNextRequest={() => this.setState({ active: next })}
			/>
		);
	}
}
