// node_modules
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
// redux
import { changeSubtab } from "api/redux/actions";
import { Loading } from "client/components/Loading";
import { buildTabTarget } from "client/components/Menu/utils";
import SubTabContainers from "client/components/Routes/SubTabContainers";
// components
import { TabsContainer, TCProps } from "client/pages/admin/components/ReactTabsContainer";
import { stringToInt } from "client/utils/functions";
import { flowRight as compose } from "lodash";
import { AnalyticsEventType, StoreType } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import track from "react-tracking";

@track(
	props => {
		return { event_type: AnalyticsEventType.NAVIGATION, event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}tabs` };
	},
	{ dispatchOnMount: true }
)
export class VendorsTabsComponentView extends React.Component<LMI.IVendorsTPProps, LMI.IVendorsTPState> {
	constructor(props) {
		super(props);

		this.state = {
			vendorId: props.vendorId,
			subRouteId: "contact"
		};
	}

	render() {
		const { hasErrors, message, loading, store_approved_vendors, vendorId, queryComplete } = this.props;

		if (hasErrors) {
			console.log("Vendors Details GQL Errors", message);
			return null;
		}
		if (loading) return <Loading />;

		let selected = store_approved_vendors.find(vend => vend.vendor.id.toString() === vendorId);
		if (!selected) selected = store_approved_vendors[0];
		const isInActive = selected && !selected.is_active;
		return <span>{queryComplete || isInActive ? <TabsContainer {...this.getTabProps(selected.vendor.id.toString())} /> : ""}</span>;
	}

	componentWillUnmount() {
		this.props.changeSubtab(null);
	}

	isInternalVendor = () => {
		const store = this.props.store_approved_vendors.find(v => v.vendor.id === parseInt(this.props.vendorId, 10));
		return store && store.vendor.store_type_id === StoreType.InternalVendor ? true : false;
	};

	getTabProps(vendorId: string): TCProps {
		const { subRouteId } = this.state;
		const vendorActive = this.props.store_approved_vendors && this.props.store_approved_vendors.find(vend => vend.vendor.id.toString() === vendorId).is_active;
		const autoApproveTask =
			this.props.store_approved_vendors && this.props.store_approved_vendors.find(vend => vend.vendor.id.toString() === vendorId).auto_approve_tasks;
		const autoApproveTaskReq =
			this.props.store_approved_vendors && this.props.store_approved_vendors.find(vend => vend.vendor.id.toString() === vendorId).auto_approve_task_requests;

		const tabProps: TCProps = {
			subRouteId,
			subTabContainer: SubTabContainers.vendors,
			permissions: this.props.permissions,
			classes: "vendors-tab-panel-tabs",
			viewType: `${this.props.viewType} sub-component`,
			dataPassThru: {
				vendorId,
				vendorName: this.props.store_approved_vendors && this.props.store_approved_vendors.find(v => v.vendor.id.toString() === vendorId).vendor.name,
				storeId: this.props.storeId,
				orgId: stringToInt(localStorage.getItem("selectedOrgId")),
				is_active: vendorActive,
				auto_approve_tasks: autoApproveTask,
				auto_approve_task_requests: autoApproveTaskReq,
				refetchAll: this.props.refetch,
				refreshBadge: () => {
					this.props.refetchMissing();
				},
				tracking_path: this.props.tracking.getTrackingData().event_subtype
			},
			onChange: (tabSelected: string) => this.selectedTab(tabSelected)
		};
		if (this.props.vendor_missing_documents) {
			tabProps.badges = this.buildBadges(this.props);
		}
		return tabProps;
	}

	buildBadges(props: any) {
		const badges = [];
		// vendor documents badge
		if (props.vendor_missing_documents && props.vendor_missing_documents.length) {
			const documents = {
				id: "vendors-business-documents",
				color: "red",
				value: props.vendor_missing_documents.length
			};
			badges.push(documents);
		}
		return badges;
	}

	selectedTab(tab) {
		this.props.changeSubtab(tab);
		this.props.history.push(buildTabTarget(this.props.match.path, tab));
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		return { subRouteId: nextProps.match ? nextProps.match.params.id : prevState.subRouteId };
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		changeSubtab: (tabName: string) => {
			dispatch(changeSubtab(tabName));
		}
	};
};

export const VendorsTabsComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.vendor.missingDocuments, {
		skip: (ownProps: any) => {
			if (!ownProps || !ownProps.store_approved_vendors || ownProps.store_approved_vendors.length <= 0) return true;
			const selected = ownProps.store_approved_vendors.find(vend => vend.vendor.id.toString() === ownProps.vendorId);
			return selected && !selected.is_active;
		},
		options: (props: any) => {
			return {
				variables: {
					vendorId: stringToInt(props.vendorId),
					storeId: stringToInt(props.storeId)
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, vendor_missing_documents, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, error };

			return {
				vendor_missing_documents,
				queryComplete: true,
				refetchMissing: refetch
			};
		}
	})
)(VendorsTabsComponentView) as React.ComponentType<any>;

export default VendorsTabsComponent;
