import { graphql } from "@apollo/react-hoc";
import { Form, TextArea, Input } from "formsy-semantic-ui-react";
import { gqlQueries } from "gql-imports";
import { MaskedInputComponent } from "client/pages/admin/components/MaskedInputComponent";
import { restAPI } from "client/utils/rest";
import { flowRight as compose } from "lodash";
import { Validation, YUP } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Dimmer,
  Divider,
  Header,
  Icon,
  Label,
  Loader,
} from "semantic-ui-react";
import * as yup from "yup";
import { validateEmail, validateDto } from "../../../../../../utils/functions";

export type ShareForm = yup.InferType<typeof Validation.Auth.Store.Share.ShareForm>;

enum NotificationMethod {
	Email = 2,
	Sms = 3,
	None = 4
}

export class LeadFormComponentView extends React.Component<LMI.IInventoryLeadFormProps, LMI.IInventoryLeadFormState> {
	leadForm: any;
	constructor(props) {
		super(props);
		this.state = {
			name_is_valid: false,
			email_is_valid: false,
			phone_is_valid: false,
			phone_type_valid: false,
			phone_type: "",
			field_name: "",
			field_error: "",
			selected_splash: 0,
			sending: false,
			lead_link: null,
			lead_text: null,
			lead_error: null
		};
	}

	// componentDidMount(): void {
	// 	logAction("Lead Creation Form");
	// }

	validateEmail = (event: any) => {
		this.setState({ email_is_valid: validateEmail(event.currentTarget.value) });
	};

	/**
	 * Return the phone number type from Twilio
	 * @param phone: string
	 */
	getPhoneType = async (phone: string) => {
		const method = "phone_lookup";
		let carrierType = null;
		await restAPI({
			endpointName: method,
			urlArgs: [`?phone=${phone}`],
			data: null,
			callback: (error, result): string => {
				if (!error) {
					carrierType = result.data.result.response.carrier.type as string;
				} else {
					return error;
				}
			}
		});
		return carrierType;
	};

	/**
	 * Remove all non-numerics from string
	 * @param phoneNum: string
	 */
	cleanPhone(phoneNum): string {
		const cleaned = ("" + phoneNum).replace(/\D/g, "");
		return cleaned;
	}

	handlePhoneTypeCheck = async phone => {
		const cleanPhone = await this.cleanPhone(phone);
		const phoneType = await this.getPhoneType(cleanPhone);
		if (phoneType !== "mobile") {
			this.setState({ phone_type_valid: false, phone_type: phoneType });
			return;
		}
		this.setState({ phone_type_valid: true, phone_type: phoneType });
	};

	submitLead = async notification_method => {
		const leadValues = this.leadForm.formsyForm.getCurrentValues();
		Object.keys(leadValues).forEach(key => (leadValues[key] == null || leadValues[key] === "") && delete leadValues[key]);

		const { splash, detail } = this.props;
		const { selected_splash } = this.state;

		const selectedSplash = splash[selected_splash];
		const splashData =
			selectedSplash && selectedSplash.coverpage_id ? { coverpage_id: selectedSplash.coverpage_id } : { coverpage_type_id: selectedSplash.coverpage_type_id };
		const leadData = {
			notification_method,
			...leadValues,
			...splashData,
			...{ validate_phone: false }
		};
		const shareValidation = Validation.Auth.Store.Share;
		const shareForm: ShareForm = {
			name: leadValues.customer_name,
			email: leadValues.customer_email,
			phone: leadValues.customer_phone
		};
		const validateShareForm = await validateDto(shareValidation.ShareForm, shareForm);
		if (validateShareForm.inner && validateShareForm.inner.length) {
			this.setState({ field_name: validateShareForm.inner[0].path, field_error: validateShareForm.inner[0].message });
			this.setState({ sending: false, lead_link: null });
			return;
		}

		this.setState({ sending: true });
		restAPI({
			endpointName: "createVehicleLead",
			urlArgs: [this.props.storeId, detail.id],
			data: leadData,
			callback: (error, result) => {
				if (!error) {
					this.setState({
						sending: false,
						lead_link: result.data.link,
						lead_text: result.data.message
					});

					// logAction("Lead Created", {
					// 	e_c: "Lead Created",
					// 	e_a: "createdVehicleLead",
					// 	e_n: result.data.link
					// });
				} else {
					this.setState({ sending: false, lead_link: null, lead_error: error.error });
				}
			}
		});
	};

	getModalHeader = detail => {
		return <Header as="h2" content={`#${detail && detail.stock_number ? detail.stock_number : ""} | ${detail ? detail.vehicle_title : ""}`} />;
	};

	render() {
		const { email, detail, splash, onClose, loading } = this.props;
		const {
			phone_type,
			phone_type_valid,
			name_is_valid,
			email_is_valid,
			phone_is_valid,
			selected_splash,
			sending,
			lead_link,
			lead_text,
			lead_error
		} = this.state;
		const splashImages = splash ? splash : [];
		const errorLabel = <Label color="red" pointing="above" />;

		if (lead_error)
			return (
				<div className="share-form-content">
					{this.getModalHeader(detail)}
					<Divider />
					<div className="center">
						<Icon name="attention" color="red" size="huge" />
						<Header content="We're Sorry" />
						<p>{lead_error}</p>
						<Button content="Done" onClick={() => onClose()} />
					</div>
				</div>
			);

		if (lead_link)
			return (
				<div className="share-form-content">
					{this.getModalHeader(detail)}
					<Divider />
					<div className="center">
						<Header content={lead_text} />
						<Button content="View Lead Link" onClick={() => window.open(lead_link, "_blank")} />
						<Button content="Done" onClick={() => onClose()} />
					</div>
				</div>
			);

		return (
			<Form
				className="dialog-lead-form"
				ref={node => {
					this.leadForm = node;
				}}
			>
				<div className="share-form-content head">
					{this.getModalHeader(detail)}
					<Header as="h3" color="grey" content="Share this vehicle with a potential customer" />
				</div>

				{loading || sending ? (
					<Dimmer active>
						<Loader />
					</Dimmer>
				) : (
					<div className="share-form-content">
						<Form.Field className="relative">
							<label>Name (required)</label>
							<Input
								type="text"
								name="customer_name"
								required
								onChange={(e, data) => this.setState({ name_is_valid: data.value.length > 1 })}
								validationErrors={{
									isDefaultRequiredValue: "Name is required."
								}}
								errorLabel={errorLabel}
							/>
						</Form.Field>
						<Form.Field>
							<label>Email Address</label>
							<Input type="text" name="customer_email" value={email} onChange={this.validateEmail} />
						</Form.Field>
						<Form.Field>
							<label>Mobile Phone</label>
							<MaskedInputComponent
								{...{
									type: "phone",
									name: "customer_phone",
									value: null,
									onChange: async (value, phone_is_valid) => {
										this.setState({ phone_is_valid, phone_type: "" });
										if (phone_is_valid) {
											// Let's check the number for type
											await this.handlePhoneTypeCheck(value);
										}
									}
								}}
							/>
							{phone_type && phone_type.length && (
								<Label color={phone_type !== "mobile" ? "red" : "green"} pointing="above" size="mini" style={{ marginTop: 0 + "em" }}>
									{phone_type}
								</Label>
							)}
						</Form.Field>
						<Form.Field>
							<label>Message</label>
							<TextArea rows={3} cols={40} name="customer_message" />
						</Form.Field>
						<Form.Field>
							<label>
								Cover Page <small>(select one)</small>
							</label>
							<div className="splash-images">
								<Card.Group itemsPerRow={splashImages.length < 3 ? 2 : 3}>
									{splashImages.map((i, index) => {
										return (
											<Card
												key={index}
												className={`splash${selected_splash === index ? " selected" : ""}`}
												onClick={() => this.setState({ selected_splash: index })}
												image={i.full_url}
											/>
										);
									})}
								</Card.Group>
							</div>
						</Form.Field>
					</div>
				)}

				{!sending && !lead_link ? (
					<div className="btn-dock">
						<div className="btns">
							<Button.Group>
								<Button
									positive
									type="button"
									className="submit-btn"
									disabled={!name_is_valid || !email_is_valid}
									onClick={() => this.submitLead(NotificationMethod.Email)}
								>
									Email
								</Button>
								<Button.Or />
								<Button
									color="teal"
									type="button"
									className="submit-btn"
									disabled={!name_is_valid || !phone_is_valid || !phone_type_valid}
									onClick={() => this.submitLead(NotificationMethod.Sms)}
								>
									SMS
								</Button>
								<Button.Or />
								<Button
									color="blue"
									type="button"
									className="submit-btn"
									disabled={name_is_valid && (email_is_valid || (phone_is_valid && phone_type_valid)) ? false : true}
									onClick={() => this.submitLead(NotificationMethod.None)}
								>
									Get Link
								</Button>
							</Button.Group>
						</div>
					</div>
				) : (
					""
				)}
			</Form>
		);
	}
}

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const LeadForm = compose(
  connect(mapStateToProps, mapDispatchToProps),
  graphql<any, any, any, ClassAttributes<any>>(
    gqlQueries.dealership.inventoryCoverImages,
    {
      options: (props: LMI.IInventoryLeadFormProps) => {
        return {
          variables: {
            store_id: props.storeId,
            inventory_item_id: props.detail.id,
          },
          fetchPolicy: "network-only",
        };
      },
      props: ({ data: { error, loading, inv_cover_images, refetch } }): any => {
        if (loading) return { loading: true };
        if (error) return { hasErrors: true, message: error };
        return {
          splash: inv_cover_images.inv_cover_images,
          refetch,
        };
      },
    }
  )
)(LeadFormComponentView) as React.ComponentType<any>;

export default LeadForm;
