import * as React from "react";
import { Button, Grid, Loader, Checkbox, List, Dimmer, Segment, Header } from "semantic-ui-react";
import TimePicker from "react-time-picker";
import { days } from "client/utils/globals";
import "./HoursComponent.css";

enum editPresets {
	"all" = 1,
	"Weekday" = 2,
	"Weekend" = 3
}

export class HoursComponent extends React.Component<LMI.HoursComponentProps, LMI.HoursComponentState> {
	defaultHours = { open: "0900", close: "1900" };

	setHours = (hours: LMI.IStoreOpeningPeriod[]) => {
		return hours && hours.length
			? hours
			: days.map((d, index) => {
					return {
						day: index,
						open: true,
						day_text: days[index],
						open_time: this.defaultHours.open,
						close_time: this.defaultHours.close
					} as LMI.IStoreOpeningPeriod;
			  });
	};

	constructor(props: LMI.HoursComponentProps) {
		super(props);
		this.state = {
			edited: false,
			editPreset: null,
			editPresetValues: this.defaultHours,
			hours: this.setHours(props.hours ? props.hours.days : null),
			setForFirstTime: props.hours ? false : true
		};
	}

	getTimePickerFormat = (time, open) => {
		return time ? time.replace(/(..?)/g, "$1:").slice(0, -1) : open ? "07:00" : "20:00";
	};

	handleClosed = (item, open) => {
		this.setState({
			edited: true,
			hours: this.state.hours.map((day, i) => {
				const newDay = !open && i === item ? { open: !open, open_time: this.defaultHours.open, close_time: this.defaultHours.close } : { open: !open };
				return i === item ? { ...day, ...newDay } : day;
			})
		});
	};

	handleTimeChange = (time, item, open) => {
		this.setState({
			edited: true,
			hours: this.state.hours.map((day, i) => {
				const stime = time.replace(/:/g, "");
				const openOrClosed = open ? { open_time: stime } : { close_time: stime };
				return i === item ? { ...day, ...openOrClosed } : day;
			})
		});
	};

	handlePresetTimeSet = () => {
		const { editPresetValues, editPreset, hours } = this.state;
		this.setState({
			edited: true,
			editPreset: null,
			hours: hours.map((d: LMI.IStoreOpeningPeriod, index: number) => {
				let day = d;
				const withPreset = {
					...d,
					...{
						open_time: editPresetValues.open,
						close_time: editPresetValues.close
					}
				};
				switch (editPreset) {
					case editPresets[1]:
						day = withPreset;
						break;
					case editPresets[2]:
						if (index > 0 && index < 6) {
							day = withPreset;
						}
						break;
					case editPresets[3]:
						if (index === 0 || index === 6) {
							day = withPreset;
						}
						break;
					default:
						break;
				}
				return day;
			})
		});
	};

	render() {
		const { hours, edited, editPreset, editPresetValues, setForFirstTime } = this.state;
		const timePickerOptions = {
			clockIcon: null,
			clearIcon: null,
			className: "timePicker",
			clockClassName: "clock"
		};

		if (!hours) {
			return <Loader />;
		}

		return (
			<div id="Hours-form">
				{editPresets[editPreset] !== undefined ? (
					<Grid columns="equal">
						<Grid.Column>
							<strong>Set {editPreset} Hours</strong>
						</Grid.Column>
						<Grid.Column>
							<span className="label">Open</span>
							<TimePicker
								{...timePickerOptions}
								value={this.getTimePickerFormat(editPresetValues.open, true)}
								onChange={time => {
									const editPresetValues = this.state.editPresetValues;
									editPresetValues.open = time.replace(/:/g, "");
									this.setState({ editPresetValues });
								}}
							/>
						</Grid.Column>
						<Grid.Column>
							<span className="label">Close</span>
							<TimePicker
								{...timePickerOptions}
								value={this.getTimePickerFormat(editPresetValues.close, false)}
								onChange={time => {
									const editPresetValues = this.state.editPresetValues;
									editPresetValues.close = time.replace(/:/g, "");
									this.setState({ editPresetValues });
								}}
							/>
						</Grid.Column>
						<Grid.Column>
							<Button.Group size="mini">
								<Button content="Cancel" onClick={() => this.setState({ editPreset: null })} />
								<Button.Or />
								<Button content="Submit" positive onClick={() => this.handlePresetTimeSet()} />
							</Button.Group>
						</Grid.Column>
					</Grid>
				) : (
					<List bulleted horizontal>
						<List.Item as="a" onClick={() => this.setState({ editPreset: editPresets[1] })}>
							All Hours
						</List.Item>
						<List.Item as="a" onClick={() => this.setState({ editPreset: editPresets[2] })}>
							Weekday Hours
						</List.Item>
						<List.Item as="a" onClick={() => this.setState({ editPreset: editPresets[3] })}>
							Weekend Hours
						</List.Item>
					</List>
				)}
				<Dimmer.Dimmable as={Segment} dimmed={editPresets[editPreset] !== undefined ? true : false}>
					{hours.map((field, index) => {
						const disabled = field.open === false ? true : false;
						return (
							<Grid key={index}>
								<Grid.Column width={2}>
									<strong>{field.day_text}</strong>
								</Grid.Column>
								<Grid.Column width={4}>
									<span className="label">Open</span>
									<TimePicker
										{...timePickerOptions}
										disabled={disabled}
										value={this.getTimePickerFormat(field.open_time, true)}
										onChange={time => this.handleTimeChange(time, index, true)}
									/>
								</Grid.Column>
								<Grid.Column width={4}>
									<span className="label">Close</span>
									<TimePicker
										{...timePickerOptions}
										disabled={disabled}
										value={this.getTimePickerFormat(field.close_time, false)}
										onChange={time => this.handleTimeChange(time, index, false)}
									/>
								</Grid.Column>
								<Grid.Column width={6}>
									<Checkbox label="Closed" defaultChecked={!field.open} onChange={(evt, data) => this.handleClosed(index, data.checked)} />
								</Grid.Column>
							</Grid>
						);
					})}
					<Dimmer active={editPresets[editPreset] !== undefined ? true : false}>
						<Header as="h2" content={`Setting ${editPreset} Hours`} icon inverted />
					</Dimmer>
				</Dimmer.Dimmable>
				<Button
					positive
					content="SAVE HOURS"
					disabled={!edited && !setForFirstTime}
					onClick={() =>
						this.props.onSubmit({
							...this.props.hours,
							...{
								days: this.state.hours
							}
						})
					}
				/>
			</div>
		);
	}
}

export default HoursComponent;
