// node_modules
import * as React from "react";
import { RegularExpressions } from "client/utils/regex";
// components
import { Form, Input } from "formsy-semantic-ui-react";
import { Button } from "semantic-ui-react";
import StateSelector from "client/components/StateSelector/StateSelector";

export class EditAddressComponent extends React.Component<
  LMI.IEditAddressProps,
  LMI.IEditAddressState
> {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address,
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (data) => {
    this.setState((state) => ((state.address.state = data.value), state));
  };

  zipValidation() {
    if (this.props.store_country_code === "US") {
      return "isNumeric,minLength:5,maxLength:5";
    } else {
      return "minLength:6,maxLength:6";
    }
  }

  zipValidationErrors() {
    if (this.props.store_country_code === "US") {
      return {
        isNumeric: "Only numbers allowed",
        minLength: "Must be at least 5 digits",
        maxLength: "Can only be 5 digits",
        isDefaultRequiredValue: "Postal code is required",
      };
    } else {
      return {
        minLength: "Must be at least 6 digits",
        maxLength: "Can only be 6 digits",
        isDefaultRequiredValue: "Postal code is required",
      };
    }
  }

  render() {
    const props = this.props;
    return (
      <div className="edit-address">
        <Form
          noValidate
          onSubmit={(data) => {
            Object.assign(data, { state: this.state.address.state });
            props.updateAddress(data);
          }}
        >
          <Form.Field>
            <label>Address</label>
            <Input
              name="address1"
              value={props.address && props.address.address1}
              required
            />
          </Form.Field>
          <Form.Field>
            <label>Address 2</label>
            <Input
              name="address2"
              value={props.address && props.address.address2}
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>City</label>
              <Input
                name="city"
                value={props.address && props.address.city}
                required
                validations={{
                  matchRegexp: RegularExpressions.CITY,
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>State/Province</label>
              <StateSelector
                {...{
                  value: props.address ? props.address.state : null,
                  placeholder: "Select State/Province",
                  onChange: (data) => this.handleChange(data),
                }}
              />
            </Form.Field>
            <Form.Field>
              <label>Postal</label>
              <Input
                name="zip"
                value={props.address && props.address.zip}
                required
                validations={this.zipValidation()}
                validationErrors={this.zipValidationErrors()}
              />
            </Form.Field>
          </Form.Group>
          <Button content="Submit" positive />
        </Form>
      </div>
    );
  }
}

export default EditAddressComponent;
