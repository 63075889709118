import { getTabRoutes } from "client/components/Routes/utils";

/**
 * A factory class used to create components that are displayed as the
 * panel for a tab.
 *
 * @export
 * @class TabsFactory
 */
export class TabsFactory {
	/**
	 * Get a list of data used to render tab panel components.
	 *
	 * @param {string[]} tabs - A list of tabs that will be used to populate a component.
	 * @param {(string[] | number[])} permissions - A list of user permissions which is used to filter out tabs
	 * the user can not access.
	 * @memberof TabsFactory
	 */
	getTabsList(tabs: string[], permissions: string[] | number[]): LMI.IAppRouteData[] {
		return getTabRoutes(tabs, permissions);
	}
}

export default TabsFactory;
