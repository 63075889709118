import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { Permission } from "loopmein-shared";
import { hasPermission } from "client/utils/userAccess";
import { addAlert } from "api/redux/actions";
import { restAPI } from "client/utils/rest";

import { confirmDefault, ConfirmDialog } from "client/components/ConfirmDialog";
import { ModalComponent } from "../../../ModalComponent";
import { EditInventoryForm, LeadForm } from "./";
import { Dropdown, Icon, Popup } from "semantic-ui-react";
import { Session } from "client/utils/session";
import AddTaskForm from "client/components/AddTasks/AddTaskForm";

import "./ActionMenu.css";

export class InventoryActionMenuView extends React.Component<LMI.InventoryActionMenuProps, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			confirm: null,
			show_dialog: false,
			create_lead: false,
			edit_item: false,
			add_tasks: false
		};
	}

	render() {
		const { storeId, detail, is_from_dms, permissions } = this.props;
		const { confirm, create_lead, show_dialog, edit_item, add_tasks } = this.state;
		const canEditManualInventory = hasPermission(permissions, Permission.EDIT_INVENTORY, Session.get("isSuperUser")) && !is_from_dms;
		const canDeleteManualInventory = hasPermission(permissions, Permission.REMOVE_INVENTORY, Session.get("isSuperUser")) && !is_from_dms;
		const canAddTasks = hasPermission(permissions, Permission.ADD_TASK, Session.get("isSuperUser"));

		return [
			<ConfirmDialog key="confirm" {...confirm} />,
			<ModalComponent
				key="leadFormComp"
				size="tiny"
				className="docked-modal"
				shouldBeOpen={create_lead && show_dialog}
				onClose={(evt, data) => this.setState({ create_lead: false, show_dialog: false })}
				contentComponent={() => (
					<LeadForm
						{...{
							storeId,
							detail,
							onClose: () => this.setState({ create_lead: false, show_dialog: false })
						}}
					/>
				)}
			/>,
			<ModalComponent
				key="AddTaskForm"
				headerText="Add Tasks to this Vehicle"
				shouldBeOpen={add_tasks}
				size="small"
				onClose={() => this.setState({ add_tasks: false })}
				contentComponent={() => (
					<AddTaskForm
						open_tasks={detail.tasks}
						inventory_item_id={detail.id}
						onSave={() => this.setState({ add_tasks: false }, () => this.props.refetchDetail())}
					/>
				)}
			/>,
			<ModalComponent
				key="editInventoryComp"
				size="tiny"
				className="docked-modal"
				shouldBeOpen={edit_item && show_dialog}
				onClose={(evt, data) => this.setState({ edit_item: false, show_dialog: false })}
				headerText={`Edit Inventory`}
				contentComponent={() => (
					<EditInventoryForm
						{...{
							storeId,
							detail,
							onUpdate: (error, result) => this.setState({ edit_item: false, show_dialog: false }, () => this.updateInventory(error, result)),
							onClose: () => this.setState({ edit_item: false, show_dialog: false })
						}}
					/>
				)}
			/>,
			<Popup
				key="actionBtn"
				inverted
				size="mini"
				position="top right"
				trigger={
					<Dropdown
						id="InventoryDetailActionBtn"
						button
						item
						icon={{ name: "bolt", className: "dd-as-btn-icon" }}
						direction="left"
						className="tool-btn circular no-print">
						<Dropdown.Menu className="inv-action-menu">
							{canAddTasks && (
								<Dropdown.Item onClick={() => this.setState({ show_dialog: true, add_tasks: true })}>
									<Icon name="wrench" color="grey" />
									Add Tasks
								</Dropdown.Item>
							)}
							{detail.is_active && (
								<Dropdown.Item
									onClick={() =>
										window.open(`${process.env.REACT_APP_DOX_URL}/vin/${detail.vin}?store_code=${localStorage.getItem("selectedStoreCode")}`, "_blank")
									}>
									<img className="vinfo-icon" src="/images/vinfo_icon.svg" />
									Get VINFO
								</Dropdown.Item>
							)}
							{/* <Dropdown.Item
                onClick={() => InventoryDetailComponent.printDetail(detail)}
              >
                <Icon name="print" color="orange" />
                Print
              </Dropdown.Item> */}
							<Dropdown.Item onClick={() => this.setState({ show_dialog: true, create_lead: true })}>
								<Icon name="share" color="blue" />
								New Lead
							</Dropdown.Item>
							{canEditManualInventory && (
								<Dropdown.Item onClick={() => this.setState({ show_dialog: true, edit_item: true })}>
									<Icon name="pencil" color="grey" />
									Edit
								</Dropdown.Item>
							)}
							{canDeleteManualInventory && (
								<Dropdown.Item onClick={() => this.removeInventory()}>
									<Icon name="delete" color="grey" />
									Remove
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>
				}
				content="ACTIONS"
			/>
		];
	}

	updateInventory(error, result) {
		this.props.refetchDetail();
		setTimeout(() => {
			this.props.setUpdateKey(new Date());
			this.props.addAlert({
				type: error ? "danger" : "success",
				message: error ? error.message : result.data.message
			});
		}, 400);
	}

	removeInventory() {
		const { storeId, detail, setUpdateKey } = this.props;
		this.setState({
			confirm: {
				open: true,
				title: `Please Confirm`,
				text: `Are you sure you want to remove this inventory?`,
				success: () => {
					restAPI({
						endpointName: "inv_manual_remove",
						urlArgs: [storeId, detail.id],
						data: null,
						callback: (error, result) => {
							this.setState({ confirm: confirmDefault }, () => {
								setTimeout(() => {
									setUpdateKey(new Date());
									this.props.addAlert({
										type: error ? "danger" : "success",
										message: error ? error.message : result.data.message
									});
								}, 400);
							});
						}
					});
				},
				failure: () => this.setState({ confirm: confirmDefault })
			}
		});
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const InventoryActionMenu = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.isManualInventory, {
		options: (props: any) => {
			return {
				variables: {
					store_id: parseInt(props.storeId, 10),
					inventory_item_id: props.inventory_item_id
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, inventory_detail, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				is_from_dms: inventory_detail ? inventory_detail.is_from_dms : false,
				refetch
			};
		}
	})
)(InventoryActionMenuView) as React.ComponentType<any>;

export default InventoryActionMenu;
