import * as React from "react";
import { Redirect } from "react-router";
import { Button, Container, Header, Icon, Loader } from "semantic-ui-react";

import "./NotFound.css";

export class NotFound extends React.Component<LMI.INotFoundScreenProps, LMI.INotFoundScreenState> {
	loadTimer = undefined;
	constructor(props: LMI.INotFoundScreenProps) {
		super(props);
		this.state = {
			redirect: null,
			noStores: false,
			notFound: false
		};
	}

	render() {
		const { notFound, redirect, noStores } = this.state;
		if (notFound) return this.notFound();
		if (noStores) return this.noStores();
		else if (redirect) return <Redirect to={redirect} />;
		else
			return (
				<Loader active size="massive">
					Loading Data
				</Loader>
			);
	}

	notFound() {
		return (
			<Container className="not-found-page page" textAlign="center">
				<div className="not-found-title">
					<Header as="h2" icon>
						<Icon name="bug" />
						Page Not Found
						<Header.Subheader>
							We're sorry, the page you requested could not be found.
							<br />
							If you believe this to be an error, please contact your account administrator.
						</Header.Subheader>
					</Header>
					<br />
					<br />
					<Button positive size="large" content="Administration Screen" onClick={() => this.setState({ notFound: false, redirect: "/" })} />
				</div>
			</Container>
		);
	}

	noStores() {
		return (
			<Container className="not-found-page page" textAlign="center">
				<div className="not-found-title">
					<Header as="h2" icon>
						<Icon name="settings" />
						Missing Employment Information
						<Header.Subheader>
							We are unable to find records of your employment at organizations that use LoopMeIn.
							<br />
							If you believe this to be an error, please contact your account administrator.
						</Header.Subheader>
					</Header>
				</div>
			</Container>
		);
	}

	startLoadTimer() {
		// show loader for 3 seconds before showing the not found screen
		const { redirect, noStores } = this.state;
		if (!redirect && !noStores) this.loadTimer = setTimeout(() => this.setState({ notFound: true }), 3000);
	}

	componentDidMount() {
		this.startLoadTimer();
	}

	componentWillUnmount() {
		if (this.loadTimer) clearTimeout(this.loadTimer);
	}

	static getDerivedStateFromProps(nextProps: LMI.INotFoundScreenProps, prevState: LMI.INotFoundScreenState) {
		const hasAuth = !!localStorage.getItem("token");
		if (!hasAuth) localStorage.setItem("deeplink", `${window.location.pathname}${window.location.search}`);
		const redirect = !hasAuth ? "/login" : null;
		return { redirect, noStores: nextProps.noStores };
	}
}

export default NotFound;
