// node_modules
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
// components
import { List, Checkbox } from "semantic-ui-react";
import "./PermissionsComponent.css";

export class PermissionsComponentView extends React.Component<LMI.IPermissionsCProps, LMI.IPermissionsCState> {
	constructor(props: LMI.IPermissionsCProps) {
		super(props);

		this.state = {};
	}
	render() {
		const props = this.props;
		return (
			<div className="permissions-component">
				<div className="pc-content">
					<h3>Permissions</h3>
					<List>
						{props.permissions.map(perm => {
							return (
								<List.Item key={perm.id}>
									<Checkbox
										checked={props.storePermissions.find(permId => permId === perm.id) ? true : false}
										label={perm.description}
										onClick={(evt, data) => {
											props.onClick(data, perm);
										}}
									/>
								</List.Item>
							);
						})}
					</List>
				</div>
			</div>
		);
	}

	filterDuplicatePermissions(permissions: number[]): number[] {
		const dupes = [];
		const perms = [].concat(permissions).filter(perm => {
			if (dupes.indexOf(perm) === -1) {
				dupes.push(perm);
				return true;
			}
			return false;
		});
		return perms;
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const PermissionsComponent = compose(connect(mapStateToProps, mapDispatchToProps))(PermissionsComponentView) as React.ComponentType<any>;

export default PermissionsComponent;
