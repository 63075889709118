import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-splitbuttons/styles/material.css";

import * as React from "react";
import { Loading } from "client/components/Loading";
import { debounce, flowRight as compose } from "lodash";
import { connect } from "react-redux";
import { addAlert } from "api/redux/actions";
import { restAPI } from "../../../../../utils/rest";
import { handleErrorResponse, RelativeDatePipe } from "../../../../../utils/functions";
import { InventoryConditionString, Permission, SystemLogType } from "loopmein-shared";
import { hasPermission } from "client/utils/userAccess";

import { Button, Form, Icon, Popup, TextArea } from "semantic-ui-react";
import { ModalComponent } from "client/pages/admin/components/ModalComponent/ModalComponent";
import { PhaseForm } from "../InventoryDetailTabs/Info/components/PhaseForm";
import { PhaseTimer, WorkflowService, WorkTimer } from "../../WorkflowTools";
import { InventoryActionMenu, DetailTools, DetailTotalTimer, DetailCumulativePhaseTimer } from "./components";
import { Session } from "client/utils/session";

import "./InventoryDetailHeader.css";

interface detailActions {
	id: string;
	icon?: any;
	className?: string;
	content?: any;
	disabled?: boolean;
	callback?: () => void;
}

export class InventoryDetailHeaderView extends React.Component<LMI.IInventoryDetailHeaderProps, LMI.DetailHeaderState> {
	workInterval: any;
	constructor(props: LMI.IInventoryDetailHeaderProps) {
		super(props);

		this.state = {
			show_work_timer: false,
			has_phase_owners: null,
			thresholds: {
				warning: false,
				error: false,
				clear: false
			},
			update_key: null,
			modal_open: false,
			note_modal_open: false,
			comment: null,
			selected_phase: null,
			tour_work_timer: null,
			tour_workflow_controls: null
		};
	}

	static getDerivedStateFromProps(nextProps: LMI.IInventoryDetailHeaderProps, prevState) {
		const { detail, dealer_phases, dealer_phasers } = nextProps;
		let newState = {
			...prevState,
			show_work_timer:
				detail &&
				InventoryConditionString[detail.condition] !== InventoryConditionString.New &&
				hasPermission(nextProps.permissions, Permission.EDIT_INSPECTION, Session.get("isSuperUser"))
		};
		if (!newState.phase_owners)
			newState.has_phase_owners = WorkflowService.getPhaseOwners({
				dealer_phases,
				dealer_phasers,
				detail
			});
		if (detail && dealer_phases)
			newState = {
				...newState,
				...{ thresholds: WorkflowService.getThreshold(dealer_phases, detail) }
			};
		return newState;
	}

	componentDidUpdate(prevProps, prevState) {
		const missingPhases = !this.props.dealer_phases || (this.props.dealer_phases && this.props.dealer_phases.length <= 0);
		if (missingPhases && prevState.tour_workflow_controls !== this.state.tour_workflow_controls) {
			if (this.state.tour_workflow_controls) {
				const tours: any = document.querySelectorAll(".tour-template");
				if (tours && tours.length > 0) {
					const createLabel = name => {
						const div = document.createElement("div");
						div.textContent = name;
						div.className = "ui mini label orange missing_workflow_label";
						return div;
					};
					tours.forEach(node => {
						node.appendChild(createLabel("Setup Workflow Stages to use Workflow Tools"));
					});
				}
			} else {
				const warnings: any = document.querySelectorAll(".missing_workflow_label");
				if (warnings && warnings.length > 0)
					Array.prototype.forEach.call(warnings, function (node) {
						node.parentNode.removeChild(node);
					});
			}
		}
	}

	closePhaseFormModal = () => {
		this.setState({ modal_open: false, selected_phase: null, comment: null }, () => this.props.refetchDetail());
	};

	render() {
		const { loading, detail, dealer_phases } = this.props;
		const { thresholds, modal_open, note_modal_open } = this.state;

		if (!detail)
			return (
				<div>
					<Loading />
				</div>
			);

		const { phase, current_phase } = detail;
		const showWorkFlowTools = current_phase && dealer_phases && dealer_phases.length > 0 && !loading;
		const inThreshold = `${
			phase && phase.final_stage
				? "gray"
				: thresholds && thresholds.error
				? "red"
				: thresholds && thresholds.warning
				? "yellow"
				: thresholds && thresholds.clear
				? "green"
				: "gray"
		}`;

		const formProps = {
			detail,
			onClose: this.closePhaseFormModal,
			onHandleErrorResponse: error => handleErrorResponse(error),
			onAlert: alert => this.sendAlert(alert),
			completedPhase: () => {
				this.props.refetchDetail();
				setTimeout(() => {
					if (this.props.setUpdateKey) this.props.setUpdateKey(new Date());
					this.setUpdateKey(new Date());
					this.closePhaseFormModal();
				}, 300);
			}
		};

		return (
			<div id="InventoryDetailHeader">
				{(modal_open || note_modal_open) && (
					<ModalComponent
						headerText={note_modal_open ? "Add Vehicle Note" : `Change Stage: ${phase.name}`}
						size="small"
						shouldBeOpen={modal_open || note_modal_open}
						onClose={() => this.setState({ modal_open: false, note_modal_open: false })}
						className="phase-modal"
						contentComponent={() => {
							if (note_modal_open) return this.getNoteForm();
							return <PhaseForm {...formProps} />;
						}}
					/>
				)}
				<div className={`top-bar ${inThreshold}`}>
					<span className="stock-badge">STOCK: {detail.stock_number}</span>
					<strong>
						<div className="ymm">
							<span className="condition">{detail.condition.toLowerCase() === "used" ? "PRE-OWNED" : detail.condition.toUpperCase()}</span> {detail.year}{" "}
							{detail.make} {detail.model} <span className="extras">| ODO: {Number(detail.miles).toLocaleString("en")}</span>
							<Popup
								trigger={<span className={!detail.is_active ? "sold-tag" : ""}>{!detail.is_active && "SOLD"}</span>}
								content={`Sold Date - ${
									detail.date_removed_from_inventory ? RelativeDatePipe(new Date(detail.date_removed_from_inventory), false, false, false) : "Not Found"
								}`}
							/>
						</div>
					</strong>
					{showWorkFlowTools && this.getWorkflowTimers(inThreshold)}
				</div>

				<div className={`bot-bar`}>
					<span className={`vin-box ${inThreshold}-back`}>VIN: {detail.vin}</span>
					<DetailTools {...this.props} />
					{!loading && this.getToolBar()}
				</div>
				{this.tourCtrl()}
			</div>
		);
	}

	openDetailScreen() {
		if (this.props.detail && this.props.detail.inventory_id)
			window.open(`/admin/dealerships/inventory-detail/${this.props.detail.inventory_id}?store=${this.props.storeId}`, "_blank");
	}

	getJobTitleID(user) {
		const job = user && user.employees ? user.employees.find(emp => emp.store.id === this.props.storeId) : null;
		return job && job.job_title_id ? job.job_title_id : null;
	}

	getToolBar() {
		const { is_sub, close_btn, detail, dealer_phases, dealer_phasers, currentUser } = this.props;
		const actionBtns: detailActions[] = [
			{
				id: "notes",
				icon: "quote left",
				className: "note",
				content: "ADD NOTE",
				callback: () => this.setState({ note_modal_open: true })
			},
			{ id: "action-menu" }
		];
		if (is_sub)
			actionBtns.push({
				id: "expand",
				icon: "expand arrows alternate",
				className: "expand",
				content: "EXPAND",
				callback: () => this.openDetailScreen()
			});
		if (close_btn)
			actionBtns.push({
				id: "close",
				icon: "close",
				className: "close",
				content: "CLOSE",
				callback: () => this.props.closeBtnCallback()
			});
		if (dealer_phases.length > 0) {
			const canCompleteStage =
				WorkflowService.isPhaseOwnerOrHasJobTitle({
					dealer_phasers,
					detail,
					job_title_id: this.getJobTitleID(currentUser)
				}) || hasPermission(this.props.permissions, Permission.STAGE_MANAGER, Session.get("isSuperUser"));
			const disableCompleteStage = (detail.phase && detail.phase.final_stage) || !canCompleteStage;
			// ||
			// (!detail.is_active && detail.dispositioned_at && hide_on_sold_and_dispositioned) ||
			// (!detail.is_active && !hide_on_sold_and_dispositioned);
			actionBtns.push({
				id: "completeStage",
				icon: "check",
				className: `complete-stage ${disableCompleteStage ? "disabled" : "enabled"}`,
				content: `${disableCompleteStage ? "" : "COMPLETE"}`,
				disabled: disableCompleteStage,
				callback: () => (!disableCompleteStage ? this.handleCompletePhase() : console.log("not allowed"))
			});
		}

		return (
			<div id="InventoryDetailTools" className={`detail-tools ${dealer_phases.length <= 0 ? "no-stages" : ""}`}>
				{actionBtns.map(({ id, icon, className, content, disabled, callback }, key) => {
					if (id === "action-menu") return <InventoryActionMenu key="ActionMenu" inventory_item_id={detail.id} {...this.props} />;
					else
						return (
							<Popup
								key={key}
								disabled={disabled}
								inverted
								size="mini"
								position="bottom center"
								trigger={<Icon id={`DetailTools${id}`} className={`tool-btn ${className}`} circular name={icon} onClick={() => callback()} />}
								content={content}
							/>
						);
				})}
			</div>
		);
	}

	getNoteForm = () => {
		const { comment } = this.state;
		return [
			<Form key="commentForm" noValidate>
				<div id="phaseBody" className="recon-form">
					<TextArea id="comment" rows={10} placeholder="Add Vehicle Note" value={comment ? comment : ""} onChange={this.handleChangeComment.bind(this)} />
				</div>
			</Form>,
			<div key="submitCommentForm" className="cta-footer">
				<Button
					className="submit"
					id="complete"
					disabled={!this.state.comment || this.state.comment === ""}
					positive
					content="Add Note"
					onClick={debounce(this.handleSubmitNote.bind(this), 500)}
				/>
			</div>
		];
	};

	handleChangeComment = event => {
		this.setState({ comment: event.currentTarget.value });
	};

	handleSubmitNote = () => {
		const { storeId, detail, addAlert } = this.props;
		const { comment } = this.state;
		const data = {
			type: SystemLogType.VEHICLE_NOTE,
			comment,
			description: detail.current_phase_name
		};
		restAPI({
			endpointName: "addInventoryNote",
			urlArgs: [storeId, detail.id],
			data,
			callback: error => {
				if (error) addAlert({ type: "danger", message: handleErrorResponse(error) });
				this.setState({ note_modal_open: false, comment: null }, () => this.props.updateNotes());
			}
		});
	};

	getTimerBlock = timerProps => {
		const { detail } = this.props;
		const { update_key } = this.state;
		const { inThreshold } = timerProps;

		const detailTimerProps = { ...timerProps, useFull: false };
		return (
			<span id="dayHourMinute" key={update_key}>
				<span className={`production ${update_key === "started" ? "green" : ""}`}>PRODUCTION</span>
				<span className={`work ${update_key === "started" ? "green" : ""}`}>
					<WorkTimer key={detail.id} {...timerProps} />
				</span>
				<span className="total">TOTAL</span>
				<span className="stage-total">
					<DetailCumulativePhaseTimer key={detail.id} {...detailTimerProps} />
				</span>
				<span className="recon-total">
					<DetailTotalTimer key={detail.id} {...detailTimerProps} />
				</span>
				<span className={`backdrop ${inThreshold}-back`} />
				<span className={`stage ${inThreshold}`}>
					<PhaseTimer key={timerProps.phase_timer_running_since_ms} {...timerProps} />
				</span>
				<div className="dhm-label">
					<span className="total-stage">STAGE</span>
					<span className="total-recon">RECON</span>
					<span className={`current`}>{detail.phase && detail.phase.final_stage ? "SINCE DISPOSITION" : "CURRENT STAGE"}</span>
				</div>
			</span>
		);
	};

	getWorkflowTimers(inThreshold: string = "gray") {
		const { storeId, detail } = this.props;
		const { update_key, show_work_timer, has_phase_owners } = this.state;
		const { phase } = detail;

		const phase_timer_running_since_ms = detail && detail.phase_timer_running_since ? new Date(parseInt(detail.phase_timer_running_since, 10)) : new Date();
		const stopped = !update_key || update_key === "stopped";

		const timerProps = {
			...detail,
			inThreshold,
			store_id: storeId,
			detail,
			phase,
			update_key,
			phase_timer_running_since_ms,
			onHandleErrorResponse: error => handleErrorResponse(error),
			onAlert: alert => this.sendAlert(alert),
			setUpdateKey: isRunning => this.setUpdateKey(isRunning)
		} as any;

		return (
			<div id="WorkflowControls" className="workflow-timers">
				{!has_phase_owners && (
					<span className="timer job-warning">
						<Popup
							position="bottom center"
							style={{ backgroundColor: "#fffaf3", color: "#573a08" }}
							trigger={<Icon name="warning sign" color="orange" />}
							content={
								<span>
									No <u>Users</u> or <u>Job Titles</u> are assigned to this Stage.
								</span>
							}
						/>
					</span>
				)}
				<span id="StageProductionTimer" className={`timer st ${inThreshold}`}>
					<span
						className={`production-section ${show_work_timer ? "" : "disabled"}`}
						id={!update_key || update_key === "stopped" ? "start" : "hold"}
						onClick={!update_key || update_key === "stopped" ? this.handleStartTimer : this.handleStopTimer}
						data-phase-id={detail.current_phase}
						data-inventory-id={detail.id}>
						<Popup
							trigger={
								<Icon
									className={`play_pause_btn ${stopped ? "" : "pause"}${!show_work_timer ? "disabled" : "enabled"}`}
									name={stopped ? "play circle" : "pause circle"}
								/>
							}
							content={`${!show_work_timer ? "Disabled" : "Play/Pause Production Timer"}`}
							position="left center"
							size="tiny"
							inverted
							disabled={!show_work_timer}
						/>
					</span>
					{this.getTimerBlock(timerProps)}
				</span>
			</div>
		);
	}

	handleCompletePhase() {
		const { detail } = this.props;
		if (detail.phase && detail.phase.final_stage) return false;
		this.setState({ modal_open: true });
	}

	handleStartTimer = (event: any) => {
		if (!this.state.show_work_timer) return false;
		const phaseId = parseInt(event.currentTarget.getAttribute("data-phase-id"), 10);
		const inventoryId = parseInt(event.currentTarget.getAttribute("data-inventory-id"), 10);
		const data = { store_phase_id: phaseId };
		restAPI({
			endpointName: "startWorkTimer",
			urlArgs: [this.props.storeId, inventoryId],
			data,
			callback: error => {
				if (error)
					this.props.addAlert({
						type: "danger",
						message: handleErrorResponse(error)
					});
				this.setState({ update_key: "started" }, () => {
					if (this.props.setUpdateKey) this.props.setUpdateKey("started");
					this.props.refetchDetail();
				});
			}
		});
	};

	handleStopTimer = (event: any) => {
		if (!this.state.show_work_timer) return false;
		const phaseId = parseInt(event.currentTarget.getAttribute("data-phase-id"), 10);
		const inventoryId = parseInt(event.currentTarget.getAttribute("data-inventory-id"), 10);
		const data = { store_phase_id: phaseId };
		restAPI({
			endpointName: "stopWorkTimer",
			urlArgs: [this.props.storeId, inventoryId],
			data,
			callback: error => {
				if (error)
					this.props.addAlert({
						type: "danger",
						message: handleErrorResponse(error)
					});
				this.setState({ update_key: "stopped" }, () => {
					this.clearTimers();
					if (this.props.setUpdateKey) this.props.setUpdateKey("stopped");
					this.props.refetchDetail();
				});
			}
		});
	};

	setUpdateKey(isRunning) {
		this.setState({ update_key: isRunning });
	}

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
		if (type === "danger") {
			console.log(`Error: ${message}`);
		}
	}

	clearTimers = () => {
		for (const index in (window as any).workInterval) clearInterval((window as any).workInterval[index]);
		for (const index in (window as any).phaseInterval) clearInterval((window as any).phaseInterval[index]);
	};

	tourCtrl() {
		return (
			<>
				<span id="OpenTourWorkFlowControls" onClick={() => this.setState({ tour_workflow_controls: true })} />
				<span id="OpenTourWorkTimer" onClick={() => this.setState({ tour_work_timer: true })} />
				<span
					id="CloseTourWorkTimer"
					onClick={() =>
						this.setState({
							tour_work_timer: false,
							tour_workflow_controls: false
						})
					}
				/>
			</>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		employeeId: state.app.admin.employeeId,
		currentUser: state.app.admin.currentUser
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const InventoryDetailHeader = compose(connect(mapStateToProps, mapDispatchToProps))(
	InventoryDetailHeaderView
) as React.ComponentType<LMI.IInventoryDetailHeaderProps>;

export default InventoryDetailHeader;
