import * as Raven from "raven-js";
import React from "react";
import ApolloClient from "apollo-client";
import history from "./utils/history";
import { Session } from "client/utils/session";
import { resetStore } from "api/redux/actions";
import { useGlobals } from "api/redux/hooks";
import { useQuery } from "@apollo/react-hooks";
import { Router } from "react-router-dom";
import { LmiRoutes } from "./components/Routes";
import { gqlQueries } from "gql-imports";
import { NormalizedCacheObject } from "apollo-cache-inmemory";

import { Dimmer, Loader } from "semantic-ui-react";
import { Header } from "./components/Header";
import { AlertComponent } from "./pages/admin/components/AlertComponent";
import { StoreType } from "loopmein-shared";
import { persistedStorage } from "client/utils/globals";

import LmiTour from "./components/Tours/Tours";
import AddInventory from "./components/AddInventory/AddInventoryModal";
import DataSyncManager from "./components/DataSync/DataManager";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
// import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

import "./App.css";
import "./styles/main.css";
import "./styles/accounts-basic.css";
import "./styles/calendar.min.css";

/* Theme variables */
// import './theme/variables.css';
// Where you started the midtier server

class AppComponent extends React.Component<LMI.IAppProps, LMI.IAppState> {
	constructor(props) {
		super(props);

		console.log("ENV", process.env.REACT_APP_REST_URL);
		this.state = {
			permissions: [],
			groups: [],
			storeName: "Pick a Store",
			isTourOpen: false,
			isLoggingOut: false
		};
	}

	render() {
		if (this.state.isLoggingOut)
			return (
				<Dimmer active inverted>
					<Loader inverted size="massive">
						Logging Out...
					</Loader>
				</Dimmer>
			);

		const headerProps: LMI.IHeaderProps = {
			...this.props,
			history,
			sharePages: ["share", "unsubscribe", "invoice-editor"],
			logoutFunc: evt => this.setState({ isLoggingOut: true }, () => this.logout(evt))
		};

		return (
			<Router history={history}>
				<div className="App">
					<AlertComponent />
					<Header {...headerProps} />
					<section id="Main-Content" className={this.contentClass()}>
						<div className="contentContainer">
							<LmiRoutes {...Object.assign({}, this.props, { history })} />
						</div>
					</section>
					<LmiTour />
					<AddInventory />
					<DataSyncManager {...this.props} />
				</div>
			</Router>
		);
	}

	contentClass() {
		let mainClass = `main-container${!this.props.showHeader ? " headerless" : ""}`;
		if (!this.props.authenticated) mainClass = "public-utility";
		else if (localStorage.getItem("isu") === "true") mainClass = "super-user";
		return mainClass;
	}

	updateRootData(data: any) {
		if (data.storeName) this.setState({ storeName: data.storeName });
		this.props.refetch();
	}

	clearLocalStorage() {
		const exceptions = [];
		persistedStorage.forEach(exc => {
			const val = localStorage.getItem(exc);
			if (val) exceptions.push({ exc, val });
		});
		localStorage.clear();
		exceptions.forEach(item => localStorage.setItem(item.exc, item.val));
	}

	logout(event) {
		event && event.preventDefault();
		this.clearLocalStorage();

		Session.set("userId", undefined);
		Session.set("stores", undefined);
		Session.set("storeTypeIds", undefined);
		Session.set("email", undefined);
		Session.set("fullName", undefined);
		Session.set("isSuperUser", undefined);
		Session.set("userType", undefined);

		try {
			resetStore();
			history.push("/");
			window.location.reload();
		} catch (e) {
			console.log("Logout error: ", e);
			Raven.captureException(e);
		}
	}

	shouldComponentUpdate(nextProps: any, nextState: any) {
		if (nextState.isLoggingOut) return true;
		if (this.props.userType !== nextProps.userType) return true;
		if (this.props.authenticated !== nextProps.authenticated) return true;
		if (this.props.showHeader !== nextProps.showHeader) return true;
		if (this.props.storeId !== nextProps.storeId) return true;
		if (this.props.permissions !== nextProps.permissions) return true;
		if (nextProps.currentUser && !this.props.currentUser) return true;
		return false;
	}

	componentDidUpdate(prevProps) {
		if (this.props.tabSelected === "store" && this.props.userType === StoreType.Vendor) this.props.changeTab("vendors");
		if (!prevProps.currentUser && this.props.currentUser) this.props.updateCurrentUser(this.props.currentUser);
	}
}

const useGQL = () => {
	const query = useQuery(gqlQueries.me, {
		fetchPolicy: "network-only",
		skip: !!localStorage.getItem("token") === false || !!localStorage.getItem("token") === null ? true : false
	});
	if (query.error) console.error(query.error);
	return query;
};

export const App = (client: any & ApolloClient<NormalizedCacheObject>) => {
	const globals = useGlobals();
	const { loading, error, data, refetch } = useGQL();

	return (
		<AppComponent
			{...{
				...globals,
				client,
				authenticated: data?.me ? true : false,
				currentUser: data?.me,
				loggingIn: loading,
				hasErrors: error ? true : false,
				refetch
			}}
		/>
	);
};

export default App;

// const mapStateToProps = (state: any) => {
//   return {
//     storeId: state.app.admin.storeId,
//     permissions: state.app.admin.permissions,
//     subtabSelected: state.app.admin.subtabSelected,
//     userType: state.app.admin.userType,
//     showHeader: state.app.admin.showHeader,
//   };
// };

// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     changeTab: (tabname: string) => {
//       dispatch(changeTab(tabname));
//     },
//     updateTour: (steps: LMI.ITourStepGQL[]) => {
//       dispatch(updateTour(steps));
//     },
//   };
// };

// export const App = compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   graphql<any, any, any, React.ClassAttributes<any>>(gqlQueries.me, {
//     skip: () => {
//       return (
//         !!localStorage.getItem("token") === false ||
//         !!localStorage.getItem("token") === null
//       );
//     },
//     options: (props: any) => ({
//       fetchPolicy: "network-only",
//     }),
//     props: ({ data: { error, loading, me, refetch } }): any => {
//       if (loading) return { loggingIn: true, authenticated: false };
//       if (error) return { hasErrors: true, authenticated: false, error };
//       return {
//         currentUser: me,
//         authenticated: true,
//         loggingIn: false,
//         refetch,
//       };
//     },
//   })
// )(AppView) as React.ComponentType<any>;
