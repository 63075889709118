import * as React from "react";
import { Popup } from "semantic-ui-react";

export class DetailCumulativePhaseTimer extends React.Component<LMI.IPhaseTimerProps, LMI.IPhaseTimerState> {
	constructor(props: LMI.IPhaseTimerProps) {
		super(props);

		this.state = {
			loading: false,
			elapsed_time: null,
			running: false
		};
	}

	checkInterval() {
		if ((window as any).cumPhaseInterval) clearInterval((window as any).cumPhaseInterval[`${this.props.id}${this.props.current_phase}`]);
		else (window as any).cumPhaseInterval = {};
	}

	componentDidMount() {
		const { id, current_phase, phase_timer_running_since_ms, current_stage_cumulative_ms, dispositioned_at } = this.props;
		this.checkInterval();
		if (id) {
			const elapsed = current_stage_cumulative_ms;
			this.setState({ loading: true, elapsed_time: elapsed, running: false }, () => {
				if (!dispositioned_at && phase_timer_running_since_ms) {
					const interval = 1000;
					(window as any).cumPhaseInterval[`${id}${current_phase}`] = setInterval(() => {
						this.setState(prevState => ({
							running: true,
							elapsed_time: prevState.elapsed_time + interval
						}));
					}, interval);
				}
			});
		}
	}

	componentWillUnmount() {
		this.checkInterval();
	}

	calculateMilliseconds(phase) {
		const {
			warning_threshold_days: wDays,
			warning_threshold_hours: wHours,
			warning_threshold_minutes: wMinutes,
			error_threshold_days: eDays,
			error_threshold_hours: eHours,
			error_threshold_minutes: eMinutes
		} = phase;

		const wDayMS = wDays * 24 * 60 * 60 * 1000;
		const wHoursMS = wHours * 60 * 60 * 1000;
		const wMinutesMS = wMinutes * 60 * 1000;
		const wMS = wDayMS + wHoursMS + wMinutesMS;
		const eDayMS = eDays * 24 * 60 * 60 * 1000;
		const eHoursMS = eHours * 60 * 60 * 1000;
		const eMinutesMS = eMinutes * 60 * 1000;
		const eMS = eDayMS + eHoursMS + eMinutesMS;
		return { warning: wMS, error: eMS };
	}

	calculateElapsedTime(elapsedTime) {
		let ms = elapsedTime;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = Math.floor(ms / 24);
		return {
			days: days.toString().padStart(2, "0"),
			hours: hours.toString().padStart(2, "0"),
			minutes: minutes.toString().padStart(2, "0"),
			seconds: seconds.toString().padStart(2, "0")
		};
	}

	calculateElapsedMinimum(elapsedTime) {
		let ms = elapsedTime;
		const { useFull } = this.props;

		// convert milliseconds to seconds
		ms = ms / 1000;
		const seconds = Math.floor(ms % 60);
		ms = ms / 60;
		const minutes = Math.floor(ms % 60);
		ms = ms / 60;
		const hours = Math.floor(ms % 24);
		const days = Math.floor(ms / 24);

		if (days > 0) return days + ` D${useFull ? `ay${days > 0 ? "s" : ""}` : ""}`;
		if (hours > 0) return hours + ` H${useFull ? `our${hours > 0 ? "s" : ""}` : ""}`;
		if (minutes > 0) return minutes + ` M${useFull ? `inute${minutes > 0 ? "s" : ""}` : ""}`;
		if (seconds > 0) return `<1 M${useFull ? `inute${minutes > 0 ? "s" : ""}` : ""}`;

		return `0 M${useFull ? "inutes" : ""}`;
	}

	render() {
		const { elapsed_time } = this.state;
		const time = this.calculateElapsedMinimum(elapsed_time);
		const popTime = this.calculateElapsedTime(elapsed_time);

		return (
			<Popup
				position="bottom right"
				inverted
				trigger={<span key={elapsed_time}>{time}</span>}
				content={`${"Cumulative Stage Time:"} ${popTime.days}:${popTime.hours}:${popTime.minutes}:${popTime.seconds}`}
			/>
		);
	}
}
