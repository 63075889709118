import * as React from "react";
import { sliderDefaults } from "client/utils/globals";
import { Grid, Checkbox, Input } from "semantic-ui-react";
import { SliderComponent } from "@syncfusion/ej2-react-inputs";

interface InventoryFilterProps {
	disabled?: boolean;
	hide?: boolean;
	max_selling_price: number;
	age_range: any[];
	onAgeSliderChanged: (data) => void;
	price_range: any[];
	onPriceSliderChanged: (data) => void;
	showSold: boolean;
	toggleSold: (data) => void;
	search_text: string;
	searchChanged: (data) => void;
}

interface InventoryFilterState {
	search_text: string;
	active_search: boolean;
}

export class InventoryFilters extends React.Component<InventoryFilterProps, InventoryFilterState> {
	ageSlider: SliderComponent;
	priceSlider: SliderComponent;
	searchFilterId: string = "SearchInventoryInput";
	searchFilter: HTMLInputElement;

	constructor(props: InventoryFilterProps) {
		super(props);

		this.state = {
			search_text: props.search_text,
			active_search: false
		};
	}

	static getDerivedStateFromProps(nextProps: InventoryFilterProps, prevState: InventoryFilterState) {
		if (nextProps.search_text !== "") return { active_search: true };
		if (prevState.active_search && nextProps.search_text === "") return { search_text: "", active_search: false };
		return null;
	}

	getSliderTicks(mode: string): any {
		const { max_selling_price } = this.props;
		if (mode === "age") {
			return { placement: "After", largeStep: 250, smallStep: 100, showSmallTicks: true };
		} else {
			return {
				placement: "After",
				largeStep: max_selling_price ? max_selling_price : sliderDefaults.price_range[1],
				smallStep: 5000,
				showSmallTicks: false
			};
		}
	}

	onKeyPress(e) {
		if (e.key === "Enter") this.props.searchChanged(this.state.search_text);
	}

	componentDidUpdate() {
		if (this.ageSlider) this.ageSlider.value = this.props.age_range;
		if (this.priceSlider) this.priceSlider.value = this.props.price_range;
	}

	render() {
		const { disabled, hide, showSold, max_selling_price, age_range, price_range, onAgeSliderChanged, onPriceSliderChanged, toggleSold } = this.props;
		const { search_text } = this.state;

		return (
			<div className="inventory-filters">
				<div className="slide-wrap">
					{!hide && (
						<Grid>
							<Grid.Row>
								<Grid.Column className="sliders" width={11}>
									<Grid columns="equal">
										<Grid.Column className="age">
											{!disabled && (
												<>
													<div className="label-text age">Age (days)</div>
													<SliderComponent
														ref={g => (this.ageSlider = g)}
														id="ageSlider"
														type="Range"
														value={age_range}
														min={sliderDefaults.age_range[0]}
														max={sliderDefaults.age_range[1]}
														tooltip={{ isVisible: true }}
														ticks={this.getSliderTicks("age")}
														changed={onAgeSliderChanged.bind(this)}
													/>
												</>
											)}
										</Grid.Column>
										{max_selling_price > 0 && (
											<Grid.Column className="price">
												{!disabled && (
													<>
														<div className="label-text price">Selling Price ($1K)</div>
														<SliderComponent
															ref={g => (this.priceSlider = g)}
															id="priceSlider"
															type="Range"
															value={price_range}
															min={sliderDefaults.price_range[0]}
															max={max_selling_price}
															tooltip={{ isVisible: true }}
															ticks={this.getSliderTicks("price")}
															changed={onPriceSliderChanged.bind(this)}
														/>
													</>
												)}
											</Grid.Column>
										)}
										<Grid.Column className="soldtoggle">
											<Checkbox toggle id="sold" name="sold" checked={showSold} label="Sold" onChange={(e, data) => toggleSold(data)} />
										</Grid.Column>
									</Grid>
								</Grid.Column>
								<Grid.Column width={5} className="filter">
									<Input
										id={this.searchFilterId}
										icon={{ name: "search", link: true, onClick: () => this.props.searchChanged(this.state.search_text) }}
										className="inventory-search-input"
										placeholder="Search"
										value={search_text}
										autoComplete="off"
										onChange={(e, data) => this.setState({ search_text: data.value })}
										onKeyPress={this.onKeyPress.bind(this)}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					)}
				</div>
			</div>
		);
	}
}
