// node_modules
import * as React from "react";
// components
import { Icon, Reveal } from "semantic-ui-react";
import { EFDInputComponent } from "./components/EFDInputComponent";
import "./EditableFieldDisplay.css";

export class EditableFieldDisplay extends React.Component<LMI.IEFDProps, LMI.IEFDState> {
	constructor(props) {
		super(props);

		this.state = {
			editing: false,
			currentValue: props.startingValue
		};
	}

	render() {
		return (
			<div className="editable-field-display">
				{this.props.canEdit ? (
					<span className="actionbtns">
						<Icon
							link
							name={this.state.editing ? "cancel" : "edit"}
							color={this.state.editing ? "red" : "blue"}
							onClick={() => {
								this.onToggleState();
							}}
						/>
					</span>
				) : (
					""
				)}
				{this.props.title ? (
					<span>
						{this.props.title}: {this.props.inline ? "" : <br />}
					</span>
				) : (
					""
				)}
				{this.state.editing ? (
					<EFDInputComponent
						id={this.props.id}
						labeled={this.props.title === "" ? false : true}
						value={this.state.currentValue}
						onSubmit={this.onSubmitEdit.bind(this)}
						onCancel={this.onCancelEdit.bind(this)}
						type={this.props.type ? this.props.type : "text"}
						masked={this.props.masked ? this.props.masked : false}
						validationErrors={
							this.props.validationErrors
								? this.props.validationErrors
								: {
										isWords: "No numbers or special characters allowed",
										isDefaultRequiredValue: `${this.props.title} is required`
								  }
						}
					/>
				) : this.props.privatize ? (
					this.props.canEdit ? (
						<Reveal animated="fade">
							<Reveal.Content hidden>{this.state.currentValue}</Reveal.Content>
							<Reveal.Content visible>{this.privatize(this.state.currentValue)}</Reveal.Content>
						</Reveal>
					) : (
						this.privatize(this.state.currentValue)
					)
				) : (
					<strong className="value">
						{this.props.isEmail ? (
							<a href={`mailto:${this.state.currentValue}`} target="_top">
								{this.state.currentValue}
							</a>
						) : (
							this.state.currentValue
						)}
					</strong>
				)}
			</div>
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.startingValue !== this.props.startingValue) {
			this.setState({ currentValue: this.props.startingValue });
		}
	}

	privatize(toPrivatize: string, n: number = 4): string {
		let privatized: string = "";
		for (let i = 0; i < toPrivatize.length; i++) {
			if (i < toPrivatize.length - n) {
				privatized = `${privatized}*`;
			} else {
				privatized = `${privatized}${toPrivatize[i]}`;
			}
		}

		return privatized;
	}

	onSubmitEdit(currentValue: string) {
		const newState = {
			editing: false,
			currentValue
		};
		this.setState(newState);
		this.props.onClick(newState);
	}

	onCancelEdit() {
		this.setState({ editing: false });
	}

	onStartEdit() {
		this.setState({ editing: true });
	}

	onToggleState() {
		this.setState({ editing: !this.state.editing });
	}
}
