import * as React from "react";
import { TextArea } from "semantic-ui-react";

interface GrowingTextAreaProps {
	disabled: boolean;
	value: string;
	placeholder: string;
	name: string;
	autoFocus: boolean;
	style: any;
	onChange: (e, data) => void;
	onBlur: (e, data) => void;
}

export class GrowingTextArea extends React.Component<GrowingTextAreaProps, undefined> {
	container: React.RefObject<HTMLDivElement> = React.createRef();
	charWidth: number = null;
	maxRows: number = 15;

	rowHelper(value: string): number {
		let match = 72;
		let container: HTMLDivElement = null;
		const chars = value && value.length;

		if (this.container && this.container.current) container = this.container.current;
		if (container) {
			if (!this.charWidth) this.setCharWidth(container);
			match = Math.floor(container.offsetWidth / this.charWidth);
		}

		if (chars && chars > 0) {
			const chunks = value.match(new RegExp(`.{1,${Math.trunc(match + 20)}}`, "g"));
			if (chunks && chunks.length) return chunks.length > this.maxRows ? this.maxRows : chunks.length;
			else return 1;
		} else return 1;
	}

	render() {
		const { disabled, value, placeholder, name, style, onChange, onBlur, autoFocus } = this.props;
		return (
			<div ref={this.container}>
				<TextArea
					rows={this.rowHelper(value)}
					style={style}
					name={name}
					placeholder={placeholder}
					disabled={disabled}
					onChange={onChange.bind(this)}
					onBlur={onBlur.bind(this)}
					value={value}
					autoFocus={autoFocus}
				/>
			</div>
		);
	}

	setCharWidth(container: HTMLDivElement) {
		// temporarily create a span with a single char to get the width of this font-size char width
		const span = document.createElement("span");
		span.appendChild(document.createTextNode("e"));
		const char = container.appendChild(span);
		const charWidth = char.offsetWidth;
		container.removeChild(span);
		this.charWidth = charWidth;
	}
}

export default GrowingTextArea;
