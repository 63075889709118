import moment from "moment";
import * as React from "react";
import { rangeBucketDefaults } from "client/utils/globals";
import { Popup } from "semantic-ui-react";

export class AgeDetail extends React.Component<LMI.InventoryDetailProps, undefined> {
	buckets = ["grey", "grey", "orange", "red"];
	formatBucket = item => {
		// tslint:disable-next-line:one-variable-per-declaration
		let newRanges, usedRanges, certifiedRanges, eom, bucket;
		const age = item.days_in_stock >= 0 ? item.days_in_stock : 0;

		if (this.props.store_settings && this.props.store_settings.length) {
			this.props.store_settings.forEach(setting => {
				if (setting.name === "USED_INVENTORY_RANGE_BUCKET") {
					usedRanges = setting.value ? JSON.parse(setting.value) : rangeBucketDefaults.USED_INVENTORY_RANGE_BUCKET;
				} else if (setting.name === "NEW_INVENTORY_RANGE_BUCKET") {
					newRanges = setting.value ? JSON.parse(setting.value) : rangeBucketDefaults.NEW_INVENTORY_RANGE_BUCKET;
				} else if (setting.name === "CERTIFIED_INVENTORY_RANGE_BUCKET") {
					certifiedRanges = setting.value ? JSON.parse(setting.value) : rangeBucketDefaults.CERTIFIED_INVENTORY_RANGE_BUCKET;
				}
			});
		}

		switch (item.condition) {
			case "Used":
				bucket = this.getBucket(age, usedRanges);
				eom = this.getBucket(this.getEOM(age), usedRanges);
				break;
			case "New":
				bucket = this.getBucket(age, newRanges);
				eom = this.getBucket(this.getEOM(age), newRanges);
				break;
			case "Certified":
				bucket = this.getBucket(age, certifiedRanges);
				eom = this.getBucket(this.getEOM(age), certifiedRanges);
				break;
			default:
				bucket = this.getBucket(age, newRanges);
				eom = this.getBucket(this.getEOM(age), newRanges);
				break;
		}

		return { eom, bucket };
	};

	getBucket = (age, ranges) => {
		const bucket = { class: this.buckets[0], id: 0 };
		if (ranges && ranges.length) {
			ranges.forEach((range, index) => {
				if (age >= range[0]) {
					if (range[1] && range[1] >= age) {
						bucket.class = this.buckets[index];
						bucket.id = index + 1;
					} else if (!range[1]) {
						bucket.class = this.buckets[index];
						bucket.id = index + 1;
					}
				}
			});
		}
		return bucket;
	};

	getEOM(age) {
		const remainder = moment().endOf("month");
		const now = moment();
		const diff = remainder.diff(now, "days");
		return age + diff;
	}

	render() {
		const { detail } = this.props;
		const { eom, bucket } = this.formatBucket(detail);

		return (
			<span id="AgeDetail">
				<div>
					<span className="detail-label">AGE:</span>{" "}
					<Popup
						trigger={
							<span className={bucket && bucket.class ? "vehicle-details age " + bucket.class : "vehicle-details age"}>
								{detail.days_in_stock} Days {bucket ? `(Bucket ${bucket.id})` : ""}
							</span>
						}
						content={
							<span className={bucket && bucket.class ? "vehicle-details age " + bucket.class : "vehicle-details age"}>
								{detail.days_in_stock} Days {bucket ? `(Bucket ${bucket.id})` : ""}
							</span>
						}
					/>
				</div>
				{bucket && bucket.id < 4 && (
					<div>
						<span className="detail-label">EOM AGE:</span>{" "}
						<Popup
							trigger={
								<span className={"vehicle-details age " + eom.class}>
									<i className="sub">
										{this.getEOM(detail.days_in_stock)} DAYS AT EOM (Bucket {eom.id})
									</i>
								</span>
							}
							content={
								<span className={"vehicle-details age " + eom.class}>
									<i className="sub">
										{this.getEOM(detail.days_in_stock)} DAYS AT EOM (Bucket {eom.id})
									</i>
								</span>
							}
						/>
					</div>
				)}
			</span>
		);
	}
}

export default AgeDetail;
