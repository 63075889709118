import { gqlQueries } from "gql-imports";
import { updateTour } from "api/redux/actions";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { SortBySortOrder } from "client/utils/functions";
import "./HelpTours.css";

export class HelpToursView extends React.Component<LMI.IHelpToursProps, LMI.IHelpToursState> {
	unlisten: any;

	constructor(props: LMI.IHelpToursProps) {
		super(props);

		this.state = {
			currentPath: props.history.location.pathname,
			steps: []
		};
	}

	render() {
		const tours: LMI.ITourGQL[] = this.props.tours ? this.props.tours.filter(tour => tour.route) : [];
		const tourItems: any = [];
		if (tours && tours.length) {
			tours.sort(SortBySortOrder).forEach((tour: LMI.ITourGQL) => {
				tourItems.push(
					<Dropdown.Item
						key={tour.id}
						text={tour.name}
						onClick={() => {
							this.props.updateTour(tour.steps);
						}}
					/>
				);
			});
		}
		return (
			<Dropdown pointing button icon="question" className="icon top right">
				<Dropdown.Menu>
					<Dropdown.Header content="Available Screen Tours" />
					<Dropdown.Item key="features" text="Featured Tours" onClick={() => this.props.openFeatureTours()} />
					{tourItems.length ? tourItems : <></>}
				</Dropdown.Menu>
			</Dropdown>
		);
	}

	componentDidMount() {
		this.unlisten = this.props.history.listen(location => this.setState({ currentPath: location.pathname }));
	}

	componentDidUpdate(prevProps, prevState) {
		// if current path changes fetch new tours for that screen
		if (this.state.currentPath !== prevState.currentPath && this.props.refetch) this.props.refetch({ route: this.state.currentPath });
	}

	componentWillUnmount() {
		this.unlisten();
	}
}

const mapStateToProps = (state: any) => {
	return {
		tourSteps: state.app.admin.steps
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		updateTour: (steps: LMI.ITourStepGQL[]) => {
			dispatch(updateTour(steps));
		}
	};
};

export const HelpTours = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.tours.list, {
		options: (props: any) => {
			return {
				variables: { route: props.history.location.pathname },
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, tours, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				tours,
				refetch
			};
		}
	})
)(HelpToursView) as React.ComponentType<LMI.IHelpToursProps>;

export default HelpTours;
