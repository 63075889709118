import moment from "moment";
import * as Raven from "raven-js";
import { RegularExpressions } from "./regex";
import { Role, StoreType } from "loopmein-shared";

import * as yup from "yup";
import { YUP } from "loopmein-shared";
import { ObjectShape } from "yup/lib/object";

export function handleErrorResponse({ error, message = "" }) {
	console.log(error);
	if (typeof error === "string") JSON.parse(error);
	if (message && message.trim() !== "") {
		return message;
	} else if (error.reason && error.reason.response && error.reason.response.data) {
		return error.reason.response.data.message;
	} else if (error.message) {
		return error.message;
	}
}

export function handleSuccessResponse({ result, message = "Success!" }, index = 0) {
	if (result.data && result.data.message) message = result.data.message;
	else if (Array.isArray(result) && result[index] && result[index].data) message = result[index].data.message;
	return message;
}

/**
 * Will reformat phone number as it is entered
 * @param str
 */
export const formatPhone = str => {
	let phone = ("" + str).replace(/\D/g, "").substr(0, 10);
	const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
	if (match) {
		phone = `${match[1]}${match[2] ? "-" : ""}${match[2]}${match[3] ? "-" : ""}${match[3]}`;
	}
	return phone;
};

export function formatAddress(addr) {
	return `${addr.address1 ? addr.address1 : ""}${addr.address2 ? `, ${addr.address2}` : ""}, ${addr.city ? addr.city : ""}, ${addr.state ? addr.state : ""} ${
		addr.zip ? addr.zip : ""
	}`;
}

/**
 * Will order array of objects by property key
 * @param objects
 * @param key (key of property to sort on)
 */
export const orderByProperty = (objects, key, order) => {
	const compared = (a, b) => {
		const asc = a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
		const desc = a[key] < b[key] ? 1 : b[key] > a[key] ? 0 : -1;
		return order === "ASC" ? asc : desc;
	};
	return objects.sort(compared);
};

/**
 * Convert object list to array of objects
 */
export const convertObjectToArray = obj => {
	return Object.keys(obj).map(k => obj[k]);
};

/**
 * Will attempt to convert number to currency format.
 * @param value
 * @returns [string] - Converted number to a USD currency format with commas prefixed with $ symbol.
 */
export const formatCurrency = (value: any, digits) => {
	try {
		if (!value || typeof value !== "number") {
			return null;
		}
		const formatted = value.toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return "$" + formatted;
	} catch (error) {
		Raven.captureException(error);
		console.log(`There was an error formatting currency: ${error}`);
	}
};

/**
 * Will attempt to convert number to currency format including precision to hundredths.
 * @param value
 * @returns [string] - Converted number to a USD currency format with commas prefixed with $ symbol.
 */
export const formatPrecisionCurrency = (value: number, withPrecision: boolean) => {
	try {
		if (!value || typeof value !== "number") {
			return withPrecision ? "$0.00" : "$0";
		}
		let valueString = value.toString();
		let formatted;
		if (withPrecision) {
			formatted = valueString.length <= 2 ? "0" : valueString.substr(0, valueString.length - 2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			if (valueString.length === 1) {
				valueString = `0${valueString}`;
			}
			return `$${formatted}.${valueString.substr(-2)}`;
		} else return `$${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
	} catch (error) {
		Raven.captureException(error);
		console.log(`There was an error formatting currency: ${error}`);
	}
};

/**
 * Will attempt to format number with comma.
 * @param value
 * @returns [string] - Converted number to a USD currency format with commas prefixed with $ symbol.
 */
export const formatUSNumber = (value: number) => {
	try {
		if (!value || typeof value !== "number") {
			return "";
		}
		return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	} catch (error) {
		Raven.captureException(error);
		console.log(`There was an error formatting number: ${error}`);
	}
};

export const validateEmail = (email: string) => {
	const re = RegularExpressions.EMAIL;
	return re.test(String(email).toLowerCase());
};

export const validatePhone = (phone: string) => {
	const re = RegularExpressions.PHONE_NUMBER;
	return re.test(String(phone).toLowerCase());
};

/**
 *  * Tries to parse a string as an int.  If value is not a string or cannot be converted,
 * return original value
 * @param value
 */
export function stringToInt(value) {
	if (value == null) {
		// this will convert undefined to null
		return null;
	}
	// attempt to parse only if it is a string
	if (!(typeof value === "string" || value instanceof String)) {
		return value;
	}
	const result = parseInt(value.toString(), 10);
	if (isNaN(result)) {
		// return the original value if not a number
		return value;
	}
	return result;
}

export const cloneObject = (object: any): any => {
	return JSON.parse(JSON.stringify(object));
};

export const RelativeDatePipe = (value: any, lessDescriptive: boolean = false, isTimeStamp: boolean = false, includeTime: boolean = false) => {
	if (value !== null) {
		if (isTimeStamp) {
			value = parseInt(value, 10);
		}
		value = new Date(value);
	}
	let relativeDate = "";
	if (!moment(value).isValid()) {
		return relativeDate;
	}
	if (moment().isSame(moment(value), "day")) {
		/* is today */
		relativeDate = `Today at ${moment(value).format("h:mm a")}`;
		if (lessDescriptive) {
			relativeDate = "Today";
		}
	} else if (moment().diff(moment(value).startOf("day"), "day") === 1) {
		/* is yesterday */
		relativeDate = "Yesterday";
	} else if (Math.abs(moment().diff(moment(value).startOf("day"), "day")) <= 7) {
		/* is within 7 days */
		relativeDate = moment(value).format("dddd");
		if (!lessDescriptive && moment().diff(moment(value)) > 0) {
			relativeDate = "Last " + relativeDate;
		}
	} else if (moment().isSame(moment(value), "year")) {
		/* is within this year */
		relativeDate = moment(value).format("MMMM D");
		if (lessDescriptive) {
			relativeDate = moment(value).format("MMM D");
		}
	} else {
		/* did not meet any criteria above */
		relativeDate = moment(value).format("MMM D, YYYY");
	}

	if (includeTime) relativeDate += ` at ${moment(value).format("h:mm a")}`;
	return relativeDate;
};

export const BasicDateFormat = (value: any, isTimeStamp: boolean = false) => {
	if (value !== null) {
		if (isTimeStamp) value = parseInt(value, 10);
		value = new Date(value);
	}
	return moment(value).format("MMM D, YYYY");
};

/*
 * Differentiate array of objects to find the missing object in one array and return the index
 */
export const objectArrayDiff = (before, after) => {
	const pre = before.map(o => o.id);
	const post = after.map(o => o.id);
	const currentSet = new Set(pre);
	return post.findIndex(x => !currentSet.has(x));
};

/**
 * Compare two objects shallowly
 * @param a
 * @param b
 */
export const diffObjects = (a: any, b: any): string => {
	for (var key in a) {
		if (!(key in b) || a[key] !== b[key]) {
			return key;
		}
	}
	for (var key in b) {
		if (!(key in a) || a[key] !== b[key]) {
			return key;
		}
	}
	return "equal";
};

/**
 *
 * @param anchor
 * @param offset
 */
export const hashLinkScroll = (anchor: string, offset: number) => {
	// Push onto callback queue so it runs after the DOM is updated,
	// this is required when navigating from a different page so that
	// the element is rendered on the page before trying to getElementById.
	setTimeout(() => {
		const element = document.getElementById(anchor);
		if (element) {
			element.parentElement.scrollBy({
				top: -1 * offset + element.offsetTop,
				left: 0,
				behavior: "smooth"
			});
		}
	}, 0);
};

export const scrollElementIntoView = (anchor: string): void => {
	const element = document.getElementById(anchor);
	if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
};

export const removeNullProps = (data: any) => {
	for (const prop in data) {
		if (data[prop] === null || data[prop] === undefined) {
			delete data[prop];
		}
	}
	return data;
};

export const isEmptyObject = obj => {
	for (var prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop)) {
			return false;
		}
	}
	return true;
};

export const getStoreTypeAdminRoleID = type_id => {
	let roleId = null;
	switch (type_id) {
		case StoreType.Dealership:
			roleId = Role.DealershipAdmin;
			break;
		case StoreType.Vendor:
			roleId = Role.VendorAdmin;
			break;
		case StoreType.InternalVendor:
			roleId = Role.InternalVendorAdmin;
			break;
		default:
			break;
	}
	return roleId;
};

export const formatCamelCase = text => {
	return text.replace(/([A-Z])/g, " $1");
};

export const getStoreTypeOptions = () => {
	let types: { key: string; text: string; value: number }[] = [];
	for (const type in StoreType) {
		if (typeof StoreType[type] === "number") {
			types.push({
				key: type,
				text: formatCamelCase(type),
				value: StoreType[type] as any
			});
		}
	}
	return types;
};

export const filterDataSet = ({ searchString, item }) => {
	let keep = false;
	for (const key in item) {
		if (item.hasOwnProperty(key)) {
			if (
				item[key] &&
				(typeof item[key] === "string" || key === "year" || key === "model_year") &&
				item[key].toString().toLowerCase().includes(searchString.toLowerCase())
			) {
				console.log(`Matched on: ${key}`);
				keep = true;
				break;
			}
		}
	}
	return keep;
};

export const calculateElapsedDHMS = ({ age, label = false, useFull = true }) => {
	const elapsedTime = new Date().getTime() - +age;

	let ms = elapsedTime;

	// convert milliseconds to seconds
	ms = ms / 1000;
	const seconds = Math.floor(ms % 60);
	ms = ms / 60;
	const minutes = Math.floor(ms % 60);
	ms = ms / 60;
	const hours = Math.floor(ms % 24);
	const days = Math.floor(ms / 24);

	// console.log(elapsedTime, days, hours, minutes, seconds);

	let result: string = "";
	if (label) {
		if (days > 0) result += days + ` D${useFull ? `ay${days > 1 ? "s" : ""}` : ""} `;
		if (hours > 0) result += hours + ` H${useFull ? `our${hours > 1 ? "s" : ""}` : ""} `;
		if (minutes > 0) result += minutes + ` M${useFull ? `inute${minutes > 1 ? "s" : ""}` : ""} `;
		if (seconds > 0) result += seconds + ` S${useFull ? `econd${seconds > 1 ? "s" : ""}` : ""} `;
	} else {
		result += `${days.toString().padStart(2, "0")}`;
		result += `:${hours.toString().padStart(2, "0")}`;
		result += `:${minutes.toString().padStart(2, "0")}`;
		result += `:${seconds.toString().padStart(2, "0")}`;
	}
	return result;
};

export const calculateElapsedMinimum = ({ age, useFull = true }) => {
	const elapsedTime = new Date().getTime() - +age;

	let ms = elapsedTime;

	// convert milliseconds to seconds
	ms = ms / 1000;
	const seconds = Math.floor(ms % 60);
	ms = ms / 60;
	const minutes = Math.floor(ms % 60);
	ms = ms / 60;
	const hours = Math.floor(ms % 24);
	const days = Math.floor(ms / 24);

	// console.log(elapsedTime, days, hours, minutes, seconds);

	if (days > 0) return days + ` D${useFull ? `ay${days > 1 ? "s" : ""}` : ""}`;
	if (hours > 0) return hours + ` H${useFull ? `our${hours > 1 ? "s" : ""}` : ""}`;
	if (minutes > 0) return minutes + ` M${useFull ? `inute${minutes > 1 ? "s" : ""}` : ""}`;
	if (seconds > 0) return `<1 M${useFull ? `inute${minutes > 0 ? "s" : ""}` : ""}`;

	return `<1 M${useFull ? `inute${minutes > 0 ? "s" : ""}` : ""}`;
};

export const getWarningColor = ({ settings, created_at, is_completed }) => {
	if (!settings) return "";

	// ToDo: decouple this function from the sanitization specific vars
	const SANITIZATION_WARNING_MINUTES = parseInt(settings.filter(s => s.name === "SANITIZATION_WARNING_MINUTES")[0].value, 10);
	const SANITIZATION_ERROR_MINUTES = parseInt(settings.filter(s => s.name === "SANITIZATION_ERROR_MINUTES")[0].value, 10);
	let ms = new Date().getTime() - +created_at;
	const minutes = ms / 1000 / 60;

	let color = "grey";
	if (is_completed) color = "green";
	else if (minutes >= SANITIZATION_WARNING_MINUTES && minutes < SANITIZATION_ERROR_MINUTES) color = "yellow";
	else if (minutes >= SANITIZATION_ERROR_MINUTES) color = "orange";

	return color;
};

export const multiTokenFilter = ({ searchString, array }) => {
	if (array && array.length < 1) return array;
	const splitString = searchString.split(" ");
	if (splitString.length === 1) {
		return array.filter(i => filterDataSet({ searchString, item: i }));
	} else {
		const filtered = array.filter(item => {
			const matched = splitString.filter(str => {
				return filterDataSet({ searchString: str, item });
			});
			// be sure that the item was matched on all split strings
			return matched.length === splitString.length;
		});
		return filtered;
	}
};

// simple utility to merge and remove duplicates from an array
// using a set and converting back to an array
export const mergeDedupe = (arr): any[] => {
	return Array.from(new Set([].concat(...arr)));
};

export const SortBySortOrder = (a, b) => {
	return a.sort_order - b.sort_order;
};

export const SortAlphebeticalText = (a, b) => (a.text.toLowerCase() < b.text.toLowerCase() ? -1 : a.text.toLowerCase() > b.text.toLowerCase() ? 1 : 0);

export const base64 = ({ string, mode }): string => {
	const Base64 = {
		encode: string => {
			const bb = btoa(unescape(encodeURIComponent(string)));
			return bb;
		},
		decode: b64 => decodeURIComponent(escape(window.atob(b64)))
	};
	return mode === "encode" ? Base64.encode(string) : Base64.decode(string);
};

export const camelToSnake = endpoint => {
	return endpoint.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const getViewTypeSub = () => {
	const storeTypeId = localStorage.getItem("selectedStoreType") ? stringToInt(localStorage.getItem("selectedStoreType")) : null;
	switch (storeTypeId) {
		case StoreType.Dealership:
			return "dealerships";
		case StoreType.Vendor:
			return "vendors";
		case StoreType.InternalVendor:
			return "ivendors";
	}
};

// TODO: move this into shared
export const getPhaseColor = (phases: LMI.IPhasesGQL[], phaseId: number) => {
	const phase: any = phases && phaseId && phases.find(i => i.id === phaseId);
	return phase ? phase.color_code : "#777";
};

export const stringIsNumber = value => isNaN(Number(value)) === false;

export const sortByProperty = property => {
	let sortOrder = 1;
	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}
	return (a, b) => {
		const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
		return result * sortOrder;
	};
};

export const getSelectedTabStore = containerName => {
	const lsValue: number = parseInt(localStorage.getItem(`${containerName}_defaultTabIndex`), 10);
	return !isNaN(lsValue) && lsValue > -1 ? lsValue : 0;
};

export const shadeColor = (color, percent) => {
	if (color) {
		let R = parseInt(color.substring(1, 3), 16);
		let G = parseInt(color.substring(3, 5), 16);
		let B = parseInt(color.substring(5, 7), 16);

		const createTone = ct => {
			const tone = (ct * (100 + percent)) / 100;
			return tone.toString();
		};

		R = parseInt(createTone(R));
		G = parseInt(createTone(G));
		B = parseInt(createTone(B));

		R = R < 255 ? R : 255;
		G = G < 255 ? G : 255;
		B = B < 255 ? B : 255;

		const RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
		const GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
		const BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

		return "#" + RR + GG + BB;
	} else return "#777";
};

/**
 * Yup Validator
 * @param schema
 * @param data
 * @returns string
 * Checks all fields against a REGEX pattern
 */
export const validateDto = async <T extends ObjectShape>(schema: yup.ObjectSchema<any>, data) => {
	try {
		const validated = await YUP.validate(schema, data);
		return validated;
	} catch (error) {
		return error;
	}
};

/**
 * Allow only zero plus
 * @param val
 * @returns any
 */
export const zeroPlus = (val: number) => {
	return val !== null && val >= 0 ? val : null;
};

/**
 * Check target/value
 * @param target
 * @returns truthy
 */
export const targetHasValue = target => {
	const hasTarget = target && target.value;
	const replaceSpaces = hasTarget && hasTarget.replace(/\s\s+/g, "");
	return replaceSpaces && replaceSpaces.trim() !== "";
};

/**
 * Check target/value isNumeric
 * @param target
 * @returns truthy
 */
export const isNumeric = target => {
	const value = target && target.value;
	return value && !isNaN(parseFloat(value)) && isFinite(value);
};
