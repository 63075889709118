import * as React from "react";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { RelativeDatePipe } from "client/utils/functions";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { Button, Icon, Image, List, Message, Loader } from "semantic-ui-react";

export class ActivityListView extends React.Component<any, any> {
	constructor(props) {
		super(props);

		this.state = {
			viewActivityDetail: []
		};
	}

	render() {
		const { loading, error, employee_inventory_logs, onViewAll } = this.props;
		if (loading) return <Loader active size="tiny" />;
		if (error) return <Message content="There was an error" />;
		const { logs } = employee_inventory_logs;
		if (logs.length <= 0) return <Message info size="tiny" content="There is no activity logged for this employee yet" />;
		return (
			<div className="activity">
				<Button size="mini" content="View All" className="view-all" onClick={() => onViewAll()} />
				<small className="count">{`Showing most recent ${logs.length} activities`}</small>
				<div className="minilist">
					<List divided relaxed size="small">
						{logs.map((l: LMI.IEmployeeLog, key: number) => {
							const detailOpen = this.state.viewActivityDetail.indexOf(key + 1) !== -1;
							const openClose = () => {
								return detailOpen ? this.state.viewActivityDetail.filter(i => i !== key + 1) : [...this.state.viewActivityDetail, ...[key + 1]];
							};
							return (
								<List.Item key={key}>
									<List.Content>
										<a className="checkmore" onClick={() => this.setState({ viewActivityDetail: openClose() })}>
											more <Icon name={detailOpen ? "angle up" : "angle down"} />
										</a>
										<List.Header>{`${l.log_type_name} - ${l.inv.year} ${l.inv.make} ${l.inv.model}`}</List.Header>
										<List.Description>
											on {RelativeDatePipe(l.created_at, false, true)}
											{detailOpen ? (
												<div className="details">
													<Image src={l.inv.photo_url} size="tiny" floated="left" className="veh-photo" loading="lazy" />
													<div className="veh-info">
														{`${l.inv.year} ${l.inv.make} ${l.inv.model}`}
														<br />
														VIN: {l.inv.vin ? l.inv.vin : "N/A"}
														<br />
														Stock#: {l.inv.stock_number ? l.inv.stock_number : "N/A"}
														<br />
														{l.comment ? `Comment: ${l.comment}` : ""}
													</div>
												</div>
											) : (
												""
											)}
										</List.Description>
									</List.Content>
								</List.Item>
							);
						})}
					</List>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const ActivityList = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.employeeActivity, {
		options: (props: any) => {
			return {
				variables: {
					store_id: props.store_id,
					employee_id: props.employee_id,
					limit: 10
				},
				pollInterval: 10000,
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { loading, error, employee_inventory_logs, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				employee_inventory_logs,
				refetchActivity: refetch
			};
		}
	})
)(ActivityListView) as React.ComponentType<any>;

export default ActivityList;
