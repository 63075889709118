// node_modules
import * as React from "react";
// components
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { Button, Grid, Menu } from "semantic-ui-react";
import { LookupVendorSelectedComponent } from "./LookupVendorSelectedComponent";
import "./LookupVendorListComponent.css";

export class LookupVendorListComponentView extends React.Component<LMI.LookupVendorListComponentViewProps, any> {
	constructor(props) {
		super(props);
	}

	getVendorGrid = vendor => {
		const { store_approved_vendors } = this.props;
		const storeApprovedVendorIds = store_approved_vendors.map(sav => sav.vendor.id);

		return (
			vendor &&
			vendor.vendors.map((vendor, index) => {
				const storeName = vendor.name;
				const storeCode = vendor.store_code;
				const firstName = vendor.primary_contact && vendor.primary_contact.user.first_name;
				const lastName = vendor.primary_contact && vendor.primary_contact.user.last_name;
				const email = vendor.primary_contact && vendor.primary_contact.user.email;
				const address1 = vendor.address1;
				const address2 = vendor.address2;
				const city = vendor.city;
				const state = vendor.state;
				const zip = vendor.zip;
				const phone = vendor.phone;

				const vendorApproved = storeApprovedVendorIds.indexOf(vendor.id) >= 0;
				if (this.props.selected) {
					const selectedProps = Object.assign({}, this.props, { store_code: storeCode });

					return (
						<div className="selected" id={storeCode} key={index}>
							<Grid>
								<Grid.Row columns={2}>
									<Grid.Column width={4}>
										<div className="title truncate">{storeName}</div>
										{lastName && <div>{`${firstName} ${lastName}`}</div>}
										{address1 && <div>{address1 && `${address1}`}</div>}
										{address2 && <div>{`${address2}`}</div>}
										{city && <div>{`${city}, ${state} ${zip}`}</div>}
										{email && <div>{email}</div>}
										{phone && <div>{phone && `${phone}`}</div>}
									</Grid.Column>
									<Grid.Column width={12}>
										<LookupVendorSelectedComponent {...selectedProps} />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</div>
					);
				} else {
					if (vendorApproved) {
						return (
							<Menu.Item
								id={storeCode}
								className="already-approved"
								key={index}
								onClick={() => this.props.onSendAlert({ type: "warning", message: "This vendor is already approved" })}
							>
								<div className="title truncate">{storeName}</div>
								<div className="right">{storeCode}</div>
								{lastName && <div>{`${firstName} ${lastName}`}</div>}
								{address1 && <div>{address1 && `${address1}`}</div>}
								{address2 && <div>{`${address2}`}</div>}
								{city && <div>{`${city}, ${state} ${zip}`}</div>}
								{phone && <div>{phone && `${phone}`}</div>}
							</Menu.Item>
						);
					} else {
						return (
							<Menu.Item id={storeCode} key={index} onClick={this.props.onSelectVendor}>
								<div className="title truncate">{storeName}</div>
								<div className="right">{storeCode}</div>
								{lastName && <div>{`${firstName} ${lastName}`}</div>}
								{address1 && <div>{address1 && `${address1}`}</div>}
								{address2 && <div>{`${address2}`}</div>}
								{city && <div>{`${city}, ${state} ${zip}`}</div>}
								{phone && <div>{phone && `${phone}`}</div>}
							</Menu.Item>
						);
					}
				}
			})
		);
	};

	render() {
		const { lookup_vendor, onAddVendor, loading } = this.props;

		return (
			<div className="list-container">
				{lookup_vendor && lookup_vendor.vendors.length > 0 ? (
					<Menu fluid vertical>
						{this.getVendorGrid(lookup_vendor)}
					</Menu>
				) : (
					<div className="no-results">
						{loading ? "Searching..." : "No matching vendor found..."}
						<Button primary onClick={onAddVendor}>
							Create Vendor
						</Button>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

// @ts-ignore
export const LookupVendorListComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.LookupVendorsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.lookupVendors, {
		options: (props: any) => {
			return {
				variables: {
					storeCode: props.store_code && props.store_code,
					storeName: props.store_name && props.store_name,
					email: props.email && props.email,
					phone: props.phone && props.phone
				},
				fetchPolicy: "network-only",
				notifyOnNetworkStatusChange: true
			};
		},
		props: ({ data: { error, loading, lookup_vendor, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };
			return {
				lookup_vendor,
				refetch
			};
		}
	})
)(LookupVendorListComponentView) as React.ComponentType<any>;

export default LookupVendorListComponent;
