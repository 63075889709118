import * as React from "react";
import { Button, Label, Segment } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { RegularExpressions } from "client/utils/regex";

export class RegionsDetailsComponent extends React.Component<LMI.IRegionsDetailsProps, LMI.IRegionsDetailsState> {
	constructor(props) {
		super(props);
		this.state = {
			newregion: props.region ? false : true
		};
	}

	render() {
		const props = this.props;
		const errorLabel = <Label color="red" pointing="above" />;
		return (
			<div id="auction-house-detail">
				<Form onSubmit={this.submitForm.bind(this)}>
					<Form.Field>
						<label>Region Name</label>
						<Input
							name="name"
							value={props.region && props.region.name}
							required
							validations={{
								matchRegexp: RegularExpressions.NAMES
							}}
							validationErrors={{
								matchRegexp: "Only letters, numbers, spaces, commas, periods, apostrophes, and dashes allowed.",
								isDefaultRequiredValue: "Name is required"
							}}
							errorLabel={errorLabel}
						/>
					</Form.Field>
					<Segment basic textAlign="right">
						<Button.Group>
							<Button type="button" onClick={() => props.closeCallback()}>
								Cancel
							</Button>
							<Button.Or />
							<Button positive className="submit-btn" type="submit">
								Save
							</Button>
						</Button.Group>
					</Segment>
				</Form>
			</div>
		);
	}

	submitForm(formData: any) {
		if (this.state.newregion) {
			this.props.onCreate(formData);
		} else {
			this.props.onSave(formData, this.props.region.id);
		}
	}
}

export default RegionsDetailsComponent;
