import * as React from "react";
import { Segment, Grid, Icon } from "semantic-ui-react";

export class InvoiceResult extends React.Component<LMI.LmiResultTypeProps, undefined> {
	render() {
		const { onSelect } = this.props;
		const detail: LMI.IDealerInvoicesGQL = this.props.detail;
		return (
			<Segment key={detail.id} raised className="result-tile" onClick={() => onSelect(`invoices?id=${detail.invoice_id}`)}>
				<Grid className="summary">
					<Grid.Column width={2}>
						<Icon name="file text" size="huge" color="grey" />
					</Grid.Column>
					<Grid.Column width={14}>
						<div className="vehicle">
							{/* {detail.condition && <div className="condition">{detail.condition.toLowerCase() === "used" ? "PRE-OWNED" : detail.condition.toUpperCase()}</div>}
							{detail.condition && <div className="condition">{detail.condition.toLowerCase() === "used" ? "PRE-OWNED" : detail.condition.toUpperCase()}</div>} */}

							<div className="details">{detail.provider_store ? detail.provider_store.name : detail.customer_store.name}</div>
							<div className="identity">
								{detail.invoice_number && <div className="i-label">INVOICE #: {detail.invoice_number}</div>}
								{detail.po_number && <div className="i-label">PO #: {detail.po_number}</div>}
							</div>
						</div>
					</Grid.Column>
				</Grid>
			</Segment>
		);
	}
}

export default InvoiceResult;
