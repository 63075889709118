import { gqlQueries } from "gql-imports";
import { Loading } from "client/components/Loading";
import { EFDInputComponent } from "client/pages/admin/components/EditableFieldDisplay/components/EFDInputComponent";
import { FileUploadComponent, MultiImageUploader } from "client/pages/admin/components/FileUploadComponent";
import { ModalComponent } from "client/pages/admin/components/ModalComponent";

import { formatPhone, removeNullProps } from "client/utils/functions";
import { timeZones } from "client/utils/globals";
import { RegularExpressions } from "client/utils/regex";
import { hasPermission } from "client/utils/userAccess";
import { Permission } from "loopmein-shared";
import * as React from "react";
import { ClassAttributes } from "react";
import { flowRight as compose } from "lodash";
import { addAlert } from "api/redux/actions";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
import { Button, Card, Divider, Form, Grid, Header, Icon, Image, List, Loader, Popup, Segment, Statistic } from "semantic-ui-react";
import { EditableSelectableComponent } from "../../EditableSelectableComponent";
import { SelectableUserDisplay } from "../../SelectableUserDisplay";
import HoursComponent from "../StoreTabPanel/components/HoursComponent/HoursComponent";
import StorePhotosViewer from "../StoreTabPanel/components/StorePhotosComponent/StorePhotosComponent";
import EditAddressComponent from "./components/EditAddressComponent/EditAddressComponent";
import { restAPI } from "../../../../../utils/rest";
import { Session } from "client/utils/session";

import "./VendorTabPanel.css";

export class VendorTabPanelView extends React.Component<LMI.IVendorTPProps, LMI.IVendorTPState> {
	constructor(props) {
		super(props);

		this.state = {
			editingImage: false,
			editingWebsite: false,
			editHours: false,
			addingStoreImages: false,
			editingStoreImages: false,
			editingAddress: false,
			invoiceIndex: null,
			invoicePrefix: null,
			editInvoiceConfig: false,
			loading: false
		};
	}

	buildHour = period => {
		if (!period) return;
		let hour = period.substring(0, 2);
		hour = parseInt(hour, 10) > 12 ? parseInt(hour, 10) - 12 : hour.charAt(0) === "0" ? hour.substring(1, 2) : hour;
		const minutes = period.substring(2, 4);
		const amPm = period >= 1200 ? "PM" : "AM";
		return `${hour}:${minutes}${amPm}`;
	};

	getHourDisplay = (period: LMI.IStoreOpeningPeriod) => {
		if (!period.open) {
			return "Closed";
		}
		return `${this.buildHour(period.open_time)}-${this.buildHour(period.close_time)}`;
	};

	render() {
		const props = this.props;
		if (props.hasErrors) {
			console.log("*VendorTabPanel Data error:");
			console.log(props.message.message);
		}

		if (!props.vendor || !props.employees || !props.admin_vendor || props.loading) {
			return <Loading />;
		}

		const vendor = props.vendor;
		const {
			editingWebsite,
			editingImage,
			editHours,
			addingStoreImages,
			editingStoreImages,
			editingAddress,
			invoiceIndex,
			invoicePrefix,
			editInvoiceConfig,
			loading
		} = this.state;
		const storeHours = props.store_hours;
		const storePhotos =
			vendor && vendor.store_photos
				? vendor.store_photos.map(photo => {
						const primaryStorePhotoId = vendor.primary_photo_full_url
							? vendor.store_photos.find(photo => photo.full_url === vendor.primary_photo_full_url).id
							: null;
						return {
							...photo,
							...{
								is_primary: photo.id === primaryStorePhotoId ? true : false
							}
						};
				  })
				: [];

		const organization = props.vendor.organization ? props.vendor.organization : { name: "" };
		// const employees = props.employees || [];
		const primaryContact = props.vendor.primary_contact_id || -1;
		const imageUrl: string = props.vendor.logo_url ? props.vendor.logo_url : "https://loopmein.imgix.net/default_dealership_logo.png";
		const canEditStoreInfo = hasPermission(props.permissions, Permission.ADMIN_EDIT_STORE, Session.get("isSuperUser"));
		// const canEditStoreInfo = false;
		const stats: any = [
			{
				value: vendor.employees.length,
				text: `Employee${vendor.employees.length > 1 ? "s" : ""}`,
				linked: false
			}
		];
		if (props.store_task_count) {
			if (props.store_task_count.open_task_count > 0) {
				stats.push({
					value: props.store_task_count.open_task_count,
					text: `Open Task${props.store_task_count.open_task_count > 1 ? "s" : ""}`,
					linked: false
				});
			}
			if (props.store_task_count.closed_task_count > 0) {
				stats.push({
					value: props.store_task_count.closed_task_count,
					text: `Closed Task${props.store_task_count.closed_task_count > 1 ? "s" : ""}`,
					linked: false
				});
			}
		}
		const fileProps = {
			filesToAccept: "image/png, image/jpeg",
			onSubmit: this.onSubmitFileHandler.bind(this),
			onClose: () => {
				this.setState({ editingImage: false });
			}
		};
		const editAddressModalProps: LMI.IEditAddressProps = {
			store_country_code: props.vendor.country_code,
			address: Object.assign({ address1: vendor.address1 }, { address2: vendor.address2 }, { city: vendor.city }, { state: vendor.state }, { zip: vendor.zip }),
			updateAddress: data => {
				this.submitAddress(data);
				this.setState({ editingAddress: false });
			}
		};
		return (
			<div className="vendor-tab-panel panel-content">
				{loading && <Loader indeterminate active />}
				{editingImage ? (
					<ModalComponent
						headerText="Upload Image"
						shouldBeOpen={true}
						onClose={(evt, data) => {
							this.setState({ editingImage: false });
						}}
						className="upload-image-modal"
						contentComponent={() => <FileUploadComponent {...fileProps} />}
					/>
				) : (
					""
				)}

				{addingStoreImages && (
					<ModalComponent
						headerText="Upload Store Photos"
						shouldBeOpen={addingStoreImages}
						onClose={() => {
							this.setState({ addingStoreImages: false });
						}}
						className="upload-image-modal"
						contentComponent={() => (
							<MultiImageUploader
								{...{
									onSubmit: images => this.onSubmitStorePhotosHandler(images),
									onClose: () => this.setState({ addingStoreImages: false })
								}}
							/>
						)}
					/>
				)}

				{editHours && (
					<ModalComponent
						headerText="Opening Hours"
						shouldBeOpen={true}
						onClose={() => this.setState({ editHours: false })}
						contentComponent={() => (
							<HoursComponent
								{...{
									hours: storeHours,
									onSubmit: hours => this.saveCompanyHours(hours)
								}}
							/>
						)}
					/>
				)}

				{editingStoreImages && (
					<ModalComponent
						headerText="Store Photos"
						shouldBeOpen={editingStoreImages}
						onClose={() => {
							this.setState({ editingStoreImages: false });
						}}
						className="store-images-modal"
						contentComponent={() => (
							<StorePhotosViewer
								{...{
									store_photos: storePhotos,
									active: storePhotos[0],
									thumb_format: "?fp-x=0.5&fp-y=0.5&fp-z=1&fit=crop&w=119&h=90&crop=focalpoint&auto=compress,enhance",
									main_image_format: "?&fit=clamp&max-w=860&max-h=480&auto=compress,enhance",
									canEdit: canEditStoreInfo,
									onClose: () => {
										this.setState({ editingStoreImages: false });
									},
									onDelete: photo => {
										restAPI({
											endpointName: "removeStorePhoto",
											urlArgs: [vendor.id, photo.id],
											data: null,
											callback: (err, res) => {
												if (err) {
													console.error(err);
												}
												props.vendorRefetch();
											}
										});
									},
									setPrimary: photo => {
										restAPI({
											endpointName: "modifyStorePhoto",
											urlArgs: [vendor.id, photo.id],
											data: {
												is_primary: true
											},
											callback: (err, res) => {
												if (err) {
													console.error(err);
												}
												props.vendorRefetch();
											}
										});
									}
								}}
							/>
						)}
					/>
				)}

				<Grid className="mainGrid">
					<Grid.Column width={4}>
						<div className="store-photos-container">
							<Card
								fluid
								image={imageUrl + "?fit=crop&max-h=350&auto=compress,enhance"}
								extra={
									canEditStoreInfo && (
										<Button
											className="change-image-btn"
											onClick={() => {
												this.setState({ editingImage: true });
											}}>
											Update Store Logo
										</Button>
									)
								}
							/>

							<Card fluid className="store-photos">
								<Card.Content>
									<Header>
										{canEditStoreInfo && storePhotos && storePhotos.length ? (
											<div className="store-photo-tools">
												<Popup content="Add Store Photos" trigger={<Icon name="add" onClick={() => this.setState({ addingStoreImages: true })} />} />
											</div>
										) : (
											""
										)}
										Store Photos
									</Header>
								</Card.Content>
								<Card.Content className="phtos">
									{storePhotos && storePhotos.length ? (
										<div onClick={() => this.setState({ editingStoreImages: true })}>
											<Image
												className="primary"
												label={{
													color: "orange",
													corner: "right",
													icon: "thumbtack"
												}}
												src={storePhotos.filter(f => f.is_primary).length ? storePhotos.filter(f => f.is_primary)[0].full_url : storePhotos[0].full_url}
												loading="lazy"
											/>
											{storePhotos
												.filter(f => !f.is_primary)
												.map((file, index) => {
													if (index <= 2) {
														return (
															<div key={index} className="thumbs">
																<Image key={index} src={file.full_url} size="tiny" loading="lazy" />
															</div>
														);
													}
												})}
										</div>
									) : (
										<>
											{canEditStoreInfo ? <Button content="Upload Store Photos" fluid onClick={() => this.setState({ addingStoreImages: true })} /> : <span />}
										</>
									)}
								</Card.Content>
								{storePhotos && storePhotos.length > 2 ? (
									<Card.Content extra>
										<Icon name="photo" />
										{`${storePhotos.length} Store Photos`}
									</Card.Content>
								) : (
									""
								)}
							</Card>
						</div>
					</Grid.Column>
					<Grid.Column width={12}>
						<Segment color="blue" padded className="store-settings" style={{ marginTop: 0 }}>
							<Grid>
								<Grid.Column width={8}>
									<Header as="h2">
										{vendor.name}
										<Header.Subheader>{organization.name}</Header.Subheader>
										<Header.Subheader>Store Code: {vendor.store_code}</Header.Subheader>
									</Header>
								</Grid.Column>
								<Grid.Column width={8}>
									{stats && stats.length > 1 ? (
										<Statistic.Group size="mini" widths={stats.length}>
											{stats &&
												stats.map((stat, index) => {
													return (
														<Statistic color="blue" key={index} className={stat.linked ? "stat statLink" : "stat"} onClick={stat.callback}>
															<Statistic.Value>{stat.value}</Statistic.Value>
															<Statistic.Label>{stat.text}</Statistic.Label>
														</Statistic>
													);
												})}
										</Statistic.Group>
									) : (
										<Statistic color="blue" size="mini" floated="right" className={stats[0].linked ? "stat statLink" : "stat"} onClick={stats[0].callback}>
											<Statistic.Value>{stats[0].value}</Statistic.Value>
											<Statistic.Label>{stats[0].text}</Statistic.Label>
										</Statistic>
									)}
								</Grid.Column>
								<Grid.Column width={16}>
									<Divider fitted />

									<Grid>
										<Grid.Row>
											<Grid.Column width={9} className="info-column">
												<label className="info-label">Store Contact Info:</label>
												<div className={canEditStoreInfo ? "info-block" : "info-block disabled"}>
													<List relaxed size="large">
														<List.Item>
															<List.Icon name="marker" />
															<List.Content>
																{editingAddress ? (
																	<ModalComponent
																		headerText={"Edit Address"}
																		shouldBeOpen={this.state.editingAddress}
																		onClose={(evt, data) => {
																			this.setState({ editingAddress: false });
																		}}
																		size="small"
																		className="edit-cost-modal"
																		contentComponent={() => <EditAddressComponent {...editAddressModalProps} />}
																	/>
																) : (
																	<div>
																		{vendor.address1 ? (
																			<span>
																				{canEditStoreInfo && (
																					<span className="editfunction">
																						<Icon
																							name="pencil"
																							link
																							onClick={() =>
																								this.setState({
																									editingAddress: true
																								})
																							}
																						/>
																					</span>
																				)}
																				{vendor.address1}
																				{vendor.address2 && vendor.address2}
																				{vendor.city}, {vendor.state} {vendor.zip}
																			</span>
																		) : (
																			<>
																				{canEditStoreInfo ? (
																					<a
																						onClick={() =>
																							this.setState({
																								editingAddress: true
																							})
																						}>
																						Update Address
																					</a>
																				) : (
																					<span className="nodata">Not Set</span>
																				)}
																			</>
																		)}
																	</div>
																)}
															</List.Content>
														</List.Item>
														<List.Item>
															<List.Icon name="phone" />
															<List.Content>{vendor.phone ? formatPhone(vendor.phone) : <span className="nodata">Not Set</span>}</List.Content>
														</List.Item>
														<List.Item>
															<List.Icon name="globe" />
															<List.Content>
																{editingWebsite ? (
																	<EFDInputComponent
																		id="editWebsite"
																		labeled={false}
																		value={vendor.website_url}
																		onSubmit={this.submitWebsite.bind(this)}
																		onCancel={() => this.setState({ editingWebsite: false })}
																		type="text"
																		masked={false}
																		validations={{
																			matchRegexp: /^http[s]?\:\/\//
																		}}
																		validationErrors={{
																			matchRegexp: "Website must contain link protocol 'https://' or 'http://'"
																		}}
																	/>
																) : (
																	<div>
																		{vendor.website_url ? (
																			<span>
																				{canEditStoreInfo && (
																					<span className="editfunction">
																						<Icon
																							name="pencil"
																							link
																							onClick={() =>
																								this.setState({
																									editingWebsite: true
																								})
																							}
																						/>
																					</span>
																				)}
																				<a href={`https://${vendor.website_url}`} target="_blank">
																					{vendor.website_url}
																				</a>
																			</span>
																		) : (
																			<>
																				{canEditStoreInfo ? (
																					<a
																						onClick={() =>
																							this.setState({
																								editingWebsite: true
																							})
																						}>
																						Add Website
																					</a>
																				) : (
																					<span className="nodata">No Website Set</span>
																				)}
																			</>
																		)}
																	</div>
																)}
															</List.Content>
														</List.Item>
													</List>
												</div>
												<label className="info-label">Primary Contact:</label>
												<div className={canEditStoreInfo ? "info-block" : "info-block disabled"}>
													<SelectableUserDisplay
														id="selectable-administrator-field"
														startingValue={primaryContact}
														options={props.employees
															.filter(emp => {
																return emp.is_active;
															})
															.map(emp => {
																return {
																	id: emp.id.toString(),
																	fullname: emp.user.full_name,
																	email: emp.user.email,
																	phone: emp.user.phone
																};
															})}
														classes="selectable-user"
														onClick={data => {
															this.modifyVendor(props.vendor.id, {
																primary_contact_id: parseInt(data.currentValue, 10)
															});
														}}
														canEdit={hasPermission(props.permissions, Permission.ADMIN_EDIT_STORE, Session.get("isSuperUser"))}
													/>
												</div>
											</Grid.Column>
											<Grid.Column width={7} className="info-column">
												<label className="info-label">Time Zone:</label>
												<div className={canEditStoreInfo ? "info-block" : "info-block disabled"}>
													{canEditStoreInfo ? (
														<EditableSelectableComponent
															title="Timezone"
															id="editable-selectable-timezone-field"
															startingValue={timeZones.findIndex(tz => tz.utc === vendor.timezone_name)}
															onClick={data => {
																const selectedTimeZone = timeZones[data.currentValue].utc;
																this.modifyVendor(vendor.id, {
																	timezone_name: selectedTimeZone
																});
															}}
															options={timeZones.map((zone, index) => {
																return {
																	text: zone.text,
																	value: index
																};
															})}
															disabled={!canEditStoreInfo}
														/>
													) : (
														<span>{vendor.timezone_name}</span>
													)}
												</div>
												<label className="info-label">Invoice Configuration:</label>
												<div className={canEditStoreInfo ? "info-block" : "info-block disabled"}>
													{canEditStoreInfo && (
														<span className="actionbtns">
															{!editInvoiceConfig ? (
																<Icon
																	link
																	name="pencil"
																	onClick={() => {
																		this.setState({ editInvoiceConfig: true });
																	}}
																/>
															) : (
																<Icon link name="checkmark" color="green" onClick={this.updateInvoiceConfig} />
															)}
														</span>
													)}
													<Form.Field>
														<Form.Input
															className="invoice-index"
															name="invoice_index"
															label="Invoice Index"
															value={invoiceIndex ? invoiceIndex : vendor.invoice_sequence_number}
															placeholder="Starting Index"
															onChange={this.setInvoiceIndex}
															disabled={!editInvoiceConfig}
														/>
													</Form.Field>
													<Form.Field>
														<Form.Input
															className="invoice-prefix"
															name="invoice_prefix"
															label="Invoice Prefix"
															value={invoicePrefix ? invoicePrefix : vendor.invoice_prefix}
															placeholder="Invoice Prefix"
															onChange={this.setInvoicePrefix}
															disabled={!editInvoiceConfig}
														/>
													</Form.Field>
												</div>
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Grid.Column>
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid>
			</div>
		);
	}

	updateInvoiceConfig = () => {
		const data = {};
		const { invoiceIndex, invoicePrefix } = this.state;
		if (invoiceIndex || invoicePrefix) {
			if (invoiceIndex) {
				data["invoice_sequence_number"] = invoiceIndex;
			}
			if (invoicePrefix) {
				data["invoice_prefix"] = invoicePrefix;
			}
			this.modifyVendor(this.props.vendor.id, data);
		}
		this.setState({
			editInvoiceConfig: false,
			invoiceIndex: null,
			invoicePrefix: null
		});
	};

	setInvoiceIndex = event => {
		this.setState({ invoiceIndex: parseInt(event.currentTarget.value, 10) });
	};

	setInvoicePrefix = event => {
		this.setState({ invoicePrefix: event.currentTarget.value });
	};

	submitWebsite = formData => {
		if (formData.search(RegularExpressions.ISWEBSITE) >= 0) {
			this.modifyVendor(this.props.vendor.id, { website_url: formData });
			this.setState({ editingWebsite: false });
		} else {
			this.props.addAlert({
				type: "danger",
				message: "Website must contain ssl protocol 'https://' or 'http://' to be updated",
				timeout: 3000
			});
		}
	};

	submitAddress = formData => {
		this.modifyVendor(this.props.vendor.id, formData);
		this.setState({ editingAddress: false });
	};

	addStorePhotoTask = photo => {
		return new Promise((resolve, reject) => {
			const { vendor } = this.props;
			restAPI({
				endpointName: "addStorePhoto",
				urlArgs: [vendor.id],
				data: photo,
				callback: (err, res) => {
					if (err) {
						console.error(err);
					}
					resolve(res);
				}
			});
		});
	};

	onSubmitStorePhotosHandler = async photoObjects => {
		const requests = photoObjects.map(photo => {
			return this.addStorePhotoTask({ photo: photo.image });
		});
		await Promise.all(requests);
		this.setState({ addingStoreImages: false });
		this.props.vendorRefetch();
	};

	sendAlert({ type, message }) {
		this.props.addAlert({
			type,
			message,
			timeout: 3000
		});
	}

	saveCompanyHours = hours => {
		if (!hours) return;
		this.setState({ editHours: false });
		restAPI({
			endpointName: "modifyStoreHours",
			urlArgs: [this.props.vendor.id],
			data: {
				days: hours.days
					.filter(h => h.open)
					.map(day => {
						return {
							day: day.day,
							open_time: day.open_time,
							close_time: day.close_time
						};
					})
			},
			callback: (err, res) => {
				if (!err) {
					this.props.storeHoursRefetch();
					this.props.vendorRefetch();
				}
			}
		});
	};

	onSubmitFileHandler(imageObject) {
		const logo = {
			logo: imageObject.image
		};
		restAPI({
			endpointName: "updateLogo",
			urlArgs: [this.props.vendor.id],
			data: logo,
			callback: (err, res) => {
				if (!err) {
					this.refetchQueryResults();
				}
			}
		});
		this.setState({ editingImage: false });
	}

	modifyVendor(vendorId: number, data: any) {
		this.setState({ loading: true });
		const formData = removeNullProps(data);

		restAPI({
			endpointName: "modifyStore",
			urlArgs: [vendorId],
			data: formData,
			callback: (error, result) => {
				let alert;
				if (error) {
					alert = {
						type: "danger",
						message: `Error updating vendor: ${error.message}`
					};
				} else {
					alert = { type: "success", message: `Vendor updated successfully` };
					this.refetchQueryResults();
				}
				this.setState({ loading: false });
				this.sendAlert(alert);
			}
		});
	}

	updateOrganization(storeId: number, organization_name: string) {
		restAPI({
			endpointName: "updateAutoGroup",
			urlArgs: [storeId],
			data: { auto_group_name: organization_name },
			callback: (err, res) => {
				if (!err) {
					this.refetchQueryResults();
				}
			}
		});
	}

	async refetchQueryResults() {
		this.props.usersRefetch();
		await this.props.vendorRefetch();
		this.props.einRefetch();
		this.setState({ loading: false });
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		storeName: state.app.admin.storeName,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		addAlert: (alert: LMI.IAlertsProps) => {
			dispatch(addAlert(alert));
		}
	};
};

export const VendorTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IStoreQueryProps, any, any, ClassAttributes<any>>(gqlQueries.dealership.store, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) {
				return { hasErrors: true, message: error };
			}

			return {
				vendor: store,
				vendorRefetch: refetch
			};
		}
	}),
	graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.employees, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employees, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) {
				return { hasErrors: true, message: error };
			}

			return {
				employees,
				usersRefetch: refetch
			};
		}
	}),
	graphql<LMI.IAdminVendorEINQueryProps, any, any, ClassAttributes<any>>(gqlQueries.vendor.admin.ein, {
		options: (props: any) => {
			return {
				variables: {
					vendorId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, admin_vendor, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) {
				return { hasErrors: true, message: error };
			}

			return {
				admin_vendor,
				einRefetch: refetch
			};
		}
	}),
	graphql<LMI.ITaskCountProps, any, any, ClassAttributes<any>>(gqlQueries.taskCount, {
		skip: (ownProps: any) => {
			return !hasPermission(ownProps.permissions, Permission.ADMIN_EDIT_STORE, Session.get("isSuperUser"));
		},
		options: (props: any) => {
			return {
				variables: {
					store_id: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_task_count, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				store_task_count,
				taskRefetch: refetch
			};
		}
	}),
	graphql<LMI.IStoreHoursGQLProps, any, any, ClassAttributes<any>>(gqlQueries.storeHours, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, store_hours, refetch } }): any => {
			if (loading) return { loading: true };
			if (error)
				return {
					hasErrors: true,
					message: error,
					storeHoursRefetch: refetch
				};

			return {
				store_hours,
				storeHoursRefetch: refetch
			};
		}
	})
)(VendorTabPanelView) as React.ComponentType<any>;

export default VendorTabPanel;
