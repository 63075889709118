import moment from "moment";
import * as React from "react";
import { formatCurrency } from "../../../../../../utils/functions";
import { hasPermission } from "client/utils/userAccess";
import { Session } from "client/utils/session";
import { Permission } from "loopmein-shared";

import { Button, Loader, Message, Header, Divider } from "semantic-ui-react";
import { ModalComponent } from "../../../ModalComponent";
import AddTaskForm from "client/components/AddTasks/AddTaskForm";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  SelectionSettingsModel,
} from "@syncfusion/ej2-react-grids";

import "@syncfusion/ej2-navigations/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-lists/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-popups/styles/material.css";
import "@syncfusion/ej2-react-calendars/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";
import "./Tasks.css";

export class InventoryTasks extends React.Component<
  any,
  LMI.IInventoryDetailTabState
> {
  grid: GridComponent;
  cellSelectionSettings: SelectionSettingsModel = {
    cellSelectionMode: "Box",
    type: "Single",
    mode: "Both",
  };

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      selectedRowIndex: null,
      openAddTasks: false,
    };
  }

  format(tasks: any): LMI.IInventoryTaskDetailGQL[] {
    return tasks.map((item) => {
      return {
        scheduled: moment(item.created_at).isValid()
          ? moment(item.created_at).format("L")
          : "",
        completed: moment(item.completed_at).isValid()
          ? moment(item.completed_at).format("L")
          : "",
        task: item.service ? item.service.name : null,
        vendor: item.assigned_vendor ? item.assigned_vendor.name : null,
        cost: formatCurrency(parseFloat(item.cost), 2),
      };
    });
  }

  cellSelection = (args: any) => {
    args.cancel = true;
  };

  getWrapSettings: any = () => {
    return { wrapMode: "Content" };
  };

  render() {
    const { detail, permissions } = this.props;
    const { openAddTasks } = this.state;

    const readyTasks = detail ? this.format(detail.tasks) : null;
    const canAddTasks = hasPermission(
      permissions,
      Permission.ADD_TASK,
      Session.get("isSuperUser")
    );

    if (!readyTasks) return <Loader className={`loader active`} size="small" />;

    return (
      <>
        <ModalComponent
          {...{
            headerText: "Add Tasks to this Vehicle",
            shouldBeOpen: openAddTasks,
            size: "small",
            onClose: () => this.setState({ openAddTasks: false }),
            contentComponent: () => (
              <AddTaskForm
                open_tasks={detail.tasks}
                inventory_item_id={detail.id}
                onSave={() =>
                  this.setState({ openAddTasks: false }, () =>
                    this.props.refetchDetail()
                  )
                }
              />
            ),
          }}
        />
        {readyTasks.length == 0 ? (
          <Message>
            <Header color="grey">
              There are currently no tasks for this vehicle!
            </Header>
            <Divider hidden />
            {canAddTasks && (
              <Button
                id="AddTaskBtn"
                icon="add"
                content="Add a task"
                onClick={() => this.setState({ openAddTasks: true })}
              />
            )}
          </Message>
        ) : (
          <div>
            {canAddTasks && (
              <Button
                circular
                icon="add"
                size="large"
                id="AddTaskBtn"
                className="fab"
                onClick={() => this.setState({ openAddTasks: true })}
              />
            )}
            <GridComponent
              className="grid-component"
              enableHover={false}
              allowSelection={false}
              allowSorting={true}
              selectionSettings={this.cellSelectionSettings}
              cellSelecting={this.cellSelection}
              dataSource={readyTasks}
              allowGrouping={false}
              allowPaging={false}
              allowFiltering={false}
              allowTextWrap={true}
              textWrapSettings={this.getWrapSettings}
              ref={(g) => (this.grid = g)}
              height="100%"
              gridLines="Both"
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="task"
                  headerText="Task"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="scheduled"
                  headerText="Scheduled"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="completed"
                  headerText="Completed"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="vendor"
                  headerText="Vendor"
                  textAlign="Left"
                />
                <ColumnDirective
                  field="cost"
                  headerText="Cost"
                  textAlign="Left"
                />
              </ColumnsDirective>
            </GridComponent>
          </div>
        )}
      </>
    );
  }
}

export default InventoryTasks;
