import * as React from "react";
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { Button, Modal } from "semantic-ui-react";
import "./MapDialog.css";

const MapComponent = withScriptjs(
	withGoogleMap((props: any) => (
		<GoogleMap defaultZoom={19} defaultCenter={{ lat: props.latitude, lng: props.longitude }} mapTypeId="satellite">
			<Marker position={{ lat: props.latitude, lng: props.longitude }} />
		</GoogleMap>
	))
);

export class MapsDialog extends React.Component<LMI.MapDialogProps, any> {
	render() {
		const { open, title, location, actions, onClose } = this.props;
		const mapsURL = "https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyDLZVMkEZv-BwvkZsfkXDtEJ0pbfrQabdg&libraries=geometry,drawing,places";

		return (
			<Modal className="map-dialog" open={open}>
				<Modal.Header>{title}</Modal.Header>
				<Modal.Content>
					{location && (
						<MapComponent
							{...location}
							googleMapURL={mapsURL}
							loadingElement={<div style={{ height: `110%` }} />}
							containerElement={<div style={{ height: `400px` }} />}
							mapElement={<div style={{ height: `100%` }} />}
						/>
					)}
				</Modal.Content>
				<Modal.Actions>
					{actions && actions.length
						? actions.map((action, k) => {
								return <Button key={k} positive content={action.text} onClick={() => action.action()} />;
						  })
						: ""}
					<Button content="Done" onClick={() => onClose()} />
				</Modal.Actions>
			</Modal>
		);
	}
}

export default MapsDialog;
