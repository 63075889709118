// node_modules
import * as React from "react";
// components
import {
  Button,
  Form,
  Input,
  InputOnChangeData,
  Divider,
  Grid,
} from "semantic-ui-react";
import { leaseSettingLists } from "../LeaseReturnSettingsTabPanel";
import StateSelector from "client/components/StateSelector/StateSelector";

export class LeaseReturnItemEditor extends React.Component<
  LMI.ILeaseReturnItemEditorProps,
  LMI.ILeaseReturnItemEditorState
> {
  newItem = (listType: leaseSettingLists) => {
    let item: LMI.ILeaseReturnEditableItem = {
      id: 0,
      name: null,
    };
    switch (listType) {
      case leaseSettingLists.company:
        item = {
          id: 0,
          name: null,
          description: null,
          address1: null,
          address2: null,
          city: null,
          state: null,
          zip: null,
        };
        break;
      case leaseSettingLists.status:
        item = {
          id: 0,
          name: null,
          description: null,
        };
        break;
      default:
        break;
    }
    return item;
  };

  constructor(props) {
    super(props);
    this.state = {
      item: props.item ? props.item : this.newItem(props.listType),
      isNew: !props.item,
    };
  }

  updateItem(field: InputOnChangeData) {
    const item = { ...this.state.item };
    item[field.name] = field.value;
    this.setState({ item });
  }

  render() {
    const { item, isNew } = this.state;
    const { name, description, address1, address2, city, state, zip } = item;
    const { onSave, onUpdate, listType } = this.props;

    return (
      <div id="leaseItemEditor">
        <Form>
          <Form.Field>
            <label>Name</label>
            <Input
              name="name"
              value={name ? name : ""}
              onChange={(evt, field) => this.updateItem(field)}
            />
          </Form.Field>
          {listType === leaseSettingLists.status && (
            <Form.Field>
              <label>Description</label>
              <Input
                name="description"
                value={description ? description : ""}
                onChange={(evt, field) => this.updateItem(field)}
              />
            </Form.Field>
          )}
          {listType === leaseSettingLists.company ? (
            <div className="addr-fields">
              <Divider horizontal>Location Information</Divider>
              <Grid>
                <Grid.Row style={{ paddingBottom: 0 }}>
                  <Grid.Column width={10}>
                    <Form.Field>
                      <label>Address 1</label>
                      <Input
                        name="address1"
                        value={address1 ? address1 : ""}
                        onChange={(evt, field) => this.updateItem(field)}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Address 2</label>
                      <Input
                        name="address2"
                        value={address2 ? address2 : ""}
                        onChange={(evt, field) => this.updateItem(field)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns="3" style={{ paddingBottom: 0 }}>
                  <Grid.Column>
                    <Form.Field>
                      <label>City</label>
                      <Input
                        name="city"
                        value={city ? city : ""}
                        onChange={(evt, field) => this.updateItem(field)}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>State/Province</label>
                      <StateSelector
                        {...{
                          value: state ? state : null,
                          placeholder: "Select State/Province",
                          onChange: (data) =>
                            this.updateItem({
                              name: "state",
                              value: data.value,
                            }),
                        }}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <label>Zip Code</label>
                      <Input
                        name="zip"
                        value={zip ? zip : ""}
                        onChange={(evt, field) => this.updateItem(field)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          ) : (
            ""
          )}
          <Divider hidden />
          <Button
            color="green"
            disabled={!name}
            content="Save"
            onClick={() => (isNew ? onSave(item) : onUpdate(item))}
          />
        </Form>
      </div>
    );
  }
}

export default LeaseReturnItemEditor;
