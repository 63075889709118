// node_modules
import * as React from "react";
// components
import { Card, Button, Icon, Dropdown, Divider } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import "./AddGroupComponent.css";

export class AddGroupComponent extends React.Component<LMI.SUAdminAddGroupProps, LMI.SUAdminAddGroupState> {
	constructor(props) {
		super(props);

		this.state = {
			formProps: null,
			group: "",
			region: ""
		};
	}

	render() {
		return (
			<Card fluid id="add-group-form">
				<Card.Content>
					<Card.Header>
						<Icon link name="close" color="red" className="close-right" onClick={() => this.props.nevermind()} />
						Add a new organization
					</Card.Header>
				</Card.Content>
				<Card.Content className="add-forms">
					<Form noValidate ref="form" name="group" className="new-group-form" onSubmit={this.addGroup.bind(this)}>
						<Form.Group widths="equal">
							<Form.Field>
								<label>Name of Organization</label>
								<Input name="groupname" placeholder="Organization name" />
							</Form.Field>
							<Form.Field>
								<label>Type of Organization</label>
								<Form.Select search name="store_type_id" options={this.props.storeTypes} placeholder="Select a Store Type" />
							</Form.Field>
						</Form.Group>
						<Form.Field>
							{this.state.formProps && this.state.formProps.length && this.state.formProps.find(p => p.type === "region" && p.form === "group") ? (
								<Dropdown
									text={
										this.state.formProps.filter(p => p.type === "region" && p.form === "group").length +
										" New Region" +
										(this.state.formProps.filter(p => p.type === "region" && p.form === "group").length > 1 ? "s" : "")
									}
									icon="compass"
									floating
									labeled
									button
									className="icon"
								>
									<Dropdown.Menu>
										{this.state.formProps
											.filter(p => p.type === "region" && p.form === "group")
											.map((region, index) => {
												return (
													<Dropdown.Item
														key={index}
														onClick={() => {
															const newFormProps = this.state.formProps;
															const itemIndex = newFormProps.findIndex(prop => prop.value === region.value);
															newFormProps.splice(itemIndex, 1);
															this.setState({
																formProps: newFormProps
															});
														}}
													>
														{region.value}
														<span className="dd-close-icon">
															<Icon name="close" color="red" />
														</span>
													</Dropdown.Item>
												);
											})}
									</Dropdown.Menu>
								</Dropdown>
							) : (
								""
							)}
							{this.state.formProps && this.state.formProps.length && this.state.formProps.find(p => p.type === "group" && p.form === "group") ? (
								<Dropdown
									text={
										this.state.formProps.filter(p => p.type === "group" && p.form === "group").length +
										" New Group" +
										(this.state.formProps.filter(p => p.type === "group" && p.form === "group").length > 1 ? "s" : "")
									}
									icon="users"
									floating
									labeled
									button
									className="icon"
								>
									<Dropdown.Menu>
										{this.state.formProps
											.filter(p => p.type === "group" && p.form === "group")
											.map((group, index) => {
												return (
													<Dropdown.Item
														key={index}
														onClick={() => {
															const newFormProps = this.state.formProps;
															const itemIndex = newFormProps.findIndex(prop => prop.value === group.value);
															newFormProps.splice(itemIndex, 1);
															this.setState({
																formProps: newFormProps
															});
														}}
													>
														{group.value}
														<span className="dd-close-icon">
															<Icon name="close" color="red" />
														</span>
													</Dropdown.Item>
												);
											})}
									</Dropdown.Menu>
								</Dropdown>
							) : (
								""
							)}
						</Form.Field>
						<Button positive>Save Organization</Button>
					</Form>
					<Divider horizontal>Add Regions &amp; Groups</Divider>
					<Form
						noValidate
						ref="form"
						name="addRegions"
						onSubmit={(data: any) => {
							if (this.state.formProps && this.state.formProps.find(p => p.value === data.region)) {
								console.log("The Region: '" + data.region + "' has already been added.");
							} else {
								const newProp = [
									{
										type: "region",
										form: "group",
										value: data.region
									}
								];
								this.setState({
									formProps: this.state.formProps ? [...this.state.formProps, ...newProp] : newProp,
									region: ""
								});
							}
						}}
					>
						<Form.Field>
							<label>Add a Region</label>
							<Input
								name="region"
								placeholder="Add Region"
								value={this.state.region}
								onChange={(e, { name, value }) => this.setState({ region: value })}
								action={{ icon: "plus" }}
							/>
						</Form.Field>
					</Form>
					<Form
						noValidate
						ref="form"
						name="addGroups"
						onSubmit={(data: any) => {
							if (this.state.formProps && this.state.formProps.find(p => p.value === data.group)) {
								console.log("The Group: '" + data.group + "' has already been added.");
							} else {
								const newProp = [
									{
										type: "group",
										form: "group",
										value: data.group
									}
								];
								this.setState({
									formProps: this.state.formProps ? [...this.state.formProps, ...newProp] : newProp,
									group: ""
								});
							}
						}}
					>
						<Form.Field>
							<label className="pushdown">Add a Group</label>
							<Input
								name="group"
								placeholder="Add Group"
								value={this.state.group}
								onChange={(e, { name, value }) => this.setState({ group: value })}
								action={{ icon: "plus" }}
							/>
						</Form.Field>
					</Form>
				</Card.Content>
			</Card>
		);
	}

	addGroup(data: any) {
		const newRegions = [];
		const newGroups = [];
		if (this.state.formProps && this.state.formProps.length) {
			this.state.formProps.forEach(prop => {
				const newVal = prop.value;
				if (prop.form === "group") {
					if (prop.type === "region") {
						newRegions.push(newVal);
					}
					if (prop.type === "group") {
						newGroups.push(newVal);
					}
				}
			});
		}

		// build new group data object
		const newGroup = {
			name: data.groupname,
			store_type_id: data.store_type_id,
			regions: newRegions,
			groups: newGroups
		};

		if (newGroup.name) {
			this.props.create_group(newGroup);
		} else {
			console.log("New Group reguires a name");
		}
	}
}

export default AddGroupComponent;
