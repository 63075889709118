import track from "react-tracking";
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { restAPI } from "client/utils/rest";
import { RelativeDatePipe } from "client/utils/functions";
import { AnalyticsEventType } from "loopmein-shared";

import { Grid, Item, Loader, Table, Checkbox } from "semantic-ui-react";
import { PhotoCarousel } from "client/components/ImageHandling/PhotoCarousel";

import "./NotesSidebar.css";
@track(
  {
    event_type: AnalyticsEventType.NAVIGATION,
    event_subtype: "recon.inventory.notes",
  },
  { dispatchOnMount: true }
)
export class NotesComponentView extends React.Component<
  LMI.IReconNotesProps,
  LMI.IReconNotesState
> {
  constructor(props: LMI.IReconNotesProps) {
    super(props);

    this.state = {
      loading: false,
      notes: [],
      has_notes: false,
      lightbox_open: false,
      lightbox_photo_index: 0,
      lightbox_photos: [],
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.update_key !== prevProps.update_key ||
      prevProps.last_update !== this.props.last_update ||
      (prevProps.notes_open !== this.props.notes_open && this.props.notes_open)
    ) {
      this.fetchData();
    }
  }

  componentDidMount(): void {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true });
    restAPI({
      endpointName: "get_inventory_notes",
      urlArgs: [this.props.storeId, this.props.inventoryItemId],
      data: null,
      callback: (error, result) => {
        let alert;
        if (error) {
          alert = {
            type: "danger",
            message: this.props.onHandleErrorResponse(error),
          };
          this.props.onAlert(alert);
        }
        let hasNotes = false;
        for (const d in result.data) {
          if (result.data[d].length > 0) {
            hasNotes = true;
            break;
          }
        }
        this.setState({
          notes: result.data,
          loading: false,
          has_notes: hasNotes,
        });
        this.props.onSetHasNotes(hasNotes);
      },
    });
  };

  launchLightBox(photo_url: string, notesObj: Object) {
    let logs = [];
    for (const key in notesObj) {
      if (Object.prototype.hasOwnProperty.call(notesObj, key)) {
        const notes = notesObj[key];
        if (notes.length)
          logs = [...logs, ...notes.filter((n) => n.data_photo_url)];
      }
    }
    const lightbox_photo_index = logs.findIndex(
      (i) => i.data_photo_url === photo_url
    );
    const lightbox_photos: LMI.LBPhotoObject[] = logs.map((log) => ({
      url: log.data_photo_url,
      caption: { caption: log.description },
    }));
    this.setState({
      lightbox_open: true,
      lightbox_photos,
      lightbox_photo_index,
    });
  }

  getCommentGrid = (list, block, color) => {
    const { timelineFlags, active_tab } = this.props;
    const blockProp = block.split(" ").join("_");

    return (
      <Table className="notes-table" color={color} celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={2} className="head-cell">
              {active_tab === "TIMELINE" && (
                <Checkbox
                  slider
                  className="flag-toggle"
                  checked={timelineFlags[blockProp]}
                  onChange={(e, data) => this.toggleFlags(data, block)}
                />
              )}
              <span className="header-text">{block.toUpperCase()}</span>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.map((l, index) => {
            const assignedUser =
              l.assigned_user &&
              l.assigned_user.last_name &&
              " " +
                l.assigned_user.first_name +
                " " +
                l.assigned_user.last_name;
            return (
              <Table.Row key={l.created_at + index}>
                <Table.Cell className="content">
                  <div>
                    {l.comment
                      ? l.comment
                      : l.description
                      ? l.description
                      : `${l.system_log_type_name} ${
                          assignedUser ? assignedUser.trim() : ""
                        }`}
                  </div>
                  <div className="id">
                    {l.description && l.comment
                      ? `${l.description} -`
                      : `${
                          !l.description && !l.comment
                            ? l.system_log_type_name + " - "
                            : ""
                        }`}{" "}
                    {l.first_name} {l.last_name},{" "}
                    {RelativeDatePipe(l.created_at, false, false, true)}
                  </div>
                </Table.Cell>
                <Table.Cell className="photo-thumb">
                  {l.data_photo_url ? (
                    <img
                      loading="lazy"
                      src={`${l.data_photo_url}?q=90&ch=Save-Data&auto=compress`}
                      onClick={() =>
                        this.launchLightBox(l.data_photo_url, this.state.notes)
                      }
                    />
                  ) : (
                    <div className="no-photo" />
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  getPhaseGrid = (list, block, color) => {
    return (
      <Table className="notes-table" color={color} celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={2} className="head-cell">
              <span className="header-text">{block.toUpperCase()}</span>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.map((l) => {
            if (l.description) {
              return (
                <Table.Row key={l.created_at}>
                  <Table.Cell className="content">
                    <div>{`${l.description ? l.description + " " : ""}`}</div>
                    <div className="id">
                      {l.first_name} {l.last_name},{" "}
                      {RelativeDatePipe(l.created_at, false, false, false)}
                    </div>
                  </Table.Cell>
                  <Table.Cell className="photo-thumb">
                    {l.data_photo_url ? (
                      <img
                        loading="lazy"
                        src={`${l.data_photo_url}?q=90&ch=Save-Data&auto=compress`}
                        onClick={() =>
                          this.launchLightBox(
                            l.data_photo_url,
                            this.state.notes
                          )
                        }
                      />
                    ) : (
                      <div className="no-photo" />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            }
          })}
        </Table.Body>
      </Table>
    );
  };

  getDescriptionGrid = (list, block, color) => {
    const { timelineFlags, active_tab } = this.props;

    return (
      <Table className="notes-table" color={color} celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={2} className="head-cell">
              {active_tab === "TIMELINE" && (
                <Checkbox
                  slider
                  className="flag-toggle"
                  checked={timelineFlags[block]}
                  onChange={(e, data) => this.toggleFlags(data, block)}
                />
              )}
              <span className="header-text">{block.toUpperCase()}</span>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {list.map((l) => {
            if (l.description) {
              return (
                <Table.Row key={l.created_at}>
                  <Table.Cell className="content">
                    <div>{`${l.description ? l.description + " " : ""}${
                      l.comment ? "(Comment: " + l.comment + ")" : ""
                    }`}</div>
                    <div className="id">
                      {l.first_name} {l.last_name},{" "}
                      {RelativeDatePipe(l.created_at, false, false, true)}
                    </div>
                  </Table.Cell>
                  <Table.Cell className="photo-thumb">
                    {l.data_photo_url ? (
                      <img
                        loading="lazy"
                        src={`${l.data_photo_url}?q=90&ch=Save-Data&auto=compress`}
                        onClick={() =>
                          this.launchLightBox(
                            l.data_photo_url,
                            this.state.notes
                          )
                        }
                      />
                    ) : (
                      <div className="no-photo" />
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            }
          })}
        </Table.Body>
      </Table>
    );
  };

  toggleFlags = (data, block) => {
    const blockProp = block.split(" ").join("_");
    // this.setState({ [blockProp]: data.checked });
    this.props.onToggleTimelineFlags(blockProp, data.checked);
  };

  render() {
    const { notes_open, active_tab } = this.props;
    const {
      notes,
      loading,
      has_notes,
      lightbox_open,
      lightbox_photo_index,
      lightbox_photos,
    } = this.state;
    const {
      phase,
      task_description,
      vendor_task,
      accessory,
      issue,
      vehicle_note,
      inspection_note,
      misc,
    } = notes;

    if (notes_open) {
      return (
        <>
          {lightbox_open && (
            <PhotoCarousel
              photo_index={lightbox_photo_index}
              photos={lightbox_photos}
              onCloseLightbox={() =>
                this.setState({
                  lightbox_open: false,
                  lightbox_photo_index: 0,
                  lightbox_photos: [],
                })
              }
            />
          )}
          <Grid.Column
            id="NotesColumn"
            className="side-bar-notes"
            width={4}
            key={active_tab}
          >
            {!loading && !has_notes && (
              <div className="no-notes">
                No notes available for this vehicle.
              </div>
            )}
            {loading ? (
              <Loader className={`loader active`} size="small" />
            ) : (
              <Item.Group>
                {vehicle_note && vehicle_note.length > 0 && (
                  <Item>
                    {this.getCommentGrid(vehicle_note, "Vehicle Notes", "pink")}
                  </Item>
                )}
                {inspection_note && inspection_note.length > 0 && (
                  <Item>
                    {this.getCommentGrid(inspection_note, "Inspections", "red")}
                  </Item>
                )}
                {issue && issue.length > 0 && (
                  <Item>
                    {this.getDescriptionGrid(issue, "Issues", "yellow")}
                  </Item>
                )}
                {task_description && task_description.length > 0 && (
                  <Item>
                    {this.getDescriptionGrid(
                      task_description,
                      "Tasks",
                      "orange"
                    )}
                  </Item>
                )}
                {vendor_task && vendor_task.length > 0 && (
                  <Item>
                    {this.getDescriptionGrid(vendor_task, "Vendors", "green")}
                  </Item>
                )}
                {accessory && accessory.length > 0 && (
                  <Item>
                    {this.getDescriptionGrid(
                      accessory,
                      "Accessories",
                      "purple"
                    )}
                  </Item>
                )}
                {phase && phase.length > 0 && (
                  <Item>{this.getPhaseGrid(phase, "Stages", "blue")}</Item>
                )}
                {misc && misc.length > 0 && (
                  <Item>
                    {this.getDescriptionGrid(misc, "Miscellaneous", "brown")}
                  </Item>
                )}
              </Item.Group>
            )}
          </Grid.Column>
        </>
      );
    } else return <span />;
  }
}

const mapStateToProps = (state) => {
  return {
    triggerLogUpdate: state.app.admin.triggerLogUpdate,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export const NotesView = compose(connect(mapStateToProps, mapDispatchToProps))(
  NotesComponentView
);
