import { stringToInt } from "client/utils/functions";
import { StoreType } from "loopmein-shared";
import { RouteData } from "./RouteData";

function hasPermission(route: LMI.IAppRouteData, permissions: any[]): boolean {
	let has_perm = false;
	if (route.permissions.length <= 0) has_perm = true;
	else
		route.permissions.forEach(perm => {
			if (permissions.indexOf(perm) > -1) has_perm = true;
		});
	return has_perm;
}

function routePath(route: LMI.IAppRouteData): string {
	const typeId = localStorage.getItem("selectedStoreType") ? stringToInt(localStorage.getItem("selectedStoreType")) : null;
	if (typeId) {
		const storeType = typeId === StoreType.Dealership ? "dealerships" : `${typeId === StoreType.InternalVendor ? "i" : ""}vendors`;
		return `/admin/${route.userRoute ? "user" : storeType}/${route.id}${route.dynamicURL ? "/:id" : ""}`;
	} else return null;
}

function friskMenuActions(route: LMI.IAppRouteData, permissions: number[]) {
	if (route.menuActions && route.menuActions.length) {
		return route.menuActions.filter(a => {
			if (a.permissions) return hasPermission({ permissions: a.permissions } as LMI.IAppRouteData, permissions);
			else return true;
		});
	} else return [];
}

export const getTabRoutes = (tabsArray, permissions) => {
	const appRoutes = getAppRoutes(permissions);
	return tabsArray.map(tab => appRoutes.find(route => route.id === tab)).filter(i => i);
};

export const getAppRoutes = permissions => {
	const storeTypeId = localStorage.getItem("selectedStoreType") ? stringToInt(localStorage.getItem("selectedStoreType")) : null;
	return RouteData.filter(route => (storeTypeId && route.storeType ? route.storeType.includes(storeTypeId) : true))
		.filter(route => hasPermission(route, permissions) && (route.accessCallback ? route.accessCallback() : true))
		.map(route => ({ ...route, ...{ path: routePath(route), ...{ menuActions: friskMenuActions(route, permissions) } } }));
};

export const getMenuData = () => {
	const menuData = RouteData.filter(i => i.menuIndex >= 0)
		.sort((a, b) => a.menuIndex - b.menuIndex)
		.map(i => i.id);
	return menuData;
};
