// node_modules
import * as React from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
// components
import { Button } from "semantic-ui-react";
import { Form } from "formsy-semantic-ui-react";

export class AddEmployeeModalView extends React.Component<LMI.IAddEmployeeModalProps, any> {
	render() {
		const props = this.props;
		const assigned_employees = props.assigned_employees.map(ae => ae.email);
		const employees =
			props.employees &&
			props.employees
				.map(emp => {
					if (assigned_employees.indexOf(emp.user.email) < 0) {
						return { text: emp.user.full_name, value: emp.id, key: emp.id };
					} else return null;
				})
				.filter(emp => emp !== null);
		return (
			<div id="job-role-detail">
				<Form onSubmit={this.submitForm.bind(this)}>
					<Form.Field>
						{employees && <Form.Select search label="Employees" name="employee_id" options={employees} placeholder="Select an Employee" />}
					</Form.Field>
					<Button content="Assign Employee" positive />
				</Form>
			</div>
		);
	}

	submitForm(formData: any) {
		this.props.onSubmit(formData);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const AddEmployeeModal = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IEmployeesQueryProps, any, any, ClassAttributes<any>>(gqlQueries.employeesByIsActive, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					isActive: true
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, employees, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				employees,
				refetch
			};
		}
	})
)(AddEmployeeModalView) as React.ComponentType<any>;

export default AddEmployeeModal;
