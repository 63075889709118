import * as React from "react";
import { ClassAttributes } from "react";
import { Button, Grid, Header, Icon, Input, Loader, Modal, Popup } from "semantic-ui-react";
import { restAPI } from "../../../../../../utils/rest";
import { connect } from "react-redux";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { flowRight as compose } from "lodash";

export class EditableCardView extends React.Component<LMI.IReconCardProps, LMI.IReconCardState> {
	constructor(props: LMI.IReconCardProps) {
		super(props);

		this.state = {
			saving: false,
			change_made: false,
			name: props.name,
			description: props.description,
			work_timer_enabled: props.work_timer_enabled,
			warning_threshold_minutes: props.warning_threshold_minutes,
			warning_threshold_hours: props.warning_threshold_hours,
			warning_threshold_days: props.warning_threshold_days,
			error_threshold_minutes: props.error_threshold_minutes,
			error_threshold_hours: props.error_threshold_hours,
			error_threshold_days: props.error_threshold_days,
			color_code: props.color_code ? props.color_code : "#ffffff",
			final_stage: props.final_stage,
			has_new: props.has_new,
			has_used: props.has_used,
			modal_open: false,
			modal: {},
			open_confirmation: false
		};
	}

	handleRemoveCard = (event: any) => {
		const { id, storeId } = this.props;
		const data = { phase_id: id };

		restAPI({
			endpointName: "removeStorePhases",
			urlArgs: [storeId],
			data,
			callback: error => {
				let alert;
				if (error) {
					alert = { type: "danger", message: this.props.onHandleErrorResponse(error) };
					this.props.onSendAlert(alert);
				}
				this.setState({ open_confirmation: false }, () => this.props.refetchRecon());
			}
		});
	};

	handleUpdateCard = (name: any) => {
		if (this.state.change_made) {
			const { id, storeId } = this.props;
			this.setState({ saving: true });

			const data = [{ id, [name]: this.state[name] }];

			restAPI({
				endpointName: "modify_store_phases",
				urlArgs: [storeId],
				data,
				callback: error => {
					let alert;
					if (error) {
						alert = { type: "danger", message: this.props.onHandleErrorResponse(error) };
						this.props.onSendAlert(alert);
					}
					this.setState({ saving: false, change_made: false });
				}
			});
		}
	};

	handleChange = (event: any, data: any) => {
		// console.log("handleChange", event, data, event.currentTarget.checked);
		const value = data.type === "checkbox" ? event.currentTarget.checked : data.type === "number" && !data.value ? 0 : data.value;
		this.setState({ [data.name]: value, saving: false, change_made: true }, () => {
			this.handleUpdateCard(data.name);
		});
	};

	findElement = (element: HTMLElement, className: string) => {
		if (element.classList.contains(className)) {
			return element;
		}
		return this.findElement(element.parentElement, className);
	};

	handleModalOpen = event => {
		const element = this.findElement(event.target, "event-container");
		const toolName = element.getAttribute("data-tool");
		this.props.onHandleModalOpen({ ...this.props, toolName });
	};

	assignments = (assignments, id) => {
		const thisPhaseAssignments = assignments && assignments.filter(a => a.phase_id === id);
		return (
			thisPhaseAssignments &&
			thisPhaseAssignments.map((a, index) => {
				return index < thisPhaseAssignments.length - 1 ? `${a.name}, ` : a.name;
			})
		);
	};

	stageDestinations = target_stages => {
		return (
			target_stages &&
			target_stages.map((a, index) => {
				return index < target_stages.length - 1 ? `${a.name}, ` : a.name;
			})
		);
	};

	reconAssignments = (assignments, id) => {
		const filtered = assignments && assignments.filter(a => a.phase_id === id);
		return filtered && filtered[0]
			? filtered[0].recon_levels.map((a, index) => {
					return index < filtered[0].recon_levels.length - 1 ? `${a.name}, ` : a.name;
			  })
			: "";
	};

	handleOpenConfirmation = () => {
		this.setState({ open_confirmation: true });
	};

	handleCloseConfirmation = () => {
		this.setState({ open_confirmation: false });
	};

	render() {
		const { order, id, eventProps, draggable, user_assignments, job_title_assignments, recon_assignments, assigned_phases } = this.props;

		const {
			name,
			work_timer_enabled,
			color_code,
			description,
			error_threshold_days,
			error_threshold_hours,
			error_threshold_minutes,
			warning_threshold_days,
			warning_threshold_hours,
			warning_threshold_minutes,
			final_stage,
			has_new,
			has_used,
			saving,
			open_confirmation
		} = this.state;

		const reconAssignment = this.reconAssignments(recon_assignments, id);
		const userAssignment = this.assignments(user_assignments, id);
		const jobTitleAssignment = this.assignments(job_title_assignments, id);
		const stageDestinations = assigned_phases ? this.stageDestinations(assigned_phases) : null;
		return (
			<Grid.Column className={`card-column ${draggable && "editable"}`} data-order={order} data-id={id}>
				<div
					id={`card-${id}`}
					style={{ boxShadow: "0 -3px 0 0 " + color_code }}
					className={`ui card`}
					{...eventProps}
					draggable={draggable}
					data-order={order}
					data-id={id}
				>
					{saving && <Loader className={`loader active`} size="small" />}
					<div className="content top">
						<div className={`header colored`}>
							<Grid>
								<Grid.Column width={draggable ? 13 : 16} className="slim-right">
									<Input className="input med title" name="name" value={name} placeholder="Stage Name" onChange={(e, data) => this.handleChange(e, data)} />
								</Grid.Column>
								{draggable && (
									<Grid.Column width={3}>
										<i className="icon grid layout handle left" data-id={id} />
									</Grid.Column>
								)}
							</Grid>
						</div>
					</div>
					<div className="content card-body">
						<h4 className="ui sub header">
							<Input
								className="input med"
								name="description"
								value={description}
								placeholder="Optional Stage Description"
								onChange={(e, data) => this.handleChange(e, data)}
							/>
						</h4>
						<div className="ui small feed">
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid>
											<Grid.Column width={12}>Allow New Vehicles:</Grid.Column>
											<Grid.Column width={4} className="recon-setting-checks">
												<Input type="checkbox" className="input" name="has_new" checked={has_new} onChange={(e, data) => this.handleChange(e, data)} />
											</Grid.Column>
										</Grid>
									</div>
								</div>
							</div>
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid>
											<Grid.Column width={12}>Allow Used Vehicles:</Grid.Column>
											<Grid.Column width={4} className="recon-setting-checks">
												<Input type="checkbox" className="input" name="has_used" checked={has_used} onChange={(e, data) => this.handleChange(e, data)} />
											</Grid.Column>
										</Grid>
									</div>
								</div>
							</div>
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid>
											<Grid.Column width={12}>Final Stage:</Grid.Column>
											<Grid.Column width={4} className="recon-setting-checks">
												<Input type="checkbox" className="input" name="final_stage" checked={final_stage} onChange={(e, data) => this.handleChange(e, data)} />
											</Grid.Column>
										</Grid>
									</div>
								</div>
							</div>
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid>
											<Grid.Column width={12}>Include in Recon Timer:</Grid.Column>
											<Grid.Column width={4} className="recon-setting-checks">
												<Input
													type="checkbox"
													className="input"
													name="work_timer_enabled"
													checked={work_timer_enabled}
													onChange={(e, data) => this.handleChange(e, data)}
												/>
											</Grid.Column>
										</Grid>
									</div>
								</div>
							</div>
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid>
											<Grid.Column width={5}>Warning:</Grid.Column>
											<Grid.Column width={11}>
												<Input
													type="number"
													className="input mini"
													placeholder="DD"
													name="warning_threshold_days"
													min={0}
													max={180}
													value={warning_threshold_days}
													onChange={(e, data) => this.handleChange(e, data)}
												/>
												<Input
													type="number"
													className="input mini"
													placeholder="HH"
													min={0}
													max={23}
													name="warning_threshold_hours"
													value={warning_threshold_hours}
													onChange={(e, data) => this.handleChange(e, data)}
												/>
												<Input
													type="number"
													className="input mini"
													placeholder="MM"
													min={0}
													max={59}
													name="warning_threshold_minutes"
													value={warning_threshold_minutes}
													onChange={(e, data) => this.handleChange(e, data)}
												/>
											</Grid.Column>
										</Grid>
									</div>
								</div>
							</div>
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid>
											<Grid.Column width={5}>Error:</Grid.Column>
											<Grid.Column width={11}>
												<Input
													type="number"
													className="input mini"
													placeholder="DD"
													name="error_threshold_days"
													min={0}
													max={180}
													value={error_threshold_days}
													onChange={(e, data) => this.handleChange(e, data)}
												/>
												<Input
													type="number"
													className="input mini"
													placeholder="HH"
													name="error_threshold_hours"
													min={0}
													max={23}
													value={error_threshold_hours}
													onChange={(e, data) => this.handleChange(e, data)}
												/>
												<Input
													type="number"
													className="input mini"
													placeholder="MM"
													name="error_threshold_minutes"
													min={0}
													max={59}
													value={error_threshold_minutes}
													onChange={(e, data) => this.handleChange(e, data)}
												/>
											</Grid.Column>
										</Grid>
									</div>
								</div>
							</div>
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid className="event-container" onMouseUp={e => this.handleModalOpen(e)} data-tool="workflow">
											<Popup
												content={reconAssignment}
												disabled={!reconAssignment || reconAssignment.length <= 0}
												trigger={
													<Grid.Column className="editable" width={16}>
														<span className="title">Workflow Assigned: </span>
														<div className="text snapped">{reconAssignment}</div>
													</Grid.Column>
												}
											/>
										</Grid>
									</div>
								</div>
							</div>
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid className="event-container" onMouseUp={e => this.handleModalOpen(e)} data-tool="users">
											<Popup
												content={userAssignment}
												disabled={!userAssignment || userAssignment.length <= 0}
												trigger={
													<Grid.Column className="editable" width={16}>
														<span className="title">User(s) Assigned: </span>
														<div className="text snapped">{userAssignment}</div>
													</Grid.Column>
												}
											/>
										</Grid>
									</div>
								</div>
							</div>
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid className="event-container" onMouseUp={e => this.handleModalOpen(e)} data-tool="job titles">
											<Popup
												content={jobTitleAssignment}
												disabled={!jobTitleAssignment || jobTitleAssignment.length <= 0}
												trigger={
													<Grid.Column className="editable" width={16}>
														<span className="title">Job Title(s) Assigned: </span>
														<div className="text snapped">{jobTitleAssignment}</div>
													</Grid.Column>
												}
											/>
										</Grid>
									</div>
								</div>
							</div>
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid className="event-container" onMouseUp={e => this.handleModalOpen(e)} data-tool="stage destinations">
											<Popup
												content={stageDestinations}
												disabled={!stageDestinations || stageDestinations.length <= 0}
												trigger={
													<Grid.Column className="editable" width={16}>
														{!assigned_phases ? (
															<Loader size="mini" inline active className="inline-loader" />
														) : (
															<>
																<span className="title">Stage Destinations: </span>
																<div className="text snapped">{stageDestinations}</div>
															</>
														)}
													</Grid.Column>
												}
											/>
										</Grid>
									</div>
								</div>
							</div>
							<div className="event">
								<div className="content">
									<div className="summary">
										<Grid>
											<Grid.Column width={10}>Color:</Grid.Column>
											<Grid.Column width={6}>
												<Input type="color" className="input small" name="color_code" value={color_code} onChange={(e, data) => this.handleChange(e, data)} />
											</Grid.Column>
										</Grid>
									</div>
								</div>
							</div>
						</div>
					</div>
					{!draggable && (
						<Modal
							id="confirmation"
							trigger={
								<Button className="remove" color="red" size="mini" attached="bottom" onClick={this.handleOpenConfirmation}>
									DELETE
								</Button>
							}
							open={open_confirmation}
							onClose={this.handleCloseConfirmation}
							closeIcon
						>
							<Header content={`Remove Stage: '${name}'`} />
							<Modal.Content className="body">
								<p>Are you sure you want to remove this stage?</p>
							</Modal.Content>
							<Modal.Actions>
								<Button color="red" onClick={this.handleCloseConfirmation}>
									<Icon name="remove" /> No
								</Button>
								<Button color="green" onClick={this.handleRemoveCard}>
									<Icon name="checkmark" /> Yes
								</Button>
							</Modal.Actions>
						</Modal>
					)}
				</div>
			</Grid.Column>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const EditableCard = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<any, any, any, ClassAttributes<any>>(gqlQueries.dealership.stageEditorAssignments, {
		options: (props: any) => {
			return {
				variables: {
					storeId: parseInt(props.storeId, 10),
					stageId: props.id
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, stage_workflow_admin, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };

			return {
				assigned_phases: stage_workflow_admin.stage.target_stages,
				refetchAssignedPhases: refetch
			};
		}
	})
)(EditableCardView) as React.ComponentType<any>;

export default EditableCard;
