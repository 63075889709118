// node_modules
import * as React from "react";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { Button, Image, TextArea } from "semantic-ui-react";
import { generateThumbnail, humanFileSize, calculateBase64ImageSize, promiseBase64, getFileObjectFromPath } from "client/utils/fileHandling";
import { FileDropComponent } from "client/components/FileHandling";

export class VehicleNoteForm extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			note: null,
			files: [],
			cost: null
		};
	}

	getStateValues() {
		return { noteForm: { description: this.state.note ? this.state.note.value : null, files: this.state.files }, completion: { cost: this.state.cost } };
	}

	getReadyTextPreview(props, photo_tag) {
		if ((props.props && props.props.before_photo_full_url) || (props.props && props.props.after_photo_full_url)) {
			const url = photo_tag === "before" ? props.before_photo_full_url : props.after_photo_full_url;
			return (
				<span>
					<Image src={url} rounded size="tiny" alt="photo preview" className="imagedoc" />
					<span style={{ marginTop: "10px" }}>Drop your photo or click here to upload</span>
				</span>
			);
		} else {
			return <span>Drop your photo or click here to upload</span>;
		}
	}

	isMissingRequired() {
		if (this.props.requireNote) {
			const values = this.getStateValues();
			const noteForm = values.noteForm;
			return !noteForm.description && noteForm.files.length == 0;
		} else return false;
	}

	render() {
		const files: any = this.props.files && this.props.files.length > 0 ? this.props.files : false;
		return (
			<div className="ui form">
				{this.props.cost && (
					<div style={{ marginBottom: "15px" }}>
						<span style={{ marginRight: "10px" }}>Override Cost: </span>
						<NumericTextBoxComponent format="c2" value={this.props.props ? this.props.props.cost : 0} change={data => this.setState({ cost: data.value })} />
					</div>
				)}
				<TextArea id="comment" className="notes" rows={4} placeholder="Enter note here" onChange={(e, data) => this.setState({ note: data })} />
				{files && (
					<div className="file-uploads">
						{files.map(photo_tag => {
							const beforeAfter = ["before", "after"].includes(photo_tag);
							const fileDropProps = {
								multiple: false,
								fileSizeMaxMB: 2,
								fileTypes: ["image/png", "image/jpg", "image/jpeg", "image/gif"],
								enableCropping: false,
								dropIcon: "photo",
								dropMessage: "Drop your photo or click here to upload",
								onReturnFileHandles: fileHandles => this.setState({ files: [...this.state.files, ...[{ file: fileHandles[0], photo_tag }]] }),
								generateThumbnail: args => generateThumbnail(args),
								humanFileSize: args => humanFileSize(args),
								calculateBase64ImageSize: args => calculateBase64ImageSize(args),
								promiseBase64: args => promiseBase64(args),
								getFileObjectFromPath: args => getFileObjectFromPath(args)
							};

							return (
								<div key={photo_tag} className={beforeAfter ? "before-after" : ""}>
									{beforeAfter && <span className="label">{`${photo_tag} Photo`}</span>}
									<FileDropComponent {...fileDropProps} />
								</div>
							);
						})}
					</div>
				)}
				<Button positive disabled={this.isMissingRequired()} content={this.props.btnText} onClick={() => this.props.onSubmit(this.getStateValues())} />
			</div>
		);
	}
}

export default VehicleNoteForm;
