// node_modules
import { gqlQueries } from "gql-imports";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { Props } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
// components
import { Form, Loader } from "semantic-ui-react";
import "./VehicleSelector.css";

class VehicleSelectorView extends React.Component<LMI.VehicleSelectorProps, undefined> {
	constructor(props) {
		super(props);
	}

	renderVehicleSelect = () => {
		const { loading, value, trimLabel, spec, isRequired, isDisabled, isMultiSelect, onSelection, hideLabel } = this.props;
		const label = trimLabel ? `${spec} for ${trimLabel}` : spec;
		const selectValue = isMultiSelect ? (Array.isArray(value) ? value : value !== undefined ? [value] : []) : value !== undefined ? value : "";

		return (
			<Form.Field required={isRequired} className="option-select">
				{loading && <Loader indeterminate />}
				<Form.Select
					search
					clearable
					label={hideLabel ? false : label}
					required={isRequired}
					disabled={isDisabled}
					multiple={isMultiSelect}
					name={spec.toLowerCase()}
					className="vehicle-select"
					value={selectValue}
					placeholder={`Select a ${spec}`}
					options={this.formatSelectOptions()}
					onChange={(e, data) => onSelection(data.value)}
				/>
			</Form.Field>
		);
	};

	formatSelectOptions = () => {
		// format select option and consider useItemIdsAsValues when mapping
		const { useItemIdsAsValues } = this.props;
		return this.props.options && this.props.options.length > 0
			? this.props.options.map((option: any, key) => {
					const value = useItemIdsAsValues ? option.id : option && option.toLowerCase();
					const text = useItemIdsAsValues ? option.name : option;
					return { key, text, value };
			  })
			: [];
	};

	clearSelection = () => {
		this.props.onSelection("nullOption");
	};

	render() {
		if (this.props.hasErrors) {
			console.log(`VehicleSelector Data errors: ${this.props.error}`);
		}
		return this.renderVehicleSelect();
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const VehicleSelector = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IVehicleMakePropsGQL, any, any, Props<any>>(gqlQueries.super.vehicleMakes, {
		skip: (ownProps: LMI.VehicleSelectorProps) => ownProps.spec !== "Make" || ownProps.useItemIdsAsValues,
		options: (props: any) => {
			return {
				variables: {
					year: props.context.year
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, vehicle_makes, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				options: vehicle_makes,
				refetch
			};
		}
	}),
	graphql<LMI.IVehicleMakeWithIdPropsGQL, any, any, Props<any>>(gqlQueries.super.vehicleMakesWithId, {
		skip: (ownProps: LMI.VehicleSelectorProps) => ownProps.spec !== "Make" || !ownProps.useItemIdsAsValues,
		options: (props: any) => {
			return {
				variables: {
					years: props.context.year
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, vehicle_makes_with_id, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				options: vehicle_makes_with_id.makes,
				refetch
			};
		}
	}),
	graphql<LMI.IVehicleModelPropsGQL, any, any, Props<any>>(gqlQueries.super.vehicleModels, {
		skip: (ownProps: LMI.VehicleSelectorProps) => ownProps.spec !== "Model" || !ownProps.context.make,
		options: (props: any) => {
			return {
				variables: {
					year: props.context.year,
					make: props.context.make
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, vehicle_models, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				options: vehicle_models,
				refetch
			};
		}
	}),
	graphql<LMI.IVehicleTrimsPropsGQL, any, any, Props<any>>(gqlQueries.super.vehicleTrims, {
		skip: (ownProps: LMI.VehicleSelectorProps) => ownProps.spec !== "Trim" || !ownProps.context.make || !ownProps.context.model,
		options: (props: any) => {
			return {
				variables: {
					year: props.context.year,
					make: props.context.make,
					model: props.context.model
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, vehicle_trims, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true };
			return {
				options: vehicle_trims,
				refetch
			};
		}
	})
)(VehicleSelectorView) as React.ComponentType<any>;

export default VehicleSelector;
