// node_modules
import { gqlQueries } from "gql-imports";
import { changeSubtab } from "api/redux/actions";
import { TabsContainer, TCProps } from "client/pages/admin/components/ReactTabsContainer";

import { stringToInt } from "client/utils/functions";
import * as React from "react";
import { flowRight as compose } from "lodash";
import { ClassAttributes } from "react";
import { graphql } from "@apollo/react-hoc";
import { connect } from "react-redux";
// components
import { Header, Icon, List, Message, Segment } from "semantic-ui-react";
import { Loading } from "../../../../../components/Loading";
import { buildTabTarget } from "client/components/Menu/utils";
import SubTabContainers from "client/components/Routes/SubTabContainers";
import "./ClientsTabPanel.css";

export class ClientsTabPanelView extends React.Component<LMI.IClientsTPProps, LMI.IClientsTPState> {
	constructor(props) {
		super(props);
		this.state = {
			storeId: null,
			storeName: null,
			store_code: null,
			vendorDealers: [],
			subRouteId: "contact"
		};
	}

	render() {
		const { loading, hasErrors, message, vendor_dealers } = this.props;
		const { storeId, storeName, store_code, vendorDealers } = this.state;

		if (hasErrors) {
			console.log("GQL Errors", message);
			return null;
		}
		if (!vendor_dealers || loading) return <Loading />;

		const tabProps = this.getTabProps(storeId, storeName, store_code);

		return (
			<div className="clients-tab-panel panel-content">
				<Segment className="approved-clients-container">
					<Header size="tiny">
						<Icon name="wrench" />
						Clients
					</Header>
					{vendorDealers.length ? (
						<List selection divided>
							{vendorDealers.map(li => {
								const selected = storeId === li.id.toString();
								return (
									<List.Item
										key={li.id}
										header={li.header}
										description={li.description}
										className={selected ? "highlight-vendor" : null}
										onClick={() => {
											if (storeId !== li.id || !localStorage.getItem(SubTabContainers.clients)) {
												localStorage.setItem(SubTabContainers.clients, li.id);
												this.setState({ storeId: li.id, storeName: li.header, store_code: li.store_code });
											}
										}}
									/>
								);
							})}
						</List>
					) : (
						<Message style={{ textAlign: "left" }} icon="warning circle" header="Nothing to Show" content="There are currently no clients for this vendor." />
					)}
				</Segment>
				{storeId !== null ? <TabsContainer {...tabProps} /> : ""}
			</div>
		);
	}

	getTabProps(clientId: string, storeName: string, store_code: string): TCProps {
		const { subRouteId } = this.state;
		const { storeId, permissions, viewType } = this.props;
		const dataPassThru = {
			clientId,
			orgId: stringToInt(localStorage.getItem("selectedOrgId")),
			vendorId: storeId,
			storeName,
			store_code
		};
		return {
			subRouteId,
			subTabContainer: SubTabContainers.clients,
			permissions,
			classes: "clients-tab-panel-tabs",
			viewType: `${viewType} sub-component`,
			dataPassThru,
			onChange: (tabSelected: string) => this.selectedTab(tabSelected)
		};
	}

	selectedTab(tab) {
		this.props.changeSubtab(tab);
		this.props.history.push(buildTabTarget(this.props.match.path, tab));
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let { vendorDealers, storeId, storeName, store_code } = prevState;

		// set vendorDealers
		if (nextProps.vendor_dealers && nextProps.vendor_dealers.length > 0) {
			if (vendorDealers.length !== nextProps.vendor_dealers.length) {
				vendorDealers = nextProps.vendor_dealers.map((client: LMI.IVendorDealersProps) => {
					return {
						id: client.store_id.toString(),
						header: client.name,
						store_code: client.store_code,
						description: client.open_task_count + " open tasks"
					};
				});
			}
		}

		// set selected customer
		if (vendorDealers.length > 0) {
			const lsValue: number = parseInt(localStorage.getItem(SubTabContainers.clients), 10);
			const selectedClientStore: number = !isNaN(lsValue) && lsValue > -1 ? lsValue : -1;
			const selectedClientIndex: number = selectedClientStore > -1 ? vendorDealers.findIndex(elm => elm.id === `${lsValue}`) : 0;
			const sd: LMI.IVendorDealerInterface = vendorDealers[selectedClientIndex];
			storeId = sd?.id;
			storeName = sd?.header;
			store_code = sd?.store_code;
		}

		const subRouteId = nextProps.match ? nextProps.match.params.id : prevState.subRouteId;
		return { vendorDealers, storeId, storeName, store_code, subRouteId };
	}

	componentWillUnmount() {
		this.props.changeSubtab(null);
	}
}

const mapStateToProps = (state: any) => {
	return {
		storeId: state.app.admin.storeId,
		permissions: state.app.admin.permissions
	};
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		changeSubtab: (tabName: string) => {
			dispatch(changeSubtab(tabName));
		}
	};
};

export const ClientsTabPanel = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.IClientsQueryProps, any, any, ClassAttributes<any>>(gqlQueries.vendor.clients, {
		skip: (ownProps: any) => !ownProps.storeId,
		options: (props: any) => {
			return {
				variables: {
					vendorId: stringToInt(props.storeId)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, vendor_dealers, refetch } }): any => {
			if (loading) return { loading: true };
			if (error) return { hasErrors: true, message: error };

			return {
				vendor_dealers,
				refetch
			};
		}
	})
)(ClientsTabPanelView) as React.ComponentType<any>;

export default ClientsTabPanel;
