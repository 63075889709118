import { restAPI } from "../../../../../utils/rest";
import * as React from "react";
import { ClassAttributes } from "react";
import { connect } from "react-redux";
import { flowRight as compose } from "lodash";
import { graphql } from "@apollo/react-hoc";
import { gqlQueries } from "gql-imports";
import { Accordion, Button, Card, Checkbox, Container, Dimmer, Grid, Header, Icon, List, Loader } from "semantic-ui-react";
import { Form, Input } from "formsy-semantic-ui-react";
import { stringToInt } from "client/utils/functions";

import "./GroupInfoComponent.css";

export class GroupInfoComponentView extends React.Component<LMI.SUAdminGroupProps, LMI.SUAdminGroupState> {
	constructor(props) {
		super(props);

		this.state = {
			editGroupInfo: false,
			groupDetail: -1,
			addGroup: false,
			addRegion: false,
			editRegion: null,
			editGroup: null
		};
	}

	render() {
		if (this.props.loading) {
			return (
				<Dimmer active inverted>
					<Loader inverted />
				</Dimmer>
			);
		}

		const group = this.props.su_organizations ? this.props.su_organizations[0] : this.props.group;

		return (
			<div id="group-info-component">
				<Grid>
					<Grid.Column width={13}>
						{this.state.editGroupInfo ? (
							<Form noValidate onSubmit={this.updateOrg.bind(this)}>
								<Form.Field>
									<Icon name="close" color="red" className="action-icon" link onClick={() => this.setState({ editGroupInfo: false })} />
									<label>Organization Name</label>
									<Input name="name" value={group.name} action={{ icon: "save" }} />
								</Form.Field>
							</Form>
						) : (
							<Container className="group-info">
								<Header as="h3">
									{group.name}
									<Icon
										link
										name="pencil"
										className="edit-group-icon"
										color="blue"
										onClick={() => {
											this.setState({
												editGroupInfo: true
											});
										}}
									/>
								</Header>
								<small>
									Group ID: <strong>{group.id}</strong>
								</small>
							</Container>
						)}
					</Grid.Column>
					<Grid.Column width={3}>
						<Checkbox toggle checked={group.is_active} label={group.is_active ? "Active" : "Not Active"} onChange={(e, data) => this.toggleActive(data)} />
					</Grid.Column>
					<Grid.Column width={16}>
						<Container>
							<Grid>
								<Grid.Column width={16}>
									<Accordion fluid styled>
										<Accordion.Title
											active={this.state.groupDetail === 0}
											onClick={(e, titleProps) => {
												const detail = titleProps.active ? -1 : 0;
												this.setState({
													groupDetail: detail
												});
											}}
										>
											<Icon name="dropdown" />
											Regions
										</Accordion.Title>
										<Accordion.Content active={this.state.groupDetail === 0}>
											{group.regions && group.regions.length ? (
												<List className="group-detail-list">
													{group.regions.map(region => {
														return (
															<List.Item key={region.id}>
																{this.state.editRegion === region.id ? (
																	<Form noValidate onSubmit={data => this.updateRegion(data, region.id)}>
																		<Form.Field>
																			<Icon name="close" color="red" className="action-icon" link onClick={() => this.setState({ editRegion: null })} />
																			<label>Edit Region Name</label>
																			<Input name="name" value={region.name} action={{ icon: "save" }} />
																		</Form.Field>
																	</Form>
																) : (
																	<span>
																		<Icon link name="pencil" onClick={() => this.setState({ editRegion: region.id })} /> {region.name}
																	</span>
																)}
															</List.Item>
														);
													})}
												</List>
											) : (
												<Header as="h4">There are currently no regions setup</Header>
											)}
											{this.state.addRegion ? (
												<Card>
													<Card.Content>
														<Form noValidate onSubmit={this.addNewRegion.bind(this)}>
															<Form.Field>
																<Icon name="close" color="red" className="action-icon" link onClick={() => this.setState({ addRegion: false })} />
																<label>Region Name</label>
																<Input autoFocus name="name" action={{ icon: "save" }} />
															</Form.Field>
														</Form>
													</Card.Content>
												</Card>
											) : (
												<Button
													size="tiny"
													onClick={() => {
														this.setState({ addRegion: true });
													}}
												>
													Add a Region
												</Button>
											)}
										</Accordion.Content>

										<Accordion.Title
											active={this.state.groupDetail === 1}
											onClick={(e, titleProps) => {
												const detail = titleProps.active ? -1 : 1;
												this.setState({
													groupDetail: detail
												});
											}}
										>
											<Icon name="dropdown" />
											Groups
										</Accordion.Title>
										<Accordion.Content active={this.state.groupDetail === 1}>
											{group.groups && group.groups.length ? (
												<List className="group-detail-list">
													{group.groups.map(gr => {
														return (
															<List.Item key={gr.id}>
																{this.state.editGroup === gr.id ? (
																	<Form noValidate onSubmit={data => this.updateGroup(data, gr.id)}>
																		<Form.Field>
																			<Icon name="close" color="red" className="action-icon" link onClick={() => this.setState({ editGroup: null })} />
																			<label>Edit Group Name</label>
																			<Input name="name" value={gr.name} action={{ icon: "save" }} />
																		</Form.Field>
																	</Form>
																) : (
																	<span>
																		<Icon link name="pencil" onClick={() => this.setState({ editGroup: gr.id })} /> {gr.name}
																	</span>
																)}
															</List.Item>
														);
													})}
												</List>
											) : (
												<Header as="h4">There are currently no groups setup</Header>
											)}
											{this.state.addGroup ? (
												<Card>
													<Card.Content>
														<Form noValidate onSubmit={this.addNewGroup.bind(this)}>
															<Form.Field>
																<Icon name="close" color="red" className="action-icon" link onClick={() => this.setState({ addGroup: false })} />
																<label>Group Name</label>
																<Input autoFocus name="name" action={{ icon: "save" }} />
															</Form.Field>
														</Form>
													</Card.Content>
												</Card>
											) : (
												<Button
													size="tiny"
													onClick={() => {
														this.setState({ addGroup: true });
													}}
												>
													Add a Group
												</Button>
											)}
										</Accordion.Content>
									</Accordion>
								</Grid.Column>
							</Grid>
						</Container>
					</Grid.Column>
					<Grid.Column width={16}>
						<Header as="h4">Stores in this organization</Header>
					</Grid.Column>
				</Grid>
			</div>
		);
	}

	toggleActive(data: any) {
		const datas = { is_active: data.checked };
		this.updateOrg(datas);
	}

	updateOrg(data: any) {
		restAPI({
			endpointName: "suUpdateOrganization",
			urlArgs: [this.props.su_organizations[0].id],
			data: data,
			callback: (err, res) => {
				if (!err) {
					this.setState({ editGroupInfo: false });
					this.props.refetch();
				}
			}
		});
	}

	updateRegion(data: any, regionId: number) {
		const region = {
			...data,
			organization_id: this.props.su_organizations[0].id
		};
		restAPI({
			endpointName: "suUpdateRegion",
			urlArgs: [regionId],
			data: region,
			callback: (err, res) => {
				if (!err) {
					this.setState({ editRegion: null });
					this.props.refetch();
				}
			}
		});
	}

	addNewRegion(data: any) {
		const region = {
			...data,
			organization_id: this.props.su_organizations[0].id
		};
		restAPI({
			endpointName: "suAddRegion",
			urlArgs: null,
			data: region,
			callback: (err, res) => {
				if (!err) {
					this.setState({ addRegion: false });
					this.props.refetch();
				}
			}
		});
	}

	updateGroup(data: any, groupId: number) {
		const group = {
			...data,
			organization_id: this.props.su_organizations[0].id
		};
		restAPI({
			endpointName: "suUpdateGroup",
			urlArgs: [groupId],
			data: group,
			callback: (err, res) => {
				if (!err) {
					this.setState({ editGroup: null });
					this.props.refetch();
				}
			}
		});
	}

	addNewGroup(data: any) {
		const group = {
			...data,
			organization_id: this.props.su_organizations[0].id
		};
		restAPI({
			endpointName: "suAddGroup",
			urlArgs: null,
			data: group,
			callback: (err, res) => {
				if (!err) {
					this.setState({ addGroup: false });
					this.props.refetch();
				}
			}
		});
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const GroupInfoComponent = compose(
	connect(mapStateToProps, mapDispatchToProps),
	graphql<LMI.OrgAdminGQL, any, any, ClassAttributes<any>>(gqlQueries.super.organization, {
		options: (props: any) => {
			return {
				variables: {
					id: stringToInt(props.group.id)
				},
				fetchPolicy: "network-only"
			};
		},
		props: ({ data: { error, loading, su_organizations, refetch } }): any => {
			if (loading) {
				return { loading: true };
			}
			if (error) {
				return { hasErrors: true };
			}
			return {
				su_organizations,
				refetch
			};
		}
	})
)(GroupInfoComponentView) as React.ComponentType<any>;

export default GroupInfoComponent;
