import * as Raven from "raven-js";
import * as React from "react";
import { withRouter } from "react-router";
import { flowRight as compose } from "lodash";
import { connect } from "react-redux";
import RenderDataGrid, { DataGridProps, GridColumnHeaders, GridToolOptions } from "../../../../../../components/DataGrid/DataGrid";
import { Column, GridComponent } from "@syncfusion/ej2-react-grids";
import { AnalyticsEventType, Months } from "loopmein-shared";
import { QueryCellInfoEventArgs, RowDataBoundEventArgs } from "@syncfusion/ej2-grids";
import { ModalComponent } from "../../../ModalComponent";
import { StageBreakdownDetailGrid } from "./detail/detail";
import { StageBreakdownReconLevelDetailGrid } from "./detail/reconLevelDetail";
import { Loader } from "semantic-ui-react";
import track from "react-tracking";
import { FinalStageBreakdownDetailGrid } from "./detail/finalStageDetail";

import "./grid.css";

@track(
	props => {
		return { event_type: AnalyticsEventType.NAVIGATION, event_subtype: `${props.tracking_path ? props.tracking_path + "." : ""}grid` };
	},
	{ dispatchOnMount: false }
)
export class StageBreakdownGridView extends React.Component<LMI.IStageBreakdownGridProps, LMI.IStageBreakdownGridState> {
	grids: GridComponent[] = [];
	nonSelectableColumns: string[] = ["AVG", "TOTAL"];
	reconRowId = -2;

	constructor(props: LMI.IStageBreakdownGridProps) {
		super(props);

		this.state = {
			modal_open: false,
			modal: { title: "Breakdown Detail" }
		};
	}

	setGrid(grid) {
		this.grids.push(grid);
	}

	phaseHasInventory = (phase_id: number): boolean => {
		const phase = this.props.phases.filter(p => p.id === phase_id)[0];
		return phase && phase.inventory[0];
	};

	phaseInventoryCount = (phase_id: number): number => {
		const phase = this.props.phases.filter(p => p.id === phase_id)[0];
		return phase && phase.inventory[0] ? phase.inventory.length : 0;
	};

	createGridColumns(source, category, gridData) {
		const { data, existing_months } = gridData;
		if (!existing_months) return false;

		const defaultProps = {
			textAlign: "center",
			allowFiltering: false,
			allowSorting: false
		};

		const sourceGrid = source === "reconlevels" ? 0 : 1;

		const months = [...existing_months];
		months.push("AVG");

		const headerCategoryData: any = [
			{
				...defaultProps,
				id: "category",
				headerText: "Category",
				textAlign: "Center",
				columns: [
					{
						field: "category",
						headerText: category,
						textAlign: "Left",
						width: 180,
						allowFiltering: true,
						allowSorting: true,
						template: props => {
							const phaseHasInventory = this.phaseHasInventory(props.phase_id);
							const isActive = source === "stages" && phaseHasInventory;
							const phase = this.props.phases.filter(p => p.id === props.phase_id)[0];
							const phaseInventoryCount = this.phaseInventoryCount(props.phase_id);
							const isReconTotalRow = typeof props.recon_level_id !== "undefined" && props.recon_level_id == this.reconRowId;
							const borderColor = isReconTotalRow ? "rgb(15, 217, 45)" : phase ? phase.color_code : "#fff";
							return (
								<span
									data-column="category"
									style={{
										color: `${source === "stages" && !phase ? "#bbb" : "#000"}`,
										fontStyle: `${source === "stages" && !phase ? "italic" : "normal"}`,
										padding: "10px 5px",
										marginLeft: "0px",
										borderLeft: `4px solid ${borderColor}`
									}}>
									{props.category}{" "}
									<span className={!props.final_stage && isActive ? "active" : ""}>
										{props.final_stage ? "" : source === "stages" ? `(${phaseInventoryCount})` : ""}
									</span>
								</span>
							);
						},
						valueAccessor: (field: string, data: any, column: Column) => {
							const phaseInventoryCount = this.phaseInventoryCount(data.phase_id);
							return `${data[field]} ${data.final_stage ? "" : source === "stages" ? "(" + phaseInventoryCount + ")" : ""}`;
						}
					},
					{
						field: "warning",
						headerText: "Warning",
						textAlign: "Center",
						width: 60,
						allowFiltering: false,
						allowSorting: false,
						template: (props: any) => {
							return <span style={{ color: "orange" }}>{props[props.column.field] ? props[props.column.field].text : ""}</span>;
						},
						valueAccessor: (field: string, data: any, column: Column) => {
							return data[field] ? data[field].text : "";
						}
					},
					{
						field: "critical",
						headerText: "Critical",
						textAlign: "Center",
						width: 60,
						allowFiltering: false,
						allowSorting: false,
						template: (props: any) => {
							return <span style={{ color: "red" }}>{props[props.column.field] ? props[props.column.field].text : ""}</span>;
						},
						valueAccessor: (field: string, data: any, column: Column) => {
							return data[field] ? data[field].text : "";
						}
					}
				]
			}
		];

		const headerData = headerCategoryData.concat(
			months.map((d, index) => {
				return {
					...defaultProps,
					id: d.toUpperCase(),
					headerText: d.substr(0, 3) as any,
					textAlign: "Center",
					columns: [
						{
							field: "count",
							headerText: "#",
							textAlign: "Center",
							allowFiltering: false,
							allowSorting: false,
							template: (props: any) => {
								const isSelectable = (props[d].count > 0 || props[d].month < props[d].stop_month) && !this.nonSelectableColumns.includes(d);
								return (
									<span
										className={`${isSelectable ? "selectable" : ""}`}
										data-year={d.split("-")[1]}
										data-column={d.split("-")[0]}
										data-id={sourceGrid === 0 ? props.recon_level_id : props.phase_id}
										data-count={props[d].count}
										data-days={props[d].days}
										data-index={sourceGrid}
										onClick={this.handleSelect.bind(this, props)}>
										{props[d][props.column.field] > 0 ? (d === "AVG" ? Math.round(props[d][props.column.field]) : props[d][props.column.field]) : "--"}
									</span>
								);
							},
							valueAccessor: (field: string, data: any, column: Column) => {
								return data[d][field] > 0 ? (d === "AVG" ? Math.round(data[d][field]) : data[d][field]) : "--";
							}
						},
						{
							field: "days",
							headerText: "Days",
							textAlign: "Center",
							allowFiltering: false,
							allowSorting: false,
							width: 60,
							template: (props: any) => {
								const isSelectable = (props[d].count > 0 || props[d].month < props[d].stop_month) && !this.nonSelectableColumns.includes(d);
								return (
									<span
										className={`${isSelectable ? "selectable" : ""}`}
										data-year={d.split("-")[1]}
										data-column={d.split("-")[0]}
										data-id={sourceGrid === 0 ? props.recon_level_id : props.phase_id}
										data-count={props[d].count}
										data-days={props[d].days}
										data-index={sourceGrid}
										onClick={this.handleSelect.bind(this, props)}
										style={{
											color:
												props.critical && props[d][props.column.field] > props.critical.number
													? "red"
													: props.warning && props[d][props.column.field] > props.warning.number
													? "orange"
													: ""
										}}>
										{props.final_stage ? "-- --" : props[d][props.column.field].toFixed(2)}
									</span>
								);
							},
							valueAccessor: (field: string, data: any, column: Column) => {
								return data.final_stage ? "-- --" : data[d][field].toFixed(2);
							}
						}
					]
				};
			})
		);

		headerData.push({
			...defaultProps,
			id: "SUM",
			headerText: "TOTAL",
			textAlign: "center",
			columns: [
				{
					field: "count",
					headerText: "#",
					textAlign: "Center",
					allowFiltering: false,
					allowSorting: false,
					width: 60,
					template: (props: any) => {
						return <span data-column="TOTAL">{props["SUM"]["count"] > 0 ? props["SUM"]["count"] : "--"}</span>;
					},
					valueAccessor: (field: string, data: any, column: Column) => {
						return data["SUM"]["count"] > 0 ? data["SUM"]["count"] : "--";
					}
				}
			]
		});

		return GridColumnHeaders(data, headerData, ["category", ...months, "AVG", "SUM"]);
	}

	customizeCell = (args: QueryCellInfoEventArgs) => {
		const isSelectable = (args.cell.firstChild as HTMLElement).classList.contains("selectable");
		if (isSelectable) {
			args.cell.classList.add("selectable");
		}
	};

	closeModal = () => {
		this.setState({ modal_open: false });
	};

	clearSelection = index => {
		this.grids[index].clearSelection();
	};

	handleSelect = (data, args: any): boolean => {
		const { phasers } = this.props;
		const dataSet = args.target.dataset as DOMStringMap;
		const { column, count, days, index, year } = dataSet;
		const indexInt = parseInt(index, 10);

		this.clearSelection(indexInt);

		try {
			const reconLevelId = (data as any).recon_level_id as number;
			const phaseId = (data as any).phase_id as number;
			const roles = phasers.job_title_assignments.filter(j => j.phase_id === phaseId);
			const users = phasers.user_assignments.filter(u => u.phase_id === phaseId);
			const phaseHasInventory = this.phaseHasInventory(phaseId);
			const finalStage = (data as any).final_stage as boolean;

			if (!column || this.nonSelectableColumns.includes(column) || (column === "category" && (indexInt === 0 || !phaseHasInventory))) {
				return false;
			}

			const phaseName = (data as any).category as string;
			const critical = (data as any).critical as string;
			const warning = (data as any).warning as string;

			if (column === "category") {
				this.props.history.push(`/admin/dealerships/workflow/${phaseId}`);
			} else {
				this.setState({
					modal_open: true,
					modal: {
						title: `${phaseName} - ${column} ${year ? year : ""}`,
						recon_level_id: phaseName === "New" ? -1 : phaseName === "Not Set" ? 0 : reconLevelId,
						phase_id: phaseId,
						phase_name: phaseName,
						year,
						month: Months[column],
						users,
						roles,
						critical,
						warning,
						count,
						days,
						index: finalStage ? 2 : indexInt
					}
				});
			}
		} catch (error) {
			Raven.captureException(error);
			console.error("Captured Grid Error: ", error);
		}
	};

	rowDataBound = (args: RowDataBoundEventArgs) => {
		if (args.row) {
			if ((args.data as any).final_stage) {
				args.row.classList.add("row-final-stage");
			}
		}
	};

	reconTotalBinding = (args: RowDataBoundEventArgs) => {
		if (args.row) {
			const data: any = { ...args.data };
			if (data.recon_level_id == this.reconRowId) args.row.classList.add("row-total");
		}
	};

	render() {
		const { modal_open, modal } = this.state;
		const { store_id, breakdown, phases, phasers, total_or_recon } = this.props;

		if (!breakdown || !phases || !phasers) return <Loader className={`loader active`} size="small" />;

		const { average_top_grid, average_bottom_grid, key, recon_level_selected } = breakdown;

		return (
			<div className="stage-breakdown-grid" key={key}>
				{modal_open && (
					<ModalComponent
						headerText={modal.title}
						size="fullscreen"
						shouldBeOpen={modal_open}
						onClose={() => this.closeModal()}
						className="stage-breakdown-detail-modal"
						contentComponent={() => {
							if (modal.index === 0) {
								return (
									<StageBreakdownReconLevelDetailGrid
										{...{
											recon_level_id: modal.recon_level_id,
											store_id,
											month: modal.month,
											users: modal.users,
											roles: modal.roles,
											warning: modal.warning,
											critical: modal.critical,
											year: modal.year,
											count: modal.count,
											days: modal.days,
											tracking_path: this.props.tracking.getTrackingData().event_subtype,
											total_or_recon
										}}
									/>
								);
							} else if (modal.index === 1) {
								return (
									<StageBreakdownDetailGrid
										{...{
											recon_level_selected: recon_level_selected === -2 ? null : recon_level_selected,
											phase_id: modal.phase_id,
											store_id,
											month: modal.month,
											users: modal.users,
											roles: modal.roles,
											warning: modal.warning,
											critical: modal.critical,
											year: modal.year,
											count: modal.count,
											days: modal.days,
											tracking_path: this.props.tracking.getTrackingData().event_subtype
										}}
									/>
								);
							} else {
								return (
									<FinalStageBreakdownDetailGrid
										{...{
											recon_level_selected: recon_level_selected === -2 ? null : recon_level_selected,
											phase_id: modal.phase_id,
											store_id,
											month: modal.month,
											users: modal.users,
											roles: modal.roles,
											year: modal.year,
											count: modal.count,
											days: modal.days,
											tracking_path: this.props.tracking.getTrackingData().event_subtype
										}}
									/>
								);
							}
						}}
					/>
				)}
				<div className="top-grid">
					<RenderDataGrid
						{...({
							rowHeight: "25",
							gridLines: "Both",
							total: average_top_grid.data ? average_top_grid.data.length : 0,
							data: average_top_grid.data,
							columns: this.createGridColumns("reconlevels", "Recon Level", average_top_grid),
							queryCellInfo: this.customizeCell,
							tools: [GridToolOptions.excelexport, GridToolOptions.sorting, GridToolOptions.title],
							grid_title: { text: "Recon Levels", style: { padding: "12px" } },
							textwrap: false,
							setGrid: grid => this.setGrid(grid),
							onRowDataBound: this.reconTotalBinding,
							noresults: {
								icon: "truck",
								text: "No vehicles have been processed yet."
							}
						} as DataGridProps)}
					/>
				</div>
				<div className="bottom-grid">
					<RenderDataGrid
						{...({
							rowHeight: "25",
							gridLines: "Both",
							total: average_bottom_grid.data ? average_bottom_grid.data.length : 0,
							data: average_bottom_grid.data,
							columns: this.createGridColumns("stages", "Stage (Active Units)", average_bottom_grid),
							queryCellInfo: this.customizeCell,
							tools: [GridToolOptions.excelexport, GridToolOptions.sorting, GridToolOptions.title],
							grid_title: { text: "Stages", style: { padding: "12px" } },
							onRowDataBound: this.rowDataBound,
							textwrap: false,
							setGrid: grid => this.setGrid(grid),
							noresults: {
								hide: true
							}
						} as DataGridProps)}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => {
	return {};
};

const mapDispatchToProps = (dispatch: any) => {
	return {};
};

export const StageBreakdownGrid = compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(StageBreakdownGridView);
export default StageBreakdownGrid;
